import { ProColumns, ProTable } from '@ant-design/pro-components';
import axiosApiInstance from '../../../api/axiosClient';
import { SearchConfig } from '@ant-design/pro-table/es/components/Form/FormRender';
import { useBusinessesManualQuery, useCategoriesManualQuery } from './queryHooks';
import { Badge, Button, Tooltip } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    MailOutlined,
    PhoneOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../context/GlobalContext';

const ContactsTable = () => {
    const { refetch: fetchCategories } = useCategoriesManualQuery();
    const { refetch: fetchBusinesses } = useBusinessesManualQuery();

    const location = useLocation();
    const navigate = useNavigate();
    const { contactsTableRef } = useContext(GlobalStateContext);

    const searchConfig: SearchConfig = {
        resetText: 'Clear',
        searchText: 'Search',
        collapsed: false,
        collapseRender: false
    };

    const columns: ProColumns<API.CRM.Contact>[] = [
        {
            title: 'Name',
            dataIndex: 'full_name',
            sorter: (a, b) => a.full_name.localeCompare(b.full_name)
        },
        {
            title: 'Category',
            dataIndex: 'contact_category',
            key: 'contact_category_id',
            renderText: (_, { contact_category }) => contact_category.name,
            valueType: 'select',
            request: () =>
                fetchCategories().then(({ data }) =>
                    data.map((datum) => ({
                        label: datum.name,
                        value: datum.id
                    }))
                )
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            key: 'primary_phone_number',
            width: 188,
            renderText: (text, { primary_phone_number }) => primary_phone_number.number,
            render: (dom, { phone_numbers, primary_phone_number, full_name }) => [
                // <Space align='start' size={0} wrap={false}>
                <>{dom}</>,
                <>
                    {primary_phone_number.is_primary ? (
                        <Button
                            size='small'
                            type='link'
                            icon={<PhoneOutlined />}
                            onClick={() =>
                                navigate({
                                    pathname: '/social/connections/call',
                                    search: `?number=${primary_phone_number.number}&name=${
                                        primary_phone_number.contact_name === '-'
                                            ? full_name
                                            : primary_phone_number.contact_name
                                    }`
                                })
                            }
                        />
                    ) : (
                        <></>
                    )}
                </>,
                <>
                    <Badge
                        count={phone_numbers.length > 1 ? phone_numbers.length : 0}
                        size='small'
                        style={{ margin: '-2px 2px 1px 3px' }}
                        offset={[-2, -3]}
                    />
                </>
                // </Space>
            ],
            copyable: true,
            search: false
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'primary_email',
            width: 320,
            sorter: (a, b) => a.primary_email.email_address.localeCompare(b.primary_email.email_address),
            renderText: (_, { primary_email }) => primary_email.email_address,
            render: (dom, { emails, primary_email }) => (
                <>
                    {dom}
                    {primary_email.is_primary ? (
                        <Button
                            size='small'
                            type='link'
                            icon={<MailOutlined />}
                            href={`mailto:${primary_email.email_address}`}
                        />
                    ) : (
                        <></>
                    )}
                    <Badge
                        count={emails.length > 1 ? emails.length : 0}
                        size='small'
                        style={{ margin: '-2px 2px 1px 3px' }}
                        offset={[-1, -3]}
                    />
                </>
            ),
            copyable: true,
            search: false
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'primary_address',
            copyable: true,
            sorter: (a, b) => a.primary_address.full_address.localeCompare(b.primary_address.full_address),
            renderText: (_, { primary_address }) => primary_address.full_address,
            render: (dom, { primary_address, addresses }) => (
                <>
                    {dom}
                    <Badge
                        count={addresses.length > 1 ? addresses.length : 0}
                        size='small'
                        style={{ margin: '-2px 2px 1px 3px' }}
                        offset={[-1, -3]}
                    />
                </>
            )
        },
        {
            title: 'Business',
            dataIndex: 'business',
            key: 'business_id',
            renderText: (_, { business }) => business.business_name,
            valueType: 'select',
            fieldProps: {
                showSearch: true
            },
            request: () =>
                fetchBusinesses().then(({ data }) =>
                    data.map((datum) => ({
                        label: datum.business_name,
                        value: datum.id
                    }))
                )
        },
        {
            title: '',
            valueType: 'option',
            width: 75,
            render: (_, entity) => [
                <Link to={`view/${entity.id}`} state={{ background: location }}>
                    <Tooltip title='View'>
                        <Button type='link' icon={<EyeOutlined />} />
                    </Tooltip>
                </Link>,
                <Link to={`edit/${entity.id}`} state={{ background: location }}>
                    <Tooltip title='Edit'>
                        <Button type='link' icon={<EditOutlined />} />
                    </Tooltip>
                </Link>,
                <Link to={`delete/${entity.id}?name=${entity.full_name}`} state={{ background: location }}>
                    <Tooltip title='Delete' color='red'>
                        <Button type='link' danger icon={<DeleteOutlined />} />
                    </Tooltip>
                </Link>
            ]
        }
    ];
    return (
        <>
            <ProTable
                request={(params) =>
                    axiosApiInstance.get('/api/connections/contacts/', {
                        params: params
                    })
                }
                columns={columns}
                search={searchConfig}
                options={{
                    density: false,
                    fullScreen: false
                    //     reload: false,
                    //     setting: false,
                    //     search: false
                }}
                actionRef={contactsTableRef}
                toolBarRender={() => [
                    <Link to='new' state={{ background: location }}>
                        <Button key='new' type='primary' icon={<PlusOutlined />}>
                            New
                        </Button>
                    </Link>
                ]}
                // rowKey='id'
                // rowSelection={{
                //     selectedRowKeys,
                //     onChange: (selectedRowKeys: Key[], selectedRows) => {
                //         console.log('selected rows: ', selectedRowKeys);
                //         setSelectedRowKeys(selectedRowKeys);
                //     },
                //     alwaysShowAlert: false
                // }}
                // tableAlertRender={false}
            />
            <Outlet />
        </>
    );
};

export default ContactsTable;
