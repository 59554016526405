import { Row, Spin, Tabs, Statistic, Card } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { Component } from 'react';
// import { Card } from 'rmwc';
import { AggrReportResult, buildAnalyticsReportForMetricForAssets, buildAnalyticsReportForMetricForCMSsV2 } from '../../../api/aggregateReportBuilder';
import { defaultRevenueCurrency } from '../../../common/cmsInfo';
import { initClient, isUserAuthorized, authorize } from '../../../common/gapiHandler';
import { roundNum } from '../../../common/utils';
import DashboardReportingGraphV2 from '../../../REPORTING/components/DashboardReportingGraphV2';
import './index.css';

export interface IAssetPerformanceProps {
	//Here we pass the Props Interface
	ppkValue: string;
	cmsExtraId: string
}

export interface IAssetPerformanceState {
	//here we pass the State Interface
	isGapiLibraryLoading: boolean,
	isAuthorized: boolean,
	isAuthorizing: boolean,
	isRequestLoading: boolean,
	responseData?: AggrReportResult,
	graphData?: {
		[key: string]: { data: any[], unit?: string }
	}
	emptyResponse: boolean
}

//class ComponentName Component<PropsInterface, StateInterface>
class AssetPerformance extends Component<IAssetPerformanceProps, IAssetPerformanceState> {

	constructor(props: IAssetPerformanceProps) {
		super(props)

		this.state = {
			isAuthorized: false,
			isAuthorizing: false,
			isRequestLoading: true,
			isGapiLibraryLoading: true,
			emptyResponse: false
		}
	}


	//Add style here
	style = {
	};

	// Before the component mounts, we initialise our state
	componentWillMount() {
	}

	// After the component did mount, we set the state.
	componentDidMount() {
		// console.log('cms prop', this.props.cmsExtraId);

		this.initGAPI()
	}

	initGAPI() {
		(window as any).gapi.load('client:auth2', () => initClient((success: boolean) => {
			console.log(success ? 'Loaded gapi client' : 'Could not load gapi client');
			this.setState({ isGapiLibraryLoading: false })

			if (isUserAuthorized()) this.setState({ isAuthorized: true })
			else this.setState({ isAuthorized: false })

			if (!isUserAuthorized()) this.authorize()

			if (success)  //test
				this.loadAssetPerformanceData()
			// 	fetchAnalytics({ startDate: '2021-05-01', endDate: '2021-05-20', filters: 'uploaderType==self', metrics: 'views' }, (res: any) => console.log(res))
		}))
	}

	async loadAssetPerformanceData() {

		const startDate = '2010-01-01'
		const currDate = new Date()
		const endDate = currDate.getFullYear() + '-' + (currDate.getMonth() + 1 < 10 ? '0' : '') + (currDate.getMonth() + 1) + '-01'

		// let metricToDimensionToValueMap: any = await buildAnalyticsReportForMetricForCMSsV2([this.props.cmsExtraId] as cmsIdEnum[], { startDate, endDate, dimensions: 'month', currency: 'EUR', filters: 'channel==' + this.props.ppkValue })
		// let metricToDimensionToValueMap: any = await buildAnalyticsReportForMetricForAssets({ [this.props.cmsExtraId]: [this.props.ppkValue] }, { startDate, endDate, dimensions: 'month', currency: 'EUR', includeHistoricalChannelData: true })
		let metricToDimensionToValueMap: any = await buildAnalyticsReportForMetricForAssets({ [this.props.cmsExtraId]: [this.props.ppkValue] }, { startDate, endDate, dimensions: 'month', currency: 'EUR', includeHistoricalChannelData: false })
		// console.log('metricToDimensionToValueMap', metricToDimensionToValueMap);


		this.setState({ responseData: JSON.parse(JSON.stringify(metricToDimensionToValueMap)) }, () => {
			// console.log('this.state.responseData', this.state.responseData);

			if (Object.entries(this.state.responseData?.views!).length === 0) {
				this.setState({ emptyResponse: true, isRequestLoading: false })
				return
			}
			// console.log('HERE', this.state.responseData);

			const graphData = {
				views: { data: Object.entries(metricToDimensionToValueMap.views.values).sort((a: any, b: any) => a[0].localeCompare(b[0])), unit: metricToDimensionToValueMap.views.unit },
				watchTime: { data: Object.entries(metricToDimensionToValueMap.watchTime.values).sort((a: any, b: any) => a[0].localeCompare(b[0])), unit: metricToDimensionToValueMap.watchTime.unit },
				subscribers: { data: Object.entries(metricToDimensionToValueMap.subscribers.values).sort((a: any, b: any) => a[0].localeCompare(b[0])), unit: metricToDimensionToValueMap.subscribers.unit },
				revenue: { data: Object.entries(metricToDimensionToValueMap.revenue.values).sort((a: any, b: any) => a[0].localeCompare(b[0])), unit: metricToDimensionToValueMap.revenue.unit },
			}

			this.setState({ isRequestLoading: false, graphData: graphData })
		})


	}

	authorize() {
		console.log('Authorizing...');

		this.setState({ isAuthorizing: true },
			() => authorize()
				.then(() => this.setState({ isAuthorized: true, isAuthorizing: false }))
				.catch(() => this.setState({ isAuthorized: false, isAuthorizing: false }))
		)
	}

	render() {

		const cardPadding = 16
		return (
			<div className="AssetPerformance" style={{ width: '100%' }} >


				<Row style={{ width: '100%' }}>
					{!this.state.isAuthorized && !this.state.isAuthorizing && !this.state.isGapiLibraryLoading && <Row justify='center'><Text type='danger'>You are not authorized to access the performance data of this asset.</Text></Row>}
					{this.state.emptyResponse && <Row justify='center'><Text type='secondary'>No performance data could be fetched for this asset.</Text></Row>}
					{this.state.isGapiLibraryLoading && !this.state.isRequestLoading && <Row justify='center'>
						<Spin spinning={true}
						// tip='Library loading...'
						></Spin>
					</Row>}
					{!this.state.isGapiLibraryLoading && this.state.isAuthorizing && <Row justify='center'><Spin spinning={true} tip='Authorizing...'></Spin></Row>}

					<div style={{ width: '100%' }}>
						<Spin spinning={this.state.isRequestLoading} tip='Loading...' style={{ width: '100%' }}>
							{!this.state.isGapiLibraryLoading && this.state.isAuthorized && !this.state.emptyResponse && !this.state.isRequestLoading &&

								<Tabs defaultActiveKey="views" style={{ width: '100%' }} >
									<Tabs.TabPane tab={
										<Card style={{ padding: cardPadding }}>
											<Statistic title='Views' value={this.state.responseData?.views.sum.value} suffix={this.state.responseData?.views.sum.unit} />
										</Card>
									} key="views">
										{/* <div style={{ width: '100%' }}> */}
										<DashboardReportingGraphV2 metric='views' label={false} unit={this.state.graphData?.views.unit!} dimension='month' data={this.state.graphData?.views.data} cumulative={false} slider />
										{/* </div> */}
									</Tabs.TabPane>
									<Tabs.TabPane tab={
										<Card style={{ padding: cardPadding }}>
											<Statistic title='Watch Time' value={this.state.responseData?.watchTime.sum.value} suffix={this.state.responseData?.watchTime.sum.unit} />
										</Card>
									} key="watchTime">
										<DashboardReportingGraphV2 metric='watchTime' label={false} unit={this.state.graphData?.watchTime.unit!} dimension='month' data={this.state.graphData?.watchTime.data} cumulative={false} slider />
									</Tabs.TabPane>
									<Tabs.TabPane tab={
										<Card style={{ padding: cardPadding }}>
											<Statistic title='Subscribers' value={this.state.responseData?.subscribers.sum.value} suffix={this.state.responseData?.subscribers.sum.unit} />
										</Card>
									} key="subscribers">
										<DashboardReportingGraphV2 metric='subscribers' label={false} unit={this.state.graphData?.subscribers.unit!} dimension='month' data={this.state.graphData?.subscribers.data} cumulative={false} slider />
									</Tabs.TabPane>
									<Tabs.TabPane tab={
										<Card style={{ padding: cardPadding }}>
											<Statistic title='Est. Revenue' value={this.state.responseData?.revenue.sum.value} prefix={defaultRevenueCurrency} suffix={this.state.responseData?.revenue.sum.unit} precision={2} />
										</Card>
									} key="revenue">
										<DashboardReportingGraphV2 metric='revenue' label={false} unit={this.state.graphData?.revenue.unit!} dimension='month' data={this.state.graphData?.revenue.data} cumulative={false} slider />
									</Tabs.TabPane>
								</Tabs>
							}

						</Spin>
					</div>
				</Row>


			</div>
		);
	}
}

export default AssetPerformance;