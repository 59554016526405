import * as React from 'react';
import {Suspense} from 'react';
import {RESET} from "jotai/utils";
import {Col, Modal, Row, Select} from "antd";
import {useAtom} from "jotai";
import {
    channelCategoriesAtom,
    channelMetricsAtom,
    compareCategoriesModalSelectedCategoriesAtom,
    loadableCompareCategoriesChartDataAtom,
    selectedChannelMetricAtom,
    selectedChannelMetricObjAtom,
    showCompareCategoriesModalAtom,
    yFieldAtom
} from "./atoms";
import {ProCard, ProTable} from "@ant-design/pro-components";
import './CompareCategoriesChartModal.css'
import {Line} from "@ant-design/plots";
import {numFormatterInt} from "./YouTubeBenchmarks";
import dayjs from "dayjs";
import {useAtomValue, useSetAtom} from "jotai/index";
import {PageLoading} from "@ant-design/pro-layout";

type Props = {};

export const CompareCategoriesChartModal = (props: Props) => {
    const setShowModal = useSetAtom(showCompareCategoriesModalAtom);
    const setSelectedCategories = useSetAtom(compareCategoriesModalSelectedCategoriesAtom);
    const setSelectedChannelMetric = useSetAtom(selectedChannelMetricAtom);

    return (
        <div>
            <Modal
                className={'category-chart-modal-container'}
                open={true}
                onCancel={() => {
                    setShowModal(RESET)
                    setSelectedCategories(RESET)
                    setSelectedChannelMetric(RESET)
                    // setFilterSearch(RESET)
                }}
                width={'100%'}
                bodyStyle={{maxHeight: '70vh'}}
                footer={null}
            >
                <Suspense fallback={
                    <PageLoading style={{height: '70vh'}}/>
                }>
                    <ModalContent/>
                </Suspense>
            </Modal>
        </div>
    );
};


export const ModalContent = (props: Props) => {
    const [selectedCategories, setSelectedCategories] = useAtom(compareCategoriesModalSelectedCategoriesAtom);
    const channelMetrics = useAtomValue(channelMetricsAtom);
    const [selectedChannelMetric, setSelectedChannelMetric] = useAtom(selectedChannelMetricAtom);
    const [monthlyChartData] = useAtom(loadableCompareCategoriesChartDataAtom);
    const selectedChannelMetricObj = useAtomValue(selectedChannelMetricObjAtom)
    const yField = useAtomValue(yFieldAtom)
    const channelCategories = useAtomValue(channelCategoriesAtom)
    return (
        <Row
            style={{maxHeight: 'inherit'}}
        >
            <Col span={8} style={{maxHeight: 'inherit'}}>
                <ProTable
                    columns={[
                        {
                            // channel
                            title: 'Category',
                            dataIndex: 'category',
                        },
                    ]}
                    // dataSource={rankedChannels}
                    // dataSource={searchFilteredChannels}
                    dataSource={channelCategories}
                    search={false}
                    // options={false}
                    options={{
                        density: false,
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        // search: {
                        //     placeholder: "Search...",
                        //     onChange: (e) => {
                        //         setFilterSearch(e.target.value)
                        //     },
                        //     value: filterSearch,
                        //     allowClear: true,
                        //     style: { width: '100%', flexGrow: 1 }
                        // },
                        search: false,
                    }}
                    // cardBordered
                    pagination={false}
                    tableStyle={{
                        padding: 0, margin: 0, paddingBlock: 0, paddingInline: 0,
                        // overflowY: 'auto',
                        // maxHeight: 'inherit'
                    }}
                    style={{
                        overflowY: 'auto',
                        maxHeight: 'inherit'
                    }}
                    rowKey="id"
                    rowSelection={{
                        selectedRowKeys: selectedCategories,
                        type: 'checkbox',
                        onChange: (selectedRowKeys: number[], selectedRows) => {
                            // console.log(selectedRowKeys, selectedRows);
                            setSelectedCategories(selectedRowKeys)
                        },
                    }}
                    tableAlertRender={false}

                />
            </Col>

            <Col span={16} style={{maxHeight: 'inherit'}}>
                <ProCard
                    colSpan={16}
                    // layout="center"
                    // bordered
                    split={'horizontal'}
                    // style={{maxHeight: 'inherit'}}
                    style={{height: '100%'}}
                    bodyStyle={{height: '100%'}}
                >
                    <ProCard>

                        <Select
                            value={selectedChannelMetric}
                            options={channelMetrics.map((metric) => ({
                                label: metric.name,
                                value: metric.data_index
                            }))}
                            dropdownMatchSelectWidth={false}
                            onChange={(value) => {
                                setSelectedChannelMetric(value)
                            }}
                        />
                    </ProCard>
                    <ProCard
                        style={{height: '100%'}}
                        bodyStyle={{height: '100%'}}
                        className={'category-chart-container-line-card'}

                    >
                        <Line
                            style={{height: '100%'}}
                            // point style
                            point={{
                                size: 2,
                                shape: 'dot',
                            }}
                            smooth={true}
                            loading={monthlyChartData?.state === 'loading'}
                            data={monthlyChartData.state === 'hasData' ? monthlyChartData.data : []}
                            xField={'date'}
                            // yField={selectedChannelMetricObj?.json_index}
                            yField={yField}
                            seriesField={'categoryID'}
                            slider={{
                                start: 0,
                                end: 1,
                            }}
                            meta={{
                                date: {
                                    formatter: (v) => {
                                        return dayjs(v).format('MMM YYYY')
                                    }
                                },
                                [selectedChannelMetricObj?.json_index]: {
                                    formatter: (v) => {
                                        return numFormatterInt.format(v || 0)
                                    }
                                },
                                categoryID: {
                                    formatter: (v) => {
                                        // console.log(v)
                                        return channelCategories.find((cat) => cat.id == v)?.category
                                    }
                                }
                            }}

                        />
                    </ProCard>

                </ProCard>

            </Col>
        </Row>

    );
};

export default CompareCategoriesChartModal;