import {Button, ConfigProvider, notification, Space} from 'antd';
import React, {Component} from 'react';
import './index.css';
import enUSIntl from "antd/lib/locale/en_US";
import ProTable, {ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import {FileTextOutlined} from "@ant-design/icons";
import {humanFileSize} from "../../../common/utils";
import withRouter from "../../../components/withRouter";


export interface IPaymentCycleFilesProps {
    //Here we pass the Props Interface
    pageSize?: number
    polling?: false | number
    params: any
}

export interface IPaymentCycleFilesState {


}


type PaymentCycleFile = {
    cycle_id: string;
    filename: string;
    size: number;
    step: string;
    phase: string;
};


//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleFiles extends Component<IPaymentCycleFilesProps, IPaymentCycleFilesState> {

    constructor(props: IPaymentCycleFilesProps) {
        super(props);


    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {

    }

    // After the component did mount, we set the state.
    componentDidMount() {
        // console.log('cycle files', this.props.cycle_id);

        // postRequest('/api/paymentCycles/analyticsTest').then(value => {
        //     console.log(value)
        // }).catch(reason => {
        //     console.log(reason)
        // })

    }

    columns: ProColumns<PaymentCycleFile>[] = [
        {
            title: 'Filename', dataIndex: 'filename', valueType: 'text',
        },
        {
            title: 'Product', dataIndex: 'product', valueType: 'text'
        },
        {
            title: 'Size',
            dataIndex: 'size',
            // render: (num) => (((num as number) / (1024 * 1024))?.toLocaleString('el-GR')),
            render: (num) => humanFileSize(num as number),
            sorter: (a, b) => a.size - b.size, defaultSortOrder: "descend"
        },
        {
            title: 'Step', dataIndex: 'step', valueType: 'text',
        },
        {
            title: 'Phase', dataIndex: 'phase', valueType: 'text',
            valueEnum: row => ({
                // all: { text: 'processing', status: 'processing' },
                default: {
                    text: 'default',
                    status: 'default',
                },
                processing: {
                    text: 'processing',
                    status: 'processing',
                },
                success: {
                    text: 'success',
                    status: 'success',
                },
                error: {
                    text: 'error',
                    status: 'error',
                },
            })
        },
        {
            title: 'Action',
            // dataIndex: 'size',
            // render: (num) => (((num as number) / (1024 * 1024))?.toLocaleString('el-GR')),
            render: (dom, entity) => <Button onClick={
                () => this.loadOne(entity.filename)
            }>Load</Button>,
        },
    ];

    loadOne = (filename: string) => {
        // this.setState({filesPolling: 2000})
        postRequest('/api/paymentCycles/loadOne/' + this.props.params.id, {filename})
            .then(value => {
                console.log(value)
                notification.success({message: 'Success'})
                // this.setState({filesPolling: undefined})

            }).catch(error => {
            let apiError = error.response.data as API.ApiError;
            notification.error({ message: 'Error ' + error.response.status, description: apiError.message });

        })
    }

    render() {


        return (
            <>

                {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}

                <ConfigProvider locale={enUSIntl}>

                    {/*<Card bodyStyle={{padding: 0}}>*/}

                    <ProTable<PaymentCycleFile>
                        columns={this.columns}
                        // actionRef={this.state.actionRef as any}
                        // cardBordered
                        bordered
                        // size={"small"}
                        // style={{fontSize:6}}
                        // showHeader={false}
                        // headerTitle={"Logs"}
                        polling={this.props.polling || 0}
                        request={async (params = {}, sort, filter) => {
                            const res = await postRequest('/api/paymentCycles/getFiles/' + this.props.params.id)
                            const data = res.data || []
                            // console.log(res.data)
                            return {data, success: true, total: data.length}
                        }}
                        rowKey="filename"
                        search={false}
                        locale={{emptyText: <Space direction={'vertical'}> <FileTextOutlined/> No files</Space>}}
                        pagination={{pageSize: this.props.pageSize || 10, hideOnSinglePage: true}}
                        dateFormatter="string"
                        toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                        // toolBarRender={false}
                        // toolBarRender={() => []}
                    />
                    {/*</Card>*/}


                </ConfigProvider>

            </>
        );
    }
}

export default withRouter(PaymentCycleFiles);