import React, {useState} from 'react';
import {Button, Checkbox, Col, Form, Row, Space} from 'antd';
import {ProForm, ProFormTextArea} from '@ant-design/pro-form';
import ProTable from '@ant-design/pro-table';
import {saveAs} from 'file-saver';
import * as XLSX from 'xlsx';
import axiosApiInstance from "../../api/axiosClient";
import ProFormDatePickerMonth from "@ant-design/pro-form/es/components/DatePicker/MonthPicker";
import {CheckboxChangeEvent} from "antd/es/checkbox";

declare interface VideoData {
    channel_id: string;
    channel_name: string;
    video_id: string;
    video_url: string;
    publish_date: string;
    title: string;
    views: number;
    likes: number;
    comments: number;
}

const VideoStatsReporting = () => {
    const [form] = Form.useForm();
    const [tableData, setTableData] = useState<VideoData[]>([]);
    const [loading, setLoading] = useState(false);
    const [lifetime, setLifetime] = useState<boolean>(false);

    const onLifetimeChange = (e: CheckboxChangeEvent) => {
        setLifetime(e.target.checked)
    };

    function getFormattedCurrentDate(): string {
        const currentDate = new Date();

        const year = currentDate.getUTCFullYear().toString().padStart(4, '0');
        const month = (currentDate.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getUTCDate().toString().padStart(2, '0');
        const hours = currentDate.getUTCHours().toString().padStart(2, '0');
        const minutes = currentDate.getUTCMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getUTCSeconds().toString().padStart(2, '0');
        const milliseconds = currentDate.getUTCMilliseconds().toString().padStart(3, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    }

    const checkIfDateIsValid = async (rule: any, value: any) => {
        // If user selected a future month
        if (value && value.isAfter() && !lifetime) {
            throw new Error('Please select a past or current month');
        }
    };


    const fetchVideoStats = async (values) => {
        if (lifetime) {
            values.month = getFormattedCurrentDate()
        }

        return axiosApiInstance.get<VideoData[]>('/api/youtubereports/fetch_3p_video_stats', {
            params: {
                ...values,
                lifetime: lifetime,
            }
        });
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();

            // Perform API request to fetch YouTube video stats
            const videoStats = await fetchVideoStats(values);

            if (videoStats.data != null) {
                setTableData(videoStats.data);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'VideoStats');
        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(data, `video_stats_${Date.now()}.xlsx`);
    };

    return (
        <div>
            <Row justify={"space-between"}>
                <ProForm form={form} layout="vertical" onFinish={handleSubmit} style={{width: "100%"}}
                         submitter={{
                             render: (_, dom) => (
                                 <Form.Item>
                                     <Button
                                         type="primary"
                                         htmlType="submit"
                                         loading={loading}
                                     >
                                         Submit
                                     </Button>
                                     <Button
                                         type="default"
                                         style={{marginLeft: 16}}
                                         onClick={handleExport}
                                         disabled={!tableData.length}
                                     >
                                         Export to Excel
                                     </Button>
                                 </Form.Item>
                             ),
                         }}
                >
                    {/*<ProFormGroup>*/}
                    {/*    <Space align={"baseline"}>*/}
                    {/*<Row justify={"space-around"}>*/}
                        <Space size={"large"} align={"center"}>

                            {/*<Col span={24}>*/}
                                <ProFormTextArea
                                    name="channel_ids"
                                    label="Channels"
                                    rules={[{required: true, message: 'Please enter channels'}]}
                                    placeholder="Enter channel IDs, one per line, e.g. UC_x5XG1OV2P6uZZ5FSM9Ttw"
                                    // placeholder={["Enter channel IDs, one per line", "Example: UC_x5XG1OV2P6uZZ5FSM9Ttw"]}
                                    width="md"
                                    // style={{whiteSpace: 'pre'}}
                                    // fieldProps={{
                                    //     autoSize: {
                                    //         minRows: 4,
                                    //         maxRows: 8,
                                    //     },
                                    // }}
                                />
                            {/*</Col>*/}

                            {/*<Col span={24}>*/}
                            {/*    <Row>*/}
                                    {/*<Col span={8}>*/}
                                        <Checkbox onChange={onLifetimeChange}
                                                  disabled={loading}
                                                  checked={lifetime}>
                                            Lifetime
                                        </Checkbox>
                                    {/*</Col>*/}

                                    {/*<Col span={16}>*/}
                                        <ProFormDatePickerMonth
                                            name="month"
                                            label="Month"
                                            rules={[
                                                {required: !lifetime, message: 'Please select month'},
                                                {validator: checkIfDateIsValid}
                                            ]}
                                            width="sm"
                                            fieldProps={{
                                                disabled: lifetime
                                            }}
                                        />
                                    {/*</Col>*/}
                                {/*</Row>*/}
                            {/*</Col>*/}

                        </Space>
                    {/*</Row>*/}
                    {/*</Space>*/}

                    {/*<ProFormDateRangePicker*/}
                    {/*    name="dateRange"*/}
                    {/*    label="Date Range"*/}
                    {/*    rules={[{required: true, message: 'Please select date range'}]}*/}
                    {/*    width="md"*/}
                    {/*/>*/}
                    {/*<Form.Item>*/}
                    {/*    <Button type="primary" htmlType="submit" loading={loading}>*/}
                    {/*        Submit*/}
                    {/*    </Button>*/}
                    {/*    <Button type="primary" style={{marginLeft: 16}} onClick={handleExport} disabled={!tableData.length}>*/}
                    {/*        Export to Excel*/}
                    {/*    </Button>*/}
                    {/*</Form.Item>*/}
                    {/*</ProFormGroup>*/}
                </ProForm>
            </Row>

            <Row>
                <ProTable
                    columns={[
                        // {
                        //     title: 'Channel ID',
                        //     dataIndex: 'channel_id',
                        // },
                        {
                            title: 'Channel',
                            dataIndex: 'channel_name',
                            render: (dom, entity) => <a target={"_blank"} href={
                                `https://www.youtube.com/channel/${entity.channel_id}`
                            }>{entity.channel_name}</a>,
                            ellipsis: true,
                            // width: '20%',
                        },
                        // {
                        //     title: 'Video ID',
                        //     dataIndex: 'video_id',
                        // },
                        {
                            title: 'Video',
                            dataIndex: 'title',
                            render: (dom, entity) => <a target={"_blank"} href={entity.video_url}>{entity.title}</a>,
                            ellipsis: true,
                            // width: '20%',

                        },
                        // {
                        //     title: 'Video URL',
                        //     dataIndex: 'video_url',
                        //     renderText: (text) => <a href={text}>{text}</a>,
                        // },
                        {
                            title: 'Publish Date',
                            dataIndex: 'publish_date',
                            align: 'right',
                        },
                        {
                            title: 'Views',
                            dataIndex: 'views',
                            align: 'right',
                            renderText: (text) => Intl.NumberFormat().format(text),
                        },
                        {
                            title: 'Likes',
                            dataIndex: 'likes',
                            align: 'right',
                            renderText: (text) => Intl.NumberFormat().format(text),
                        },
                        {
                            title: 'Comments',
                            dataIndex: 'comments',
                            align: 'right',
                            renderText: (text) => Intl.NumberFormat().format(text),
                        },
                    ]}
                    dataSource={tableData}
                    search={false}
                    pagination={false}
                />
            </Row>
        </div>
    );
};

export default VideoStatsReporting;
