import {
    ModalForm,
    ProFormDigit,
    ProFormGroup,
    ProFormList,
    ProFormSelect,
    ProFormSwitch,
    ProFormText,
    ProFormTextArea
} from '@ant-design/pro-form';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosApiInstance from '../../../api/axiosClient';
import { GlobalStateContext } from '../../../context/GlobalContext';
import { useBusinessesManualQuery, useCategoriesManualQuery, useContactByIDQuery } from './queryHooks';
import { Button, Divider, Form, Input, InputRef, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const EditConnectionModal = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { contactsTableRef } = useContext(GlobalStateContext);
    const inputRef = useRef<InputRef>(null);
    const [form] = Form.useForm<API.CRM.Contact>();
    const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);

    const { refetch: fetchCategories } = useCategoriesManualQuery();
    const { refetch: fetchBusinesses } = useBusinessesManualQuery();
    const { data } = useContactByIDQuery(id);

    const addBusiness = async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        setIsInputDisabled(true);
        const newbusiness: Partial<API.CRM.Business> = {
            id: crypto.randomUUID(),
            business_name: inputRef.current.input.value
        };
        await axiosApiInstance
            .post('/api/connections/businesses/', newbusiness)
            .catch((reason) => console.error(reason));
        form.resetFields(['business_id']);
        setIsInputDisabled(false);
    };

    const addCategory = async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        setIsInputDisabled(true);
        const newcategory: Partial<API.CRM.ContactCategory> = {
            id: crypto.randomUUID(),
            name: inputRef.current.input.value
        };
        await axiosApiInstance
            .post('/api/connections/categories/', newcategory)
            .catch((reason) => console.error(reason));
        form.resetFields(['contact_category_id']);
        form.setFieldValue('contact_category_id', newcategory.id);
        setIsInputDisabled(false);
    };
    return (
        <ModalForm<API.CRM.Contact>
            title='Edit Contact'
            autoFocusFirstInput
            name='form'
            open
            width={1000}
            form={form}
            modalProps={{
                onCancel: () => navigate('/social/connections')
            }}
            request={async () =>
                await axiosApiInstance
                    .get(`/api/connections/contacts/${id}/`)
                    .then(({ data }) => data)
                    .catch((reason) => {
                        console.error(reason);
                        return {};
                    })
            }
            onFinish={async (values) => {
                const deleted_phones = data.phone_numbers.filter(
                    ({ id }) => !values.phone_numbers.map(({ id }) => id).includes(id)
                );
                const deleted_emails = data.emails.filter(
                    ({ id }) => !values.emails.map(({ id }) => id).includes(id)
                );
                const deleted_addresses = data.addresses.filter(
                    ({ id }) => !values.addresses.map(({ id }) => id).includes(id)
                );

                const delete_promises = [
                    ...deleted_phones.map(({ id }) =>
                        axiosApiInstance.delete(`/api/connections/phones/${id}/`)
                    ),
                    ...deleted_emails.map(({ id }) =>
                        axiosApiInstance.delete(`/api/connections/emails/${id}/`)
                    ),
                    ...deleted_addresses.map(({ id }) =>
                        axiosApiInstance.delete(`/api/connections/addresses/${id}/`)
                    )
                ];
                await Promise.all(delete_promises);

                const phones = values.phone_numbers;
                const emails = values.emails;
                const addresses = values.addresses;
                const contact: Partial<API.CRM.Contact> = {
                    id: values.id,
                    numeric_id: values.numeric_id,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    business_id: values.business_id,
                    contact_category_id: values.contact_category_id,
                    comment: values.comment
                };

                const put_promises = [
                    ...phones.map((phone) =>
                        axiosApiInstance.put(
                            `/api/connections/contacts/${id}/phones/${phone.id ?? crypto.randomUUID()}`,
                            phone
                        )
                    ),
                    ...emails.map((email) =>
                        axiosApiInstance.put(
                            `/api/connections/contacts/${id}/emails/${email.id ?? crypto.randomUUID()}`,
                            email
                        )
                    ),
                    ...addresses.map((address) =>
                        axiosApiInstance.put(
                            `/api/connections/contacts/${id}/addresses/${address.id ?? crypto.randomUUID()}`,
                            address
                        )
                    )
                ];
                await Promise.all(put_promises);

                await axiosApiInstance.put(`/api/connections/contacts/`, contact);

                contactsTableRef.current.reload();
                navigate('/social/connections');
            }}
        >
            <ProFormText name='id' fieldId='id' hidden />
            <ProFormGroup title='Personal Details'>
                <ProFormText width='xl' name='first_name' placeholder='First Name' />
                <ProFormText width='xl' name='last_name' placeholder='Last Name' />
            </ProFormGroup>
            <ProFormGroup>
                <ProFormSelect
                    width='md'
                    name='contact_category_id'
                    placeholder='Select Category'
                    fieldProps={{
                        dropdownRender: (menu) => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                    <Input
                                        placeholder='Enter New Category'
                                        ref={inputRef}
                                        disabled={isInputDisabled}
                                    />
                                    <Button
                                        type='text'
                                        icon={<PlusOutlined />}
                                        onClick={addCategory}
                                        disabled={isInputDisabled}
                                    >
                                        Add
                                    </Button>
                                </Space>
                            </>
                        )
                    }}
                    request={() =>
                        fetchCategories().then(({ data }) =>
                            data.map((datum) => ({
                                label: datum.name,
                                value: datum.id
                            }))
                        )
                    }
                />
                <ProFormSelect
                    width='md'
                    name='business_id'
                    placeholder='Select Business'
                    fieldProps={{
                        dropdownRender: (menu) => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                    <Input
                                        placeholder='Enter Business Name'
                                        ref={inputRef}
                                        disabled={isInputDisabled}
                                    />
                                    <Button
                                        type='text'
                                        icon={<PlusOutlined />}
                                        onClick={addBusiness}
                                        disabled={isInputDisabled}
                                    >
                                        Add
                                    </Button>
                                </Space>
                            </>
                        )
                    }}
                    request={() =>
                        fetchBusinesses().then(({ data }) =>
                            data.map((datum) => ({
                                label: datum.business_name,
                                value: datum.id
                            }))
                        )
                    }
                />
            </ProFormGroup>

            <ProFormGroup title='Phone Numbers' />
            <ProFormList
                name='phone_numbers'
                creatorButtonProps={{
                    creatorButtonText: 'New Phone Number',
                    size: 'small'
                }}
            >
                {(f, index, action) => {
                    return (
                        <ProFormGroup>
                            <ProFormText name='id' hidden />
                            <ProFormDigit name='numeric_id' hidden />
                            <ProFormText width='sm' name='number' placeholder='Phone Number' />
                            <ProFormText
                                width='md'
                                name='contact_name'
                                placeholder='Contact Name (Optional)'
                            />
                            <ProFormSwitch
                                name='is_primary'
                                tooltip='Make Primary?'
                                fieldProps={{
                                    onChange: (checked) => {
                                        form.setFieldsValue({
                                            phone_numbers: form
                                                .getFieldsValue()
                                                .phone_numbers.map((phone) => ({
                                                    ...phone,
                                                    is_primary: false
                                                }))
                                        });
                                        action.setCurrentRowData({
                                            is_primary: checked
                                        });
                                    }
                                }}
                            />
                        </ProFormGroup>
                    );
                }}
            </ProFormList>

            <ProFormGroup title='E-mails' />
            <ProFormList
                name='emails'
                creatorButtonProps={{ creatorButtonText: 'New E-mail', size: 'small' }}
            >
                {(f, index, action) => {
                    return (
                        <ProFormGroup>
                            <ProFormText name='id' hidden />
                            <ProFormText width='xl' name='email_address' placeholder='E-mail' />
                            <ProFormSwitch
                                name='is_primary'
                                tooltip='Make Primary?'
                                fieldProps={{
                                    onChange: (checked) => {
                                        form.setFieldsValue({
                                            emails: form
                                                .getFieldsValue()
                                                .emails.map((email) => ({ ...email, is_primary: false }))
                                        });
                                        action.setCurrentRowData({
                                            is_primary: checked
                                        });
                                    }
                                }}
                            />
                        </ProFormGroup>
                    );
                }}
            </ProFormList>
            <ProFormGroup title='Addresses' />
            <ProFormList
                name='addresses'
                creatorButtonProps={{ creatorButtonText: 'New Address', size: 'small' }}
            >
                {(f, index, action) => {
                    return (
                        <ProFormGroup>
                            <ProFormText name='id' hidden />
                            <ProFormText width='sm' name='street' placeholder='Street' />
                            <ProFormText width='sm' name='area' placeholder='Area' />
                            <ProFormText width='xs' name='area_code' placeholder='ZIP Code' />
                            <ProFormText width='xs' name='city' placeholder='City' />
                            <ProFormSwitch
                                name='is_primary'
                                tooltip='Make Primary?'
                                fieldProps={{
                                    onChange: (checked) => {
                                        form.setFieldsValue({
                                            addresses: form.getFieldsValue().addresses.map((address) => ({
                                                ...address,
                                                is_primary: false
                                            }))
                                        });
                                        action.setCurrentRowData({
                                            is_primary: checked
                                        });
                                    }
                                }}
                            />
                        </ProFormGroup>
                    );
                }}
            </ProFormList>
            <ProFormGroup title='Notes' />
            <ProFormTextArea placeholder='...Enter your comments here' name='comment' />
        </ModalForm>
    );
};

export default EditConnectionModal;
