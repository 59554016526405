import React, {Component} from 'react';
import './index.css';
import type {ProColumns} from '@ant-design/pro-table';
import {EditableProTable} from '@ant-design/pro-table';
// import ProCard from '@ant-design/pro-card';
import {Button, ConfigProvider, notification, Popconfirm, Row, Space, Spin, Typography} from 'antd';
// import { ProFormField } from '@ant-design/pro-form';
import enUSIntl from 'antd/lib/locale/en_US';
import {postRequest} from '../../../api/postRequest';

export interface ICustomerReportTableProps {
    //Here we pass the Props Interface
}

export interface ICustomerReportTableState {
    //here we pass the State Interface

    editableKeys: any,
    dataSource: DataSourceType[]
    loading: boolean
    changedRecordKeys: React.Key[]
    saving: boolean
    rebuilding: boolean
}

type DataSourceType = {
    customer_id: number
    full_name: string
    prev_balance: number
    admin_fees: number
    granted_affiliate_revenue: number
    prev_remaining_downpayment: number
    downpayment_reduction: number
    earnings: number
    threshold: number
    notes: string
    new_balance: number
    new_remaining_downpayment: number
    default_payment: number
    default_to_be_paid: boolean
    final_payment: number
    final_to_be_paid: boolean
    payment_cycle: string
    execution_date: string
    payment_option: number
    payment_transaction_id: string
};

// const defaultData: DataSourceType[] = new Array(20).fill(1).map((_, index) => {
// 	return {
// 		customer_id: (Date.now() + index).toString(),
// 		current_earnings: 0,
// 		threshold_to_pay: 0,
// 		calculated_amount_to_pay: 0,
// 		amount_to_pay: 0,
// 		to_be_paid: true,
// 	};
// });


class CustomerReportTable extends Component<ICustomerReportTableProps, ICustomerReportTableState> {
    constructor(props: ICustomerReportTableProps) {
        super(props)

        this.state = {
            // editableKeys: defaultData.map((item) => item.customer_id),
            editableKeys: [],
            // dataSource: defaultData,
            dataSource: [],
            loading: false,
            changedRecordKeys: [],
            saving: false,
            rebuilding: false
        }

    }

    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getData()

    }

    getData = async () => {
        this.setState({loading: true})

        const res = await postRequest('/api/erp/newcycle/constructpaymentsreport')
        let data = res.data.result || []

        console.log(data);

        for (const item of data) {
            item.default_to_be_paid = item.default_to_be_paid === '1'
            item.final_to_be_paid = item.final_to_be_paid === '1'
            // item.key = (Math.random() + 1).toString(36).substring(7);
            // item.key = item.customer_id + '_' + Date.now()
        }
        this.setState({
            dataSource: data,
            editableKeys: data.map((item: DataSourceType) => item.customer_id),
            loading: false
        })
    }

    rebuildReport = async () => {
        this.setState({loading: true, rebuilding: true})

        // console.log(this.state.dataSource);
        const res = await postRequest('/api/erp/newcycle/forcebuildpaymentsreport')

        await this.getData()

        this.setState({loading: false, rebuilding: false})

    }

    resetDefaults = async () => {
        this.setState({loading: true, rebuilding: true})
        // this.setState({ loading: true })

        // console.log(this.state.dataSource);
        const res = await postRequest('/api/erp/newcycle/resetreportdefaults')

        await this.getData()

        // this.setState({ loading: false })
        this.setState({loading: false, rebuilding: false})

    }

    saveChangesToDB = async () => {
        // console.log('changedRecordKeys', this.state.changedRecordKeys);

        if (this.state.changedRecordKeys.length === 0) {
            notification.info({message: 'No changes were made.'})
            return
        }


        this.setState({saving: true})

        for (const record of this.state.dataSource) {
            if (!this.state.changedRecordKeys.includes(record.customer_id)) continue
            let body = {
                customer_id: record.customer_id, final_payment: record.final_to_be_paid ? record.final_payment : 0,
                final_to_be_paid: record.final_to_be_paid === true ? '1' : '0',
                admin_fees: record.admin_fees, downpayment_reduction: record.downpayment_reduction, notes: record.notes
            }
            const res = await postRequest('/api/erp/newcycle/updatepaymentsreportrow', body)
        }

        notification.success({message: 'Successfully saved ' + this.state.changedRecordKeys.length + ' changed records to the database.'})

        this.setState({saving: false, changedRecordKeys: []})

        await this.getData()
    }

    columns: ProColumns<DataSourceType>[] = [
        {title: 'Customer id', dataIndex: 'customer_id', editable: false},
        {title: 'Full name', dataIndex: 'full_name', editable: false},
        {title: 'Previous balance', dataIndex: 'prev_balance', editable: false},
        {title: 'Earnings', dataIndex: 'earnings', editable: false},
        {title: 'Affiliate revenue', dataIndex: 'granted_affiliate_revenue', editable: false},
        {title: 'Admin fees', dataIndex: 'admin_fees', valueType: 'digit'},
        // { title: 'prev_remaining_downpayment', dataIndex: 'prev_remaining_downpayment', editable: false },
        // { title: 'downpayment_reduction', dataIndex: 'downpayment_reduction', valueType: 'digit' },
        {title: 'Threshold', dataIndex: 'threshold', editable: false},
        // { title: 'new_remaining_downpayment', dataIndex: 'new_remaining_downpayment', editable: false },
        // { title: 'Default payment', dataIndex: 'default_payment', editable: false },
        // { title: 'Default paid', dataIndex: 'default_to_be_paid', editable: false, render: (_, entity) => entity.default_to_be_paid ? 'YES' : 'NO' },
        // { title: 'Final payment', dataIndex: 'final_payment', valueType: 'digit' },// impl below

        {
            title: 'Final payment',
            dataIndex: 'final_payment',
            valueType: 'digit',
            fieldProps: (from:any, {rowKey, rowIndex}:any) => {
                if (this.state.dataSource[rowIndex].final_to_be_paid === false) {
                    return {disabled: true, allowClear: false};
                }
                return {disabled: false, allowClear: false};
            }
        },
        {title: 'Final Paid', dataIndex: 'final_to_be_paid', valueType: 'switch'},
        {title: 'New (final) balance', dataIndex: 'new_balance', editable: false},
        // { title: 'payment_cycle', dataIndex: 'payment_cycle', editable: false },
        // { title: 'execution_date', dataIndex: 'execution_date', editable: false },
        // { title: 'payment_option', dataIndex: 'payment_option', editable: false },
        // { title: 'payment_transaction_id', dataIndex: 'payment_transaction_id', editable: false },
        {
            title: 'Notes',
            dataIndex: 'notes',
            valueType: 'textarea',
            fieldProps: (from:any, {rowKey, rowIndex}:any) => ({maxLength: 250, showCount: false, rows: 1})
        },
    ];

    //Add style here
    style = {
        width: '100%'
    };

    render() {
        return (
            <div className="CustomerReportTable" style={this.style}>
                <Row justify='space-between' style={{padding: 16}}>
                    <Typography.Title level={5}>Customer Payments Report</Typography.Title>
                    <Space>
                        <Popconfirm title={'Are you sure? This will discard all changes.'}
                                    onConfirm={this.rebuildReport}>
                            <Button
                                type="default"
                                key="save"
                                danger
                                // onClick={() => {}}
                            >
                                Reconstruct report
                            </Button>
                        </Popconfirm>
                        <Popconfirm
                            title={'Are you sure? This will set the final fields to values of the default fields.'}
                            onConfirm={this.resetDefaults}>
                            <Button
                                type="default"
                                key="save"
                            >
                                Reset defaults
                            </Button>
                        </Popconfirm>
                        <Button
                            type="primary"
                            key="save"
                            loading={this.state.saving}
                            onClick={() => {
                                // dataSource is the current data, you can call the api to save it
                                this.saveChangesToDB()
                                console.log(this.state.dataSource);
                            }}
                        >
                            Save changes
                        </Button>
                    </Space>
                </Row>
                {this.state.rebuilding ? <Spin style={this.style} tip='Building report...'/> :
                    <ConfigProvider locale={enUSIntl}>
                        <EditableProTable<DataSourceType>

                            // headerTitle="Editable table"
                            loading={this.state.loading || this.state.saving}
                            columns={this.columns}
                            rowKey="customer_id"
                            // rowKey="id"
                            value={this.state.dataSource}
                            onChange={(dataSource) => this.setState({dataSource: dataSource as any})}
                            // recordCreatorProps={{
                            // 	newRecordType: 'dataSource',
                            // 	record: () => ({
                            // 		customer_id: Date.now(),
                            // 	}),
                            // }}
                            recordCreatorProps={false}
                            // pagination={{}}
                            pagination={{pageSize: 6}}
                            // toolBarRender={() => {
                            // 	return [];
                            // }}
                            editable={{
                                type: 'multiple',
                                editableKeys: this.state.editableKeys,
                                // actionRender: (row, config, defaultDoms) => {
                                // 	return [defaultDoms.delete];
                                // },
                                onValuesChange: (record, recordList) => {
                                    if (!this.state.changedRecordKeys.includes(record.customer_id))
                                        this.setState({changedRecordKeys: [...this.state.changedRecordKeys, record.customer_id]})
                                    this.setState({dataSource: recordList})
                                },
                                onChange: (editableKeys) => this.setState({editableKeys}),
                            }}
                            bordered
                        />
                    </ConfigProvider>
                }


            </div>
        )
    }


}

export default CustomerReportTable;