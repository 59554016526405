import {Alert, Button, Card, message, Row} from 'antd';
import Title from 'antd/lib/typography/Title';
import React, {Component} from 'react';
import './index.css';
import {postRequest} from "../../../api/postRequest";

export interface IInitiateStepProps {
    //Here we pass the Props Interface
    handler?: Function,
    year: number,
    id: number,
    type_cycle: number,
}

export interface IInitiateStepState {
    //here we pass the State Interface
    year: number,
    id: number,
    type_cycle: number,
    proceed: boolean,
}

interface InitiateBilingCycleResponse {
    id: number,
    year: number,
    type_cycle: number,
}


const FINAL_STEP_NUMBER = 6

//class ComponentName Component<PropsInterface, StateInterface>
class InitiateStep extends Component<IInitiateStepProps, IInitiateStepState> {

    constructor(props: IInitiateStepProps) {
        super(props);

        //CHECK LAST AVAILABLE CYCLE
        this.state = {
            year: this.props.year,
            id: this.props.id,
            type_cycle: this.props.type_cycle,
            proceed: false
        }

    }

    initiateCycle() {
        if ((this.state.type_cycle === 0 && this.state.id === 0 && this.state.year === 0) || (this.state.type_cycle === FINAL_STEP_NUMBER)) {

            const curYear = Number(new Date().getFullYear())
            postRequest(`/api/erp/newcycle/initiate`, {
                id: this.state.id + 1,
                year: curYear
            })
                .then((response) => {
                    // console.log(response);
                    if ('error' in response.data) {
                        message.error('Error: ' + response.data.error)
                        return
                    }

                    var result = response.data.status as InitiateBilingCycleResponse

                    if (result.type_cycle == -1 || result.id == -1 && result.year == -1) {
                        message.error('Error: empty year/id request issued')
                        return
                    }

                    // this.setState({ id: res_id, year: res_year, type_cycle: res_status })
                    this.setState({id: result.id, year: result.year, type_cycle: result.type_cycle})
                    window.location.reload();

                }).catch(function (error) {
                console.log(error);
            });
        }


    }


    // Before the component mounts, we initialise our state
    componentWillMount() {


    }

    // After the component did mount, we set the state.
    componentDidMount() {
        console.log('/initiate');
    }

    render() {

        return (
            <div>
                {/* <Row justify="center">
					<Title level={3}>Initiate Step</Title>
				</Row> */}

                <br/>
                <Alert type='info' showIcon message='Click on Initiate New Cycle to initiate a new billing cycle'
                       closable/>
                <br/>

                <Row justify='center'>
                    <Card style={{padding: '5px 30px 5px 30px'}}>
                        {(this.state.type_cycle === 0 && this.state.id === 0 && this.state.year === 0) || (this.state.type_cycle === FINAL_STEP_NUMBER) ?
                            <>
                                {/* <Row justify='center'> */}
                                <Row justify='center'>
                                    <p>
                                        <Title level={5}>
                                            Last Cycle
                                            Id: {this.state.year !== 0 && this.state.id !== 0 ? this.state.year + "_" + this.state.id : '-'}
                                        </Title>
                                    </p>
                                </Row>
                                <Row justify='center'>
                                    <p>
                                        <Title level={5}>
                                            Next Cycle Id: <b>{new Date().getFullYear()}_{this.state.id + 1}</b>
                                            {/* Next Cycle Id: {new Date().getFullYear()}_{this.state.id + 1} */}
                                        </Title>
                                    </p>
                                </Row>
                                <Row justify='center'>
                                    <p>
                                        <Button size='large' type={"primary"} onClick={() => this.initiateCycle()}>Initiate
                                            New Cycle</Button>
                                    </p>
                                </Row>
                                {/* </Row> */}
                            </> :
                            <>
                                <p>
                                    Cycle <b>({this.state.year + "_" + this.state.id})</b> is already Initialised
                                    Proceed to next step...
                                </p>
                                <Button type={"primary"} onClick={() => window.location.reload()}>Load Data
                                    Step</Button>
                            </>}
                    </Card>
                </Row>

                <Row>

                </Row>
            </div>
        );
    }
}

export default InitiateStep;