import ProDescriptions from '@ant-design/pro-descriptions';
import { Descriptions, List, Row, Space, Table, Typography } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosApiInstance from '../../../api/axiosClient';
import { HomeOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

type ContactItemListProps = {
    dataSource: string[];
    icon: JSX.Element;
    headerTitle: string;
};

const ContactItemList = ({ dataSource, icon, headerTitle }: ContactItemListProps) =>
    dataSource.length === 0 ? (
        <></>
    ) : (
        <div
            style={{
                maxHeight: 160,
                overflowY: 'auto'
            }}
        >
            <List
                size='small'
                header={
                    <Space>
                        {icon}
                        <Typography.Text strong>{headerTitle}</Typography.Text>
                    </Space>
                }
                style={{
                    width: 400
                }}
                bordered
                dataSource={dataSource}
                renderItem={(item) => <List.Item>{item}</List.Item>}
            />
        </div>
    );

const ViewConnectionModal = () => {
    const navigate = useNavigate();
    const params = useParams();
    return (
        <Modal
            width={600}
            title='Info'
            open
            onOk={() => navigate('/social/connections')}
            onCancel={() => navigate('/social/connections')}
        >
            <ProDescriptions<API.CRM.Contact>
                contentStyle={{
                    justifyContent: 'center'
                }}
                request={async () =>
                    await axiosApiInstance
                        .get<API.CRM.Contact>(`/api/connections/contacts/${params.id}/`)
                        .then((response) => ({
                            data: response.data,
                            success: response.status === 200
                        }))
                        .catch((reason) => {
                            console.error(reason);
                            return {
                                data: {},
                                success: true
                            };
                        })
                }
            >
                <ProDescriptions.Item span={24} label='Name' dataIndex='full_name' />
                <ProDescriptions.Item label='Category' dataIndex={['contact_category', 'name']} />
                <ProDescriptions.Item label='Business' dataIndex={['business', 'business_name']} />
                <ProDescriptions.Item span={24} label='Comments' dataIndex={'comment'} />
                <ProDescriptions.Item
                    span={24}
                    dataIndex={'phone_numbers'}
                    render={(dom) => {
                        return (
                            <ContactItemList
                                dataSource={(dom as Array<API.CRM.PhoneNumber>).map(
                                    (phone) =>
                                        `${phone.number}` +
                                        (phone.contact_name !== '-' ? ` - ${phone.contact_name}` : '')
                                )}
                                icon={<PhoneOutlined />}
                                headerTitle='Phone Numbers'
                            />
                        );
                    }}
                />
                <ProDescriptions.Item
                    span={24}
                    dataIndex={'emails'}
                    render={(dom) => {
                        return (
                            <ContactItemList
                                dataSource={(dom as Array<API.CRM.Email>).map((email) => email.email_address)}
                                icon={<MailOutlined />}
                                headerTitle='E-Mails'
                            />
                        );
                    }}
                />
                <ProDescriptions.Item
                    span={24}
                    dataIndex={'addresses'}
                    render={(dom) => {
                        return (
                            <ContactItemList
                                dataSource={(dom as Array<API.CRM.Address>).map(
                                    (address) => address.full_address
                                )}
                                icon={<HomeOutlined />}
                                headerTitle='Addresses'
                            />
                        );
                    }}
                />
            </ProDescriptions>
        </Modal>
    );
};

export default ViewConnectionModal;
