import React, {Component} from 'react';
// import './index.css';
import {postRequest} from '../../../api/postRequest';
import ProForm, {
    ProFormDateRangePicker,
    ProFormField,
    ProFormGroup,
    ProFormRadio,
    ProFormSelect
} from '@ant-design/pro-form';
// import {enUSIntl} from "@ant-design/pro-provider";
import {
    Alert,
    Button,
    ConfigProvider,
    FormInstance,
    notification,
    Row,
    Space,
    Spin,
    Switch,
    Tooltip,
    Typography
} from 'antd';
import enUSIntl from 'antd/lib/locale/en_US';
import {Column, getCanvasPattern} from '@ant-design/plots';
// import moment from 'moment';
import dayjs from "dayjs";
import {addAlpha, capitalize, roundNum, shadeColor} from '../../../common/utils';
import {DownloadOutlined, SearchOutlined} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import {deepMix, each, groupBy} from '@antv/util';
import {buildGraphData, graphData, makeGraphConfigDataArray} from './graphConfigBuilder';
import {buildTotals} from './totalsBuilder';
import {PageContainer} from "@ant-design/pro-components";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(quarterOfYear)
dayjs.extend(customParseFormat)

export interface IReportingChartsEngineProps {
    //Here we pass the Props Interface
    // children?: React.ReactNode;
    // breadcrumpLinks?: string[];
    // breadcrumpTitles?: any[];F
}

export interface IReportingChartsEngineState {
    //here we pass the State Interface
    graphConfig: graphData | null;
    totals: any;
    loading: boolean;
    formRef: React.RefObject<FormInstance>;
    showTotalsAnnotations: boolean;
    chartRef?: any;
    currentData?: any;
    annotations?: any;
    inputType: string;
    availableTags: Array<any>;
    categoryValues: Array<any>;
    inputHasChanged: boolean;
}

const PATTERN_MAP = [
    // {type: 'line',},
    {
        type: 'square',
        cfg: {
            size: 8,
            padding: 1,
            rotation: 45,
            isStagger: false,
        },
    },
    {},
    // {type: 'square',},
    {type: 'dot'},
];

const PATTERN_MAP_LEGEND = [
    // '▧',
    '▩',
    '■',
    // '▦',
    '◙',
];

const pattern = ({dateRangeChronologicalOrder}: any, color: string) => {
    // console.log(dateRangeChronologicalOrder)
    return getCanvasPattern(
        deepMix({}, PATTERN_MAP[dateRangeChronologicalOrder], {
            cfg: {
                backgroundColor: color,
            },
        })
    );
};

//class ComponentName Component<PropsInterface, StateInterface>
class ReportingChartsEngine extends Component<IReportingChartsEngineProps, IReportingChartsEngineState> {
    //Component State
    state = {
        graphConfig: null,
        totals: null,
        loading: false,
        formRef: React.createRef<FormInstance>(),
        showTotalsAnnotations: true,
        chartRef: React.createRef(),
        currentData: null,
        annotations: null,
        inputType: 'cms',
        availableTags: [],
        categoryValues: [],
        inputHasChanged: false,
    };

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    //   componentWillMount() {}

    // After the component did mount, we set the state.
    componentDidMount() {
        this.state.formRef.current?.setFields([{
            name: ['cms_list'],
            value: ['xcDgV1hLEnOLtNvdGppirg', 'y8Sek62JbLMqQ2rx2TXgpg']
        }]);
        this.getTags();
        this.getCategories();
        this.fetchGraphData(this.state.formRef.current?.getFieldsValue());
    }

    getTags = async () => {
        try {
            const response = await postRequest('/api/tags/getcategoryvaluesdrawer');
            this.setState({availableTags: response.data || []});
            // const tagValues = this.state.availableTags.map((tag: any) => ({ label: tag.value, value: tag.id }));
            console.log(this.state.availableTags.filter((tag) => tag.category_id === 1));
            // console.log(this.state.formRef.current?.getFieldValue('input_type'));
        } catch (e: any) {
            notification.error({message: e.response.data.message});
        }
    };

    getCategories = async () => {
        try {
            const response = await postRequest('/api/tags/getcategories');
            this.setState({categoryValues: response.data || []});
            console.log(this.state.categoryValues);
        } catch (e: any) {
            notification.error({message: e.response.data.message});
        }
    };

    makeTagDropdownMenu = () => {
        const categories = this.state.categoryValues;
        const tags = this.state.availableTags;

        return categories
            .filter((category) => category.id !== 6)
            .map((category: any) => ({
                label: category.category_name,
                value: category.id,
                optionType: 'optGroup',
                options: tags.filter((tag) => tag.category_id === category.id).map((tag: any) => ({
                    label: tag.value,
                    value: tag.id,
                    id: tag.id
                })),
            }));
    };

    fetchGraphData = async (values: any) => {
        this.setState({loading: true, graphConfig: null});

        const postDataObject = values;
        postDataObject['date_range_1'] = [
            dayjs(postDataObject['date_range_1'][0]).format('YYYY-MM-DD'),
            dayjs(postDataObject['date_range_1'][1]).format('YYYY-MM-DD'),
        ];
        postDataObject['date_range_2'] = [
            dayjs(postDataObject['date_range_2'][0]).format('YYYY-MM-DD'),
            dayjs(postDataObject['date_range_2'][1]).format('YYYY-MM-DD'),
        ];

        const requestTemplate = {
            elements: values.cms_list.map((elem: any) => elem.toString()),
            query_by: values.input_type,
            metric: values.metric,
            dimension: 'day',
            currency: 'EUR',
        };

        const defaultParams = {
            metric: values.metric,
            group_by: values.group_by,
            round_unit: values.round_unit,
            tagValues: values.input_type === 'tags' ? this.state.availableTags : undefined,
        };

        const fetchViewsForDateRange = async (dateRange: string[]) => {
            if (values.input_type === 'cms') {
                try {
                    const a = postRequest(
                        '/api/graphsReporting/fetch',
                        {
                            ...requestTemplate,
                            date_range: dateRange,
                            filters: 'uploaderType==self',
                        },
                        false
                    );
                    const b = postRequest(
                        '/api/graphsReporting/fetch',
                        {
                            ...requestTemplate,
                            date_range: dateRange,
                            filters: 'uploaderType==thirdParty;claimedStatus==claimed',
                        },
                        false
                    );
                    const [res1, res2] = await Promise.all([a, b]);
                    return [res1.data || [], res2.data || []];
                } catch (e: any) {
                    console.error(e);
                    throw e;
                }
            }

            // TODO: Tidy up
            if (values.input_type === 'tags') {
                try {
                    const a = postRequest(
                        '/api/graphsReporting/fetch',
                        {
                            ...requestTemplate,
                            date_range: dateRange,
                            filters: 'uploaderType==self',
                        },
                        false
                    );
                    const [res1] = await Promise.all([a]);
                    return [res1.data || [], undefined];
                } catch (e: any) {
                    console.error(e);
                    throw e;
                }
            }
        };

        const fetchRevenueForDateRange = async (dateRange: string[]) => {
            try {
                const req = await postRequest(
                    '/api/graphsReporting/fetch',
                    {
                        ...requestTemplate,
                        date_range: dateRange,
                        filters: 'claimedStatus==claimed',
                    },
                    false
                );
                return req.data;
            } catch (e) {
                console.error(e);
                throw e;
            }
        };

        let data1: Array<any> = [];
        let data2: Array<any> = [];

        const datesInOrder: boolean = dayjs(values.date_range_2[1], 'YYYY-MM-DD').diff(dayjs(values.date_range_1[1], 'YYYY-MM-DD')) > 0;

        const firstDateRange = datesInOrder ? values.date_range_1 : values.date_range_2;
        const secondDateRange = datesInOrder ? values.date_range_2 : values.date_range_1;

        try {
            if (values.metric === 'views') {
                const get1 = fetchViewsForDateRange(firstDateRange);
                const get2 = fetchViewsForDateRange(secondDateRange);
                const [[selfUploaded1, thirdParty1], [selfUploaded2, thirdParty2]] = await Promise.all([get1, get2]);

                data1 = makeGraphConfigDataArray(
                    {
                        ...defaultParams,
                        date_range: firstDateRange,
                        topologicalOrder: 0,
                        chronologicalOrder: 0,
                    },
                    selfUploaded1,
                    thirdParty1
                );

                data2 = makeGraphConfigDataArray(
                    {
                        ...defaultParams,
                        date_range: secondDateRange,
                        topologicalOrder: 1,
                        chronologicalOrder: 1,
                    },
                    selfUploaded2,
                    thirdParty2
                );
            }
            if (values.metric === 'estimatedRevenue') {
                const get1 = fetchRevenueForDateRange(firstDateRange);
                const get2 = fetchRevenueForDateRange(secondDateRange);
                const [res1, res2] = await Promise.all([get1, get2]);

                data1 = makeGraphConfigDataArray(
                    {
                        ...defaultParams,
                        date_range: firstDateRange,
                        topologicalOrder: 0,
                        chronologicalOrder: 0,
                    },
                    res1
                );

                data2 = makeGraphConfigDataArray(
                    {
                        ...defaultParams,
                        date_range: secondDateRange,
                        topologicalOrder: 1,
                        chronologicalOrder: 1,
                    },
                    res2
                );
            }
            this.setState({
                graphConfig: buildGraphData(data1, data2),
                totals: buildTotals([data1, data2]),
                loading: false,
            });
            this.setAnnotations();
        } catch (e: any) {
            notification.error({message: 'Error'});
            console.log(e);
            this.setState({loading: false});
            return;
        }
    };

    setAnnotations = () => {
        const annotations: any = [];
        let filteredData: any = this.state.graphConfig.data;
        // console.log("here, data: ", filteredData)

        // if (this.state.chartRef.current) {
        //   filteredData = (this.state.chartRef.current as any).chart.filteredData;
        //   console.log('here00');
        // } else if (this.state.graphConfig) {
        //   filteredData = (this.state.graphConfig as any).data;
        //   console.log('here11');
        // }
        if (filteredData) {
            // console.log('here2', filteredData);
            each(
                groupBy(filteredData, (item: any) => {
                    return item['timeUnitIndexAlpha'] + '_' + item['dateRange'];
                }),
                (values, k) => {
                    let value = values.reduce((a: any, b: any) => a + (b.value || 0), 0);
                    value = roundNum(value, 2);
                    // console.log('{values}, k}', { values }, k);

                    const numAnnotations = (this.state.graphConfig as any).data.length / values.length;

                    let xPercent = 0.9;
                    if (values[0].dateRangeChronologicalOrder == 0) {
                        xPercent = -32 / numAnnotations;
                    } else {
                        xPercent = 32 / numAnnotations;
                    }
                    annotations.push({
                        type: 'text',
                        // position: {timeUnitIndexAlpha: k.split('_')[0], dateRange: k.split('_')[1]},
                        position: (xScale: any, yScale: any) => {
                            return [`${xScale.scale(k.split('_')[0]) * 100 + xPercent}%`, `${(1 - yScale.value.scale(value)) * 100}%`];
                        },
                        content: `${value.toLocaleString('el-GR')}`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)',
                        },
                        offsetY: -14,
                    });
                }
            );
        }
        this.setState({annotations});
    };

    onCloseFetchData = () => {
        const value = this.state.formRef.current?.getFieldValue('cms_list');
        if (value.length !== 0) {
            this.fetchGraphData(this.state.formRef.current?.getFieldsValue());
        }
        this.setState({inputHasChanged: false});
    };

    render() {
        return (
            <div className='ReportingChartsEngine' style={this.style}>
                {/*<MainLayout breadcrumpLinks={} >*/}
                <PageContainer>
                    {/*<Title>Charts Engine (placeholder)</Title>*/}
                    <Typography.Title level={2}>Charts Engine</Typography.Title>

                    <Alert
                        message={<Text>Welcome to the Charts Engine. Fill the form below. By clicking on Query, get your
                            comparative graph in seconds.</Text>}
                        type='info'
                        closable
                    />

                    <br/>

                    {/*    FORM     */}
                    <ConfigProvider locale={enUSIntl}>
                        <ProForm
                            onFinish={async (values) => {
                                this.fetchGraphData(values);
                                // console.log(values);
                            }}
                            onSubmitCapture={(e) => console.log(e)}
                            formRef={this.state.formRef as any}
                            submitter={{
                                // resetButtonProps: {
                                //     style: {
                                //         // Hide the reset button
                                //         display: 'none',
                                //     },
                                // },
                                // searchConfig: {
                                //     resetText: "Reset",
                                //     submitText: 'Submit'
                                // },
                                render: false,
                                // render: true
                            }}
                            validateMessages={{required: 'This field is required'}}
                            layout='vertical'
                            labelCol={{span: 0, offset: 40}}
                            // layout='horizontal'
                            // layout='inline'
                            // size='middle'
                            // size='small'
                            size='large'
                            initialValues={{
                                metric: 'views',
                                // "cms_list": ["xcDgV1hLEnOLtNvdGppirg", "ZRcggOuStL2Jbwqu0TqgYA"],
                                //cms_list: ['xcDgV1hLEnOLtNvdGppirg', 'y8Sek62JbLMqQ2rx2TXgpg'],
                                cms_list: [],
                                input_type: 'cms',
                                // "date_range_1": ["2021-12-01", "2021-12-31"],
                                // "date_range_1": ["2020-01-01", "2020-12-31"],
                                date_range_1: [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
                                // "date_range_2": ["2022-02-01", "2022-02-28"],
                                // "date_range_2": ["2021-01-01", "2021-12-31"],
                                date_range_2: [dayjs().subtract(2, 'years').startOf('year'), dayjs().subtract(2, 'years').endOf('year')],
                                // "group_by": "week"
                                group_by: 'quarter',
                                // "round_unit": "M"
                                round_unit: 'B',
                            }}>
                            <ProFormGroup>
                                <div style={{width: 140}}>
                                    <ProFormRadio.Group
                                        name='input_type'
                                        label='Input option'
                                        radioType='button'
                                        fieldProps={{
                                            buttonStyle: 'solid',
                                            defaultValue: 'cms',
                                            onChange: (e) => {
                                                this.state.formRef.current?.resetFields(['cms_list']);
                                                this.setState({inputType: e.target.value, graphConfig: null});
                                            },
                                        }}
                                        options={[
                                            {
                                                label: 'CMS',
                                                value: 'cms',
                                            },
                                            {
                                                label: 'Tags',
                                                value: 'tags',
                                            },
                                        ]}
                                    />
                                </div>

                                <div style={{width: 180}}>
                                    <ProFormSelect
                                        name='cms_list'
                                        label={this.state.inputType === 'cms' ? 'CMS' : 'Tags'}
                                        options={
                                            this.state.inputType === 'cms'
                                                ? [
                                                    {label: 'Srav', value: 'y8Sek62JbLMqQ2rx2TXgpg'},
                                                    {label: 'LTD', value: 'Obm3Siph0b-6wTgyPbtJZw'},
                                                    {label: 'LTD M', value: 'xcDgV1hLEnOLtNvdGppirg'},
                                                    {
                                                        label: 'Entertainment M',
                                                        value: 'ZRcggOuStL2Jbwqu0TqgYA',
                                                    },
                                                    {label: 'Entertainment', value: '_amtO2sTzzw2cuVqaxQ2Kg'},
                                                    {label: 'S', value: 'idnX3ho04dWOzhWsooMvHw'},
                                                    {label: 'Music', value: 'nQT84PQuawtnFBF5XIWixQ'},
                                                    {label: 'Live', value: 'BFEqhq9k4jWPkvgaLq_Qug'},
                                                ]
                                                : this.makeTagDropdownMenu() // this.state.availableTags.map((tag: any) => ({ label: tag.value, value: tag.id }))
                                        }
                                        // initialValue={['xcDgV1hLEnOLtNvdGppirg']}
                                        fieldProps={{
                                            mode: 'tags',
                                            maxTagCount: 'responsive',
                                            width: '200',
                                            // TODO: Implement without re-rendering the graph
                                            // onChange: () => {
                                            //   this.setState({ inputHasChanged: true });
                                            // },
                                            // onDropdownVisibleChange: (open) => {
                                            //   !open && this.state.inputHasChanged && this.onCloseFetchData();
                                            // },
                                        }}
                                        rules={[{required: true}]}
                                    />
                                </div>

                                {/* <ProFormSelect name='whtv' label='whtv' options={this.makeTagDropdownMenu()} /> */}

                                <ProFormDateRangePicker
                                    name='date_range_1'
                                    label='Period 1'
                                    rules={[{required: true}]}
                                    fieldProps={{
                                        ranges: {
                                            // Today: [moment(), moment()],
                                            // 'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            // 'This Week': [moment().startOf('week'), moment().endOf('week')],
                                            // 'This Quarter': [moment().startOf('year'), moment().endOf('quarter')],
                                            // 'This Year': [moment().startOf('year'), moment().endOf('year')],
                                            // month
                                            [dayjs().format('MMMM YY')]: [dayjs().startOf('month'), dayjs().endOf('month')],
                                            // month - 1
                                            [dayjs().subtract(1, 'month').format('MMMM YY')]: [
                                                dayjs().subtract(1, 'month').startOf('month'),
                                                dayjs().subtract(1, 'month').endOf('month'),
                                            ],
                                            // month - 2
                                            [dayjs().subtract(2, 'month').format('MMMM YY')]: [
                                                dayjs().subtract(2, 'month').startOf('month'),
                                                dayjs().subtract(2, 'month').endOf('month'),
                                            ],
                                            // month - 3
                                            // [moment().subtract(3, 'month').format('MMMM YY')]: [moment().subtract(3, 'month').startOf('month'), moment().subtract(3, 'month').endOf('month')],
                                            // quarter - 1
                                            [dayjs().subtract(1, 'quarter').format('[Q]Q YYYY')]: [
                                                dayjs().subtract(1, 'quarter').startOf('quarter'),
                                                dayjs().subtract(1, 'quarter').endOf('quarter'),
                                            ],
                                            // quarter - 2
                                            [dayjs().subtract(2, 'quarter').format('[Q]Q YYYY')]: [
                                                dayjs().subtract(2, 'quarter').startOf('quarter'),
                                                dayjs().subtract(2, 'quarter').endOf('quarter'),
                                            ],
                                            // previous month - 1 year
                                            [dayjs().subtract(1, 'month').subtract(1, 'year').format('MMMM YY')]: [
                                                dayjs().subtract(1, 'month').subtract(1, 'year').startOf('month'),
                                                dayjs().subtract(1, 'month').subtract(1, 'year').endOf('month'),
                                            ],
                                            // quarter - 1 year
                                            [dayjs().subtract(1, 'quarter').subtract(1, 'year').format('[Q]Q YYYY')]: [
                                                dayjs().subtract(1, 'quarter').subtract(1, 'year').startOf('quarter'),
                                                dayjs().subtract(1, 'quarter').subtract(1, 'year').endOf('quarter'),
                                            ],
                                            // year
                                            [dayjs().startOf('year').format('YYYY')]: [dayjs().startOf('year'), dayjs().endOf('year')],
                                            // year - 1
                                            [dayjs().subtract(1, 'year').startOf('year').format('YYYY')]: [
                                                dayjs().subtract(1, 'year').startOf('year'),
                                                dayjs().subtract(1, 'year').endOf('year'),
                                            ],
                                            // year - 2
                                            [dayjs().subtract(2, 'year').startOf('year').format('YYYY')]: [
                                                dayjs().subtract(2, 'year').startOf('year'),
                                                dayjs().subtract(2, 'year').endOf('year'),
                                            ],
                                            // month - 1 year
                                            [dayjs().subtract(1, 'year').startOf('month').format('MMMM YY')]: [
                                                dayjs().subtract(1, 'year').startOf('month'),
                                                dayjs().subtract(1, 'year').endOf('month'),
                                            ],
                                        },
                                    }}
                                />
                                <ProFormDateRangePicker
                                    name='date_range_2'
                                    label='Period 2'
                                    rules={[{required: true}]}
                                    fieldProps={{
                                        ranges: {
                                            // month
                                            [dayjs().format('MMMM YY')]: [dayjs().startOf('month'), dayjs().endOf('month')],
                                            // month - 1
                                            [dayjs().subtract(1, 'month').format('MMMM YY')]: [
                                                dayjs().subtract(1, 'month').startOf('month'),
                                                dayjs().subtract(1, 'month').endOf('month'),
                                            ],
                                            // month - 2
                                            [dayjs().subtract(2, 'month').format('MMMM YY')]: [
                                                dayjs().subtract(2, 'month').startOf('month'),
                                                dayjs().subtract(2, 'month').endOf('month'),
                                            ],
                                            // quarter - 1
                                            [dayjs().subtract(1, 'quarter').format('[Q]Q YYYY')]: [
                                                dayjs().subtract(1, 'quarter').startOf('quarter'),
                                                dayjs().subtract(1, 'quarter').endOf('quarter'),
                                            ],
                                            // quarter - 2
                                            [dayjs().subtract(2, 'quarter').format('[Q]Q YYYY')]: [
                                                dayjs().subtract(2, 'quarter').startOf('quarter'),
                                                dayjs().subtract(2, 'quarter').endOf('quarter'),
                                            ],
                                            // month - 1 year
                                            [dayjs().subtract(1, 'month').subtract(1, 'year').format('MMMM YY')]: [
                                                dayjs().subtract(1, 'month').subtract(1, 'year').startOf('month'),
                                                dayjs().subtract(1, 'month').subtract(1, 'year').endOf('month'),
                                            ],
                                            // quarter - 1 year
                                            [dayjs().subtract(1, 'quarter').subtract(1, 'year').format('[Q]Q YYYY')]: [
                                                dayjs().subtract(1, 'quarter').subtract(1, 'year').startOf('quarter'),
                                                dayjs().subtract(1, 'quarter').subtract(1, 'year').endOf('quarter'),
                                            ],
                                            // year
                                            [dayjs().startOf('year').format('YYYY')]: [dayjs().startOf('year'), dayjs().endOf('year')],
                                            // year - 1
                                            [dayjs().subtract(1, 'year').startOf('year').format('YYYY')]: [
                                                dayjs().subtract(1, 'year').startOf('year'),
                                                dayjs().subtract(1, 'year').endOf('year'),
                                            ],
                                            // year - 2
                                            [dayjs().subtract(2, 'year').startOf('year').format('YYYY')]: [
                                                dayjs().subtract(2, 'year').startOf('year'),
                                                dayjs().subtract(2, 'year').endOf('year'),
                                            ],
                                            // month - 1 year
                                            [dayjs().subtract(1, 'year').startOf('month').format('MMMM YY')]: [
                                                dayjs().subtract(1, 'year').startOf('month'),
                                                dayjs().subtract(1, 'year').endOf('month'),
                                            ],
                                        },
                                        //TODO: Implement with proper rendering
                                        // onBlur: () => {
                                        //   const value = this.state.formRef.current?.getFieldValue('cms_list');
                                        //   if (value.length !== 0) {
                                        //     this.fetchGraphData(this.state.formRef.current?.getFieldsValue());
                                        //   }
                                        // },
                                        format: 'YYYY-MM-DD',
                                    }}
                                />

                                <ProFormSelect
                                    name='metric'
                                    label='Metric'
                                    options={[
                                        {label: 'Views', value: 'views'},
                                        {label: 'Revenue (€)', value: 'estimatedRevenue'},
                                    ]}
                                    // initialValue={'views'}
                                    //TODO: Implement with proper rendering
                                    // fieldProps={{
                                    //   onSelect: (value: string) => {
                                    //     this.onCloseFetchData();
                                    //   },
                                    // }}
                                    rules={[{required: true}]}
                                />

                                <ProFormSelect
                                    name='group_by'
                                    label='Group by'
                                    options={[
                                        {label: 'Day', value: 'day'},
                                        {label: 'Week', value: 'week'},
                                        {label: 'Month', value: 'month'},
                                        {label: 'Quarter', value: 'quarter'},
                                        {label: 'Half', value: 'half'},
                                        {label: 'Year', value: 'year'},
                                    ]}
                                    // fieldProps={{
                                    //     optionItemRender(item) {
                                    //         return item.label + ' - ' + item.value;
                                    //     },
                                    // }}
                                    // placeholder="Please select a country"
                                    rules={[{required: true}]}
                                    // rules={[{ required: true, message: 'Please select your country!' }]}
                                />

                                <ProFormSelect
                                    name='round_unit'
                                    label='Round to'
                                    options={[
                                        {label: 'Thousands', value: 'K'},
                                        {label: 'Millions', value: 'M'},
                                        {label: 'Billions', value: 'B'},
                                    ]}
                                    // rules={[{required: true}]}
                                />

                                <ProFormField label={'Run'}>
                                    <Button
                                        type={'primary'}
                                        onClick={() => {
                                            // (this.state.chartRef.current as any).chart.validateFields()
                                            // this.state.formRef!.current!.validateFields().then(value => {
                                            // this.fetchGraphData(this.state.formRef.current?.getFieldsValue())
                                            // })
                                            this.state.formRef!.current!.submit();
                                            // this.fetchGraphData(this.state.formRef.current?.getFieldsValue())
                                            // (this.state.chartRef.current as any).chart.submit()
                                            // console.log(this.state.formRef.current)
                                        }}
                                        icon={<SearchOutlined/>}>
                                        Query
                                    </Button>
                                </ProFormField>
                            </ProFormGroup>
                        </ProForm>
                    </ConfigProvider>

                    {/*     CHART   */}
                    <div style={{marginTop: 16}}>
                        <br/>

                        {this.state.graphConfig && (
                            <>
                                {/*     TOTALS      */}
                                {this.state.totals && (
                                    <Row justify={'space-between'}>
                                        {/*<Title>Totals:</Title>*/}
                                        <Space direction={'horizontal'} size={'middle'}>
                                            {/*<Statistic value={'Totals:'} title={""}/>*/}
                                            {/*{Object.keys(this.state.totals!).map((dateRange) => {*/}
                                            {/*    // return <Statistic title={dateRange} value={this.state.totals![dateRange]}/>*/}
                                            {/*    return <Card size={"small"}> <Statistic title={"Total for period:\n" + dateRange}*/}
                                            {/*                                            value={(this.state.totals![dateRange] as any).value}*/}
                                            {/*                                            decimalSeparator={','}*/}
                                            {/*                                            groupSeparator={'.'}*/}
                                            {/*                                            suffix={(this.state.totals![dateRange] as any).valueUnit}*/}
                                            {/*        // style={{whiteSpace: "pre-line"}}*/}
                                            {/*    /> </Card>*/}
                                            {/*})}*/}

                                            {(this.state.totals as any).map((total: any) => {
                                                // return <Statistic title={dateRange} value={this.state.totals![dateRange]}/>
                                                // console.log(total)
                                                return (
                                                    <div key={total.key}>
                                                        {/*// <Card size={"small"}>*/}
                                                        {/*    <Statistic title={"Total for period:\n" + dateRange}*/}
                                                        {/*                                        value={(this.state.totals![dateRange] as any).value}*/}
                                                        {/*                                        decimalSeparator={','}*/}
                                                        {/*                                        groupSeparator={'.'}*/}
                                                        {/*                                        suffix={(this.state.totals![dateRange] as any).valueUnit}*/}
                                                        {/*    // style={{whiteSpace: "pre-line"}}*/}
                                                        {/*/> */}
                                                        <Space direction={'horizontal'} size={'small'}>
                                                            {/*<img src={'https://www.wowpatterns.com/assets/files/resource_images/diagonal-lines-vector-pattern.jpg'}  width={16} />*/}
                                                            {/*       <LineOutlined />*/}
                                                        </Space>
                                                        <Space direction={'horizontal'} align={'center'}>
                                                            <Text
                                                                style={{fontSize: 24}}>{PATTERN_MAP_LEGEND[total.dateRangeChronologicalOrder]}</Text>
                                                            {total.dateRange + ' (Total: ' + (total.value || 0).toLocaleString('el-GR') + (total.valueUnit ? '' + total.valueUnit : '') + ')'}
                                                        </Space>
                                                        {/*// </Card>*/}
                                                    </div>
                                                );
                                            })}
                                        </Space>

                                        <Space size={'large'}>
                                            <Switch
                                                checkedChildren='Show totals'
                                                unCheckedChildren='Hide totals'
                                                checked={this.state.showTotalsAnnotations}
                                                onChange={(checked) => this.setState({showTotalsAnnotations: checked})}
                                            />

                                            <Tooltip title={'Download chart'}>
                                                <Button
                                                    type='default'
                                                    icon={<DownloadOutlined/>}
                                                    size={'middle'}
                                                    shape='round'
                                                    onClick={() => (this.state.chartRef as any).current.downloadImage()}
                                                />
                                            </Tooltip>
                                        </Space>
                                    </Row>
                                )}
                                <br/>

                                {/*     GRAPH   */}

                                <Column
                                    {...this.state.graphConfig!}
                                    // legend={ {}}
                                    // locale={'el-GR'}
                                    appendPadding={[20, 0, 0, 0]}
                                    chartRef={this.state.chartRef}
                                    // padding={[50, 20, 20, 20]}
                                    // height={600}
                                    // onReady={chart => {
                                    //     // console.log(chart.chart.getData())
                                    //     chart.on('click', (evt:any)=> {
                                    //         console.log('onclick', evt, evt.item)
                                    //         this.setAnnotations()
                                    //         // chart.addAnnotations([{content:'TEST',type:'text',position:[10,10]}])
                                    //         // chart.render()
                                    //     })
                                    // }}
                                    // onEvent={(chart, event) => {
                                    //     if (event.type == 'click') {
                                    //         // console.log((this.state.chartRef.current as any).chart.getData())
                                    //         // console.log(chart, event)
                                    //         console.log(chart.chart.getData())
                                    //         // this.setState({currentData: chart.chart.getData()})
                                    //         // this.setAnnotations();
                                    //
                                    //         // chart.changeData(chart.chart.getData())
                                    //         // (this.state.chartRef.current as any).chart.changeData(chart.chart.getData())
                                    //         // chart.chart.render()
                                    //         // event.gEvent.defaultPrevented=false
                                    //
                                    //     }
                                    // }}
                                    tooltip={{
                                        fields: ['dateRange', 'key', 'value', 'timeUnitIndexAlpha', 'dateAlpha', 'metric', 'valueUnit'],
                                        // title:(title, datum) => {
                                        //     // console.log(title ,datum)
                                        //     console.log(datum)
                                        // },
                                        // customContent: (title, data) => {
                                        //     console.log(title)
                                        //     // let datum = data[0]
                                        //     // console.log(datum)
                                        //     // return <>
                                        //     //     `CMS: ${datum['key']},
                                        //     //     Period: ${datum['dateRange']},
                                        //     //     Sub-period starting from: ${datum['dateAlpha']}
                                        //     //     ${datum['metric']}: ${datum['value']}`
                                        //     // </>
                                        // },
                                        // showContent:true,
                                        //{
                                        //     "dateRange": "01/01/2021 to 31/12/2021",
                                        //     "key": "LTD M",
                                        //     "value": 491766321,
                                        //     "timeUnitIndexAlpha": "Quarter 4",
                                        //     "dateAlpha": "01/10/2021"
                                        //     "metric": "views"
                                        // }
                                        formatter: (datum) => {
                                            // console.log(datum)
                                            // return {
                                            //     'CMS': datum['key'],
                                            //     'Period': datum['dateRange'],
                                            //     'Sub-period starting from': datum['dateAlpha'],
                                            //     [datum['metric']]: datum["value"]
                                            // };
                                            // return {name: 'TEST', value:1123}
                                            return {
                                                name: datum['key'] + ' | Period ' + datum['dateRange'] + ' | Sub-period: ' + datum['dateAlpha'] + ' | ' + capitalize(datum['metric']),
                                                value: datum['valueUnit'] ? (datum['value']?.toLocaleString('el-GR') || 0) + ' ' + datum['valueUnit'] : datum['value'],
                                            };
                                        },
                                        shared: false,
                                    }}
                                    // label={{
                                    //     position: "middle",
                                    //     layout: [
                                    //         {type: 'interval-adjust-position',},
                                    //         {type: 'interval-hide-overlap',},
                                    //         {type: 'adjust-color',},
                                    //     ],
                                    // }}
                                    annotations={this.state.showTotalsAnnotations && this.state.annotations}
                                    //     pattern={pattern}
                                    columnStyle={{
                                        radius: 4,
                                    }}
                                    // columnBackground={{style:{fill:"#FFFFFF", fillOpacity:0.4}}}
                                    rawFields={[
                                        // 'date',
                                        // 'dateAlpha',
                                        // 'dateRange',
                                        // 'dateRangeTopologicalOrder',
                                        'dateRangeChronologicalOrder',
                                        // 'key',
                                        // 'metric',
                                        // 'timeUnit',
                                        // 'timeUnitIndex',
                                        // 'timeUnitIndexAlpha',
                                        // 'value',
                                        // 'valueAlpha',
                                        // 'valueUnit'
                                    ]}
                                    // pattern={ {
                                    //     type: 'dot',
                                    //     cfg: {
                                    //         size: 4,
                                    //         padding: 4,
                                    //         rotation: 0,
                                    //         fill: '#FFF',
                                    //         isStagger: true,
                                    //     },
                                    // }}

                                    // label={{
                                    //     formatter:(data, mappingData, index) => {
                                    //         console.log(data, mappingData, index)
                                    //     }
                                    // }}
                                    // seriesField={'key'}
                                    //     groupField={'dateRange'}
                                    pattern={({dateRangeChronologicalOrder}, color) => {
                                        if (dateRangeChronologicalOrder == 0) {
                                            // console.log(color)
                                            // console.log(shadeColor(color,-20))
                                            color = shadeColor(color, -40);
                                            color = addAlpha(color, 1);
                                        }
                                        return pattern(
                                            {
                                                dateRangeChronologicalOrder,
                                            },
                                            color
                                        );
                                    }}
                                    // legend={{
                                    //     items:[
                                    //         {
                                    //             marker:(name, index, item) => {
                                    //                 console.log(item)
                                    //                 const color = (item.style as any).fill;
                                    //                 return {
                                    //                     style: {
                                    //                         fill:color,
                                    //                         r: 8,
                                    //                     },
                                    //                 };
                                    //             },
                                    //             // value:'test2',
                                    //             name:'test2',
                                    //             // id:'test2',
                                    //         },
                                    //         {value:'test'}
                                    //     ],
                                    // marker: (text, index, item) => {
                                    //     console.log(item)
                                    //     const color = (item.style as any).fill;
                                    //     return {
                                    //         style: {
                                    //             fill: pattern(
                                    //                 {
                                    //                     dateRangeChronologicalOrder: text,
                                    //                 },
                                    //                 // color,
                                    //                 ''
                                    //             ),
                                    //             r: 8,
                                    //         },
                                    //     };
                                    // },
                                    // }}
                                />
                            </>
                        )}
                        {this.state.loading && (
                            // <Column xField={"x"} yField={"y"} data={[]} loading={true}/>
                            <Row justify={'center'}>
                                <Spin spinning={true}/>
                            </Row>
                        )}
                    </div>
                </PageContainer>
            </div>
        );
    }
}

export default ReportingChartsEngine;
