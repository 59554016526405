import { PageContainer } from '@ant-design/pro-components';

import ContactsTable from './ContactsTable';

const ConnectionsPage = () => {
    return (
        <PageContainer>
            <ContactsTable />
        </PageContainer>
    );
};

export default ConnectionsPage;
