import * as React from 'react';
import {useContext, useState} from 'react';
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, notification, Select, Space, Tag, Tooltip} from "antd";
import {ProColumns} from "@ant-design/pro-table";
import axiosApiInstance from "../../../api/axiosClient";
import {GlobalStateContext} from "../../../context/GlobalContext";
import DeleteCreatorLead from "./DeleteCreatorLead";
import DeleteBulkCreatorLeads from "./DeleteBulkCreatorLeads";
import Icon, {EditOutlined, EyeOutlined, InstagramOutlined, PlusOutlined, YoutubeFilled} from "@ant-design/icons";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import NotifyCreatorLeadManager from "./NotifyCreatorLeadManager";
import ExportCreatorLeadsExcel from "./ExportCreatorLeadsExcel";
import {UserAuth} from "../../../context/AuthContext";
import {ReactComponent as TikTokSquare} from '../../../assets/tiktok_circle.svg';
import {IG_COLOR, YT_COLOR} from "../../../common/constants";
import './CreatorsLeads.css';
// import { createFromIconfontCN } from '@ant-design/icons';
// const IconFont = createFromIconfontCN({
//     scriptUrl: [
//         '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overridden)
//         '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
//     ],
// });
type Props = {};


const CreatorsLeads = (props: Props) => {
    // const actionRef = useRef<ActionType>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const {
        creatorsLeadsTableRef,
        setCreatorsLeadsCategories,
        setCreatorsLeadsItems,
        creatorsLeadsCategories,
        employees,
        setEmployees,
    } = useContext(GlobalStateContext);
    const [categoriesFilters, setCategoriesFilters] = useState(undefined);
    const [employeesFilters, setEmployeesFilters] = useState(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate()
    const location = useLocation()
    const {can} = UserAuth();


    const {
        //Potential YouTube Leads
        potentialLeadsTableRef,
        potentialYouTubeLeads,
        setPotentialYouTubeLeads,
        potentialYouTubeLeadsFilters,
        setPotentialYouTubeLeadsFilters,
    } = useContext(GlobalStateContext);

    // Drawer
    const [open, setOpen] = useState(false);
    const {Option} = Select;


    const columns: ProColumns<API.CreatorsLeads.CreatorLead>[] = [
        {
            dataIndex: 'id',
            title: 'ID',
            // valueType: 'indexBorder',
            width: 48,
        },
        {
            title: 'Creator',
            dataIndex: 'creator',
        },
        {
            title: 'Identity',
            dataIndex: 'main_platform_id',
            valueType: 'select',
            valueEnum: {
                1: {text: 'YouTube'},
                2: {text: 'Instagram'},
                3: {text: 'TikTok'},
            },
            filters: true,
            render: (_, record) => record.main_platform.characterization
        },
        {
            title: 'Accounts',
            dataIndex: 'accounts_stringified',
            sorter: true,
            hideInSearch: true,
            // render: (dom, record) => (
            //     <Tooltip title={<Space>
            //         {record.accounts?.map((account) => (
            //             <a href={account.key} target="_blank" rel="noreferrer">
            //                 <Avatar key={account.key} src={account.key} style={{
            //                     background:
            //                         account.platform.name === 'YouTube' ? YT_COLOR :
            //                             account.platform.name === 'Instagram' ? 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)' :
            //                                 account.platform.name === 'TikTok' ? '#fff' : '#fff',
            //                     // background: account.platform.name === 'Instagram' ? 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)' : 'none',
            //         backgroundClip: 'content-box',
            //                 }} icon={
            //                     account.platform.name === 'YouTube' ? <YoutubeFilled/> :
            //                         account.platform.name === 'Instagram' ? <InstagramOutlined/> :
            //                             account.platform.name === 'TikTok' ?
            //                                 <Icon style={{
            //                                     color: '#000',
            //                                     // color: '#111111', filter: 'drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4)'
            //                                 }} component={TikTokSquare}/> :
            //                                 null}/>
            //             </a>
            //         ))}
            //     </Space>}>
            //         {dom}
            //     </Tooltip>
            // ),
            render: (dom, record) => (
                <Space direction={"vertical"}>
                    {dom}
                    <Space style={{
                        display: 'flex', alignItems: 'center',
                        // add a light background to the icons
                        backgroundColor: '#f0f0f0',
                        borderRadius: '10px',
                        padding: '2px 5px 0px 5px',
                        width: 'fit-content',
                    }}>
                        {record.accounts?.map((account) => (
                            <Tooltip title={account.key}>
                                <a href={account.key} target="_blank" rel="noreferrer">
                                    {account.platform.name === 'YouTube' ? <YoutubeFilled
                                            style={{
                                                color: YT_COLOR,
                                                fontSize: 18,
                                            }}/> :
                                        account.platform.name === 'Instagram' ? <InstagramOutlined
                                                // color={'transparent'}
                                                // className={'instagram-gradient'}
                                                style={{
                                                    color: IG_COLOR,
                                                    //     // mask: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
                                                    //     // maskClip: 'padding-box',
                                                    fontSize: 18,
                                                    //     // backgroundImage: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
                                                    //     // backgroundClip: 'text',
                                                    //     // fill: 'transparent',
                                                    //     // color: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
                                                    // background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
                                                }}
                                                // style={{
                                                // background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
                                                // backgroundClip: 'text',
                                                //}}
                                            /> :
                                            account.platform.name === 'TikTok' ?
                                                <Icon
                                                    // className={'instagram-gradient'}
                                                    style={{
                                                        color: '#000',
                                                        fontSize: 16,
                                                        // color: 'red',
                                                        // background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
                                                        // backgroundClip: 'text',
                                                        // color: '#111111', filter: 'drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4)'
                                                    }} component={TikTokSquare}/> :
                                                null}
                                </a>
                            </Tooltip>
                        ))}
                    </Space>
                </Space>
            ),
        },

        {
            // disable: true,
            title: 'Categories',
            dataIndex: 'content_categories',
            search: false,
            filters: categoriesFilters,
            render: (_, record) => (
                <Space>
                    {record.content_categories?.map(({name}) => (
                        <Tag key={name}>
                            {name}
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: 'Communication via',
            dataIndex: 'communication_via_id',
            filters: true,
            valueType: 'select',
            valueEnum: {
                1: {text: 'Email'},
                2: {text: 'Phone'},
                3: {text: 'Instagram'},
            },
        },
        {
            title: 'Negotiation',
            dataIndex: 'negotiation',
        },
        {
            // disable: true,
            title: 'Communication Status',
            dataIndex: 'status_id',
            filters: true,
            valueType: 'select',
            width: 100,
            valueEnum: {
                1: {
                    text: 'Signed',
                    status: 'Success',
                    // disabled: true,
                },
                2: {
                    text: 'Pending',
                    status: 'warning',
                    // disabled: true,
                },
                3: {
                    text: 'Declined',
                    status: 'error',
                    // disabled: true,
                },
            },
        },
        {
            title: 'Comments',
            dataIndex: 'reason',
        },
        {
            title: 'Date of communication',
            dataIndex: 'date_of_communication',
            valueType: 'date',
            sorter: true,
            // valueType: 'dateRange',
            // hideInTable: true,
            // search: {
            //     transform: (value) => {
            //         return {
            //             startTime: value[0],
            //             endTime: value[1],
            //         };
            //     },
            // },
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            valueType: 'date',
            sorter: true,
        },
        {
            title: 'Created By',
            dataIndex: 'created_by_employee_id',
            filters: employeesFilters,
            render: (_, record) => record.created_by_employee?.full_name
        },
        {
            title: 'Managed By',
            dataIndex: 'managed_by_employee_id',
            filters: employeesFilters,
            render: (_, record) => record.managed_by_employee?.full_name
        },
        {
            title: 'Approval Status',
            dataIndex: 'approval_status_id',
            valueType: 'select',
            align: "center",
            // width: '7%',

            //Filtering column
            filters: true,
            // filterMultiple: false,
            valueEnum: {
                1: {
                    text: 'Approved',
                },
                0: {
                    text: 'Rejected',
                },
                2: {
                    text: 'Pending',
                },
            },

            // render: ((dom, entity, index) => {
            //     return <Checkbox checked={entity.approved}
            //                      onChange={checked => {
            //                          onApproveStateChange(entity.id, checked.target.checked)
            //                      }}/>;
            // })
            render: ((dom, entity, index) => {
                return <Select size={"small"} value={entity.approval_status_id}
                               options={[
                                   {label: 'Approved', value: 1},
                                   {label: 'Rejected', value: 0},
                                   {label: 'Pending', value: 2},
                               ]}
                               dropdownMatchSelectWidth={false}
                    // border accordingly to status
                               style={{
                                   border: entity.approval_status_id === 1 ? '1px solid #52c41a' :
                                       entity.approval_status_id === 0 ? '1px solid #ff4d4f' :
                                           '1px solid #faad14',
                                   borderRadius: '5px',
                                   // width: '100px',
                               }}
                               bordered={false}
                               onChange={value => {
                                   onApprovalStateChange(entity.id, value)
                               }}/>;
            })
        },
        {
            title: '',
            render: (_, record) => <Space>
                <Link to={`${record.id}`}>
                    <Tooltip title={'View'}><Button icon={<EyeOutlined/>} type={"text"}/></Tooltip>
                </Link>
                <Link to={`${record.id}/edit`}>
                    <Tooltip title={'Edit'}><Button icon={<EditOutlined/>} type={"text"}/></Tooltip>
                </Link>
                {/*<CreatorLeadViewEdit editable={false} lead={record} key={record.id + '_view'}/>*/}
                {/*<CreatorLeadViewEdit editable={true} lead={record} key={record.id + '_edit'}/>*/}
                <NotifyCreatorLeadManager id={record.id} key={record.id}/>
                <DeleteCreatorLead id={record.id} key={record.id}/>
            </Space>
        }
    ];


    const fetchCategoriesData = async () => {
        try {
            const response = await axiosApiInstance.get<API.CreatorsLeads.CreatorLeadContentCategory[]>('/api/leads/categories/')
            setCreatorsLeadsCategories(response.data);
            potentialLeadsTableRef.current?.reload()
        } catch (e) {
            setCreatorsLeadsCategories(undefined);
        }
    };

    const fetchEmployeesData = async () => {
        try {
            const response = await axiosApiInstance.get<API.CRM.Employee[]>('/api/employees/')
            console.log(response.data)
            setEmployees(response.data);
        } catch (e) {
            setEmployees(undefined);
        }
    };

    const onApprovalStateChange = async (leadId: number, value: number) => {
        try {
            // console.log(checked)
            const res = await axiosApiInstance.post<API.CreatorsLeads.CreatorLead[]>('/api/leads/items/approve/' + leadId, {
                approval_status_id: value
            })

            notification.success({message: 'Success'})

            //Refresh
            creatorsLeadsTableRef.current?.reload()
        } catch (e: any) {
            notification.error({message: e.response.data.message})
        }
    };

    const removeLeadFromList = async (channelId: string) => {
        setPotentialYouTubeLeads(potentialYouTubeLeads.filter((i) => i.channel_id != channelId))
    };

    return (
        <PageContainer>

            <ProTable<API.CreatorsLeads.CreatorLead>
                columns={columns}
                actionRef={creatorsLeadsTableRef}
                ghost
                rowKey={record => record.id}
                // search={{
                //     labelWidth: 'auto',
                // }}
                search={false}
                options={{
                    // setting: {
                    //     listsHeight: 400,
                    // },
                    search: {placeholder: 'Please enter keyword', allowClear: true},
                }}
                request={async (params = {}, sort, filter) =>
                    // axiosApiInstance.get<CreatorLeadRow[]>('/api/leads/', {params})
                {
                    // console.log('on request')
                    // console.log(params)
                    console.log(filter)
                    // console.log(sort)
                    const res = await axiosApiInstance.get<API.CreatorsLeads.CreatorLead[]>('/api/leads/items', {
                        params:
                            {
                                ...filter, ...params,
                                sort_communication_date: sort.date_of_communication,
                                sort_creation_date: sort.created_at,
                                sort_accounts: sort.accounts_stringified
                            }
                    })
                    // console.log(res.data)
                    await fetchCategoriesData()
                    await fetchEmployeesData()
                    return Promise.resolve({data: res.data, success: true, total: res.data?.length})
                }
                }
                onDataSourceChange={dataSource => {
                    // console.log('on ds change', dataSource)
                    setCreatorsLeadsItems(dataSource)
                    // setFilters()
                    setCategoriesFilters(creatorsLeadsCategories?.map(value => ({
                        text: value.name,
                        value: value.id
                    })))
                    setEmployeesFilters(employees?.map(value => ({
                        text: value.full_name,
                        value: value.id
                    })))
                }}
                // onLoad={dataSource => {
                //     // console.log('on load')
                //     // setCreatorsLeadsItems(dataSource)
                // }}
                pagination={{
                    pageSize: 7,
                    // pageSize: 150,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    // onChange: (page) => console.log(page),
                }}
                dateFormatter="string"
                toolBarRender={() => [
                    <Button key="button" icon={<PlusOutlined/>} type="primary"
                            onClick={() => navigate('/crm/leads/list/new', {state: {background: location}})}
                    >
                        New
                    </Button>,
                    can('export', 'leads::data') && <ExportCreatorLeadsExcel/>,
                    // <CreatorLeadCreate/>
                ]}
                tableAlertRender={({selectedRowKeys}) => {
                    return (
                        <Space size={24}>
                          <span>
                                Selected {selectedRowKeys.length} {selectedRowKeys.length > 1 ? 'leads' : 'lead'}
                          </span>
                        </Space>
                    )
                }}
                tableAlertOptionRender={({selectedRowKeys, onCleanSelected}) => {
                    return (
                        <Space size={16}>
                            <DeleteBulkCreatorLeads leadsIds={selectedRowKeys as number[]}
                                                    onClearSelection={onCleanSelected}/>
                            <a style={{marginInlineStart: 8}} onClick={onCleanSelected}>
                                Clear selection
                            </a>
                            {/*<a>Delete batch</a>*/}
                        </Space>
                    );
                }}
                rowSelection={{
                    selectedRowKeys,
                    onChange: (keys: React.Key[]) => setSelectedRowKeys(keys as number[]),
                    // renderCell: (value, record, index, originNode) => {
                    //     return <Row justify={"center"} align="middle" style={{height: 46}}>
                    //         <Col>
                    //             {originNode}
                    //         </Col>
                    //     </Row>
                    // },
                }}
                columnsState={{
                    persistenceKey: 'creator-leads-list',
                    persistenceType: 'localStorage',
                    // onChange(value) {
                    //     console.log('value: ', value);
                    // },
                }}
            />
            {/*</Col>*/}
            {/*</Row>*/}

            <Outlet/>


        </PageContainer>
    );
};

export default CreatorsLeads;