import { Typography } from 'antd';
import Modal from 'antd/es/modal/Modal';
import React, { useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axiosApiInstance from '../../../api/axiosClient';
import { GlobalStateContext } from '../../../context/GlobalContext';

const DeleteConnectionModal = () => {
    const navigate = useNavigate();
    const { contactsTableRef } = useContext(GlobalStateContext);
    const [searchParams] = useSearchParams();
    const { id } = useParams();

    return (
        <Modal
            open
            okType='danger'
            okText='Delete'
            title={`Delete Contact: ${searchParams.get('name')}`}
            onOk={async () => {
                // console.log('OK Clicked');
                await axiosApiInstance
                    .delete(`/api/connections/contacts/${id}/`, {
                        headers: {
                            'Access-Control-Arrow-Origin': '*'
                        }
                    })
                    .then(() => contactsTableRef.current.reload())
                    .finally(() => navigate('/social/connections'));
                return;
            }}
            onCancel={() => {
                console.log('Cancel');
                navigate('/social/connections');
                return;
            }}
        >
            <Typography.Text>Are you sure you want to delete the following contact?</Typography.Text>
            <br />
            <Typography.Text strong type='danger'>
                This action is non-reversible!
            </Typography.Text>
        </Modal>
    );
};

export default DeleteConnectionModal;
