import {blue, cyan, geekblue, gold, green, lime, magenta, purple, red, volcano} from '@ant-design/colors';

export const hashCode = (str: string) => {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
};

export const intToColor = (i: number) => {
    const first = 3
    const last = 7
    //  const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#87d068'];
    // const colors = [...red, ...volcano, ...gold, ...yellow, ...lime, ...green, ...cyan, ...blue, ...geekblue, ...purple, ...magenta, ...grey];
    const colors = [...red.slice(first, last), ...volcano.slice(first, last), ...gold.slice(first, last), ...lime.slice(first, last), ...green.slice(first, last), ...cyan.slice(first, last), ...blue.slice(first, last), ...geekblue.slice(first, last), ...purple.slice(first, last), ...magenta.slice(first, last)];

    return colors[(Math.abs(i) + 1) % colors.length];
};

export const truncateMetricValueToDecimals = (value: number, decimalPrecision: number) => {
    return value % 1 === 0 ? value : Number.parseFloat(value.toString()).toFixed(decimalPrecision)
};


// rounding functions

export const defaultPrecision = 2


export const roundNum = (num: number, decimalPrecision?: number) => {
    return Math.round((num + Number.EPSILON) * Math.pow(10, decimalPrecision !== undefined ? decimalPrecision : 2)) / Math.pow(10, decimalPrecision !== undefined ? decimalPrecision : 2)
};

const unitToRoundFunc: { [key: string]: Function } = {
    BHr: (num: number, decimalPrecision?: number) => roundNum(num / (60 * 1000 * 1000 * 1000), decimalPrecision || defaultPrecision),
    MHr: (num: number, decimalPrecision?: number) => roundNum(num / (60 * 1000 * 1000), decimalPrecision || defaultPrecision),
    KHr: (num: number, decimalPrecision?: number) => roundNum(num / (60 * 1000), decimalPrecision || defaultPrecision),
    Hr: (num: number, decimalPrecision?: number) => roundNum(num / 60, decimalPrecision || defaultPrecision),
    T: (num: number, decimalPrecision?: number) => roundNum(num / (1000 * 1000 * 1000 * 1000), decimalPrecision || defaultPrecision),
    B: (num: number, decimalPrecision?: number) => roundNum(num / (1000 * 1000 * 1000), decimalPrecision || defaultPrecision),
    M: (num: number, decimalPrecision?: number) => roundNum(num / (1000 * 1000), decimalPrecision || defaultPrecision),
    K: (num: number, decimalPrecision?: number) => roundNum(num / 1000, decimalPrecision || defaultPrecision),
}

export const defaultResult = (num: number) => {
    return {
        value: num,
        unit: ''
    }
}


export const roundNumAdvanced = (num: number, isTimeInMinutes?: boolean, decimalPrecision?: number, fixedUnit?: string, isMoney?: boolean): {
    value: number,
    unit: string
} => {

    // if (isMoney) {
    //    return {
    //       value: num,
    //       unit: '€'
    //    }
    // }


    if (fixedUnit) {
        if (unitToRoundFunc[fixedUnit])
            return {value: unitToRoundFunc[fixedUnit](num, decimalPrecision), unit: fixedUnit}
        else {
            return defaultResult(num)
        }
    }


    if (isTimeInMinutes) {
        return roundTime(num, decimalPrecision)
    } else if (isMoney) {
        // let temp = roundNumber(num, decimalPrecision)
        // temp.unit = temp.unit + ' EUR'
        // return temp
        return roundNumber(num, decimalPrecision)
    } else {
        return roundNumber(num, decimalPrecision)
    }


    // default value
    // return defaultResult(num)
};


export const roundTime = (num: number, decimalPrecision?: number): { value: number, unit: string } => {
    if (num >= 60 * 1000 * 1000 * 1000) {
        return {
            value: unitToRoundFunc['BHr'](num, decimalPrecision),
            unit: 'BHr'
        }
    } else if (num >= 60 * 1000 * 1000) {
        return {
            value: unitToRoundFunc['MHr'](num, decimalPrecision),
            unit: 'MHr'
        }
    } else if (num >= 60 * 1000) {
        return {
            value: unitToRoundFunc['KHr'](num, decimalPrecision),
            unit: 'KHr'
        }
    } else if (num >= 60) {
        return {
            value: unitToRoundFunc['Hr'](num, decimalPrecision),
            unit: 'Hr'
        }
    } else if (num < 60) {
        return {
            value: num,
            unit: 'Min'
        }
    }

    return defaultResult(num)
}


export const roundNumber = (num: number, decimalPrecision?: number): { value: number, unit: string } => {
    if (num >= 1000 * 1000 * 1000 * 1000) {
        return {
            value: unitToRoundFunc['T'](num, decimalPrecision),
            unit: 'T'
        }
    } else if (num >= 1000 * 1000 * 1000) {
        return {
            value: unitToRoundFunc['B'](num, decimalPrecision),
            unit: 'B'
        }
    } else if (num >= 1000 * 1000) {
        return {
            value: unitToRoundFunc['M'](num, decimalPrecision),
            unit: 'M'
        }
    } else if (num >= 1000) {
        return {
            value: unitToRoundFunc['K'](num, decimalPrecision),
            unit: 'K'
        }
    } else if (num < 1000) {
        return defaultResult(num)
    }

    return defaultResult(num)
}

export const capitalize = (s: string): string => {
    if (!s) return s
    if (s.length == 0)
        return s
    return s[0].toUpperCase() + s.substring(1)
}

export const camelCaseSplit = (inputString: string) => {
    const f = (str: string) => str.replace(/(?<=[a-z\d])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/g, ' ');
    return f(inputString);
}


export const findMaxUnit = (unit1: string, unit2: string, isTime: boolean = false) => {
    let timeUnits = ['Hr', 'KHr', 'MHr', 'BHr']
    let nonTimeUnits = ['K', 'M', 'B', 'T']
    if (isTime) {
        return timeUnits.indexOf(unit1) > timeUnits.indexOf(unit2) ? unit1 : unit2
    }
    return nonTimeUnits.indexOf(unit1) > nonTimeUnits.indexOf(unit2) ? unit1 : unit2
};


export const shadeColor = (color: string, percent: number) => {

    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.floor(R * (100 + percent) / 100);
    G = Math.floor(G * (100 + percent) / 100);
    B = Math.floor(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    let RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    let GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    let BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}

export const addAlpha = (color: string, opacity: number) => {
    color = color.substring(1)
    // coerce values so ti is between 0 and 1.
    let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return '#' + color + _opacity.toString(16).toUpperCase();
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1): string => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}


export const getRndInteger = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// export const formatNumber = (num: number, decimals?: number) => {
//     return new Intl.NumberFormat('el-GR',
//         // { maximumSignificantDigits: 3 }
//         {maximumFractionDigits: decimals || 2}
//     ).format(num)
// }
// export const formatPercent = (num: number, decimals?: number) => {
//     return new Intl.NumberFormat('el-GR',
//         // { maximumSignificantDigits: 3 }
//         {maximumFractionDigits: decimals || 2}
//     ).format(num)
// }