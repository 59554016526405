import axios from "axios";
import {API} from "../common/constants";
import {auth} from '../config/firebase';

export const getRequest = async (endpoint: string) => {

    const session_url = API + endpoint

    const token = await auth.currentUser?.getIdToken()
    // console.log({token})
    return axios.get(session_url, {
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            Authorization: `Bearer ${token}`
        }

    });
};