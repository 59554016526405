import React, {Component} from 'react';
import {Tabs, Typography} from 'antd';
import './index.css';
import AdminTags from "../AdminTags";
import AdminTiers from "../AdminTiers";
import PPK_PAGES from "../PPK_Pages";
import AdminSocialPlatforms from "../AdminSocialPlatforms";
import ShowDataTable from "../../../components/ShowDataTable";
import {PageContainer} from "@ant-design/pro-components";

const {TabPane} = Tabs;

export interface IAdminDashboardProps {
    //Here we pass the Props Interface
}

export interface IAdminDashboardState {
    //here we pass the State Interface
}

//class ComponentName Component<PropsInterface, StateInterface>
class AdminDashboard extends Component<IAdminDashboardProps, IAdminDashboardState> {
    //Component State
    state = {};

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        console.log('Admin mount')
    }

    render() {
        return (
            <>
                <PageContainer>

                    <Tabs type="card">
                        <TabPane tab={'Tags'} key={'tags'}>
                            <AdminTags/>
                        </TabPane>

                        <TabPane tab={'Tiers'} key={'tiers'}>
                            <AdminTiers/>
                        </TabPane>

                        <TabPane tab={'Platforms Products & Keys'} key={'ppks'}>
                            <PPK_PAGES/>
                        </TabPane>

                        <TabPane tab={'Social Platforms'} key={'social_platforms'}>
                            <AdminSocialPlatforms/>
                        </TabPane>

                        <TabPane tab={'Currencies'} key={'currencies'}>
                            <Typography.Title level={5}>Currencies</Typography.Title>
                            <ShowDataTable style={{height: '300px', width: '100%'}}
                                           call_url="/api/admin/getcurrencies"/>
                        </TabPane>

                        <TabPane tab={'Countries'} key={'countries'}>
                            <Typography.Title level={5}>Countries</Typography.Title>
                            <ShowDataTable style={{height: '300px', width: '100%'}} call_url="/api/admin/getcountries"/>
                        </TabPane>
                    </Tabs>


                    {/*   <Col span={12}>*/}
                    {/*      <Space direction="vertical">*/}
                    {/*         <Typography.Title level={5}>Lookup Tables</Typography.Title>*/}
                    {/*         <Button href="admin/lookup">Lookup Tables</Button>*/}
                    {/*      </Space>*/}
                    {/*      <Divider />*/}
                    {/*      <Space direction="vertical">*/}
                    {/*         <Typography.Title level={5}>Users</Typography.Title>*/}
                    {/*         <Button disabled href="admin/users">*/}
                    {/*            Users*/}
                    {/*         </Button>*/}
                    {/*      </Space>*/}
                    {/*      <Divider />*/}
                    {/*   </Col>*/}
                    {/*</Row>*/}

                </PageContainer>
            </>
        );
    }
}

export default AdminDashboard;
