import {
    Button,
    Card,
    ConfigProvider,
    Divider,
    notification,
    Row,
    Space,
    Spin,
    Statistic,
    Tooltip,
    Typography
} from 'antd';
import React, {Component} from 'react';
import './index.css';
import ProTable, {ActionType, ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import ProCard from "@ant-design/pro-card";
import {FileTextOutlined} from "@ant-design/icons";
import enUSIntl from "antd/lib/locale/en_US";
import {ModalForm} from "@ant-design/pro-form";
import PaymentCycleCustomerBreakdownByAsset from "../PaymentCycleCustomerBreakdownByAsset";
import {auth} from "../../../config/firebase";
import axios from "axios";
import {API} from "../../../common/constants";
import withRouter from "../../../components/withRouter";
// import { ProCard } from '@ant-design/pro-components';
// import ProCardDivider from "@ant-design/pro-card/es/components/Divider";
// const {Divider} = ProCard;

export interface IPaymentCycleResultsAnalyticsProps {
    //Here we pass the Props Interface
    params: any
    pageSize?: number
    polling?: false | number
}

export interface IPaymentCycleResultsAnalyticsState {
    // data?: PaymentCycleResult[]
    data?: any
    filteredData?: any
    actionRef: React.RefObject<ActionType>
    responsive?: boolean
    loading: boolean

    searchText: string,
    searchedColumn: string,
}

// depr
// type PaymentCycleResult = {
//     podio: number
//     full_name: string
//     product: string
//     cms: string
//     ads_type: string
//     key: string
//     gross_euro: number
//     commission: number
//     client: number
//     dm: number
//     podio_affiliate: number
//     commission_affiliate: number
//     affiliate_share: number
//     total_earnings_of_client: number
//     total_affiliation_given_by_client: number
//     total_affiliation_given_to_client: number
//     previous_balance: number
//     final_earnings_of_client: number
//     new_balance: number
//     threshold: number
// };
type PaymentCycleResult = {
    podio: number
    full_name: string
    prev_balance: number
    client_euro_direct: number
    client_revenue_from_affiliations: number
    final_balance_euro: number
    final_balance_payment_currency: number
    payment_currency: string
    tier: string
};


//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleResultsAnalytics extends Component<IPaymentCycleResultsAnalyticsProps, IPaymentCycleResultsAnalyticsState> {

    constructor(props: IPaymentCycleResultsAnalyticsProps) {
        super(props);

        this.state = {
            actionRef: React.createRef<ActionType>(),
            responsive: false,
            loading: false,

            searchText: '',
            searchedColumn: ''
        }

    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {

    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getTotalsAndResults()
    }

    getTotalsAndResults = async () => {
        this.setState({loading: true})
        try {
            const res = await postRequest('/api/paymentCycles/getResultsAnalytics/' + this.props.params.id)
            console.log(res.data)
            this.setState({data: res.data, loading: false})
        } catch (e) {
            // return
            this.setState({loading: false})
        }
    }


    // columns: ColumnsType<any> = [
    columns: ProColumns<PaymentCycleResult>[] = [
        {
            title: 'Podio',
            dataIndex: 'podio',
            // valueType: 'digit',
            fixed: 'left',
            width: 70,
            sorter: (a, b) => a.podio - b.podio
            // , ...this.getColumnSearchProps('podio')
        },
        {title: 'Full Name', dataIndex: 'full_name'},
        // {title: 'Product', dataIndex: 'product', valueType: 'text'},
        // {title: 'CMS', dataIndex: 'cms', valueType: 'text'},
        // {title: 'Ads type', dataIndex: 'ads_type', valueType: 'text'},
        // {title: 'Key', dataIndex: 'key', valueType: 'text'},
        // {title: 'Gross Euro', dataIndex: 'gross_euro', valueType: 'digit'},
        // {title: 'Commission', dataIndex: 'commission', valueType: 'digit'},
        {
            title: 'Previous Balance',
            dataIndex: 'prev_balance',
            hideInSearch: true,
            valueType: "digit",
            sorter: (a, b) => a.client_euro_direct - b.client_euro_direct
        },
        {
            title: 'Client Revenue (Direct) (€)',
            dataIndex: 'client_euro_direct',
            valueType: "digit",
            hideInSearch: true,
            sorter: (a, b) => a.client_euro_direct - b.client_euro_direct
        },
        // {title: 'DM', dataIndex: 'dm', valueType: 'digit'},
        // {title: 'Podio Affiliate', dataIndex: 'podio_affiliate', valueType: 'digit'},
        // {title: 'Commission Affiliate', dataIndex: 'commission_affiliate', valueType: 'digit'},
        // {title: 'Affiliate Share', dataIndex: 'affiliate_share', valueType: 'digit'},
        // {title: 'Total Earnings Of Client', dataIndex: 'total_earnings_of_client', hideInSearch: true},
        // {
        //     title: 'Total Affiliation Given by Client',
        //     dataIndex: 'total_affiliation_given_by_client',
        //     valueType: 'digit'
        // },
        {
            title: 'Client Revenue From Affiliations (€)',
            dataIndex: 'client_revenue_from_affiliations',
            hideInSearch: true,
            valueType: "digit",
            sorter: (a, b) => a.client_revenue_from_affiliations - b.client_revenue_from_affiliations
        },
        // {title: 'Final Earnings Of Client', dataIndex: 'final_earnings_of_client', hideInSearch: true},
        {
            title: 'Final Balance (€)', dataIndex: 'final_balance_euro', valueType: "digit", hideInSearch: true,
            sorter: (a, b) => a.final_balance_euro - b.final_balance_euro
        },
        {
            title: 'Final Balance Payment Currency',
            dataIndex: 'final_balance_payment_currency',
            valueType: "digit",
            hideInSearch: true,
            sorter: (a, b) => a.final_balance_payment_currency - b.final_balance_payment_currency
        },
        {
            title: 'Payment Currency',
            dataIndex: 'payment_currency',
            valueType: 'select',
            valueEnum: {EUR: {text: 'EUR'}, GBP: {text: 'GBP'}},
        },
        {title: 'Tier', dataIndex: 'tier'},
        {title: 'Threshold', dataIndex: 'threshold', hideInSearch: true},
        {
            title: 'More', hideInSearch: true, render: (dom, entity, index, action, schema) => {
                return <ConfigProvider locale={enUSIntl}>
                    <ModalForm
                        // title="Payment Cycle Logs"
                        width={1200}
                        trigger={
                            <Tooltip title={"Breakdown Analysis for Customer"}>
                                <Button type="link">
                                    <FileTextOutlined/>
                                </Button>
                            </Tooltip>
                        }
                        autoFocusFirstInput
                        modalProps={{
                            cancelButtonProps: {hidden: true},
                            cancelText: 'Close',
                            closable: false
                        }}
                        submitter={{
                            submitButtonProps: {
                                style: {
                                    display: 'none',
                                },
                            },
                        }}
                    >
                        <PaymentCycleCustomerBreakdownByAsset cycle_id={this.props.params.id}
                                                              customer_id={entity.podio}/>
                    </ModalForm>
                </ConfigProvider>
            }
        },
    ];

    onSearch = (params: PaymentCycleResult) => {
        let filteredData: PaymentCycleResult[] = JSON.parse(JSON.stringify(this.state.data.customerResultsBreakdown))
        Object.keys(params).map((key) => {
            if (params[key] == '') return true
            filteredData = filteredData.filter((datum) => {
                // console.log(key, params[key], datum[key].toString().toLowerCase().includes(params[key].toLowerCase()))
                // return datum[key] == params[key] // strict comparison
                return datum[key].toString().toLowerCase().includes(params[key].toLowerCase()) // loose comparison
            })
        })
        this.setState({filteredData})
    }
    onReset = () => {
        this.onSearch({} as any)
    }

    downloadHistoryExport = async () => {
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(API + '/api/paymentCycles/downloadHistoryExport/' + this.props.params.id, null,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
            .then(res => {
                // console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'history_export_' + this.props.params.id + '.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({message: 'Success'})
            }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    downloadMonthlyExport = async () => {
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(API + '/api/paymentCycles/downloadMonthlyExport/' + this.props.params.id, null,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
            .then(res => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'monthly_export_' + this.props.params.id + '.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({message: 'Success'})
            }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    render() {

        const dividerHeight = 70

        return (
            <div>

                {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}
                {/*<Space>*/}

                {
                    this.state.data &&
                    <div>

                        <Row justify={"space-between"}>
                            <Typography.Title level={5}>Summary</Typography.Title>
                            <Space>
                                <Button onClick={this.downloadMonthlyExport}>Export 'Monthly' Report</Button>
                                <Button onClick={this.downloadHistoryExport}>Export 'History' Report</Button>
                            </Space>
                        </Row>

                        <br/>

                        {/*<ProCard title={"Summary"} bordered={false}>*/}
                        <Card bodyStyle={{padding: 12}}>

                            <Row justify={"space-evenly"}>

                                {/*<RcResizeObserver*/}
                                {/*    key="resize-observer"*/}
                                {/*    onResize={(offset) => {*/}
                                {/*        console.log(offset)*/}
                                {/*        this.setState({responsive: offset.width < 576});*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <ProCard.Group title="Summary" direction={this.state.responsive ? 'column' : 'row'}>*/}
                                {/*<ProCard.Group title="Summary" direction={'row'}>*/}
                                <Space>
                                    <ProCard>
                                        <Statistic title="Gross Euro" value={this.state.data.grossEuro} precision={2}
                                                   suffix="€"/>
                                    </ProCard>
                                </Space>
                                {/*<Divider type={this.state.responsive ? 'horizontal' : 'vertical'}/>*/}
                                <Divider type={'vertical'} style={{height: dividerHeight}}/>
                                <Space>
                                    <ProCard>
                                        <Statistic title="Client Euro" value={this.state.data.customerAndAffiliatesEuro}
                                                   precision={2} suffix="€"/>
                                    </ProCard>
                                </Space>
                                <Divider type={'vertical'} style={{height: dividerHeight}}/>
                                {/*<Divider type={this.state.responsive ? 'horizontal' : 'vertical'}/>*/}
                                <Space>
                                    <ProCard>
                                        <Statistic title="DM Euro" value={this.state.data.dmEuro} precision={2}
                                                   suffix="€"/>
                                    </ProCard>
                                </Space>
                                {/*<Divider type={this.state.responsive ? 'horizontal' : 'vertical'}/>*/}
                                <Divider type={'vertical'} style={{height: dividerHeight}}/>
                                <Space>
                                    <ProCard>
                                        <Statistic title="Unmatched Revenue Euro"
                                                   value={this.state.data.unmatchedEuro} precision={2} suffix="€"/>
                                    </ProCard>
                                </Space>
                                {/*</ProCard.Group>*/}
                                {/*</RcResizeObserver>*/}
                            </Row>
                            {/*</ProCard>*/}
                        </Card>

                        <br/>
                        {/*<Divider/>*/}

                        <ConfigProvider locale={enUSIntl}>

                            <ProTable<PaymentCycleResult>
                                columns={this.columns}
                                actionRef={this.state.actionRef as any}
                                // cardBordered
                                bordered
                                // size={"small"}
                                // style={{fontSize:6}}
                                // showHeader={false}
                                // headerTitle={"Logs"}
                                polling={this.props.polling || 0}
                                // request={async (params = {}, sort, filter) => {
                                //     try {
                                //         const res = await postRequest('/api/paymentCycles/getResultsAnalytics/' + this.props.cycle_id)
                                //         const data = res.data || []
                                //         console.log(res.data)
                                //         return {data, success: true, total: data.length}
                                //     } catch (e) {
                                //         return {data: [], success: false, total: 0}
                                //     }
                                // }}
                                rowKey={(record) => record.podio}
                                dataSource={this.state.filteredData ? this.state.filteredData : this.state.data.customerResultsBreakdown}
                                locale={{
                                    emptyText: <Space direction={'vertical'}> <FileTextOutlined/>No exported unmatched
                                        files</Space>
                                }}
                                pagination={{pageSize: this.props.pageSize || 10, hideOnSinglePage: true}}
                                dateFormatter="string"
                                // toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                                toolBarRender={false}
                                // toolBarRender={() => []}
                                // scroll={{x: 1600}}
                                // search={false}
                                onSubmit={this.onSearch}
                                onReset={this.onReset}
                                search={{layout: "vertical"}}
                            />
                            {/*</Card>*/}


                        </ConfigProvider>

                    </div>

                }

                {this.state.loading &&
                <Row justify={"center"}>
                    <Spin/>
                </Row>
                }

            </div>
        );
    }
}

export default withRouter(PaymentCycleResultsAnalytics);