import * as React from 'react';
import {Avatar, Row, Space, Typography} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {ProTable} from "@ant-design/pro-components";
import {numFormatterInt} from "./TikTokBenchmarks";
import {useAtom, useAtomValue} from "jotai";
import {channelsAtom, rankingViewsAtom} from "./atoms";
import dayjs from "dayjs";

type Props = {};

export const RankingView = (props: Props) => {
    const [{data: rankingViews}] = useAtom(rankingViewsAtom)
    const {data: accounts} = useAtomValue(channelsAtom)
    return (
        <div
            style={{width: '100%'}}
        >
            <Row style={{padding: 4, marginBottom: 8}}>
                <Typography.Text type={'secondary'}>
                    <InfoCircleOutlined/> Based on the most recent data
                    available.</Typography.Text>
            </Row>
            <ProTable<API.Benchmarks.TiktokBenchmarksAccountDataPoint>
                // actionRef={actionRefRanking}
                dataSource={rankingViews}
                rowKey={'channelID'}
                search={false}
                toolBarRender={false}
                // style={{width: '100%'}}
                columns={[
                    {
                        title: 'Rank',
                        dataIndex: 'rank',
                        key: 'rank',
                        valueType: 'indexBorder',
                        // render: (value, record, index) => index + 1,
                        width: 120,
                        align: 'center',
                    },
                    {
                        render: (dom, entity) => {
                            const img = accounts.find((a) => a.userID === entity.userID)?.image
                            return <Avatar
                                src={`data:image/jpeg;base64,${img}`}
                                style={{width: 34, height: 34}}
                                shape={"circle"}
                            />
                        },
                        width: 70,
                    },
                    {
                        title: 'Account',
                        dataIndex: 'userID',
                        key: 'userID',
                        sorter: (a, b) => a.userID.localeCompare(b.userID),
                        // render the name with an icon to open the channel in a new tab
                        render: (value, record) => {
                            const name = accounts.find((a) => a.userID === record.userID)?.name
                            return (
                                <Space direction={"vertical"} size={0}>
                                    <Typography.Link strong
                                                     href={`https://www.tiktok.com/@${record.userID}`}
                                                     target={'_blank'}
                                    >{value}</Typography.Link>
                                    <Typography.Text type={'secondary'}>{name}</Typography.Text>
                                </Space>
                            )
                        }
                    },
                    {
                        title: 'Followers',
                        dataIndex: 'followers',
                        key: 'followers',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.followers - b.followers,
                    },
                    {
                        title: 'Following',
                        dataIndex: 'following',
                        key: 'following',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.following - b.following,
                    },
                    {
                        title: 'Likes',
                        dataIndex: 'likes',
                        key: 'likes',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.likes - b.likes,
                    },
                    {
                        title: 'Uploads',
                        dataIndex: 'uploads',
                        key: 'uploads',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.uploads - b.uploads,
                    },
                    {
                        title: 'Date',
                        dataIndex: 'date',
                        renderText: (value) => dayjs(value).format('YYYY-MM-DD'),
                        tooltip: 'Date of latest data retrieval',
                    }
                ]}
                pagination={false}
                cardBordered
            />
        </div>
    );
};

export default RankingView;