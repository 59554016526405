import React, {Component} from 'react';
import './index.css';
import NewContract from '../NewContract';
import withRouter from "../../../components/withRouter";

interface IMatchParams {
    id: string;
}

export interface IIndexProps {
    //Here we pass the Props Interface
    params: any
}

export interface IIndexState {
    //here we pass the State Interface
}

//class ComponentName Component<PropsInterface, StateInterface>
class EditContract extends Component<IIndexProps, IIndexState> {

    //Component State
    state = {}

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }

    render() {
        return (
            <>
                <NewContract edit id={+this.props.params.id}/>
            </>
        );
    }
}

export default withRouter(EditContract);