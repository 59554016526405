import * as React from 'react';
import {useState} from 'react';
import {Alert, Button, Form, message, Tooltip,} from "antd";
import {ModalForm} from '@ant-design/pro-components';
import {FileExcelOutlined, UploadOutlined} from "@ant-design/icons";
import {ProFormUploadDragger} from "@ant-design/pro-form";
import axios from "axios";
import {API} from "../../../common/constants";
import {auth} from "../../../config/firebase";

// const {Dragger} = Upload;

type Props = {
    id?: number
};

const ImportUpdateReleasesExcel = (props: Props) => {
    const [uploading, setUploading] = useState(false)
    // const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [form] = Form.useForm();
    const [errorList, setErrorList] = useState<string[]>([])

    const fileList = Form.useWatch('dragger', form);

    // useEffect(() => {
    //     console.log({fileList});
    // }, [fileList]);


    const uploadExcel = async () => {
        if (fileList?.length === 0) {
            message.error('Please select a file');
            return false;
        }

        setUploading(true)
        setErrorList([])

        const formData = new FormData();
        for (const file of fileList)
            formData.append("file", file.originFileObj);

        const token = await auth.currentUser?.getIdToken()
        let endpoint = API + '/api/products/releases/import';

        return axios.post(endpoint, formData, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            },
        }).then(res => {
            message.success('Uploaded successfully');
            setUploading(false);
            setErrorList([])
            return true;
        }).catch(reason => {
            console.log({reason})
            // message.error('Failed to upload');
            setUploading(false);
            setErrorList([reason.response.data.message])
            return false;
        })

    }

    return (
        <ModalForm
            form={form}
            width={400}
            title={"Import/Update Releases from excel"}
            trigger={
                <Tooltip title={'Import/Update from excel'}>
                    <Button type={"text"} icon={<UploadOutlined/>}/>
                </Tooltip>
            }
            submitter={{submitButtonProps: {loading: uploading, disabled: fileList?.length === 0}}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                okText: 'Submit',
                cancelText: 'Cancel',
                afterClose: () => {
                    form.resetFields();
                    setErrorList([])
                }
            }}
            onFinish={async (values) => {
                return uploadExcel()
                // console.log({draggerVal: fileList});
            }}
        >

            {errorList && errorList.map((error, index) => (
                <Alert type={'error'} message={error} key={index} closable/>
            ))}

            <ProFormUploadDragger
                name={'dragger'}
                icon={<FileExcelOutlined/>}
                title='Click or drag file to this area to upload'
                description='A file can contain multiple releases.'
                // help={<>Click <a href='#'>here</a> to download a sample file.</>} // TODO
                help={<>Export to excel to get the Excel template.</>} // TODO
                fieldProps={{
                    beforeUpload: (file) => {
                        // console.log(file);
                        return false;
                    },
                    maxCount: 1,
                    // onChange: handleChange,
                    disabled: uploading
                }}
                accept={'.xlsx'}
            />
            {/*<Dragger {...uploadProps}>*/}
            {/*    <p className="ant-upload-drag-icon">*/}
            {/*        <InboxOutlined />*/}
            {/*    </p>*/}
            {/*    <p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
            {/*    <p className="ant-upload-hint">*/}
            {/*        Support for a single or bulk upload. Strictly prohibited from uploading company data or other*/}
            {/*        banned files.*/}
            {/*    </p>*/}
            {/*</Dragger>*/}

        </ModalForm>
    );
};

export default ImportUpdateReleasesExcel;