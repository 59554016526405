import React, {Component} from 'react';
import './index.css';
import {Table} from 'antd';
import {roundNum} from '../../../common/utils';
import {postRequest} from "../../../api/postRequest";

export interface IResultsCustomerProps {
    //Here we pass the Props Interface
    customer_id: number
}

export interface IResultsCustomerState {
    //here we pass the State Interface
    data: any
    loading: boolean,
}

//class ComponentName Component<PropsInterface, StateInterface>
class ResultsCustomer extends Component<IResultsCustomerProps, IResultsCustomerState> {

    constructor(props: IResultsCustomerProps) {
        super(props)

        this.state = {
            data: [],
            loading: false,

        }
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getFunc()
    }

    async getFunc() {
        this.setState({loading: true})

        const res = await this.getCustomerResults(this.props.customer_id)
        this.setState({data: res.data.result || []})

        this.setState({loading: false})

    }


    getCustomerResults = (customer_id: number) => {

        return postRequest(`/api/erp/newcycle/results_customer`, {customer_id: customer_id})

    }


    numberColumnConfig = (config: 'origin' | 'destination' | 'none') => {
        return {
            align: 'right' as const,
            render: (text: string, record: any) => {
                if (config == 'origin') return record.origin_currency_symbol + ' ' + roundNum(parseFloat(text), 3)
                if (config == 'destination') return record.destination_currency_symbol + ' ' + roundNum(parseFloat(text), 3)
                if (config == 'none') return roundNum(parseFloat(text), 3)
            }
        }
    }


    columns = [
        // { title: 'Product Category', dataIndex: 'product_category', key: 'product_category', width: 600, ellipsis: true, render: (text: string) => text.substr(text.lastIndexOf('/') + 1) },
        {title: 'Key', dataIndex: 'ppk_value', key: 'ppk_value'},
        {title: 'Product Category', dataIndex: 'product_category', key: 'product_category'},
        {title: 'Source', dataIndex: 'source', key: 'source'},
        // { title: 'Asset ID', dataIndex: 'asset_id', key: 'asset_id', ...this.numberColumnConfig('none'), width: 80 },
        // { title: 'Origin currency', dataIndex: 'origin_currency_code', key: 'origin_currency_code', },
        // { title: 'Origin_currency_symbol', dataIndex: 'origin_currency_symbol', key: 'origin_currency_symbol', },
        {
            title: 'Gross Revenue (Platform)',
            dataIndex: 'gross_deposited',
            key: 'gross_deposited', ...this.numberColumnConfig('origin'),
        },
        {title: 'Commision', dataIndex: 'commision', key: 'commision', ...this.numberColumnConfig('none'),},
        {title: 'Rate', dataIndex: 'rate', key: 'rate', align: 'right' as 'right', ellipsis: true},
        {
            title: 'Gross Revenue',
            dataIndex: 'gross_deposited_euro',
            key: 'gross_deposited_euro', ...this.numberColumnConfig('destination'),
        },
        {title: 'DM Fee', dataIndex: 'dm_net', key: 'dm_net', ...this.numberColumnConfig('destination'),},
        {
            title: 'Client Revenue',
            dataIndex: 'client_net',
            key: 'client_net', ...this.numberColumnConfig('destination'),
        },
        {title: 'Affiliate id', dataIndex: 'affiliate_id', key: 'affiliate_id', ...this.numberColumnConfig('none')},
        {
            title: 'Affiliate Commision',
            dataIndex: 'affiliate_commision',
            key: 'affiliate_commision', ...this.numberColumnConfig('none')
        },
        {
            title: 'Affiliate Share',
            dataIndex: 'affiliation_fees',
            key: 'affiliation_fees', ...this.numberColumnConfig('destination')
        },
        // { title: 'Afiliate Share', dataIndex: 'affiliation_fees', key: 'affiliation_fees', },
        // { title: 'Destination currency', dataIndex: 'destination_currency_code', key: 'destination_currency_code', },
        // { title: 'destination_currency_symbol', dataIndex: 'destination_currency_symbol', key: 'destination_currency_symbol' },
    ];

    render() {
        return (
            <div className="ResultsCustomer" style={this.style}>

                <Table
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{hideOnSinglePage: true}}
                    loading={this.state.loading}

                />
            </div>
        );
    }
}

export default ResultsCustomer;