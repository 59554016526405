import {Column, Line} from '@ant-design/plots';
import {Empty, Row, Tag} from 'antd';
import Text from 'antd/lib/typography/Text';
import React, {Component} from 'react';
import {aggregatedMetric} from '../../../api/aggregateReportBuilder';
import {roundNum} from '../../../common/utils';
import './index.css';
import moment from "moment";

// const wildCardDimension = 'day'
const decimals = 2

export interface IDashboardReportingGraphV2Props {
    //Here we pass the Props Interface
    metric: aggregatedMetric
    cumulative: boolean
    label: string | false
    data?: any
    dimension: string
    unit: string
    slider?: boolean
}

export interface IDashboardReportingGraphV2State {
    //here we pass the State Interface
    // isLoading: boolean;
    // data?: any
    // formRef: React.RefObject<FormInstance<any>>;
    // dimension?: string
    // dimension: 'day' | 'month'
}


//class ComponentName Component<PropsInterface, StateInterface>
class DashboardReportingGraphV2 extends Component<IDashboardReportingGraphV2Props, IDashboardReportingGraphV2State> {

    constructor(props: IDashboardReportingGraphV2Props) {
        super(props)

        this.state = {
            isLoading: false,
            // formRef: React.createRef<FormInstance<any>>()
        }
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {

    }


    render() {
        const graphHeight = 420
        // const graphHeight = '100%'

        // const data =
        let NonCumulativeGraphComp
        if (this.props.dimension == 'month')
            NonCumulativeGraphComp = Column
        else
            NonCumulativeGraphComp = Line


        return (
            <div className="DashboardReportingGraphV2" style={{width: '100%'}}>


                {/* Graph */}
                <Row>
                    <div style={{height: graphHeight, width: '100%'}}>
                        {this.props.label && <Row justify='end'>
                            <Tag>{this.props.label + (this.props.unit ? ' (' + this.props.unit + ')' : '')}</Tag>
                        </Row>}

                        {!this.props.data &&
                        <Row justify='center' style={{height: graphHeight, flex: 1, alignContent: 'center'}}> <Empty
                            description={<Text type='secondary'>Please select some parameters.</Text>}/></Row>}

                        {this.props.data && !this.props.cumulative &&
                        <div style={{height: graphHeight, flex: 1}}>
                            <NonCumulativeGraphComp data={this.props.data.map((row: any) => {
                                return {[this.props.dimension]: row[0], [this.props.metric]: row[1]}
                            })}
                                       xField={this.props.dimension}
                                       yField={this.props.metric}
                                       autoFit
                                       tooltip={{
                                           title: (title, datum) => {
                                               if (this.props.dimension === 'day')
                                                   return moment(datum[this.props.dimension]).format("ddd, MMM D, YYYY")

                                               else // if (this.props.dimension === 'month')
                                                   return moment(datum[this.props.dimension]).format("MMM YYYY")
                                           },

                                           formatter: (datum) => {
                                               // console.log('datum', datum, datum[this.props.metric] % 1 === 0 );
                                               // console.log('datum', datum, datum[this.props.dimension]);
                                               if (this.props.dimension === 'day')
                                                   return {
                                                       name: moment(datum[this.props.dimension]).format("ddd, MMM D, YYYY"),
                                                       value: roundNum(datum[this.props.metric], decimals) + this.props.unit
                                                   };
                                               else // if (this.props.dimension === 'month')
                                                   return {
                                                       name: moment(datum[this.props.dimension]).format("MMM YYYY"),
                                                       value: roundNum(datum[this.props.metric], decimals) + this.props.unit
                                                   };
                                           },
                                       }}
                                       xAxis={{
                                           label: {
                                               formatter: (v) => {
                                                   if (this.props.dimension === 'month')
                                                       return moment(v).format("MMM YYYY")
                                                   if (this.props.dimension === 'day')
                                                       return moment(v).format("MMM D, YYYY")
                                                   return v
                                               },
                                           },
                                       }}
                                       yAxis={{
                                           label: {
                                               formatter: (v) => {
                                                   return v + ' ' + this.props.unit
                                               },
                                           },
                                       }}
                                       slider={this.props.slider ? {
                                           start: 0,
                                           end: 1,
                                       } : false}
                            />
                        </div>
                        }

                        {this.props.data && this.props.cumulative &&
                        // <div style={{ height: graphHeight, flex: 1 }}>
                        <Line
                            data={this.props.data.map((row: any) => {
                                return {[this.props.dimension]: row[0], [this.props.metric]: row[1]}
                            })}
                            xField={this.props.dimension}
                            yField={this.props.metric}
                            autoFit
                            tooltip={{
                                title: (title, datum) => {
                                    if (this.props.dimension === 'day')
                                        return moment(datum[this.props.dimension]).format("ddd, MMM D, YYYY")

                                    else // if (this.props.dimension === 'month')
                                        return moment(datum[this.props.dimension]).format("MMM YYYY")
                                },

                                formatter: (datum) => {
                                    // console.log('datum', datum, datum[this.props.metric] % 1 === 0 );
                                    // console.log('datum', datum, datum[this.props.dimension]);
                                    if (this.props.dimension === 'day')
                                        return {
                                            name: moment(datum[this.props.dimension]).format("ddd, MMM D, YYYY"),
                                            value: roundNum(datum[this.props.metric], decimals) + this.props.unit
                                        };
                                    else // if (this.props.dimension === 'month')
                                        return {
                                            name: moment(datum[this.props.dimension]).format("MMM YYYY"),
                                            value: roundNum(datum[this.props.metric], decimals) + this.props.unit
                                        };
                                },
                            }}
                            xAxis={{
                                label: {
                                    formatter: (v) => {
                                        if (this.props.dimension === 'month')
                                            return moment(v).format("MMM YYYY")
                                        if (this.props.dimension === 'day')
                                            return moment(v).format("MMM D, YYYY")
                                        return v
                                    },
                                },
                            }}
                            yAxis={{
                                label: {
                                    formatter: (v) => {
                                        return v + ' ' + this.props.unit
                                    },
                                },
                            }}
                        />
                            // </div>
                        }

                    </div>

                </Row>


            </div>
        );
    }
}

export default DashboardReportingGraphV2;