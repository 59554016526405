import { useAtomValue } from 'jotai';
import React from 'react';
import { releasesActionRefAtom } from '../ddex_atoms';
import { ModalForm } from '@ant-design/pro-form';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axiosApiInstance from '../../../../api/axiosClient';
import { useNavigate, useParams } from 'react-router-dom';

const REDIRECT_URL = `/music/artists/`;

const DeleteArtistForm = () => {
    const { key_name } = useParams();
    const navigate = useNavigate();
    const releasesActionRef = useAtomValue(releasesActionRefAtom);

    return (
        <ModalForm
            open
            width={400}
            title={`Delete Artist: ${key_name}`}
            submitter={{ submitButtonProps: { danger: true } }}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                okText: 'Delete',
                cancelText: 'No',
                onCancel: () => navigate(REDIRECT_URL)
            }}
            onFinish={async (values) => {
                return axiosApiInstance
                    .delete(`/api/products/artists/${key_name}/`)
                    .then((res) => {
                        // console.log(res)
                        // queryClient.invalidateQueries({queryKey: ['creators','items']})
                        releasesActionRef?.current?.reload();
                        navigate(REDIRECT_URL);
                        return true;
                    })
                    .catch((reason) => {
                        console.log(reason);
                    });
            }}
        >
            Are you sure?
        </ModalForm>
    );
};

export default DeleteArtistForm;
