import React, {Component} from 'react';
import './index.css';
import axios from 'axios';
import {API} from '../../../common/constants';
import {Button, Col, Input, message, Popconfirm, Row, Table} from 'antd';
import {auth} from "../../../config/firebase";
import {getRequest} from "../../../api/getRequest";

export interface IAdminSocialPlatformsProps {
    //Here we pass the Props Interface
}

export interface IAdminSocialPlatformsState {
    //here we pass the State Interface
    rows: number;
    data: any;
    newtag: string;
    newdescr: string;
    isLoading: boolean;
}

const adminpage = 'socialplatform';

//class ComponentName Component<PropsInterface, StateInterface>
class AdminSocialPlatforms extends Component<IAdminSocialPlatformsProps, IAdminSocialPlatformsState> {
    constructor(props: IAdminSocialPlatformsProps) {
        super(props);

        this.addFunc = this.addFunc.bind(this);
        this.getFunc = this.getFunc.bind(this);
        this.editFunc = this.editFunc.bind(this);
        this.deleteFunc = this.deleteFunc.bind(this);

        this.state = {rows: 0, data: [], newtag: '', newdescr: '', isLoading: false};
    }

    newTagChange = (e: { target: { value: any } }) => {
        this.setState({
            newtag: e.target.value,
        });
    };

    newDescrChange = (e: { target: { value: any } }) => {
        this.setState({
            newdescr: e.target.value,
        });
    };

    editFunc(id: string) {
    }

    deleteFunc = async (id: string) => {
        var session_url = API + '/api/admin/delete' + adminpage;
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(
                session_url,
                {
                    withCredentials: true,
                },
                {
                    params: {
                        id: id,
                    },
                    headers: {
                        Accept: '*/*',
                        Authorization: `Bearer ${token}`
                    },
                }
            )
            .then((response) => {
                console.log('Authenticated');

                this.getFunc();
                message.success('Deleted successfully.');
            })
            .catch(function (error) {
                console.log('Error on Authentication Delete');
                message.success('An error occured.');
            });
    }

    getFunc() {
        this.setState({isLoading: true});

        getRequest('/api/admin/' + adminpage)
            .then((response) => {
                console.log('Authenticated');
                console.log(response);
                this.setState({data: JSON.parse(response.data['rows'])});
                // console.log(this.state.data["arr"]["length"])
                this.setState({rows: this.state.data['arr']['length']});
                this.setState({isLoading: false});
            })
            .catch((error) => {
                console.log('Error on GET :' + error);
                this.setState({isLoading: false});
            });
    }

    addFunc = async () => {
        //Check fields
        const session_url = API + '/api/admin/add' + adminpage;

        const token = await auth.currentUser?.getIdToken()
        axios
            .post(
                session_url,
                {withCredentials: true},
                {
                    params: {
                        descr: this.state.newdescr,
                        logo_tag: this.state.newtag,
                    },
                    headers: {
                        Accept: '*/*',
                        Authorization: `Bearer ${token}`
                    },
                }
            )
            .then((response) => {
                console.log(response);
                message.success('Added successfully.');
                this.getFunc();
            })
            .catch(function (error) {
                console.log('Error on Authentication ADD');
                message.success('An error occured.');
            });
    }

    // Before the component mounts, we initialise our state
    componentWillMount() {
        this.getFunc();
    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }

    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '20%',
            },
            {
                title: 'Description',
                dataIndex: 'descr',
                width: '25%',
                // editable: true,
                ellipsis: true,
            },
            {
                title: 'Log tag',
                dataIndex: 'logo_tag',
                width: '25%',
                ellipsis: true,
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                width: '30%',
                render: (value: any, record: any) => (
                    <>
                        <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteFunc(record.id)}>
                            <a>Delete</a>
                        </Popconfirm>
                        <Button disabled type="link">
                            Edit
                        </Button>
                    </>
                ),
            },
        ];
        const colSpan = 12;
        return (
            <>
                {/*<MainLayout*/}
                {/*    breadcrumpLinks={['/admin', '/socialplatforms']}*/}
                {/*    breadcrumpTitles={['Admin Dashboard', 'Social Platforms']}*/}
                {/*>*/}
                <Row>
                    <Col span={colSpan}>
                        <Row>
                            {this.state.data.arr && (
                                <Table
                                    columns={columns}
                                    dataSource={[...this.state.data.arr]}
                                    // pagination={{ showSizeChanger: false, pageSize: 5 }}
                                    pagination={false}
                                    loading={this.state.isLoading}
                                    scroll={{y: 330}}
                                />
                            )}
                        </Row>

                        <Row style={{marginTop: 8}}>
                            <Input.Group compact>
                                <Input
                                    addonBefore="auto id"
                                    style={{
                                        width:
                                            parseInt(columns[0].width.split('%')[0]) +
                                            parseInt(columns[1].width.split('%')[0]) +
                                            '%',
                                    }}
                                    value={this.state.newdescr}
                                    onChange={this.newDescrChange}
                                    placeholder="description"
                                />
                                <Input
                                    style={{width: columns[2].width}}
                                    value={this.state.newtag}
                                    onChange={this.newTagChange}
                                    placeholder="logo tag"
                                />
                                <Button type="primary" onClick={() => this.addFunc()}
                                        style={{width: columns[3].width}}>
                                    Add
                                </Button>
                            </Input.Group>
                        </Row>
                    </Col>
                </Row>
                {/*</MainLayout>*/}
            </>
        );
    }
}

export default AdminSocialPlatforms;
