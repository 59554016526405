import {FieldTimeOutlined, InfoCircleOutlined, LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import {
    Alert,
    Button,
    Card,
    Col,
    ConfigProvider,
    Divider,
    message,
    notification,
    Popconfirm,
    Progress,
    Row,
    Space,
    Spin,
    Table,
    Typography,
    Upload
} from 'antd';
import {TableRowSelection} from 'antd/lib/table/interface';
import Text from 'antd/lib/typography/Text';
import axios from 'axios';
import qs from 'qs';
import React, {Component} from 'react';
import {executeQuery} from '../../../api/executeQuery';
import {postRequest} from '../../../api/postRequest';
import {API} from '../../../common/constants';
import {MatchComputeCategories, MatchComputeKeys, MatchComputeProgress} from '../../../common/models';
import {roundNum} from '../../../common/utils';
import './index.css';
import {ModalForm, ProFormDateRangePicker, ProFormDependency, ProFormSwitch} from "@ant-design/pro-form";
import {enUSIntl} from "@ant-design/pro-provider";
import {auth} from "../../../config/firebase";

export interface IMatchComputeStepProps {
    //Here we pass the Props Interface
    handler?: Function,
    year: number,
    id: number,
    type_cycle: number,
}

export interface IMatchComputeStepState {
    //here we pass the State Interface
    year: number,
    id: number,
    type_cycle: number,
    proceed_disabled: boolean,
    previous_disabled: boolean,
    compute_disabled: boolean,
    loading: boolean,
    rate: number,
    data: any,
    selectedProductKeys?: { [key: string]: any[]; },
    selectedCategoriesKeys?: any[],
    match_statistics?: {
        total_records: number,
        movements_empty_ppk_value: number,
        movements_matched_with_asset_and_customer: number,
        movements_not_matched_with_asset: number
        movements_not_matched_with_customer: number
    }
    time?: string,
    matchComputeProgress?: MatchComputeProgress,
    erasing: boolean
    fileList: any[]

    downloading: boolean
    uploading: boolean
    uploadProgress?: number
    compute_exists: boolean
    compute_exists_disabled: boolean

    products_columns?: any
    products_data?: any
    products_categories?: any
    initializing_keys: boolean

    dateRangeFormValues?: any
}

const MOCK_COMPUTE = false

//class ComponentName Component<PropsInterface, StateInterface>
class MatchComputeStep extends Component<IMatchComputeStepProps, IMatchComputeStepState> {

    constructor(props: IMatchComputeStepProps) {
        super(props)

        this.getLoadedFunc = this.getLoadedFunc.bind(this)
        this.proceedStep = this.proceedStep.bind(this)
        this.previousStep = this.previousStep.bind(this)


        this.state = {
            year: this.props.year,
            id: this.props.id,
            type_cycle: this.props.type_cycle,
            loading: false,
            proceed_disabled: true,
            previous_disabled: false,
            compute_disabled: false,
            compute_exists_disabled: false,
            data: [],
            rate: 0,
            erasing: false,
            fileList: [],
            downloading: false,
            uploading: false,
            compute_exists: false,
            initializing_keys: false
        }
    }

    //Add style here
    style = {};

    getLoadedFunc = async () => {

        postRequest(`/api/erp/newcycle/loadedfiles`, {
            id: this.state.id,
            year: this.state.year
        })
            .then((response) => {
                console.log('Authenticated');
                console.log(response)

                let result: string = response.data.result;
                result = result.replace("[", "")
                result = result.replace("]", "")
                // result = result.replace("./ERP_VAULT/", "")
                const res = result.split(",");


                this.setState({data: res})


            }).catch(function (error) {
            console.log('Error on GET :' + error);
        });
    }

    handleSelectedProductKeysChange = (selectedProductKeys: any, key: string) => {
        this.setState({
            selectedProductKeys: {                   // object that we want to update
                ...this.state.selectedProductKeys,    // keep all other key-value pairs
                [key]: selectedProductKeys       // update the value of specific key
            },
            compute_disabled: false,
            proceed_disabled: true
        })
    }

    handleSelectedCategoriesKeysChange = (selectedCategoriesKeys: any) => {
        this.setState({
            selectedCategoriesKeys: selectedCategoriesKeys,
            compute_disabled: false,
            proceed_disabled: true
        })
    }

    // Before the component mounts, we initialise our state
    componentWillMount() {
        this.getLoadedFunc()
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.initializeSelectBoxes()
        this.checkIfComputationsExist()
    }

    initializeSelectBoxes = async () => {
        this.setState({initializing_keys: true})
        // GET KEYS

        const keys = (await executeQuery('GET_MATCH_COMPUTE_KEYS')).data as MatchComputeKeys[] || [];
        const categories = (await executeQuery('GET_MATCH_COMPUTE_CATEGORIES')).data as MatchComputeCategories[] || []

        // console.log(keys, categories);

        let columns: any = {}

        // const columns: { [key: string]: any } = {
        // 	'YT': [
        // 		{
        // 			title: 'Youtube',
        // 			dataIndex: "type"
        // 		}
        // 	],
        for (const k of keys) {
            if (k.product_key in columns) continue
            columns[k.product_key] = [{title: k.column_name, dataIndex: 'type'}]
        }

        let data: any = {}
        // const data: { [key: string]: any } = {
        // 	'YT': [
        // 		{
        // 			key: "YTDME",
        // 			type: 'Youtube / Entertainment / DME',
        // 		},
        // 		{

        for (const k of keys) {
            if (!data[k.product_key]) data[k.product_key] = []
            data[k.product_key].push({key: k.source_key, type: k.source_descr})
        }

        let categories_keys: any = []
        for (const c of categories) {
            categories_keys.push({key: c.product_category, type: c.product_category})
        }


        // INIT SELECTED KEYS

        let selectedProductKeys: any = []
        if ('computeSelectedProductKeys' in localStorage)
            selectedProductKeys = JSON.parse(localStorage.getItem('computeSelectedProductKeys')!)
        else {
            for (const k of keys) {
                if (!selectedProductKeys[k.product_key]) selectedProductKeys[k.product_key] = []
                selectedProductKeys[k.product_key].push(k.source_key)

            }
        }


        let selectedCategoriesKeys: any = []
        if ('computeSelectedCategoriesKeys' in localStorage)
            selectedCategoriesKeys = JSON.parse(localStorage.getItem('computeSelectedCategoriesKeys')!)
        else {
            selectedCategoriesKeys = categories_keys.map((k: any) => k.key)
        }

        console.log(columns, data, categories_keys, selectedProductKeys, selectedCategoriesKeys);


        this.setState({
            products_columns: columns,
            products_data: data,
            products_categories: categories_keys,
            selectedProductKeys,
            selectedCategoriesKeys,
            initializing_keys: false
        })
    }

    checkIfComputationsExist = async () => {
        const res = await postRequest('/api/erp/newcycle/existscompute')
        let data = res.data.result
        this.setState({compute_exists: data})
    }

    wait(milliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    compute = async () => {
        this.setState({loading: true, proceed_disabled: true, compute_disabled: true})

        if (MOCK_COMPUTE) {
            await this.wait(1000)
            this.setState({loading: false, proceed_disabled: false, compute_exists_disabled: true})
            return
        }


        // let session_url = API + `/api/erp/newcycle/matchcompute`;

        var allSelectedProductKeys: any[] = [];
        Object.keys(this.state.selectedProductKeys!).map((key, index) => allSelectedProductKeys.push(...this.state.selectedProductKeys![key]))

        var allSelectedCategoriesKeys: any[] = [];
        allSelectedCategoriesKeys = [...this.state.selectedCategoriesKeys!]

        // console.log('all', allSelectedProductKeys, allSelectedCategoriesKeys);

        let product_list = allSelectedProductKeys.join()
        let category_list = allSelectedCategoriesKeys.join()

        //SEND COMPUTE REST API CALL
        var data = {
            id: this.state.id,
            year: this.state.year,
            product_list,
            category_list,
            date_range_enabled: this.state.dateRangeFormValues?.date_range_enabled || false,
            start_date: this.state.dateRangeFormValues?.start_date || '',
            end_date: this.state.dateRangeFormValues?.end_date || ''
        }

        postRequest('/api/erp/newcycle/matchcompute', data)

            .then((response) => {
                console.log('Authenticated');
                console.log(response)

                let res = response.data
                let time = res.status
                this.setState({time})

                notification.success({message: 'Results calculated successfully in ' + (res.status), key: 'time'});
                // message.success("Results calculated in " + res.status)

                setTimeout(() => {
                    this.setState({
                        loading: false,
                        proceed_disabled: false,
                        compute_exists_disabled: true,
                        match_statistics: response.data.statistics
                    })
                }, 1000);

                // this.props.handler? this.props.handler() : message.error("Error function call")


            }).catch(function (error) {
            console.log('Error on GET :' + error);
            message.error(error)
        });


        // poll for match-compute progress
        let interval = setInterval(async () => {
            if (this.state.loading == false) {
                clearInterval(interval);
                return;
            }

            const res = await postRequest(`/api/erp/newcycle/matchcomputeprogress`, {
                id: this.state.id,
                year: this.state.year
            })
            console.log('proccessed_records', res.data.result);
            this.setState({matchComputeProgress: res.data.result})


        }, 500);


        // this.setState({ proceed: false, loading: false })
    }


    async eraseComputeResults_depr() {

        this.setState({erasing: true})

        const res = await postRequest(`/api/erp/newcycle/erasecomputeresults`, {
            id: this.state.id,
            year: this.state.year
        })
        this.setState({erasing: false})
        // window.location.reload()
    }




    handleUpload = ({fileList}: any) => {

        console.log('fileList', fileList);

        // you store them in state, so that you can make a http req with them later
        this.setState({fileList});
    };

    handleSubmit = async (event: { preventDefault: () => void; }) => {

        this.setState({uploading: true})
        event.preventDefault();

        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key

        // formData.append("files", this.state.fileList.map((f) => f.originFileObj));
        for (const file of this.state.fileList)
            formData.append("files", file.originFileObj);
        formData.append("year", this.props.year + '');
        formData.append("id", this.props.id + '');

        const token = await auth.currentUser?.getIdToken()

        axios
            .post(API + `/api/erp/newcycle/upload_unmatched`, formData,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent
                        const percent = roundNum((loaded / total) * 100, 2)
                        console.log('progressEvent', progressEvent, percent);
                        this.setState({uploadProgress: roundNum(percent / 2, 2)})
                    }
                })
            .then(res => {
                this.setState({uploadProgress: 100})

                console.log("res", res);

                setTimeout(() => {
                    this.setState({fileList: [], uploading: false})
                }, 1000)

                notification.success({message: 'Successfully uploaded files and updated the missing values.'})

            })
            .catch(err => {
                this.setState({uploading: false})
                console.log("err", err);
            });
    };

    downloadUnmatched = async () => {
        const data = qs.stringify({id: this.state.id, year: this.state.year});

        const session_url = API + `/api/erp/newcycle/download_unmatched`;

        this.setState({downloading: true})
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(session_url, data,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
            .then(res => {
                this.setState({downloading: false})
                console.log("res", res);

                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'unmatched.zip');
                document.body.appendChild(link);
                link.click();

                notification.success({message: 'Successfully exported unmatched files for all input files.'})
            })
            .catch(err => {
                this.setState({downloading: false})
                console.log("err", err);
            });
    };

    render() {

        let totalKeysSelected = 0;
        if (this.state.selectedProductKeys)
            Object.keys(this.state.selectedProductKeys).map((key, index) => totalKeysSelected += this.state.selectedProductKeys![key].length)

        const productKeySelection = (key: string) => ({
            selectedRowKeys: this.state.selectedProductKeys![key],
            onChange: (selectedRowKeys: any) => this.handleSelectedProductKeysChange(selectedRowKeys, key),
            type: 'checkbox' as 'checkbox'
        }) as TableRowSelection<any>

        const productCategorySelection = () => ({
            selectedRowKeys: this.state.selectedCategoriesKeys,
            onChange: (selectedRowKeys: any) => this.handleSelectedCategoriesKeysChange(selectedRowKeys),
            type: 'checkbox' as 'checkbox'
        }) as TableRowSelection<any>

        // const hasSelected = selectedRowKeys.length > 0;
        const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;


        const {fileList} = this.state;


        return (
            <>
                {/* <Row justify="center">
					<Title level={2}> Match & Compute </Title>
				</Row> */}

                <br/>
                <Alert message='Please select the file types to be computed.' type='info' showIcon closable/>
                <br/>

                <Alert message={<>

                    <p>Extract the unmatched records and download them in a zipped version.</p>
                    <Row>
                        <Button onClick={this.downloadUnmatched} loading={this.state.downloading}>
                            Download Unmatched Files (.zip)
                        </Button>
                    </Row>
                    {/* <Divider /> */}

                </>}/>
                <br/>

                <Alert message={<>
                    <p>Upload the fixed unmatched records by selecting the "UNMATCHED" directory with same folder
                        structure and file names as when downloaded. Hit "Submit" to upload the files and load the fixes
                        back to the system.</p>
                    <Row>
                        <Upload
                            fileList={fileList}
                            onChange={this.handleUpload}
                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                            directory
                        >
                            <Button icon={<UploadOutlined/>}>Upload Fixed Unmatched Folder</Button>

                        </Upload>
                        {this.state.uploadProgress !== undefined && this.state.uploading &&
                        <Progress percent={this.state.uploadProgress} status='active'/>}
                    </Row>
                    {this.state.fileList.length > 0 &&
                    <>
                        <br/>
                        <Row>
                            <Button onClick={this.handleSubmit} loading={this.state.uploading}>
                                Submit
                            </Button>
                        </Row>
                    </>
                    }

                </>}/>

                <br/>


                {this.state.match_statistics &&
                <>
                    {/* <Space direction='vertical'> */}
                    {this.state.match_statistics.total_records != 0 &&
                    <Alert message={'Total records: ' + this.state.match_statistics.total_records} type='info' showIcon
                           closable/>}
                    {this.state.match_statistics.movements_empty_ppk_value != 0 && <Alert
                        message={this.state.match_statistics.movements_empty_ppk_value + ' (' + roundNum((this.state.match_statistics.movements_empty_ppk_value / this.state.match_statistics.total_records) * 100, 0) + '%) records do not contain a channel/label.'}
                        type='warning' showIcon closable/>}
                    {this.state.match_statistics.movements_not_matched_with_asset != 0 && <Alert
                        message={this.state.match_statistics.movements_not_matched_with_asset + ' (' + roundNum((this.state.match_statistics.movements_not_matched_with_asset / this.state.match_statistics.total_records) * 100, 0) + '%) records contain a channel/label, but could not be matched with a database asset.'}
                        type='warning' showIcon closable/>}
                    {this.state.match_statistics.movements_not_matched_with_customer != 0 && <Alert
                        message={this.state.match_statistics.movements_not_matched_with_customer + ' (' + roundNum((this.state.match_statistics.movements_not_matched_with_customer / this.state.match_statistics.total_records) * 100, 0) + '%) records were matched with a database asset, but could not be matched with a customer.'}
                        type='warning' showIcon closable/>}
                    {this.state.match_statistics.movements_matched_with_asset_and_customer != 0 && <Alert
                        message={this.state.match_statistics.movements_matched_with_asset_and_customer + ' (' + roundNum((this.state.match_statistics.movements_matched_with_asset_and_customer / this.state.match_statistics.total_records) * 100, 0) + '%) records were matched with an asset and customer.'}
                        type='success' showIcon closable/>}
                    {this.state.time &&
                    <Alert message={'Elapsed time: ' + this.state.time} type='info' showIcon closable/>}
                    {/* </Space> */}
                    <br/>
                </>}

                <Row justify="space-between" align='middle'>

                    <Space>
                        <Button type='primary' disabled={totalKeysSelected === 0 || this.state.compute_disabled}
                                onClick={() => {
                                    this.compute()
                                }} loading={this.state.loading}>Compute Selected</Button>
                        {totalKeysSelected === 0 && <Text type='secondary'>None selected.</Text>}
                    </Space>

                    {/*<Col span={6}>*/}

                    <Space>
                        <ConfigProvider locale={enUSIntl}>
                            <ModalForm<{
                                date_range_enabled: boolean;
                                start_date: string;
                                end_date: string;
                            }>

                                // onFinish={async (values) => console.log(values)}
                                layout={"inline"}
                                // submitter={{render: false}}
                                // onValuesChange={(changedValues, values) => {
                                //     this.setState({dateRangeFormValues: values})
                                //     // console.log(values)
                                // }}

                                // modal
                                title={<><FieldTimeOutlined/> Define Date Range</>}
                                trigger={
                                    <Button type="default">
                                        {/*<PlusOutlined/>*/}
                                        <FieldTimeOutlined/>
                                        Define Date Range
                                    </Button>
                                }
                                // autoFocusFirstInput
                                submitter={{resetButtonProps: false}}
                                modalProps={{
                                    // onCancel: () => console.log('run'),
                                    closable: false,
                                    maskClosable: false,
                                    // okButtonProps={}
                                    okText: "Save",
                                }}
                                onFinish={async (values: any) => {
                                    // await waitTime(2000);
                                    // console.log(values);
                                    message.success('Success');
                                    this.setState({dateRangeFormValues: values})

                                    return true;
                                }}

                            >

                                <Alert
                                    message={"If you define a date range now, only records with a reporting date in this range will be considered for computation."}
                                    showIcon={true}
                                    type={"info"}
                                />
                                <br/>
                                <ProFormSwitch
                                    name={"date_range_enabled"}
                                    label={"Date Range Enable/Disable"}
                                    transform={(value, field, object) => {
                                        return {
                                            date_range_enabled: value,
                                            start_date: undefined,
                                            end_date: undefined
                                        }
                                    }}
                                />
                                <br/>
                                <ProFormDependency name={['date_range_enabled']}>
                                    {({date_range_enabled}) => {
                                        if (date_range_enabled) {
                                            return <ProFormDateRangePicker name="date_range"
                                                                           placeholder={["Start Date", "End Date"]}
                                                                           label={"Pick Date Range"}
                                                                           rules={[{required: true}]}
                                                                           transform={(value, field, object) => {
                                                                               return {
                                                                                   start_date: value[0],
                                                                                   end_date: value[1]
                                                                               }
                                                                           }
                                                                           }/>
                                        }
                                        return null
                                    }}
                                </ProFormDependency>


                            </ModalForm>
                        </ConfigProvider>
                        {this.state.dateRangeFormValues?.start_date && <Typography.Text
                            type={"secondary"}>{this.state.dateRangeFormValues.start_date} to {this.state.dateRangeFormValues.end_date}</Typography.Text>}
                    </Space>
                    {/*</Col>*/}

                    {/* <Space>
						<Button danger onClick={() => { this.eraseComputeResults_depr() }} loading={this.state.erasing} >Erase calculations</Button>
					</Space> */}

                    {/* <Space>
						<Form.Item label="Youtube Rate ($ — €): " name="rate" initialValue={'1'}>
							<InputNumber min={0} max={1} step={0.000000001} style={{ width: "150px" }} defaultValue={0.795313551} onChange={this.onRateChange} value={this.state.rate} />
						</Form.Item>
					</Space> */}

                </Row>
                <br/>


                {this.state.matchComputeProgress && this.state.loading && <Progress status="active"
                                                                                    percent={roundNum((this.state.matchComputeProgress.current_step / this.state.matchComputeProgress.total_steps) * 100, 0)}/>}

                <div className="site-card-wrapper">
                    <Spin
                        // indicator={<></>}
                        spinning={this.state.loading}
                        // tip={
                        // 	this.state.matchComputeProgress &&
                        // 	'Asset matching process: ' + roundNum((this.state.matchComputeProgress.asset_proccessed_records / this.state.matchComputeProgress.total_records) * 100, 0) + '%\n' +
                        // 	'Customer matching process: ' + roundNum((this.state.matchComputeProgress.customer_proccessed_records / this.state.matchComputeProgress.total_records) * 100, 0) + '%\n' +
                        // 	'Compute process: ' + roundNum((this.state.matchComputeProgress.compute_proccessed_records / this.state.matchComputeProgress.total_records) * 100, 0) + '%'
                        // }
                        tip={this.state.matchComputeProgress && this.state.matchComputeProgress.message + '...'}
                        style={{width: '100%', whiteSpace: 'pre-line'}}>


                        {this.state.initializing_keys || !this.state.products_data ? <Spin/> :
                            <>
                                <Divider>Select Sources</Divider>

                                <Row gutter={[16, 16]}>
                                    {Object.keys(this.state.products_data).map((key) => <>
                                        <Col span={6}>
                                            <Card bodyStyle={{padding: "0"}} hoverable>
                                                <Table style={{width: "100%"}}
                                                       dataSource={this.state.products_data[key]}
                                                       columns={this.state.products_columns[key]}
                                                       rowSelection={productKeySelection(key)} pagination={false}/>
                                            </Card>
                                        </Col>
                                    </>)}
                                </Row>

                                <Divider>Select Categories</Divider>

                                <Row gutter={[16, 16]}>
                                    <Col span={6}>
                                        <Card bodyStyle={{padding: "0"}} hoverable>
                                            <Table style={{width: "100%"}} dataSource={this.state.products_categories}
                                                   columns={[{title: 'Product Categories', dataIndex: 'type'}]}
                                                   rowSelection={productCategorySelection()} pagination={false}/>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Spin>
                </div>

                <br/>
                <Row justify="space-between" align="middle" style={{paddingTop: "10px"}}>

                    <Button onClick={() => this.previousStep()} disabled={this.state.previous_disabled}>Previous
                        Step</Button>

                    <Space>
                        {this.state.compute_exists === true && this.state.compute_exists_disabled === false &&
                        <Popconfirm title={'Previous computations exist in the database.\n Continue with them?'}
                                    onConfirm={this.proceedStep} okText='Yes, continue to next step'>
                            <InfoCircleOutlined/>
                        </Popconfirm>
                        }

                        {this.state.compute_exists === false && this.state.compute_exists_disabled === false &&
                        <Popconfirm title={'No previous computations exist in the database.'}>
                            <InfoCircleOutlined/>
                        </Popconfirm>
                        }

                        <Button type={"primary"} onClick={() => this.proceedStep()}
                                disabled={this.state.proceed_disabled}>Next Step</Button>
                    </Space>

                </Row>

            </>
        );
    }

    previousStep(): void {
        this.setState({previous_disabled: true});

        postRequest(`/api/erp/newcycle/proceedcycle`, {
            id: this.state.id,
            year: this.state.year,
            step: "1"
        })
            .then((response) => {
                console.log('Authenticated');
                console.log(response)
                // window.location.reload()

                this.cacheSelectedKeys()

                this.props.handler ? this.props.handler() : window.location.reload()
            }).catch((error) => {
            console.log('Error on GET :' + error);
        });
    }

    proceedStep(): void {

        this.setState({proceed_disabled: true});

        postRequest(`/api/erp/newcycle/proceedcycle`, {
            id: this.state.id,
            year: this.state.year,
            step: "3"
        })
            .then((response) => {
                console.log('Authenticated');
                console.log(response)

                this.cacheSelectedKeys()

                this.props.handler ? this.props.handler() : message.error("Error function call")
            }).catch(function (error) {
            console.log('Error on GET :' + error);
        });

    }

    cacheSelectedKeys() {
        try {
            localStorage.setItem('computeSelectedProductKeys', JSON.stringify(this.state.selectedProductKeys))
            localStorage.setItem('computeSelectedCategoriesKeys', JSON.stringify(this.state.selectedCategoriesKeys))
        } catch (e) {
            console.log('Local storage error');
        }
    }
}

export default MatchComputeStep;