import {
    AppstoreAddOutlined,
    BoxPlotOutlined,
    CheckCircleOutlined,
    CheckSquareOutlined,
    DollarOutlined,
    ExclamationCircleOutlined,
    SolutionOutlined
} from '@ant-design/icons';
import {Button, message, Row, Steps, Typography} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import React, {Component} from 'react';
import FinalizeStep from '../../components/FinalizeStep';
import InitiateStep from '../../components/InitiateStep';
import LoadStep from '../../components/LoadStep';
import MatchComputeStep from '../../components/MatchComputeStep';
import ResultsStep from '../../components/ResultsStep';
import './index.css';
import CloseCycle from '../../components/CloseCycle';
import {postRequest} from "../../../api/postRequest";
import {PageContainer} from "@ant-design/pro-components";

export interface INewBillableCycleProps {
    //Here we pass the Props Interface
}

export interface INewBillableCycleState {
    //here we pass the State Interface
    current: number,
    id: number,
    year: number,
    type_cycle: number,
    // session_cache: { [key: string]: any }
}


interface BilingCycleCheckResponse {
    id: number,
    year: number,
    type_cycle: number,
}


const FINAL_STEP_NUMBER = 6

//class ComponentName Component<PropsInterface, StateInterface>
class NewBillableCycle extends Component<INewBillableCycleProps, INewBillableCycleState> {

    constructor(props: INewBillableCycleProps) {
        super(props);
        this.checkifbillablecycle = this.checkifbillablecycle.bind(this)
        this.state = {
            current: 0,
            id: -1,
            year: -1,
            type_cycle: -1,
            // session_cache: {}
        }
    }

    //Add style here
    style = {};

    cycleStep(id: number, year: number, type_cycle: number) {

        var cycle_steps = [
            {
                title: 'Initiate',
                content: <InitiateStep id={id} year={year} type_cycle={type_cycle}
                                       handler={() => this.checkifbillablecycle()}/>,
            },
            {
                title: 'Load',
                content: <LoadStep id={id} year={year} type_cycle={type_cycle}
                                   handler={() => this.checkifbillablecycle()}/>,
            },
            {
                title: 'Match & Compute',
                content: <MatchComputeStep id={id} year={year} type_cycle={type_cycle}
                                           handler={() => this.checkifbillablecycle()}/>,
            },
            {
                title: 'Results',
                content: <ResultsStep id={id} year={year} type_cycle={type_cycle}
                                      handler={() => this.checkifbillablecycle()}/>,
            },
            {
                title: 'Finalize',
                content: <FinalizeStep id={id + ''} year={year + ''} type_cycle={type_cycle + ''}
                                       handler={() => this.checkifbillablecycle()}/>,
            },
            {
                title: 'Close',
                content: <CloseCycle id={id + ''} year={year + ''} type_cycle={type_cycle + ''}
                                     handler={() => this.checkifbillablecycle()}/>,
            },
        ]

        return cycle_steps[this.state.current].content

    }


    async checkifbillablecycle() {
        postRequest(`/api/erp/newcycle/checkcycle`)
            .then((response) => {

                let result = response.data.status as BilingCycleCheckResponse

                // alert(response.data.status)
                let step = 0
                //if its already initiated go to step 1
                if (result.type_cycle === 0 && result.id !== 0 && result.year !== 0) {
                    step = 1
                }
                //if last cycle is finalised
                if (result.type_cycle === FINAL_STEP_NUMBER || result.id === 0 || result.year === 0) {
                    step = 0
                }
                if (result.type_cycle >= 1 && result.type_cycle < FINAL_STEP_NUMBER) {
                    step = result.type_cycle
                }

                this.setState({id: result.id, year: result.year, type_cycle: result.type_cycle, current: step})

            }).catch(function (error) {
            console.log(error);
            message.error(String(error))
        });
    }


    // Before the component mounts, we initialise our state
    componentWillMount() {
        this.checkifbillablecycle()
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        // console.log('/newbillablecycle');
    }

    scrapeCycle() {
        postRequest(`/api/erp/newcycle/scrapecycle`)
            .then((response) => {

                let result = response.data.status
                console.log(response);

                window.location.reload();

            }).catch(function (error) {
            console.log(error);
            message.error(String(error))
        });
    }

    render() {
        const {Step} = Steps;
        const {Title} = Typography;
        let ribbonText = ""
        if (this.state.id != 0 && this.state.type_cycle !== FINAL_STEP_NUMBER - 1) {
            ribbonText = `Cycle ID: ${this.state.year + "_" + this.state.id}`;
            // ribbonText = `Cycle ID: ${this.state.year + "_" + this.state.id} (Step: ${Number(this.state.type_cycle) + 2})`;
        } else {
            // ribbonText = `Initiate a new Billing Cycle (Step: 1)`
            ribbonText = `New Cycle`
        }
        // console.log('rbntxt', ribbonText);

        return (
            <>
                {/*<MainLayout key={this.state.id} breadcrumpLinks={["ERP", "newbillablecycle"]}*/}
                {/*            breadcrumpTitles={["ERP", <><Space>New Billing Cycle<Tag*/}
                {/*                color={'blue'}>{ribbonText}</Tag></Space></>]}>*/}
                <PageContainer>
                    {/* Scrape */}
                    <Row justify='end'>
                        <Button icon={<ExclamationCircleOutlined/>} danger onClick={() => this.scrapeCycle()}
                                size='small'>Scrape Cycle (Only for Testing)</Button>
                    </Row>
                    <br/>
                    {/* STEPS */}
                    <Steps type="navigation" size="small" current={this.state.current}>
                        <Step status={this.state.current === 0 ? "process" : "wait"} title="Initiate"
                              icon={<AppstoreAddOutlined/>}/>
                        <Step status={this.state.current === 1 ? "process" : "wait"} title="Load Data"
                              icon={<SolutionOutlined/>}/>
                        <Step status={this.state.current === 2 ? "process" : "wait"} title="Match & Compute"
                              icon={<BoxPlotOutlined/>}/>
                        <Step status={this.state.current === 3 ? "process" : "wait"} title="Results & Reports"
                              icon={<DollarOutlined/>}/>
                        <Step status={this.state.current === 4 ? "process" : "wait"} title="Finalize"
                              icon={<CheckSquareOutlined/>}/>
                        <Step status={this.state.current === 5 ? "process" : "wait"} title="Close"
                              icon={<CheckCircleOutlined/>}/>
                    </Steps>
                    {/* CONTENT */}
                    {/* <Badge.Ribbon text={ribbonText}>
					</Badge.Ribbon> */}
                    <Content style={{marginTop: "10px", paddingTop: "20px"}}>
                        {this.state.type_cycle !== -1 && this.state.type_cycle !== undefined ? this.cycleStep(this.state.id, this.state.year, this.state.type_cycle) : "Loading"}
                    </Content>

                </PageContainer>

            </>
        );
    }
}

export default NewBillableCycle;