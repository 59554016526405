import {Button, ConfigProvider, notification, Row, Space} from 'antd';
import React, {Component} from 'react';
import './index.css';
import enUSIntl from "antd/lib/locale/en_US";
import {ActionType, EditableProTable, ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import {FileTextOutlined, ReloadOutlined} from "@ant-design/icons";
import withRouter from "../../../components/withRouter";


export interface IPaymentCycleUnmatchedKeysProps {
    //Here we pass the Props Interface
    params: any
    pageSize?: number
    polling?: false | number
}

export interface IPaymentCycleUnmatchedKeysState {
    dataSource?: PaymentCycleUnmatchedKey[]
    actionRef: React.RefObject<ActionType>
    editableKeys: any,
    changedRecordKeys: React.Key[]
}


type PaymentCycleUnmatchedKey = {
    out_asset_key?: string
    meta_asset_domain_id?: number
    asset_domain_description?: string
    out_customer_id?: number
    out_customer_commission?: number
    out_affiliate_customer_id?: number
    out_affiliate_customer_commission?: number
    out_payment_currency?: number
    gross_euro?: number
};


//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleUnmatchedKeys extends Component<IPaymentCycleUnmatchedKeysProps, IPaymentCycleUnmatchedKeysState> {

    constructor(props: IPaymentCycleUnmatchedKeysProps) {
        super(props);

        this.state = {
            actionRef: React.createRef<ActionType>(),
            editableKeys: [],
            changedRecordKeys: [],
        }

    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {

    }

    // After the component did mount, we set the state.
    componentDidMount() {

    }

    columns: ProColumns<PaymentCycleUnmatchedKey>[] = [
        {
            title: 'Asset key', dataIndex: 'out_asset_key', valueType: 'text',
            editable: false
        },
        // {title: 'Asset_domain_id', dataIndex: 'meta_asset_domain_id', valueType: 'digit'},
        {
            title: 'Asset domain', dataIndex: 'asset_domain_description', valueType: 'text',
            editable: false
        },
        {title: 'Customer id', dataIndex: 'out_customer_id', valueType: 'digit'},
        {title: 'Commission', dataIndex: 'out_customer_commission', valueType: 'digit'},
        {title: 'Affiliate customer id', dataIndex: 'out_affiliate_customer_id', valueType: 'digit'},
        {title: 'Affiliate commission', dataIndex: 'out_affiliate_customer_commission', valueType: 'digit'},
        // {title: 'Payment currency', dataIndex: 'out_payment_currency', valueType: 'digit'},
        {
            title: 'Payment currency', dataIndex: 'out_payment_currency', valueType: "select",
            // valueEnum: {
            //     0: {text: 'None'},
            //     43: {text: 'EUR'},
            //     46: {text: 'GBP'},
            //     142: {text: 'USD'},
            // },
            fieldProps: {
                options: [{value: 0, label: 'None'}, {value: 43, label: 'EUR'}, {value: 46, label: 'GBP'}, {
                    value: 142,
                    label: 'USD'
                }]
            }
        },
        {
            title: 'Gross euro', dataIndex: 'gross_euro', valueType: 'digit',
            // sorter: (a: any, b: any) => a.gross_euro - b.gross_euro,
            editable: false
        },
        {
            title: 'Suggest',
            render: (dom, entity, index) => <Button onClick={() => this.onSuggestRowFix(entity, index)}>Suggest
                Fix</Button>,
            editable: false
        },
        {
            title: 'Apply',
            render: (dom, entity, index) => <Button onClick={() => this.onApplyRowFix(entity, index)}>Apply
                Fix</Button>,
            editable: false
        },
    ];

    // onSubmit = async () => {
    //     if (!this.state.data) {
    //         notification.warn({message: 'No data loaded'})
    //         return
    //     }
    //     try {
    //         const res = await postRequest('/api/paymentCycles/uploadBalancesInfo/' + this.props.cycle_id, this.state.data, false)
    //         console.log(res)
    //         notification.success({message: 'OK'})
    //         this.state.actionRef.current?.reload()
    //
    //         this.onClear()
    //     } catch (e) {
    //         notification.error({message: 'Something went wrong'})
    //     }
    // }

    // onClear = () => {
    //     // @ts-ignore
    //     document.querySelector('.csv-input').value = ''
    //     this.setState({data: undefined})
    // }

    onSuggestRowFix = async (entity: PaymentCycleUnmatchedKey, index: number) => {

        try {
            const res = await postRequest('/api/paymentCycles/getSuggestedUnmatchedKeyFix', entity, false)
            console.log(res.data)
            // notification.success({message: 'OK'})
            // this.state.actionRef.current?.reload()

            if (res.data == null) {
                notification.info({message: 'No suggestions'})
                return
            }

            let dataSource = [...this.state.dataSource!];
            let item: PaymentCycleUnmatchedKey = {
                ...dataSource[index],
                out_customer_id: res.data.out_customer_id,
                out_customer_commission: res.data.out_customer_commission,
                out_affiliate_customer_id: res.data.out_affiliate_customer_id,
                out_affiliate_customer_commission: res.data.out_affiliate_customer_commission,
                out_payment_currency: res.data.out_payment_currency,
            };
            dataSource[index] = item;
            this.setState({dataSource});

            notification.success({message: 'Suggestion found'})
        } catch (e) {
            notification.error({message: 'Something went wrong'})
            // console.log(e)
        }

    }

    onApplyRowFix = async (entity: PaymentCycleUnmatchedKey, index: number) => {
        notification.info({message: 'Operation started. Please wait.'})
        // console.log(entity)
        // return
        try {
            const res = await postRequest('/api/paymentCycles/applyUnmatchedKeyFix/' + this.props.params.id, entity, false)
            console.log(res)
            notification.success({message: 'OK'})
            this.state.actionRef.current?.reload()

        } catch (e) {
            notification.error({message: 'Something went wrong'})
        }

    }

    onReload = async () => {
        this.state.actionRef.current?.reload()
    }


    render() {


        return (
            <>

                {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}
                <Space>
                    {/*<CSVReader*/}
                    {/*    parserOptions={{*/}
                    {/*        header: true,*/}
                    {/*        dynamicTyping: true*/}
                    {/*    }}*/}
                    {/*    // inputRef={instance => {*/}
                    {/*    //     console.log(instance)*/}
                    {/*    // }}*/}
                    {/*    // cssInputClass={"ant-btn ant-btn-default"}*/}
                    {/*    label={'Upload / Replace Customer Balances: '}*/}
                    {/*    onFileLoaded={(data, fileInfo, originalFile) => {*/}
                    {/*        console.log(data, fileInfo, originalFile)*/}
                    {/*        this.setState({data})*/}
                    {/*    }}*/}
                    {/*    onError={error => {*/}
                    {/*        notification.error({message: error.message})*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {/*{this.state.data &&*/}
                    {/*<>*/}
                    {/*    <Button type={"default"} onClick={this.onSubmit} size={"middle"}>Load<UploadOutlined/></Button>*/}
                    {/*    <Button type={"default"} danger onClick={this.onClear}*/}
                    {/*            size={"middle"}>Clear<ClearOutlined/></Button>*/}
                    {/*</>*/}
                    {/*}*/}
                </Space>

                <Row justify={"end"}>
                    <Button type={"text"} onClick={this.onReload}> <ReloadOutlined/> </Button>
                </Row>

                <ConfigProvider locale={enUSIntl}>

                    {/*<Card bodyStyle={{padding: 0}}>*/}

                    <EditableProTable<PaymentCycleUnmatchedKey>
                        columns={this.columns}
                        actionRef={this.state.actionRef as any}
                        controlled
                        // cardBordered
                        bordered
                        // size={"small"}
                        rowKey={(record, index) => record.out_asset_key + '#' + record.meta_asset_domain_id}
                        // style={{fontSize:6}}
                        value={this.state.dataSource}
                        onChange={(dataSource) => this.setState({dataSource: dataSource as any})}
                        editable={{
                            type: 'multiple',
                            editableKeys: this.state.editableKeys,
                            onValuesChange: (record, recordList) => {
                                // console.log(recordList)
                                if (!this.state.changedRecordKeys.includes(record.out_asset_key + '#' + record.meta_asset_domain_id))
                                    this.setState({changedRecordKeys: [...this.state.changedRecordKeys, record.out_asset_key + '#' + record.meta_asset_domain_id]})
                                this.setState({dataSource: recordList})
                            },
                            onChange: (editableKeys) => this.setState({editableKeys}),
                        }}
                        recordCreatorProps={false}
                        polling={this.props.polling || 0}
                        request={async (params = {}, sort, filter) => {
                            try {
                                const res = await postRequest('/api/paymentCycles/getUnmatchedKeys/' + this.props.params.id)
                                const data = res.data || []
                                // console.log(res.data)
                                this.setState({
                                    editableKeys: data.map((item: PaymentCycleUnmatchedKey) => item.out_asset_key + '#' + item.meta_asset_domain_id),
                                }, () => {
                                    // console.log('this.state.editableKeys', this.state.editableKeys)
                                })
                                return {data, success: true, total: data.length}
                            } catch (e) {
                                return {data: [], success: false, total: 0}
                            }
                        }}
                        // rowKey="filename"
                        search={false}
                        locale={{
                            emptyText: <Space direction={'vertical'}> <FileTextOutlined/>No unmatched keys</Space>
                        }}
                        pagination={{pageSize: 1000, hideOnSinglePage: true}}
                        dateFormatter="string"
                        toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                        // toolBarRender={false}
                        // toolBarRender={() => []}
                    />
                    {/*</Card>*/}


                </ConfigProvider>

            </>
        );
    }
}

export default withRouter(PaymentCycleUnmatchedKeys);
