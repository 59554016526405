import React, {Component} from 'react';
import './index.css';
import {TAG_SHAPE, TAG_SIZE} from '../../../common/constants';
import {
    AutoComplete,
    Avatar,
    Badge,
    Button,
    Col,
    Drawer,
    Input,
    notification,
    Row,
    Space,
    Spin,
    Table,
    Tooltip,
    Typography,
} from 'antd';

// import {Container} from 'react-smooth-dnd';
import {PlusOutlined, UnorderedListOutlined} from '@ant-design/icons';
import {Link, Navigate} from 'react-router-dom';
import TagsDrawer from '../../components/TagsDrawer';
import {
    AssignTagForm,
    AssignTagFormV2,
    CustomerListRow,
    TagCategoryValueDrawer,
    TagCategoryValueEntity
} from '../../../common/models';
import {assignTagToEntity} from '../../../api/assignTagToEntity';
import {postRequest} from "../../../api/postRequest";
import {assignTagToEntityV2} from "../../../api/assignTagToEntityV2";
import {PageContainer} from "@ant-design/pro-components";

const {Text} = Typography;

export interface IIndexProps {
    //Here we pass the Props Interface
    // api_call: string;
}

export interface IIndexState {
    //here we pass the State Interface
    data: any;
    rows: number;
    isLoading: boolean;
    customer: string;
    component_customer: any;
    searched: string;
    filteredData: any;
    isCustomerVisible: boolean;
    customerTags: { [customerId: number]: { id: number; data: React.ReactNode; obj: TagCategoryValueDrawer }[] };
    isTagsVisible: boolean;
    isDragging: boolean;
    redirect: boolean
}

const applyDrag = (arr: any, dragResult: any) => {
    const {removedIndex, addedIndex, payload} = dragResult;
    // console.log('drag res', dragResult);
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
    }

    return result;
};

//class ComponentName Component<PropsInterface, StateInterface>
class Customers extends Component<IIndexProps, IIndexState> {
    constructor(props: IIndexProps) {
        super(props);

        this.state = {
            data: [], // Fetched data
            filteredData: [], // data after filter (initially same as fetched data)
            rows: 0, // number of rows fetched
            isLoading: true, // while loading
            customer: '0', //Current customer
            component_customer: undefined, // Customer component
            searched: '',
            isCustomerVisible: false,
            redirect: false,

            // tags
            customerTags: {},
            isTagsVisible: false,
            isDragging: false,
        };

        this.getFunc = this.getFunc.bind(this);
        this.setCustomer = this.setCustomer.bind(this);
        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.getUniqueValues = this.getUniqueValues.bind(this);
        this.onCustomerClose = this.onCustomerClose.bind(this);
        this.showTagsDrawer = this.showTagsDrawer.bind(this);
        this.closeTagsDrawer = this.closeTagsDrawer.bind(this);
    }

    showTagsDrawer() {
        this.setState({isTagsVisible: true});
    }

    closeTagsDrawer() {
        this.setState({isTagsVisible: false});
    }

    getFunc = async () => {

        try {
            const response = await postRequest('/api/customers/getall')
            const resultCustomers = response.data
            const customerTags = await this.initializeCustomerTags(resultCustomers.map((c: CustomerListRow) => c.id));

            this.setState(
                {
                    data: resultCustomers,
                    filteredData: resultCustomers,
                    rows: resultCustomers.length,
                    customerTags: customerTags,
                },
                async () => {
                    this.setState({isLoading: false})
                    this.columns[5].filters.push(...await this.getUniqueTags())
                }
            );

            // console.log('data tiers', this.getUniqueTiers());
            // this.columns[2].filters = this.getUniqueValues('tier');
            this.columns[2].filters.push(...this.getUniqueValues('tier'));
            this.columns[3].filters.push(...this.getUniqueValues('account_manager'));
            this.columns[4].filters.push(...this.getUniqueValues('payment_option'));

        } catch (error) {
            this.setState({
                data: [],
                filteredData: [],
                rows: 0,
                customerTags: {},
                isLoading: false
            });
            console.log('Error on GET :' + error);
        }

    };

    async initializeCustomerTags(customerIds: string[]) {
        let customerTags: any = {};
        customerIds.forEach((id) => {
            customerTags[id] = [];
        });

        try {
            const res = await postRequest('/api/tags/getcategoryvaluesentities', {domain: '_customer'});
            if (!res.data) return customerTags
            // console.log('gettagsres', res);
            res.data.forEach((tagValue: TagCategoryValueEntity) => {
                // console.log('tag.entityId', tag.entityId);
                if (!customerTags[+tagValue.entity_id]) return
                customerTags[+tagValue.entity_id].push({
                    id: tagValue.id,
                    data: (
                        <Tooltip title={tagValue.value + ' (' + tagValue.category_name + ')'} placement="top">
                            <Avatar
                                style={{backgroundColor: tagValue.color}}
                                // icon={<UserOutlined />}
                                shape={TAG_SHAPE}
                            >
                                {tagValue.value}
                            </Avatar>
                        </Tooltip>
                    ),
                    obj: tagValue,
                });
            });
            // console.log('astags', assetTags);

            return customerTags;
        } catch (e) {
            notification.error({message: e.response.data.message})
            return customerTags
        }
    }

    // async getTagsOfEntitiesInDomain(entityDomain: string) {
    //     const session_url = API + `/api/tags/getTagsOfEntitiesInDomain`;
    //
    //     const data = qs.stringify({
    //         entityDomain: '_customer',
    //     });
    //
    //     return axios.post(session_url, data, {
    //         headers: {
    //             Accept: '*/*',
    //             'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    //         },
    //         auth: {
    //             username: 'dev',
    //             password: '1234',
    //         },
    //     });
    // }

    handleSearchInput(text: string) {
        // console.log('handler', text);

        this.setState({searched: text}, () => {
            // this.setState({
            //    filteredData: {
            //       arr: this.state.data.arr.filter(
            //          (customer: CustomerListRow) =>
            //             customer.id.toLowerCase().includes(this.state.searched.toLowerCase()) ||
            //             customer.full_name.toLowerCase().includes(this.state.searched.toLowerCase())
            //       ),
            //    },
            // });
            this.setState({
                filteredData: this.state.data.filter(
                    (customer: CustomerListRow) =>
                        customer.id.toString().includes(this.state.searched.toString().toLowerCase()) ||
                        customer.full_name.toLowerCase().includes(this.state.searched.toString().toLowerCase())
                ),

            });
        });
    }

    setCustomer(id: string) {
        if (id != this.state.customer) {
            // this.setState({ component_customer: <Customer customer={id} />, customer: id }, () =>
            //    this.setState({ isCustomerVisible: true })
            // );
            this.setState({customer: id}, () => {
                this.setState({redirect: true});
            });
        }
    }

    onCustomerClose() {
        this.setState({component_customer: undefined, customer: '0'});
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getFunc();
    }

    columns: any = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 80,
            ellipsis: true,
            sorter: (a: CustomerListRow, b: CustomerListRow) => parseInt(a.id) - parseInt(b.id),
            sortDirections: ['ascend', 'descend'],
            filters: [{
                text: 'Hide Removed',
                value: 'Removed',
            }],
            defaultFilteredValue: ['Removed'],
            onFilter: (value: any, record: CustomerListRow) => record.tier.localeCompare(value) != 0,
        },
        {
            title: 'Client',
            dataIndex: 'full_name',
            width: 120,
            ellipsis: true,
            // render: ({ text }: any) => <Text ellipsis>{text}</Text>,
            sorter: (a: CustomerListRow, b: CustomerListRow) => a.full_name.localeCompare(b.full_name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            // title: 'Tier',
            // dataIndex: 'tier',
            width: 0,
            // width: 80,
            ellipsis: true,
            filters: [],
            // onFilter: (value: any, record: CustomerListRow) => record.tier.localeCompare(value) === 0,
            // sorter: (a: CustomerListRow, b: CustomerListRow) => a.tier.localeCompare(b.tier),
            // sortDirections: ['ascend', 'descend'],
            // render: (text: string) => {
            //     if (text === 'Removed') return <Text type="danger">{text}</Text>;
            //     else return <>{text}</>;
            // },
        },
        {
            title: 'Account Manager',
            dataIndex: 'account_manager',
            ellipsis: true,
            width: 150,
            filters: [],
            onFilter: (value: any, record: CustomerListRow) => record.account_manager.localeCompare(value) === 0,
            sorter: (a: CustomerListRow, b: CustomerListRow) => a.account_manager.localeCompare(b.account_manager),
            sortDirections: ['ascend', 'descend'],
        },
        {
            // title: 'Payment option',
            // dataIndex: 'payment_option',
            ellipsis: true,
            // width: 140,
            width: 0,
            filters: [],
            // onFilter: (value: any, record: CustomerListRow) => record.payment_option.localeCompare(value) === 0,
            // sorter: (a: CustomerListRow, b: CustomerListRow) => a.payment_option.localeCompare(b.payment_option),
            // sortDirections: ['ascend', 'descend'],
        },
        {
            title: (
                <Space>
                    Tags
                    <Button type="dashed" onClick={() => this.showTagsDrawer()} icon={<PlusOutlined/>}>
                        Assign
                    </Button>
                </Space>
            ),
            filterMode: 'tree',
            filterMultiple: true,
            key: 'tags',
            width: 120,
            filters: [],
            onFilter: (value: any, record: CustomerListRow) => {
                if (value.startsWith('category')) return true
                let valueId = value.split('#')[1];
                return this.state.customerTags[record.id].some(element => element.id.toString() == valueId)

            },
            render: (text: string, record: CustomerListRow, rowIndex: number) => {
                return (
                    <div
                        style={{
                            // flex: 1,
                            border: this.state.isDragging ? '2px dashed #bfbfbf' : 'none',
                            background: this.state.isDragging ? '#f5f5f5' : 'transparent',
                        }}
                    >
                        {/*<Container*/}
                        {/*    groupName="1"*/}
                        {/*    orientation="horizontal"*/}
                        {/*    behaviour="drop-zone"*/}
                        {/*    getChildPayload={(i) => {*/}
                        {/*        // console.log(i, this.state.customerTags[+record.id][i]);*/}
                        {/*        return this.state.customerTags[+record.id][i];*/}
                        {/*    }}*/}
                        {/*    // onDropReady={(e) => console.log('drop ready', e)}*/}
                        {/*    onDrop={async (dropResult) => {*/}
                        {/*        if (dropResult.addedIndex == null) return;*/}
                        {/*        console.log('drop body', dropResult, 'this', record.id);*/}
                        {/*        const assignTagForm: AssignTagFormV2 = {*/}
                        {/*            tag_category_value_id: parseInt(dropResult.payload.id),*/}
                        {/*            entity_id: parseInt(record.id),*/}
                        {/*            taggable_entity_domain: '_customer'*/}
                        {/*        };*/}

                        {/*        await assignTagToEntityV2(assignTagForm);*/}

                        {/*        //TODO*/}
                        {/*        // await this.assignCustomerTagsToCustomerAssets(parseInt(dropResult.payload.id), parseInt(record.id));*/}

                        {/*        // console.log('record', record.id, 'got', dropResult.payload.id);*/}

                        {/*        // console.log('ct', this.state.customerTags);*/}


                        {/*        // this.columns[5].filters.push(...this.getUniqueTags())*/}
                        {/*        // console.log('ut', await this.getUniqueTags())*/}
                        {/*        this.columns[5].filters = [...await this.getUniqueTags()]*/}

                        {/*        // await this.initializeCustomerTags(this.state.data.arr.map((c: CustomerListRow) => c.id))*/}

                        {/*        let newState = {...this.state.customerTags};*/}
                        {/*        newState[+record.id] = applyDrag(this.state.customerTags[+record.id], dropResult);*/}
                        {/*        this.setState({customerTags: newState});*/}
                        {/*    }}*/}
                        {/*    shouldAnimateDrop={() => false}*/}
                        {/*    shouldAcceptDrop={(sourceContainerOptions, payload) => {*/}
                        {/*        if (this.state.customerTags[+record.id].filter((e: any) => e.id == payload.id).length > 0)*/}
                        {/*            return false;*/}
                        {/*        return true;*/}
                        {/*    }}*/}
                        {/*>*/}
                            <Avatar.Group
                                maxCount={6}
                                maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}}
                                style={{width: '150px', padding: '5px', height: '40px '}}
                                size={TAG_SIZE}
                                // size={TAG_GROUP_SIZE}
                            >
                                {this.state.customerTags[+record.id].map((p: any, i: number) => {
                                    // console.log('this.state.tagState[record.id]',this.state.tagState[record.id]);
                                    return p.data;
                                })}
                            </Avatar.Group>
                        {/*</Container>*/}
                    </div>
                );
            },
        },
    ];

    async assignCustomerTagsToCustomerAssets(tagId: number, customerId: number) {
        // const response = await getCustomerAssets(customerId); // DEPR
        const response = await postRequest(`/api/assets/ppk/get/${customerId}`)

        // console.log('getCustomerAssets', response);
        if (!response.data) response.data = []

        for (const asset of response.data) {
            const assignTagForm: AssignTagForm = {
                tagId,
                entityId: asset.asset_id,
                entityDomain: '_asset',
            };

            await assignTagToEntity(assignTagForm);
        }
    }

    getUniqueValues(field: string): { text: string; value: string }[] {
        let distinct = Array.from(
            new Set<string>(this.state.data.map((record: any) => record[field])).values()
        ).sort();
        let filters: { text: string; value: string }[] = [];
        distinct.forEach((item) => {
            filters.push({text: item, value: item});
        });

        // console.log(filters);

        return filters;
    }

    async getUniqueTags(): Promise<any> {
        try {
            const res = await postRequest('/api/tags/getdistinctcategoryvaluesdomain', {domain: '_customer'})
            if (!res.data) return []
            const distinct = res.data as TagCategoryValueDrawer[]
            let filters: { text: React.ReactNode; value: any; key?: string; children: { text: React.ReactNode; value: any; key?: string }[] }[] = [];
            distinct.forEach((item) => {
                const categoryFilterIndex = filters.findIndex((value, index, obj) => value.value == 'category#' + item.category_id);
                if (categoryFilterIndex == -1) {
                    filters.push({
                        text: <Badge color={item.color} text={item.category_name} key={item.category_id}/>,
                        value: 'category#' + item.category_id,
                        children: []
                    })
                    filters[filters.length - 1].children.push({
                        text: <Badge color={item.color} text={item.value} key={item.id}/>,
                        value: 'value#' + item.id,
                    });
                } else {
                    filters[categoryFilterIndex].children.push({
                        text: <Badge color={item.color} text={item.value} key={item.id}/>,
                        value: 'value#' + item.id,
                    });
                }
            });
            return filters
        } catch (e) {
            notification.error({message: e.response.data.message})
            return []
        }

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={'/crm/customers/view/' + this.state.customer}/>;
        }

        return (
            <>
                <PageContainer>
                    <Row gutter={0} id="customer-search">
                        {/* <Col span={this.state.customer === '0' ? 23 : 12}> */}
                        <Col span={23}>
                            <Spin spinning={this.state.isLoading} style={{height: '100vh'}}>
                                {!this.state.isLoading && (
                                    <Space direction="vertical">
                                        <AutoComplete
                                            // dropdownMatchSelectWidth={252}
                                            style={{width: '100%'}}
                                            onSelect={(id: any) => {
                                                this.setCustomer(id);
                                            }}
                                            // onSearch={(val)=>{console.log('ac onSearch',val)}}
                                            // onChange={(val)=>{console.log('ac onChange',val)}}
                                            onChange={this.handleSearchInput}
                                            options={this.state.filteredData.map((customer: CustomerListRow) => {
                                                return {
                                                    label: customer.id + ' / ' + customer.full_name,
                                                    value: customer.id,
                                                };
                                            })}
                                            // placeholder="Search..."
                                        >
                                            <Input.Search size="large" placeholder="Search..." allowClear/>
                                        </AutoComplete>

                                        <TagsDrawer
                                            onClose={this.closeTagsDrawer}
                                            isVisible={this.state.isTagsVisible}
                                            onDragStart={() => this.setState({isDragging: true})}
                                            onDragEnd={() => this.setState({isDragging: false})}
                                        />

                                        <Table
                                            columns={this.columns}
                                            dataSource={this.state.filteredData}
                                            // scroll={
                                            // {
                                            // y: window.innerHeight * (7 / 10),
                                            // y: '100%',
                                            // x: '100vw',
                                            // }
                                            // }
                                            // scroll={{ y: 'calc(100vh - 4em)' }}
                                            onRow={(record: CustomerListRow, rowIndex: any) => {
                                                return {
                                                    onClick: (event: any) => {
                                                        console.log('event', record);
                                                        this.setCustomer(record.id);
                                                    }, // click row
                                                    onDoubleClick: (event: any) => {
                                                    }, // double click row
                                                    onContextMenu: (event: any) => {
                                                    }, // right button click row
                                                    onMouseEnter: (event: any) => {
                                                    }, // mouse enter row
                                                    onMouseLeave: (event: any) => {
                                                    }, // mouse leave row
                                                };
                                            }}
                                            // pagination={{ simple: true }}
                                            pagination={{showSizeChanger: false}}
                                            rowClassName={'clickable-row'}
                                            // style={{ height: window.innerHeight * (7 / 10) }}
                                        />
                                    </Space>
                                )}
                            </Spin>
                        </Col>
                        {/* {this.state.customer !== '0' && (
                     <Col span={12} style={{ padding: '0px 16px 0px 16px' }}>
                        {this.state.component_customer}
                     </Col>
                  )} */}
                        <Drawer
                            title={'Customer ' + this.state.customer}
                            placement={'right'}
                            closable={true}
                            onClose={() => this.setState({isCustomerVisible: false})}
                            afterVisibleChange={(visible) => {
                                if (!visible) this.onCustomerClose();
                            }}
                            visible={this.state.isCustomerVisible}
                            // mask={false}
                            keyboard={true}
                            width={800}
                            footer={
                                <Row justify="end">
                                    <Link to={'/crm/customers/view/' + this.state.customer}>
                                        <Button icon={<UnorderedListOutlined/>}>Extended Customer Info</Button>
                                    </Link>
                                </Row>
                            }
                        >
                            {this.state.component_customer}
                        </Drawer>
                    </Row>
                </PageContainer>
            </>
        );
    }
}

export default Customers;
