import React, {Component} from 'react';
import NewContractForm from '../../components/NewContractForm';
import './index.css';
import {Button, Card, FormInstance, message, Row, Space, Typography} from 'antd';
import moment from 'moment';
import {ExclamationCircleOutlined, FormOutlined} from '@ant-design/icons';
import {postRequest} from "../../../api/postRequest";
import confirm from "antd/es/modal/confirm";
import {PageContainer} from "@ant-design/pro-components";

const {Title} = Typography;

export interface INewContractProps {
    //Here we pass the Props Interface
    edit?: boolean
    id?: number
}

export interface INewContractState {
    //here we pass the State Interface
    formRef: React.RefObject<FormInstance>;
    changeOccured: boolean
}

//class ComponentName Component<PropsInterface, StateInterface>
class NewContract extends Component<INewContractProps, INewContractState> {
    constructor(props: INewContractProps) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onValidate = this.onValidate.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onFormValuesChange = this.onFormValuesChange.bind(this);

        //Component State
        this.state = {
            formRef: React.createRef<FormInstance>(),
            changeOccured: false
        };
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {

    }

    onFormValuesChange() {
        this.setState({changeOccured: true})
    }

    onSubmit(e: any) {
        e.preventDefault();
        this.state.formRef.current
            ?.validateFields()
            .then((result) => {
                //AXIOS CALL
                // console.log(this.state.formRef.current?.getFieldsValue())
                // var start_date = this.state.formRef.current?.getFieldInstance("start-date")
                const start_date = this.state.formRef.current?.getFieldValue("start_date");
                // var end_date = this.state.formRef.current?.getFieldInstance("end-date")
                const end_date = this.state.formRef.current?.getFieldValue("end_date");

                const data = this.state.formRef.current?.getFieldsValue();
                data.start_date = moment(start_date).format("YYYY-MM-DD")
                data.end_date = moment(end_date).format("YYYY-MM-DD")
                // data.linked_assets = data.linked_assets.join()
                // data.linked_assets = data.linked_assets_commissions.map((record: { asset_id: string, commission: number }) => record.asset_id).join()

                console.log('contract data', data)
                // return
                if (this.props.edit) {
                    this.editContract(data)
                } else {
                    this.postNewContract(data)
                }
            })
            .catch((err) => {
                confirm({
                    title: 'Do you want to proceed anyway?',
                    icon: <ExclamationCircleOutlined/>,
                    content: 'Some field values are not of the appropriate type.',
                    onOk: () => {

                        const start_date = this.state.formRef.current?.getFieldValue("start_date");
                        // var end_date = this.state.formRef.current?.getFieldInstance("end-date")
                        const end_date = this.state.formRef.current?.getFieldValue("end_date");

                        const data = this.state.formRef.current?.getFieldsValue();
                        data.start_date = moment(start_date).format("YYYY-MM-DD")
                        data.end_date = moment(end_date).format("YYYY-MM-DD")
                        // data.linked_assets = data.linked_assets.join()
                        // data.linked_assets = data.linked_assets_commissions.map((record: { asset_id: string, commission: number }) => record.asset_id).join()

                        console.log('contract data', data)
                        // return
                        if (this.props.edit) {
                            this.editContract(data)
                        } else {
                            this.postNewContract(data)
                        }
                    },
                    onCancel: () => {
                        // console.log('Cancel');
                    },
                });
            });
    }

    postNewContract = async (data: any) => {
        // console.log(data)
        // return

        postRequest("/api/contracts/create", data, false)
            .then(async (response) => {
                // alert(response.data)
                const contract_id = response.data.id
                message.success({content: "Contract created! (ID: " + contract_id + ")", key: 'submit'})

            })
            .catch((error) => {
                message.error({content: error.response.data.message, key: 'submit'})
            });
    };

    editContract = async (data: any) => {
        // console.log('edit')
        // return

        postRequest("/api/contracts/edit/" + this.props.id, data, false)
            .then(async (response) => {
                // alert(response.data)
                const contract_id = response.data.id
                message.success({content: "Saved! Contract ID = " + contract_id, key: 'submit'})

            })
            .catch((error) => {
                message.error({content: error.response.data.message, key: 'submit'})
            });
    };


    onValidate(e: any) {
        message.loading({content: 'Please wait...', key: 'validate'});
        this.state.formRef.current
            ?.validateFields()
            .then((result) => {
                message.success({content: 'All fields are valid', key: 'validate'});
            })
            .catch((err) => {
                message.error({content: 'Some fields are invalid', key: 'validate'});
            });
    }

    onReset() {
        this.state.formRef.current?.resetFields();
    }

    render() {
        const bread = this.props.edit ? '/crm/contracts/edit/' + this.props.id : '/crm/contracts/new'
        const breadTitle = this.props.edit ? 'Edit Contract ' + this.props.id : 'New Contract'
        const pageTitle = this.props.edit ? 'Edit Contract ' + this.props.id : 'Create New Contract'
        const submitBtnText = this.props.edit ? 'Save' : 'Create'

        return (
            <>
                <PageContainer>
                    <div className="NewContract" style={this.style}>
                        {/* <Row justify="center">
                     <Title level={2}>Create New Contract</Title>
                  </Row> */}
                        {/*<Card bordered={false}>*/}
                        {/*    <><Row justify='center'><Space direction='horizontal' align='baseline'> <Title*/}
                        {/*        level={3}>{pageTitle}</Title> <FormOutlined style={{fontSize: 20}}/></Space></Row></>*/}
                        {/*</Card>*/}

                        <NewContractForm
                            formRef={this.state.formRef}
                            edit={this.props.edit}
                            id={this.props.id}
                            onFormValuesChange={this.onFormValuesChange}
                        />
                        <br/>

                        <br/>
                        <br/>

                        {/* Actions */}

                        {/* <Space size={48} style={{ marginLeft: '64px' }}> */}
                        <Row justify="space-between" style={{padding: '0 64px 0 64px'}}>

                            {!this.props.edit &&
                            <Button onClick={this.onReset} size="large" danger>
                                Reset
                            </Button>
                            }

                            <Button onClick={this.onValidate} size="large">
                                Validate
                            </Button>

                            <Button type="primary" htmlType="submit" onClick={this.onSubmit} size="large"
                                    disabled={this.props.edit ? !this.state.changeOccured : false}>
                                {submitBtnText}
                            </Button>
                        </Row>
                    </div>
                </PageContainer>
            </>
        );
    }
}

export default NewContract;
