import React, {Component} from 'react';
import './index.css';
import {Card, Col, ConfigProvider, Form, FormInstance, Input, InputNumber, Row,} from 'antd';
import {executeQuery} from '../../../api/executeQuery';
import {validateVAT} from '../../../api/validateVAT';
import EntityTags from '../EntityTags';
import {postRequest} from "../../../api/postRequest";
import enUSIntl from "antd/lib/locale/en_US";
import {ProForm, ProFormSelect} from "@ant-design/pro-form";

export interface INewCustomerFormProps {
    //Here we pass the Props Interface
    formRef: React.RefObject<FormInstance>;
    edit?: boolean
    id?: number
    onFormValuesChange: Function
}

export interface INewCustomerFormState {
    //here we pass the State Interface
    // countries: any[],
    // companies: any[],
    // payment_options: any[],
    // employees: any[],
    // currencies: any[],
    customerData: any
}

//class ComponentName Component<PropsInterface, StateInterface>
class NewCustomerForm extends Component<INewCustomerFormProps, INewCustomerFormState> {
    constructor(props: INewCustomerFormProps) {
        super(props);

        //Component State
        this.state = {
            // countries: [],
            // companies: [],
            // payment_options: [],
            // employees: [],
            // currencies: [],
            customerData: null
        };
    }

    //Add style here
    style = {};

    getFunc = async () => {


        // IF EDIT
        if (this.props.edit) {
            postRequest('/api/customers/getraw/' + this.props.id)
                .then((response) => {
                    // console.log('Authenticated');
                    // const result = response.data as ExtendedCustomerInfoRaw || [];
                    const result = response.data || null;
                    console.log(result);
                    this.props.formRef.current?.setFieldsValue(result)
                    // let customerData = result
                    // this.setState({customerData})
                })
                .catch((error) => {
                    console.log('Error on GET :' + error);
                });
        }
    };

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getFunc()
    }

    render() {


        const layout = {
            labelCol: {span: 18},
            wrapperCol: {span: 18},
        };
        const tailLayout = {
            wrapperCol: {offset: 10, span: 14},
        };

        return (
            <div className="NewCustomerForm" style={this.style}>
                <ConfigProvider locale={enUSIntl}>
                    <ProForm
                        {...layout}
                        name="customerForm"
                        //   onFinish={onFinish}
                        //   onFinishFailed={onFinishFailed}
                        validateMessages={{required: 'This field is required!'}}
                        formRef={this.props.formRef}
                        layout="vertical"
                        size="middle"
                        onValuesChange={() => this.props.onFormValuesChange()}
                        // style={{ marginLeft: '48px' }}
                        // initialValues={this.state.customerData}
                        submitter={false}
                    >
                        <Card title='Customer Details' style={{width: '100%'}}>

                            <Row justify="space-around">
                                <Col span={6}>
                                    <Form.Item label="Customer ID" name="id"
                                               tooltip={!this.props.edit && 'Optional: A unique ID will be automatically assigned to the customer, if one is not provided'}
                                        // rules={[{ required: true }]}
                                    >
                                        <InputNumber disabled={this.props.edit === true}/>
                                    </Form.Item>

                                    <Form.Item label="First Name" name="first_name"
                                               // rules={[{required: true}]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item label="Last Name" name="last_name"
                                               // rules={[{required: true}]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item label="Full Name" name="full_name" rules={[{required: true}]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>

                                    <Form.Item
                                        label="Primary Contact Phone Number"
                                        name="phone"
                                        // rules={[{required: true}]}
                                    >
                                        {/* <Input addonBefore={"+30"}  /> */}
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item label="Primary Email" name="email"
                                               rules={[{required: true, type: "email"}]}>
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item label="Contact Address" name="address"
                                               // rules={[{required: true}]}
                                    >
                                        <Input/>
                                    </Form.Item>


                                    <ProFormSelect
                                        label="Residential Country" name="country"
                                        request={async (params) => {
                                            try {
                                                const response = await executeQuery('GET_COUNTRIES')
                                                // console.log('GET_COUNTRIES', response);
                                                const countries = response.data = response.data ? response.data : []

                                                // const allAssets = (await getAllAssets()).data
                                                let selectOptions: any = [];
                                                countries.forEach((element: any) => {
                                                    selectOptions.push({
                                                        label: element.value + " / " + element.descr,
                                                        value: parseInt(element.id)
                                                    })
                                                });
                                                // console.log(selectOptions)
                                                return selectOptions

                                            } catch (error) {
                                                console.log('Error:', error);
                                            }
                                        }}
                                        fieldProps={{
                                            filterOption: (inputValue, option) =>
                                                option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0,
                                            showSearch: true,
                                            optionFilterProp: "label"
                                        }}
                                    />

                                </Col>

                                <Col span={6}>


                                    <Form.Item label="Zipcode" name="zipcode"
                                        // rules={[{ required: true }]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    {/* <Form.Item label="Fiscal Country" name="fiscal_country" rules={[{ required: true }]}>
                           <Select
                              showSearch
                              allowClear
                              style={{ width: "100%" }}
                              placeholder="Select a Fiscal Country"
                              optionFilterProp="label" 
                              options={this.state.countries} // replace with acutal data !!!
                              filterOption={(inputValue, option) =>
                                 option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                              }
                           ></Select> 
                        </Form.Item> */}

                                    <Form.Item label="Fiscal VAT" name="vat"
                                               rules={[
                                                   // {required: true},
                                                   ({getFieldValue}) => ({

                                                       async validator(_, value) {
                                                           if (value === undefined || value === '')
                                                               return Promise.resolve();

                                                           value = value !== undefined && value !== '' ? value : '0'
                                                           console.log('value', value);
                                                           const response = await validateVAT(value)
                                                           console.log('resp', response.data);
                                                           if (response.data.validity === undefined)
                                                               return Promise.resolve();
                                                           const result = response.data.validity
                                                           // console.log('validator VAT', result);
                                                           if (result === 'true') {
                                                               return Promise.resolve();
                                                           } else if (result === 'false') {
                                                               return Promise.reject(new Error('Wrong VAT format'));
                                                           }
                                                       },
                                                   }),
                                               ]}
                                    >
                                        <Input placeholder={'EL123456789'}/>
                                    </Form.Item>


                                    <ProFormSelect
                                        label="Fiscal Default Currency"
                                        name="fiscal_default_currency"
                                        // rules={[{required: true}]}
                                        request={async (params) => {
                                            try {
                                                const response = await executeQuery('GET_CURRENCIES')
                                                // console.log('GET_CURRENCIES', response);
                                                const currencies = response.data ? response.data : []

                                                let selectOptions: any = []
                                                currencies.forEach((element: any) => {
                                                    selectOptions.push({
                                                        label: element.value + " / " + element.descr,
                                                        value: parseInt(element.id),
                                                    })
                                                });
                                                console.log(selectOptions)
                                                return selectOptions
                                            } catch (error) {
                                                console.log('Error:', error);
                                                return []
                                            }
                                        }}
                                        fieldProps={{
                                            filterOption: (inputValue, option) =>
                                                option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0,
                                            showSearch: true,
                                            optionFilterProp: "label"
                                        }}
                                    />

                                    <ProFormSelect
                                        label="Payment Option" name="payment_option"
                                        // rules={[{required: true}]}
                                        request={async (params) => {
                                            try {
                                                const response = await executeQuery('GET_PAYMENTS_OPTIONS')
                                                // console.log('GET_PAYMENTS_OPTIONS', response);
                                                const paymentsOptions = response.data ? response.data : []
                                                // const allAssets = (await getAllAssets()).data
                                                let selectOptions: any = [];
                                                paymentsOptions.forEach((element: any) => {
                                                    selectOptions.push({
                                                        label: element.title,
                                                        value: parseInt(element.id)
                                                    })
                                                });
                                                return selectOptions

                                            } catch (error) {
                                                console.log('Error:', error);
                                                return []
                                            }

                                        }}
                                        fieldProps={{
                                            filterOption: (inputValue, option) =>
                                                option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0,
                                            showSearch: true,
                                            optionFilterProp: "label"
                                        }}
                                    />


                                </Col>

                                <Col span={6}>

                                    {/* <Form.Item
                           label="Payment Currency"
                           name="payment_currency_id"
                           rules={[{ required: true }]}
                           initialValue={'43'}
                        >
                           {this.state.currencies ?
                              <Select
                                 showSearch
                                 allowClear
                                 style={{ width: "100%" }}
                                 placeholder="Select Payment Currency"
                                 optionFilterProp="label"
                                 //  onChange={onChange}
                                 //  onSearch={onSearch}
                                 options={this.state.currencies} // replace with acutal data !!!
                                 filterOption={(inputValue, option) =>
                                    option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                                 }
                              ></Select> : "Loading ..."}
                        </Form.Item> */}

                                    {/*<Form.Item label="Payment Destination" name="payment_destination">*/}
                                    {/*    <Input placeholder='IBAN, email etc.'/>*/}
                                    {/*</Form.Item>*/}

                                    <Form.Item label="Paypal Email" name="paypal_email">
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item label="IΒΑΝ Number" name="iban_number">
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item label="Swift/Bic Code" name="swift_bic_code">
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item label="Bank Account Owner" name="bank_account_owner">
                                        <Input/>
                                    </Form.Item>

                                    {/* <Form.Item label="Company ID" name="company_id"
                        // rules={[{ required: true }]}
                        >
                           <Select
                              showSearch
                              allowClear
                              style={{ width: "100%" }}
                              placeholder="Select a Company"
                              optionFilterProp="label"
                              options={this.state.companies}
                              filterOption={(inputValue, option) =>
                                 option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                              }
                           ></Select>
                        </Form.Item> */}

                                    {/*<Form.Item label="Account Manager" name="account_manager_id"*/}
                                    {/*           rules={[{required: true}]} initialValue={'0'}>*/}
                                    {/*    <Select*/}
                                    {/*        showSearch*/}
                                    {/*        allowClear*/}
                                    {/*        style={{width: "100%"}}*/}
                                    {/*        placeholder="Select a manager"*/}
                                    {/*        optionFilterProp="label"*/}
                                    {/*        options={this.state.employees}*/}
                                    {/*        filterOption={(inputValue, option) =>*/}
                                    {/*            option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0*/}
                                    {/*        }*/}
                                    {/*    ></Select>*/}
                                    {/*</Form.Item>*/}


                                </Col>
                            </Row>

                            <Row>
                                <Col span={11}>
                                    <Form.Item label="Notes" name="notes" rules={[{required: false}]}>
                                        <Input.TextArea
                                            // style={{width: "100%"}}
                                            placeholder="Write a note..."
                                            rows={3}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>

                        <br/>
                        {/* <br /> */}
                        {/*{!this.props.edit &&*/}
                        {/*<Row>*/}
                        {/*    /!* <Card title='Customer Tags' style={{ width: '100%' }} bordered={false}> *!/*/}
                        {/*    <Form.Item name="customer_tags" rules={[{required: false}]} style={{width: '100%'}}*/}
                        {/*               initialValue={[]} label={'Customer tags (separate values with comma)'}>*/}
                        {/*        <Select*/}
                        {/*            mode="tags"*/}
                        {/*            style={{width: '100%'}}*/}
                        {/*            placeholder="Start typing tags (optional)"*/}
                        {/*            optionLabelProp="label"*/}
                        {/*            tokenSeparators={[',']}*/}
                        {/*            open={false}*/}
                        {/*            // options={this.state.dropdownAssets}*/}
                        {/*            // filterOption={(inputValue, option) =>*/}
                        {/*            //    option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0*/}
                        {/*            // }*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*    /!* </Card> *!/*/}
                        {/*</Row>*/}
                        {/*}*/}


                        {/* <br /> */}
                        {this.props.edit &&
                        <Card title='Customer Tags' style={{width: '100%'}} bordered={true}>
                            <Row>
                                <EntityTags editable entityDomain='_customer' entityId={this.props.id + ''}/>
                            </Row>
                        </Card>
                        }
                    </ProForm>

                </ConfigProvider>

            </div>
        );
    }
}

export default NewCustomerForm;
