import {
    ProFormDigit,
    ProFormGroup,
    ProFormItem,
    ProFormList,
    ProFormRadio,
    ProFormSelect,
    ProFormSwitch,
    ProFormText,
    StepsForm
} from '@ant-design/pro-form';
import React, { useContext, useState } from 'react';
import { countryOptions } from './ddex_atoms';
import ProCard from '@ant-design/pro-card';
import { Badge, Button, Modal, Result, Tooltip } from 'antd';
import { DeleteOutlined, DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { useAtom } from 'jotai';
import axiosApiInstance from '../../../api/axiosClient';
import { DDEXPageContext, isModalOpenAtom } from './DDEXMainPage';
import StepsFormFirstPage from './StepFormFirstPage';
import StepsFormSecondPage from './StepsFormSecondPage';

export const checksEnabled = false;

export const fetchLabelOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.Label[]>(`/api/products/labels/`)
        .then(({ data }) =>
            data.map((label) => ({
                label: label.name,
                value: label.ID
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

export const fetchAssetLabelOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.AssetLabel[]>(`/api/products/asset_labels/`)
        .then(({ data }) =>
            data.map((label) => ({
                label: label.name,
                value: label.ID
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

export const fetchArtistOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.Artist[]>(`/api/products/artists/`)
        .then(({ data }) => data)
        .then((artists) =>
            artists.map((artist) => ({
                label: artist.key_name,
                value: artist.key_name
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

const ReleaseEntryStepForm = () => {
    const { stepFormRef, firstFormPageRef, secondFormPageRef, releasesActionRef } =
        useContext(DDEXPageContext);
    const [isOpen, setIsOpen] = useAtom(isModalOpenAtom);

    const handleFirstPageFlip = async (
        main_artists: API.MusicDistribution.Artist[],
        featuring_artists: API.MusicDistribution.Artist[]
    ) => {
        const tracks = secondFormPageRef.current?.getFieldValue('art_tracks');
        secondFormPageRef.current?.setFieldValue(
            'art_tracks',
            tracks.map((track: API.DDEX.Track) => ({ ...track, main_artists, featuring_artists }))
        );
    };
    return (
        // <ProCard>
        <StepsForm
            formRef={stepFormRef}
            stepsFormRender={(dom, submitter) => (
                <Modal
                    open={isOpen}
                    width={1600}
                    onCancel={() => setIsOpen(false)}
                    footer={submitter}
                    destroyOnClose
                >
                    <div style={{ marginTop: 36, marginBottom: 20 }}>{dom}</div>
                </Modal>
            )}
            onFinish={async (values) => {
                values = {
                    ...values,
                    artists: values.artists.map((key_name) => ({ key_name })),
                    featuring_artists: values.featuring_artists?.map((key_name) => ({ id: key_name })),
                    art_tracks: values.art_tracks.map((track) => ({
                        ...track,
                        main_artists: track.main_artists?.map((key_name) => ({ key_name })),
                        featuring_artists: track.featuring_artists?.map((key_name) => ({ key_name })),
                        composers: track.composers?.map((key_name) => ({ key_name })),
                        authors: track.authors?.map((key_name) => ({ key_name })),
                        remixers: track.remixers?.map((key_name) => ({ key_name })),
                        producers: track.producers?.map((key_name) => ({ key_name })),
                        arrangers: track.arrangers?.map((key_name) => ({ key_name })),
                        publishers: track.publishers?.map((key_name) => ({ key_name }))
                    }))
                };
                console.log(values);
                axiosApiInstance.post(`/api/products/releases/`, values);
                // message.success('submitted successfully');
                releasesActionRef?.current?.reload();
                // setIsOpen(false);
                // setIsOpen(false);
                return true;
            }}
        >
            <StepsForm.StepForm
                title='Release Information'
                layout='horizontal'
                formRef={firstFormPageRef}
                labelAlign='left'
                grid={true}
                rowProps={{
                    gutter: 4
                }}
                colProps={{ span: 24 }}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 24 }}
                dateFormatter={(value) => value.format('YYYY-MM-DDTHH:mm:ssZ')}
                onFinish={async ({ artists, featuring_artists }) => {
                    await handleFirstPageFlip(artists, featuring_artists);
                    return true;
                }}
            >
                <StepsFormFirstPage
                    initialValues={{
                        upc_ean: 12345600000
                    }}
                />
            </StepsForm.StepForm>
            <StepsForm.StepForm
                title='Art Tracks'
                formRef={secondFormPageRef}
                stepProps={{ description: 'Input information for each Art Track' }}
            >
                <StepsFormSecondPage />
            </StepsForm.StepForm>

            <StepsForm.StepForm title='Submit' stepProps={{ description: 'Preview & Submit Release' }}>
                <Result status='success' title='Release has been submitted successfully' />
            </StepsForm.StepForm>
        </StepsForm>
        // </ProCard>
    );
};

export default ReleaseEntryStepForm;
