import { cmsIdEnum, cmsIdToName } from '../common/cmsInfo';
import { fetchAnalytics } from './ytAnalyticsReport';
import { YTAnalyticsReportQueryParams } from '../common/models';
import { findMaxUnit, roundNumAdvanced } from '../common/utils';
import { postRequest } from './postRequest';

export type YoutubeAnalyticsResponse = {
  body: {
    [key: string]: {
      assoc_assets?: Array<string>;
      headers: {
        columnType: string;
        dataType: string;
        name: string;
      }[];
      rows: Array<string | number>;
    };
  };
};

export type YoutubeAnalyticsQuery = {
  date_range: string[];
  elements: string[];
  query_by: string;
  metric: string;
  dimension: string;
  currency?: string;
};

export const buildLifetimeAnalyticsReport = async () => {
  console.log('Started fetching analytics...');

  let aggrResult: {
    [key: string]: {
      lifetimeViewsBil: number;
      lifetimeWatchTimeHrsMil: number;
      lifetimeSubscribers: number;
      lifetimeRevenue: number;
    };
  } = {};

  const values = {
    startDate: '2000-01-01',
    endDate: '2021-05-25',
    metrics:
      'views,redViews,estimatedMinutesWatched,estimatedRedMinutesWatched,subscribersGained,subscribersLost,estimatedRevenue,estimatedAdRevenue,estimatedRedPartnerRevenue,grossRevenue',
    // filters: 'uploaderType==self'
  };

  for (const cmsId in cmsIdEnum) {
    // console.log('cmsId', cmsId, cmsIdToName[cmsId])

    // FETCH OWN CONTENT
    let res: any = await fetchAnalytics({
      ...values,
      filters: 'uploaderType==self',
      contentOwner: cmsId,
    });
    console.log('res1', res);
    aggrResult[cmsIdToName[cmsId as cmsIdEnum]] = {
      lifetimeViewsBil: Math.round(((res.rows[0][0] + res.rows[0][1]) / 1000000000) * 100) / 100, // billion 2 decimals
      lifetimeWatchTimeHrsMil: Math.round(((res.rows[0][2] + res.rows[0][3]) / 60 / 1000000) * 100) / 100, // million hrs
      lifetimeSubscribers: res.rows[0][4] - res.rows[0][5],
      // lifetimeRevenue: res.rows[0][6] + res.rows[0][9]
      lifetimeRevenue: res.rows[0][6] + res.rows[0][7] + res.rows[0][8] + res.rows[0][9],
    };

    // FETCH THIRD PARTY CLAIMED CONTENT
    res = await fetchAnalytics({
      ...values,
      filters: 'uploaderType==thirdParty;claimedStatus==claimed',
      contentOwner: cmsId,
    });
    console.log('res2', res);
    aggrResult[cmsIdToName[cmsId as cmsIdEnum]] = {
      lifetimeViewsBil:
        aggrResult[cmsIdToName[cmsId as cmsIdEnum]].lifetimeViewsBil +
        Math.round(((res.rows[0][0] + res.rows[0][1]) / 1000000000 + Number.EPSILON) * 100) / 100, // billion 2 decimals
      lifetimeWatchTimeHrsMil:
        aggrResult[cmsIdToName[cmsId as cmsIdEnum]].lifetimeWatchTimeHrsMil +
        Math.round(((res.rows[0][2] + res.rows[0][3]) / 60 / 1000000 + Number.EPSILON) * 100) / 100, // million hrs
      lifetimeSubscribers: aggrResult[cmsIdToName[cmsId as cmsIdEnum]].lifetimeSubscribers + res.rows[0][4] - res.rows[0][5],
      lifetimeRevenue: res.rows[0][6] + res.rows[0][7] + res.rows[0][8] + res.rows[0][9],
      // lifetimeRevenue: res.rows[0][6] + res.rows[0][9]
    };
  }

  let totalViews = 0,
    totalWatchTimeMin = 0,
    totalSubscribers = 0,
    totalRevenue = 0;
  for (const cms in aggrResult) {
    // if (cms === 'ALL') continue
    // console.log('aggrResult[cms]', this.state.aggrResult[cms], cms);
    totalViews += aggrResult[cms].lifetimeViewsBil;
    totalWatchTimeMin += aggrResult[cms].lifetimeWatchTimeHrsMil;
    totalSubscribers += aggrResult[cms].lifetimeSubscribers;
    totalRevenue += aggrResult[cms].lifetimeRevenue;
  }
  aggrResult['ALL'] = {
    lifetimeViewsBil: totalViews,
    // lifetimeViewsBil: Math.round(totalViews / 1000000000), // billion
    lifetimeWatchTimeHrsMil: totalWatchTimeMin,
    // lifetimeWatchTimeHrsMil: Math.round((totalWatchTimeMin / 60) / 1000000), // million hours
    lifetimeSubscribers: totalSubscribers,
    // lifetimeSubscribers: totalSubscribers
    lifetimeRevenue: totalRevenue,
  };

  // console.log(aggrResult);

  return aggrResult;

  // fs.writeFile('allanalytics.json', JSON.stringify(aggrResult), 'utf8', () => true);
};

export type aggregatedMetric = 'views' | 'watchTime' | 'subscribers' | 'revenue';
export const buildAnalyticsReportForMetricForCMSs = async (aggMetric: aggregatedMetric, cmsIds: cmsIdEnum[], ytParams: YTAnalyticsReportQueryParams) => {
  console.log('Started fetching analytics...');

  console.log(aggMetric, cmsIds, ytParams);

  let aggrResult: {
    [key: string]: {
      views?: number;
      watchTime?: number;
      subscribers?: number;
      revenue?: number;
    };
  } = {};

  let metrics;
  if (aggMetric === 'views') {
    metrics = 'views,redViews';
  } else if (aggMetric === 'watchTime') {
    metrics = 'estimatedMinutesWatched,estimatedRedMinutesWatched';
  } else if (aggMetric === 'subscribers') {
    metrics = 'subscribersGained,subscribersLost';
  } else if (aggMetric === 'revenue') {
    metrics = 'estimatedRevenue,estimatedAdRevenue,estimatedRedPartnerRevenue,grossRevenue';
  } else {
    metrics = '';
  }

  const values = {
    ...ytParams,
    metrics,
  };

  let dimensionToValueMap: { [key: string]: number } = {}; // dimension can be day, month or other supported dimensions (only 1)

  for (const cmsId of cmsIds) {
    console.log('cmsId', cmsId, cmsIdToName[cmsId], cmsId as cmsIdEnum);

    // console.log('res1', res1);
    // console.log('res2', res2);
    // for (const row of res1.rows) {
    //     const dim = row[0]
    //     if (!dimensionToValueMap[dim]) dimensionToValueMap[dim] = 0
    //     dimensionToValueMap[dim] += row[1] + row[2] // if views
    // }

    // FETCH OWN CONTENT
    const res1: any = await fetchAnalytics({
      ...values,
      filters: 'uploaderType==self',
      contentOwner: cmsId,
    });
    const res2: any = await fetchAnalytics({
      ...values,
      filters: 'uploaderType==thirdParty;claimedStatus==claimed',
      contentOwner: cmsId,
    });

    if (aggMetric === 'views') {
      for (const row of res1.rows) {
        const dim = row[0];
        if (!dimensionToValueMap[dim]) dimensionToValueMap[dim] = 0;
        dimensionToValueMap[dim] += row[1] + row[2]; // billion
      }
      for (const row of res2.rows) {
        const dim = row[0];
        if (!dimensionToValueMap[dim]) dimensionToValueMap[dim] = 0;
        dimensionToValueMap[dim] += row[1] + row[2]; // billion
      }
    } else if (aggMetric === 'watchTime') {
      for (const row of res1.rows) {
        const dim = row[0];
        if (!dimensionToValueMap[dim]) dimensionToValueMap[dim] = 0;
        dimensionToValueMap[dim] += row[1] + row[2]; // million hrs
      }
      for (const row of res2.rows) {
        const dim = row[0];
        if (!dimensionToValueMap[dim]) dimensionToValueMap[dim] = 0;
        dimensionToValueMap[dim] += row[1] + row[2]; // million hrs
      }
    } else if (aggMetric === 'subscribers') {
      for (const row of res1.rows) {
        const dim = row[0];
        if (!dimensionToValueMap[dim]) dimensionToValueMap[dim] = 0;
        dimensionToValueMap[dim] += row[1] - row[2]; // subsGained - subsLost
      }
      for (const row of res2.rows) {
        const dim = row[0];
        if (!dimensionToValueMap[dim]) dimensionToValueMap[dim] = 0;
        dimensionToValueMap[dim] += row[1] - row[2]; // subsGained - subsLost
      }
    } else if (aggMetric === 'revenue') {
      for (const row of res1.rows) {
        const dim = row[0];
        if (!dimensionToValueMap[dim]) dimensionToValueMap[dim] = 0;
        dimensionToValueMap[dim] += row[1] + row[2] + row[3] + row[4]; // if views
      }
      for (const row of res2.rows) {
        const dim = row[0];
        if (!dimensionToValueMap[dim]) dimensionToValueMap[dim] = 0;
        dimensionToValueMap[dim] += row[1] + row[2] + row[3] + row[4]; // if views
      }
    }
  }

  let entries = Object.entries(dimensionToValueMap);
  entries.forEach((e) => {
    if (aggMetric === 'views') {
      e[1] = Math.round((e[1] / 1000000 + Number.EPSILON) * 100) / 100; // mil
    } else if (aggMetric === 'watchTime') {
      e[1] = Math.round((e[1] / 60 / 1000 + Number.EPSILON) * 100) / 100; // k hrs
    } else if (aggMetric === 'subscribers') {
    } else if (aggMetric === 'revenue') {
    }
  });
  return entries.sort((a: any, b: any) => a - b);
};

export interface AggrReportResult {
  [key: string]: {
    values: { [key: string]: number };
    unit?: string;
    sum: { value: number; unit: string };
  };
}

export const buildAnalyticsReportForMetricForCMSsV2 = async (cmsIds: cmsIdEnum[], ytParams: YTAnalyticsReportQueryParams) => {
  console.log('Started fetching analytics...');

  // console.log(cmsIds, ytParams);
  // ytParams.filters = ytParams.filters ? ytParams.filters : ''

  // const metrics = 'views,redViews,estimatedMinutesWatched,estimatedRedMinutesWatched,subscribersGained,subscribersLost,estimatedRevenue,estimatedAdRevenue,estimatedRedPartnerRevenue,grossRevenue'
  const metrics =
    'views,redViews,estimatedMinutesWatched,estimatedRedMinutesWatched,subscribersGained,subscribersLost,estimatedRevenue,estimatedRedPartnerRevenue';

  const values = {
    ...ytParams,
    metrics,
  };

  // views -> month -> number of views
  let metricToDimensionToValueMap: AggrReportResult = {
    views: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    watchTime: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    subscribers: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    revenue: {
      values: {},
      sum: { value: 0, unit: '' },
    },
  }; // dimension can be day, month or other supported dimensions (only 1)

  for (const cmsId of cmsIds) {
    console.log('cmsId', cmsId, cmsId as cmsIdEnum);

    // FETCH OWN CONTENT
    const res1: any = await fetchAnalytics({
      ...values,
      filters: 'uploaderType==self',
      contentOwner: cmsId,
    });
    // console.log(res1.rows[0]);
    if ('error' in res1) continue;

    // FETCH THIRD PARTY CONTENT
    const res2: any = await fetchAnalytics({
      ...values,
      filters: 'uploaderType==thirdParty;claimedStatus==claimed',
      contentOwner: cmsId,
    });
    if ('error' in res2) continue;

    // console.log('TEST',
    //     values,
    //     { ...values, filters: 'uploaderType==self', contentOwner: cmsId },
    //     { ...values, filters: 'uploaderType==thirdParty;claimedStatus==claimed', contentOwner: cmsId }
    // );

    if (!res1.rows) res1.rows = [];
    if (!res2.rows) res2.rows = [];

    for (const row of res1.rows) {
      const dimension = row[0]; // probably month
      if (!metricToDimensionToValueMap.views.values[dimension]) metricToDimensionToValueMap.views.values[dimension] = 0;
      if (!metricToDimensionToValueMap.watchTime.values[dimension]) metricToDimensionToValueMap.watchTime.values[dimension] = 0;
      if (!metricToDimensionToValueMap.subscribers.values[dimension]) metricToDimensionToValueMap.subscribers.values[dimension] = 0;
      if (!metricToDimensionToValueMap.revenue.values[dimension]) metricToDimensionToValueMap.revenue.values[dimension] = 0;
      // metricToDimensionToValueMap.views.values[dimension] += row[1] + row[2]  // billion WRONG
      metricToDimensionToValueMap.views.values[dimension] += row[1]; // billion
      // metricToDimensionToValueMap.watchTime.values[dimension] += row[3] + row[4]  // million hrs WRONG
      metricToDimensionToValueMap.watchTime.values[dimension] += row[3]; // million hrs
      metricToDimensionToValueMap.subscribers.values[dimension] += row[5] - row[6]; // subsGained - subsLost
      // metricToDimensionToValueMap.revenue.values[dimension] += row[7] + row[8] // if views WRONG
      metricToDimensionToValueMap.revenue.values[dimension] += row[7]; // if views
    }

    for (const row of res2.rows) {
      const dimension = row[0]; // probably month
      if (!metricToDimensionToValueMap.views.values[dimension]) metricToDimensionToValueMap.views.values[dimension] = 0;
      if (!metricToDimensionToValueMap.watchTime.values[dimension]) metricToDimensionToValueMap.watchTime.values[dimension] = 0;
      if (!metricToDimensionToValueMap.subscribers.values[dimension]) metricToDimensionToValueMap.subscribers.values[dimension] = 0;
      if (!metricToDimensionToValueMap.revenue.values[dimension]) metricToDimensionToValueMap.revenue.values[dimension] = 0;
      // metricToDimensionToValueMap.views.values[dimension] += row[1] + row[2]  // billion WRONG
      metricToDimensionToValueMap.views.values[dimension] += row[1]; // billion
      // metricToDimensionToValueMap.watchTime.values[dimension] += row[3] + row[4]  // million hrs WRONG
      metricToDimensionToValueMap.watchTime.values[dimension] += row[3]; // million hrs
      metricToDimensionToValueMap.subscribers.values[dimension] += row[5] - row[6]; // subsGained - subsLost
      // metricToDimensionToValueMap.revenue.values[dimension] += row[7] + row[8] // if views WRONG
      metricToDimensionToValueMap.revenue.values[dimension] += row[7]; // if views
    }
  }

  // calculate sums
  metricToDimensionToValueMap = calculateSumsForAggrReportResult(metricToDimensionToValueMap);

  // console.log('metricToDimensionToValueMap', metricToDimensionToValueMap);

  // init round units per metric (based on the max value of a metric)
  metricToDimensionToValueMap = initRoundUnitsPerMetricForAggrReportResult(metricToDimensionToValueMap);

  // make transformations, roundings etc.
  metricToDimensionToValueMap = roundUpAggrReportResultValues(metricToDimensionToValueMap);

  console.log('builder', metricToDimensionToValueMap);
  // console.log('sort', Object.entries(metricToDimensionToValueMap.views))
  // console.log('sort', Object.entries(metricToDimensionToValueMap.views).sort((a: any, b: any) => -a[0].localeCompare(b[0])));
  return metricToDimensionToValueMap;
};

export const buildAnalyticsReportForMetricForCMSsV3 = (results: Array<YoutubeAnalyticsResponse>) => {
  // views -> month -> number of views
  let metricToDimensionToValueMap: AggrReportResult = {
    views: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    watchTime: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    subscribers: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    revenue: {
      values: {},
      sum: { value: 0, unit: '' },
    },
  }; // dimension can be day, month or other supported dimensions (only 1)

  const [selfUploaded, thirdParty] = results;
  const cmsIDs = Object.keys(selfUploaded.body);
  console.log('list of cms: ', cmsIDs);

  for (const id of cmsIDs) {
    const selfUploadedRows = selfUploaded.body[id].rows || [];
    const thirdPartyRows = thirdParty.body[id].rows || [];

    console.log(`for the CMS with id ${id} we are examining the following rows: `);
    console.log('self uploaded rows: ', selfUploadedRows, 'third party rows: ', thirdPartyRows);

    for (const row of selfUploadedRows.concat(thirdPartyRows)) {
      const dimension = row[0];
      if (!metricToDimensionToValueMap.views.values[dimension]) metricToDimensionToValueMap.views.values[dimension] = 0;
      if (!metricToDimensionToValueMap.watchTime.values[dimension]) metricToDimensionToValueMap.watchTime.values[dimension] = 0;
      if (!metricToDimensionToValueMap.subscribers.values[dimension]) metricToDimensionToValueMap.subscribers.values[dimension] = 0;
      if (!metricToDimensionToValueMap.revenue.values[dimension]) metricToDimensionToValueMap.revenue.values[dimension] = 0;

      metricToDimensionToValueMap.views.values[dimension] += row[1]; // billion
      metricToDimensionToValueMap.watchTime.values[dimension] += row[3]; // million hrs
      metricToDimensionToValueMap.subscribers.values[dimension] += row[5] - row[6]; // subsGained - subsLost
      metricToDimensionToValueMap.revenue.values[dimension] += row[7]; // if views
    }
  }

  // calculate sums
  metricToDimensionToValueMap = calculateSumsForAggrReportResult(metricToDimensionToValueMap);

  // init round units per metric (based on the max value of a metric)
  metricToDimensionToValueMap = initRoundUnitsPerMetricForAggrReportResult(metricToDimensionToValueMap);

  // make transformations, roundings etc.
  metricToDimensionToValueMap = roundUpAggrReportResultValues(metricToDimensionToValueMap);

  console.log('builder', metricToDimensionToValueMap);
  return metricToDimensionToValueMap;
};

export interface CmsIdToAssetsMap {
  [cmsId: string]: string[];
}

export const buildAnalyticsReportForMetricForAssetsComparative = async (
  cmsIdToAssetsMap1: CmsIdToAssetsMap,
  cmsIdToAssetsMap2: CmsIdToAssetsMap,
  ytParams: YTAnalyticsReportQueryParams
) => {
  console.log('Started fetching analytics...');

  const metrics =
    'views,redViews,estimatedMinutesWatched,estimatedRedMinutesWatched,subscribersGained,subscribersLost,estimatedRevenue,estimatedRedPartnerRevenue';

  const values = {
    ...ytParams,
    metrics,
  };

  let metricToDimensionToValueMapGeneral: any[] = [];

  for (const cmsIdToAssetsMap of [cmsIdToAssetsMap1, cmsIdToAssetsMap2]) {
    // views -> month -> number of views
    let metricToDimensionToValueMap: AggrReportResult = {
      views: {
        values: {},
        sum: { value: 0, unit: '' },
      },
      watchTime: {
        values: {},
        sum: { value: 0, unit: '' },
      },
      subscribers: {
        values: {},
        sum: { value: 0, unit: '' },
      },
      revenue: {
        values: {},
        sum: { value: 0, unit: '' },
      },
    }; // dimension can be day, month or other supported dimensions (only 1)
    console.log('investigating cmsIdToAssetsMap: ', cmsIdToAssetsMap);

    for (const cmsId in cmsIdToAssetsMap) {
      console.log('cmsId', cmsId, cmsId as cmsIdEnum);

      if (cmsIdToAssetsMap[cmsId].length === 0 || !cmsIdToAssetsMap[cmsId]) continue;
      // FETCH OWN CONTENT

      // console.log('join',cmsIdToAssetsMap[cmsId].join(','));
      // console.log('TEST',{ ...values, contentOwner: cmsId as cmsIdEnum, filters: 'channel==' + cmsIdToAssetsMap[cmsId].join(',') });
      const res: any = await fetchAnalytics({
        ...values,
        contentOwner: cmsId as cmsIdEnum,
        filters: 'channel==' + cmsIdToAssetsMap[cmsId].join(','),
      });

      if ('error' in res) continue;
      // console.log(res1.rows[0]);
      console.log('res', res.rows);

      if (!res.rows) res.rows = [];

      for (const row of res.rows) {
        const dimension = row[0]; // probably month
        if (!metricToDimensionToValueMap.views.values[dimension]) metricToDimensionToValueMap.views.values[dimension] = 0;
        if (!metricToDimensionToValueMap.watchTime.values[dimension]) metricToDimensionToValueMap.watchTime.values[dimension] = 0;
        if (!metricToDimensionToValueMap.subscribers.values[dimension]) metricToDimensionToValueMap.subscribers.values[dimension] = 0;
        if (!metricToDimensionToValueMap.revenue.values[dimension]) metricToDimensionToValueMap.revenue.values[dimension] = 0;
        // metricToDimensionToValueMap.views.values[dimension] += row[1] + row[2]  // billion WRONG
        metricToDimensionToValueMap.views.values[dimension] += row[1]; // billion
        // metricToDimensionToValueMap.watchTime.values[dimension] += row[3] + row[4]  // million hrs WRONG
        metricToDimensionToValueMap.watchTime.values[dimension] += row[3]; // million hrs
        metricToDimensionToValueMap.subscribers.values[dimension] += row[5] - row[6]; // subsGained - subsLost
        // metricToDimensionToValueMap.revenue.values[dimension] += row[7] + row[8] // if views WRONG
        metricToDimensionToValueMap.revenue.values[dimension] += row[7]; // if views
      }
    }

    // calculate sums
    metricToDimensionToValueMap = calculateSumsForAggrReportResult(metricToDimensionToValueMap);

    // init round units per metric (based on the max value of a metric)
    metricToDimensionToValueMap = initRoundUnitsPerMetricForAggrReportResult(metricToDimensionToValueMap);

    metricToDimensionToValueMapGeneral.push(metricToDimensionToValueMap);
  }

  console.log('map general before rounding :', metricToDimensionToValueMapGeneral);

  for (const metric of ['views', 'watchTime', 'subscribers', 'revenue']) {
    //find the max unit for data
    let maxUnit = findMaxUnit(metricToDimensionToValueMapGeneral[0][metric].unit, metricToDimensionToValueMapGeneral[1][metric].unit, metric == 'watchTime');

    metricToDimensionToValueMapGeneral[0][metric].unit = maxUnit;
    metricToDimensionToValueMapGeneral[1][metric].unit = maxUnit;

    metricToDimensionToValueMapGeneral[0] = roundUpAggrReportResultValuesForMetric(metricToDimensionToValueMapGeneral[0], metric, maxUnit);
    metricToDimensionToValueMapGeneral[1] = roundUpAggrReportResultValuesForMetric(metricToDimensionToValueMapGeneral[1], metric, maxUnit);
  }

  // make transformations, roundings etc.
  // metricToDimensionToValueMap = roundUpAggrReportResultValuesComparative(metricToDimensionToValueMap)

  // console.log('builder', metricToDimensionToValueMap);

  return metricToDimensionToValueMapGeneral;
};

function aggregate(response: YoutubeAnalyticsResponse): AggrReportResult {
  let aggregatedReport: AggrReportResult = {
    views: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    watchTime: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    subscribers: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    revenue: {
      values: {},
      sum: { value: 0, unit: '' },
    },
  };

  const body = response.body;

  for (const cms in body) {
    const res = body[cms];
    const columnHeaders = res.headers;

    const dimensionIndex = columnHeaders.findIndex((col) => {
      return col.columnType === 'DIMENSION';
    });
    const viewsIndex = columnHeaders.findIndex((col) => {
      return col.name === 'views';
    });
    const watchTimeIndex = columnHeaders.findIndex((col) => {
      return col.name === 'estimatedMinutesWatched';
    });
    const subsGainedIndex = columnHeaders.findIndex((col) => {
      return col.name === 'subscribersGained';
    });
    const subsLostIndex = columnHeaders.findIndex((col) => {
      return col.name === 'subscribersLost';
    });
    const revenueIndex = columnHeaders.findIndex((col) => {
      return col.name === 'estimatedRevenue';
    });

    for (const row of res.rows) {
      const dimension = row[dimensionIndex]; // probably month
      if (!aggregatedReport.views.values[dimension]) aggregatedReport.views.values[dimension] = 0;
      if (!aggregatedReport.watchTime.values[dimension]) aggregatedReport.watchTime.values[dimension] = 0;
      if (!aggregatedReport.subscribers.values[dimension]) aggregatedReport.subscribers.values[dimension] = 0;
      if (!aggregatedReport.revenue.values[dimension]) aggregatedReport.revenue.values[dimension] = 0;

      aggregatedReport.views.values[dimension] += row[viewsIndex]; // billion
      aggregatedReport.watchTime.values[dimension] += row[watchTimeIndex]; // million hrs
      aggregatedReport.subscribers.values[dimension] += row[subsGainedIndex] - row[subsLostIndex]; // subsGained - subsLost
      aggregatedReport.revenue.values[dimension] += row[revenueIndex]; // if views
    }
  }
  return aggregatedReport;
}

export const roundUpAggrReportResultValuesComparative = (analyticsResponses: YoutubeAnalyticsResponse[]) => {
  let finalMap: any[] = [];
  let metricToDimensionToValueMapGeneral: AggrReportResult[] = analyticsResponses.map((response) => aggregate(response));

  for (let metricToDimensionToValueMap of metricToDimensionToValueMapGeneral) {
    // calculate sums
    metricToDimensionToValueMap = calculateSumsForAggrReportResult(metricToDimensionToValueMap);

    // init round units per metric (based on the max value of a metric)
    metricToDimensionToValueMap = initRoundUnitsPerMetricForAggrReportResult(metricToDimensionToValueMap);

    finalMap.push(metricToDimensionToValueMap);
  }

  for (const metric of ['views', 'watchTime', 'subscribers', 'revenue']) {
    //find the max unit for data
    let maxUnit = findMaxUnit(finalMap[0][metric].unit, finalMap[1][metric].unit, metric == 'watchTime');

    finalMap[0][metric].unit = maxUnit;
    finalMap[1][metric].unit = maxUnit;

    finalMap[0] = roundUpAggrReportResultValuesForMetric(metricToDimensionToValueMapGeneral[0], metric, maxUnit);
    finalMap[1] = roundUpAggrReportResultValuesForMetric(metricToDimensionToValueMapGeneral[1], metric, maxUnit);
  }

  return finalMap;
};

export const buildAnalyticsReportForMetricForAssets = async (cmsIdToAssetsMap: CmsIdToAssetsMap, ytParams: YTAnalyticsReportQueryParams) => {
  console.log('Started fetching analytics...');

  // console.log(cmsIds, ytParams);

  const metrics =
    'views,redViews,estimatedMinutesWatched,estimatedRedMinutesWatched,subscribersGained,subscribersLost,estimatedRevenue,estimatedRedPartnerRevenue';

  const values = {
    ...ytParams,
    metrics,
  };

  // views -> month -> number of views
  let metricToDimensionToValueMap: AggrReportResult = {
    views: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    watchTime: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    subscribers: {
      values: {},
      sum: { value: 0, unit: '' },
    },
    revenue: {
      values: {},
      sum: { value: 0, unit: '' },
    },
  }; // dimension can be day, month or other supported dimensions (only 1)

  for (const cmsId in cmsIdToAssetsMap) {
    console.log('cmsId', cmsId, cmsId as cmsIdEnum);

    if (cmsIdToAssetsMap[cmsId].length === 0 || !cmsIdToAssetsMap[cmsId]) continue;
    // FETCH OWN CONTENT

    // console.log('join',cmsIdToAssetsMap[cmsId].join(','));
    // console.log('TEST',{ ...values, contentOwner: cmsId as cmsIdEnum, filters: 'channel==' + cmsIdToAssetsMap[cmsId].join(',') });
    const res: any = await fetchAnalytics({
      ...values,
      contentOwner: cmsId as cmsIdEnum,
      filters: 'channel==' + cmsIdToAssetsMap[cmsId].join(','),
    });

    if ('error' in res) continue;
    // console.log(res1.rows[0]);
    // console.log('res',res.rows);

    if (!res.rows) res.rows = [];

    for (const row of res.rows) {
      const dimension = row[0]; // probably month
      if (!metricToDimensionToValueMap.views.values[dimension]) metricToDimensionToValueMap.views.values[dimension] = 0;
      if (!metricToDimensionToValueMap.watchTime.values[dimension]) metricToDimensionToValueMap.watchTime.values[dimension] = 0;
      if (!metricToDimensionToValueMap.subscribers.values[dimension]) metricToDimensionToValueMap.subscribers.values[dimension] = 0;
      if (!metricToDimensionToValueMap.revenue.values[dimension]) metricToDimensionToValueMap.revenue.values[dimension] = 0;
      // metricToDimensionToValueMap.views.values[dimension] += row[1] + row[2]  // billion WRONG
      metricToDimensionToValueMap.views.values[dimension] += row[1]; // billion
      // metricToDimensionToValueMap.watchTime.values[dimension] += row[3] + row[4]  // million hrs WRONG
      metricToDimensionToValueMap.watchTime.values[dimension] += row[3]; // million hrs
      metricToDimensionToValueMap.subscribers.values[dimension] += row[5] - row[6]; // subsGained - subsLost
      // metricToDimensionToValueMap.revenue.values[dimension] += row[7] + row[8] // if views WRONG
      metricToDimensionToValueMap.revenue.values[dimension] += row[7]; // if views
    }
  }

  console.log('metricToDimensionToValueMap', metricToDimensionToValueMap);

  // calculate sums
  metricToDimensionToValueMap = calculateSumsForAggrReportResult(metricToDimensionToValueMap);

  // init round units per metric (based on the max value of a metric)
  metricToDimensionToValueMap = initRoundUnitsPerMetricForAggrReportResult(metricToDimensionToValueMap);

  // make transformations, roundings etc.
  metricToDimensionToValueMap = roundUpAggrReportResultValues(metricToDimensionToValueMap);

  console.log('builder', metricToDimensionToValueMap);

  return metricToDimensionToValueMap;
};

export const roundUpAggrReportResultValuesForMetric = (aggrReportResult: AggrReportResult, metric: string, unit?: string): AggrReportResult => {
  // make transformations, roundings etc.

  if (metric === 'views') {
    // round to million views
    for (const dimension in aggrReportResult[metric].values) {
      // metricToDimensionToValueMap[metric][dimension] = roundNum(metricToDimensionToValueMap[metric][dimension] / 1000000)
      // console.log('rounding', metricToDimensionToValueMap[metric].values[dimension], 'to', metricToDimensionToValueMap[metric].unit, 'result', roundNumAdvanced(metricToDimensionToValueMap[metric].values[dimension], false, 2, metricToDimensionToValueMap[metric].unit).value);

      aggrReportResult[metric].values[dimension] = roundNumAdvanced(
        aggrReportResult[metric].values[dimension],
        false,
        2,
        unit || aggrReportResult[metric].unit
      ).value;
    }
  } else if (metric === 'watchTime') {
    // round to thousand hours
    for (const dimension in aggrReportResult[metric].values) {
      // metricToDimensionToValueMap[metric][dimension] = roundNum((metricToDimensionToValueMap[metric][dimension] / 60) / 1000)
      aggrReportResult[metric].values[dimension] = roundNumAdvanced(
        aggrReportResult[metric].values[dimension],
        true,
        1,
        unit || aggrReportResult[metric].unit
      ).value;
    }
  } else if (metric === 'subscribers') {
    // no transformation
    // aggrReportResult[metric].unit = ''
    for (const dimension in aggrReportResult[metric].values) {
      // metricToDimensionToValueMap[metric][dimension] = roundNum((metricToDimensionToValueMap[metric][dimension] / 60) / 1000)
      aggrReportResult[metric].values[dimension] = roundNumAdvanced(
        aggrReportResult[metric].values[dimension],
        false,
        undefined,
        unit || aggrReportResult[metric].unit
      ).value;
    }
  } else if (metric === 'revenue') {
    // no
    // aggrReportResult[metric].unit = '€'
    for (const dimension in aggrReportResult[metric].values) {
      // metricToDimensionToValueMap[metric][dimension] = roundNum((metricToDimensionToValueMap[metric][dimension] / 60) / 1000)
      aggrReportResult[metric].values[dimension] = roundNumAdvanced(
        aggrReportResult[metric].values[dimension],
        false,
        undefined,
        unit || aggrReportResult[metric].unit,
        true
      ).value;
    }
  }

  return aggrReportResult;
};

export const roundUpAggrReportResultValues = (aggrReportResult: AggrReportResult, unit?: string): AggrReportResult => {
  // make transformations, roundings etc.
  for (const metric in aggrReportResult) {
    if (metric === 'views') {
      // round to million views
      for (const dimension in aggrReportResult[metric].values) {
        // metricToDimensionToValueMap[metric][dimension] = roundNum(metricToDimensionToValueMap[metric][dimension] / 1000000)
        // console.log('rounding', metricToDimensionToValueMap[metric].values[dimension], 'to', metricToDimensionToValueMap[metric].unit, 'result', roundNumAdvanced(metricToDimensionToValueMap[metric].values[dimension], false, 2, metricToDimensionToValueMap[metric].unit).value);

        aggrReportResult[metric].values[dimension] = roundNumAdvanced(
          aggrReportResult[metric].values[dimension],
          false,
          2,
          unit || aggrReportResult[metric].unit
        ).value;
      }
    } else if (metric === 'watchTime') {
      // round to thousand hours
      for (const dimension in aggrReportResult[metric].values) {
        // metricToDimensionToValueMap[metric][dimension] = roundNum((metricToDimensionToValueMap[metric][dimension] / 60) / 1000)
        aggrReportResult[metric].values[dimension] = roundNumAdvanced(
          aggrReportResult[metric].values[dimension],
          true,
          1,
          unit || aggrReportResult[metric].unit
        ).value;
      }
    } else if (metric === 'subscribers') {
      // no transformation
      // aggrReportResult[metric].unit = ''
      for (const dimension in aggrReportResult[metric].values) {
        // metricToDimensionToValueMap[metric][dimension] = roundNum((metricToDimensionToValueMap[metric][dimension] / 60) / 1000)
        aggrReportResult[metric].values[dimension] = roundNumAdvanced(
          aggrReportResult[metric].values[dimension],
          false,
          undefined,
          unit || aggrReportResult[metric].unit
        ).value;
      }
    } else if (metric === 'revenue') {
      // no
      // aggrReportResult[metric].unit = '€'
      for (const dimension in aggrReportResult[metric].values) {
        // metricToDimensionToValueMap[metric][dimension] = roundNum((metricToDimensionToValueMap[metric][dimension] / 60) / 1000)
        aggrReportResult[metric].values[dimension] = roundNumAdvanced(
          aggrReportResult[metric].values[dimension],
          false,
          undefined,
          unit || aggrReportResult[metric].unit,
          true
        ).value;
      }
    }
  }

  return aggrReportResult;
};

export const calculateSumsForAggrReportResult = (aggrReportResult: AggrReportResult): AggrReportResult => {
  for (const metric in aggrReportResult) {
    for (const dimension in aggrReportResult[metric].values) {
      // collect raw sum
      aggrReportResult[metric].sum.value += aggrReportResult[metric].values[dimension];
    }
    // round

    aggrReportResult[metric].sum = roundNumAdvanced(aggrReportResult[metric].sum.value, metric === 'watchTime', 2, undefined, metric === 'revenue');
    console.log('sum', metric, aggrReportResult[metric].sum);
  }

  return aggrReportResult;
};

export const initRoundUnitsPerMetricForAggrReportResult = (aggrReportResult: AggrReportResult): AggrReportResult => {
  // init round units per metric (based on the first value of a metric)

  for (const metric in aggrReportResult) {
    const valuesOfMetric = Object.values(aggrReportResult[metric].values);
    const maxValueOfMetric = Math.max(...valuesOfMetric);
    // console.log('initr', metric, firstValueOfMetric);
    if (maxValueOfMetric !== undefined) {
      // console.log('initrun', metric, valuesOfMetric, maxValueOfMetric, roundNumAdvanced(maxValueOfMetric, metric === 'watchTime').unit);
      aggrReportResult[metric].unit = roundNumAdvanced(maxValueOfMetric, metric === 'watchTime', undefined, undefined, metric === 'revenue').unit;
      // console.log(metric, firstValueOfMetric, roundNumAdvanced(firstValueOfMetric).unit);
      // metricToDimensionToValueMap[metric].unit = roundNumAdvanced(firstValueOfMetric, metric === 'watchTime',).unit
    } else {
      aggrReportResult[metric].unit = '';
    }
  }

  return aggrReportResult;
};

export const convertAggrReportResultToCumulative = (aggrReportResult: AggrReportResult): AggrReportResult => {
  // aggrReportResult = JSON.parse(JSON.stringify(aggrReportResult))

  for (const metric in aggrReportResult) {
    // console.log('metric', metric);

    let entries = Object.entries(aggrReportResult[metric].values);
    entries.sort((a: any, b: any) => a[0].localeCompare(b[0]));
    // console.log(metricToDimensionToValueMap[metric], 'entries', entries);

    entries.forEach(([key, value], index) => {
      // if (metric==='views')console.log(index);
      if (index === 0) return;
      // console.log(key, metricToDimensionToValueMap[metric].values[key], metricToDimensionToValueMap[metric].values[entries[index - 1][0]], metricToDimensionToValueMap[metric].values[key] + metricToDimensionToValueMap[metric].values[entries[index - 1][0]]);

      aggrReportResult[metric].values[key] = aggrReportResult[metric].values[key] + aggrReportResult[metric].values[entries[index - 1][0]];
    });
  }

  return aggrReportResult;
};

export interface AggrReportGraphData {
  [key: string]: { data: any[]; unit?: string };
}

export const constructGraphDataFromAggrReportResult = (aggrReportResult: AggrReportResult): AggrReportGraphData => {
  return {
    views: {
      data: Object.entries(aggrReportResult.views.values).sort((a: any, b: any) => a[0].localeCompare(b[0])),
      unit: aggrReportResult.views.unit,
    },
    watchTime: {
      data: Object.entries(aggrReportResult.watchTime.values).sort((a: any, b: any) => a[0].localeCompare(b[0])),
      unit: aggrReportResult.watchTime.unit,
    },
    subscribers: {
      data: Object.entries(aggrReportResult.subscribers.values).sort((a: any, b: any) => a[0].localeCompare(b[0])),
      unit: aggrReportResult.subscribers.unit,
    },
    revenue: {
      data: Object.entries(aggrReportResult.revenue.values).sort((a: any, b: any) => a[0].localeCompare(b[0])),
      unit: aggrReportResult.revenue.unit,
    },
  };
};

// export const constructGraphDataComparative = (graphData1:any, graphData2:any) => {
//
//     let graphData = [
//         ...graphData1.map((i: any[]) => ({
//             [this.props.dimension]: i[0],
//             [this.props.metric]: i[1],
//             category: 'Left'
//         })),
//         ...this.props.data2.map((i: any[]) => ({
//             [this.props.dimension]: i[0],
//             [this.props.metric]: i[1],
//             category: 'Right'
//         })),
//     ]
//
//     graphData.sort((a: any, b: any) => {
//         let dsa = a[this.props.dimension].split('-'),
//             dsb = b[this.props.dimension].split('-')
//         if (new Date(dsa[0], dsa[1]).valueOf() > new Date(dsb[0], dsb[1]).valueOf()) {
//             return 1
//         } else if (new Date(dsa[0], dsa[1]).valueOf() < new Date(dsb[0], dsb[1]).valueOf()) {
//             return -1
//         } else return 0
//     });
// }
