import { fetchAnalytics } from "../api/ytAnalyticsReport";
import { DISCOVERY_DOC_YT_AL, OAUTH2_CLIENT_ID, OAUTH2_SCOPES } from "./youtube_api_constants";

export const initClient = (callback: Function) => {
    (window as any).gapi.client.init({ clientId: OAUTH2_CLIENT_ID, scope: OAUTH2_SCOPES.join(' ') })
        .then(() => {
    console.log('2 init client then')
            loadYTAPIClientInterfaces(callback);

            // Listen for sign-in state changes.
            (window as any).gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);


            // console.log('user', user);
            if (isUserAuthorized()) {
                console.log('You are currently signed in and have granted access to the Reporting app.');
            }

        }).catch(() => {
            callback(false)
            console.log('Error on init client.');
        });
}

export const sendAuthorizedApiRequest = (requestData: any) => {
    // console.log('sendAuthorizedApiRequest', this.state.isAuthorized);
    if (isUserAuthorized()) {
        fetchAnalytics(requestData)
    } else {
        (window as any).gapi.auth2.getAuthInstance().signIn()
            .then((result: any) => {
                console.log('You are currently signed in and have granted access to the Reporting app.');
                fetchAnalytics(requestData)
            }).catch((err: any) => {
                console.log('Error on sign in', err);
                console.log('You have not authorized the Reporting app or you are signed out.');
            });;
    }
}

export const setSigninStatus = () => {

    if (isUserAuthorized()) {
        console.log('You are currently signed in and have granted access to the Reporting app.');
    } else {
        console.log('You have not authorized the Reporting app or you are signed out.');
    }

}

export const isUserAuthorized = () => {
    // console.log((window as any).gapi.auth2.getAuthInstance() !== null && (window as any).gapi.auth2.getAuthInstance().currentUser.get().hasGrantedScopes(OAUTH2_SCOPES.join(' ')));
    return (window as any).gapi.auth2.getAuthInstance() !== null && (window as any).gapi.auth2.getAuthInstance().currentUser.get().hasGrantedScopes(OAUTH2_SCOPES.join(' '))
}

export const updateSigninStatus = () => {
    setSigninStatus();
}

export const handleAuthClick = () => {
    if ((window as any).gapi.auth2.getAuthInstance().isSignedIn.get()) {
        // User is authorized and has clicked "Sign out" button.
        (window as any).gapi.auth2.getAuthInstance().signOut();
    } else {
        // User is not signed in. Start Google auth flow.
        (window as any).gapi.auth2.getAuthInstance().signIn();
    }
}

export const authorize = () => {
    // User is not signed in. Start Google auth flow.
    return (window as any).gapi.auth2.getAuthInstance().signIn();
}

export const loadYTAPIClientInterfaces = (callback: Function) => {
    (window as any).gapi.client.load(DISCOVERY_DOC_YT_AL)
        .then((result: any) => {
            console.log('3 loadYTAPIClientInterfaces',result)
            callback(true)
            console.log('Successfully loaded YT Analytics client.');
            // fetchAnalytics({ startDate: '2021-05-01', endDate: '2021-05-20', filters: 'uploaderType==self', metrics: 'views' }, (res: any) => console.log(res))
        }).catch((err: any) => {
            console.log('loadYTAPIClientInterfaces err',err)
            callback(false)
            console.log('Error on loading YT Analytics client.', err);
        });
}

export const revokeAccess = (callback: Function) => {
    (window as any).gapi.auth2.getAuthInstance().disconnect();
    callback()
}