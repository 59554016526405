import {Button, notification, Popconfirm, Row} from 'antd';
import React, {Component} from 'react';
import {postRequest} from '../../../api/postRequest';
import CustomerReportTable from '../CustomerReportTable';
import './index.css';

export interface IFinalizeStepProps {
    //Here we pass the Props Interface
    handler?: Function,
    year: string,
    id: string,
    type_cycle: string,
}

export interface IFinalizeStepState {
    //here we pass the State Interface
    year: string,
    id: string,
    type_cycle: string,
    previous: boolean,
    proceed: boolean
    loading: boolean
}

const MOCK_FINALIZE = false

//class ComponentName Component<PropsInterface, StateInterface>
class FinalizeStep extends Component<IFinalizeStepProps, IFinalizeStepState> {

    constructor(props: IFinalizeStepProps) {
        super(props)

        this.state = {
            id: this.props.id,
            year: this.props.year,
            type_cycle: this.props.type_cycle,
            previous: false,
            proceed: false,
            loading: false
        }

        this.previousStep = this.previousStep.bind(this)
        this.proceedStep = this.proceedStep.bind(this)
        this.finalizePayments = this.finalizePayments.bind(this)
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }

    render() {
        return (
            <>

                <Row style={{width: '100%'}}>
                    {/* <Result
						status="success"
						title="Successfully Made it to Final Step"
						subTitle="By pressing FINALIZE button this cycle is going to close."
						extra={[
							<Button type="ghost" onClick={() => this.previousStep()} >Previous Step</Button>,
							<Button type="primary">
								Distribute Client Reports
							</Button>,
							<Space>   </Space>,
							<Button type="primary" onClick={() => this.proceedStep()}>
								FINALIZE
							</Button>,
						]}
					/> */}

                    <CustomerReportTable/>
                </Row>

                <br/>
                <Row justify="space-between" align="middle">

                    {/* <Space>
						<Button type={"primary"} onClick={() => this.calculateResults()}>Calculate Results</Button>

					</Space> */}
                    <Button type={"ghost"} onClick={() => this.previousStep()} disabled={this.state.previous}>Previous
                        Step</Button>
                    <Popconfirm title='Are you sure? You will not be able to make any other changes if you continue.'
                                onConfirm={this.proceedStep} disabled={this.state.proceed}>
                        <Button type={"primary"} disabled={this.state.proceed} loading={this.state.loading}>Finalize
                            Payments</Button>
                    </Popconfirm>
                    {/* <Space>
					</Space> */}
                </Row>
            </>

        );
    }


    wait(milliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }


    finalizePayments = async () => {

        if (MOCK_FINALIZE) {
            await this.wait(1000)
            // this.setState({ loading: false, proceed_disabled: false, compute_exists_disabled: true })
            return
        }


        this.setState({loading: true})
        await postRequest('/api/erp/newcycle/finalizepayments')
        this.setState({loading: false})

        notification.success({message: 'Successfully finalized payments.'})

    }

    previousStep(): void {

        postRequest(`/api/erp/newcycle/proceedcycle`, {
            id: this.state.id,
            year: this.state.year,
            step: "3"
        }).then((response) => {
            console.log('Authenticated');
            console.log(response)

            this.props.handler ? this.props.handler() : window.location.reload()
        }).catch(function (error) {
            console.log('Error on GET :' + error);
        });

    }

    async proceedStep() {

        await this.finalizePayments()

        postRequest(`/api/erp/newcycle/proceedcycle`, {
            id: this.state.id,
            year: this.state.year,
            step: "5"
        })
            .then((response) => {
                // console.log('Authenticated');
                // console.log(response)

                this.props.handler ? this.props.handler() : window.location.reload()
            }).catch(function (error) {
            console.log('Error on GET :' + error);
        });

    }

}

export default FinalizeStep;