import React, {Component} from 'react';
// import { Grid, GridRow, GridCell, List, TextField, Button } from 'rmwc';
import NewAssetForm from '../../components/NewAssetForm';
import './index.css';
import {Button, Card, FormInstance, message, Row, Space, Typography} from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import Text from 'antd/lib/typography/Text';
import {postRequest} from "../../../api/postRequest";
import {PageContainer} from "@ant-design/pro-components";

const {Title} = Typography;


export interface INewAssetProps {
    //Here we pass the Props Interface
    edit?: boolean
    id?: number;
}

export interface INewAssetState {
    //here we pass the State Interface
    formRef: React.RefObject<FormInstance>;
    changeOccured: boolean
}

//class ComponentName Component<PropsInterface, StateInterface>
class NewAsset extends Component<INewAssetProps, INewAssetState> {
    constructor(props: INewAssetProps) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onValidate = this.onValidate.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onFormValuesChange = this.onFormValuesChange.bind(this);

        //Component State
        this.state = {
            formRef: React.createRef<FormInstance>(),
            changeOccured: false
        };
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }

    onFormValuesChange() {
        this.setState({changeOccured: true})
    }

    onSubmit(e: any) {
        e.preventDefault();
        console.log(this.state.formRef.current?.getFieldsValue())
        this.state.formRef.current
            ?.validateFields()
            .then((result) => {
                if (this.props.edit) {
                    this.editAsset()
                } else {
                    this.postNewAsset()
                }
            })
            .catch((err) => {
                message.error({content: 'Not valid!', key: 'validate'});
            });

    }

    postNewAsset = async () => {
        // const ppk_value = this.state.formRef.current?.getFieldValue('ppk_value')
        // console.log('ppk_value', ppk_value);
        // return
        console.log(this.state.formRef.current?.getFieldsValue())

        postRequest("/api/assets/create", this.state.formRef.current?.getFieldsValue(), false)
            .then(async (response) => {
                // alert(response.data)


                const assetId = response.data.id
                message.success({content: "Asset created! ID = " + assetId, key: 'submit'})

                // const ppk_value = this.state.formRef.current?.getFieldValue('ppk_value')
                const ppk_id = this.state.formRef.current?.getFieldValue('ppk_id')
                // console.log('ppk_value', ppk_value);

                //get asset image (if...)
                if (ppk_id == 1) {

                    message.success({
                        content: <>
                            <Space>{response.data?.imageUrl ? <Avatar src={response.data?.imageUrl}/> :
                                <Avatar>{assetId}</Avatar>} {response.data?.channelTitle}</Space>
                            <Row><Text type='secondary'>{response.data?.channelCategories.join(', ')}</Text></Row>
                        </>,

                        duration: 8
                    })

                }

                // return
                // }

                // message.warning(response.data["result"])


            })
            .catch((error) => {
                message.error({content: error.response.data.message, key: 'submit'})
                // console.log('Error on GET :' + error);
                // message.error('Error on GET :' + error);
            });
    };

    editAsset = async () => {
        // const ppk_value = this.state.formRef.current?.getFieldValue('ppk_value')
        // console.log('ppk_value', ppk_value);
        // return
        message.loading({content: 'Saving...', key: 'submit'});

        postRequest("/api/assets/edit/" + this.props.id, this.state.formRef.current?.getFieldsValue(), false)
            .then(async (response) => {
                // alert(response.data)
                const assetId = response.data.id
                message.success({content: "Saved! Asset ID = " + assetId, key: 'submit'})

            })
            .catch((error) => {
                message.error({content: error.response.data.message, key: 'submit'})
                // console.log('Error:' + error);
                // message.error('Error:' + error);
            });
    };

    onValidate(e: any) {
        message.loading({content: 'Please wait...', key: 'validate'});
        this.state.formRef.current
            ?.validateFields()
            .then((result) => {
                message.success({content: 'All fields are valid', key: 'validate'});
            })
            .catch((err) => {
                message.error({content: 'Some fields are invalid', key: 'validate'});
            });
    }

    onReset() {
        this.state.formRef.current?.resetFields();
    }

    render() {
        const bread = this.props.edit ? '/crm/assets/edit/' + this.props.id : '/crm/assets/new'
        const breadTitle = this.props.edit ? 'Edit Asset ' + this.props.id : 'New Asset'
        const pageTitle = this.props.edit ? 'Edit Asset ' + this.props.id : 'Create New Asset'
        const submitBtnText = this.props.edit ? 'Save' : 'Create'

        return (
            <>
                <PageContainer>
                    <div className="NewAsset" style={this.style}>
                        {/* <Row justify="center">
                     <Title level={2}>Create New Asset</Title>
                  </Row> */}
                        {/*<Card bordered={false}>*/}
                        {/*    <><Row justify='center'><Space direction='horizontal' align='baseline'> <Title*/}
                        {/*        level={3}>{pageTitle}</Title> <FormOutlined style={{fontSize: 20}}/></Space></Row></>*/}
                        {/*</Card>*/}

                        {/* <br /> */}
                        <Card>
                            <NewAssetForm
                                formRef={this.state.formRef}
                                edit={this.props.edit}
                                id={this.props.id}
                                onFormValuesChange={this.onFormValuesChange}
                            />
                        </Card>

                        <br/>
                        <br/>

                        {/* Actions */}
                        <Row justify="space-between" style={{padding: '0 64px 0 64px'}}>

                            {!this.props.edit &&
                                <Button onClick={this.onReset} size="large" danger>
                                    Reset
                                </Button>
                            }

                            <Button onClick={this.onValidate} size="large">
                                Validate
                            </Button>

                            <Button type="primary" htmlType="submit" onClick={this.onSubmit} size="large"
                                    disabled={this.props.edit ? !this.state.changeOccured : false}>
                                {submitBtnText}
                            </Button>
                        </Row>

                    </div>
                </PageContainer>
            </>
        );
    }
}

export default NewAsset;
