const numberFormatter = new Intl.NumberFormat('el-GR', {
    // style: 'decimal',
    maximumFractionDigits: 2,
    notation: 'compact',

});

const followersCountFormatter = (
    followers_count: number,
) => {
    // if (followers_count < 1000) {
    //     // return numberFormatter.format(followers_count);
    //     return followers_count;
    // } else if (followers_count < 1000000) {
    //     return numberFormatter.format(parseFloat((followers_count / 1000).toFixed(1))) + 'K';
    // } else {
    //     return numberFormatter.format(parseFloat((followers_count / 1000000).toFixed(1))) + 'M';
    // }

    // return numberFormatter.format(followers_count);
    // console.log(numberFormatter.format(followers_count), numberFormatter.format(followers_count).replace(' χιλ.', 'K').replace(' εκ.', 'M'));
    return numberFormatter.format(followers_count).replace(' χιλ.', 'K').replace(' εκ.', 'M');
}

export default followersCountFormatter