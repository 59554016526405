import {message, Spin, Table} from 'antd';
import React, {Component} from 'react';
import './index.css';
import {getRequest} from "../../api/getRequest";
import {postRequest} from "../../api/postRequest";

export interface IDynamicTableProps {
    //Here we pass the Props Interface
    call_api_get?: string,
    call_api_post?: string,
    call_api_post_params?: any,
}

export interface IDynamicTableState {
    //here we pass the State Interface
    data: any,
    result: any[],
    columns: any[],
}

//class ComponentName Component<PropsInterface, StateInterface>
class DynamicTable extends Component<IDynamicTableProps, IDynamicTableState> {

    constructor(props: IDynamicTableProps) {
        super(props)

        this.state = {
            data: [],
            result: [],
            columns: [],
        }
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    getFunc = async () => {
        getRequest(this.props.call_api_get!)
            .then((response) => {
                console.log('Authenticated');
                console.log(response);
                console.log(response.status);

                if (response.status != 200) {
                    console.log('Error on calling backend.');
                    message.warning("Error on calling backend.")
                    this.setState({data: [], columns: []});
                    return
                }


                var col: any
                response.data.forEach((item: any) => {

                    col = Object.keys(item)
                });
                var columns: any[] = []

                col.forEach((element: any) => {
                    columns.push({
                        title: element,
                        dataIndex: element,
                        key: element,
                    },)
                });


                this.setState({data: response.data, columns: columns});

            })
            .catch((error) => {
                console.log('Error on GET :' + String(error));
                message.warning(String(error))
                this.setState({data: [], columns: []});
            });
    };

    postFunc = async () => {
        postRequest(this.props.call_api_post!, this.props.call_api_post_params)
            .then((response) => {
                console.log('Authenticated');
                console.log(response);
                console.log(response.status);

                if (response.status != 200) {
                    console.log('Error on calling backend.');
                    message.warning("Error on calling backend.")
                    this.setState({data: [], columns: []});
                    return
                } else {


                    var col: any
                    response.data.forEach((item: any) => {

                        col = Object.keys(item)
                    });
                    var columns: any[] = []

                    col.forEach((element: any) => {
                        columns.push({
                            title: element,
                            dataIndex: element,
                            key: element,

                        },)
                    });

                    this.setState({data: response.data, columns: columns});

                }
            })
            .catch((error) => {
                console.log('Error on GET :' + String(error));
                message.warning(String(error))
                this.setState({data: [], columns: []});
            });
    };

    // After the component did mount, we set the state.
    componentDidMount() {
        if (this.props.call_api_get) {
            this.getFunc();
            return
        }

        if (this.props.call_api_post && this.props.call_api_post_params) {
            this.postFunc();
            return
        }

        message.warning("Error with api call.")
    }

    render() {
        return (
            <div className="DynamicTable" style={{width: "100%"}}>
                {this.state.data ?
                    <Table style={{width: "100%"}} dataSource={this.state.data} columns={this.state.columns}/> :
                    <Spin size="large"/>
                }
            </div>
        );
    }
}

export default DynamicTable;