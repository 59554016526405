import React, { Component } from 'react';
import NewAsset from '../NewAsset';
import './index.css';
import withRouter from "../../../components/withRouter";

interface IMatchParams {
	id: string;
}

export interface IEditAssetProps {
	//Here we pass the Props Interface
	params: any
}

export interface IEditAssetState {
	//here we pass the State Interface
}

//class ComponentName Component<PropsInterface, StateInterface>
class EditAsset extends Component<IEditAssetProps, IEditAssetState> {

	constructor(props: IEditAssetProps) {
		super(props)

		this.state = {

		}
	}


	//Add style here
	style = {
	};

	// Before the component mounts, we initialise our state
	componentWillMount() {
	}

	// After the component did mount, we set the state.
	componentDidMount() {
	}

	render() {
		return (
			<>
				<NewAsset edit id={+this.props.params.id} />
			</>
		);
	}
}

export default withRouter(EditAsset);