import {Button, Result} from 'antd';
import React, {Component} from 'react';
import './index.css';
import {postRequest} from "../../../api/postRequest";

export interface ICloseCycleProps {
    //Here we pass the Props Interface
    handler?: Function,
    year: string,
    id: string,
    type_cycle: string,
}

export interface ICloseCycleState {
    //here we pass the State Interface
    year: string,
    id: string,
    type_cycle: string,
    previous: boolean,
    proceed: boolean
}

//class ComponentName Component<PropsInterface, StateInterface>
class CloseCycle extends Component<ICloseCycleProps, ICloseCycleState> {

    constructor(props: ICloseCycleProps) {
        super(props)

        this.state = {
            id: this.props.id,
            year: this.props.year,
            type_cycle: this.props.type_cycle,
            previous: false,
            proceed: false
        }
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }

    render() {
        return (
            <div className="CloseCycle" style={this.style}>
                <Result
                    status="success"
                    title="Successfully finalized customer payments!"
                    subTitle="You can check out the Payments History page to review them."
                    extra={[
                        <Button type="primary" key="close_cycle" onClick={() => this.proceedStep()}
                                disabled={this.state.proceed}>
                            Close Cycle
                        </Button>,
                        // <Button key="buy">Buy Again</Button>,
                    ]}
                />

                {/* <Row justify="end" align="middle">
					<Button type={"ghost"} onClick={() => this.previousStep()} disabled={this.state.previous}>Previous Step</Button>
					<Button type={"primary"} onClick={() => this.proceedStep()} disabled={this.state.proceed}>Close Cycle</Button>
				</Row> */}
            </div>
        );
    }



    proceedStep(): void {

        postRequest(`/api/erp/newcycle/proceedcycle`, {
            id: this.state.id,
            year: this.state.year,
            step: "6"
        })
            .then((response) => {
                console.log('Authenticated');
                console.log(response)

                window.location.reload()
                // this.props.handler ? this.props.handler() : window.location.reload()
            }).catch(function (error) {
            console.log('Error on GET :' + error);
        });

    }
}

export default CloseCycle;