import { useEffect, useRef, useState } from 'react';
import {
    Alert,
    Button,
    Col,
    ConfigProvider,
    Form,
    InputNumber,
    notification,
    Row,
    Select,
    Spin,
    Typography
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import enUSIntl from 'antd/lib/locale/en_US';
import { postRequest } from '../../../api/postRequest';
import moment from 'moment';
import { getRequest } from '../../../api/getRequest';
import VideoCard from './VideoCard/VideoCard';
import Graph from './Graph/Graph';
import ChannelPlaceholder from './ChannelPlaceholder/ChannelPlaceholder';
import { PageContainer } from '@ant-design/pro-components';

type OptionData = Array<{
    label: string;
    optionType: string;
    value: string;
    options: Array<{
        label: string;
        value: string;
    }>;
}>;

type ChannelData = {
    title: string;
    image_url: string;
    channel_id: string;
};

let cachedVideoOptions = [];
let graphData = undefined;
let channelToOwnerMap = {};

const gutterSpacing = {
    xs: 16,
    sm: 18,
    md: 24,
    lg: 28,
    xl: 32
};

const defaultTimespan = { step: 40, granularity: 'd' };

const VideoComparison = (): JSX.Element => {
    // run on mount
    useEffect(() => {
        bootstrap();
    }, []);

    const [channelData, setChannelData] = useState<ChannelData>(undefined);
    const [channelOptions, setChannelOptions] = useState<OptionData>(undefined);
    const [videoOptions, setVideoOptions] = useState([]);
    const [filteredChannelOptions, setFilteredChannelOptions] = useState<OptionData>(undefined);
    const [timespan, setTimespan] = useState(defaultTimespan);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFetchingVideos, setIsFetchingVideos] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [selectedVideos, setSelectedVideos] = useState([]);

    const formRef = useRef(null);

    // fetch videos for selected channel every time a channel is selected
    useEffect(() => {
        fetchChannelVideos(channelData ? channelData.channel_id : 'UCcbNHNmULeU1OoNylpPIRQQ');
    }, [channelData]);

    // ? hacky workaround solution to deal with clearing the video selector form (maybe this can be improved?)
    useEffect(() => {
        try {
            if (selectedVideos.length === 0) {
                formRef.current.setFields([{ name: ['video_selector'], value: [] }]);
            }
        } catch (e) {
            console.log('formRef is null');
        }
    }, [selectedVideos]);

    /**
     * Bootstrap function of component, load necessary data before the submit form is displayed
     */
    async function bootstrap() {
        await fetchChannelToOwnermap();
        fetchChannelOptions();
        console.log('channel to owner map: ', channelToOwnerMap);
    }

    /**
     * Remove the video that has the same video id value as the target param
     * @param target video id of target
     */
    function removeSelectedVideo(target: string) {
        const filterFunc = (videos: any) => videos.filter((card: any) => card.value !== target);
        const newFormValues = filterFunc(selectedVideos).map((opt: any) => opt.value);

        setSelectedVideos(filterFunc); // ? parameter of setSelectedVideos NEEDS to be a function to work properly
        formRef.current.setFields([{ name: ['video_selector'], value: newFormValues }]);
    }

    /**
     * fetch channel to owner map from backend api
     */
    async function fetchChannelToOwnermap() {
        try {
            const res = await getRequest('/api/youtubedata/get_channel_to_cms_map');
            channelToOwnerMap = res.data.body;
            console.log('channel to owner map:', channelToOwnerMap);
        } catch (e: any) {
            console.error(e);
        }
    }

    /**
     * Fetch the list of available channels that the user can select, filter the ones that don't belond to a CMS
     */
    async function fetchChannelOptions() {
        const filterChannelsWithNoCMS = (channel) => channelToOwnerMap[channel.value];
        const applyNoCMSFilter = (owner) => ({
            ...owner,
            options: owner.options.filter(filterChannelsWithNoCMS)
        });
        const filterEmptyOwners = (owner) => owner.options.length > 0;
        try {
            const res = await postRequest('/api/youtubereports/supported_channels_data');
            const filteredChannels = res.data.map(applyNoCMSFilter).filter(filterEmptyOwners);
            // console.log('filtered channels: ', filteredChannels);
            setChannelOptions(filteredChannels);
            setFilteredChannelOptions(filteredChannels);
            setIsLoaded(true);
        } catch (e: any) {
            console.error(e);
        }
    }

    /**
     * fetch data for selected channel (name, url, profile photo etc...)
     * @param channel_id channel id to fetch data from
     */
    async function fetchChannelData(channel_id: string) {
        try {
            const res = await postRequest('/api/youtubereports/validate_search_channel', { channel_id });
            setChannelData(res.data);
        } catch (e: any) {
            console.error(e);
        }
    }

    /**
     * fetch the latest 20 videos of the selected channel, or fetch the video that corresponds to the video_id param
     * @param channel_id id of selected channel
     * @param video_id optional targeted video id
     */
    async function fetchChannelVideos(channel_id: string, video_id?: string) {
        try {
            setIsFetchingVideos(true);
            const res = await postRequest(
                '/api/youtubedata/fetch_uploads',
                {
                    channel_id: channel_id,
                    video_id: video_id
                },
                false
            );
            const videoData = res.data.body.items;
            // console.log('videoData: ', videoData);
            const newVideoOptions = videoData.map((entry) => ({
                label: entry.snippet.title,
                value: entry.snippet.resourceId.videoId,
                channel: entry.snippet.channelId,
                thumbnail: entry.snippet.thumbnails.default.url,
                date: entry.snippet.publishedAt,
                content_owner: channelToOwnerMap[entry.snippet.channelId]
            }));

            setVideoOptions(newVideoOptions);
            if (!video_id) cachedVideoOptions = [...newVideoOptions]; // cache the 20 most recent videos
            // console.log(videoOptions);
            setIsFetchingVideos(false);
        } catch (e: any) {
            notification.info({ message: 'Could not find video in selected channel' });
            console.error(e);
        }
    }

    /**
     * Search for a video uploaded by the selected channel through it's video url.
     * @param video_url video url belonging to selected channel
     * @returns void
     */
    function onSearchHandler(video_url: any) {
        const regx = /(youtube\.com\/watch\?v=|youtu\.be\/)([\S]{11})/; // get youtube long or short url, with video id subgroup

        // if video_url is not a valid youtube url
        if (!regx.test(video_url)) {
            console.log('match not found');
            return;
        }

        const video_id = video_url.match(regx).pop(); // last matched group = video id
        fetchChannelVideos(channelData.channel_id, video_id);
    }

    /**
     * Query data for the list of selected videos
     */
    async function onQueryHandler() {
        // convert selected videos to a list of valid request objects
        const request = {
            videos: selectedVideos.map((video) => ({
                start_date: moment(video.date).format('YYYY-MM-DD'),
                end_date: moment(video.date)
                    .add(timespan.step, timespan.granularity as any)
                    .format('YYYY-MM-DD'),
                content_owner: video.content_owner,
                video_title: video.label,
                channel_id: video.channel,
                video_id: video.value
            }))
        };

        try {
            setIsSearching(true);
            const req = await postRequest('/api/youtubereports/fetch_video_data', request, false);
            console.log('response data: ', req.data.body);
            // setGraphData(req.data.body);
            graphData = req.data.body;
            setIsSearching(false);
            if (!req.data.body) notification.error({ message: 'Video data was not found' });
        } catch (e: any) {
            console.error(e);
        }
    }

    return (
        <PageContainer>
            <ConfigProvider locale={enUSIntl}>
                <Typography.Title level={2}>Video Comparison</Typography.Title>
                <Alert
                    message={
                        <Typography.Text>
                            Welcome to the Video Comparator. Select a Channel and pick Videos to compare
                            across a given timespan
                        </Typography.Text>
                    }
                    type='info'
                    closable
                />
                {isLoaded ? (
                    <>
                        <div className='channel_select_form'>
                            <Form layout='horizontal' size='large' ref={formRef}>
                                <Row
                                    align='stretch'
                                    gutter={gutterSpacing}
                                    justify='space-evenly'
                                    style={{ width: '80%', minWidth: 900 }}>
                                    <Col style={{ flex: 4 }}>
                                        <Row style={{ marginTop: 16, marginBottom: 16 }}>
                                            <Typography.Text>Channel</Typography.Text>
                                        </Row>
                                        <Form.Item name='channel_selector'>
                                            <Select
                                                className='channel_selector'
                                                options={filteredChannelOptions}
                                                showSearch
                                                allowClear
                                                placeholder='Select Channel'
                                                onClear={() => {
                                                    setFilteredChannelOptions(channelOptions);
                                                    setChannelData(undefined);
                                                }}
                                                onSelect={(value: string, option: any) => {
                                                    console.log(channelToOwnerMap[value]);
                                                    fetchChannelData(value);
                                                    fetchChannelVideos(value);
                                                }}
                                                filterOption={true}
                                                optionFilterProp='label'
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ flex: 5 }}>
                                        <Row style={{ marginTop: 16, marginBottom: 16 }}>
                                            <Typography.Text>Videos</Typography.Text>
                                        </Row>
                                        <Row style={{ flex: 1 }}>
                                            <Form.Item
                                                name='video_selector'
                                                initialValue={[]}
                                                style={{ flex: 1 }}>
                                                <Select
                                                    options={videoOptions}
                                                    showSearch
                                                    allowClear
                                                    loading={isFetchingVideos}
                                                    disabled={channelData === undefined}
                                                    placeholder='Select Video or Paste URL'
                                                    mode='multiple'
                                                    maxTagTextLength={50}
                                                    maxTagCount={0}
                                                    filterOption={false}
                                                    onSelect={(value: string, option: any) => {
                                                        setSelectedVideos((list) => [...list, option]);
                                                    }}
                                                    onDeselect={(value: string, option: any) => {
                                                        removeSelectedVideo(value);
                                                    }}
                                                    onClear={() => {
                                                        setSelectedVideos([]);
                                                        fetchChannelVideos(channelData.channel_id);
                                                    }}
                                                    onDropdownVisibleChange={() =>
                                                        setVideoOptions(cachedVideoOptions)
                                                    }
                                                    onSearch={onSearchHandler}
                                                />
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col style={{ flex: 2 }}>
                                        <Row style={{ marginTop: 16, marginBottom: 16 }}>
                                            <Typography.Text>Timespan</Typography.Text>
                                        </Row>
                                        <Form.Item name='timespan_selector'>
                                            <InputNumber
                                                required
                                                controls={false}
                                                defaultValue={defaultTimespan.step}
                                                onChange={(val) => {
                                                    setTimespan((prev) => ({ ...prev, step: val }));
                                                }}
                                                addonAfter={
                                                    <Select
                                                        defaultValue='Days'
                                                        options={[
                                                            { label: 'Days', value: 'd' },
                                                            { label: 'Weeks', value: 'w' }
                                                        ]}
                                                        onChange={(val) => {
                                                            setTimespan((prev) => ({
                                                                ...prev,
                                                                granularity: val
                                                            }));
                                                        }}
                                                        style={{ width: 100 }}
                                                    />
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ flex: 1 }}>
                                        <Row style={{ marginTop: 16, marginBottom: 16 }}>
                                            <Typography.Text>
                                                <br />
                                            </Typography.Text>
                                        </Row>
                                        <Button
                                            type='primary'
                                            icon={<SearchOutlined />}
                                            onClick={onQueryHandler}
                                            loading={isSearching}
                                            disabled={selectedVideos.length === 0}>
                                            Query
                                        </Button>
                                    </Col>
                                </Row>
                                <Row align='middle' style={{ marginBottom: 4, width: '80%' }}>
                                    {channelData ? (
                                        <Row align='middle'>
                                            <img
                                                width={48}
                                                style={{ borderRadius: 24 }}
                                                src={channelData.image_url}
                                            />
                                            <div style={{ marginLeft: 12 }}>
                                                <a
                                                    href={
                                                        'https://www.youtube.com/channel/' +
                                                        channelData.channel_id
                                                    }
                                                    target={'_blank'}>
                                                    <Typography.Text type='secondary'>
                                                        {channelData.title}
                                                    </Typography.Text>
                                                </a>
                                            </div>
                                        </Row>
                                    ) : (
                                        <ChannelPlaceholder />
                                    )}
                                </Row>
                                <div
                                    style={{
                                        width: 1200,
                                        height: 200,
                                        border:
                                            selectedVideos.length > 0
                                                ? '1px solid #40a9ff'
                                                : '1px solid #aaa',
                                        background: '#eee',
                                        borderRadius: '4px',
                                        scrollBehavior: 'smooth',
                                        scrollbarColor: '#aaa #fff',
                                        scrollbarWidth: 'thin',
                                        marginTop: '16px',
                                        overflowX: 'auto',
                                        display: 'flex',
                                        //alignItems: 'center',
                                        boxShadow: selectedVideos.length > 0 ? '0px 0px 4px #1890ff' : ''
                                    }}>
                                    {selectedVideos.length > 0 ? (
                                        selectedVideos.map(({ value, label, thumbnail }) => (
                                            <VideoCard
                                                key={value}
                                                value={value}
                                                label={label}
                                                thumbnail={thumbnail}
                                                onClick={removeSelectedVideo}
                                            />
                                        ))
                                    ) : (
                                        <Typography.Text
                                            italic
                                            style={{
                                                color: '#BBB',
                                                display: 'flex',
                                                flex: 1,
                                                alignSelf: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            List of Selected Videos
                                        </Typography.Text>
                                    )}
                                </div>
                            </Form>
                        </div>
                        {graphData && !isSearching && <Graph data={graphData} />}
                    </>
                ) : (
                    <Row justify='center' style={{ marginTop: '4em' }}>
                        <Spin style={{ alignSelf: 'center' }} />
                    </Row>
                )}
            </ConfigProvider>
        </PageContainer>
    );
};

export default VideoComparison;
