import {Button, ConfigProvider, notification, Row, Space, Tooltip} from 'antd';
import React, {Component} from 'react';
import './index.css';
import enUSIntl from "antd/lib/locale/en_US";
import ProTable, {ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import {CloudDownloadOutlined, DownloadOutlined, FileTextOutlined, TeamOutlined} from "@ant-design/icons";
import {humanFileSize} from "../../../common/utils";
import {auth} from "../../../config/firebase";
import axios from "axios";
import {API} from "../../../common/constants";
import withRouter from "../../../components/withRouter";


export interface IPaymentCycleCustomerReportsProps {
    //Here we pass the Props Interface
    params: any
    pageSize?: number
    polling?: false | number
}

export interface IPaymentCycleCustomerReportsState {
    data?: any
    filteredData?: any
    loading: boolean
}


type PaymentCycleCustomerReport = {
    file_name: string;
    product: string;
    path: string;
    customer_id: number;
    size: number;
};


//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleCustomerReports extends Component<IPaymentCycleCustomerReportsProps, IPaymentCycleCustomerReportsState> {

    constructor(props: IPaymentCycleCustomerReportsProps) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {

    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        this.setState({loading: true})
        try {
            const res = await postRequest('/api/paymentCycles/getCustomerReports/' + this.props.params.id)
            // console.log(res.data)
            this.setState({data: res.data, loading: false})
        } catch (e) {
            // return
            this.setState({loading: false})
        }
    }

    columns: ProColumns<PaymentCycleCustomerReport>[] = [
        {
            title: 'Filename', dataIndex: 'file_name', valueType: 'text',
        },
        {
            title: 'Customer Id', dataIndex: 'customer_id', valueType: 'text',
        },
        {
            title: 'Product', dataIndex: 'product',
            valueType: 'select',
            valueEnum: {
                'Youtube Entertainment': {text: 'Youtube Entertainment'},
                'Youtube Music': {text: 'Youtube Music'},
                'Spotify': {text: 'Spotify'},
                'MD': {text: 'Music Distribution'}
            },
        },
        {
            title: 'Size',
            dataIndex: 'size',
            render: (num) => humanFileSize(num as number),
            sorter: (a, b) => a.size - b.size, defaultSortOrder: "descend",
            hideInSearch: true
        },
        {
            title: 'Action',
            align: "center",
            hideInSearch: true,
            render: (dom, entity) =>
                (
                    <Tooltip title={"Download customer report"}>
                        <Button icon={<DownloadOutlined/>} type={"text"} shape={"circle"} onClick={
                            () => this.downloadSingleReport(entity)
                        }/>
                    </Tooltip>
                ),
        },
    ];

    downloadSingleReport = async (entity: PaymentCycleCustomerReport) => {
        // console.log(filepath)
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(API + '/api/paymentCycles/downloadCustomerReport/' + this.props.params.id, null,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob',
                    params: {filepath: entity.path}
                })
            .then(res => {
                // console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', entity.file_name + '.xlsx');
                document.body.appendChild(link);
                link.click();

                // notification.success({message: 'Success'})
            }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    onSearch = (params: PaymentCycleCustomerReport) => {
        let filteredData: PaymentCycleCustomerReport[] = JSON.parse(JSON.stringify(this.state.data))
        Object.keys(params).map((key) => {
            if (params[key] == '') return true
            filteredData = filteredData.filter((datum) => {
                // console.log(key, params[key], datum[key].toString().toLowerCase().includes(params[key].toLowerCase()))
                // return datum[key] == params[key] // strict comparison
                return datum[key].toString().toLowerCase().includes(params[key].toLowerCase()) // loose comparison
            })
        })
        this.setState({filteredData})
    }
    onReset = () => {
        this.onSearch({} as any)
    }

    generateYoutubeEntertainmentReports = () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        postRequest('/api/paymentCycles/generateYoutubeEntertainmentReports/' + this.props.params.id).then(value => {
            // console.log(value)
            notification.success({message: 'Done'})
        }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    generateYoutubeMusicReports = () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        postRequest('/api/paymentCycles/generateYoutubeMusicReports/' + this.props.params.id).then(value => {
            // console.log(value)
            notification.success({message: 'Done'})
        }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    generateSpotifyReports = () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        postRequest('/api/paymentCycles/generateSpotifyReports/' + this.props.params.id).then(value => {
            // console.log(value)
            notification.success({message: 'Done'})
        }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    generateMusicDistributionReports = () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        postRequest('/api/paymentCycles/generateMusicDistributionReports/' + this.props.params.id).then(value => {
            // console.log(value)
            notification.success({message: 'Done'})
        }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    downloadCustomerReportsAll = async () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(API + '/api/paymentCycles/downloadCustomerReportsAll/' + this.props.params.id, null,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
            .then(res => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'customer_reports_' + this.props.params.id + '.zip');
                document.body.appendChild(link);
                link.click();

                notification.success({message: 'Success'})
            }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    downloadCustomerReportsAllByCustomerId = async () => {
        notification.info({message: 'Operation started. Please check the logs for status updates.'})
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(API + '/api/paymentCycles/downloadCustomerReportsAllByCustomerId/' + this.props.params.id, null,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
            .then(res => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'customer_reports_by_customer_id_' + this.props.params.id + '.zip');
                document.body.appendChild(link);
                link.click();

                notification.success({message: 'Success'})
            }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    render() {


        return (
            <>

                {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}

                <Row justify={"space-between"}>

                    <Space>

                        <Button onClick={this.generateYoutubeEntertainmentReports}>Generate Youtube Entertainment
                            Reports</Button>
                        <Button onClick={this.generateYoutubeMusicReports}>Generate Youtube Music Reports</Button>
                        <Button onClick={this.generateSpotifyReports}>Generate Spotify Reports</Button>
                        <Button onClick={this.generateMusicDistributionReports}>Generate Music Distribution
                            Reports</Button>

                    </Space>

                    <Space>
                        <Button icon={<CloudDownloadOutlined/>} onClick={this.downloadCustomerReportsAll}>Download All
                            Reports</Button>
                        <Button icon={<TeamOutlined/>} onClick={this.downloadCustomerReportsAllByCustomerId}>Download
                            All
                            Reports By Customer</Button>
                    </Space>


                </Row>

                <br/>

                <ConfigProvider locale={enUSIntl}>

                    {/*<Card bodyStyle={{padding: 0}}>*/}

                    <ProTable<PaymentCycleCustomerReport>
                        columns={this.columns}
                        // actionRef={this.state.actionRef as any}
                        // cardBordered
                        bordered
                        loading={this.state.loading}
                        // size={"small"}
                        // style={{fontSize:6}}
                        // showHeader={false}
                        // headerTitle={"Logs"}
                        polling={this.props.polling || 0}
                        // request={async (params = {}, sort, filter) => {
                        //     // console.log('REQUEST', params, filter)
                        //     const res = await postRequest('/api/paymentCycles/getCustomerReports/' + this.props.cycle_id)
                        //     const data = res.data || []
                        //     this.setState({data: res.data, filteredData: null})
                        //     return {data, success: true, total: data.length}
                        // }}
                        rowKey={(record) => record.file_name}
                        locale={{emptyText: <Space direction={'vertical'}> <FileTextOutlined/> No files</Space>}}
                        pagination={{pageSize: this.props.pageSize || 10, hideOnSinglePage: true}}
                        dateFormatter="string"
                        // search={false}
                        onSubmit={this.onSearch}
                        onReset={this.onReset}
                        search={{layout: "vertical"}}
                        dataSource={this.state.filteredData ? this.state.filteredData : this.state.data}
                        toolBarRender={false}
                        // toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                        // toolBarRender={() => []}
                    />
                    {/*</Card>*/}


                </ConfigProvider>

            </>
        );
    }
}

export default withRouter(PaymentCycleCustomerReports);