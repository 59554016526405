import { useState, useEffect } from 'react';
import { Layout, Switch, Typography, Space, Radio, Divider } from 'antd';
import { Line, LineConfig } from '@ant-design/plots';

const { Header, Content, Footer } = Layout;
const defaultMetric = 'views';

const Graph = ({ data }) => {
  const [graphData, setGraphData] = useState(data);
  const [isCumulative, setIsCumulative] = useState(true);
  const [selectedMetric, setSelectedMetric] = useState(defaultMetric);

  const config: LineConfig = {
    data: graphData,
    xField: 'day_index',
    yField: 'active_metric',
    legend: { position: 'right' },
    seriesField: 'video_title',
    smooth: isCumulative,
    autoFit: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 3000,
      },
    },
  };

  useEffect(() => {
    data = data.map((datum) => ({ ...datum, active_metric: datum.metrics[selectedMetric][isCumulative ? 'value_accumulated' : 'value'] }));
    setGraphData(data);
  }, [isCumulative, selectedMetric]);

  return (
    <Layout>
      <Divider />
      <Header style={{ backgroundColor: '#FFF', display: 'flex' }}>
        <Space align='center' style={{ flex: 1, justifyContent: 'space-between' }}>
          <Space>
            <Typography.Text type='secondary'>Cumulative</Typography.Text>
            <Switch
              defaultChecked
              onChange={(checked) => {
                setIsCumulative(checked);
              }}
            />
          </Space>
          <Radio.Group buttonStyle='solid' defaultValue={defaultMetric} onChange={(e) => setSelectedMetric(e.target.value)}>
            <Radio.Button value='views'>Views</Radio.Button>
            <Radio.Button value='revenue'>Revenue</Radio.Button>
            <Radio.Button value='watch_time'>Watch Time</Radio.Button>
          </Radio.Group>
        </Space>
      </Header>
      <Content>
        <Line {...config} />
      </Content>
    </Layout>
  );
};

export default Graph;
