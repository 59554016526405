import { useQuery } from '@tanstack/react-query';
import axiosApiInstance from '../../../api/axiosClient';

export const useContactsManualQuery = (params?: any) =>
    useQuery<API.CRM.Contact[]>({
        queryKey: ['connections', 'contacts', params],
        queryFn: async ({ queryKey }) =>
            await axiosApiInstance
                .get('/api/connections/contacts/', {
                    params: queryKey[2]
                })
                .then(({ data }) => data)
                .catch((reason) => console.error(reason)),
        enabled: false
    });

export const useCategoriesManualQuery = () =>
    useQuery<API.CRM.ContactCategory[]>({
        queryKey: ['connections', 'categories'],
        queryFn: async () =>
            await axiosApiInstance
                .get<API.CRM.ContactCategory[]>('/api/connections/categories/')
                .then(({ data }) => data)
                .catch((reason) => {
                    console.error(reason);
                    return [];
                }),
        enabled: false
    });

export const useBusinessesManualQuery = () =>
    useQuery<API.CRM.Business[]>({
        queryKey: ['connections', 'businesses'],
        queryFn: async () =>
            await axiosApiInstance
                .get<API.CRM.Business[]>('/api/connections/businesses/')
                .then(({ data }) => data)
                .catch((reason) => {
                    console.error(reason);
                    return [];
                }),
        enabled: false
    });

export const useContactByIDQuery = (id: string) =>
    useQuery<API.CRM.Contact>({
        queryKey: ['connections', 'contacts', id],
        queryFn: async () =>
            await axiosApiInstance
                .get<API.CRM.Contact>(`/api/connections/contacts/${id}/`)
                .then(({ data }) => data)
                .catch((reason) => {
                    console.log(reason);
                    return null;
                }),
        cacheTime: 0,
        staleTime: 0
        // .catch((reason) => {
        //     console.error(reason);
        // })
    });
