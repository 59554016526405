import React, {Component} from 'react';
// import { Grid, GridCell, GridRow, Typography } from 'rmwc';
import AdminKeys from '../AdminKeys';
import AdminPlatforms from '../AdminPlatforms';
import AdminProducts from '../AdminProducts';
import {Col, Row, Typography} from 'antd';
import './index.css';

export interface IPPK_PagesProps {
    //Here we pass the Props Interface
}

export interface IPPK_PagesState {
    //here we pass the State Interface
}

//class ComponentName Component<PropsInterface, StateInterface>
class PPK_PAGES extends Component<IPPK_PagesProps, IPPK_PagesState> {
    //Component State
    state = {};

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }

    render() {
        const colSpan = 12;
        return (
            <>
                {/*<MainLayout*/}
                {/*   breadcrumpLinks={['/admin', '/ppks']}*/}
                {/*   breadcrumpTitles={['Admin Dashboard', 'Platforms Products Keys']}*/}
                {/*>*/}
                <Row>
                    <Col span={colSpan}>
                        <Typography.Title level={3}> Platforms</Typography.Title>
                        <AdminPlatforms/>
                    </Col>
                </Row>

                <br/>
                <Row>
                    <Col span={colSpan}>
                        <Typography.Title level={3}> Products</Typography.Title>
                        <AdminProducts/>
                    </Col>
                </Row>
                <br/>

                <Row>
                    <Col span={colSpan}>
                        <Typography.Title level={3}> Keys</Typography.Title>
                        <AdminKeys/>
                    </Col>
                </Row>
                <br/>
                {/*</MainLayout>*/}
            </>
        );
    }
}

export default PPK_PAGES;
