import axiosApiInstance from "../../../api/axiosClient";
import {atomWithQuery, atomWithSuspenseQuery} from "jotai-tanstack-query";
import {atomWithHash} from "jotai-location";
import {atom} from "jotai";
import { atomFamily } from "jotai/utils";

// /api/creators/items
// export const creatorsAtom = atomWithSuspenseQuery<API.DataResponse<API.Creators.Creator[]>>((get) => ({
export const creatorsAtom = atomWithQuery<API.DataResponse<API.Creators.Creator[]>>((get) => ({
    // const response = await axiosApiInstance.get(`/api/creators/items`);
    // // console.log(response.data)
    // return response.data;
    queryKey: ['creators', 'items', get(pageAtom), get(pageSizeAtom), get(filterSearchAtom)],
    queryFn: async () => {
        const response = await axiosApiInstance.get(`/api/creators/items`, {
            params: {
                page: get(pageAtom),
                page_size: get(pageSizeAtom),
                search: get(filterSearchAtom),
            }
        });
        return response.data;
    }

}));

export const filterSearchAtom = atomWithHash<string | undefined>('filterSearch', undefined);


// /api/creators/categories/
export const creatorCategoriesAtom = atomWithSuspenseQuery<API.Creators.CreatorContentCategory[]>((get) => ({
    // const response = await axiosApiInstance.get(`/api/creators/categories/`);
    // console.log(response.data)
    // return response.data;
    queryKey: ['creators', 'categories'],
    queryFn: async () => {
        const response = await axiosApiInstance.get(`/api/creators/categories/`);
        return response.data;
    }
}));

// pagination
export const pageAtom = atomWithHash<number>('creatorsCurrent', 1);
export const pageSizeAtom = atomWithHash<number>('creatorsPageSize', 7);

export enum CreatorGroupBy {
    Creator = 'creator',
    YoutubeAccount = 'youtube_account',
    InstagramAccount = 'instagram_account',
    TiktokAccount = 'tiktok_account',
}

export const groupByAtom = atomWithHash<CreatorGroupBy>('groupBy', CreatorGroupBy.Creator);


export const creatorsAccountsByPlatformAtom = atomWithQuery<API.DataResponse<API.Creators.CreatorWithAccount[]>>((get) => ({
    // const response = await axiosApiInstance.get(`/api/creators/items`);
    // // console.log(response.data)
    // return response.data;
    queryKey: ['creators', 'accounts', 'by_platform', get(groupByAtom), get(pageAtom), get(pageSizeAtom), get(filterSearchAtom)],
    queryFn: async () => {
        let url;
        if (get(groupByAtom) === CreatorGroupBy.YoutubeAccount) {
            url = `/api/creators/accounts/by_platform/1`;
        } else if (get(groupByAtom) === CreatorGroupBy.InstagramAccount) {
            url = `/api/creators/accounts/by_platform/2`;
        } else if (get(groupByAtom) === CreatorGroupBy.TiktokAccount) {
            url = `/api/creators/accounts/by_platform/3`;
        } else {
            return [];
        }
        const response = await axiosApiInstance.get(url, {
            params: {
                page: get(pageAtom),
                page_size: get(pageSizeAtom),
                search: get(filterSearchAtom),
            }
        });
        return response.data;
    }

}));



// family of atoms (because we need to pass id to the atom from the component)
export const creatorTagsAtomById = atomFamily((id) => {
    // console.log('creatorTagsAtomById', id);
    return atomWithSuspenseQuery((get) => ({
        queryKey: ['creators', 'edit', 'tags', id],
        queryFn: async () => {
            const response = await axiosApiInstance.get(`/api/creators/tags/${id}`);
            return response.data;
        }
    }))
})

export const creatorAtom = atomFamily((id) => {
    return atomWithSuspenseQuery((get) => ({
        queryKey: ['creators', id],
        queryFn: async () => {
            if (!id) return;
            const response = await axiosApiInstance.get(`/api/creators/items/${id}`);
            return response.data;
        }
    }))
} )