import {
    ModalForm,
    ProFormGroup,
    ProFormList,
    ProFormSelect,
    ProFormSwitch,
    ProFormText,
    ProFormTextArea
} from '@ant-design/pro-form';
import { useNavigate } from 'react-router-dom';
import axiosApiInstance from '../../../api/axiosClient';
import { useBusinessesManualQuery, useCategoriesManualQuery } from './queryHooks';
import { useAtom, useAtomValue } from 'jotai';
import { GlobalStateContext } from '../../../context/GlobalContext';
import { useContext, useRef, useState } from 'react';
import { Button, Divider, Form, Input, InputRef, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

type PrimaryIndeces = {
    phone: number | false;
    email: number | false;
    address: number | false;
};

const NewConnectionModal = () => {
    const navigate = useNavigate();
    const inputRef = useRef<InputRef>(null);
    const { refetch: fetchCategories } = useCategoriesManualQuery();
    const { refetch: fetchBusinesses } = useBusinessesManualQuery();
    const { contactsTableRef } = useContext(GlobalStateContext);
    const [form] = Form.useForm<API.CRM.Contact>();
    const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
    const [primaries, setPrimaries] = useState<PrimaryIndeces>({
        phone: 0,
        email: 0,
        address: 0
    });

    const addBusiness = async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        setIsInputDisabled(true);
        const newbusiness: Partial<API.CRM.Business> = {
            id: crypto.randomUUID(),
            business_name: inputRef.current.input.value
        };
        await axiosApiInstance
            .post('/api/connections/businesses/', newbusiness)
            .catch((reason) => console.error(reason));
        form.resetFields(['business_id']);
        setIsInputDisabled(false);
    };

    const addCategory = async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        setIsInputDisabled(true);
        const newcategory: Partial<API.CRM.ContactCategory> = {
            id: crypto.randomUUID(),
            name: inputRef.current.input.value
        };
        await axiosApiInstance
            .post('/api/connections/categories/', newcategory)
            .catch((reason) => console.error(reason));
        form.resetFields(['contact_category_id']);
        form.setFieldValue('contact_category_id', newcategory.id);
        setIsInputDisabled(false);
    };

    return (
        <ModalForm<API.CRM.Contact>
            title='Add Contact'
            autoFocusFirstInput
            open
            width={1000}
            form={form}
            modalProps={{
                onCancel: () => navigate('/social/connections')
            }}
            onFinish={async (values: Partial<API.CRM.Contact>) => {
                if (values.phone_numbers) {
                    values.phone_numbers = values.phone_numbers.map((phone, index) => ({
                        ...phone,
                        is_primary: index === primaries.phone
                    }));
                }

                if (values.emails) {
                    values.emails = values.emails.map((email, index) => ({
                        ...email,
                        is_primary: index === primaries.email
                    }));
                }

                if (values.addresses) {
                    values.addresses = values.addresses.map((address, index) => ({
                        ...address,
                        is_primary: index === primaries.address
                    }));
                }
                await axiosApiInstance.post('/api/connections/contacts/', values);
                contactsTableRef.current.reload();
                navigate('/social/connections');
            }}
        >
            <ProFormText name='id' initialValue={crypto.randomUUID()} hidden />
            <ProFormGroup title='Personal Details'>
                <ProFormText width='xl' name='first_name' placeholder='First Name' />
                <ProFormText width='xl' name='last_name' placeholder='Last Name' />
            </ProFormGroup>
            <ProFormGroup>
                <ProFormSelect
                    width='md'
                    name='contact_category_id'
                    placeholder='Select Category'
                    fieldProps={{
                        dropdownRender: (menu) => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                    <Input
                                        placeholder='Enter New Category'
                                        ref={inputRef}
                                        disabled={isInputDisabled}
                                    />
                                    <Button
                                        type='text'
                                        icon={<PlusOutlined />}
                                        onClick={addCategory}
                                        disabled={isInputDisabled}
                                        // disabled={isAddButtonDisabled}
                                    >
                                        Add
                                    </Button>
                                </Space>
                            </>
                        )
                    }}
                    request={() =>
                        fetchCategories().then(({ data }) =>
                            data.map((datum) => ({
                                label: datum.name,
                                value: datum.id
                            }))
                        )
                    }
                />
                <ProFormSelect
                    width='md'
                    name='business_id'
                    placeholder='Select Business'
                    fieldProps={{
                        showSearch: true,
                        dropdownRender: (menu) => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                    <Input
                                        placeholder='Enter New Business Name'
                                        ref={inputRef}
                                        disabled={isInputDisabled}
                                        onChange={(e) => {
                                            setIsAddButtonDisabled(
                                                e.currentTarget.value === '' || isInputDisabled
                                            );
                                        }}
                                    />
                                    <Button
                                        type='text'
                                        icon={<PlusOutlined />}
                                        onClick={addBusiness}
                                        // disabled={isInputDisabled}
                                        disabled={isAddButtonDisabled}
                                    >
                                        Add
                                    </Button>
                                </Space>
                            </>
                        )
                    }}
                    request={() =>
                        fetchBusinesses().then(({ data }) =>
                            data.map((datum) => ({
                                label: datum.business_name,
                                value: datum.id
                            }))
                        )
                    }
                />
            </ProFormGroup>

            <ProFormGroup title='Phone Numbers' />
            <ProFormList
                name='phone_numbers'
                creatorButtonProps={{
                    creatorButtonText: 'New Phone Number',
                    size: 'small'
                }}
            >
                {(f, index, action) => {
                    return (
                        <ProFormGroup>
                            <ProFormText name='id' initialValue={crypto.randomUUID()} hidden />
                            <ProFormText width='sm' name='number' placeholder='Phone Number' />
                            <ProFormText
                                width='md'
                                name='contact_name'
                                placeholder='Contact Name (Optional)'
                            />
                            <ProFormSwitch
                                name='is_primary'
                                initialValue={false}
                                fieldProps={{
                                    checked: primaries.phone !== false && index === primaries.phone,
                                    onChange: (checked) =>
                                        checked
                                            ? setPrimaries((prev) => ({ ...prev, phone: index }))
                                            : setPrimaries((prev) => ({ ...prev, phone: false }))
                                }}
                                tooltip='Make Primary?'
                            />
                        </ProFormGroup>
                    );
                }}
            </ProFormList>

            <ProFormGroup title='E-mails' />
            <ProFormList
                name='emails'
                creatorButtonProps={{ creatorButtonText: 'New E-mail', size: 'small' }}
            >
                {(f, index, action) => {
                    return (
                        <ProFormGroup>
                            <ProFormText name='id' initialValue={crypto.randomUUID()} hidden />
                            <ProFormText width='xl' name='email_address' placeholder='E-mail' />
                            <ProFormSwitch
                                name='is_primary'
                                initialValue={false}
                                fieldProps={{
                                    checked: primaries.email !== false && index === primaries.email,
                                    onChange: (checked) =>
                                        checked
                                            ? setPrimaries((prev) => ({ ...prev, email: index }))
                                            : setPrimaries((prev) => ({ ...prev, email: false }))
                                }}
                                tooltip='Make Primary?'
                            />
                        </ProFormGroup>
                    );
                }}
            </ProFormList>
            <ProFormGroup title='Addresses' />
            <ProFormList
                name='addresses'
                creatorButtonProps={{ creatorButtonText: 'New Address', size: 'small' }}
            >
                {(f, index, action) => {
                    return (
                        <ProFormGroup>
                            <ProFormText name='id' initialValue={crypto.randomUUID()} hidden />
                            <ProFormText width='sm' name='street' placeholder='Street' />
                            <ProFormText width='sm' name='area' placeholder='Area' />
                            <ProFormText width='xs' name='area_code' placeholder='ZIP Code' />
                            <ProFormText width='xs' name='city' placeholder='City' />
                            <ProFormSwitch
                                name='is_primary'
                                // initialValue={index === primaries.address}
                                fieldProps={{
                                    checked: primaries.address !== false && index === primaries.address,
                                    onChange: (checked) => {
                                        checked
                                            ? setPrimaries((prev) => ({ ...prev, address: index }))
                                            : setPrimaries((prev) => ({ ...prev, address: false }));
                                    }
                                }}
                                tooltip='Make Primary?'
                            />
                        </ProFormGroup>
                    );
                }}
            </ProFormList>
            <ProFormGroup title='Notes' />
            <ProFormTextArea placeholder='...Enter your comments here' name='comment' />
        </ModalForm>
    );
};

export default NewConnectionModal;
