import {
    ProFormCheckbox,
    ProFormDatePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormRadio,
    ProFormSelect,
    ProFormText,
    ProFormTreeSelect,
    ProFormUploadDragger,
    StepsForm
} from '@ant-design/pro-form';
import { useCallback, useContext, useEffect } from 'react';
import { DDEXPageContext } from './DDEXMainPage';
import ProCard from '@ant-design/pro-card';
import { checksEnabled, fetchAssetLabelOptions, fetchLabelOptions } from './ReleaseEntryStepForm';
import axiosApiInstance from '../../../api/axiosClient';
import NewArtistForm from './NewArtistForm';
import NewLabelForm from './NewLabelForm';
import {
    countryTreeOptions,
    genreOptions,
    genreToSubgenreOptions,
    releasePriceTierOptions
} from './ddex_atoms';
import ProFormDatePickerYear from '@ant-design/pro-form/es/components/DatePicker/YearPicker';
import { message, TreeSelect } from 'antd';
import { Store } from 'antd/es/form/interface';
import axios from 'axios';

const fetchArtistOptions = async () =>
    axiosApiInstance
        .get<API.MusicDistribution.Artist[]>(`/api/products/artists/`)
        .then(({ data }) => data)
        .then((artists) =>
            artists.map((artist) => ({
                label: artist.key_name,
                value: artist.key_name
            }))
        )
        .catch((reason) => {
            console.error(reason);
            return [];
        });

const createArtistDisplayString = (main_artists: string[], featuring_artists: string[]): string => {
    var s = '';
    if (!main_artists || main_artists.length === 0) {
        return s;
    }

    var delim = ', ';
    if (main_artists.length === 2) delim = ' & ';

    s = s + main_artists.join(delim);

    // if (featuring_artists && featuring_artists.length > 0) {
    //     s = s + ' (ft. ' + featuring_artists.join(', ') + ')';
    // }

    return s;
};

const StepsFormFirstPage = ({ initialValues }: StepsFormFirstPageProps) => {
    const { stepFormRef, firstFormPageRef, secondFormPageRef, listRef, setArtists } =
        useContext(DDEXPageContext);

    const { SHOW_PARENT } = TreeSelect;

    useEffect(() => console.log('first form page rendered'));

    const handleFirstPageFlip = async (
        main_artists: API.MusicDistribution.Artist[],
        featuring_artists: API.MusicDistribution.Artist[]
    ) => {
        const tracks = secondFormPageRef.current?.getFieldValue('art_tracks');
        secondFormPageRef.current?.setFieldValue(
            'art_tracks',
            tracks.map((track: API.DDEX.Track) => ({ ...track, main_artists, featuring_artists }))
        );
    };

    const handleTrackNumbers = useCallback(
        (n: number, noOfTracks: number) => {
            if (n > noOfTracks) {
                listRef.current.add(
                    { isrc: '', track_artist_id: firstFormPageRef.current?.getFieldValue('main_artist_id') },
                    n
                );
            } else {
                listRef.current.remove(n);
            }
        },
        [listRef]
    );

    return (
        <ProCard colSpan={24} direction='row' style={{ width: 1600 }} split='vertical'>
            <ProCard colSpan={12}>
                <ProFormText
                    name={'name'}
                    label='Release Title'
                    placeholder={'Main Title of Release'}
                    required
                    rules={[
                        {
                            required: checksEnabled
                        }
                    ]}
                />
                <ProFormText
                    name={'subtitle'}
                    label='Version/Subtitle'
                    placeholder={'Release Subtitle (optional)'}
                />
                <ProFormText
                    name={'upc_ean'}
                    label='UPC/EAN'
                    placeholder={`The Release's UPC/EAN Code`}
                    hasFeedback
                    required
                    // rules={[
                    //     {
                    //         pattern: /^\d+$/g,
                    //         message: 'UPC/EAN should contain only digits',
                    //         required: checksEnabled
                    //     },
                    //     {
                    //         min: 12,
                    //         max: 13,
                    //         message: 'UPC/EAN should be 12-13 digits long',
                    //         validateTrigger: 'onFinish',
                    //         required: checksEnabled
                    //     },
                    //     {
                    //         validator(rule, value, callback) {
                    //             const digits = value.split('').map(Number);
                    //             let checksum = 0;
                    //             if (digits.length === 12) {
                    //                 // UPC
                    //                 for (let i = 0; i < 11; i++) {
                    //                     checksum += i % 2 === 0 ? digits[i] * 3 : digits[i];
                    //                 }
                    //                 checksum = (10 - (checksum % 10)) % 10;
                    //             } else {
                    //                 // EAN
                    //                 for (let i = 0; i < 12; i++) {
                    //                     checksum += i % 2 === 0 ? digits[i] : digits[i] * 3;
                    //                 }
                    //                 checksum = (10 - (checksum % 10)) % 10;
                    //             }
                    //             if (checksum === digits[digits.length - 1]) {
                    //                 callback();
                    //             } else {
                    //                 callback('Invalid UPC/EAN');
                    //             }
                    //         },
                    //         required: checksEnabled,
                    //         validateTrigger: 'onFinish'
                    //     }
                    // ]}
                />
                <ProFormSelect
                    name={'artists'}
                    label='Main Artists'
                    mode='multiple'
                    request={fetchArtistOptions}
                    fieldProps={{
                        onChange(value) {
                            const main_artists = value;
                            const featuring_artists =
                                firstFormPageRef.current?.getFieldValue('featuring_artists');

                            const artist_display = createArtistDisplayString(main_artists, featuring_artists);
                            firstFormPageRef.current?.setFieldValue('artist_display', artist_display);
                        },
                        dropdownRender(menu) {
                            return (
                                <>
                                    {menu}
                                    <NewArtistForm stepFormRef={stepFormRef} />
                                </>
                            );
                        }
                    }}
                    required
                    rules={[
                        {
                            required: checksEnabled
                        }
                    ]}
                />
                <ProFormCheckbox
                    name={'is_compilation'}
                    label={'Various Artists/Compilation'}
                    placeholder={'Various Artists/Compilation'}
                />
                <ProFormSelect
                    name={'featuring_artists'}
                    label='Featuring Artists'
                    mode='multiple'
                    request={fetchArtistOptions}
                    fieldProps={{
                        onChange(value) {
                            setArtists((prev) => ({ ...prev, featuring_artists: value }));
                        },
                        dropdownRender(menu) {
                            return (
                                <>
                                    {menu}
                                    <NewArtistForm stepFormRef={stepFormRef} />
                                </>
                            );
                        }
                    }}
                />
                <ProFormText name={'artist_display'} label='Artist Display Name' />
                <ProFormSelect
                    name={'label_id'}
                    label='Label'
                    placeholder={`The Release's Record Label`}
                    // options={labelOptions}
                    request={fetchLabelOptions}
                    fieldProps={{
                        showSearch: true,
                        dropdownRender(menu) {
                            return (
                                <>
                                    {menu}
                                    <NewLabelForm stepFormRef={stepFormRef} />
                                </>
                            );
                        }
                    }}
                    required
                    rules={[
                        {
                            required: checksEnabled
                        }
                    ]}
                />
                {/* <ProFormSelect
                    name={['label', 'asset_label_id']}
                    label='Asset Label'
                    placeholder={`YouTube Asset Label`}
                    // options={labelOptions}
                    request={fetchAssetLabelOptions}
                    fieldProps={{
                        showSearch: true
                    }}
                    required
                    rules={[
                        {
                            required: checksEnabled
                        }
                    ]}
                /> */}
                <ProFormSelect
                    name={'asset_label_id'}
                    label='Asset Label'
                    placeholder={`YouTube Asset Label`}
                    // options={labelOptions}
                    request={fetchAssetLabelOptions}
                    fieldProps={{
                        showSearch: true
                    }}
                    required
                    rules={[
                        {
                            required: checksEnabled
                        }
                    ]}
                />

                <ProFormSelect
                    name='genre'
                    label='Genre'
                    options={genreOptions}
                    showSearch
                    fieldProps={{
                        onChange(value, option) {
                            firstFormPageRef.current?.setFieldValue('sub_genre', undefined);
                        },
                        onClear() {
                            firstFormPageRef.current?.setFieldValue('sub_genre', undefined);
                        }
                    }}
                />
                <ProFormDependency name={['genre']}>
                    {({ genre }) => (
                        <ProFormSelect
                            name='sub_genre'
                            label='Subgenre'
                            options={genreToSubgenreOptions[genre]}
                            showSearch
                        />
                    )}
                </ProFormDependency>
            </ProCard>
            <ProCard>
                <ProFormRadio.Group
                    name='type'
                    label='Format'
                    radioType='button'
                    initialValue={'ALBUM'}
                    options={['ALBUM', 'SINGLE', 'EP']}
                    fieldProps={{
                        onChange(e) {
                            // When release type changes, set the price tier to the lowest available option
                            const price_options = releasePriceTierOptions[e.target.value];
                            firstFormPageRef.current?.setFieldValue('price_tier', price_options[0].value);
                        }
                    }}
                />
                <ProFormDatePicker
                    name='digital_release_date'
                    label='Digital Release Date'
                    placeholder={'Release Date'}
                    // dataFormat='YYYY-MM-DDTHH:mm:ssZ'
                    normalize={(value) => value?.format('YYYY-MM-DDTHH:mm:ssZ')}
                    fieldProps={{
                        // format: 'YYYY-MM-DDTHH:mm:ssZ'
                        onChange(value, dateString) {
                            firstFormPageRef.current?.setFieldsValue({
                                original_release_date: value,
                                production_year: value
                            });
                        }
                    }}
                    required
                    rules={[{ required: checksEnabled }]}
                />
                <ProFormDatePicker
                    name='original_release_date'
                    label='Original Release Date'
                    placeholder={'Physical/Original Release Date'}
                    // dataFormat='YYYY-MM-DDTHH:mm:ssZ'
                    normalize={(value) => value?.format('YYYY-MM-DDTHH:mm:ssZ')}
                    fieldProps={
                        {
                            // format: 'YYYY-MM-DDTHH:mm:ssZ'
                        }
                    }
                    required
                    rules={[{ required: checksEnabled }]}
                />
                <ProFormDatePickerYear
                    label='Production Year'
                    name='production_year'
                    normalize={(value) => value?.format('YYYY-MM-DDTHH:mm:ssZ')}
                    required
                    rules={[{ required: checksEnabled }]}
                />
                {/* </ProFormGroup> */}
                <ProFormDigit
                    name='number_of_tracks'
                    width='xs'
                    label='No. of Tracks'
                    min={1}
                    initialValue={1}
                    fieldProps={{
                        onChange(value) {
                            handleTrackNumbers(value as number, listRef.current?.getList().length);
                        }
                    }}
                />
                <ProFormDependency name={['type']}>
                    {({ type }) => {
                        const options = releasePriceTierOptions[type];
                        return (
                            <ProFormSelect
                                label='Price Tier'
                                name='price_tier'
                                initialValue={options ? options[0].value : undefined}
                                options={options}
                            />
                        );
                    }}
                </ProFormDependency>
                <ProFormTreeSelect
                    label='Release Territories'
                    name='release_territories'
                    initialValue={'WW'}
                    // options={countryTreeOptions}
                    request={async () => countryTreeOptions}
                    fieldProps={{
                        showCheckedStrategy: SHOW_PARENT,
                        showSearch: true,
                        treeCheckable: true,
                        maxTagCount: 5
                    }}
                />
                <ProFormText hidden name='album_cover_filename' />
                <ProFormUploadDragger
                    title='Cover Art'
                    description={`Drag and drop image file for the release's cover art`}
                    isList
                    max={1}
                    fieldProps={{
                        listType: 'picture',
                        // iconRender(file, listType) {
                        //     return file;
                        // },
                        beforeUpload: (file) => {
                            console.log(file);
                            firstFormPageRef?.current?.setFieldValue('album_cover_filename', file.name);
                            return true;
                        },
                        customRequest: async ({ file, onSuccess, onError, onProgress }) => {
                            // console.log(file);
                            // return Promise.resolve()
                            try {
                                const response = await axiosApiInstance.post(
                                    '/api/products/releases/generate-presigned-url-upload',
                                    {
                                        fileName: (file as any).name,
                                        fileType: (file as any).type,
                                        folderName: firstFormPageRef?.current?.getFieldValue('upc_ean')
                                    }
                                );
                                // console.log({response});

                                const { presignedUrl } = response.data;

                                return axios
                                    .put(presignedUrl, file, {
                                        headers: {
                                            'Content-Type': (file as any).type
                                        },
                                        onUploadProgress: (progressEvent) => {
                                            const percentCompleted = Math.round(
                                                (progressEvent.loaded * 100) / progressEvent.total
                                            );
                                            onProgress({ percent: percentCompleted });
                                        }
                                    })
                                    .then(() => {
                                        message.success('File uploaded successfully');
                                        // onSuccess(responseUpl.data)
                                        onSuccess('ok');
                                    })
                                    .catch((err) => {
                                        console.error('Error uploading file:', err);
                                        message.error('Error uploading file');
                                        onError(err);
                                    });
                            } catch (err) {
                                console.error('Error uploading file:', err);
                                message.error('Error uploading file');
                                onError(err);
                            }
                        }
                    }}
                />
            </ProCard>
        </ProCard>
    );
};

type StepsFormFirstPageProps = {
    initialValues?: Store;
};

export default StepsFormFirstPage;
