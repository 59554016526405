import {ExclamationCircleOutlined, SearchOutlined} from '@ant-design/icons';
import {
    Button,
    Card,
    Divider,
    Input,
    message,
    notification,
    Popconfirm,
    Row,
    Select,
    Space,
    Spin,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import exportFromJSON from 'export-from-json';
import React, {Component} from 'react';
import Highlighter from 'react-highlight-words';
import {postRequest} from '../../../api/postRequest';
import './index.css';
import {getRequest} from "../../../api/getRequest";
import {PageContainer} from "@ant-design/pro-components";

const {Option} = Select;
const {Title} = Typography;

export interface IPaymentsHistoryProps {
    //Here we pass the Props Interface
}

export interface IPaymentsHistoryState {
    //here we pass the State Interface
    dropdownData: any,
    totalDMNet: number,
    totalClientNet: number,
    dropdownLoading: boolean,
    reportsLoading: boolean,
    earningsReport?: any[],
    paymentsReport?: any[],
    selectedCycle?: string

    searchText: string,
    searchedColumn: string,

}

//class ComponentName Component<PropsInterface, StateInterface>
class PaymentsHistory extends Component<IPaymentsHistoryProps, IPaymentsHistoryState> {

    constructor(props: IPaymentsHistoryProps) {
        super(props)


        this.state = {
            dropdownData: [],
            totalDMNet: 0.0,
            totalClientNet: 0.0,
            reportsLoading: false,
            dropdownLoading: false,

            searchText: '',
            searchedColumn: ''
        }
    }

    //Add style here
    style = {};

    getFunc = async () => {
        getRequest("/api/erp/financialhistorysummary")
            .then((response) => {
                console.log('Authenticated');
                console.log(response);

                var c: any = {};
                var j = 1;


                response.data.forEach((item: any) => {


                    // result["key"] = j
                    // result["full_name"] = item["full_name"]
                    // results.push(result)
                    if (c[item["year"] + "_" + item["id"]] == undefined) {

                        c[item["year"] + "_" + item["id"]] = {
                            key: j,
                            full_name: "-",
                            affiliate_id: "-",
                            balance: "-",
                            customer_id: "-",
                            dm_euro: 0.0,
                            paid: "-",
                            datetime: "-",
                            id: item["id"],
                            percentage: "-",
                            ppk_value: "-",
                            rate: "-",
                            affiliate_share: "-",
                            affiliate_percentage: "-",
                            client_euro: 0.0,
                            tier: "-",
                            total_euro: "-",
                            year: item["year"],
                            children: []
                        }
                        j++;
                    }
                    // if(m[item["customer_id"]] == undefined){

                    //   m[item["customer_id"]] =  {
                    //     key : j,
                    //     full_name : item["full_name"],
                    //     affiliate_id: "-",
                    //     balance: "-",
                    //     customer_id: item["customer_id"] ,
                    //     dm_euro:"-",paid: "-",
                    //     datetime:"-",
                    //     id:"-",
                    //     percentage: "-",
                    //     ppk_value: "-",
                    //     rate: "-",
                    //     affiliate_share: "-",
                    //     affiliate_percentage: "-",
                    //     client_euro: "-",
                    //     tier: item["tier"],
                    //     total_euro: "-",
                    //     year: "-",
                    //     children : []
                    //   }
                    //   j++;
                    // }

                });
                var totalDMNet = 0.0
                var totalClientNet = 0.0
                for (let index = 0; index < response.data.length; index++) {
                    var json_item = response.data[index]
                    json_item["key"] = j


                    // m[response.data[index]["customer_id"]].children.push(json_item)
                    c[response.data[index]["year"] + "_" + response.data[index]["id"]].children.push(json_item)
                    c[response.data[index]["year"] + "_" + response.data[index]["id"]].dm_euro += Number(json_item["dm_euro"])
                    c[response.data[index]["year"] + "_" + response.data[index]["id"]].client_euro += Number(json_item["client_euro"])
                    totalDMNet += Number(json_item["dm_euro"]);
                    totalClientNet += Number(json_item["client_euro"]);
                    j++;
                }
                console.log("==============================================")
                var result = [];
                for (const [key, value] of Object.entries(c)) {
                    // console.log(key, value);
                    result.push(value)
                }
                console.log("==============================================")
                //  console.log(m)
                console.log(c)
                console.log("==============================================")
                //  console.log(map)


                this.setState({dropdownData: result, totalClientNet: totalClientNet, totalDMNet: totalDMNet});

            })
            .catch((error) => {
                console.log('Error on GET :' + error);
                message.error(error)
            });
    };

    componentWillMount() {
        // this.getFunc()
        this.getFunc2()
    }

    getFunc2 = async () => {
        this.setState({dropdownLoading: true})
        try {
            let res = await postRequest("/api/erp/financialhistorysummary")
            let data = res.data || []
            data.map((item: any) => item.key = item.payment_cycle)
            console.log(data);

            if (data[0].payment_cycle)
                this.getDataForCycle(data[0].payment_cycle)
            this.setState({selectedCycle: data[0].payment_cycle})

            this.setState({dropdownData: data, dropdownLoading: false})
        } catch (e) {
            notification.error({message: e.response.data.message})
            this.setState({earningsReport: [], paymentsReport: [], dropdownData: [], dropdownLoading: false})

        }

    }

    getDataForCycle = async (value: string) => {

        // console.log('SELECTED payment_cycle', value);

        this.setState({reportsLoading: true})
        try {
            let res = await postRequest("/api/erp/financialhistorycycle", {payment_cycle: value})
            let earningsReport = res.data.result.earnings || []
            let paymentsReport = res.data.result.payments || []
            // data.map((item: any) => item.key = item.payment_cycle)
            console.log(res.data);

            this.setState({earningsReport, paymentsReport, reportsLoading: false})
        } catch (e) {
            notification.error({message: e.response.data.message})
            this.setState({earningsReport: [], paymentsReport: [], reportsLoading: false})

        }


    }

    searchInput: any = null

    getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    // placeholder={`Search ${dataIndex}`}
                    placeholder={`Search...`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                    {/* <Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							this.setState({
								searchText: selectedKeys[0],
								searchedColumn: dataIndex,
							});
						}}
					>
						Filter
					</Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => this.searchInput!.select(), 100);
            }
        },
        render: (text: any) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({searchText: ''});
    };

    columns_earnings: ColumnsType<any> = [
        // { title: "Cycle ID", dataIndex: "payment_cycle" },
        {title: "Asset key", dataIndex: "asset_key", ...this.getColumnSearchProps('asset_key')},
        {title: "Customer id", dataIndex: "customer_id", ...this.getColumnSearchProps('customer_id'),},
        {title: "Full name", dataIndex: "full_name", ...this.getColumnSearchProps('full_name')},
        {title: "Total euro", dataIndex: "total_euro", align: 'right', render: (input: any) => input + " €"},
        {title: "Rate", dataIndex: "rate"},
        {title: "Commision", dataIndex: "commision"},
        {title: "DM euro", dataIndex: "dm_euro", align: 'right', render: (input: any) => input + " €"},
        {title: "Client euro", dataIndex: "client_euro", align: 'right', render: (input: any) => input + " €"},
        {title: "Affiliate id", dataIndex: "affiliate_id"},
        {title: "Affiliate percentage", dataIndex: "affiliate_percentage"},
        {title: "Affiliate share", dataIndex: "affiliate_share", align: 'right', render: (input: any) => input + " €"},
        {title: "Date", dataIndex: "date"},
    ]

    columns_payments: ColumnsType<any> = [
        // { title: "Cycle ID", dataIndex: "payment_cycle" },
        {title: "Customer id", dataIndex: "customer_id", ...this.getColumnSearchProps('customer_id')},
        {title: "Full name", dataIndex: "full_name", ...this.getColumnSearchProps('full_name')},
        {title: "Prev. balance", dataIndex: "prev_balance", align: 'right', render: (input: any) => input + " €"},
        {
            title: "Prev. remaining downpayment",
            dataIndex: "prev_remaining_downpayment",
            align: 'right',
            render: (input: any) => input + " €"
        },
        {title: "Admin fees", dataIndex: "admin_fees", align: 'right', render: (input: any) => input + " €"},
        {
            title: "Granted affiliate revenue",
            dataIndex: "granted_affiliate_revenue",
            align: 'right',
            render: (input: any) => input + " €"
        },
        {
            title: "Downpayment reduction",
            dataIndex: "downpayment_reduction",
            align: 'right',
            render: (input: any) => input + " €"
        },
        {title: "Earnings", dataIndex: "earnings", align: 'right', render: (input: any) => input + " €"},
        {
            title: "New remaining downpayment",
            dataIndex: "new_remaining_downpayment",
            align: 'right',
            render: (input: any) => input + " €"
        },
        {title: "Payment amount", dataIndex: "payment_amount", align: 'right', render: (input: any) => input + " €"},
        {title: "Paid", dataIndex: "paid"},
        {title: "New balance", dataIndex: "new_balance", align: 'right', render: (input: any) => input + " €"},
        {title: "Date", dataIndex: "date"},
        {title: "Notes", dataIndex: "notes"},
    ]

    onSelectChange = (value: string) => {
        console.log('value', value);

        this.setState({selectedCycle: value})
    }

    exportEarngingsToExcel = (data: any, fileName: string) => {
        // let data = JSON.stringify(this.state.earningsReport!)
        // let data = this.state.earningsReport!
        exportFromJSON({data, fileName, exportType: 'xls'})
    }

    deleteCycle = async () => {
        // console.log('selectedCycle', this.state.selectedCycle);

        const res = await postRequest("/api/erp/deletetestcycle", {
            cycle_year: this.state.selectedCycle?.split('_')[0],
            cycle_id: this.state.selectedCycle?.split('_')[1]
        })

        notification.success({message: 'Deleted.'})
        // return
        setTimeout(() => {
            window.location.reload()
        }, 300)

    }

    render() {

        return (

            <PageContainer>

                <Row style={{width: '100%'}} justify='space-around' align='middle'>

                    {/* <Space style={{ width: '100%' }}> */}

                    <Typography.Text strong style={{width: '20%', textAlign: 'center'}}>Select payment
                        cycle:</Typography.Text>

                    {this.state.dropdownLoading ? <Spin/> :
                        <Select
                            showSearch
                            // style={{ width: 200 }}
                            style={{width: '80%'}}
                            placeholder="Select cycle"
                            optionFilterProp="children"
                            onChange={this.onSelectChange}
                            // onSearch={onSearch}
                            defaultActiveFirstOption={true}
                            loading={this.state.dropdownLoading}
                            defaultValue={this.state.dropdownData[0]?.payment_cycle}
                            onSelect={this.getDataForCycle}
                            // filterOption={(input, option) =>
                            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            // }
                        >
                            {this.state.dropdownData.map((el: any) => (
                                // <Option value={el.payment_cycle}>{el.payment_cycle} | {el.client_euro} | {el.datetime} | {el.num_customers}</Option>
                                // <Option value={el.payment_cycle}>{el.payment_cycle} ( € {el.client_euro} | {el.datetime} )</Option>
                                <Option value={el.payment_cycle}>{el.payment_cycle} ( {el.datetime} )</Option>
                            ))}
                        </Select>
                    }

                    {/* </Space> */}
                </Row>
                <br/>

                <Divider/>


                {this.state.reportsLoading || !this.state.earningsReport || !this.state.paymentsReport ?
                    <Row justify='center'><Spin/></Row> :
                    <>
                        <Row justify='space-between' style={{padding: 4}}>
                            <Title level={5}>Earnings Report</Title>

                            <Space>
                                <Button type='primary'
                                        onClick={() => this.exportEarngingsToExcel(this.state.earningsReport!, 'earnings_report_' + this.state.selectedCycle)}>
                                    Export to excel
                                </Button>
                            </Space>
                        </Row>
                        <br/>
                        <Row>
                            <Card bodyStyle={{padding: 0}} style={{width: '100%'}}>
                                <Table
                                    dataSource={this.state.earningsReport}
                                    columns={this.columns_earnings}
                                    pagination={{hideOnSinglePage: true}}
                                    loading={this.state.reportsLoading}
                                />
                            </Card>
                        </Row>
                        <br/>

                        <Row justify='space-between' style={{padding: 4}}>
                            <Title level={5}>Payments Report</Title>

                            <Space>
                                <Button type='primary'
                                        onClick={() => this.exportEarngingsToExcel(this.state.paymentsReport!, 'payments_report_' + this.state.selectedCycle)}>
                                    Export to excel
                                </Button>
                            </Space>
                        </Row>
                        <br/>
                        <Row>
                            <Card bodyStyle={{padding: 0}} style={{width: '100%'}}>
                                <Table
                                    dataSource={this.state.paymentsReport}
                                    columns={this.columns_payments}
                                    pagination={{hideOnSinglePage: true}}
                                    loading={this.state.reportsLoading}
                                />
                            </Card>
                        </Row>

                        <br/>
                        {/* Delete Cycle */}
                        {this.state.selectedCycle?.split('_').length == 3 && this.state.selectedCycle?.split('_')[2] == 'test' &&
                            <Row justify='start'>
                                <Popconfirm title={'Are you sure? This will discard the selected billing cycle.'}
                                            onConfirm={this.deleteCycle}>
                                    <Button icon={<ExclamationCircleOutlined/>} danger size='small'>Delete Test
                                        Cycle</Button>
                                </Popconfirm>
                            </Row>
                        }

                    </>
                }


            </PageContainer>

        );
    }
}

export default PaymentsHistory;