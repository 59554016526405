import * as React from 'react';
import {ProTable} from "@ant-design/pro-components";
import dayjs from "dayjs";
import {Typography} from "antd";
import {numFormatterInt} from "./TikTokBenchmarks";
import {useAtom} from "jotai";
import {yearlyViewsAtom} from "./atoms";

type Props = {};

export const YearView = (props: Props) => {
    const [{data: yearlyViews}, refreshYearlyViews] = useAtom(yearlyViewsAtom)

    return (
        <div>
            <ProTable<API.Benchmarks.TiktokBenchmarksAccountDataPoint>
                // actionRef={actionRefYearly}
                rowKey={'month'}
                dataSource={yearlyViews.data}
                search={false}
                toolBarRender={false}
                // options={{
                //     reload: true,
                //     fullScreen: false,
                //     setting: false,
                //     density: false,
                //     search: false,
                // }}
                columns={[
                    // {
                    //     key: 'index',
                    //     valueType: 'indexBorder',
                    //     align: 'center',
                    // },
                    {
                        title: 'Month',
                        dataIndex: 'date',
                        key: 'date',
                        renderText: (value: string) => dayjs(value).format('MMMM'),
                        sorter: (a, b) => dayjs(a.date, 'YYYY-MM-DD').diff(dayjs(b.date, 'YYYY-MM-DD')),
                    },
                    {
                        title: 'Followers',
                        dataIndex: 'followers',
                        key: 'followers',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.followers - b.followers,
                    },
                    {
                        title: 'Following',
                        dataIndex: 'following',
                        key: 'following',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.following - b.following,
                    },
                    {
                        title: 'Likes',
                        dataIndex: 'likes',
                        key: 'likes',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.likes - b.likes,
                    },
                    {
                        title: 'Uploads',
                        dataIndex: 'uploads',
                        key: 'uploads',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.uploads - b.uploads,
                    },
                ]}

                pagination={false}
                cardBordered
                summary={() => {
                    return (
                        <ProTable.Summary fixed="top">
                            <ProTable.Summary.Row style={{backgroundColor: '#e6f4ff'}}>
                                <ProTable.Summary.Cell index={0}>
                                    <Typography.Text strong>Average</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={1}>
                                    <Typography.Text
                                        strong>{numFormatterInt.format(yearlyViews.avg.followers)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={2}>
                                    <Typography.Text
                                        strong>{numFormatterInt.format(yearlyViews.avg.following)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={3}>
                                    <Typography.Text
                                        strong>{numFormatterInt.format(yearlyViews.avg.likes)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={4}>
                                    <Typography.Text
                                        strong>{numFormatterInt.format(yearlyViews.avg.uploads)}</Typography.Text>
                                </ProTable.Summary.Cell>
                            </ProTable.Summary.Row>
                        </ProTable.Summary>
                    )
                }}
                sticky={{offsetHeader: 54}}
            />
        </div>
    );
};

export default YearView;