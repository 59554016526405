import { AppstoreAddOutlined, BoxPlotOutlined, CalculatorOutlined, CheckSquareOutlined, DeleteOutlined, DollarOutlined, ExclamationCircleOutlined, LoadingOutlined, SmileOutlined, SolutionOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, message, notification, Row, Space, Steps, Tag, Typography, Tree, Spin, Modal, Alert, Upload, Progress } from 'antd';
import { blue } from '@ant-design/colors';
import axios from 'axios';
import qs from 'qs';
import { API } from '../../../common/constants';
import React, { Component } from 'react';
import './index.css';
import { postRequest } from '../../../api/postRequest';
import { hashCode, roundNum } from '../../../common/utils';
import { EventDataNode } from 'rc-tree/lib/interface';
import { textChangeRangeIsUnchanged } from 'typescript';
const { DirectoryTree } = Tree;

export interface IFileManagerComponentProps {
	//Here we pass the Props Interface
}

export interface IFileManagerComponentState {
	//here we pass the State Interface
	treeData?: any
	loading: boolean
	showUploadModal: boolean
	selectedPath?: string
	fileList: any[]
	uploading: boolean
	uploadProgress?: number
	showDeleteModal: boolean
	deleting: boolean
}

//class ComponentName Component<PropsInterface, StateInterface>
class FileManagerComponent extends Component<IFileManagerComponentProps, IFileManagerComponentState> {

	constructor(props: IFileManagerComponentProps) {
		super(props)

		this.state = {
			loading: false,
			showUploadModal: false,
			fileList: [],
			uploading: false,
			showDeleteModal: false,
			deleting: false,
		}
	}


	//Add style here
	style = {
	};

	// Before the component mounts, we initialise our state
	componentWillMount() {
	}

	// After the component did mount, we set the state.
	componentDidMount() {
		this.getTreeFileStructure()
	}

	getTreeFileStructure = async () => {
		this.setState({ loading: true })

		const response = await postRequest(`/api/erp/geterpfiles`);

		// console.log('Authenticated');
		// console.log(response)

		let files = response.data.result.split(',') || []
		console.log(files)
		// let treeData = this.buildTreeData(files)
		let treeData = this.arrangeIntoTree(files)
		console.log(treeData)

		this.setState({ treeData: treeData })
		// let res = response.data
		// let time = res.status

		// notification.success({ message: '' });
		this.setState({ loading: false })

	}

	arrangeIntoTree(paths: string[]) {
		let tree: any = [];

		for (let i = 0; i < paths.length; i++) {
			// let path = paths[i];
			let path = paths[i].split('/');
			let currentLevel = tree;
			for (let j = 0; j < path.length; j++) {
				let part = path[j];

				let existingPath = findWhere(currentLevel, 'title', part);

				if (existingPath) {
					currentLevel = existingPath.children;
				} else {
					let newPart = {
						title: part,
						children: [],
						// key: hashCode(paths[i] + part),
						key: paths[i],
						// path: paths[i],
						isLeaf: part.includes('.')
					}

					currentLevel.push(newPart);
					currentLevel = newPart.children;
				}
			}
		}
		return tree;

		function findWhere(array: string | any[], key: string, value: string) {
			let t = 0; // t is used as a counter
			while (t < array.length && array[t][key] !== value) { t++; }; // find the index where the id is the as the aValue

			if (t < array.length) {
				return array[t]
			} else {
				return false;
			}
		}
	}

	// buildTreeData(paths: string[]) {

	// 	let result: any = [];
	// 	let level = { result };

	// 	paths.forEach((path: string) => {
	// 		path.split('/').reduce((r: { [key: string]: any }, title, i, a) => {
	// 			if (!r[title]) {
	// 				r[title] = { result: [] };

	// 				// let path_base = path.substr(path.lastIndexOf('\\') + 1)
	// 				if (i == a.length - 1) {
	// 					r.isLeaf = true
	// 					r.result.push({ title, key: path += '_' + title, children: r[title].result, isLeaf: true })

	// 				} else {
	// 					r.result.push({ title, key: path += '_' + title, children: r[title].result })

	// 				}
	// 			}

	// 			// console.log('pathbase', path_base, 'r[title]', r[title]);
	// 			return r[title];

	// 		}, level)
	// 	})

	// 	return result

	// }


	handleRightClickNode = async (info: { event: React.MouseEvent<Element, MouseEvent>, node: any }) => {


		if (info.node.isLeaf) {
			// if a file is clicked, ask if the user wants to delete it from the filesystem
			this.setState({ showDeleteModal: true, selectedPath: info.node.key as string })

		} else {
			// this is a folder
			// to upload file in folder, all children must be leafs
			for (const child of info.node.children!) {
				if (!child.isLeaf) {
					message.warning('Please select a leaf directory to upload files to.')
					return
				}
			}

			// show upload modal, with the selected path
			this.setState({ showUploadModal: true, selectedPath: info.node.key as string })


		}
	}

	handleUpload = ({ fileList }: any) => {

		console.log('fileList', fileList);

		// you store them in state, so that you can make a http req with them later
		this.setState({ fileList });
	};

	handleSubmit = (event: { preventDefault: () => void; }) => {

		this.setState({ uploading: true })
		event.preventDefault();

		let formData = new FormData();
		// add one or more of your files in FormData
		// again, the original file is located at the `originFileObj` key

		// formData.append("files", this.state.fileList.map((f) => f.originFileObj));
		for (const file of this.state.fileList)
			formData.append("files", file.originFileObj);

		formData.append("target_path", this.state.selectedPath!)

		axios
			.post(API + `/api/erp/upload_cycle_files`, formData,
				{
					headers: {
						"Accept": "*/*",
						'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
					},
					auth: {
						username: 'dev',
						password: '1234',
					},
					onUploadProgress: (progressEvent) => {
						const { loaded, total } = progressEvent
						const percent = roundNum((loaded / total) * 100, 1)
						console.log('progressEvent', progressEvent, percent);
						this.setState({ uploadProgress: percent })
					}
				})
			.then(res => {
				this.setState({ uploading: false })
				console.log("res", res);

				notification.success({ message: 'Successfully uploaded ' + this.state.fileList.length + ' file(s).' })

				this.closeUploadModal()

				this.getTreeFileStructure()


			})
			.catch(err => {
				this.setState({ uploading: false })
				console.log("err", err);
			});
	};

	handleDelete = async () => {

		this.setState({ deleting: true })

		let res = await postRequest('/api/erp/remove_cycle_file', { target_path: this.state.selectedPath })

		this.setState({ deleting: false })

		notification.success({ message: 'Successfully deleted file.' })

		this.closeDeleteModal()

		this.getTreeFileStructure()

	}

	closeUploadModal = () => { this.setState({ showUploadModal: false, uploadProgress: 0, fileList: [] }) }

	closeDeleteModal = () => { this.setState({ showDeleteModal: false }) }

	render() {
		return (
			<div className="FileManagerComponent" style={this.style}>
				{!this.state.treeData && <Row justify='center'><Spin /></Row>}
				{this.state.treeData &&
					<DirectoryTree
						// multiple
						// onSelect={onSelect}
						// onExpand={onExpand}
						treeData={this.state.treeData}
						defaultExpandAll
						expandAction={'doubleClick'}
						onRightClick={this.handleRightClickNode}
					/>
				}

				{/* UPLOAD MODAL */}

				<Modal visible={this.state.showUploadModal} title={'Upload to remote directory'} closable maskClosable={!this.state.uploading}
					onCancel={this.closeUploadModal}
					onOk={this.handleSubmit}
					okText='Upload'
					okButtonProps={{ disabled: this.state.fileList.length === 0 || this.state.uploadProgress === 100, loading: this.state.uploading }}
					width={720}
				>

					<Alert message={<>
						<Typography.Text>
							Upload files to path: <Typography.Text strong> {this.state.selectedPath}</Typography.Text>
						</Typography.Text>
					</>} />
					<br />
					<Row>
						<Upload
							fileList={this.state.fileList}
							onChange={this.handleUpload}
							beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
							multiple
							accept='.csv,application/vnd.ms-excel'
						>
							<Button icon={<UploadOutlined />}>Choose files</Button>

						</Upload>
						{this.state.uploadProgress !== undefined && this.state.uploadProgress !== 0 && <Progress percent={this.state.uploadProgress} />}
					</Row>

				</Modal>

				{/* DELETE MODAL */}

				<Modal visible={this.state.showDeleteModal} title={'Delete file from remote directory'} closable maskClosable
					onCancel={this.closeDeleteModal}
					onOk={this.handleDelete}
					okText='Yes, delete file'
					okButtonProps={{ danger: true, loading: this.state.deleting }}
				>

					<Alert message={<>
						<Typography.Text>
							Are you sure you want to delete <Typography.Text strong> {this.state.selectedPath}</Typography.Text> ?
						</Typography.Text>
					</>} type='warning' />

				</Modal>

			</div>
		);
	}
}

export default FileManagerComponent;