import {Card, ConfigProvider} from 'antd';
import React, {Component} from 'react';
import './index.css';
import enUSIntl from "antd/lib/locale/en_US";
import {ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import moment from "moment";
import ProDescriptions from "@ant-design/pro-descriptions";
import {roundNum} from "../../../common/utils";


export interface IPaymentCycleHealthCheckProps {
    //Here we pass the Props Interface
    cycle_id?: string
    pageSize?: number
    polling?: number
}

export interface IPaymentCycleHealthCheckState {


}


type PaymentCycleHealthCheckType = {
    cycle_id: string;
    message: string;
    creation_time: string;
    type: string;
};


//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleHealthCheck extends Component<IPaymentCycleHealthCheckProps, IPaymentCycleHealthCheckState> {

    constructor(props: IPaymentCycleHealthCheckProps) {
        super(props);

        this.state = {}
    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {

    }

    // After the component did mount, we set the state.
    componentDidMount() {
        // console.log('cycle logs', this.props.cycle_id);
    }

    columns: ProColumns<PaymentCycleHealthCheckType>[] = [
        {
            title: 'Type', dataIndex: 'type', valueType: 'text', width: 120, align: "center",
            // valueEnum: row => ({
            //     // all: { text: 'processing', status: 'processing' },
            //     info: {
            //         // text: 'info',
            //         status: 'default',
            //     },
            //     success: {
            //         // text: 'done',
            //         status: 'success',
            //     },
            //     error: {
            //         // text: 'error',
            //         status: 'error',
            //     },
            // })
            valueEnum: row => ({
                // all: { text: 'processing', status: 'processing' },
                default: {
                    text: 'default',
                    status: 'default',
                },
                processing: {
                    text: 'processing',
                    status: 'processing',
                },
                success: {
                    text: 'success',
                    status: 'success',
                },
                error: {
                    text: 'error',
                    status: 'error',
                },
            })
        },
        {
            title: 'Creation time', dataIndex: 'creation_time', valueType: 'text', width: 160,
            render: (dom, entity, index) =>
                (moment(entity.creation_time, "YYYY-MM-DD kk:mm:ss").format("DD/MM/YYYY kk:mm:ss"))
        },
        {
            title: 'Message', dataIndex: 'message', valueType: 'text',
            // copyable: true
        },
    ];


    render() {


        return (
            <>

                {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}


                {/*<Card bodyStyle={{justifyItems:"center"}}>*/}
                {/*<Card bodyStyle={{padding: 0}}>*/}
                <Card>

                    <ConfigProvider locale={enUSIntl}>
                        <ProDescriptions
                            // title="Basic stats"
                            // size={"default"}
                            column={1}
                            request={async () => {
                                // console.log(params)
                                const res = await postRequest('/api/paymentCycles/getHealthCheck/' + this.props.cycle_id)
                                const data = res.data
                                // console.log(res.data)
                                return {data, success: true}
                            }}
                            columns={[
                                {
                                    title: 'Total rows', dataIndex: 'total_rows', valueType: 'text',
                                    renderText: num => (num?.toLocaleString('el-GR'))
                                },
                                {
                                    title: 'Matched records', dataIndex: 'matched_records', valueType: 'text',
                                    render: (dom, entity) => entity.matched_records.toLocaleString('el-GR') + ' (' + roundNum((entity.matched_records / entity.total_rows) * 100, 2).toLocaleString('el-GR') + '%)'
                                },
                                {
                                    title: 'Not matched records', dataIndex: 'not_matched_records', valueType: 'text',
                                    render: (dom, entity) => entity.not_matched_records.toLocaleString('el-GR') + ' (' + roundNum((entity.not_matched_records / entity.total_rows) * 100, 2).toLocaleString('el-GR') + '%)'
                                },
                                {
                                    title: 'Missing customers',
                                    dataIndex: 'missing_customers',
                                    valueType: 'text',
                                    tooltip: 'Customers from assets info not found in active clients info',
                                    renderText: num => {
                                        if (num > 0) {
                                            return <span style={{color: 'red'}}>{num}</span>
                                        }
                                        return num
                                    }
                                },
                                {
                                    title: 'Missing affiliate customers',
                                    dataIndex: 'missing_affiliate_customers',
                                    valueType: 'text',
                                    tooltip: 'Affiliate customers from assets info not found in active clients info',
                                    renderText: num => {
                                        if (num > 0) {
                                            return <span style={{color: 'red'}}>{num}</span>
                                        }
                                        return num
                                    }
                                },
                                {
                                    title: 'Customers missing reports for Youtube Entertainment',
                                    dataIndex: 'customers_missing_report_youtube_entertainment',
                                    valueType: 'text',
                                    render: (dom, entity) => {
                                        if (entity?.customers_missing_report_youtube_entertainment?.length > 0) {
                                            return <span
                                                style={{color: 'red'}}>{entity?.customers_missing_report_youtube_entertainment?.join(', ')}</span>
                                        }
                                        return 'None'
                                    }
                                },
                                {
                                    title: 'Customers missing reports for Youtube Music',
                                    dataIndex: 'customers_missing_report_youtube_music',
                                    valueType: 'text',
                                    render: (dom, entity) => {
                                        if (entity?.customers_missing_report_youtube_music?.length > 0) {
                                            return <span
                                                style={{color: 'red'}}>{entity?.customers_missing_report_youtube_music?.join(', ')}</span>
                                        }
                                        return 'None'
                                    }
                                },
                                {
                                    title: 'Customers missing reports for Spotify',
                                    dataIndex: 'customers_missing_report_spotify',
                                    valueType: 'text',
                                    render: (dom, entity) => {
                                        if (entity?.customers_missing_report_spotify?.length > 0) {
                                            return <span
                                                style={{color: 'red'}}>{entity?.customers_missing_report_spotify?.join(', ')}</span>
                                        }
                                        return 'None'
                                    }
                                },
                                {
                                    title: 'Customers missing reports for Music Distribution',
                                    dataIndex: 'customers_missing_report_md',
                                    valueType: 'text',
                                    render: (dom, entity) => {
                                        if (entity?.customers_missing_report_md?.length > 0) {
                                            return <span
                                                style={{color: 'red'}}>{entity?.customers_missing_report_md?.join(', ')}</span>
                                        }
                                        return 'None'
                                    }
                                },
                                // {
                                //     title: 'Creation time',
                                //     dataIndex: 'creation_time',
                                //     valueType: 'text',
                                //     renderText: (text, record, index) => {
                                //         return (moment(text, "YYYY-MM-DD kk:mm:ss").format("DD/MM/YYYY kk:mm:ss"))
                                //     }
                                // },
                                // {title: 'Description', dataIndex: 'description', valueType: 'text',},
                                // {title: 'Step', dataIndex: 'step', valueType: 'text',},
                                // {
                                //     title: 'Phase', dataIndex: 'phase', valueType: 'text',
                                //     valueEnum: {
                                //         // all: { text: '全部', status: 'Default' },
                                //         // open: {
                                //         //     text: '未解决',
                                //         //     status: 'Error',
                                //         // },
                                //         done: {
                                //             text: 'done',
                                //             status: 'success',
                                //         },
                                //         // processing: {
                                //         //     text: '解决中',
                                //         //     status: 'Processing',
                                //         // },
                                //     }
                                // },
                                // {
                                //     title: 'Available files count',
                                //     dataIndex: 'available_files_cnt',
                                //     valueType: 'digit',
                                // },
                                // {
                                //     title: 'Loaded files count',
                                //     dataIndex: 'loaded_files_cnt',
                                //     valueType: 'digit',
                                // },
                                // {
                                //     title: 'Loaded records count',
                                //     dataIndex: 'loaded_records_cnt',
                                //     renderText: num => (num?.toLocaleString('el-GR'))
                                // },
                                // {
                                //     title: 'Reference month', dataIndex: 'reference_month', valueType: 'text',
                                //     render: (dom, entity, index) =>
                                //         (moment(entity.reference_month, "YYYY-MM-DD").format("MMM YYYY"))
                                // }
                            ]}
                        />
                    </ConfigProvider>
                </Card>


            </>
        );
    }
}

export default PaymentCycleHealthCheck;