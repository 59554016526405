import {ModalForm} from '@ant-design/pro-components';
import * as React from 'react';
import {useContext} from 'react';
import {Typography} from "antd";
import axiosApiInstance from "../../../api/axiosClient";
import {GlobalStateContext} from "../../../context/GlobalContext";

type Props = {
    leadsIds: number[]
    onClearSelection?: any
};

export const DeleteBulkCreatorLeads = (props: Props) => {
    const {creatorsLeadsTableRef} = useContext(GlobalStateContext);

    return (
        <ModalForm
            width={400}
            title={props.leadsIds.length > 1 ? `Delete ${props.leadsIds.length} Items` : "Delete 1 Item"}
            trigger={
                <Typography.Link
                    // type={props.batch ? "danger" : undefined}
                    type={"danger"}
                >{'Batch delete'}</Typography.Link>
            }
            submitter={{submitButtonProps: {danger: true}}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                // onCancel: () => console.log('run'),
                okText: 'Delete',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                return axiosApiInstance.delete('/api/leads/items', {data: {ids: props.leadsIds}})
                    .then(res => {
                        creatorsLeadsTableRef?.current?.reload()
                        props.onClearSelection()
                        return true
                    }).catch(reason => false)
            }}
        >
            Are you sure?
        </ModalForm>
    );
};

export default DeleteBulkCreatorLeads