import axios from "axios";
import { YOUTUBE_API_KEY } from "../common/youtube_api_constants";
const topics = require('./youtube_topics_map.json')

const IMG_RESOLUTION: 'default' | 'medium' | 'high' = 'high';


export const youtubeGetChannelImageTitleTopics = async (channel: string) => {
   const ytResponse = await axios.get('channels', {
      baseURL: 'https://youtube.googleapis.com/youtube/v3/',
      params: {
         part: 'snippet,topicDetails',
         key: YOUTUBE_API_KEY,
         id: channel,
         fields: 'items/snippet/thumbnails/' + IMG_RESOLUTION + ',items/snippet/title,items/topicDetails/topicIds',
      },
      headers: {},
   });
   //   console.log(ytResponse);

   try {
      const imgUrl = ytResponse.data.items[0].snippet.thumbnails[IMG_RESOLUTION].url;
      const channelTitle = ytResponse.data.items[0].snippet.title;

      //topics
      const matchedTopics = ytResponse.data.items[0].topicDetails.topicIds.map((topic_id: string) => {
         return topics[topic_id] ? topics[topic_id] : undefined
      });


      return { imgUrl: imgUrl, channelTitle: channelTitle, topics: matchedTopics }
   } catch (error) {
      console.log('youtubeGetChannelImageAndTitle error:', error);
      return
   }
}