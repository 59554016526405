import React, { useContext } from 'react';
import { DDEXPageContext } from './DDEXMainPage';
import { ModalForm } from '@ant-design/pro-form';

const ArtTracksArtistsModal = () => {
    const { trackAction } = useContext(DDEXPageContext);
    return <ModalForm open>{`${trackAction.getCurrentRowData()}`}</ModalForm>;
};

export default ArtTracksArtistsModal;
