import {
    Alert,
    Button,
    Card,
    Col,
    ConfigProvider,
    Divider,
    message,
    notification,
    Row,
    Select,
    Space,
    Spin,
    Statistic,
    Typography
} from 'antd';
import ProForm, {ProFormDateRangePicker, ProFormDependency, ProFormSwitch} from '@ant-design/pro-form';
import {enUSIntl} from '@ant-design/pro-provider';
import Text from 'antd/lib/typography/Text';
import React, {Component} from 'react';
import {
    AggrReportResult,
    constructGraphDataFromAggrReportResult,
    convertAggrReportResultToCumulative,
    roundUpAggrReportResultValuesComparative,
    YoutubeAnalyticsResponse,
} from '../../../api/aggregateReportBuilder';
import {cmsIdEnum, defaultRevenueCurrency} from '../../../common/cmsInfo';
import {authorize} from '../../../common/gapiHandler';
import {TagCategoryValueDrawer} from '../../../common/models';
import dayjs from "dayjs";
import './index.css';
import {SearchOutlined} from '@ant-design/icons';
import {postRequest} from '../../../api/postRequest';
import DashboardReportingGraphV2Comparative from '../../components/DashboardReportingGraphV2Comparative';
import {PageContainer} from "@ant-design/pro-components";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat)

export interface IWhatIfReportingV2Props {
    //Here we pass the Props Interface
}

export interface IWhatIfReportingV2State {
    //here we pass the State Interface
    //here we pass the State Interface
    isAuthorized: boolean;
    isAuthorizing: boolean;
    isRequestLoading: boolean;
    // responseData?: { [key: string]: { [key: string]: number } },
    responseData1?: AggrReportResult;
    responseData2?: AggrReportResult;
    aggrStats?: { [key: string]: number };
    isGapiLibraryLoading: boolean;
    report?: any;
    formValues: GraphFormValues;
    // graphData?: { [key: string]: any[] },
    graphData1?: {
        [key: string]: { data: any[]; unit?: string };
    };
    graphData2?: {
        [key: string]: { data: any[]; unit?: string };
    };
    // select
    availableTags: TagCategoryValueDrawer[];
    // assets: AssetListRow[]
    selectedTags1?: number[];
    selectedTags2?: number[];
    assetsData1?: string[];
    assetsData2?: string[];

    graphDataComparative?: any;
}

interface GraphFormValues {
    startDate?: string;
    endDate?: string;
    contentOwner?: string[];
    lifetime?: boolean;
    cumulative?: boolean;
}

interface AssetAssocWithTag {
    asset_id: number;
    cms: string;
    cms_extra_id: string;
    ppk_value: string;
}

//class ComponentName Component<PropsInterface, StateInterface>
class WhatIfReportingV2 extends Component<IWhatIfReportingV2Props, IWhatIfReportingV2State> {
    constructor(props: IWhatIfReportingV2Props) {
        super(props);

        const currDate = new Date();

        this.state = {
            isAuthorized: false,
            isAuthorizing: false,
            isRequestLoading: false,
            isGapiLibraryLoading: true,
            formValues: {
                startDate: currDate.getFullYear() - 1 + '-' + (currDate.getMonth() + 1 < 10 ? '0' : '') + (currDate.getMonth() + 1) + '-01',
                endDate: currDate.getFullYear() + '-' + (currDate.getMonth() + 1 < 10 ? '0' : '') + (currDate.getMonth() + 1) + '-01',
                contentOwner: [cmsIdEnum['xcDgV1hLEnOLtNvdGppirg']],
                lifetime: false,
                cumulative: true,
            },
            aggrStats: {},
            availableTags: [],
            // assets: [],
        };
        this.formHandler = this.formHandler.bind(this);
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        // Load the gapi (google apis) JS client library and the auth2 client

        // (window as any).gapi.load('client:auth2', () =>
        //   initClient((success: boolean) => {
        //     console.log(success ? 'Loaded gapi client' : 'Could not load gapi client');
        //     this.setState({ isGapiLibraryLoading: false });

        //     if (isUserAuthorized()) this.setState({ isAuthorized: true });
        //     else this.setState({ isAuthorized: false });

        //     if (!isUserAuthorized()) this.authorize();

        //     if (success)
        //       //test
        //       // this.formHandler()
        //       return;
        //     // 	fetchAnalytics({ startDate: '2021-05-01', endDate: '2021-05-20', filters: 'uploaderType==self', metrics: 'views' }, (res: any) => console.log(res))
        //   })
        // );

        this.setState({isGapiLibraryLoading: false, isAuthorized: true}); // TODO: Duct tape solution!! refactor later

        this.getFunc();
    }

    authorize() {
        // deprecated
        console.log('Authorizing...');

        this.setState({isAuthorizing: true}, () =>
            authorize()
                .then(() => this.setState({isAuthorized: true, isAuthorizing: false}))
                .catch(() => this.setState({isAuthorized: false, isAuthorizing: false}))
        );
    }

    async formHandler() {
        // set the date & check form value input sanity
        let formValues = {...this.state.formValues};

        const requestTemplate = {
            date_range: [formValues.startDate, formValues.endDate],
            metric: 'views,redViews,estimatedMinutesWatched,estimatedRedMinutesWatched,subscribersGained,subscribersLost,estimatedRevenue,estimatedRedPartnerRevenue',
            dimension: 'month',
            query_by: 'tags',
            currency: 'EUR',
        };

        if (this.state.formValues.lifetime) {
            const startDate = '2010-01-01';
            const currDate = new Date();
            const endDate = currDate.getFullYear() + '-' + (currDate.getMonth() + 1 < 10 ? '0' : '') + (currDate.getMonth() + 1) + '-01';
            formValues = {...formValues, startDate, endDate};
        }

        console.log('form values: ', this.state.formValues);

        if (!this.state.selectedTags1 || !this.state.selectedTags2) {
            message.warning('Please select tags from the dropdown!');
            return;
        }

        this.setState({isRequestLoading: true});

        // console.log('Selected the following tags:');
        // console.log(`Tags 1: ${this.state.selectedTags1}, Tags 2: ${this.state.selectedTags2}`);

        // send requests based on selected tags
        let response1: YoutubeAnalyticsResponse;
        let response2: YoutubeAnalyticsResponse;

        try {
            const a = postRequest(
                '/api/graphsReporting/fetch',
                {
                    ...requestTemplate,
                    elements: this.state.selectedTags1.map((elem) => elem.toString()),
                },
                false
            );
            const b = postRequest(
                '/api/graphsReporting/fetch',
                {
                    ...requestTemplate,
                    elements: this.state.selectedTags2.map((elem) => elem.toString()),
                },
                false
            );
            const [res1, res2] = await Promise.all([a, b]); // this is sometimes slow for some reason
            // [response1, response2] = [a.data || [], b.data || []];
            [response1, response2] = [res1.data || [], res2.data || []];
        } catch (e: any) {
            notification.error({message: e.response.data.message});
            this.setState({isRequestLoading: false});
            return;
        }

        console.log('responses recieved: ', response1, response2);
        let acquiredAssetData1: string[] = [];
        let acquiredAssetData2: string[] = [];

        for (const cms in response1.body) {
            let newAssets = response1.body[cms].assoc_assets!;
            acquiredAssetData1 = acquiredAssetData1.concat(newAssets);
        }

        for (const cms in response2.body) {
            let newAssets = response2.body[cms].assoc_assets!;
            acquiredAssetData2 = acquiredAssetData2.concat(newAssets);
        }

        console.log('acquired asset data 1 & 2: ', acquiredAssetData1, acquiredAssetData2);

        // process the received youtube analytics data
        let [aggrReportResult1, aggrReportResult2] = roundUpAggrReportResultValuesComparative([response1, response2]);
        // console.log('final aggrReportResultComparative data: ', [aggrReportResult1, aggrReportResult2]);

        this.setState(
            {
                responseData1: JSON.parse(JSON.stringify(aggrReportResult1)),
                responseData2: JSON.parse(JSON.stringify(aggrReportResult2)),
                assetsData1: acquiredAssetData1,
                assetsData2: acquiredAssetData2,
            },
            () => {
                // console.log('this.state.responseData', this.state.responseData);

                if (this.state.formValues.cumulative === true) {
                    aggrReportResult1 = convertAggrReportResultToCumulative(aggrReportResult1);
                    aggrReportResult2 = convertAggrReportResultToCumulative(aggrReportResult2);
                }

                const graphData1 = constructGraphDataFromAggrReportResult(aggrReportResult1);
                const graphData2 = constructGraphDataFromAggrReportResult(aggrReportResult2);

                // console.log({ graphData1 });

                // const graphDataComparative = constructGraphDataComparative(graphData1, graphData2)

                this.setState({isRequestLoading: false, graphData1, graphData2});
            }
        );
    }

    toggleCumulativeOffline() {
        // console.log('toggleCumulativeOffline', this.state.formValues.cumulative, this.state.responseData);

        this.setState({isRequestLoading: true});

        let aggrReportResult1 = JSON.parse(JSON.stringify(this.state.responseData1));
        let aggrReportResult2 = JSON.parse(JSON.stringify(this.state.responseData2));
        if (this.state.formValues.cumulative) {
            aggrReportResult1 = convertAggrReportResultToCumulative(aggrReportResult1);
            aggrReportResult2 = convertAggrReportResultToCumulative(aggrReportResult2);
        }

        const graphData1 = constructGraphDataFromAggrReportResult(aggrReportResult1);
        const graphData2 = constructGraphDataFromAggrReportResult(aggrReportResult2);

        console.log({graphData1});

        // this.setState({isRequestLoading: false, graphData1, graphData2})
        this.setState({graphData1, graphData2}, () => {
            this.setState({isRequestLoading: false});
        });
    }

    getFunc = async () => {
        try {
            const response = await postRequest('/api/tags/getcategoryvaluesdrawer');
            console.log({response});

            this.setState({availableTags: response.data || []});
        } catch (e: any) {
            notification.error({message: e.response.data.message});
        }
    };

    render() {
        const selectWidth = '100%';
        const formItemSize = 'middle';

        const selectTag1 = (
            <Select
                mode={'multiple' as const}
                // mode="tags"
                size={formItemSize}
                placeholder='Please select'
                // defaultValue={[24, 25]}
                onChange={(values: number[]) => this.setState({selectedTags1: values})}
                // loading={this.props.isTagsLoading}
                showArrow
                showSearch
                style={{width: selectWidth}}
                maxTagCount={'responsive' as const}
                options={this.state.availableTags.map((tag: TagCategoryValueDrawer) => {
                    return {
                        value: tag.id,
                        // label: <Badge color={tag.color} text={tag.value + ' (' + tag.category_name + ')'}/>
                        label: tag.value + ' (' + tag.category_name + ')',
                    };
                })}
                filterOption={(input, option) => option?.label?.toString().toLowerCase().indexOf(input.toLowerCase())! >= 0}

                // optionFilterProp='label'
            >
                {/* {children} */}
                {/* {this.state.tags.map((tag: ResponseTag) => <Option value={tag.id} key={tag.id}>{tag.class}</Option>)} */}
            </Select>
        );

        const selectTag2 = (
            <Select
                mode={'multiple' as const}
                // mode="tags"
                size={formItemSize}
                placeholder='Please select'
                // defaultValue={[24, 25]}
                onChange={(values: number[]) => this.setState({selectedTags2: values})}
                // loading={this.props.isTagsLoading}
                showArrow
                showSearch
                style={{width: selectWidth}}
                maxTagCount={'responsive' as const}
                options={this.state.availableTags.map((tag: TagCategoryValueDrawer) => {
                    return {
                        value: tag.id,
                        // label: <Badge color={tag.color} text={tag.value + ' (' + tag.category_name + ')'}/>
                        label: tag.value + ' (' + tag.category_name + ')',
                    };
                })}
                filterOption={(input, option) => option?.label?.toString().toLowerCase().indexOf(input.toLowerCase())! >= 0}>
                {/* {children} */}
                {/* {this.state.tags.map((tag: ResponseTag) => <Option value={tag.id} key={tag.id}>{tag.class}</Option>)} */}
            </Select>
        );

        const inputForm = (
            <ConfigProvider locale={enUSIntl}>
                {/* <LightFilter */}
                {/* <QueryFilter */}
                <ProForm
                    onFinish={async (values: GraphFormValues) => {
                        // this.sendAuthorizedApiRequest(values)
                        // message.success("Success");
                        // if (values.startDate && values.endDate && values.dimension && values.contentOwner)
                        // console.log(values);
                    }}
                    submitter={{
                        searchConfig: {
                            resetText: 'Reset',
                            submitText: 'Submit',
                        },
                        render: false,
                    }}
                    validateMessages={{required: 'This field is required'}}
                    layout='inline'
                    onValuesChange={(changedValues: GraphFormValues, values: GraphFormValues) => {
                        // UPDATE TRIGGER LOGIC
                        // console.log('form', values, changedValues);

                        if (Object.keys(changedValues).length === 1 && changedValues.cumulative !== undefined && (this.state.responseData1 || this.state.responseData2)) {
                            this.setState({formValues: {...this.state.formValues, ...changedValues}}, () => this.toggleCumulativeOffline());
                            return;
                        }

                        // this.setState({ formValues: { ...this.state.formValues, ...changedValues } }, () => this.formHandler())
                        this.setState({
                            formValues: {...this.state.formValues, ...changedValues},
                        });
                    }}
                    size='middle'>
                    <ProFormSwitch name='cumulative' label='Cumulative'
                                   fieldProps={{checked: this.state.formValues.cumulative}} style={{flex: 1}}/>
                    <ProFormSwitch name='lifetime' label='Lifetime'
                                   fieldProps={{checked: this.state.formValues.lifetime}}/>
                    <ProFormDependency name={['lifetime']}>
                        {({lifetime}) => {
                            return (
                                <ProFormDateRangePicker
                                    width='md'
                                    name='monthRange'
                                    disabled={lifetime}
                                    rules={[{required: true}]}
                                    fieldProps={{
                                        picker: 'month',
                                        placeholder: ['Start month', 'End month'],
                                        value: [dayjs(this.state.formValues.startDate, 'YYYY-MM-DD'), dayjs(this.state.formValues.endDate, 'YYYY-MM-DD')],
                                    }}
                                    transform={(values: string[]) => {
                                        return {
                                            startDate: values[0].substring(0, 8) + '01' || undefined,
                                            endDate: values[1].substring(0, 8) + '01' || undefined,
                                        };
                                    }}
                                    style={{flex: 1}}
                                />
                            );
                        }}
                    </ProFormDependency>
                    <Button type='primary' size='middle' onClick={() => this.formHandler()}>
                        Run <SearchOutlined/>
                    </Button>
                </ProForm>
                {/* </QueryFilter> */}
                {/* </LightFilter> */}
            </ConfigProvider>
        );

        return (
            <div className='WhatIfReportingV2' style={this.style}>
                <PageContainer>
                    <Typography.Title level={2}>What-If Reporting</Typography.Title>

                    <Alert
                        message={<Text>Welcome to the What If Reporting Dashboard. Compare performance across selected
                            categories (tags) of channels.</Text>}
                        type='info'
                        closable
                    />

                    <br/>
                    <br/>

                    {this.state.isGapiLibraryLoading && (
                        <Row justify='center'>
                            <Spin spinning={true} tip='Library loading...'></Spin>
                        </Row>
                    )}
                    {!this.state.isGapiLibraryLoading && this.state.isAuthorizing && (
                        <Row justify='center'>
                            <Spin spinning={true} tip='Authorizing...'></Spin>
                        </Row>
                    )}

                    {!this.state.isGapiLibraryLoading && this.state.isAuthorized && (
                        <>
                            <Spin spinning={this.state.isRequestLoading} tip='Loading...'>
                                <Row gutter={32}>
                                    <Col span={24}>
                                        <Card>
                                            <Space direction='vertical' style={{width: selectWidth}}>
                                                <Text>Select query parameters: </Text>
                                                {inputForm}
                                            </Space>
                                        </Card>
                                    </Col>
                                </Row>

                                <Divider/>
                                {/* <Space direction='horizontal' > */}
                                <Row gutter={32}>
                                    <Col span={12}>
                                        <Card>
                                            <Space direction='vertical' style={{width: selectWidth}}>
                                                <Text>Select first category: </Text>
                                                {selectTag1}
                                                {this.state.assetsData1 &&
                                                <Text type='secondary'>{this.state.assetsData1.length || 0} associated
                                                    assets found.</Text>}
                                            </Space>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card>
                                            <Space direction='vertical' style={{width: selectWidth}}>
                                                <Text>Select second category: </Text>
                                                {selectTag2}
                                                {this.state.assetsData2 &&
                                                <Text type='secondary'>{this.state.assetsData2.length || 0} associated
                                                    assets found.</Text>}
                                            </Space>
                                        </Card>
                                    </Col>
                                </Row>

                                <Divider/>

                                {/* {inputForm} */}
                                {/* </Space> */}
                                {/*{this.state.isRequestLoading == false ? <>*/}

                                {this.state.graphData1 && this.state.graphData2 && !this.state.isRequestLoading && (
                                    <>
                                        <Divider/>
                                        {/* <br /> */}

                                        <Row
                                            // justify='space-between' style={{ backgroundColor: '#F0F2F5', padding: '16px 32px 15px 32px' }}
                                        >
                                            <Col span={12}>
                                                <Row
                                                    justify='space-between'
                                                    style={{
                                                        backgroundColor: '#F0F2F5',
                                                        padding: '16px 32px 15px 32px',
                                                    }}>
                                                    <Card>
                                                        <Statistic title='Views'
                                                                   value={this.state.responseData1?.views.sum.value}
                                                                   suffix={this.state.responseData1?.views.sum.unit}/>
                                                    </Card>
                                                    <Card>
                                                        <Statistic
                                                            title='Watch Time'
                                                            value={this.state.responseData1?.watchTime.sum.value}
                                                            suffix={this.state.responseData1?.watchTime.sum.unit}
                                                        />
                                                    </Card>
                                                    <Card>
                                                        <Statistic
                                                            title='Subscribers'
                                                            value={this.state.responseData1?.subscribers.sum.value}
                                                            suffix={this.state.responseData1?.subscribers.sum.unit}
                                                        />
                                                    </Card>
                                                    <Card>
                                                        <Statistic
                                                            title='Est. Revenue'
                                                            value={this.state.responseData1?.revenue.sum.value}
                                                            prefix={defaultRevenueCurrency}
                                                            suffix={this.state.responseData1?.revenue.sum.unit}
                                                            precision={2}
                                                        />
                                                    </Card>
                                                </Row>
                                            </Col>

                                            <Col span={12}>
                                                <Row
                                                    justify='space-between'
                                                    style={{
                                                        backgroundColor: '#F0F2F5',
                                                        padding: '16px 32px 15px 32px',
                                                    }}>
                                                    <Card>
                                                        <Statistic title='Views'
                                                                   value={this.state.responseData2?.views.sum.value}
                                                                   suffix={this.state.responseData2?.views.sum.unit}/>
                                                    </Card>
                                                    <Card>
                                                        <Statistic
                                                            title='Watch Time'
                                                            value={this.state.responseData2?.watchTime.sum.value}
                                                            suffix={this.state.responseData2?.watchTime.sum.unit}
                                                        />
                                                    </Card>
                                                    <Card>
                                                        <Statistic
                                                            title='Subscribers'
                                                            value={this.state.responseData2?.subscribers.sum.value}
                                                            suffix={this.state.responseData2?.subscribers.sum.unit}
                                                        />
                                                    </Card>
                                                    <Card>
                                                        <Statistic
                                                            title='Est. Revenue'
                                                            value={this.state.responseData2?.revenue.sum.value}
                                                            prefix={defaultRevenueCurrency}
                                                            suffix={this.state.responseData2?.revenue.sum.unit}
                                                            precision={2}
                                                        />
                                                    </Card>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <br/>
                                        <br/>
                                        <Row wrap gutter={32} style={{marginBottom: 64}}>
                                            <Col span={12}>
                                                {/*<DashboardReportingGraphV2 metric='views' label='Views'*/}
                                                {/*                           unit={this.state.graphData1?.views.unit!}*/}
                                                {/*                           dimension='month'*/}
                                                {/*                           data={this.state.graphData1?.views.data}*/}
                                                {/*                           cumulative={this.state.formValues.cumulative!}/>*/}
                                                <DashboardReportingGraphV2Comparative
                                                    metric='views'
                                                    label='Views'
                                                    unit={this.state.graphData1?.views.unit!}
                                                    dimension='month'
                                                    data1={this.state.graphData1?.views.data}
                                                    data2={this.state.graphData2?.views.data}
                                                    cumulative={this.state.formValues.cumulative!}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                {/*<DashboardReportingGraphV2 metric='views' label='Views'*/}
                                                {/*                           unit={this.state.graphData2?.views.unit!}*/}
                                                {/*                           dimension='month'*/}
                                                {/*                           data={this.state.graphData2?.views.data}*/}
                                                {/*                           cumulative={this.state.formValues.cumulative!}/>*/}
                                                <DashboardReportingGraphV2Comparative
                                                    metric='subscribers'
                                                    label='Subscribers'
                                                    unit={this.state.graphData1?.subscribers.unit!}
                                                    dimension='month'
                                                    data1={this.state.graphData1?.subscribers.data}
                                                    data2={this.state.graphData2?.subscribers.data}
                                                    cumulative={this.state.formValues.cumulative!}
                                                />
                                            </Col>
                                        </Row>
                                        <Row wrap gutter={32} style={{marginBottom: 64}}>
                                            <Col span={12}>
                                                {/*<DashboardReportingGraphV2 metric='subscribers' label='Subscribers'*/}
                                                {/*                           unit={this.state.graphData1?.subscribers.unit!}*/}
                                                {/*                           dimension='month'*/}
                                                {/*                           data={this.state.graphData1?.subscribers.data}*/}
                                                {/*                           cumulative={this.state.formValues.cumulative!}/>*/}
                                                <DashboardReportingGraphV2Comparative
                                                    metric='watchTime'
                                                    label='Watch Time'
                                                    unit={this.state.graphData1?.watchTime.unit!}
                                                    dimension='month'
                                                    data1={this.state.graphData1?.watchTime.data}
                                                    data2={this.state.graphData2?.watchTime.data}
                                                    cumulative={this.state.formValues.cumulative!}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                {/*<DashboardReportingGraphV2 metric='subscribers' label='Subscribers'*/}
                                                {/*                           unit={this.state.graphData2?.subscribers.unit!}*/}
                                                {/*                           dimension='month'*/}
                                                {/*                           data={this.state.graphData2?.subscribers.data}*/}
                                                {/*                           cumulative={this.state.formValues.cumulative!}/>*/}
                                                <DashboardReportingGraphV2Comparative
                                                    metric='revenue'
                                                    label='Revenue'
                                                    unit={this.state.graphData2?.revenue.unit!}
                                                    dimension='month'
                                                    data1={this.state.graphData1?.revenue.data}
                                                    data2={this.state.graphData2?.revenue.data}
                                                    cumulative={this.state.formValues.cumulative!}
                                                />
                                            </Col>
                                        </Row>
                                        {/*<Row wrap gutter={32} style={{marginBottom: 64}}>*/}
                                        {/*    <Col span={12}>*/}
                                        {/*        <DashboardReportingGraphV2 metric='watchTime' label='Watch Time'*/}
                                        {/*                                   unit={this.state.graphData1?.watchTime.unit!}*/}
                                        {/*                                   dimension='month'*/}
                                        {/*                                   data={this.state.graphData1?.watchTime.data}*/}
                                        {/*                                   cumulative={this.state.formValues.cumulative!}/>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col span={12}>*/}
                                        {/*        <DashboardReportingGraphV2 metric='watchTime' label='Watch Time'*/}
                                        {/*                                   unit={this.state.graphData2?.watchTime.unit!}*/}
                                        {/*                                   dimension='month'*/}
                                        {/*                                   data={this.state.graphData2?.watchTime.data}*/}
                                        {/*                                   cumulative={this.state.formValues.cumulative!}/>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<Row wrap gutter={32} style={{marginBottom: 64}}>*/}
                                        {/*    <Col span={12}>*/}
                                        {/*        <DashboardReportingGraphV2 metric='revenue' label='Revenue'*/}
                                        {/*                                   unit={this.state.graphData1?.revenue.unit!}*/}
                                        {/*                                   dimension='month'*/}
                                        {/*                                   data={this.state.graphData1?.revenue.data}*/}
                                        {/*                                   cumulative={this.state.formValues.cumulative!}/>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col span={12}>*/}
                                        {/*        <DashboardReportingGraphV2 metric='revenue' label='Revenue'*/}
                                        {/*                                   unit={this.state.graphData2?.revenue.unit!}*/}
                                        {/*                                   dimension='month'*/}
                                        {/*                                   data={this.state.graphData2?.revenue.data}*/}
                                        {/*                                   cumulative={this.state.formValues.cumulative!}/>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*TEST*/}
                                        {/*<DashboardReportingGraphV2Comparative metric='revenue' label='Revenue'*/}
                                        {/*                                      unit={this.state.graphData2?.revenue.unit!}*/}
                                        {/*                                      dimension='month'*/}
                                        {/*                                      data1={this.state.graphData1?.revenue.data}*/}
                                        {/*                                      data2={this.state.graphData2?.revenue.data}*/}
                                        {/*                                      cumulative={this.state.formValues.cumulative!}/>*/}
                                        {/*TEST*/}
                                    </>
                                )}
                                {/*</> : null}*/}
                            </Spin>
                        </>
                    )}

                    {/* <Button onClick={this.reportHandler}>
						Generate analytics report
					</Button>
					<Card>
						{this.state.report && JSON.stringify(this.state.report)}
					</Card> */}
                </PageContainer>
            </div>
        );
    }
}

export default WhatIfReportingV2;
