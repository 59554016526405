import {atomWithHash} from "jotai-location";
import dayjs from "dayjs";
import {atom,} from "jotai";
import {loadable} from "jotai/utils";
import axiosApiInstance from "../../../../api/axiosClient";
import {atomWithRefresh} from "../../../../common/atom_utils";

export type ViewType = 'month' | 'year' | 'ranking' | 'channels';
export const viewTypeAtom = atomWithHash<ViewType>('viewType', 'month');
export const yearAtom = atomWithHash<number>('year', dayjs().subtract(1, 'month').year());
export const monthAtom = atomWithHash<number>('month', dayjs().subtract(1, 'month').month() + 1);
export const showMonthModalAtom = atomWithHash('showMonthModal', false);
export const showCompareCategoriesModalAtom = atomWithHash('showCompareCategoriesModal', false);
export const monthModalSelectedChannelsAtom = atomWithHash<string[]>('monthModalSelectedChannels', []);
export const compareCategoriesModalSelectedCategoriesAtom = atomWithHash<number[]>('compareCategoriesModalSelectedCategories', []);
export const selectedChannelMetricAtom = atomWithHash<string>('selectedChannelMetric', 'Total Subscribers (Lifetime)');
export const selectedChannelMetricObjAtom = atom<any>(async (get) => {
    const channelMetrics = await get(channelMetricsAtom);
    return channelMetrics.find((metric) => metric.data_index === get(selectedChannelMetricAtom));
});

export const yFieldAtom = atom(async (get) => (await get(selectedChannelMetricObjAtom))?.json_index);


export const rankedChannelsAtom = atom<Promise<API.Benchmarks.YouTubeRankingView[]>>(async (get) => {
    const response = await axiosApiInstance.get(`/api/benchmarks/youtube/ranking`);
    // console.log(response.data)
    return response.data;
});

export const channelMetricsAtom = atom(async (get) => {
    const response = await axiosApiInstance.get(`/api/benchmarks/youtube/metrics`);
    // console.log(response.data)
    return response.data;
});

export const monthlyChartDataAtom = atom(async (get) => {
    const sc = get(monthModalSelectedChannelsAtom);
    const sm = get(selectedChannelMetricAtom);
    const response = await axiosApiInstance.get(`/api/benchmarks/youtube/monthly_channel_data`,
        {params: {selectedChannelIDs: sc, selectedMetric: sm}});
    // console.log(response.data)
    return response.data?.sort  ((a: any, b: any) => dayjs(a.date).unix() - dayjs(b.date).unix()) || [];
});

export const loadableMonthlyChartDataAtom = loadable(monthlyChartDataAtom)

export const channelsAtom = atomWithRefresh<Promise<API.Benchmarks.YouTubeBenchmarksHarvestChannel[]>>(async (get) => {
    const categoryIDs = get(filterChannelCategoriesAtom);
    const response = await axiosApiInstance.get(`/api/benchmarks/youtube/channels`, {params: {filter_category_ids: categoryIDs}});
    // console.log(response.data)
    return response.data;
});

export const channelCategoriesAtom = atomWithRefresh<Promise<API.Benchmarks.YouTubeBenchmarksChannelCategory[]>>(async (get) => {
    const response = await axiosApiInstance.get(`/api/benchmarks/youtube/channel_categories`);
    // console.log(response.data)
    return response.data;
});

export const filterChannelCategoriesAtom = atomWithHash<number[]>('filterChannelCategories', []);

export const filterSearchAtom = atomWithHash<string | undefined>('filterSearch', undefined);

export const searchFilteredChannelsAtom = atom(async (get) => {
    const search = get(filterSearchAtom);
    const channels = await get(channelsAtom);
    if (!search) {
        return channels;
    }
    return channels.filter((channel: any) => channel.name.toLowerCase().includes(search.toLowerCase()));
});


export const monthlyViewsAtom = atomWithRefresh<Promise<{
    data: API.Benchmarks.YouTubeMonthlyView[];
    avg: API.Benchmarks.YouTubeMonthlyView
}>>(async (get) => {
    const categoryIDs = get(filterChannelCategoriesAtom);
    const year = get(yearAtom);
    const month = get(monthAtom);
    const hideNegativeData = get(hideNegativeDataAtom);
    const response = await axiosApiInstance.get(`/api/benchmarks/youtube/monthly`, {
        params: {
            year,
            month,
            filter_category_ids: categoryIDs,
            hide_negative_data: hideNegativeData
        }
    });
    // console.log(response.data)
    return response.data;
});

export const yearlyViewsAtom = atomWithRefresh<Promise<{
    data: API.Benchmarks.YouTubeYearlyView[];
    avg: API.Benchmarks.YouTubeYearlyView
}>>(async (get) => {
    const categoryIDs = get(filterChannelCategoriesAtom);
    const year = get(yearAtom);
    const hideNegativeData = get(hideNegativeDataAtom);
    const response = await axiosApiInstance.get(`/api/benchmarks/youtube/yearly`, {
        params: {
            year,
            filter_category_ids: categoryIDs,
            hide_negative_data: hideNegativeData
        }
    });
    // console.log(response.data)
    return response.data;
});

export const rankingViewsAtom = atomWithRefresh<Promise<API.Benchmarks.YouTubeRankingView[]>>(async (get) => {
    const categoryIDs = get(filterChannelCategoriesAtom);
    const response = await axiosApiInstance.get(`/api/benchmarks/youtube/ranking`, {
        params: {
            filter_category_ids: categoryIDs
        }
    });
    return response.data;
});

export const monthModalFilterSearchAtom = atomWithHash<string | undefined>('monthModalFilterSearch', undefined);

export const monthModalSearchFilteredChannelsAtom = atom(async (get) => {
    const rankedChannels = await get(rankedChannelsAtom);
    const monthModalSelectedChannels = get(monthModalSelectedChannelsAtom);
    const search = get(monthModalFilterSearchAtom);
    // console.log(search, monthModalSelectedChannels, rankedChannels)
    if (!search) {
        return rankedChannels
    }
    const filteredChannels = [
        // first the selected
        ...monthModalSelectedChannels?.map((channelID: string) => rankedChannels.find((channel) => channel.channelID === channelID)),
        // then the searched, but only if not already selected
        ...rankedChannels.filter((channel) => channel.channelName?.toLowerCase().includes(search?.toLowerCase()) && !monthModalSelectedChannels.includes(channel.channelID))
    ]
    return filteredChannels
});

export const compareCategoriesChartDataAtom = atom(async (get) => {
    const sc = get(compareCategoriesModalSelectedCategoriesAtom);
    const sm = get(selectedChannelMetricAtom);
    const response = await axiosApiInstance.get(`/api/benchmarks/youtube/monthly_data_by_category`,
        {params: {selectedCategoryIDs: sc, selectedMetric: sm}});
    console.log(response.data)
    return response.data?.sort((a: any, b: any) => dayjs(a.date).unix() - dayjs(b.date).unix());
});

export const loadableCompareCategoriesChartDataAtom = loadable(compareCategoriesChartDataAtom)

export const hideNegativeDataAtom = atomWithHash<boolean>('hideNegativeData', false);