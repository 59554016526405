import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {Col, Divider, Form, InputRef, notification, Row, Space, Tag, TreeSelect} from 'antd';
import {useNavigate, useParams} from "react-router-dom";
import {
    ModalForm,
    ProFormDigit,
    ProFormGroup,
    ProFormInstance,
    ProFormList,
    ProFormSelect,
    ProFormText,
    ProFormTextArea
} from "@ant-design/pro-components";
import {PageLoading} from "@ant-design/pro-layout";
import axiosApiInstance from "../../../api/axiosClient";
import {GlobalStateContext} from "../../../context/GlobalContext";
import type {DraggableData, DraggableEvent} from 'react-draggable';
import {useAtom, useAtomValue} from "jotai";
// import {creatorCategoriesAtom, creatorsAtom, creatorTagsAtom, tagOptionsAtom} from "./atoms";
import {creatorAtom, creatorTagsAtomById,} from "./atoms";
import {queryClientAtom} from "jotai-tanstack-query";
import {ProFormTreeSelect} from "@ant-design/pro-form/lib";
import {getPaletteColor} from "./utils";
import {generate} from "@ant-design/colors";
// import TagOptions = API.Creators.TagOptions;

type Props = {
    editable: boolean
};

export type TagCategoryValue = {
    label: string;
    value: string;
    checked: boolean;
    color: string;
}

export type TagCategory = {
    label: string;
    value: string;
    checkable: boolean;
    allowMultiple: boolean;
    children: TagCategoryValue[];
}

// export type TagOptions = {
//     label: string;
//     value: number;
//     color: string;
//     checkable: boolean;
//     children: TagOptions[];
// }

// Extract initially selected values from tagOptions
export const getInitialSelectedValues = (options: TagCategory[]): string[] => {

    if (!options) {
        return []
    }

    const selectedValues: string[] = [];
    options.forEach(category => {
        category.children.forEach(child => {
            if (child.checked) {
                selectedValues.push(child.value);
            }
        });
    });
    // console.log({selectedValues})
    return selectedValues;
};

const CreatorViewEdit = (props: Props) => {
    const [form] = Form.useForm<API.Creators.Creator>();
    const navigate = useNavigate();
    const routerParams = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const formRef = useRef<ProFormInstance>();
    const inputRef = useRef<InputRef>(null);
    // const categoriesRef = useRef<BaseSelect>(null);
    // const [categoriesData, setCategoriesData] = useState<API.Creators.CreatorContentCategory[] | undefined>([]);
    const {
        employees,
    } = useContext(GlobalStateContext);
    // watch for changes of managed_by_employee_id field
    // const managed_by_employee_id = Form.useWatch('managed_by_employee_id', form);
    const queryClient = useAtomValue(queryClientAtom);
    const {id} = useParams();
    // const [{data: creators}] = useAtom(creatorsAtom);
    const [{data: tagOptions}] = useAtom(creatorTagsAtomById(id));
    const [{data: creator}] = useAtom(creatorAtom(id));
    // const [tagOptions, setTagOptions] = useState([])

    const {SHOW_PARENT} = TreeSelect;


    useEffect(() => {
        setFormData()
    }, [id]);

    const setFormData = () => {
        form.setFieldsValue(creator)
    }

    const [bounds, setBounds] = useState({left: 0, top: 0, bottom: 0, right: 0});
    const draggleRef = useRef<HTMLDivElement>(null);
    const [disabled, setDisabled] = useState(true);

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const {clientWidth, clientHeight} = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    const [timeoutId, setTimeoutId] = useState(null);

    const handleValidationYTVideo = (rule, value): Promise<void | any> => {
        if (value) {
            if (value < 200) {
                return Promise.reject("Price seems lower than usual..");
            } else if (value > 30000) {
                return Promise.reject("Price seems higher than usual..");
            } else {
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }
    };

    const handleValidationTTVideo = (rule, value): Promise<void | any> => {

        // Your validation logic here
        if (value) {
            if (value < 40) {
                // callback("Price seems lower than usual..");
                return Promise.reject("Price seems lower than usual..");
            } else if (value > 8000) {
                // callback("Price seems higher than usual..");
                return Promise.reject("Price seems higher than usual..");
            } else {
                // callback();
                return Promise.resolve();
            }
        } else {
            // callback();
            return Promise.resolve();
        }

    };

    const handleValidationIGReel = (rule, value) => {
        if (value) {
            if (value < 40) {
                return Promise.reject("Price seems lower than usual..");
            } else if (value > 8000) {
                return Promise.reject("Price seems higher than usual..");
            } else {
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }
    };

    const handleValidationIGPost = (rule, value) => {
        if (value) {
            if (value < 20) {
                return Promise.reject("Price seems lower than usual..");
            } else if (value > 5000) {
                return Promise.reject("Price seems higher than usual..");
            } else {
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }

    };

    const handleValidationIGStory = (rule, value) => {
        if (value) {
            if (value < 20) {
                return Promise.reject("Price seems lower than usual..");
            } else if (value > 2000) {
                return Promise.reject("Price seems higher than usual..");
            } else {
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }
    };

    const [selectedValues, setSelectedValues] = useState<string[]>(getInitialSelectedValues(tagOptions));

    const handleChange = (values: string[]) => {
        let newSelectedValues = [...values];

        tagOptions.forEach(category => {
            if (!category.allowMultiple) {
                const selectedChildren = newSelectedValues.filter(value =>
                    category.children.some(child => child.value === value)
                );
                if (selectedChildren.length > 1) {
                    // Keep only the most recently selected child
                    const lastSelectedChild = selectedChildren.pop();
                    newSelectedValues = newSelectedValues.filter(value =>
                        !category.children.some(child => child.value === value)
                    );
                    if (lastSelectedChild !== undefined) {
                        newSelectedValues.push(lastSelectedChild);
                    }
                }
            }
        });
        // console.log({newSelectedValues})
        setSelectedValues(newSelectedValues);
    };

    const tagRender = (props: any) => {
        const {label, value, closable, onClose} = props;

        const findColor = (options: TagCategory[], value: string): string | undefined => {
            for (const option of options) {
                if (option.value === value) return undefined;
                const found = option.children.find(child => child.value === value);
                if (found) return found.color;
            }
            return undefined;
        };

        const tagColor = findColor(tagOptions, value);
        if (!tagColor) return null;
        const palette = tagColor ? generate(tagColor) : [];

        return (
            <Tag
                color={tagColor}
                closable={closable}
                onClose={onClose}
                style={{marginRight: 3, color: getPaletteColor(tagColor, palette)}} // Apply text color
            >
                {label}
            </Tag>
        );
    };

    return (
        <ModalForm<API.Creators.Creator>
            open
            form={form}
            // formRef={formRef}
            autoFocusFirstInput
            width={props.editable ? 1000 : undefined}
            submitter={props.editable ? {} : false}
            // submitter={{render: (_, dom) => props.editable ? dom : null}}
            onVisibleChange={(visible) => {
                if (visible) setFormData();
            }}
            modalProps={{
                destroyOnClose: true,
                okText: 'Save',
                okButtonProps: {hidden: !props.editable, loading: loading},
                onCancel: () => navigate(`/crm/creators/list`),
            }}
            onFinish={async (formData) => {
                console.log(formData);
                // return true
                return axiosApiInstance.put(`/api/creators/items/${routerParams.id}`, formData)
                    .then(() => {
                        notification.success({message: 'Success'});
                        queryClient.invalidateQueries({queryKey: ['creators']});
                        return true;
                    })
                    .catch((error) => {
                        notification.error({message: 'Error'});
                        console.error(error);
                        return false;
                    });
            }}
            validateMessages={{required: "${label} is required!"}}
            readonly={!props.editable}
            requiredMark={props.editable}
        >

            {loading ? <PageLoading/> :
                <>
                    <ProFormDigit
                        name="id"
                        hidden={true}
                    />
                    <ProFormGroup>
                        <ProFormText
                            width="lg"
                            name="creator"
                            label="Creator"
                            rules={[{required: true}]}
                        />
                    </ProFormGroup>

                    <ProFormGroup>
                        <ProFormSelect
                            width="xs"
                            // wrapperCol={{style: {width: 90}}}
                            name="main_platform_id"
                            label="Main Platform"
                            options={[{label: 'YouTube', value: 1}, {label: 'Instagram', value: 2}, {
                                label: 'TikTok',
                                value: 3
                            }, {label: 'Unknown', value: 4}]}
                            rules={[{required: true}]}
                        />

                        <ProFormTreeSelect
                            width={"lg"}
                            label={"Tags"}
                            disabled={!props.editable}
                            name="tags"
                            normalize={(value, prevValue, allValues) => {
                                // console.log({value, prevValue, allValues})
                                return value.map(val => ({
                                    tag_category_id: parseInt(val.split(",")[0]),
                                    tag_category_value_id: parseInt(val.split(",")[1])
                                }))
                            }}
                            fieldProps={{
                                showCheckedStrategy: SHOW_PARENT,
                                multiple: true,
                                tagRender: (props) => tagRender(props),
                                treeCheckable: true, // This will make selected items display as tags
                                value: selectedValues,
                                onChange: handleChange,
                                treeData: tagOptions,
                                // disabled: !props.editable,
                            }}
                        />
                    </ProFormGroup>


                    <ProFormGroup>
                        <ProFormTextArea
                            width="md"
                            name="reason"
                            label="Comments"
                            // rules={[{required: true}]}
                        />
                    </ProFormGroup>

                    <Divider/>

                    <ProFormGroup title={"Social Media Accounts"} titleStyle={{font: "unset"}}>
                        <ProFormList name="accounts"
                            // label={'Social Media Accounts'}
                                     copyIconProps={props.editable ? {} : false}
                                     deleteIconProps={props.editable ? {} : false}
                                     creatorButtonProps={props.editable ? {creatorButtonText: 'Add account'} : false}
                                     actionRender={(field, action, defaultActionDom) => [
                                         ...defaultActionDom,
                                         // !props.editable && <Tooltip title={'Fetch follower count'}>
                                         //     <Button icon={<DownloadOutlined/>} type={"link"}
                                         //             onClick={() => updateAccountFollowerCount(field)}/>
                                         // </Tooltip>
                                     ]}
                        >
                            {(f, index, action) => {
                                return (
                                    <ProFormGroup>
                                        <ProFormDigit
                                            name="id"
                                            hidden={true}
                                        />

                                        <ProFormDigit
                                            name="creator_id"
                                            hidden={true}
                                        />

                                        <ProFormSelect
                                            // width="xs"
                                            wrapperCol={{style: {width: 70}}}
                                            name="platform_id"
                                            label="Platform"
                                            // valueEnum={
                                            //     {
                                            //         1: {
                                            //             text: 'YouTube',
                                            //         }
                                            //     }}
                                            options={[{label: 'YouTube', value: 1}, {
                                                label: 'Instagram',
                                                value: 2
                                            }, {label: 'TikTok', value: 3},]}
                                            rules={[{required: true}]}
                                        />


                                        <ProFormText
                                            // width={"md"}
                                            wrapperCol={{style: {width: 430}}}
                                            label="Key"
                                            name="key"
                                        />

                                        <ProFormDigit
                                            name="following"
                                            label={'Following'}
                                            fieldProps={{precision: 0}}
                                            // width={"xs"}
                                            wrapperCol={{style: {width: 80}}}
                                            readonly={true}
                                        />

                                        {/*<ProFormSwitch*/}
                                        {/*    name="auto_fetch_followers"*/}
                                        {/*    label={'Auto Fetch Followers'}*/}
                                        {/*    // width={"xs"}*/}
                                        {/*    hidden={!props.editable}*/}
                                        {/*    wrapperCol={{style: {width: 150}}}*/}
                                        {/*/>*/}


                                    </ProFormGroup>
                                );
                            }}
                        </ProFormList>
                    </ProFormGroup>

                    <Divider/>

                    <ProFormGroup title={"Pricings"} titleStyle={{font: "unset"}}>

                        <Row justify="space-between">
                            <Space wrap size={24}>
                                <Col>
                                    <ProFormDigit
                                        name={["pricing", "youtube_video"]}
                                        label="Price YouTube Video"
                                        fieldProps={{precision: 0}}
                                        width={"sm"}
                                        rules={[
                                            {
                                                validator: (rule, value) => {
                                                    return handleValidationYTVideo(rule, value);
                                                },
                                                type: "number",
                                                warningOnly: true,
                                                required: true,
                                            },
                                        ]}
                                    />
                                </Col>

                                <Col>
                                    <ProFormDigit
                                        name={["pricing", "tiktok_video"]}
                                        label="Price TikTok Video"
                                        fieldProps={{precision: 0}}
                                        width={"sm"}
                                        rules={[
                                            {
                                                // validator: (rule, value, callback) => {
                                                //     handleValidationTTVideo(rule, value, callback);
                                                // },
                                                validator: (rule, value) => {
                                                    return handleValidationTTVideo(rule, value);
                                                },
                                                type: "number",
                                                warningOnly: true,
                                                required: true,
                                            },
                                        ]}
                                    />
                                </Col>
                            </Space>
                        </Row>

                        <Row justify="space-evenly" style={{width: '100%'}}>
                            <Space wrap size={24}>
                                <Col>
                                    <ProFormDigit
                                        name={["pricing", "instagram_reel"]}
                                        label="Price Instagram Reel"
                                        fieldProps={{precision: 0}}
                                        width={"sm"}
                                        rules={[
                                            {
                                                validator: (rule, value) => {
                                                    return handleValidationIGReel(rule, value);
                                                },
                                                type: "number",
                                                warningOnly: true,
                                                required: true,
                                            },
                                        ]}
                                    />
                                </Col>

                                <Col>
                                    <ProFormDigit
                                        name={["pricing", "instagram_post"]}
                                        label="Price Instagram Post"
                                        fieldProps={{precision: 0}}
                                        width={"sm"}
                                        rules={[
                                            {
                                                validator: (rule, value) => {
                                                    return handleValidationIGPost(rule, value);
                                                },
                                                type: "number",
                                                warningOnly: true,
                                                required: true,
                                            },
                                        ]}
                                    />
                                </Col>

                                <Col>
                                    <ProFormDigit
                                        name={["pricing", "instagram_story"]}
                                        label="Price Instagram Story"
                                        fieldProps={{precision: 0}}
                                        width={"sm"}
                                        rules={[
                                            {
                                                validator: (rule, value) => {
                                                    return handleValidationIGStory(rule, value);
                                                },
                                                type: "number",
                                                warningOnly: true,
                                                required: true,
                                            },
                                        ]}
                                    />
                                </Col>
                            </Space>
                        </Row>

                    </ProFormGroup>

                </>
            }

        </ModalForm>
    );
};

export default CreatorViewEdit;