import * as React from 'react';
import dayjs from "dayjs";
import XLSX from "sheetjs-style-v2";
import {useAtom, useAtomValue} from "jotai";
import {
    channelsAtom,
    filterAccountTypeAtom,
    monthAtom,
    monthlyViewsAtom,
    rankingViewsAtom,
    yearAtom,
    yearlyViewsAtom
} from "./atoms";
import {Button, Tooltip} from "antd";
import {FileExcelOutlined} from "@ant-design/icons";

type Props = {};


export const ExportToExcelMonth = (props: Props) => {
    const [year, setYear] = useAtom(yearAtom);
    const [month, setMonth] = useAtom(monthAtom);
    const [{data: monthlyViews}, refreshMonthlyViews] = useAtom(monthlyViewsAtom)
    const {data: accounts} = useAtomValue(channelsAtom)
    const [filterAccountType, setFilterAccountType] = useAtom(filterAccountTypeAtom)

    const buildAndDownloadXLSX = () => {

        let data: any
        let title: string = 'TikTok_Benchmarks_'

        if (filterAccountType === 'BRAND') {
            title += `Brand_Accounts_`
        } else if (filterAccountType === 'CREATOR') {
            title += `Creator_Accounts_`
        }

        data = [...monthlyViews.data.map((item) => {
            const name = accounts.find((a) => a.userID === item.userID)?.name
            return {...item, name}
        }),
            {
                ...monthlyViews.avg,
                name: 'Average',
                userID: '',
            }]

        title += `Month_${dayjs(month.toString(), 'M').format('MMM')}_${year}.xlsx`

        //
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {dateNF: 'dd/mm/yyyy', cellDates: true});
        // const wb: XLSX.WorkBook = {Sheets: {[viewType]: ws}, SheetNames: [capitalize(viewType)]};
        const wb: XLSX.WorkBook = {Sheets: {Report: ws}, SheetNames: ['Report']};
        XLSX.writeFile(wb, title, {
            bookType: 'xlsx',
            type: 'array'
        });

    };

    return (
        <Tooltip title={'Export to Excel'}>
            <Button
                size={'large'}
                type={'dashed'}
                icon={<FileExcelOutlined style={{color: '#575757'}}/>}
                onClick={() => {
                    buildAndDownloadXLSX();
                }}
            />
        </Tooltip>
    );
};


export const ExportToExcelYear = (props: Props) => {
    const [year, setYear] = useAtom(yearAtom);
    const [{data: yearlyViews}, refreshYearlyViews] = useAtom(yearlyViewsAtom)
    const [filterAccountType, setFilterAccountType] = useAtom(filterAccountTypeAtom)

    const buildAndDownloadXLSX = () => {
        let data: any
        let title: string = 'TikTok_Benchmarks_'

        if (filterAccountType === 'BRAND') {
            title += `Brand_Accounts_`
        } else if (filterAccountType === 'CREATOR') {
            title += `Creator_Accounts_`
        }

        data = [...yearlyViews.data,
            {
                ...yearlyViews.avg,
                month: 'Average',
            }]

        title += `Year_${year}.xlsx`

        //
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {dateNF: 'dd/mm/yyyy', cellDates: true});
        // const wb: XLSX.WorkBook = {Sheets: {[viewType]: ws}, SheetNames: [capitalize(viewType)]};
        const wb: XLSX.WorkBook = {Sheets: {Report: ws}, SheetNames: ['Report']};
        XLSX.writeFile(wb, title, {
            bookType: 'xlsx',
            type: 'array'
        });

    };

    return (
        <Tooltip title={'Export to Excel'}>
            <Button
                size={'large'}
                type={'dashed'}
                icon={<FileExcelOutlined style={{color: '#575757'}}/>}
                onClick={() => {
                    buildAndDownloadXLSX();
                }}
            />
        </Tooltip>
    );
};

export const ExportToExcelRanking = (props: Props) => {
    const [{data: rankingViews}, refreshRankingViews] = useAtom(rankingViewsAtom)
    const {data: accounts} = useAtomValue(channelsAtom)
    const [filterAccountType, setFilterAccountType] = useAtom(filterAccountTypeAtom)

    const buildAndDownloadXLSX = () => {

        let data: any
        let title: string = 'TikTok_Benchmarks_'

        if (filterAccountType === 'BRAND') {
            title += `Brand_Accounts_`
        } else if (filterAccountType === 'CREATOR') {
            title += `Creator_Accounts_`
        }

        data = rankingViews.map((item, index) => {
            const name = accounts.find((a) => a.userID === item.userID)?.name
            return {
                ...item,
                name,
                rank: index + 1,
            }
        })
        title += `Ranking_${dayjs().format('DD_MM_YYYY')}.xlsx`

        //
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {dateNF: 'dd/mm/yyyy', cellDates: true});
        // const wb: XLSX.WorkBook = {Sheets: {[viewType]: ws}, SheetNames: [capitalize(viewType)]};
        const wb: XLSX.WorkBook = {Sheets: {Report: ws}, SheetNames: ['Report']};
        XLSX.writeFile(wb, title, {
            bookType: 'xlsx',
            type: 'array'
        });

    };

    return (
        <Tooltip title={'Export to Excel'}>
            <Button
                size={'large'}
                type={'dashed'}
                icon={<FileExcelOutlined style={{color: '#575757'}}/>}
                onClick={() => {
                    buildAndDownloadXLSX();
                }}
            />
        </Tooltip>
    );
};

export const ExportToExcelChannels = (props: Props) => {
    const [filterAccountType, setFilterAccountType] = useAtom(filterAccountTypeAtom)
    const {data: accounts} = useAtomValue(channelsAtom)

    const buildAndDownloadXLSX = () => {

        let data: any
        let title: string = 'TikTok_Benchmarks_'

        data = accounts.map((item, index) => {
            return {
                userID: item.userID,
                name: item.name,
                // categories: item.categories.map((category) => category.category).join(';'),
            }
        })
        if (filterAccountType === 'BRAND') {
            title += `Brand_Accounts.xlsx`
        } else if (filterAccountType === 'CREATOR') {
            title += `Creator_Accounts.xlsx`
        } else {
            title += `Accounts.xlsx`
        }

        //
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {dateNF: 'dd/mm/yyyy', cellDates: true});
        // const wb: XLSX.WorkBook = {Sheets: {[viewType]: ws}, SheetNames: [capitalize(viewType)]};
        const wb: XLSX.WorkBook = {Sheets: {Report: ws}, SheetNames: ['Report']};
        XLSX.writeFile(wb, title, {
            bookType: 'xlsx',
            type: 'array'
        });

    };

    return (
        <Tooltip title={'Export to Excel'}>
            <Button
                size={'large'}
                type={'dashed'}
                icon={<FileExcelOutlined style={{color: '#575757'}}/>}
                onClick={() => {
                    buildAndDownloadXLSX();
                }}
            />
        </Tooltip>
    );
};