import * as React from 'react';
import {Key, useEffect, useState} from 'react';
import {ModalForm, ProList} from "@ant-design/pro-components";
import {Button, Tooltip, Typography} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {useAtom} from "jotai";
import {channelCategoriesAtom, channelsAtom} from "./atoms";
import axiosApiInstance from "../../../../api/axiosClient";

type Props = {
    channel: API.Benchmarks.YouTubeBenchmarksHarvestChannel
};

export const CategorySelectModal = (props: Props) => {
    const [channelCategories, refreshChannelCategories] = useAtom(channelCategoriesAtom)
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [channels, refreshChannels] = useAtom(channelsAtom)


    useEffect(() => {
        setSelectedRowKeys(props.channel.categories.map((category) => category.id))
    }, [])

    return (
        <ModalForm
            trigger={
                <Tooltip title="Edit Categories">
                    <Button type="ghost" icon={<EditOutlined/>}/>
                </Tooltip>
            }
            width={600}
            style={{maxHeight: '60vh'}}
            // title={'Select Categories'}
            title={<>Select Categories <Typography.Text
                type="secondary">{selectedRowKeys.length} selected</Typography.Text></>}
            onFinish={async (values) => {
                return axiosApiInstance.put(`/api/benchmarks/youtube/channel/${props.channel.id}/categories`, selectedRowKeys.map((key) => ({id: key})))
                    .then((res) => {
                        refreshChannels()
                        return true
                    })
                    .catch((err) => false)
            }}
        >
            <ProList<API.Benchmarks.YouTubeBenchmarksChannelCategory>
                toolBarRender={false}
                options={false}
                tableAlertRender={false}
                metas={{
                    title: {
                        dataIndex: 'category',
                    },
                    description: {},
                    subTitle: {},
                    actions: {},
                    avatar: {render: () => null},
                }}
                rowKey="id"
                headerTitle="Select Categories"
                rowSelection={{
                    onChange: (keys, selectedRows) => {
                        // console.log(keys, selectedRows);
                        setSelectedRowKeys(keys)
                    },
                    selectedRowKeys: selectedRowKeys
                }}
                dataSource={channelCategories}
                style={{maxHeight: 'inherit', overflow: 'auto'}}
            />
        </ModalForm>
    );
};

export default CategorySelectModal;