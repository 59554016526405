import '@ant-design/colors';
import {
    blue,
    cyan,
    geekblue,
    gold,
    green,
    lime,
    magenta,
    orange,
    purple,
    red,
    volcano,
    yellow,
} from "@ant-design/colors";

// a function that gives the avatar another background color, according to the first letter of the influencer_handle
export const avatarColorizer = (influencer_handle: string) => {
    const firstLetter = influencer_handle.charAt(0).toUpperCase();
    const colors = [
        // fill in the colors from the antd color palette
        // red.primary,
        // volcano.primary,
        // orange.primary,
        // gold.primary,
        // yellow.primary,
        // lime.primary,
        // green.primary,
        // cyan.primary,
        // blue.primary,
        // geekblue.primary,
        // purple.primary,
        // magenta.primary,
        red[4],
        volcano[4],
        orange[4],
        gold[4],
        yellow[4],
        lime[4],
        green[4],
        cyan[4],
        blue[4],
        geekblue[4],
        purple[4],
        magenta[4],
        // geekblue[3],
        // red[3],
        // green[3],
        // geekblue[3],
        // purple[3],
        // magenta[3],
        // gold[3],
    ];
    const color = colors[firstLetter.charCodeAt(0) % colors.length];
    return color;
}