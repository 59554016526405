import React from 'react';
import { Card, Typography } from 'antd';

const { Meta } = Card;

const VideoCard = ({ value, onClick, thumbnail, label }) => {
  return (
    <div
      className='videoCard'
      id={value}
      style={{ margin: 8 }}
      onClick={(e) => {
        onClick(value);
      }}>
      <Card style={{ width: 120 }} hoverable={true} size='small' cover={<img alt={value} src={thumbnail} />}>
        <Meta
          description={
            <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ color: '#888', fontSize: 12 }}>
              {label}
            </Typography.Paragraph>
          }
        />
      </Card>
    </div>
  );
};

export default VideoCard;
