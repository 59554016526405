import React, { createContext, Dispatch, SetStateAction, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-components';

//create a context, with createContext api
type ContextType = {
    // deliverablesListRef?: React.MutableRefObject<ActionType | undefined>
    creatorsLeadsTableRef?: React.MutableRefObject<ActionType | undefined>;
    creatorsLeadsItems?: API.CreatorsLeads.CreatorLead[];
    setCreatorsLeadsItems?: Dispatch<SetStateAction<API.CreatorsLeads.CreatorLead[] | undefined>>;
    creatorsLeadsCategories?: API.CreatorsLeads.CreatorLeadContentCategory[];
    setCreatorsLeadsCategories?: Dispatch<
        SetStateAction<API.CreatorsLeads.CreatorLeadContentCategory[] | undefined>
    >;
    authorizing?: boolean;
    setAuthorizing?: Dispatch<SetStateAction<boolean>>;
    employees?: API.CRM.Employee[];
    setEmployees?: Dispatch<SetStateAction<API.CRM.Employee[] | undefined>>;

    //Potential YouTube Leads
    potentialLeadsTableRef?: React.MutableRefObject<ActionType | undefined>;
    potentialYouTubeLeads?: API.PotentialLeadsYouTube.PotentialLead[];
    setPotentialYouTubeLeads?: Dispatch<
        SetStateAction<API.PotentialLeadsYouTube.PotentialLead[] | undefined>
    >;
    potentialYouTubeLeadsFilters?: API.PotentialLeadsYouTube.PotentialLeadFilter[];
    setPotentialYouTubeLeadsFilters?: Dispatch<
        SetStateAction<API.PotentialLeadsYouTube.PotentialLeadFilter[] | undefined>
    >;
    contactsTableRef?: React.MutableRefObject<ActionType | undefined>;
    revenueBracketsTableRef?: React.MutableRefObject<ActionType | undefined>;
};

export const GlobalStateContext = createContext<ContextType>({});

const GlobalStateProvider = ({ children }: any) => {
    // this state will be shared with all components
    // const [deliverablesListRef, setDeliverablesListRef] = useState();
    // const deliverablesListRef = useRef<ActionType>();
    const creatorsLeadsTableRef = useRef<ActionType>();
    const contactsTableRef = useRef<ActionType>();
    const revenueBracketsTableRef = useRef<ActionType>();
    const [creatorsLeadsCategories, setCreatorsLeadsCategories] = useState<
        API.CreatorsLeads.CreatorLeadContentCategory[] | undefined
    >(undefined);
    const [employees, setEmployees] = useState<API.CRM.Employee[] | undefined>(undefined);
    const [creatorsLeadsItems, setCreatorsLeadsItems] = useState<API.CreatorsLeads.CreatorLead[] | undefined>(
        undefined
    );
    const [authorizing, setAuthorizing] = useState<boolean>(true);

    //Potential YouTube Leads
    const potentialLeadsTableRef = useRef<ActionType>();
    const [potentialYouTubeLeads, setPotentialYouTubeLeads] = useState<
        API.PotentialLeadsYouTube.PotentialLead[] | undefined
    >(undefined);
    const [potentialYouTubeLeadsFilters, setPotentialYouTubeLeadsFilters] = useState<
        API.PotentialLeadsYouTube.PotentialLeadFilter[] | undefined
    >(undefined);

    return (
        // this is the provider providing state
        <GlobalStateContext.Provider
            value={{
                // deliverablesListRef,
                creatorsLeadsTableRef,
                creatorsLeadsItems,
                setCreatorsLeadsItems,
                creatorsLeadsCategories,
                setCreatorsLeadsCategories,
                authorizing,
                setAuthorizing,
                employees,
                setEmployees,

                //Potential YouTube Leads
                potentialLeadsTableRef,
                potentialYouTubeLeads,
                setPotentialYouTubeLeads,
                potentialYouTubeLeadsFilters,
                setPotentialYouTubeLeadsFilters,

                // Various
                contactsTableRef,
                revenueBracketsTableRef
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateProvider;
