import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {ProTable} from "@ant-design/pro-components";
import {Avatar, Button, Popover, Row, Space, Tag, Tooltip} from "antd";
import {ProColumns} from "@ant-design/pro-table";
import DeleteCreator from "./DeleteCreator";
import {EditOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
// import NotifyCreatorManager from "./NotifyCreatorManager";
import ExportCreatorsExcel from "./ExportCreatorsExcel";
import {UserAuth} from "../../../context/AuthContext";
import './Creators.css';
import DeleteBulkCreators from "./DeleteBulkCreators";
import {creatorsAtom, filterSearchAtom, pageAtom, pageSizeAtom} from "./atoms";
import {useAtom, useAtomValue} from "jotai";
import {queryClientAtom} from "jotai-tanstack-query";
import {generate} from '@ant-design/colors';
import {
    formatFollowers,
    formatSocialMediaAccountName,
    getPaletteColor,
    TitleIG,
    TitleTT,
    TitleYT,
    toEUR
} from "./utils";
// import { createFromIconfontCN } from '@ant-design/icons';
// const IconFont = createFromIconfontCN({
//     scriptUrl: [
//         '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overridden)
//         '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
//     ],
// });
type Props = {};


const Creators = (props: Props) => {
    //
    // State - Constants
    //
    // const actionRef = useRef<ActionType>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    // const {
    //     employees,
    //     setEmployees,
    // } = useContext(GlobalStateContext);
    const [categoriesFilters, setCategoriesFilters] = useState(undefined);
    const [employeesFilters, setEmployeesFilters] = useState(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate()
    const location = useLocation()
    const {can} = UserAuth();

    const [{data: creators, isPending, isError}] = useAtom(creatorsAtom);
    const [page, setPage] = useAtom(pageAtom);
    const [pageSize, setPageSize] = useAtom(pageSizeAtom);

    const queryClient = useAtomValue(queryClientAtom);
    const [filterSearch, setFilterSearch] = useAtom(filterSearchAtom)
    // const creatorsAfterSortSearch = useAtomValue(creatorsAfterSortSearchAtom)

    const TagsColumnFormat = ({creator}) => {
        const [visibleTags, setVisibleTags] = useState([]);
        const [hiddenTagsCount, setHiddenTagsCount] = useState(0);
        const [popoverWidth, setPopoverWidth] = useState(240);
        const tagContainerRef = useRef(null);

        useEffect(() => {
            const calculateVisibleTags = () => {
                const tags = creator.tags || [];
                const primaryColors = tags.map(tag => tag.TagCategory.color);
                const palettes = primaryColors.map(color => generate(color));
                const appropriateColors = palettes.map((palette, index) => getPaletteColor(primaryColors[index], palette));

                const containerWidth = tagContainerRef.current ? tagContainerRef.current.offsetWidth : 0;
                const tagWidths = tags.map((tag, index) => {
                    const tagElement = document.createElement('div');
                    tagElement.style.display = 'inline-block';
                    tagElement.style.padding = '0 8px';
                    tagElement.style.borderRadius = '2px';
                    tagElement.style.backgroundColor = primaryColors[index];
                    tagElement.style.color = appropriateColors[index];
                    tagElement.textContent = tag.TagCategoryValue.value;
                    document.body.appendChild(tagElement);
                    const width = tagElement.offsetWidth + 8; // Include margin
                    document.body.removeChild(tagElement);
                    return width;
                });

                const grayTagWidth = 60; // Approximate width for the "+ <number> ..." tag
                let visibleTags = [];
                let totalWidth = 0;
                let lastVisibleIndex = -1;

                // Always include the first tag
                if (tags.length > 0) {
                    totalWidth += tagWidths[0];
                    visibleTags.push(
                        <Tooltip key={tags[0].id} title={tags[0].TagCategory.category_name}>
                            <Tag
                                color={primaryColors[0]}
                                style={{color: appropriateColors[0]}}
                            >
                                {tags[0].TagCategoryValue.value}
                            </Tag>
                        </Tooltip>
                    );
                    lastVisibleIndex = 0;
                }

                // Include as many tags as possible within the container width
                for (let i = 1; i < tags.length; i++) {
                    if (totalWidth + tagWidths[i] + grayTagWidth <= containerWidth) {
                        totalWidth += tagWidths[i];
                        visibleTags.push(
                            <Tooltip key={tags[i].id} title={tags[i].TagCategory.category_name}>
                                <Tag
                                    color={primaryColors[i]}
                                    style={{color: appropriateColors[i]}}
                                >
                                    {tags[i].TagCategoryValue.value}
                                </Tag>
                            </Tooltip>
                        );
                        lastVisibleIndex = i;
                    } else {
                        break;
                    }
                }

                // Adjust if the gray tag doesn't fit
                if (totalWidth + grayTagWidth > containerWidth) {
                    totalWidth -= tagWidths[lastVisibleIndex];
                    visibleTags.pop();
                    lastVisibleIndex--;
                }

                // If the first tag alone doesn't fit with the gray tag, move it to extra tags
                if (tags.length > 0 && tagWidths[0] + grayTagWidth > containerWidth) {
                    visibleTags = [];
                    lastVisibleIndex = -1;
                }

                setVisibleTags(visibleTags);
                setHiddenTagsCount(tags.length - (lastVisibleIndex + 1));

                // Calculate popover width based on the maximum tag line length
                let currentLineWidth = 0;
                let maxLineWidth = 0;

                const hiddenTags = tags.slice(lastVisibleIndex + 1);
                hiddenTags.forEach((tag, index) => {
                    currentLineWidth += tagWidths[lastVisibleIndex + 1 + index];
                    if (currentLineWidth > 240) { // Assume the default width
                        maxLineWidth = Math.max(maxLineWidth, currentLineWidth - tagWidths[lastVisibleIndex + 1 + index]);
                        currentLineWidth = tagWidths[lastVisibleIndex + 1 + index];
                    }
                });

                maxLineWidth = Math.max(maxLineWidth, currentLineWidth);

                setPopoverWidth(Math.min(maxLineWidth + 10, 240)); // Add 10 pixels for padding
            };

            calculateVisibleTags();
            window.addEventListener('resize', calculateVisibleTags);
            return () => window.removeEventListener('resize', calculateVisibleTags);
        }, [creator.tags]);

        return (
            <div ref={tagContainerRef}
                 style={{display: 'flex', alignItems: 'center', width: "inherit", marginRight: "-60px"}}>
                <Space direction="horizontal">
                    <Row justify={"space-evenly"}>
                        {visibleTags}
                        {hiddenTagsCount > 0 && (
                            <Popover
                                content={
                                    <Space
                                        direction="horizontal"
                                        wrap
                                        style={{
                                            width: `${popoverWidth}px`,
                                            marginRight: "-18px"
                                        }}
                                    >
                                        <Row
                                            justify={"space-evenly"}
                                            // justify={"center"}
                                            style={{marginBottom: "-9px"}}
                                        >
                                            {creator.tags.slice(visibleTags.length).map(tag => (
                                                <Tooltip key={tag.id} title={tag.TagCategory.category_name}>
                                                    <Tag
                                                        color={tag.TagCategory.color}
                                                        style={{
                                                            color: getPaletteColor(tag.TagCategory.color, generate(tag.TagCategory.color)),
                                                            marginBottom: "9px"
                                                        }}
                                                    >
                                                        {tag.TagCategoryValue.value}
                                                    </Tag>
                                                </Tooltip>
                                            ))}
                                        </Row>
                                    </Space>
                                }
                                title="More Tags"
                            >
                                <Tag color="#bfbfbf">+{hiddenTagsCount} ...</Tag>
                            </Popover>
                        )}
                    </Row>
                </Space>
            </div>
        );
    };

    const columns: ProColumns<API.Creators.Creator>[] = [
        {
            dataIndex: 'id',
            title: 'ID',
            // valueType: 'indexBorder',
            align: 'center',
            width: '',
        },
        {
            align: 'center',
            render: (_, record) => {
                // first account's image
                if (!record.accounts || record.accounts.length === 0) {
                    return null
                }
                const img = record.accounts[0].image;

                return <Avatar
                    src={`data:image/jpeg;base64,${img}`}
                    style={{width: 34, height: 34}}
                    shape={"circle"}
                />
            },
            // width: 70,
            width: '2%',
        },
        {
            title: 'Creator',
            dataIndex: 'creator',
            align: 'center',
            width: '8%',
        },
        {
            title: 'Identity',
            dataIndex: 'main_platform_id',
            valueType: 'select',
            align: 'center',
            width: '8%',
            valueEnum: {
                1: {text: 'YouTube'},
                2: {text: 'Instagram'},
                3: {text: 'TikTok'},
            },
            // filters: true,
            render: (_, record) => record.main_platform.characterization
        },
        {
            title: 'Followers',
            dataIndex: 'followers',
            // width: "50%",
            // sorter: true,
            hideInSearch: true,
            align: 'center',
            width: '16%',
            children: [
                {
                    title: TitleYT('YouTube'),
                    dataIndex: 'youtube_pricing',
                    align: 'center',

                    // renderText: (_text, record) => toEUR(record.pricing.youtube_video),
                    render: (dom, record) => record.accounts.filter(account => account.platform.name === "YouTube").map((account) => (
                        <Space direction="vertical" style={{display: 'flex', alignItems: 'center'}}>
                            <Tooltip title={formatSocialMediaAccountName(account.key)}>
                                <a href={account.key} target="_blank" rel="noreferrer"
                                   style={{color: "darkslategrey"}}>{formatFollowers(account.following)}</a>
                            </Tooltip>
                        </Space>
                    )),
                    sorter: (a, b) => {
                        //
                        // Sorting column by creator's biggest channel's followers
                        //

                        // Store the two creators' accounts' followers into two tables
                        let followers_a = a.accounts.filter(account => account.platform.name === "YouTube").map((account) => account.following)
                        let followers_b = b.accounts.filter(account => account.platform.name === "YouTube").map((account) => account.following)

                        // Keep only the max number of followers of each creator's biggest channel
                        let max_a = 0
                        if (followers_a.length > 0) {
                            max_a = Math.max(...followers_a);
                        }

                        let max_b = 0
                        if (followers_b.length > 0) {
                            max_b = Math.max(...followers_b);
                        }

                        // Compare the two maxes to sort the table, based on each creator's biggest channel's followers
                        if (max_a > max_b) {
                            return 1;
                        } else if (max_a < max_b) {
                            return -1;
                        } else {
                            return 0;
                        }

                        //
                        // Compare by the total number of followers of each creator
                        //
                        // // Calculate the total number of followers of each creator
                        // let sum_a = 0
                        // for (let i = followers_a.length - 1; i >= 0; i--) {
                        //     sum_a = sum_a + followers_a[i]
                        // }
                        //
                        // let sum_b = 0
                        // for (let i = followers_b.length - 1; i >= 0; i--) {
                        //     sum_b = sum_b + followers_b[i]
                        // }
                        //
                        // // Compare the two sums to sort the table, based on each creator's total followers
                        // if (sum_a > sum_b) {
                        //     return 1;
                        // } else if (sum_a < sum_b) {
                        //     return -1;
                        // } else {
                        //     return 0;
                        // }
                    }
                },
                {
                    title: TitleIG('Instagram'),
                    dataIndex: 'instagram_pricing',
                    align: 'center',

                    render: (dom, record) => record.accounts.filter(account => account.platform.name === "Instagram").map((account) => (
                        <Space direction="vertical" style={{display: 'flex', alignItems: 'center'}}>
                            <Tooltip title={formatSocialMediaAccountName(account.key)}>
                                <a href={account.key} target="_blank" rel="noreferrer"
                                   style={{color: "darkslategrey"}}>{formatFollowers(account.following)}</a>
                            </Tooltip>
                        </Space>
                    )),
                    sorter: (a, b) => {
                        //
                        // Sorting column by creator's biggest channel's followers
                        //

                        // Store the two creators' accounts' followers into two tables
                        let followers_a = a.accounts.filter(account => account.platform.name === "Instagram").map((account) => account.following)
                        let followers_b = b.accounts.filter(account => account.platform.name === "Instagram").map((account) => account.following)

                        // Keep only the max number of followers of each creator's biggest channel
                        let max_a = 0
                        if (followers_a.length > 0) {
                            max_a = Math.max(...followers_a);
                        }

                        let max_b = 0
                        if (followers_b.length > 0) {
                            max_b = Math.max(...followers_b);
                        }

                        // Compare the two maxes to sort the table, based on each creator's biggest channel's followers
                        if (max_a > max_b) {
                            return 1;
                        } else if (max_a < max_b) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                },
                {
                    title: TitleTT('TikTok'),
                    dataIndex: 'tiktok_pricing',
                    align: 'center',

                    render: (dom, record) => record.accounts.filter(account => account.platform.name === "TikTok").map((account) => (
                        <Space direction="vertical" style={{display: 'flex', alignItems: 'center'}}>
                            <Tooltip title={formatSocialMediaAccountName(account.key)}>
                                <a href={account.key} target="_blank" rel="noreferrer"
                                   style={{color: "darkslategrey"}}>{formatFollowers(account.following)}</a>
                            </Tooltip>
                        </Space>
                    )),
                    sorter: (a, b) => {
                        //
                        // Sorting column by creator's biggest channel's followers
                        //

                        // Store the two creators' accounts' followers into two tables
                        let followers_a = a.accounts.filter(account => account.platform.name === "TikTok").map((account) => account.following)
                        let followers_b = b.accounts.filter(account => account.platform.name === "TikTok").map((account) => account.following)

                        // Keep only the max number of followers of each creator's biggest channel
                        let max_a = 0
                        if (followers_a.length > 0) {
                            max_a = Math.max(...followers_a);
                        }

                        let max_b = 0
                        if (followers_b.length > 0) {
                            max_b = Math.max(...followers_b);
                        }

                        // Compare the two maxes to sort the table, based on each creator's biggest channel's followers
                        if (max_a > max_b) {
                            return 1;
                        } else if (max_a < max_b) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                },
            ],
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            align: 'center',
            width: '16%',
            search: false,
            render: (_, creator) => <TagsColumnFormat creator={creator}/>,
        },
        {
            title: 'Pricings',
            dataIndex: 'pricings',
            align: 'center',
            width: '40%',
            children: [
                {
                    title: TitleYT('YouTube'),
                    dataIndex: 'youtube_pricing',
                    align: 'center',
                    children: [
                        {
                            title: 'Video',
                            dataIndex: 'youtube_video_pricing',
                            align: 'center',
                            renderText: (_text, record) => toEUR(record.pricing.youtube_video),
                            sorter: (a, b) => a.pricing.youtube_video - b.pricing.youtube_video
                        }
                    ]
                },
                {
                    title: ''
                    // render: () => <Divider type='vertical' />
                },
                {
                    title: TitleIG('Instagram'),
                    dataIndex: 'instagram_pricing',
                    align: 'center',
                    children: [
                        {
                            title: 'Reel',
                            dataIndex: 'instagram_reel_pricing',
                            align: 'center',
                            renderText: (_text, record) => toEUR(record.pricing.instagram_reel),
                            sorter: (a, b) => a.pricing.instagram_reel - b.pricing.instagram_reel
                        },
                        {
                            title: 'Post',
                            dataIndex: 'instagram_post_pricing',
                            align: 'center',
                            renderText: (_text, record) => toEUR(record.pricing.instagram_post),
                            sorter: (a, b) => a.pricing.instagram_post - b.pricing.instagram_post
                        },
                        {
                            title: 'Story',
                            dataIndex: 'instagram_story_pricing',
                            align: 'center',
                            renderText: (_text, record) => toEUR(record.pricing.instagram_story),
                            sorter: (a, b) => a.pricing.instagram_story - b.pricing.instagram_story
                        }
                    ]
                },
                {
                    title: ''
                    // render: () => <Divider type='vertical' />
                },
                {
                    title: TitleTT('TikTok'),
                    dataIndex: 'tiktok_pricing',
                    align: 'center',
                    children: [
                        {
                            title: 'Video',
                            dataIndex: 'tiktok_video_pricing',
                            align: 'center',
                            renderText: (_text, record) => toEUR(record.pricing.tiktok_video),
                            sorter: (a, b) => a.pricing.tiktok_video - b.pricing.tiktok_video
                        }
                    ]
                }
            ]
        },
        {
            title: '',
            width: '8%',
            render: (_, record) => <Space>
                <Link to={`${record.id}`}>
                    <Tooltip title={'View'}><Button icon={<EyeOutlined/>} type={"text"}/></Tooltip>
                </Link>
                <Link to={`${record.id}/edit`}>
                    <Tooltip title={'Edit'}><Button icon={<EditOutlined/>} type={"text"}/></Tooltip>
                </Link>
                {/*<CreatorViewEdit editable={false} creator={record} key={record.id + '_view'}/>*/}
                {/*<CreatorViewEdit editable={true} creator={record} key={record.id + '_edit'}/>*/}
                {/*<NotifyCreatorManager id={record.id} key={record.id}/>*/}
                <DeleteCreator id={record.id} key={record.id}/>
            </Space>
        }
    ];

    return (
        <>

            <ProTable<API.Creators.Creator>
                columns={columns as any}
                // actionRef={creatorsTableRef}
                // dataSource={creators}
                loading={isPending}
                dataSource={creators?.data}
                ghost
                rowKey={record => record.id}
                // search={{
                //     labelWidth: 'auto',
                // }}
                search={false}
                options={{
                    // setting: {
                    //     listsHeight: 400,
                    // },
                    reload: () => {
                        // queryClient.invalidateQueries({queryKey: ['creators', 'items']})
                        return queryClient.invalidateQueries({queryKey: ['creators']})
                    },
                    search: {
                        placeholder: 'Please enter keyword', allowClear: true,
                        onChange: (e) => {
                            // console.log('search', e)
                            setFilterSearch(e.target.value)
                            setPage(1);
                        },
                        value: filterSearch,
                    },
                }}
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: creators?.total,
                    onChange: (page, pageSize) => {
                        setPage(page)
                        setPageSize(pageSize)
                    },
                    hideOnSinglePage: true,
                    showSizeChanger: true,
                }}
                // request={async (params = {}, sort, filter) =>
                //     // axiosApiInstance.get<CreatorRow[]>('/api/creators/', {params})
                // {
                //     // console.log('on request')
                //     // console.log(params)
                //     console.log(filter)
                //     // console.log(sort)
                //     const res = await axiosApiInstance.get<API.Creators.Creator[]>('/api/creators/items', {
                //         params:
                //             {
                //                 ...filter, ...params,
                //                 sort_communication_date: sort.date_of_communication,
                //                 sort_creation_date: sort.created_at,
                //                 sort_accounts: sort.accounts_stringified
                //             }
                //     })
                //     // console.log(res.data)
                //     // await fetchCategoriesData()
                //     // await fetchEmployeesData()
                //     return Promise.resolve({data: res.data, success: true, total: res.data?.length})
                // }
                // }
                //TODO
                // onDataSourceChange={dataSource => {
                //     // console.log('on ds change', dataSource)
                //     setCreators(dataSource)
                //     // setFilters()
                //     setCategoriesFilters(creatorsCategories?.map(value => ({
                //         text: value.name,
                //         value: value.id
                //     })))
                //     setEmployeesFilters(employees?.map(value => ({
                //         text: value.full_name,
                //         value: value.id
                //     })))
                // }}
                // onLoad={dataSource => {
                //     // console.log('on load')
                //     // setCreatorsItems(dataSource)
                // }}
                // pagination={{
                //     pageSize: 7,
                //     // pageSize: 150,
                //     hideOnSinglePage: true,
                //     showSizeChanger: false,
                //     // onChange: (page) => console.log(page),
                // }}
                dateFormatter="string"
                toolBarRender={() => [
                    <Button key="button" icon={<PlusOutlined/>} type="primary"
                            onClick={() => navigate('/crm/creators/list/new', {state: {background: location}})}
                    >
                        New
                    </Button>,
                    can('export', 'leads::data') && <ExportCreatorsExcel/>, // correct permission, do not change
                    // <CreatorCreate/>
                ]}
                tableAlertRender={({selectedRowKeys}) => {
                    return (
                        <Space size={24}>
                          <span>
                                Selected {selectedRowKeys.length} {selectedRowKeys.length > 1 ? 'creators' : 'creator'}
                          </span>
                        </Space>
                    )
                }}
                tableAlertOptionRender={({selectedRowKeys, onCleanSelected}) => {
                    return (
                        <Space size={16}>
                            <DeleteBulkCreators creatorsIds={selectedRowKeys as number[]}
                                                onClearSelection={onCleanSelected}/>
                            <a style={{marginInlineStart: 8}} onClick={onCleanSelected}>
                                Clear selection
                            </a>
                            {/*<a>Delete batch</a>*/}
                        </Space>
                    );
                }}
                rowSelection={{
                    selectedRowKeys,
                    onChange: (keys: React.Key[]) => setSelectedRowKeys(keys as number[]),
                    // renderCell: (value, record, index, originNode) => {
                    //     return <Row justify={"center"} align="middle" style={{height: 46}}>
                    //         <Col>
                    //             {originNode}
                    //         </Col>
                    //     </Row>
                    // },
                }}
                columnsState={{
                    persistenceKey: 'creator-creators-list',
                    persistenceType: 'localStorage',
                    // onChange(value) {
                    //     console.log('value: ', value);
                    // },
                }}
            />
            {/*</Col>*/}
            {/*</Row>*/}

            <Outlet/>

        </>
    );
};

export default Creators;