import React, {Component} from 'react';
import './index.css';
import {Form, FormInstance, Input, InputNumber, message, Row, Select,} from 'antd';
import {ExtendedAssetInfoRaw} from '../../../common/models';
import {executeQuery} from '../../../api/executeQuery';
import {postRequest} from "../../../api/postRequest";

export interface INewAssetFormProps {
    //Here we pass the Props Interface
    formRef: React.RefObject<FormInstance>;
    edit?: boolean
    id?: number
    onFormValuesChange: Function
}

export interface INewAssetFormState {
    //here we pass the State Interface
    ppk_ids: any[],
    cms_options?: any[],
}

//class ComponentName Component<PropsInterface, StateInterface>
class NewAssetForm extends Component<INewAssetFormProps, INewAssetFormState> {
    constructor(props: INewAssetFormProps) {
        super(props);

        //Component State
        this.state = {
            // formRef: React.createRef<FormInstance>(),
            ppk_ids: []
        };
    }

    //Add style here
    style = {};

    ppk_ids = [];

    getFunc = async () => {
        executeQuery("GET_PPKS")
            .then(async (response) => {
                // alert(response.data)
                console.log(response.data)

                const res: { label: string; value: any; }[] = [];

                response.data.forEach((element: any) => {
                    const line = {
                        // "label": element["platform"] + " / " + element["product"] + " / " + element["key"],
                        "label": element.descr,
                        "value": parseInt(element.id)
                    };
                    res.push(line)
                });

                this.setState({ppk_ids: res})
            })
            .catch((error) => {
                console.log('Error on GET :' + error);
                message.error('Error on GET :' + error);
            });

        // get cms's
        try {
            const response = await executeQuery('GET_CMS')
            // console.log('GET_CURRENCIES', response);
            // const currencies = response.data ? response.data as CmsInfo[] : []
            const currencies = response.data || []

            // const allAssets = (await getAllAssets()).data
            const selectOptions: { label: string; value: any; }[] = [];
            currencies.forEach((element: any) => {
                selectOptions.push({
                    label: element.value,
                    value: parseInt(element.id)
                })
            });
            // console.log(selectOptions)
            this.setState({cms_options: selectOptions})

        } catch (error) {
            console.log('Error:', error);
        }


        // IF EDIT
        if (this.props.edit) {

            postRequest('/api/assets/getraw/' + this.props.id)
                .then(async (response) => {
                    // console.log('Authenticated');
                    const result = response.data as ExtendedAssetInfoRaw || null;
                    console.log('res', result);
                    this.props.formRef.current?.setFieldsValue(result)
                })
                .catch((error) => {
                    console.log('Error on GET :' + error);
                });

        }


    };

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getFunc();

    }

    render() {


        return (
            // <div className="NewassetForm" style={this.style}>

            <Row justify="center">
                <Form
                    // {...layout}
                    name="assetForm"
                    //   onFinish={onFinish}
                    //   onFinishFailed={onFinishFailed}
                    validateMessages={{required: 'This field is required!'}}
                    ref={this.props.formRef}
                    layout="vertical"
                    size="middle"
                    onValuesChange={() => this.props.onFormValuesChange()}
                >

                    {this.props.edit &&
                    <Form.Item label="Asset ID" name="id">
                        <InputNumber disabled/>
                    </Form.Item>
                    }

                    {/* <Col span={8}> */}
                    <Form.Item label="Asset Domain" name="ppk_id" rules={[{required: true}]}>
                        {this.state.ppk_ids.length != 0 ?
                            <Select
                                showSearch
                                allowClear
                                style={{width: 400}}
                                placeholder="Select asset domain"
                                optionFilterProp="label"
                                //  onChange={onChange}
                                //  onSearch={onSearch}
                                options={this.state.ppk_ids}
                                filterOption={(inputValue, option) =>
                                    option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                                }
                            ></Select> : "Loading..."}
                    </Form.Item>

                    <Form.Item label="Asset Key" name="ppk_value" rules={[{required: true}]}>
                        <Input placeholder={"Channel ID, Asset Label etc."}/>
                    </Form.Item>

                    <Form.Item label="CMS" name="cms_id" rules={[{required: true}]}>
                        <Select
                            showSearch
                            allowClear
                            style={{width: 400}}
                            placeholder="Select a CMS (optional)"
                            optionFilterProp="label"
                            options={this.state.cms_options}
                            // filterOption={(inputValue, option) =>
                            //    option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                            // }
                        ></Select>
                    </Form.Item>

                    <Form.Item label="Notes" name="descr" rules={[{required: false}]}>
                        <Input.TextArea
                            // autoSize={{minRows: 4}}
                            placeholder="Write a note..."
                            rows={3}/>
                    </Form.Item>
                    {/* </Col> */}
                    {/* <Col span={12}></Col> */}
                </Form>
            </Row>
            // </div>
        );
    }
}

export default NewAssetForm;
