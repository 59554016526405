import { PlusOutlined } from '@ant-design/icons';
import { ModalForm, ProFormInstance, ProFormList, ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { Button, Form } from 'antd';
import React from 'react';
import axiosApiInstance from '../../../api/axiosClient';

const NewLabelForm = ({ stepFormRef }: Props) => {
    const [form] = Form.useForm<API.DDEX.Label>();
    return (
        <ModalForm<API.DDEX.Label>
            title='Add new artist'
            width={1000}
            form={form}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            trigger={
                <Button type='dashed' icon={<PlusOutlined />} style={{ width: '100%' }}>
                    Add
                </Button>
            }
            modalProps={{
                destroyOnClose: true,
                onCancel: () => true
            }}
            onFinish={async (values) => {
                await axiosApiInstance.post('/api/records/labels/', values);
                stepFormRef.current?.resetFields(['label_id']);
                return true;
            }}
        >
            <ProFormText key={'full_name'} name={'full_name'} label='Name' />
            <ProFormText key={'p_line'} name={'p_line'} label='(P) Line' />
            <ProFormText key={'c_line'} name={'c_line'} label='(C) Line' />
            <ProFormSelect key={'asset_label'} name={'asset_label'} label='Asset Label' />
        </ModalForm>
    );
};

type Props = {
    stepFormRef: React.MutableRefObject<ProFormInstance>;
};

export default NewLabelForm;
