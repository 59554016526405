import {API} from "../../common/constants";
import {roundNum} from "../../common/utils";
import {Button, notification, Progress, Row, Upload} from "antd";
import React, {useState} from "react";
import {UploadOutlined} from "@ant-design/icons";
import {PageContainer} from "@ant-design/pro-components";
import axios from "axios";
import {auth} from "../../config/firebase";

type Props = {};

// Convert metadata for a song/album release from one format to another

const MetadataConverter = (props: Props) => {

    const [fileList, setFileList] = useState<any[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [converting, setConverting] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>();

    const handleUpload = ({fileList}: any) => {

        if (fileList.length > 1) {
            notification.error({message: 'You can only upload one file at a time.'})
            return
        }
        console.log('fileList', fileList);

        // you store them in state, so that you can make a http req with them later
        setFileList(fileList)
    };

    const handleSubmit = async (event: { preventDefault: () => void; }) => {

        setUploading(true)
        event.preventDefault();

        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key

        // formData.append("files", this.state.fileList.map((f) => f.originFileObj));
        for (const file of fileList)
            formData.append("files", file.originFileObj);

        console.log('formData', formData)
        const access_token = await auth.currentUser?.getIdToken();

        axios
            .post(API + '/api/tools/metadata_converter/upload', formData,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${access_token}`
                    },
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent
                        const percent = roundNum((loaded / total) * 100, 1)
                        console.log('progressEvent', progressEvent, percent);
                        setUploadProgress(percent)
                    }
                })
            .then(res => {
                setUploading(false)
                // console.log("res", res);

                setConverting(true)
                axios
                    .post(API + '/api/tools/metadata_converter/convert', {
                            in_file_name: fileList[0].name,
                        },
                        {
                            headers: {
                                "Accept": "*/*",
                                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                                Authorization: `Bearer ${access_token}`
                            },
                            responseType: 'blob',
                        }
                    ).then(res => {
                    // console.log("res", res);
                    setConverting(false)
                    notification.success({message: 'Successfully converted'})

                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileList[0].name + '_converted.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })

                // notification.success({message: 'Successfully uploaded ' + fileList.length + ' file(s).'})


            })
            .catch(err => {
                setUploading(false)
                setConverting(false)
                // console.log("err", err);
            });
    };


    return (
        <PageContainer
            title={'Metadata Converter'}
        >

            <Row style={{width: '35%'}} >
                <Upload
                    fileList={fileList}
                    onChange={handleUpload}
                    beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                    multiple={false}
                    accept={".xlsx"}
                >
                    <Button icon={<UploadOutlined/>}>Choose file</Button>

                </Upload>
                {uploadProgress !== undefined && uploadProgress !== 0 && fileList.length > 0 && <Progress percent={uploadProgress}/>}
            </Row>

            <br/>
            <Row>

                <Button onClick={handleSubmit} disabled={fileList.length === 0 || uploading || converting}
                        loading={uploading || converting}
                        type={"primary"}>
                    Convert
                </Button>

            </Row>

        </PageContainer>
    );
};
export default MetadataConverter;
