import {ConfigProvider, Row, Space, Spin} from 'antd';
import React, {Component} from 'react';
import './index.css';
import enUSIntl from "antd/lib/locale/en_US";
import ProTable, {ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import {FileTextOutlined} from "@ant-design/icons";


export interface IPaymentCycleCustomerBreakdownByAssetProps {
    //Here we pass the Props Interface
    cycle_id?: string
    customer_id?: number
    pageSize?: number
    polling?: number
}

export interface IPaymentCycleCustomerBreakdownByAssetState {
    data?: any
    filteredData?: any
    loading: boolean
}


type PaymentCycleCustomerBreakdownRow = {
    cms: string
    ads_type: string
    asset_key: string
    meta_asset_domain_id: number
    gross_euro: number
    gross_deposited_original_currency: number
    rate_original_currency_to_eur: number
    commission: number
    client_euro: number
    revenue_type: string
    asset_domain: string
};


//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleCustomerBreakdownByAsset extends Component<IPaymentCycleCustomerBreakdownByAssetProps, IPaymentCycleCustomerBreakdownByAssetState> {

    constructor(props: IPaymentCycleCustomerBreakdownByAssetProps) {
        super(props);
        this.state = {
            loading: false,
        }

    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {

    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        this.setState({loading: true})
        try {
            const res = await postRequest('/api/paymentCycles/getResultsAnalyticsCustomerBreakdown/' + this.props.cycle_id,
                {customer_id: this.props.customer_id})
            console.log(res.data)
            this.setState({data: res.data, loading: false})
        } catch (e) {
            // return
            this.setState({loading: false})
        }
    }

    columns: ProColumns<PaymentCycleCustomerBreakdownRow>[] = [
        {title: 'Asset Key', dataIndex: 'asset_key', valueType: 'text',},
        {title: 'Asset Domain', dataIndex: 'asset_domain', valueType: 'text',},
        {title: 'CMS', dataIndex: 'cms', valueType: 'text',},
        {title: 'Ads type', dataIndex: 'ads_type', valueType: 'text',},
        // {title: 'MetaAssetDomainId', dataIndex: 'meta_asset_domain_id', valueType: 'digit',},
        {
            title: 'Gross Deposited Original Currency',
            dataIndex: 'gross_deposited_original_currency',
            valueType: 'digit',
            sorter: (a, b) => a.gross_deposited_original_currency - b.gross_deposited_original_currency,
            hideInSearch: true
        },
        {title: 'Rate to Euro', dataIndex: 'rate_original_currency_to_eur', valueType: 'digit', hideInSearch: true},
        {
            title: 'Gross Euro', dataIndex: 'gross_euro', valueType: 'digit',
            sorter: (a, b) => a.gross_euro - b.gross_euro, hideInSearch: true

        },
        {title: 'Commission', dataIndex: 'commission', valueType: 'digit', hideInSearch: true},
        {
            title: 'Client Revenue (€)', dataIndex: 'client_euro', valueType: 'digit',
            sorter: (a, b) => a.client_euro - b.client_euro, hideInSearch: true
        },
        {
            title: 'Revenue Type', dataIndex: 'revenue_type', valueType: 'select',
            valueEnum: {
                direct: {
                    text: 'Direct',
                    status: 'success',
                },
                affiliate: {
                    text: 'Affiliate',
                    status: 'processing',
                },
            },
        },
    ];

    onSearch = (params: PaymentCycleCustomerBreakdownByAsset) => {
        let filteredData: PaymentCycleCustomerBreakdownByAsset[] = JSON.parse(JSON.stringify(this.state.data))
        Object.keys(params).map((key) => {
            if (params[key] == '') return true
            filteredData = filteredData.filter((datum) => {
                // console.log(key, params[key], datum[key].toString().toLowerCase().includes(params[key].toLowerCase()))
                // return datum[key] == params[key] // strict comparison
                return datum[key].toString().toLowerCase().includes(params[key].toLowerCase()) // loose comparison
            })
        })
        this.setState({filteredData})
    }
    onReset = () => {
        this.onSearch({} as any)
    }


    render() {


        return (
            <>

                {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}

                {
                    this.state.data &&
                    <ConfigProvider locale={enUSIntl}>

                        {/*<Card bodyStyle={{padding: 0}}>*/}

                        <ProTable<PaymentCycleCustomerBreakdownRow>
                            columns={this.columns}
                            polling={this.props.polling || 0}
                            // request={async (params = {}, sort, filter) => {
                            //     const res = await postRequest('/api/paymentCycles/getResultsAnalyticsCustomerBreakdown/' + this.props.cycle_id,
                            //         {customer_id: this.props.customer_id})
                            //     const data = res.data || []
                            //     // console.log(res.data)
                            //     return {data, success: true, total: data.length}
                            // }}
                            rowKey={record => record.asset_key + record.meta_asset_domain_id + record.cms + record.ads_type}
                            // search={false}
                            dataSource={this.state.filteredData ? this.state.filteredData : this.state.data}
                            locale={{emptyText: <Space direction={'vertical'}> <FileTextOutlined/> No logs</Space>}}
                            pagination={{pageSize: this.props.pageSize || 7, hideOnSinglePage: true}}
                            dateFormatter="string"
                            search={{layout: "vertical"}}
                            // toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                            toolBarRender={false}
                            // toolBarRender={() => []}
                            onSubmit={this.onSearch}
                            onReset={this.onReset}
                        />
                        {/*</Card>*/}


                    </ConfigProvider>
                }

                {this.state.loading &&
                <Row justify={"center"}>
                    <Spin/>
                </Row>
                }

            </>
        );
    }
}

export default PaymentCycleCustomerBreakdownByAsset;