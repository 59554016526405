export const API = process.env.REACT_APP_API;
export const RBAC_API = process.env.REACT_APP_RBAC_API;

export const TAG_SIZE = 35;
export const TAG_GROUP_SIZE = 'large';
export const TAG_SHAPE = 'circle';

export const MAX_SHOWN_TAGS = 10;
export const BADGE_FONT_SIZE = 18;
export const AVATAR_SIZE = 50;
export const YT_TOPIC_COLOR = '#108ee9';
export const YT_COLOR = '#FF0000';
export const IG_COLOR = '#E1306C';
export const TT_COLOR = '#010101';
export const DM_COLOR = '#127a8d';
export const ANTD_CORRECT_GREEN = '#73d13d';
export const ANTD_WRONG_RED = '#ff4d4f';
