import React, {Component, useEffect, useState} from 'react';
import './index.css';
import {postRequest} from "../../../api/postRequest";
import {
    Alert,
    Avatar,
    Button,
    Col,
    ConfigProvider,
    Empty,
    Form,
    FormInstance,
    Image,
    Modal,
    notification,
    Progress,
    Result,
    Row,
    Space,
    Spin,
    Tooltip,
    Typography
} from "antd";
import {camelCaseSplit, capitalize} from "../../../common/utils";
import ProForm, {
    ProFormDatePicker,
    ProFormDependency,
    ProFormGroup,
    ProFormList,
    ProFormRadio,
    ProFormSelect,
    ProFormText
} from '@ant-design/pro-form';
import enUSIntl from 'antd/lib/locale/en_US';
import {
    CheckCircleFilled,
    DeleteOutlined,
    FileExcelOutlined,
    FilePptOutlined,
    MonitorOutlined,
    PlusOutlined,
    WarningOutlined
} from "@ant-design/icons";
import moment from "moment";
import {TagCategoryValueDrawer} from "../../../common/models";
import {PageContainer} from "@ant-design/pro-components";

//constants
export const ReportTypeVideoToVideo = 'ReportTypeVideoToVideo'
export const ReportTypeChannel = 'ReportTypeChannel'
export const ReportTypeBulkChannel = 'ReportTypeBulkChannel'
export const ReportTypeStaticChannel = 'ReportTypeStaticChannel'
export const ReportTypeStaticBulkChannel = 'ReportTypeStaticBulkChannel'


export const Verbose = 'verbose'
export const NonVerbose = 'non_verbose'
export const AutoVerbose = 'auto'
export const Month = 'month'
export const Quarter = 'quarter'
export const HalfYear = 'half'
export const Year = 'year'

export interface ISmartReportsProps {
    //Here we pass the Props Interface
}

export interface ISmartReportsState {
    //here we pass the State Interface
    processing: boolean
    success?: any
    error?: any
    resultModalVisible: boolean
    formRef: React.RefObject<FormInstance>
    // channelInfo?: any
    loadingChannelsFromTags?: boolean
    processingInfoMultipleChannels?: { [k: string]: { processing: boolean, successData: any, error: any } }
    resultBulkModalVisible: boolean
}

//class ComponentName Component<PropsInterface, StateInterface>
class SmartReports extends Component<ISmartReportsProps, ISmartReportsState> {

    constructor(props: ISmartReportsProps) {
        super(props)

        // const now = Date.now()

        this.state = {
            processing: false,
            resultModalVisible: false,
            formRef: React.createRef<FormInstance>(),
            resultBulkModalVisible: false,
            // resultBulkModalVisible: true,
        }
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        // this.getFunc()
    }

    onSubmit = async (values: any) => {
        // console.log({values})
        // console.log(values.params[0].start_date)
        // console.log(values.params[0].end_date)
        // console.log(values)
        // console.log(values.channel_params[0])
        // console.log(values.start_date)
        // console.log(values.period_over_period)
        // console.log(values.year_over_year)
        // console.log(values.params[0])

        if (values.report_type == ReportTypeVideoToVideo) {

            Object.defineProperty(values, 'params',
                Object.getOwnPropertyDescriptor(values, 'video_params')!);
            delete values['video_params'];
            // console.log(values)


            values.params[0].start_date = moment(values.params[0].start_date).format('YYYY-MM-DD')
            values.params[0].end_date = moment(values.params[0].end_date).format('YYYY-MM-DD')
            values.params[1].start_date = moment(values.params[1].start_date).format('YYYY-MM-DD')
            values.params[1].end_date = moment(values.params[1].end_date).format('YYYY-MM-DD')
            // console.log(values)

        }

        if (values.report_type == ReportTypeChannel || values.report_type == ReportTypeStaticChannel) {
            Object.defineProperty(values, 'params',
                Object.getOwnPropertyDescriptor(values, 'channel_params')!);
            delete values['channel_params'];
            // console.log(values)
            // console.log(values.params[0])

            const v = values.params[0].period
            if (values.params[0].range == Month) {
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY-MM").format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY-MM").add(1, 'M').subtract(1, 'd').format("YYYY-MM-DD"),
                    period_over_period: {
                        start_date: moment(v, "YYYY-MM").subtract(1, 'M').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-MM").subtract(1, 'd').format("YYYY-MM-DD"),
                    },
                    year_over_year: {
                        start_date: moment(v, "YYYY-MM").subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-MM").subtract(1, 'y').add(1, 'M').subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }


            if (values.params[0].range == Quarter) {
                // console.log(values)
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY-Q").format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY-Q").add(1, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    period_over_period: {
                        start_date: moment(v, "YYYY-Q").subtract(1, 'Q').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-Q").subtract(1, 'd').format("YYYY-MM-DD"),
                    },
                    year_over_year: {
                        start_date: moment(v, "YYYY-Q").subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-Q").subtract(1, 'y').add(1, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }

            if (values.params[0].range == HalfYear) {
                const halfno = values.params[0].halfno
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY").add(halfno == 1 ? 2 : 4, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    //new
                    period_over_period: {
                        start_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(2, 'Q').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    },
                    year_over_year: {
                        start_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'y').add(2, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }

            if (values.params[0].range == Year) {
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY").format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY").add(1, 'y').subtract(1, 'd').format("YYYY-MM-DD"),
                    //test
                    // period_over_period: {
                    //     start_date: moment(v, "YYYY").subtract(1, 'y').format("YYYY-MM-DD"),
                    //     end_date: moment(v, "YYYY").subtract(1, 'd').format("YYYY-MM-DD"),
                    // },
                    //new
                    year_over_year: {
                        start_date: moment(v, "YYYY").subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY").subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }

            // delete values.params[0]['range']
            delete values.params[0]['period']

            if (!values.report_template_requests) {
                values.report_template_requests = []
            }

            // console.log(values)

        }

        if (values.report_type == ReportTypeBulkChannel || values.report_type == ReportTypeStaticBulkChannel) {
            if (values.bulk_channel_params.length == 0) {
                notification.warning({message: 'No channels provided.'})
                return
            }
            // console.log({values})
            this.setState({
                processing: true,
                success: null,
                error: null,
                processingInfoMultipleChannels: {},
                resultBulkModalVisible: true
            })

            for (let i = 0; i < values.bulk_channel_params.length; i++) {

                let requestData: any = {
                    params: [
                        {
                            channel_id: values.bulk_channel_params[i].channel_id,
                            start_date: values.start_date,
                            end_date: values.end_date,
                            verbose: values.verbose,
                            range: values.range,
                            period: values.period
                        }
                    ],
                    report_template_requests: values.report_template_requests || [],
                    report_type: values.report_type
                }

                const period = requestData.params[0].period
                const range = requestData.params[0].range
                if (range == Month) {
                    requestData.params[0] = {
                        ...requestData.params[0],
                        start_date: moment(period, "YYYY-MM").format("YYYY-MM-DD"),
                        end_date: moment(period, "YYYY-MM").add(1, 'M').subtract(1, 'd').format("YYYY-MM-DD"),
                        period_over_period: {
                            start_date: moment(period, "YYYY-MM").subtract(1, 'M').format("YYYY-MM-DD"),
                            end_date: moment(period, "YYYY-MM").subtract(1, 'd').format("YYYY-MM-DD"),
                        },
                        year_over_year: {
                            start_date: moment(period, "YYYY-MM").subtract(1, 'y').format("YYYY-MM-DD"),
                            end_date: moment(period, "YYYY-MM").subtract(1, 'y').add(1, 'M').subtract(1, 'd').format("YYYY-MM-DD"),
                        }
                    }
                }


                if (range == Quarter) {
                    // console.log(requestData)
                    requestData.params[0] = {
                        ...requestData.params[0],
                        start_date: moment(period, "YYYY-Q").format("YYYY-MM-DD"),
                        end_date: moment(period, "YYYY-Q").add(1, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                        period_over_period: {
                            start_date: moment(period, "YYYY-Q").subtract(1, 'Q').format("YYYY-MM-DD"),
                            end_date: moment(period, "YYYY-Q").subtract(1, 'd').format("YYYY-MM-DD"),
                        },
                        year_over_year: {
                            start_date: moment(period, "YYYY-Q").subtract(1, 'y').format("YYYY-MM-DD"),
                            end_date: moment(period, "YYYY-Q").subtract(1, 'y').add(1, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                        }
                    }
                }

                if (range == HalfYear) {
                    const halfno = values.halfno
                    requestData.params[0] = {
                        ...requestData.params[0],
                        start_date: moment(period, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').format("YYYY-MM-DD"),
                        end_date: moment(period, "YYYY").add(halfno == 1 ? 2 : 4, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                        //new
                        period_over_period: {
                            start_date: moment(period, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(2, 'Q').format("YYYY-MM-DD"),
                            end_date: moment(period, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                        },
                        year_over_year: {
                            start_date: moment(period, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'y').format("YYYY-MM-DD"),
                            end_date: moment(period, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'y').add(2, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                        }
                    }
                }

                if (range == Year) {
                    requestData.params[0] = {
                        ...requestData.params[0],
                        start_date: moment(period, "YYYY").format("YYYY-MM-DD"),
                        end_date: moment(period, "YYYY").add(1, 'y').subtract(1, 'd').format("YYYY-MM-DD"),
                        //test
                        // period_over_period: {
                        //     start_date: moment(period, "YYYY").subtract(1, 'y').format("YYYY-MM-DD"),
                        //     end_date: moment(period, "YYYY").subtract(1, 'd').format("YYYY-MM-DD"),
                        // },
                        //new
                        year_over_year: {
                            start_date: moment(period, "YYYY").subtract(1, 'y').format("YYYY-MM-DD"),
                            end_date: moment(period, "YYYY").subtract(1, 'd').format("YYYY-MM-DD"),
                        }
                    }
                }


                // console.log({requestData}); return;
                this.setState({
                    processingInfoMultipleChannels: {
                        ...this.state.processingInfoMultipleChannels!, [requestData.params[0].channel_id]: {
                            processing: true,
                            error: undefined,
                            successData: undefined
                        }
                    }
                })
                try {
                    const res = await postRequest(`/api/youtubereports/make/${requestData.report_type}`, requestData, false)
                    console.log({res: res.data})
                    //    show result Modal
                    //update object
                    let processingInfoMultipleChannels = {...this.state.processingInfoMultipleChannels!};
                    let processingInfo = {...processingInfoMultipleChannels[1]};
                    processingInfo.processing = false;
                    processingInfo.successData = res.data;
                    processingInfo.error = null;
                    processingInfoMultipleChannels[requestData.params[0].channel_id] = processingInfo;
                    this.setState({processingInfoMultipleChannels});
                } catch (err) {
                    let message = capitalize(err.response.data.message)
                    console.log({err: message})

                    //update object
                    let processingInfoMultipleChannels = {...this.state.processingInfoMultipleChannels!};
                    let processingInfo = {...processingInfoMultipleChannels[1]};
                    processingInfo.processing = false;
                    processingInfo.successData = null;
                    processingInfo.error = message;
                    processingInfoMultipleChannels[requestData.params[0].channel_id] = processingInfo;
                    this.setState({processingInfoMultipleChannels});


                    // this.setState({processing: false, error: message})
                    this.setState({processing: false})
                    notification.error({message, duration: 0})
                }

            }
            this.setState({processing: false})
            return

        }

        console.log({values})
        console.log(values.params[0])
        // console.log(values.report_template_requests[0])
        // console.log(values.report_template_requests[1])

        // return

        this.setState({processing: true, success: null, error: null})

        postRequest(`/api/youtubereports/make/${values.report_type}`, values, false).then((res) => {
            this.setState({processing: false, success: res.data})
            console.log({res})
            //    show result Modal
            this.setState({resultModalVisible: true})
        }).catch((err) => {
            let message = capitalize(err.response.data.message)
            this.setState({processing: false, error: message})
            notification.error({message, duration: 0})
        })
    }


    onLoadChannels = async () => {
        //"UCLGvENoc24tiWCxEFgHHong", "UC0b4HJFwZ2-XbTlMIJbF2Jw"
        this.setState({loadingChannelsFromTags: true})
        const tags = this.state.formRef.current?.getFieldValue('tags')
        try {
            const res = await postRequest('/api/tags/getchannelswithalltags', {tags: tags.join()})
            console.log(res)
            const data = res.data || []
            notification.info({message: data.length + ' channels found'})
            const prev = this.state.formRef.current?.getFieldValue('bulk_channel_params') || []
            const bulk_channel_params = data.map((i: any) => ({channel_id: i.ppk_value}))
            // this.state.formRef.current?.setFieldsValue({bulk_channel_params})
            this.state.formRef.current?.setFieldsValue({bulk_channel_params: [...bulk_channel_params, ...prev]})
            this.setState({loadingChannelsFromTags: false})
        } catch (e) {
            notification.error({message: e.response.data.message})
            this.setState({loadingChannelsFromTags: false})
        }

    }

    onAddChannelToList = async () => {
        //"UCLGvENoc24tiWCxEFgHHong", "UC0b4HJFwZ2-XbTlMIJbF2Jw"
        // this.setState({loadingChannelsFromTags: true})
        const newChannel = this.state.formRef.current?.getFieldValue('channel_select')
        // console.log({newChannel})
        try {
            const prev = this.state.formRef.current?.getFieldValue('bulk_channel_params') || []
            // this.state.formRef.current?.setFieldsValue({bulk_channel_params: [...prev, {channel_id: newChannel}]})
            this.state.formRef.current?.setFieldsValue({bulk_channel_params: [{channel_id: newChannel}, ...prev]})
            // this.setState({loadingChannelsFromTags: false})
        } catch (e) {
            notification.error({message: e.response.data.message})
            // this.setState({loadingChannelsFromTags: false})
        }
    }

    onClearChannelsList = async () => {
        this.setState({loadingChannelsFromTags: true})
        this.state.formRef.current?.setFieldsValue({bulk_channel_params: []})
        notification.info({message: 'Cleared all channels'})
        this.setState({loadingChannelsFromTags: false})
    }

    render() {

        const addBtnHeight = 540

        return (
            <div className="SmartReports" style={this.style}>
                <PageContainer>


                    <Spin spinning={this.state.processing} tip={"Processing... This won't take long."}>

                        <Alert
                            message={<>
                                <Typography.Text type={"secondary"}>
                                    {"For detailed documentation, see "}
                                    <Typography.Link
                                        href="https://developers.google.com/youtube/analytics/content_owner_reports#video-reports"
                                        target={"_blank"}>the official docs</Typography.Link>.
                                </Typography.Text>
                            </>}
                            type={"info"}
                            showIcon={true}
                        />

                        <ConfigProvider locale={enUSIntl}>
                            <ProForm size={"large"} onFinish={(values) => this.onSubmit(values)}
                                     formRef={this.state.formRef as any} style={{width: '100%'}}>
                                {/*Report Type*/}
                                <ProFormSelect name="report_type" label={"Report Type"}
                                               initialValue={ReportTypeStaticChannel}
                                               rules={[{required: true}]}
                                               fieldProps={{defaultActiveFirstOption: true}} // works ? maybe not
                                               request={async () => {
                                                   let res = await postRequest('/api/youtubereports/supported_report_types')
                                                   let data = res.data
                                                   // let options: { [k: string]: string }[] = [] Doesnt work
                                                   // data.map((i: string) => options.push({i}))
                                                   let options: { label?: string, value?: string }[] = []
                                                   data.map((i: string) => options.push({
                                                       label: camelCaseSplit(i),
                                                       value: i
                                                   }))
                                                   // console.log({options})
                                                   return options
                                               }}/>
                                {/*Params*/}
                                <ProFormDependency name={['report_type']}>
                                    {({report_type}) => {
                                        // console.log({report_type})
                                        if (!report_type) {
                                            return <Empty description={"Select a report type"}/>;
                                        }
                                        if (report_type == ReportTypeVideoToVideo) {
                                            return <ProFormList
                                                name={['video_params']}
                                                label="Report Parameters"
                                                // initialValue={[{}, {}]}
                                                initialValue={[
                                                    {
                                                        "video_id": "gQCRTYOKG8c",
                                                        // "start_date": moment("2021-01-01"),
                                                        // "end_date": moment("2022-01-10")
                                                        "start_date": "2021-01-01",
                                                        "end_date": "2022-01-10"
                                                    },
                                                    {
                                                        "video_id": "iOyAhFdfjGg",
                                                        // "start_date": moment("2021-01-01"),
                                                        // "end_date": moment("2022-01-10")
                                                        "start_date": "2021-01-01",
                                                        "end_date": "2022-01-10"
                                                    }
                                                ]} //test with a default config
                                                creatorButtonProps={false}
                                                deleteIconProps={false}
                                                copyIconProps={false}
                                                key={'video-list'}
                                            >
                                                <ProForm.Group>
                                                    <ProFormText name="video_id" label="Video ID"
                                                                 rules={[{required: true}]}/>
                                                    <ProFormDatePicker name="start_date" label="Start date"
                                                                       fieldProps={{
                                                                           format: 'YYYY-MM-DD',
                                                                           picker: 'date'
                                                                       }}

                                                                       rules={[{
                                                                           // type: 'object' as const,
                                                                           // type: 'string',
                                                                           required: true
                                                                       }]}/>
                                                    <ProFormDatePicker name="end_date" label="End date"
                                                                       fieldProps={{
                                                                           format: 'YYYY-MM-DD',
                                                                           picker: 'date'
                                                                       }}

                                                                       rules={[{
                                                                           // type: 'object' as const,
                                                                           required: true
                                                                       }]}/>


                                                </ProForm.Group>
                                            </ProFormList>
                                        }

                                        if (report_type == ReportTypeChannel || report_type == ReportTypeStaticChannel) {
                                            return (
                                                <>
                                                    <ProFormList
                                                        name={['channel_params']}
                                                        label="Report Parameters"
                                                        creatorButtonProps={false}
                                                        deleteIconProps={false}
                                                        copyIconProps={false}
                                                        initialValue={[
                                                            {}
                                                        ]}
                                                        key={'channel-list'}
                                                    >

                                                        <ProFormGroup>

                                                            {/*<ProFormText name={"channel_id"} label="Channel ID"*/}
                                                            {/*             rules={[{required: true}]}*/}
                                                            {/*             initialValue={'UC9DGkZrmvWPk-yqLawxTcfQ'}/>*/}

                                                            <ProFormSelect
                                                                name={"channel_id"}
                                                                label="Channel ID"
                                                                rules={[{required: true}]}
                                                                initialValue={'UC9DGkZrmvWPk-yqLawxTcfQ'}
                                                                request={async () => {
                                                                    let res = await postRequest('/api/youtubereports/supported_channels_data')
                                                                    return res.data
                                                                }}
                                                                width={300}
                                                                showSearch
                                                                help={

                                                                    <Form.Item noStyle shouldUpdate>
                                                                        {(form) => {
                                                                            const channel_id = form.getFieldValue('channel_params')[0].channel_id
                                                                            // console.log(channel_id)
                                                                            if (!channel_id) return null
                                                                            return <ChannelInfoData
                                                                                channel_id={channel_id}/>
                                                                        }}
                                                                    </Form.Item>

                                                                }
                                                            />

                                                            <ProFormRadio.Group
                                                                name={"range"}
                                                                label="Range"
                                                                radioType="button"
                                                                options={[
                                                                    {label: 'Month', value: Month,},
                                                                    {label: 'Quarter', value: Quarter,},
                                                                    {label: 'Half-Year', value: HalfYear,},
                                                                    {label: 'Year', value: Year,},
                                                                ]}
                                                                initialValue={Month}
                                                                rules={[{required: true}]}
                                                            />


                                                            <ProFormDependency name={['range']}>
                                                                {({range}) => {
                                                                    // let range=range.range
                                                                    // console.log({range})
                                                                    if (!range) {
                                                                        return <Empty
                                                                            description={"Select range"}/>;
                                                                    }
                                                                    if (range == Month) {
                                                                        return (
                                                                            <>
                                                                                <ProFormDatePicker.Month name="period"
                                                                                                         label="Period"
                                                                                                         fieldProps={{
                                                                                                             format: 'YYYY-MM',
                                                                                                             // onChange:(v,d)=>{
                                                                                                             //     console.log(v,d)
                                                                                                             // }
                                                                                                         }}

                                                                                                         rules={[{
                                                                                                             // type: 'object',
                                                                                                             required: true,
                                                                                                         }]}
                                                                                                         initialValue={moment(moment().startOf('month').subtract(1, 'month'), "YYYY-MM-DD")}
                                                                                />

                                                                            </>
                                                                        )
                                                                    }
                                                                    if (range == Quarter) {
                                                                        return <ProFormDatePicker.Quarter name="period"
                                                                                                          label="Period"
                                                                                                          fieldProps={{
                                                                                                              format: "YYYY-Q"
                                                                                                          }}

                                                                                                          rules={[{
                                                                                                              required: true,
                                                                                                          }]}
                                                                        />
                                                                    }
                                                                    if (range == HalfYear) {
                                                                        return <ProFormDatePicker.Year name="period"
                                                                                                       label="Period"
                                                                                                       addonAfter={
                                                                                                           <ProFormRadio.Group
                                                                                                               name="halfno"
                                                                                                               noStyle
                                                                                                               radioType="button"
                                                                                                               options={[
                                                                                                                   {
                                                                                                                       label: '1st',
                                                                                                                       value: 1,
                                                                                                                   },
                                                                                                                   {
                                                                                                                       label: '2nd',
                                                                                                                       value: 2,
                                                                                                                   },
                                                                                                               ]}
                                                                                                               initialValue={1}
                                                                                                               rules={[{required: true}]}
                                                                                                               // transform={(_) => ({})}
                                                                                                           />
                                                                                                       }
                                                                                                       fieldProps={{
                                                                                                           format: 'YYYY'
                                                                                                       }}

                                                                                                       rules={[{
                                                                                                           required: true,
                                                                                                       }]}
                                                                        />
                                                                    }
                                                                    if (range == Year) {
                                                                        return <ProFormDatePicker.Year name="period"
                                                                                                       label="Period"
                                                                                                       fieldProps={{
                                                                                                           format: 'YYYY'
                                                                                                       }}

                                                                                                       rules={[{
                                                                                                           required: true,
                                                                                                       }]}
                                                                        />
                                                                    }
                                                                    return null
                                                                }
                                                                }
                                                            </ProFormDependency>

                                                            <ProFormDependency name={['range']}>
                                                                {({range}) => {
                                                                    // if (range == HalfYear || range == Year) return null
                                                                    return (<ProFormRadio.Group
                                                                        name={"verbose"}
                                                                        label="Verbose"
                                                                        radioType="button"
                                                                        options={[
                                                                            {label: 'Yes', value: Verbose,},
                                                                            {label: 'No', value: NonVerbose,}
                                                                        ]}
                                                                        initialValue={Verbose}
                                                                        rules={[{required: true}]}
                                                                        // disabled={range == HalfYear || range == Year}
                                                                        // disabled={range == Year}
                                                                        help={range == Year ? 'Only non-verbose reports can be exported.' : 'Applies to every template request where Verbose=Auto.'}
                                                                    />)
                                                                }}
                                                            </ProFormDependency>

                                                        </ProFormGroup>

                                                    </ProFormList>
                                                </>

                                            )
                                        }


                                        if (report_type == ReportTypeBulkChannel || report_type == ReportTypeStaticBulkChannel) {
                                            return (
                                                <>
                                                    <ProFormGroup>
                                                        <ProFormRadio.Group
                                                            name={"range"}
                                                            label="Range"
                                                            radioType="button"
                                                            options={[
                                                                {label: 'Month', value: Month,},
                                                                {label: 'Quarter', value: Quarter,},
                                                                {label: 'Half-Year', value: HalfYear,},
                                                                {label: 'Year', value: Year,},
                                                            ]}
                                                            initialValue={Month}
                                                            rules={[{required: true}]}
                                                        />


                                                        <ProFormDependency name={['range']}>
                                                            {({range}) => {
                                                                // let range=range.range
                                                                // console.log({range})
                                                                if (!range) {
                                                                    return <Empty
                                                                        description={"Select range"}/>;
                                                                }
                                                                if (range == Month) {
                                                                    return (
                                                                        <>
                                                                            <ProFormDatePicker.Month name="period"
                                                                                                     label="Period"
                                                                                                     fieldProps={{
                                                                                                         format: 'YYYY-MM',
                                                                                                         // onChange:(v,d)=>{
                                                                                                         //     console.log(v,d)
                                                                                                         // }
                                                                                                     }}

                                                                                                     rules={[{
                                                                                                         // type: 'object',
                                                                                                         required: true,
                                                                                                     }]}
                                                                            />

                                                                        </>
                                                                    )
                                                                }
                                                                if (range == Quarter) {
                                                                    return <ProFormDatePicker.Quarter name="period"
                                                                                                      label="Period"
                                                                                                      fieldProps={{
                                                                                                          format: "YYYY-Q"
                                                                                                      }}

                                                                                                      rules={[{
                                                                                                          required: true,
                                                                                                      }]}
                                                                    />
                                                                }
                                                                if (range == HalfYear) {
                                                                    return <ProFormDatePicker.Year name="period"
                                                                                                   label="Period"
                                                                                                   addonAfter={
                                                                                                       <ProFormRadio.Group
                                                                                                           name="halfno"
                                                                                                           noStyle
                                                                                                           radioType="button"
                                                                                                           options={[
                                                                                                               {
                                                                                                                   label: '1st',
                                                                                                                   value: 1,
                                                                                                               },
                                                                                                               {
                                                                                                                   label: '2nd',
                                                                                                                   value: 2,
                                                                                                               },
                                                                                                           ]}
                                                                                                           initialValue={1}
                                                                                                           rules={[{required: true}]}
                                                                                                           // transform={(_) => ({})}
                                                                                                       />
                                                                                                   }
                                                                                                   fieldProps={{
                                                                                                       format: 'YYYY'
                                                                                                   }}

                                                                                                   rules={[{
                                                                                                       required: true,
                                                                                                   }]}
                                                                    />
                                                                }
                                                                if (range == Year) {
                                                                    return <ProFormDatePicker.Year name="period"
                                                                                                   label="Period"
                                                                                                   fieldProps={{
                                                                                                       format: 'YYYY'
                                                                                                   }}

                                                                                                   rules={[{
                                                                                                       required: true,
                                                                                                   }]}
                                                                    />
                                                                }
                                                                return null
                                                            }
                                                            }
                                                        </ProFormDependency>

                                                        <ProFormDependency name={['range']}>
                                                            {({range}) => {
                                                                // if (range == HalfYear || range == Year) return null
                                                                return (<ProFormRadio.Group
                                                                    name={"verbose"}
                                                                    label="Verbose"
                                                                    radioType="button"
                                                                    options={[
                                                                        {label: 'Yes', value: Verbose,},
                                                                        {label: 'No', value: NonVerbose,}
                                                                    ]}
                                                                    initialValue={Verbose}
                                                                    rules={[{required: true}]}
                                                                    // disabled={range == Year}
                                                                    // help={range == HalfYear || range == Year ? 'Only non-comparative reports can be exported.' : 'Applies to every template request where Verbose=Auto.'}
                                                                    help={range == Year ? 'Only non-verbose reports can be exported.' : 'Applies to every template request where Verbose=Auto.'}
                                                                />)
                                                            }}
                                                        </ProFormDependency>
                                                    </ProFormGroup>

                                                    <br/>
                                                    <Row>
                                                        <Space direction={'horizontal'} align={'baseline'}>
                                                            <Typography.Text>Channels List</Typography.Text>
                                                            <Button
                                                                danger size={"small"}
                                                                onClick={this.onClearChannelsList}
                                                                disabled={this.state.loadingChannelsFromTags == true}
                                                                icon={<DeleteOutlined/>}>Clear all
                                                            </Button>
                                                        </Space>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Space direction={'horizontal'} align={'baseline'}>
                                                            <Typography.Text type={'secondary'}>Add channels using
                                                                tags:</Typography.Text>
                                                            <ProFormSelect
                                                                name={'tags'}
                                                                placeholder="Please select tags"
                                                                showSearch
                                                                width={350}
                                                                fieldProps={{
                                                                    mode: 'multiple',
                                                                    maxTagCount: 'responsive',
                                                                    showArrow: true
                                                                }}
                                                                disabled={this.state.loadingChannelsFromTags == true}
                                                                request={async (params) => {
                                                                    try {
                                                                        const response = await postRequest('/api/tags/getcategoryvaluesdrawer')
                                                                        // console.log({response});
                                                                        let opts = response?.data.map((tag: TagCategoryValueDrawer) => {
                                                                            return {
                                                                                value: tag.id,
                                                                                // label: <Badge color={tag.color} text={tag.value + ' (' + tag.category_name + ')'}/>
                                                                                label: tag.value + ' (' + tag.category_name + ')'
                                                                            }
                                                                        })
                                                                        return opts
                                                                    } catch (e) {
                                                                        notification.error({message: e.response.data.message})
                                                                        return null
                                                                    }
                                                                }}
                                                            />

                                                            <Button onClick={this.onLoadChannels}
                                                                    disabled={this.state.loadingChannelsFromTags == true}
                                                                // size={'middle'}
                                                                    icon={<PlusOutlined/>}>Add channels with these tags
                                                            </Button>
                                                        </Space>

                                                    </Row>
                                                    <Row>
                                                        <Space align={'baseline'}>
                                                            <Typography.Text
                                                                // style={{marginBottom: 32}}
                                                                type={'secondary'}>Or
                                                                enter channel(s) manually:</Typography.Text>

                                                            <ProFormSelect
                                                                name={"channel_select"}
                                                                // initialValue={'UC9DGkZrmvWPk-yqLawxTcfQ'}
                                                                request={async () => {
                                                                    let res = await postRequest('/api/youtubereports/supported_channels_data')
                                                                    return res.data
                                                                }}
                                                                disabled={this.state.loadingChannelsFromTags == true}
                                                                width={430}
                                                                showSearch
                                                                placeholder={"Please select channel"}
                                                            />
                                                            <Button
                                                                onClick={this.onAddChannelToList}
                                                                disabled={this.state.loadingChannelsFromTags == true}
                                                                icon={<PlusOutlined/>}>Add channel</Button>
                                                        </Space>
                                                    </Row>
                                                    <Spin spinning={this.state.loadingChannelsFromTags == true}>
                                                        <ProFormList
                                                            name={['bulk_channel_params']}
                                                            // label="Report Parameters"
                                                            // label={}
                                                            creatorButtonProps={false}
                                                            // creatorButtonProps={{creatorButtonText: "Add Channel"}}
                                                            deleteIconProps={{tooltipText: "Delete"}}
                                                            copyIconProps={{tooltipText: "Copy"}}
                                                            // initialValue={[{}]}
                                                            // initialValue={[
                                                            //     {channel_id: 'UCnJnnEztMhpBEZaVORLriFg'},
                                                            //     {channel_id: 'UCcbNHNmULeU1OoNylpPIRQQ'},
                                                            // ]}
                                                            key={'bulk-channel-list'}
                                                        >
                                                            {(field, index, op) => {
                                                                // console.log(field, index, op)
                                                                return (
                                                                    // <ProFormGroup>
                                                                    // <Col span={8}>
                                                                    <>
                                                                        {/*<ProFormDigit initialValue={index + 1} readonly width={'xs'}/>*/}
                                                                        <ProFormText
                                                                            // disabled
                                                                            name={"channel_id"}
                                                                            // label="Channel ID"
                                                                            // formItemProps={{wrapperCol: {span: 24}, labelAlign:'left'}}
                                                                            rules={[{required: true}]}
                                                                            // initialValue={'UC9DGkZrmvWPk-yqLawxTcfQ'}
                                                                            // request={async () => {
                                                                            //     let res = await postRequest('/api/youtubereports/supported_channels_data')
                                                                            //     return res.data
                                                                            // }}
                                                                            width={500}
                                                                            // showSearch
                                                                            addonBefore={index + 1}
                                                                            placeholder={"Please select channel"}
                                                                            addonAfter={
                                                                                <Space>
                                                                                    <Form.Item noStyle shouldUpdate>
                                                                                        {(form) => {
                                                                                            const channel_id = form.getFieldValue('bulk_channel_params')[index]?.channel_id
                                                                                            // console.log(channel_id)
                                                                                            if (!channel_id) return null
                                                                                            return <>
                                                                                                <ChannelInfoData
                                                                                                    channel_id={channel_id}/>
                                                                                            </>
                                                                                        }}
                                                                                    </Form.Item>
                                                                                    {/*{true && <Progress percent={100} showInfo={false} status={'active'} />}*/}

                                                                                </Space>
                                                                            }
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                            }
                                                        </ProFormList>
                                                    </Spin>
                                                </>

                                            )
                                        }


                                        return null;
                                    }}


                                </ProFormDependency>

                                {/*<Row>*/}
                                {/*    <Col span={24}>*/}
                                <ProFormDependency name={['report_type']} ignoreFormListField style={{width: '100%'}}>
                                    {({report_type}) => {
                                        // console.log({report_type})
                                        if (!report_type) {
                                            return null;
                                            // return <Empty description={"Select a report type"}/>;
                                        }

                                        if (report_type.includes("Static")) {
                                            return <>
                                                <Alert
                                                    message={"The templates included in the static report are the following:"}
                                                    showIcon
                                                />
                                                <Alert
                                                    message={"" +
                                                        // "The templates included in the static report are the following:\n" +
                                                        "- Key Channel Metrics\n" +
                                                        // "\t* Key Channel Metrics\n" +
                                                        "- Performance Overview\n" +
                                                        "\t* Views\n" +
                                                        "\t* Watchtime\n" +
                                                        "\t* Top Videos\n" +
                                                        "\t* Subscribers vs Non Subscribers Watchtime\n" +
                                                        "\t* Subscribers Growth\n" +
                                                        "\t* Subscribers Source\n" +
                                                        "\t* Subscribers YouTube Watch Page\n" +
                                                        "- Channel Reach\n" +
                                                        "\t* Traffic Sources (Views)\n" +
                                                        "\t* Traffic Sources: YouTube Search (Keywords)\n" +
                                                        "\t* Devices\n" +
                                                        "\t* Playback Locations\n" +
                                                        "- Audience Demographics\n" +
                                                        "\t* Age Groups\n" +
                                                        "\t* Gender\n" +
                                                        "\t* Geography\n" +
                                                        "- Engagement\n" +
                                                        "\t* Likes\n" +
                                                        "\t* Shares\n" +
                                                        "\t* Endscreens/Cards\n" +
                                                        "\t* Playlists\n"
                                                        // "Please not that these templates cannot be modified by the user in the 'static' report type."
                                                    }
                                                    type={"info"}
                                                    // showIcon
                                                    style={{whiteSpace: "pre"}}
                                                />
                                                <Alert
                                                    message={"Please not that these templates cannot be modified by the user in the 'static' report type."}
                                                    showIcon
                                                />
                                                <br/>
                                            </>
                                        }

                                        return <ProFormList
                                            name={['report_template_requests']}
                                            label="Template Requests"
                                            // initialValue={[{}, {}]}
                                            //test with a default config
                                            initialValue={[
                                                {
                                                    "template_name": "BasicStatsVideoReportTemplate",
                                                    "metrics": [
                                                        "views",
                                                        "estimatedMinutesWatched",
                                                        "shares",
                                                        "comments",
                                                        "likes",
                                                        "dislikes",
                                                        "subscribersGained",
                                                    ],
                                                    "dimensions": []
                                                },
                                                // {
                                                //     "template_name": "EngagementAndContentSharingVideoReportTemplate",
                                                //     "metrics": [
                                                //         "shares"
                                                //     ],
                                                //     "dimensions": [
                                                //         "sharingService"
                                                //     ],
                                                //     "chart_type": "AREA"
                                                // }
                                                {
                                                    template_name: "TimeBasedVideoReportTemplate",
                                                    metrics: ["views"],
                                                    dimensions: ["day"],
                                                },
                                                {
                                                    template_name: "TimeBasedVideoReportTemplate",
                                                    metrics: ["estimatedMinutesWatched"],
                                                    dimensions: ["day"],
                                                },
                                                // TODO
                                                // {
                                                //     template_name: "PlaybackDetailsVideoReportTemplate",
                                                //     metrics: ["estimatedMinutesWatched"],
                                                //     dimensions: ["subscribedStatus"],
                                                // },
                                                {
                                                    template_name: "TimeBasedVideoReportTemplate",
                                                    metrics: ["subscribersGained"],
                                                    dimensions: ["day"],
                                                    chart_type: "COLUMN"
                                                },
                                                {
                                                    template_name: "PlaybackLocationsVideoReportTemplate",
                                                    metrics: ["views"],
                                                    dimensions: ["insightPlaybackLocationType"],
                                                    chart_type: "COLUMN"
                                                },
                                                {
                                                    template_name: "ViewerDemographicsVideoReportTemplate",
                                                    metrics: ["viewerPercentage"],
                                                    dimensions: ["ageGroup"],
                                                    chart_type: "COLUMN"
                                                },
                                                {
                                                    template_name: "ViewerDemographicsVideoReportTemplate",
                                                    metrics: ["viewerPercentage"],
                                                    dimensions: ["gender"],
                                                    chart_type: "COLUMN"
                                                },
                                                {
                                                    template_name: "UserGeographyVideoReportTemplate",
                                                    metrics: ["views"],
                                                    dimensions: ["country"],
                                                    chart_type: "COLUMN"
                                                },
                                                {
                                                    template_name: "EngagementAndContentSharingVideoReportTemplate",
                                                    metrics: ["shares"],
                                                    dimensions: ["sharingService"],
                                                    chart_type: "COLUMN"
                                                },
                                                {
                                                    template_name: "TrafficSourcesVideoReportTemplate",
                                                    metrics: ["views"],
                                                    dimensions: ["insightTrafficSourceType"],
                                                    chart_type: "COLUMN"
                                                },
                                                {
                                                    template_name: "DeviceTypeAndOperatingSystemVideoReportTemplate",
                                                    metrics: ["views"],
                                                    dimensions: ["deviceType"],
                                                    chart_type: "COLUMN"
                                                },
                                                {
                                                    template_name: "TimeBasedVideoReportTemplate",
                                                    metrics: ["likes"],
                                                    dimensions: ["day"],
                                                },
                                                {
                                                    template_name: "TimeBasedVideoReportTemplate",
                                                    metrics: ["shares"],
                                                    dimensions: ["day"],
                                                },
                                                {
                                                    template_name: "TimeBasedVideoReportTemplate",
                                                    metrics: ["cardClickRate"],
                                                    dimensions: ["day"],
                                                },
                                                {
                                                    template_name: "TimeBasedVideoReportTemplate",
                                                    metrics: ["cardTeaserClickRate"],
                                                    dimensions: ["day"],
                                                },
                                            ]}
                                            creatorButtonProps={{creatorButtonText: "Add template"}}
                                            deleteIconProps={{tooltipText: "Delete"}}
                                            copyIconProps={{tooltipText: "Copy"}}
                                        >


                                            <ProFormGroup>
                                                {/*Templates: Warning this is a select with groups*/}
                                                {/*<Row  gutter={[32,32]} wrap  >*/}
                                                {/*<Col span={16}>*/}
                                                <ProFormSelect name="template_name" label={"Template"}
                                                               rules={[{required: true}]} width={300}
                                                    // formItemProps={{wrapperCol:{span:12}}}
                                                    // fieldProps={{
                                                    //     labelInValue: true,
                                                    //     dropdownMatchSelectWidth: true
                                                    // }}
                                                               request={async () => {
                                                                   let res = await postRequest('/api/youtubereports/supported_report_templates')
                                                                   let data = res.data
                                                                   // console.log({data})
                                                                   //TODO
                                                                   // let options: { label?: string, options?: { label?: string, value?: string }[] }[] = []
                                                                   // for (const [label, values] of Object.entries(data)) {
                                                                   //     options.push({
                                                                   //         label: capitalize(label.substr(1)),
                                                                   //         options: (values as string[]).map((v: string) => ({
                                                                   //             label: camelCaseSplit(v), value: v
                                                                   //         }))
                                                                   //     })
                                                                   // }
                                                                   let options: {
                                                                       label?: string,
                                                                       value?: string
                                                                   }[] = []
                                                                   data.map((i: string) => {
                                                                       const label = capitalize(camelCaseSplit(i)).split(' ').slice(0, camelCaseSplit(i).split(' ').length - 3).join(' ')
                                                                       options.push({
                                                                           label: label,
                                                                           value: i
                                                                       })
                                                                   })
                                                                   // console.log(options)
                                                                   return options
                                                               }}/>
                                                {/*</Col>*/}
                                                {/*<Col span={16}>*/}
                                                <ProFormSelect name="metrics" label={"Metrics"} showSearch
                                                               width={300}
                                                               fieldProps={{
                                                                   dropdownMatchSelectWidth: true,
                                                                   mode: 'multiple',
                                                                   maxTagCount: 'responsive'
                                                               }}
                                                               request={async () => {
                                                                   let res = await postRequest('/api/youtubereports/supported_metrics')
                                                                   let data = res.data
                                                                   let options: {
                                                                       label?: string,
                                                                       value?: string
                                                                   }[] = []
                                                                   data.map((i: string) => options.push({
                                                                       label: capitalize(camelCaseSplit(i)),
                                                                       value: i
                                                                   }))
                                                                   // console.log({options})
                                                                   return options
                                                               }}/>
                                                {/*</Col>*/}

                                                <ProFormSelect name="dimensions" label={"Dimensions"} showSearch
                                                               width={290} rules={
                                                    [{
                                                        validator: (rule, value, callback) => {
                                                            if (value) {
                                                                if (value.length > 1) {
                                                                    callback("No more than 1 dimension");
                                                                } else if (value.length <= 5) {
                                                                    callback();
                                                                }
                                                            }
                                                            return;
                                                        }
                                                    }]
                                                }
                                                               fieldProps={{
                                                                   mode: 'multiple',
                                                                   // mode: 'tags',
                                                                   maxTagCount: 'responsive'
                                                               }}
                                                    // formItemProps={{wrapperCol:{span:16}}}
                                                               request={async () => {
                                                                   let res = await postRequest('/api/youtubereports/supported_dimensions')
                                                                   let data = res.data
                                                                   let options: {
                                                                       label?: string,
                                                                       value?: string
                                                                   }[] = []
                                                                   data.map((i: string) => options.push({
                                                                       label: capitalize(camelCaseSplit(i)),
                                                                       value: i
                                                                   }))
                                                                   // console.log({options})
                                                                   return options
                                                               }}/>

                                                <ProFormDependency name={['dimensions']}>
                                                    {({dimensions}) => {
                                                        // console.log({dimensions})
                                                        if (!dimensions) return null
                                                        // if (dimensions.length == 0) return <ProFormSelect disabled name="chart_type" label={"Chart type"} width={100} />
                                                        return (
                                                            <ProFormSelect name="chart_type" width={100}
                                                                           label={"Chart type"}
                                                                           request={async () => {
                                                                               let res = await postRequest('/api/youtubereports/supported_charts')
                                                                               let data = res.data
                                                                               let options: {
                                                                                   label?: string,
                                                                                   value?: string
                                                                               }[] = []
                                                                               data.map((i: string) => options.push({
                                                                                   label: i,
                                                                                   value: i
                                                                               }))
                                                                               // console.log({options})
                                                                               return options
                                                                           }}
                                                                           disabled={dimensions.length == 0}
                                                            />
                                                        )
                                                    }}
                                                </ProFormDependency>


                                                <Form.Item noStyle shouldUpdate>
                                                    {(form) => {
                                                        // console.log(form.getFieldValue('verbose'))
                                                        // console.log(form.getFieldValue('channel_params')[0].range)
                                                        // const verbose = form.getFieldValue('verbose')
                                                        const report_type = form.getFieldValue('report_type')
                                                        let range
                                                        if (report_type == ReportTypeChannel)
                                                            range = form.getFieldValue('channel_params')[0].range
                                                        if (report_type == ReportTypeBulkChannel)
                                                            range = form.getFieldValue('range')
                                                        // if (range == HalfYear || range == Year) return null

                                                        if (report_type == ReportTypeVideoToVideo) return null
                                                        return (
                                                            <ProFormRadio.Group
                                                                name={"verbose"}
                                                                label="Verbose"
                                                                radioType="button"
                                                                options={[
                                                                    {label: 'Yes', value: Verbose,},
                                                                    {label: 'No', value: NonVerbose,},
                                                                    {label: 'Auto', value: AutoVerbose,},
                                                                ]}
                                                                initialValue={AutoVerbose}
                                                                rules={[{required: true}]}
                                                                disabled={range == HalfYear || range == Year}
                                                            />
                                                        );
                                                    }}
                                                </Form.Item>


                                                {/*</Row>*/}

                                            </ProFormGroup>

                                        </ProFormList>

                                    }}
                                </ProFormDependency>
                                {/*</Col>*/}
                                {/*</Row>*/}
                            </ProForm>
                        </ConfigProvider>
                    </Spin>


                    {/*Result Modal For single channel*/}
                    {this.state.success &&
                        <Modal
                            visible={this.state.resultModalVisible}
                            onOk={() => {
                                this.setState({resultModalVisible: false})
                            }}
                            closable={false}
                            maskClosable={true}
                            cancelButtonProps={{hidden: true}}
                        >
                            <Result title={"Report Created Successfully!"} status="success" subTitle={
                                <>
                                    {/*TODO*/}
                                    {/*<Typography.Paragraph type={"secondary"} key={this.state.success.presentationId}*/}
                                    {/*    copyable={{text: this.state.success.presentationUrl, tooltips: ['Copy URL','Copied']}}>Presentation*/}
                                    {/*    Id: {this.state.success.presentationId}</Typography.Paragraph>*/}
                                    {/*<Typography.Text type={"secondary"}>Presentation Id: {this.state.success.presentationId}</Typography.Text>*/}
                                    {/*TODO*/}
                                    {/*<Typography.Paragraph type={"secondary"} key={this.state.success.spreadsheetId}*/}
                                    {/*    copyable={{text: this.state.success.spreadsheetURL, tooltips: ['Copy URL','Copied']}}>Spreadsheet*/}
                                    {/*    Id: {this.state.success.spreadsheetId}</Typography.Paragraph>*/}
                                    {/*<Typography.Text type={"secondary"}>Spreadsheet Id: {this.state.success.spreadsheetId}</Typography.Text>*/}
                                    <Typography.Text type={"secondary"}>Shared with: digitalminds.com</Typography.Text>
                                </>
                            }
                                    extra={[
                                        <a href={this.state.success.presentationUrl} target={"_blank"}>
                                            <Button size={"large"} type="primary" key="presentation"
                                                    icon={<FilePptOutlined/>}>Go To
                                                Presentation</Button>
                                        </a>,
                                        <a href={this.state.success.spreadsheetUrl} target={"_blank"}>
                                            <Button size={"large"} key="spreadsheet" icon={<FileExcelOutlined/>}>Go To
                                                Spreadsheet</Button>
                                        </a>
                                    ]}
                            />

                        </Modal>
                    }

                    {/*Processing/Result Modal For multi channel*/}
                    {this.state.resultBulkModalVisible && this.state.formRef.current?.getFieldValue('report_type').includes("Bulk") && this.state.formRef.current?.getFieldValue('bulk_channel_params') !== undefined &&
                        // {true &&
                        <Modal
                            visible={this.state.resultBulkModalVisible}
                            // visible={true}
                            onOk={() => {
                                this.setState({resultBulkModalVisible: false})
                            }}
                            width={1100}
                            bodyStyle={{height: 500, overflowY: 'auto'}}
                            // maskStyle={{width:2000}}
                            // closable={true}
                            okButtonProps={{disabled: this.state.processing}}
                            closable={false}
                            maskClosable={false}
                            cancelButtonProps={{hidden: true}}
                        >
                            <Result title={this.state.processing ? "Processing Reports..." : "Done!"}
                                    icon={this.state.processing ? <MonitorOutlined/> :
                                        <CheckCircleFilled style={{color: '#52c41a'}}/>} subTitle={
                                <>
                                    <br/>
                                    <ConfigProvider locale={enUSIntl}>
                                        <ProForm size={"large"} submitter={false}>
                                            <Row justify={'center'} align={'middle'}>
                                                <ProFormList
                                                    name={['result_multi_channel']}
                                                    // label="Report Parameters"
                                                    creatorButtonProps={false}
                                                    deleteIconProps={false}
                                                    copyIconProps={false}
                                                    initialValue={this.state.formRef.current?.getFieldValue('bulk_channel_params')}
                                                    // initialValue={[
                                                    //     {channel_id: 'UCnJnnEztMhpBEZaVORLriFg'},
                                                    //     {channel_id: 'UCcbNHNmULeU1OoNylpPIRQQ'},
                                                    // ]}
                                                    key={'result-bulk-channel-list'}
                                                >
                                                    {(field, index, op) => {
                                                        return (
                                                            <>
                                                                <Col style={{width: 900, alignItems: 'center'}}>
                                                                    {/*<Col span={12}>*/}

                                                                    <ProFormText
                                                                        name={"channel_id"}
                                                                        readonly
                                                                        addonBefore={index + 1}
                                                                        addonAfter={
                                                                            <Space>
                                                                                <Form.Item noStyle shouldUpdate>
                                                                                    {(form) => {
                                                                                        const channel_id = this.state.formRef.current?.getFieldValue('bulk_channel_params')[index]?.channel_id
                                                                                        // console.log(channel_id)
                                                                                        // if (this.state.formRef.current?.getFieldValue('bulk_channel_params')[index]) {
                                                                                        //     console.log(this.state.formRef.current?.getFieldValue('bulk_channel_params')[index])
                                                                                        // }
                                                                                        if (!channel_id) return null
                                                                                        return <Row
                                                                                            // style={{width: 500}}
                                                                                            align={'middle'}
                                                                                            justify={'space-around'}>

                                                                                            {/*<Col span={12} >*/}
                                                                                            <Col style={{width: 300}}>
                                                                                                <ChannelInfoData
                                                                                                    channel_id={channel_id}/>
                                                                                            </Col>

                                                                                            {/*<Col span={8}>*/}
                                                                                            <Col style={{width: 200}}>
                                                                                                {/*<Progress*/}
                                                                                                {/*    size="small"*/}
                                                                                                {/*    status={'active'}*/}
                                                                                                {/*    percent={100}*/}
                                                                                                {/*    showInfo={false}/>*/}
                                                                                                {this.state.processingInfoMultipleChannels !== undefined && this.state.processingInfoMultipleChannels![channel_id]?.processing &&
                                                                                                    <Progress
                                                                                                        status={'active'}
                                                                                                        percent={100}
                                                                                                        showInfo={false}/>}
                                                                                                {this.state.processingInfoMultipleChannels !== undefined && this.state.processingInfoMultipleChannels![channel_id]?.successData &&
                                                                                                    <Progress
                                                                                                        status={'success'}
                                                                                                        percent={100}
                                                                                                        showInfo={false}/>}
                                                                                                {this.state.processingInfoMultipleChannels !== undefined && this.state.processingInfoMultipleChannels![channel_id]?.error &&
                                                                                                    <Progress
                                                                                                        status={'exception'}
                                                                                                        percent={100}
                                                                                                        showInfo={false}/>}
                                                                                            </Col>

                                                                                            {/*<Col span={4}>*/}
                                                                                            <Col style={{width: 150}}>

                                                                                                {/*<Space>*/}
                                                                                                {/*    <a href={''}*/}
                                                                                                {/*       target={"_blank"}>*/}
                                                                                                {/*        <FilePptOutlined*/}
                                                                                                {/*            style={{fontSize: 24}}/>*/}
                                                                                                {/*    </a>*/}
                                                                                                {/*    <a href={''}*/}
                                                                                                {/*       target={"_blank"}>*/}
                                                                                                {/*        <FileExcelOutlined*/}
                                                                                                {/*            style={{fontSize: 24}}/>*/}
                                                                                                {/*    </a>*/}
                                                                                                {/*</Space>*/}

                                                                                                {this.state.processingInfoMultipleChannels !== undefined && this.state.processingInfoMultipleChannels![channel_id]?.successData &&
                                                                                                    <Space>
                                                                                                        <Tooltip
                                                                                                            title={"Go To Presentation"}>
                                                                                                            <a href={this.state.processingInfoMultipleChannels![channel_id]?.successData.presentationUrl}
                                                                                                               target={"_blank"}>
                                                                                                                <FilePptOutlined
                                                                                                                    style={{fontSize: 24}}/>
                                                                                                            </a>
                                                                                                        </Tooltip>
                                                                                                        <Tooltip
                                                                                                            title={"Go To Spreadsheet"}>
                                                                                                            <a href={this.state.processingInfoMultipleChannels![channel_id]?.successData.spreadsheetUrl}
                                                                                                               target={"_blank"}>
                                                                                                                <FileExcelOutlined
                                                                                                                    style={{fontSize: 24}}/>
                                                                                                            </a>
                                                                                                        </Tooltip>
                                                                                                    </Space>
                                                                                                }
                                                                                                {this.state.processingInfoMultipleChannels !== undefined && this.state.processingInfoMultipleChannels![channel_id]?.error &&
                                                                                                    <Tooltip
                                                                                                        title={this.state.processingInfoMultipleChannels![channel_id]?.error}>
                                                                                                        <WarningOutlined/>
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                    }}
                                                                                </Form.Item>

                                                                            </Space>
                                                                        }
                                                                    />

                                                                </Col>

                                                            </>
                                                        )
                                                    }
                                                    }
                                                </ProFormList>
                                            </Row>
                                        </ProForm>
                                    </ConfigProvider>
                                </>
                            }
                                // extra={[
                                //     <a href={this.state.success.presentationUrl} target={"_blank"}>
                                //         <Button size={"large"} type="primary" key="presentation"
                                //                 icon={<FilePptOutlined/>}>Go To
                                //             Presentation</Button>
                                //     </a>,
                                //     <a href={this.state.success.spreadsheetUrl} target={"_blank"}>
                                //         <Button size={"large"} key="spreadsheet" icon={<FileExcelOutlined/>}>Go To
                                //             Spreadsheet</Button>
                                //     </a>
                                // ]}
                            />

                        </Modal>
                    }

                </PageContainer>
            </div>
        );
    }
}

const ChannelInfoData = ({channel_id}: any) => {
    const [state, setState] = useState({title: '', image_url: '', channel_id: '', image: null})
    const [hasError, setHasError] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        postRequest('/api/youtubereports/validate_search_channel', {channel_id})
            .then(res => {
                // console.log(channel_id, res.data)
                setState(res.data)
                setHasError(false)
                setLoading(false)
            }).catch(reason => {
            setHasError(true)
            setLoading(false)
        })

    }, [channel_id])
    return (
        <>
            {loading ? <Spin delay={300}/> :
                <>
                    {hasError ?
                        <Typography.Text
                            type={'danger'}>Error</Typography.Text>
                        :
                        <Row
                            style={{padding: 12}}>
                            <Space>
                                <Avatar src={<Image
                                    src={`data:image/jpeg;base64,${state?.image}`}
                                    // src={state.image_url}
                                />}
                                        shape={'circle'}/>
                                <a href={'https://www.youtube.com/channel/' + state.channel_id}
                                   target={'_blank'}>
                                    <Typography.Text
                                        type={'secondary'}>{state.title}</Typography.Text>
                                </a>
                            </Space>
                        </Row>
                    }
                </>
            }
        </>
    )
}


export default SmartReports;