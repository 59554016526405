import React, {Component} from 'react';
import './index.css';

import {Card, Col, Layout, Row} from 'antd';
import {
    ArrowRightOutlined,
    FileTextOutlined,
    LaptopOutlined,
    LineChartOutlined,
    RiseOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import Avatar from 'antd/lib/avatar/avatar';
import {PageContainer} from "@ant-design/pro-components";

export interface IIndexProps {
    //Here we pass the Props Interface
}

export interface IIndexState {
    //here we pass the State Interface
}

//class ComponentName Component<PropsInterface, StateInterface>
class Index extends Component<IIndexProps, IIndexState> {
    render() {
        const {Header} = Layout;

        const cardWidth = 300;
        return (
            <>
                {/* NAVBAR */}

                <PageContainer>
                    <Row gutter={16} justify="space-around">
                        {/* <Col>
                     <Card
                        bordered={true}
                        actions={[
                           <Link to="/crm">
                              <ArrowRightOutlined />
                           </Link>,
                        ]}
                        style={{ width: cardWidth, marginTop: 16 }}
                     >
                        <Card.Meta
                           title="CRM"
                           description="Customer Relationship Management"
                           avatar={<Avatar icon={<UserOutlined />} />}
                        />
                     </Card>
                  </Col> */}

                        <Col>
                            <Card
                                bordered={true}
                                actions={[
                                    <Link to="/crm">
                                        <ArrowRightOutlined/>
                                    </Link>,
                                ]}
                                style={{width: cardWidth, marginTop: 16}}
                            >
                                <Card.Meta
                                    title="Customers"
                                    description="Search Customers"
                                    avatar={<Avatar icon={<UserOutlined/>}/>}
                                />
                            </Card>
                        </Col>

                        <Col>
                            <Card
                                bordered={true}
                                actions={[
                                    <Link to="/crm/assets/list">
                                        <ArrowRightOutlined/>
                                    </Link>,
                                ]}
                                style={{width: cardWidth, marginTop: 16}}
                            >
                                <Card.Meta
                                    title="Assets"
                                    description="Search Assets"
                                    avatar={<Avatar icon={<RiseOutlined/>}/>}
                                />
                            </Card>
                        </Col>

                        <Col>
                            <Card
                                bordered={true}
                                actions={[
                                    <Link to="/crm/contracts/list">
                                        <ArrowRightOutlined/>
                                    </Link>,
                                ]}
                                style={{width: cardWidth, marginTop: 16}}
                            >
                                <Card.Meta
                                    title="Contracts"
                                    description="Search Contracts"
                                    avatar={<Avatar icon={<FileTextOutlined/>}/>}
                                />
                            </Card>
                        </Col>

                        <Col>
                            <Card
                                bordered={true}
                                actions={[
                                    <Link to="/erp/paymentcycles">
                                        <ArrowRightOutlined/>
                                    </Link>,
                                ]}
                                style={{width: cardWidth, marginTop: 16}}
                            >
                                <Card.Meta
                                    title="ERP"
                                    description="Enterprise Resource Planning"
                                    avatar={<Avatar icon={<LaptopOutlined/>}/>}
                                />
                            </Card>
                        </Col>

                        <Col>
                            <Card
                                bordered={true}
                                actions={[
                                    <Link to="/reporting/smartreports">
                                        <ArrowRightOutlined/>
                                    </Link>,
                                ]}
                                style={{width: cardWidth, marginTop: 16}}
                            >
                                <Card.Meta
                                    title="Smart Reports"
                                    description="Dynamic channel reports"
                                    avatar={<Avatar icon={<LineChartOutlined/>}/>}
                                />
                            </Card>
                        </Col>
                        {/* <Col>
                     <Card
                        bordered={true}
                        actions={[
                           <Link to="/admin">
                              <ArrowRightOutlined />
                           </Link>,
                        ]}
                        style={{ width: cardWidth, marginTop: 16 }}
                     >
                        <Card.Meta
                           title="Admin"
                           description="Admin Dashboard"
                           avatar={<Avatar icon={<SettingOutlined />} />}
                        />
                     </Card>
                  </Col> */}
                    </Row>
                </PageContainer>

                {/* <Layout>

               <Navbar />

               <Row wrap={false}>
                  <Col span={2} style={{ width: 200, minWidth: 200 }}>
                     <Sidemenu />
                  </Col>

                  <Col span={22} flex="auto">
                     <Layout style={{ padding: '0 24px 24px' }}>
                        <BreadcrumbSystem links={['Home']} titles={['Home']} />
                        <Content
                           className="site-layout-background"
                           style={{
                              padding: 24,
                              margin: 0,
                              minHeight: 280,
                           }}
                        >
                           This is the homepage
                        </Content>
                     </Layout>
                  </Col>
               </Row>
            </Layout> */}

                {/* <Footer style={{ textAlign: 'center', background : 'inherit' }}><i>Digital Minds Ltd. {new Date().getFullYear()}</i></Footer> */}

                {/* <Navbar title="Home"/> */}
                {/* <Grid style={{width:'100%', height:'100%'}}>
				<GridCell span={1}/>
				<GridCell span={5}>
					<GridRow>
					</GridRow>
					<GridRow >
					<GridCell>
					<h1>Links</h1>
					<Card outlined style={{ width: '21rem', listStyleType : 'none' }}>
					  <Typography
					    use="subtitle1"
					    tag="div"
					    style={{ padding: '0.5rem 1rem' }}
					    theme="textSecondaryOnBackground"
					  >
					    Shortcuts
					  </Typography>
					  <ListDivider />
					  <CardPrimaryAction>
					    <div style={{ padding: '1rem' }}>
					      <Typography use="headline5" tag="div">
					        Customers
					      </Typography>
					      <Typography use="body1" tag="p" theme="textSecondaryOnBackground">
					       List all customers. Search through customers.
					      </Typography>
					    </div>
					  </CardPrimaryAction>
					  <ListDivider />
					  <CardPrimaryAction>
					    <div style={{ padding: '1rem' }}>
					      <Typography use="headline5" tag="div">
						 	 Payments
					      </Typography>
					      <Typography use="body1" tag="p" theme="textSecondaryOnBackground">
					        Initiate. Load. Match. Compute.
					      </Typography>
					    </div>
					  </CardPrimaryAction>
					  <ListDivider />
					  <CardPrimaryAction onClick={()=>{}}>
					    <div style={{ padding: '1rem' }}>
					      <Typography use="headline5" tag="div">
						  	Paypal Bulk paymets
					      </Typography>
					      <Typography use="body1" tag="p" theme="textSecondaryOnBackground">
					        Paypal tool for bulk payments.
					      </Typography>
					    </div>
					  </CardPrimaryAction>
					</Card>
					</GridCell>
					</GridRow>				 
				</GridCell>
				<GridCell span={5}>
			 		 
					  <GridRow>
					  
					  </GridRow>
					  <GridRow >
						  <GridCell>
					  <h1>Alerts</h1>
					  <Card outlined style={{ width: '21rem' ,listStyleType : 'none' }}>
						<Typography
						  use="subtitle1"
						  tag="div"
						  style={{ padding: '0.5rem 1rem' }}
						  theme="textSecondaryOnBackground"
						>
						  Check out the new alerts
						</Typography>
						<ListDivider />
						<CardPrimaryAction>
						  <div style={{ padding: '1rem' }}>
							<Typography use="headline5" tag="div">
							  Customers Alerts
							</Typography>
							<Typography use="body1" tag="p" theme="textSecondaryOnBackground">
							 Monitor the new alerts
							</Typography>
						  </div>
						</CardPrimaryAction>
						<ListDivider />
						<CardPrimaryAction>
						  <div style={{ padding: '1rem' }}>
							<Typography use="headline5" tag="div">
								Payments Alerts
							</Typography>
							<Typography use="body1" tag="p" theme="textSecondaryOnBackground">
							Monitor the new alerts
							</Typography>
						  </div>
						</CardPrimaryAction>
						<ListDivider />
						<CardPrimaryAction onClick={()=>{}}>
						  <div style={{ padding: '1rem' }}>
							<Typography use="headline5" tag="div">
								Paypal Bulk Alerts
							</Typography>
							<Typography use="body1" tag="p" theme="textSecondaryOnBackground">
							Monitor the new alerts
							</Typography>
						  </div>
						</CardPrimaryAction>
					  </Card>
					  </GridCell>
					  </GridRow>
				  </GridCell>
				<GridCell span={1}/>
			</Grid> */}
            </>
        );
    }
}

export default Index;
