import {Button, ConfigProvider, notification, Row, Space} from 'antd';
import React, {Component} from 'react';
import './index.css';
import enUSIntl from "antd/lib/locale/en_US";
import ProTable, {ActionType, ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import {ClearOutlined, DownloadOutlined, FileTextOutlined, UploadOutlined} from "@ant-design/icons";
import CSVReader from "react-csv-reader";
import {auth} from "../../../config/firebase";
import axios from "axios";
import {API} from "../../../common/constants";
import withRouter from "../../../components/withRouter";


export interface IPaymentCycleBalancesProps {
    //Here we pass the Props Interface
    params: any
    pageSize?: number
    polling?: false | number
}

export interface IPaymentCycleBalancesState {
    importedData?: PaymentCycleBalance[]
    actionRef: React.RefObject<ActionType>
    data?: PaymentCycleBalance[]
    loading: boolean
    filteredData?: PaymentCycleBalance[]
}


type PaymentCycleBalance = {
    cycle_id: string
    customer_id: number
    balance_before: number
    balance_after: number
};


//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleBalances extends Component<IPaymentCycleBalancesProps, IPaymentCycleBalancesState> {

    constructor(props: IPaymentCycleBalancesProps) {
        super(props);

        this.state = {
            loading: false,
            actionRef: React.createRef<ActionType>()
        }

    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {

    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        this.setState({loading: true})
        try {
            const res = await postRequest('/api/paymentCycles/getBalancesInfo/' + this.props.params.id)
            // console.log(res.data)
            this.setState({data: res.data, loading: false})
        } catch (e) {
            // return
            this.setState({loading: false})
        }
    }

    columns: ProColumns<PaymentCycleBalance>[] = [
        {title: 'Customer Id', dataIndex: 'customer_id', valueType: 'text',},
        {
            title: 'Balance Before',
            dataIndex: 'balance_before',
            valueType: 'text',
            hideInSearch: true,
            renderText: text => text.toLocaleString('el-GR') + ' €',
            sorter: (a, b) => a.balance_before - b.balance_before
        },
        // {title: 'Balance After', dataIndex: 'balance_after', valueType: 'digit'},
    ];

    onSubmit = async () => {
        if (!this.state.importedData) {
            notification.warning({message: 'No data loaded'})
            return
        }
        try {
            const res = await postRequest('/api/paymentCycles/uploadBalancesInfo/' + this.props.params.id, this.state.importedData, false)
            console.log(res)
            notification.success({message: 'OK'})
            this.state.actionRef.current?.reload()

            this.onClear()
        } catch (e) {
            notification.error({message: 'Something went wrong'})
        }
    }

    onClear = () => {
        // @ts-ignore
        document.querySelector('.csv-input').value = ''
        this.setState({importedData: undefined})
    }

    downloadSample = async () => {
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(API + '/api/paymentCycles/sampleFiles/balances', null,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
            .then(res => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'sample_balances.csv');
                document.body.appendChild(link);
                link.click();

                notification.success({message: 'Success'})
            }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    onSearch = (params: PaymentCycleBalance) => {
        let filteredData: PaymentCycleBalance[] = JSON.parse(JSON.stringify(this.state.data))
        Object.keys(params).map((key) => {
            if (params[key] == '') return true
            filteredData = filteredData.filter((datum) => {
                // console.log(key, params[key], datum[key].toString().toLowerCase().includes(params[key].toLowerCase()))
                // return datum[key] == params[key] // strict comparison
                return datum[key].toString().toLowerCase().includes(params[key].toLowerCase()) // loose comparison
            })
        })
        this.setState({filteredData})
    }
    onReset = () => {
        this.onSearch({} as any)
    }

    render() {


        return (
            <>

                {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}
                <Row justify={"space-between"}>
                    <Space>
                        <CSVReader
                            parserOptions={{
                                header: true,
                                dynamicTyping: true,
                                transform(value: string, field: string | number): any {
                                    if (field == 'balance_before') {
                                        // replace comma with dot
                                        return value.replace(',', '.')
                                    }
                                    return value
                                }
                            }}
                            // inputRef={instance => {
                            //     console.log(instance)
                            // }}
                            // cssInputClass={"ant-btn ant-btn-default"}
                            label={'Upload / Replace Customer Balances: '}
                            onFileLoaded={(data, fileInfo, originalFile) => {
                                console.log(data, fileInfo, originalFile)
                                this.setState({importedData: data})
                            }}
                            onError={error => {
                                notification.error({message: error.message})
                            }}
                        />

                        {this.state.importedData &&
                        <>
                            <Button type={"default"} onClick={this.onSubmit}
                                    size={"middle"}>Load<UploadOutlined/></Button>
                            <Button type={"default"} danger onClick={this.onClear}
                                    size={"middle"}>Clear<ClearOutlined/></Button>
                        </>
                        }
                    </Space>

                    <Button icon={<DownloadOutlined/>} onClick={this.downloadSample}>Download Sample</Button>
                </Row>
                <br/>

                <ConfigProvider locale={enUSIntl}>

                    {/*<Card bodyStyle={{padding: 0}}>*/}

                    <ProTable<PaymentCycleBalance>
                        columns={this.columns}
                        actionRef={this.state.actionRef as any}
                        // cardBordered
                        bordered
                        // size={"small"}
                        // style={{fontSize:6}}
                        // showHeader={false}
                        // headerTitle={"Logs"}
                        // polling={this.props.polling || 0}
                        // request={async (params = {}, sort, filter) => {
                        //     try {
                        //         const res = await postRequest('/api/paymentCycles/getBalancesInfo/' + this.props.cycle_id)
                        //         const data = res.data || []
                        //         console.log(res.data)
                        //         return {data, success: true, total: data.length}
                        //     } catch (e) {
                        //         return {data: [], success: false, total: 0}
                        //     }
                        // }}
                        rowKey="customer_id"
                        // search={false}
                        locale={{
                            emptyText: <Space direction={'vertical'}> <FileTextOutlined/>No exported unmatched
                                files</Space>
                        }}
                        pagination={{pageSize: this.props.pageSize || 10, hideOnSinglePage: true}}
                        dateFormatter="string"
                        // toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                        // toolBarRender={false}
                        // toolBarRender={() => []}
                        loading={this.state.loading}
                        onSubmit={this.onSearch}
                        onReset={this.onReset}
                        search={{layout: "vertical"}}
                        dataSource={this.state.filteredData ? this.state.filteredData : this.state.data}
                        toolBarRender={false}
                    />
                    {/*</Card>*/}


                </ConfigProvider>

            </>
        );
    }
}

export default withRouter(PaymentCycleBalances);