import {Button, ConfigProvider, notification, Row, Space} from 'antd';
import React, {Component} from 'react';
import './index.css';
import enUSIntl from "antd/lib/locale/en_US";
import ProTable, {ActionType, ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import {ClearOutlined, DownloadOutlined, FileTextOutlined, UploadOutlined} from "@ant-design/icons";
import CSVReader from "react-csv-reader";
import {auth} from "../../../config/firebase";
import axios from "axios";
import {API} from "../../../common/constants";
import withRouter from "../../../components/withRouter";


export interface IPaymentCycleCustomersProps {
    //Here we pass the Props Interface
    params: any
    pageSize?: number
    polling?: false | number
}

export interface IPaymentCycleCustomersState {
    importedData?: PaymentCycleCustomer[]
    data?: PaymentCycleCustomer[]
    filteredData?: PaymentCycleCustomer[]
    loading: boolean
    actionRef: React.RefObject<ActionType>
}


type PaymentCycleCustomer = {
    cycle_id: string
    full_name: string
    customer_id: number
    tier: string
    primary_contact_email: string
    phone_number: string
    country: string
    city: string
    address: string
    zipcode: string
    vat_number: string
    passport_id_number: string
    dm_manager: string
    payment_option: string
    threshold: number
    paypal_email: string
    iban_number: string
    swift_bic_code: string
    bank_account_owner: string
    contract_start_date: string
    contract_end_date: string
    payment_currency_code: number
    payment_currency_alpha: string
};


//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleCustomers extends Component<IPaymentCycleCustomersProps, IPaymentCycleCustomersState> {

    constructor(props: IPaymentCycleCustomersProps) {
        super(props);

        this.state = {
            loading: false,
            actionRef: React.createRef<ActionType>()
        }

    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {

    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        this.setState({loading: true})
        try {
            const res = await postRequest('/api/paymentCycles/getCustomersInfo/' + this.props.params.id)
            // console.log(res.data)
            this.setState({data: res.data, loading: false})
        } catch (e) {
            // return
            this.setState({loading: false})
        }
    }
    columns: ProColumns<PaymentCycleCustomer>[] = [
        {title: 'Customer Id', dataIndex: 'customer_id', valueType: 'text'},
        {title: 'Full name', dataIndex: 'full_name', valueType: 'text'},
        {title: 'Tier', dataIndex: 'tier', valueType: 'text'},
        {title: 'Primary contact email', dataIndex: 'primary_contact_email', valueType: 'text'},
        {title: 'Phone number', dataIndex: 'phone_number', valueType: 'text',},
        {title: 'Country', dataIndex: 'country', valueType: 'text', hideInSearch: true},
        {title: 'City', dataIndex: 'city', valueType: 'text', hideInSearch: true},
        {title: 'Address', dataIndex: 'address', valueType: 'text', hideInSearch: true},
        {title: 'Zipcode', dataIndex: 'zipcode', valueType: 'text', hideInSearch: true},
        {title: 'Vat number', dataIndex: 'vat_number', valueType: 'text', hideInSearch: true},
        {title: 'Passport id number', dataIndex: 'passport_id_number', valueType: 'text', hideInSearch: true},
        {title: 'Dm manager', dataIndex: 'dm_manager', valueType: 'text', hideInSearch: true},
        {title: 'Payment option', dataIndex: 'payment_option', valueType: 'text', hideInSearch: true},
        {title: 'Threshold', dataIndex: 'threshold', valueType: 'digit', hideInSearch: true},
        {title: 'Paypal email', dataIndex: 'paypal_email', valueType: 'text', hideInSearch: true},
        {title: 'Iban number', dataIndex: 'iban_number', valueType: 'text', hideInSearch: true},
        {title: 'Swift bic code', dataIndex: 'swift_bic_code', valueType: 'text', hideInSearch: true},
        {title: 'Bank account owner', dataIndex: 'bank_account_owner', valueType: 'text', hideInSearch: true},
        {title: 'Contract start date', dataIndex: 'contract_start_date', valueType: 'text', hideInSearch: true},
        {title: 'Contract end date', dataIndex: 'contract_end_date', valueType: 'text', hideInSearch: true},
        // {title: 'Payment currency code', dataIndex: 'payment_currency_code', valueType: 'digit', hideInSearch: true},
        {
            title: 'Payment currency', dataIndex: 'payment_currency_alpha', valueType: 'select',
            valueEnum: {EUR: {text: 'EUR'}, GBP: {text: 'GBP'}},
        },
    ];

    onSubmit = async () => {
        if (!this.state.importedData) {
            notification.warning({message: 'No data loaded'})
            return
        }
        try {
            const res = await postRequest('/api/paymentCycles/uploadCustomersInfo/' + this.props.params.id, this.state.importedData, false)
            // console.log(res)
            notification.success({message: 'OK'})
            this.state.actionRef.current?.reload()

            this.onClear()
        } catch (e) {
            notification.error({message: 'Something went wrong'})
        }
    }

    onClear = () => {
        // @ts-ignore
        document.querySelector('.csv-input').value = ''
        this.setState({importedData: undefined})
    }


    downloadSample = async () => {
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(API + '/api/paymentCycles/sampleFiles/clients', null,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
            .then(res => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'sample_clients.csv');
                document.body.appendChild(link);
                link.click();

                notification.success({message: 'Success'})
            }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }

    onSearch = (params: PaymentCycleCustomer) => {
        let filteredData: PaymentCycleCustomer[] = JSON.parse(JSON.stringify(this.state.data))
        Object.keys(params).map((key) => {
            if (params[key] == '') return true
            filteredData = filteredData.filter((datum) => {
                // console.log(key, params[key], datum[key].toString().toLowerCase().includes(params[key].toLowerCase()))
                // return datum[key] == params[key] // strict comparison
                return datum[key].toString().toLowerCase().includes(params[key].toLowerCase()) // loose comparison
            })
        })
        this.setState({filteredData})
    }
    onReset = () => {
        this.onSearch({} as any)
    }

    render() {


        return (
            <>

                {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}
                <Row justify={"space-between"}>
                    <Space>
                        <CSVReader
                            parserOptions={{
                                header: true,
                                dynamicTyping: true
                            }}
                            // inputRef={instance => {
                            //     console.log(instance)
                            // }}
                            // cssInputClass={"ant-btn ant-btn-default"}
                            label={'Upload / Replace Active Clients Data: '}
                            onFileLoaded={(data, fileInfo, originalFile) => {
                                // console.log(data, fileInfo, originalFile)
                                this.setState({importedData: data})
                            }}
                            onError={error => {
                                notification.error({message: error.message})
                            }}
                        />

                        {this.state.importedData &&
                        <>
                            <Button type={"default"} onClick={this.onSubmit}
                                    size={"middle"}>Load<UploadOutlined/></Button>
                            <Button type={"default"} danger onClick={this.onClear}
                                    size={"middle"}>Clear<ClearOutlined/></Button>
                        </>
                        }
                    </Space>

                    <Button icon={<DownloadOutlined/>} onClick={this.downloadSample}>Download Sample</Button>
                </Row>
                <br/>
                {/*<Divider/>*/}
                <ConfigProvider locale={enUSIntl}>

                    {/*<Card bodyStyle={{padding: 0}}>*/}

                    <ProTable<PaymentCycleCustomer>
                        columns={this.columns}
                        actionRef={this.state.actionRef as any}
                        // cardBordered
                        bordered
                        // size={"small"}
                        // style={{fontSize:6}}
                        // showHeader={false}
                        // headerTitle={"Logs"}
                        // polling={this.props.polling || 0}
                        // request={async (params = {}, sort, filter) => {
                        //     try {
                        //         const res = await postRequest('/api/paymentCycles/getCustomersInfo/' + this.props.cycle_id)
                        //         const data = res.data || []
                        //         // console.log(res.data)
                        //         return {data, success: true, total: data.length}
                        //     } catch (e) {
                        //         return {data: [], success: false, total: 0}
                        //     }
                        // }}
                        rowKey="customer_id"
                        // search={false}
                        locale={{
                            emptyText: <Space direction={'vertical'}> <FileTextOutlined/>No exported unmatched
                                files</Space>
                        }}
                        pagination={{pageSize: this.props.pageSize || 10, hideOnSinglePage: true}}
                        dateFormatter="string"
                        toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                        // toolBarRender={false}
                        // toolBarRender={() => []}
                        // style={{overflowX: "scroll" , maxWidth:1600}}
                        // onColumnsStateChange={map => console.log({map})}
                        // scroll={{x: 1600}}
                        columnsState={{
                            defaultValue: {
                                address: {show: false},
                                zipcode: {show: false},
                                vat_number: {show: false},
                                passport_id_number: {show: false},
                                dm_manager: {show: false},
                                paypal_email: {show: false},
                                iban_number: {show: false},
                                swift_bic_code: {show: false},
                                bank_account_owner: {show: false},
                            }
                        }}

                        loading={this.state.loading}
                        onSubmit={this.onSearch}
                        onReset={this.onReset}
                        search={{layout: "vertical"}}
                        dataSource={this.state.filteredData ? this.state.filteredData : this.state.data}
                        toolBarRender={false}
                    />
                    {/*</Card>*/}


                </ConfigProvider>

            </>
        );
    }
}

export default withRouter(PaymentCycleCustomers);