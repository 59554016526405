import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import {Divider, Form, InputRef, notification, Tag, TreeSelect} from 'antd';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    ModalForm,
    ProFormDigit,
    ProFormGroup,
    ProFormInstance,
    ProFormList,
    ProFormSelect,
    ProFormText,
    ProFormTextArea
} from "@ant-design/pro-components";
import {PageLoading} from "@ant-design/pro-layout";
import axiosApiInstance from "../../../api/axiosClient";
import {GlobalStateContext} from "../../../context/GlobalContext";
import {useAtom, useAtomValue} from "jotai";
import {creatorCategoriesAtom, creatorTagsAtomById} from "./atoms";
import {queryClientAtom} from "jotai-tanstack-query";
import {ProFormTreeSelect} from "@ant-design/pro-form/lib";
import {generate} from "@ant-design/colors";
import {getPaletteColor} from "./utils";
import {getInitialSelectedValues, TagCategory} from "./CreatorViewEdit";

type Props = {};

const CreatorCreate = (props: Props) => {
        const [form] = Form.useForm<API.Creators.Creator>();
        const navigate = useNavigate();
        const routerParams = useParams();
        const [loading, setLoading] = useState<boolean>(false);
        const formRef = useRef<ProFormInstance>();
        const inputRef = useRef<InputRef>(null);
        const {
            employees
        } = useContext(GlobalStateContext);
        const [notifyManagerViaEmail, setNotifyManagerViaEmail] = useState<boolean>(true);
        const [{data: creatorsCategories, isLoading}] = useAtom(creatorCategoriesAtom);
        const queryClient = useAtomValue(queryClientAtom);
        const {SHOW_PARENT} = TreeSelect;

        const [{data: tagOptions}] = useAtom(creatorTagsAtomById(0));
        const [selectedValues, setSelectedValues] = useState<string[]>(getInitialSelectedValues(tagOptions));


        // console.log('props', props.)

        const location = useLocation();
        const dataFromRouter = location.state?.data;
        if (dataFromRouter) {
            form.setFieldsValue(dataFromRouter)
        }

        const tagRender = (props: any) => {
            const {label, value, closable, onClose} = props;

            const findColor = (options: TagCategory[], value: string): string | undefined => {
                for (const option of options) {
                    if (option.value === value) return undefined;
                    const found = option.children.find(child => child.value === value);
                    if (found) return found.color;
                }
                return undefined;
            };

            const tagColor = findColor(tagOptions, value);
            if (!tagColor) return null;
            const palette = tagColor ? generate(tagColor) : [];

            return (
                <Tag
                    color={tagColor}
                    closable={closable}
                    onClose={onClose}
                    style={{marginRight: 3, color: getPaletteColor(tagColor, palette)}} // Apply text color
                >
                    {label}
                </Tag>
            );
        };

        const handleChange = (values: string[]) => {
            let newSelectedValues = [...values];

            tagOptions.forEach(category => {
                if (!category.allowMultiple) {
                    const selectedChildren = newSelectedValues.filter(value =>
                        category.children.some(child => child.value === value)
                    );
                    if (selectedChildren.length > 1) {
                        // Keep only the most recently selected child
                        const lastSelectedChild = selectedChildren.pop();
                        newSelectedValues = newSelectedValues.filter(value =>
                            !category.children.some(child => child.value === value)
                        );
                        if (lastSelectedChild !== undefined) {
                            newSelectedValues.push(lastSelectedChild);
                        }
                    }
                }
            });
            // console.log({newSelectedValues})
            setSelectedValues(newSelectedValues);
        };

        return (
            <ModalForm<API.Creators.Creator>
                open={true}
                width={"65%"}
                form={form}
                formRef={formRef}
                autoFocusFirstInput
                modalProps={{
                    destroyOnClose: true,
                    okText: 'Create',
                    okButtonProps: {loading: loading},
                    onCancel: () => navigate(`/crm/creators/list`),

                }}
                onFinish={async formData => {
                    console.log(formData)
                    // return
                    return axiosApiInstance.post(`/api/creators/items`, formData, {
                        params: {
                            notify_manager_via_email: notifyManagerViaEmail
                        }
                    })
                        .then(() => {
                            notification.success({message: 'Created successfully'})
                            // queryClient.invalidateQueries({queryKey: ['creators', 'items']})
                            queryClient.invalidateQueries({queryKey: ['creators']})
                            navigate(`/crm/creators/list`)
                            return true
                        })
                    // .then(()=>form.resetFields())
                }}
                // title={
                // <div
                //     style={{
                //         width: '100%',
                //         height: 12,
                //         cursor: 'move',
                //         // backgroundColor: '#d5d5d5',
                //         backgroundImage: 'repeating-linear-gradient(45deg, #d5d5d5 0, #d5d5d5 1px, #fff 0, #fff 50%)',
                //         backgroundSize: '10px 10px'
                //     }}
                //     onMouseOver={() => {
                //         if (disabled) {
                //             setDisabled(false);
                //         }
                //     }}
                //     onMouseOut={() => {
                //         setDisabled(true);
                //     }}
                //     onFocus={() => {
                //     }}
                //     onBlur={() => {
                //     }}
                //     // end
                // >
                // </div>
                // }
                validateMessages={{required: "${label} is required!"}}
                // params={{notify_manager_via_email: notifyManagerViaEmail}}
            >

                {loading ? <PageLoading/> :
                    <>
                        <ProFormDigit
                            name="id"
                            hidden={true}
                        />

                        <ProFormGroup>
                            <ProFormText
                                width="lg"
                                name="creator"
                                label="Creator"
                                rules={[{required: true}]}
                            />

                            {/*<ProFormSelect*/}
                            {/*    name={'managed_by_employee_id'}*/}
                            {/*    label={'Managed By'}*/}
                            {/*    width="sm"*/}
                            {/*    initialValue={29}*/}
                            {/*    showSearch*/}
                            {/*    // mode={"multiple"}*/}
                            {/*    rules={[{required: true}]}*/}
                            {/*    options={employees?.map(i => ({label: i.full_name, value: i.id}))}*/}
                            {/*    help={*/}
                            {/*        form.getFieldValue('managed_by_employee_id') && employees?.filter(i => i.id == managed_by_employee_id)[0]?.email == '' ?*/}
                            {/*            <><WarningOutlined/> No*/}
                            {/*                email found</> :*/}
                            {/*            <div style={{*/}
                            {/*                display: 'flex',*/}
                            {/*                alignItems: 'center',*/}
                            {/*                justifyContent: 'space-between'*/}
                            {/*            }}> {employees?.filter(i => i.id == managed_by_employee_id)[0]?.email}*/}
                            {/*                <Tooltip title={'Notify via email'} placement={"bottom"}>*/}
                            {/*                    <Switch*/}
                            {/*                        // checkedChildren={'Notify'} unCheckedChildren={''}*/}
                            {/*                        checkedChildren={<BellOutlined/>}*/}
                            {/*                        unCheckedChildren={<BellOutlined/>}*/}
                            {/*                        // defaultChecked*/}
                            {/*                        checked={notifyManagerViaEmail}*/}
                            {/*                        onChange={setNotifyManagerViaEmail}*/}
                            {/*                        size={"small"}*/}
                            {/*                    />*/}
                            {/*                </Tooltip>*/}
                            {/*            </div>*/}
                            {/*    }*/}
                            {/*/>*/}
                        </ProFormGroup>

                        <ProFormGroup>
                            <ProFormSelect
                                width="xs"
                                // wrapperCol={{style: {width: 90}}}
                                name="main_platform_id"
                                label="Main Platform"
                                options={[{label: 'YouTube', value: 1}, {label: 'Instagram', value: 2}, {
                                    label: 'TikTok',
                                    value: 3
                                },]}
                                rules={[{required: true}]}
                            />

                            <ProFormTreeSelect
                                width={"lg"}
                                label={"Tags"}
                                name="tags"
                                normalize={(value, prevValue, allValues) => {
                                    // console.log({value, prevValue, allValues})
                                    return value.map(val => ({
                                        tag_category_id: parseInt(val.split(",")[0]),
                                        tag_category_value_id: parseInt(val.split(",")[1])
                                    }))
                                }}
                                fieldProps={{
                                    showCheckedStrategy: SHOW_PARENT,
                                    multiple: true,
                                    tagRender: (props) => tagRender(props),
                                    treeCheckable: true, // This will make selected items display as tags
                                    value: selectedValues,
                                    onChange: handleChange,
                                    treeData: tagOptions,
                                    // disabled: !props.editable,
                                }}
                            />
                        </ProFormGroup>

                        {/*<ProFormGroup>*/}

                        {/*    <ProFormDatePicker label={'Date of communication'} name={"date_of_communication"}*/}
                        {/*                       normalize={value => {*/}
                        {/*                           // console.log(value)*/}
                        {/*                           return new Date(value).toISOString()*/}
                        {/*                       }}*/}
                        {/*    />*/}

                        {/*    <ProFormSelect*/}
                        {/*        width="sm"*/}
                        {/*        name="communication_via_id"*/}
                        {/*        label="Communication via"*/}
                        {/*        // valueEnum={*/}
                        {/*        //     {*/}
                        {/*        //         1: {*/}
                        {/*        //             text: 'Email',*/}
                        {/*        //         },*/}
                        {/*        //     }}*/}
                        {/*        options={[{label: 'Email', value: 1}, {label: 'Phone', value: 2}, {*/}
                        {/*            label: 'Instagram',*/}
                        {/*            value: 3*/}
                        {/*        },]}*/}
                        {/*        rules={[{required: true}]}*/}

                        {/*    />*/}

                        {/*    <ProFormSelect*/}
                        {/*        width="sm"*/}
                        {/*        name="status_id"*/}
                        {/*        label="Status"*/}
                        {/*        options={[{label: 'Signed', value: 1}, {label: 'Pending', value: 2}, {*/}
                        {/*            label: 'Declined',*/}
                        {/*            value: 3*/}
                        {/*        },]}*/}
                        {/*        rules={[{required: true}]}*/}
                        {/*    />*/}

                        {/*</ProFormGroup>*/}

                        <ProFormGroup>
                            {/*<ProFormTextArea*/}
                            {/*    width="md"*/}
                            {/*    name="negotiation"*/}
                            {/*    label="Negotiation"*/}
                            {/*    // rules={[{required: true}]}*/}
                            {/*/>*/}


                            <ProFormTextArea
                                width="md"
                                name="reason"
                                label="Comments"
                                // rules={[{required: true}]}
                            />

                        </ProFormGroup>

                        <ProFormList name="accounts"
                                     label={'Social Media Accounts'}
                                     creatorButtonProps={{creatorButtonText: 'Add account'}}
                                     actionRender={(field, action, defaultActionDom) => [
                                         ...defaultActionDom,
                                     ]}
                        >
                            {(f, index, action) => {
                                return (
                                    <ProFormGroup>
                                        <ProFormDigit
                                            name="id"
                                            hidden={true}
                                        />

                                        <ProFormDigit
                                            name="creator_id"
                                            hidden={true}
                                        />

                                        <ProFormSelect
                                            // width="xs"
                                            wrapperCol={{style: {width: 70}}}
                                            name="platform_id"
                                            label="Platform"
                                            options={[{label: 'YouTube', value: 1}, {
                                                label: 'Instagram',
                                                value: 2
                                            }, {label: 'TikTok', value: 3},]}
                                            rules={[{required: true}]}
                                        />


                                        <ProFormText
                                            // width={"md"}
                                            wrapperCol={{style: {width: 430}}}
                                            label="Key"
                                            name="key"
                                        />

                                        <ProFormDigit
                                            name="following"
                                            label={'Following'}
                                            fieldProps={{precision: 0}}
                                            // width={"xs"}
                                            wrapperCol={{style: {width: 80}}}
                                        />

                                        {/*<ProFormSwitch*/}
                                        {/*    name="auto_fetch_followers"*/}
                                        {/*    label={'Auto Fetch Followers'}*/}
                                        {/*    // width={"xs"}*/}
                                        {/*    wrapperCol={{style: {width: 150}}}*/}
                                        {/*/>*/}

                                    </ProFormGroup>
                                );
                            }}
                        </ProFormList>

                        <Divider/>
                        {/*Pricings*/}
                        {/*YouTubeVideo
                            TikTokVideo
                            IGReel
                            IGPost
                            IGStory*/}
                        <ProFormGroup>

                            <ProFormDigit
                                name={["pricing", "youtube_video"]}
                                label="Price YouTube Video"
                                fieldProps={{precision: 0}}
                                // rules={[{required: true}]}
                            />

                            <ProFormDigit
                                name={["pricing", "tiktok_video"]}
                                label="Price TikTok Video"
                                fieldProps={{precision: 0}}
                                // rules={[{required: true}]}
                            />

                            <ProFormDigit
                                name={["pricing", "instagram_reel"]}
                                label="Price IG Reel"
                                fieldProps={{precision: 0}}
                                // rules={[{required: true}]}
                            />

                            <ProFormDigit
                                name={["pricing", "instagram_post"]}
                                label="Price IG Post"
                                fieldProps={{precision: 0}}
                                // rules={[{required: true}]}
                            />

                            <ProFormDigit
                                name={["pricing", "instagram_story"]}
                                label="Price IG Story"
                                fieldProps={{precision: 0}}
                                // rules={[{required: true}]}
                            />


                        </ProFormGroup>

                    </>
                }

            </ModalForm>
        );
    }
;

export default CreatorCreate;