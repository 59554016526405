import * as React from 'react';
import {UserAuth} from "../context/AuthContext";
import UnauthorizedPage from "../pages/403";
import {Outlet} from "react-router-dom";

type Props = {};

//Helper wrapper component used for authorization purposes

export const AccessCRM = (props: Props): JSX.Element => {
    const {can} = UserAuth();

    const accessible = can('read', 'customers::data') || can('read', 'creators::data');

    if (!accessible) {
        return <UnauthorizedPage message={"Sorry, you are not authorized to access this content."}/>
    }

    return <Outlet/>
};