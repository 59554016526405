import axios from 'axios';
import qs from 'qs';
import { RBAC_API } from '../common/constants';
import { auth } from '../config/firebase';

export const postRequestRBAC = async (endpoint: string, data: any = null, stringify: boolean = true) => {
    if (data != null && stringify) data = qs.stringify(data);

    const session_url = RBAC_API + endpoint;

    const token = await auth.currentUser?.getIdToken();
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DELETE THE FOLLOWING LINE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //console.log({ token });
    return axios.post(session_url, data, {
        headers: {
            Accept: '*/*',
            'Access-Control-Allow-Origin': RBAC_API,
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            Authorization: `Bearer ${token}`
        }
    });
};
