import {notification, Row, Space, Tag, Tooltip, Typography} from 'antd';
import React, {Component, CSSProperties} from 'react';
import {TagCategory, TagCategoryValueExtendedEntity} from '../../../common/models';
import './index.css';
import {postRequest} from "../../../api/postRequest";
import axiosApiInstance from "../../../api/axiosClient";
import {BulbOutlined, PlusSquareOutlined} from "@ant-design/icons";

const {CheckableTag} = Tag;

export interface IEntityTagsProps {
    //Here we pass the Props Interface
    entityId: string;
    entityDomain: string;
    editable?: boolean;
    size?: 'large' | 'middle' | 'small'
    padding?: number
}

export interface IEntityTagsState {
    //here we pass the State Interface
    tagCategories?: TagCategory[];
    tagValues?: TagCategoryValueExtendedEntity[];
}

//class ComponentName Component<PropsInterface, StateInterface>
class EntityTags extends Component<IEntityTagsProps, IEntityTagsState> {
    constructor(props: IEntityTagsProps) {
        super(props);

        //Component State
        this.state = {};

    }

    //Add style here
    style: CSSProperties = {
        width: '100%s'
    };

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        await this.getTagCategories();
        await this.getTagValues();
        // await this.getTagsOfEntity();
    }

    getTagCategories = async () => {
        try {
            const res = await postRequest('/api/tags/getcategories');
            this.setState({tagCategories: res.data || []})
        } catch (e: any) {
            notification.error({message: e.response.data.message})
            this.setState({tagCategories: []})
        }

    }

    getTagValues = async () => {
        try {
            const res = await postRequest('/api/tags/getcategoryvaluesextendedentity', {
                domain: this.props.entityDomain,
                entity_id: this.props.entityId
            });
            this.setState({tagValues: res.data || []})
        } catch (e: any) {
            notification.error({message: e.response.data.message})
            this.setState({tagValues: []})
        }

    }

    toggleTaggation = async (tag: TagCategoryValueExtendedEntity, checked: boolean) => {
        if (checked) {
            //add taggation
            try {
                // const res = await postRequest('/api/tags/addtaggationextendedentity', {
                //     domain: this.props.entityDomain,
                //     entity_id: this.props.entityId,
                //     tag_category_id: tag.category_id,
                //     tag_category_value_id: tag.id
                // });
                const res = await axiosApiInstance.post('/api/tags/addtaggationextendedentity', {
                    domain: this.props.entityDomain,
                    entity_id: +this.props.entityId,
                    tag_category_id: +tag.category_id,
                    tag_category_value_id: +tag.id
                });
                notification.success({message: 'Success'})
                this.getData()
            } catch (e: any) {
                // notification.error({message: e.response.data.message})
                // this.setState({tagValues: []})
            }
        } else {
            //remove taggation
            try {
                // const res = await postRequest('/api/tags/deletetaggationextendedentity', {
                //     domain: this.props.entityDomain,
                //     entity_id: this.props.entityId,
                //     tag_category_id: tag.category_id,
                //     tag_category_value_id: tag.id
                // });
                const res = await axiosApiInstance.delete('/api/tags/deletetaggationextendedentity', {
                    data: {
                        domain: this.props.entityDomain,
                        entity_id: +this.props.entityId,
                        tag_category_id: +tag.category_id,
                        tag_category_value_id: +tag.id
                    }
                })
                notification.success({message: 'Success'})
                this.getData()
            } catch (e: any) {
                // notification.error({message: e.response.data.message})
                // this.setState({tagValues: []})
            }
        }
    }

    render() {
        return (
            <>
                <div>
                    {this.state.tagCategories?.map((c) => (
                        <>
                            {(this.props.entityDomain == '_customer' && (c.id == 1 || c.id == 2 || c.id == 12 || c.id == 15 || c.id == 16)) || (this.props.entityDomain == '_asset' && c.id != 15) ?
                                <div>
                                    <Space align={"center"}>
                                        <Typography.Title level={5}
                                        style={{marginBottom: 4}}
                                        >{c.category_name}</Typography.Title>
                                        {!c.allow_multiple &&
                                            <Tooltip title="Multiple assignments are not allowed for this tag category">
                                                {/*<PlusSquareOutlined/>*/}
                                                <BulbOutlined />
                                            </Tooltip>}
                                    </Space>
                                    <Row gutter={[0, 8]} style={{marginTop: 16}}>
                                        {this.state.tagValues?.filter((t) => (t.category_id == c.id)).map((t => (
                                            // <div>{t.value}</div>
                                            <div
                                                // style={this.props.entityDomain == '_asset' && (t.category_id == 1 || t.category_id == 2 || t.category_id == 12 || t.category_id == 16) ? {
                                                style={this.props.entityDomain == '_asset' && c.controlled_by_customer ? {
                                                    pointerEvents: "none",
                                                    opacity: "0.5"
                                                    // cursor:"not-allowed"
                                                } : {}}>
                                                <CheckableTag
                                                    key={t.id}
                                                    checked={t.checked}
                                                    onChange={checked => this.toggleTaggation(t, checked)}
                                                >
                                                    {t.value}
                                                </CheckableTag>
                                            </div>
                                        )))}
                                    </Row>
                                    <br/>
                                    <br/>
                                </div> : null}
                        </>
                    ))}
                </div>

            </>
        );
    }
}

export default EntityTags;
