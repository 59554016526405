import React, {Component} from 'react';
import './index.css';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    FormInstance,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Space,
    Switch,
} from 'antd';
import moment from 'moment';
// import dayjs from 'dayjs';
import {roundNum} from '../../../common/utils';
import {executeQuery} from '../../../api/executeQuery';
import global_vars from '../../../common/global_variables.json';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {getRequest} from "../../../api/getRequest";

export interface INewContractFormProps {
    //Here we pass the Props Interface
    formRef: React.RefObject<FormInstance>;
    edit?: boolean
    id?: number
    onFormValuesChange: Function
}

export interface INewContractFormState {
    //here we pass the State Interface
    dropdownCustomers: any[],
    payment_options: any[],
    dropdownAssets: any
}

//class ComponentName Component<PropsInterface, StateInterface>
class NewContractForm extends Component<INewContractFormProps, INewContractFormState> {
    constructor(props: INewContractFormProps) {
        super(props);

        //Component State
        this.state = {
            // formRef: React.createRef<FormInstance>(),
            dropdownCustomers: [],
            payment_options: [],
            dropdownAssets: []
        };
    }


    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {

        try {
            this.getFunc()
        } catch (error) {
            console.log('Error:', error);
        }
    }

    async getFunc() {
        let selectOptions: { label: string; value: any; }[];
        // get assets
        try {
            const response = await executeQuery('GET_UNASSIGNED_ASSETS')
            // console.log('GET_UNASSIGNED_ASSETS', response);
            // const unassignedAssets = response.data ? response.data as AssetListRow[] : []
            const unassignedAssets = response.data as any[] || []
            // const allAssets = (await getAllAssets()).data
            selectOptions = [];
            unassignedAssets.forEach((element) => {
                selectOptions.push({
                    label: element.id + " / " + element.ppk_value,
                    value: parseInt(element.id)
                })
            });
            // console.log(selectOptions)
            this.setState({dropdownAssets: selectOptions})

        } catch (error) {
            console.log('Error:', error);
        }

        // get customers
        try {
            const response = await executeQuery('GET_CUSTOMERS_ID_FULLNAME')
            // const unassignedCustomers = response.data ? response.data as CustomerListRow[] : []
            const unassignedCustomers = response.data as any[] || []
            // const allAssets = (await getAllAssets()).data
            selectOptions = [];
            unassignedCustomers.forEach((element) => {
                selectOptions.push({
                    label: element.id + " / " + element.full_name,
                    value: parseInt(element.id)
                })
            });
            // console.log(selectOptions)
            this.setState({dropdownCustomers: selectOptions})

        } catch (error) {
            console.log('Error:', error);
        }

        // get payment destinations
        try {
            const response = await executeQuery('GET_PAYMENTS_OPTIONS')
            const paymentsOptions = response.data ? response.data : []
            // const allAssets = (await getAllAssets()).data
            selectOptions = [];
            paymentsOptions.forEach((element: any) => {
                selectOptions.push({
                    label: element.title,
                    value: parseInt(element.id)
                })
            });
            // console.log(selectOptions)
            this.setState({payment_options: selectOptions})

        } catch (error) {
            console.log('Error:', error);
        }


        // IF EDIT
        if (this.props.edit) {
            getRequest('/api/contracts/getraw/' + this.props.id)
                .then(async (response) => {
                    // console.log('Authenticated');
                    // let result = response.data as ExtendedContractInfoRaw || [];
                    const result = response.data || null;
                    console.log('resExtendedContractInfo', result);

                    const dateFormat = 'YYYY-MM-DD';
                    result.start_date = moment(result.start_date, dateFormat)
                    result.end_date = moment(result.end_date, dateFormat)
                    this.props.formRef.current?.setFieldsValue(result)
                })
                .catch((error) => {
                    console.log('Error on GET :' + error);
                });
        }
    }

    render() {

        const layout = {
            labelCol: {span: 10},
            wrapperCol: {span: 14},
        };


        return (
            // <div className="NewContractForm" style={this.style}>

            <Form
                {...layout}
                name="contractForm"
                //   onFinish={onFinish}
                //   onFinishFailed={onFinishFailed}
                validateMessages={{required: 'This field is required!'}}
                ref={this.props.formRef}
                // layout='vertical'
                size="middle"
                onValuesChange={() => this.props.onFormValuesChange()}
            >
                <Card title='Contract Details' style={{width: '100%'}}>

                    <Row justify="space-around">
                        <Col span={12}>
                            {/* <Form.Item
                     label="Contract Vault ID"
                     name="contract_vault_id"
                  >
                     <Select
                        showSearch
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Select a Contract Vault ID"
                        optionFilterProp="label"
                        options={contract_vault_ids}
                        filterOption={(inputValue, option) =>
                           option?.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                        }
                     ></Select>
                  </Form.Item> */}

                            {this.props.edit &&
                            <Form.Item label="Contract ID" name="id">
                                <InputNumber disabled/>
                            </Form.Item>
                            }


                            <Form.Item label="Start date" name="start_date" rules={[{required: true}]}>
                                <DatePicker/>
                            </Form.Item>

                            <Form.Item label="End date" name="end_date"
                                       rules={[
                                           {required: true},
                                           ({getFieldValue}) => ({

                                               validator(_, value) {
                                                   if (getFieldValue('start_date')) {
                                                       const duration = roundNum(moment.duration(value.diff(getFieldValue('start_date'))).asDays(), 0)
                                                       // console.log('cd', value, getFieldValue('start_date'), value - getFieldValue('start_date'), roundNum(moment.duration(value.diff(getFieldValue('start_date'))).asDays(), 0));

                                                       if (getFieldValue('start_date') >= value) {
                                                           return Promise.reject(new Error('End date cannot be smaller than start date!'));
                                                       }
                                                       if (duration < 30) {
                                                           return Promise.reject(new Error('Contract duration is too short!'));
                                                       }

                                                       if (duration < global_vars.MINIMUM_CONTRACT_DURATION_FOR_WARNING_IN_DAYS) {
                                                           message.warning('Warning: Contract duration is abnormally short.', 5)
                                                           // console.log('duration short', duration, 'limit', global_vars.MINIMUM_CONTRACT_DURATION_FOR_WARNING_IN_DAYS);
                                                       } else if (duration > global_vars.MAXIMUM_CONTRACT_DURATION_FOR_WARNING_IN_DAYS) {
                                                           message.warning('Warning: Contract duration is abnormally long.', 5)
                                                           // console.log('duration long', duration, 'limit', global_vars.MAXIMUM_CONTRACT_DURATION_FOR_WARNING_IN_DAYS);
                                                       }

                                                       return Promise.resolve();
                                                   } else {
                                                       return Promise.reject();
                                                   }
                                               },
                                           }),
                                       ]}

                            >
                                <DatePicker/>
                            </Form.Item>

                            {/* Contract From/Till alternative */}
                            {/* <Form.Item label="Start/End date (alternative)" name="time_range">
                     <RangePicker format="DD/MM/YYYY" />
                  </Form.Item> */}

                            <Form.Item label="Autorenew" name="autorenew"
                                       initialValue={false}
                                       rules={[{required: true}]}
                            >
                                <Switch/>
                            </Form.Item>

                            <Form.Item label="Renewal duration (days)" name="duration_renewal"
                                       rules={[
                                           {required: true},
                                           ({getFieldValue}) => ({
                                               validator(_, value) {
                                                   if (getFieldValue('end_date') === undefined || getFieldValue('start_date') === undefined) return
                                                   const contract_duration = roundNum(moment.duration(getFieldValue('end_date').diff(getFieldValue('start_date'))).asDays(), 0)
                                                   if (value < 30) {
                                                       return Promise.reject(new Error('Renewal duration is too short!'));

                                                   }
                                                   if (value > contract_duration) {
                                                       return Promise.reject(new Error('Renewal duration cannot be longer than the contract duration'));
                                                   }
                                                   return Promise.resolve();
                                               },
                                           }),
                                       ]}
                                       initialValue={30}
                            >
                                <InputNumber
                                    min={0}
                                    // max={365}
                                />
                            </Form.Item>

                            {/* <Form.Item
                     label="Tier"
                     name="tier_id"
                     //   rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                     <Select
                        showSearch
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Select a Tier"
                        optionFilterProp="label"
                        //  onChange={onChange}
                        //  onSearch={onSearch}
                        options={tier_ids} // replace with acutal data !!!
                        filterOption={(inputValue, option) =>
                           option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                        }
                     ></Select>
                  </Form.Item> */}

                            <Form.Item label="Admin fees" name="admin_fees" initialValue={0}>
                                <InputNumber/>
                            </Form.Item>

                            <Form.Item label="Admin fees (%)" name="admin_fees_percentage" initialValue={0}>
                                <InputNumber
                                    min={0}
                                    max={1}
                                    step={0.01}
                                />
                            </Form.Item>

                            {/* <Form.Item label="Commission Type" name="commission_type">
                     <Select
                        showSearch
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Select a Commission Type"
                        optionFilterProp="label"
                        //  onChange={onChange}
                        //  onSearch={onSearch}
                        options={commission_types} // replace with acutal data !!!
                        filterOption={(inputValue, option) =>
                           option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                        }
                     ></Select>
                  </Form.Item> */}
                            <Form.Item label="Fixed Commission (%)" name="fixed_commission_percentage"
                                       rules={[{required: true}]} initialValue={0}>
                                <InputNumber
                                    min={0}
                                    max={1}
                                    step={0.01}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={12}>

                            <Form.Item label="Downpayment" name="downpayment" initialValue={0}>
                                <InputNumber/>
                            </Form.Item>

                            <Form.Item label="Cumulatives Fees" name="cumulatives_fees" initialValue={0}>
                                <InputNumber min={0}/>
                            </Form.Item>

                            <Form.Item label="Payment threshold (euros)" name="payment_threshold"
                                       rules={[{required: true}]} initialValue={100}>
                                <InputNumber min={0}/>
                            </Form.Item>

                            <Form.Item label="Payment Option" name="payment_option_id" rules={[{required: true}]}
                                       initialValue={1}>
                                <Select
                                    showSearch
                                    allowClear
                                    style={{width: 200}}
                                    placeholder="Select a Payment Option"
                                    optionFilterProp="label"
                                    //  onChange={onChange}
                                    //  onSearch={onSearch}
                                    options={this.state.payment_options}
                                    filterOption={(inputValue, option) =>
                                        option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                                    }
                                    // defaultValue={this.state.payment_options[0]}
                                ></Select>
                            </Form.Item>

                            <Form.Item label="Payment frequency (days)" name="payment_frequency"
                                       rules={[{required: true}]} initialValue={30}>
                                <InputNumber
                                    min={0}
                                    // max={365}
                                />
                            </Form.Item>

                            <Form.Item label="Affiliated Customer" name="affiliated_customer_id">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{width: 200}}
                                    placeholder="Select an Affiliated Customer"
                                    optionFilterProp="label"
                                    //  onChange={onChange}
                                    //  onSearch={onSearch}
                                    options={this.state.dropdownCustomers} // replace with acutal data !!!
                                    filterOption={(inputValue, option) =>
                                        option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                                    }
                                ></Select>
                            </Form.Item>

                            <Form.Item label="Affiliated Customer (%)" name="affiliated_percentage" initialValue={0}>
                                <InputNumber
                                    min={0}
                                    max={1}
                                    step={0.01}
                                />
                            </Form.Item>

                            <Form.Item label="Contract URL" name="contract_url">
                                <Input/>
                            </Form.Item>

                            {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                     </Form.Item> */}
                        </Col>
                    </Row>
                </Card>


                <br/>
                {/* <Row>
               <Card title='Linked Assets' style={{ width: '100%' }}>
                  <Form.Item name="linked_assets" rules={[{ required: false }]} style={{ width: '100%' }} initialValue={[]}>
                     <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select one or more unassigned assets"
                        optionLabelProp="label"
                        options={this.state.dropdownAssets}
                        filterOption={(inputValue, option) =>
                           option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                        }
                     />
                  </Form.Item>
               </Card>
            </Row>
            <br /> */}


                <>
                    <Row>
                        <Card title='Linked Assets' style={{width: '100%'}}>
                            <Form.List name="linked_assets_commissions"
                                       initialValue={[{asset_id: undefined, commission: undefined}]}>
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, ...restField}) => (
                                            <Space key={key} style={{display: 'flex', marginBottom: 8}}
                                                   align="baseline">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'asset_id']}
                                                    // fieldKey={[fieldKey, 'asset_id']}
                                                    rules={[{required: true, message: 'Missing asset id'}]}
                                                >
                                                    {/* <Input placeholder="Asset" /> */}
                                                    <Select
                                                        // style={{ width: '100%' }}
                                                        style={{width: 500}}
                                                        placeholder="Select one or more unassigned assets"
                                                        optionLabelProp="label"
                                                        options={this.state.dropdownAssets}
                                                        filterOption={(inputValue, option) =>
                                                            option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                                                        }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'commission']}
                                                    // fieldKey={[fieldKey, 'commission']}
                                                    rules={[{required: true, message: 'Missing commission'}]}
                                                    initialValue={this.props.formRef.current?.getFieldValue('fixed_commission_percentage')}
                                                >
                                                    <InputNumber
                                                        placeholder="0.95"
                                                        min={0}
                                                        max={1}
                                                        step={0.01}/>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                                Add field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>
                    </Row>
                    <br/>


                    <Row>
                        <Card title='Linked Customer' style={{width: '100%'}}>
                            <Form.Item name="linked_customer" rules={[{required: false}]} style={{width: '100%'}}
                                       initialValue={''}>
                                <Select
                                    // mode="multiple"
                                    style={{width: '100%'}}
                                    showSearch
                                    allowClear
                                    placeholder="Select an unassigned customer"
                                    optionFilterProp="label"
                                    options={this.state.dropdownCustomers}
                                    filterOption={(inputValue, option) =>
                                        option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                                    }
                                    disabled={this.props.edit}
                                />
                            </Form.Item>
                        </Card>
                    </Row>
                </>


            </Form>
            // </div>
        );
    }
}

export default NewContractForm;
