import * as React from 'react';
import axiosApiInstance from "../../../api/axiosClient";
import {Button, notification, Tooltip} from "antd";
import {ModalForm} from '@ant-design/pro-components';
import {BellOutlined} from "@ant-design/icons";

type Props = {
    id?: number
};

const NotifyCreatorLeadManager = (props: Props) => {

    return (
        <ModalForm
            width={400}
            title={"Notify Creator Lead manager"}
            trigger={
                <Tooltip title={'Send reminder'}>
                    <Button type={"text"} icon={<BellOutlined/>}
                        // icon={<DeleteOutlined/>}
                    />
                </Tooltip>
            }
            // submitter={{submitButtonProps: {danger: true}}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                okText: 'Yes',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                return axiosApiInstance.post(`/api/leads/items/notify/${props.id}`)
                    .then(res => {
                        // console.log(res)
                        // creatorsLeadsTableRef?.current?.reload()
                        notification.success({message: 'Notified Creator Lead manager successfully'})
                        return true
                    }).catch(reason => false)
            }}
        >
            Send email reminder to the creator lead's manager?
        </ModalForm>
    );
};

export default NotifyCreatorLeadManager;