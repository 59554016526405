import {
    ModalForm,
    ProFormCheckbox,
    ProFormGroup,
    ProFormList,
    ProFormSelect,
    ProFormText
} from '@ant-design/pro-form';
import { Modal, Result } from 'antd';
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import axiosApiInstance from '../../../../api/axiosClient';
import { ProCard, ProSkeleton } from '@ant-design/pro-components';
import { API } from '../../../../common/constants';

const REDIRECT_URL = '/music/labels';

type FormContentProps = {
    initialValues: API.MusicDistribution.Label;
};

const EditLabelForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { data, status } = useQuery({
        queryKey: ['label', id],
        queryFn: () =>
            axiosApiInstance
                .get(`/api/products/labels/${id}`)
                .then(({ data }) => data)
                .catch((reason) => console.error(reason))
    });

    const FormContent = ({ initialValues }: FormContentProps) => (
        <ProCard>
            <ProFormText hidden name='ID' initialValue={initialValues.ID} />
            <ProFormText name='name' label='Name' initialValue={initialValues.name} />
            <ProFormText name='p_line' label='(P) Line' initialValue={initialValues.p_line} />
            <ProFormText name='c_line' label='(C) Line' initialValue={initialValues.c_line} />
        </ProCard>
    );

    const renderMap = {
        loading: <ProSkeleton type='list' />,
        error: <Result title='Failed to load label' status='error' />,
        success: <FormContent initialValues={data} />
    };
    return (
        <ModalForm<API.MusicDistribution.Label>
            title='Add new label'
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            width={1000}
            open
            modalProps={{
                destroyOnClose: true,
                onCancel: () => navigate(REDIRECT_URL)
            }}
            onFinish={async (values) => {
                // navigate(REDIRECT_URL);
                axiosApiInstance
                    .put(`/api/products/labels/`, values)
                    .then((response) => console.log(response))
                    .catch((reason) => console.log(reason))
                    .finally(() => navigate(REDIRECT_URL));
                return true;
            }}
            // style={{ width: 1000 }}
        >
            {renderMap[status]}
        </ModalForm>
    );
};

export default EditLabelForm;
