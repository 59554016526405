import {Suspense} from 'react';
import {Segmented} from "antd";
import {CreatorGroupBy, groupByAtom, pageAtom} from "./atoms";
import {useAtom} from "jotai";
import Creators from "./Creators";
import {PageLoading} from "@ant-design/pro-layout";
import {PageContainer} from "@ant-design/pro-components";
import {IG_COLOR, TT_COLOR, YT_COLOR} from "../../../common/constants";
import {InstagramOutlined, TikTokOutlined, UserOutlined, YoutubeFilled} from "@ant-design/icons";
import CreatorsAccountsByPlatform from "./CreatorsAccountsByPlatform";

type Props = {};

export const CreatorsWrapper = (props: Props) => {

    const [groupBy, setGroupBy] = useAtom(groupByAtom)
    const [page, setPage] = useAtom(pageAtom);

    return (
        <PageContainer>

            <Segmented block
                       options={[
                           {label: <span><UserOutlined/> By Creator</span>, value: CreatorGroupBy.Creator},
                           {
                               label: <span><YoutubeFilled style={{fontSize: 14, color: YT_COLOR}}/> By YouTube Account</span>,
                               value: CreatorGroupBy.YoutubeAccount
                           },
                           {
                               label: <span><InstagramOutlined style={{fontSize: 14, color: IG_COLOR}}/> By Instagram Account</span>,
                               value: CreatorGroupBy.InstagramAccount
                           },
                           {
                               label: <span><TikTokOutlined style={{fontSize: 14, color: TT_COLOR}}/> By Tiktok Account</span>,
                               value: CreatorGroupBy.TiktokAccount
                           },
                       ]}
                       value={groupBy}
                       onChange={(value) => {
                           setGroupBy(value as CreatorGroupBy)
                           setPage(1)
                       }} onResize={undefined}
                       onResizeCapture={undefined}/>

            {groupBy === CreatorGroupBy.Creator && <Suspense fallback={<PageLoading/>}>
                <Creators/>
            </Suspense>}

            {groupBy === CreatorGroupBy.YoutubeAccount && <Suspense fallback={<PageLoading/>}>
                <CreatorsAccountsByPlatform/>
            </Suspense>}

            {groupBy === CreatorGroupBy.InstagramAccount && <Suspense fallback={<PageLoading/>}>
                <CreatorsAccountsByPlatform/> </Suspense>}

            {groupBy === CreatorGroupBy.TiktokAccount && <Suspense fallback={<PageLoading/>}>
                <CreatorsAccountsByPlatform/> </Suspense>}

        </PageContainer>
    );
};

export default CreatorsWrapper;