import {UploadOutlined} from '@ant-design/icons';
import {
    Alert,
    Button,
    Dropdown,
    Menu,
    Modal,
    notification,
    Progress,
    Row,
    Space,
    Spin,
    Tree,
    Typography,
    Upload
} from 'antd';
import axios from 'axios';
import {API} from '../../../common/constants';
import React, {Component} from 'react';
import './index.css';
import {postRequest} from '../../../api/postRequest';
import {roundNum} from '../../../common/utils';
import {auth} from "../../../config/firebase";
import withRouter from "../../../components/withRouter";

const {DirectoryTree} = Tree;

export interface IPaymentCycleRawDataFileVaultProps {
    //Here we pass the Props Interface
    params: any
}

export interface IPaymentCycleRawDataFileVaultState {
    //here we pass the State Interface
    treeData?: any
    loading: boolean
    showUploadFilesModal: boolean
    showUploadFolderModal: boolean
    selectedPath?: string
    fileList: any[]
    uploading: boolean
    uploadProgress?: number
    showDeleteModal: boolean
    deleting: boolean
}

//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleRawDataFileVault extends Component<IPaymentCycleRawDataFileVaultProps, IPaymentCycleRawDataFileVaultState> {

    constructor(props: IPaymentCycleRawDataFileVaultProps) {
        super(props)

        this.state = {
            loading: false,
            showUploadFilesModal: false,
            showUploadFolderModal: false,
            fileList: [],
            uploading: false,
            showDeleteModal: false,
            deleting: false,
        }
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getTreeFileStructure()
    }

    getTreeFileStructure = async () => {
        this.setState({loading: true})

        const response = await postRequest('/api/paymentCycles/getDataFileVaultFiles/' + this.props.params.id);

        // console.log('Authenticated');
        // console.log(response)

        let files = response.data || []
        console.log(files)
        // let treeData = this.buildTreeData(files)
        let treeData = this.arrangeIntoTree(files)
        console.log(treeData)

        this.setState({treeData: treeData})
        // let res = response.data
        // let time = res.status

        // notification.success({ message: '' });
        this.setState({loading: false})

    }

    arrangeIntoTree(paths: string[]) {
        let tree: any = [];

        for (let i = 0; i < paths.length; i++) {
            // let path = paths[i];
            let path = paths[i].split('/');
            let currentLevel = tree;
            for (let j = 0; j < path.length; j++) {
                let part = path[j];

                let existingPath = findWhere(currentLevel, 'title', part);

                if (existingPath) {
                    currentLevel = existingPath.children;
                } else {
                    let newPart = {
                        title: part,
                        children: [],
                        // key: hashCode(paths[i] + part),
                        key: paths[i],
                        // path: paths[i],
                        isLeaf: part.includes('.')
                    }

                    currentLevel.push(newPart);
                    currentLevel = newPart.children;
                }
            }
        }
        return tree;

        function findWhere(array: string | any[], key: string, value: string) {
            let t = 0; // t is used as a counter
            while (t < array.length && array[t][key] !== value) {
                t++;
            }
            ; // find the index where the id is the as the aValue

            if (t < array.length) {
                return array[t]
            } else {
                return false;
            }
        }
    }


    handleMenuClickFileUpload = async (node: any) => {
        // handleRightClickNode = async (info: { event: React.MouseEvent<Element, MouseEvent>, node: EventDataNode }) => {
        // handleFileUploadClickNode = async (node: any) => {

        // console.log(node)
        this.setState({showUploadFilesModal: true, selectedPath: node.key as string})

        // return
        //
        // if (node.isLeaf) {
        //     // if a file is clicked, ask if the user wants to delete it from the filesystem
        //     this.setState({showDeleteModal: true, selectedPath: node.key as string})
        //
        // } else {
        //     // this is a folder
        //     // to upload file in folder, all children must be leafs
        //     for (const child of node.children!) {
        //         if (!child.isLeaf) {
        //             message.warn('Please select a leaf directory to upload files to.')
        //             return
        //         }
        //     }
        //
        //     // show upload modal, with the selected path
        //     this.setState({showUploadModal: true, selectedPath: node.key as string})
        //
        //
        // }
    }

    handleMenuClickFolderUpload = async (node: any) => {
        this.setState({showUploadFolderModal: true, selectedPath: node.key as string})
    }

    handleMenuClickDeleteFile = async (node: any) => {
        // console.log(node)
        this.setState({showDeleteModal: true, selectedPath: node.key as string})

    }

    handleUpload = ({fileList}: any) => {

        console.log('fileList', fileList);

        // you store them in state, so that you can make a http req with them later
        this.setState({fileList});
    };

    handleSubmit = async (event: any, contentType: string) => {
        // console.log("HANDLE SUBMIT")
        this.setState({uploading: true})
        event.preventDefault();

        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key

        // formData.append("files", this.state.fileList.map((f) => f.originFileObj));
        for (const file of this.state.fileList)
            formData.append("files", file.originFileObj);
        // console.log(formData.getAll("files"), this.state.fileList)
        formData.append("target_path", this.state.selectedPath!)

        const token = await auth.currentUser?.getIdToken()

        let endpoint;
        if (contentType == 'file') { // files upload
            endpoint = '/api/paymentCycles/uploadCycleFiles/'
        } else { // folder (directory) upload
            endpoint = '/api/paymentCycles/uploadCycleFolder/'
        }

        axios
            .post(API + endpoint + this.props.params.id, formData,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent
                        const percent = roundNum((loaded / total) * 100, 1)
                        // console.log('progressEvent', progressEvent, percent);
                        this.setState({uploadProgress: percent})
                    }
                })
            .then(res => {
                this.setState({uploading: false})
                console.log("res", res);

                notification.success({message: 'Successfully uploaded ' + this.state.fileList.length + ' file(s).'})

                this.closeFileUploadModal()
                this.closeFolderUploadModal()

                this.getTreeFileStructure()


            })
            .catch(err => {
                this.setState({uploading: false})
                console.log("err", err);
            });
    };

    handleDelete = async () => {

        this.setState({deleting: true})

        let res = await postRequest('/api/paymentCycles/removeCycleFile/' + this.props.params.id, {target_path: this.state.selectedPath})

        this.setState({deleting: false})

        notification.success({message: 'Successfully deleted file.'})

        this.closeDeleteModal()

        this.getTreeFileStructure()

    }

    closeFileUploadModal = () => {
        this.setState({showUploadFilesModal: false, uploadProgress: 0, fileList: []})
    }

    closeFolderUploadModal = () => {
        this.setState({showUploadFolderModal: false, uploadProgress: 0, fileList: []})
    }

    closeDeleteModal = () => {
        this.setState({showDeleteModal: false})
    }


    render() {

        const menu = (node: any) => {
            if (node.isLeaf) {

                return <Menu
                    items={[
                        {
                            label: 'Delete file',
                            key: 'delete',
                            danger: true,
                            onClick: () => this.handleMenuClickDeleteFile(node)
                        },
                    ]}
                />

            } else {
                return <Menu
                    items={[
                        {
                            label: 'Upload File',
                            key: 'upload-file',
                            onClick: () => this.handleMenuClickFileUpload(node)
                        },
                        {
                            label: 'Upload Directory',
                            key: 'upload-directory',
                            onClick: () => this.handleMenuClickFolderUpload(node)
                        },
                        {
                            label: 'Delete folder',
                            key: 'delete',
                            danger: true,
                            onClick: () => this.handleMenuClickDeleteFile(node)
                        },
                    ]}
                />
            }
        };

        return (
            <div className="PaymentCycleRawDataFileVault" style={this.style}>
                {!this.state.treeData && <Row justify='center'><Spin/></Row>}
                {this.state.treeData &&
                // <Dropdown overlay={menu} trigger={['contextMenu']}>

                <DirectoryTree
                    // multiple
                    // onSelect={onSelect}
                    // onExpand={onExpand}
                    treeData={this.state.treeData}
                    defaultExpandAll
                    expandAction={'doubleClick'}
                    // onRightClick={this.handleRightClickNode}
                    // onContextMenu={event => {
                    //     console.log(event)
                    // }}
                    // titleRender={node => <> {node.title}<Space  style={{justifyContent:"end"}} >
                    //     <Button
                    //         size={"small"}
                    //         onClick={() => this.handleFileUploadClickNode(node)}
                    //         icon={<UploadOutlined/>}> Upload File </Button>
                    //     <Button
                    //         size={"small"}
                    //         onClick={() => this.handleFileUploadClickNode(node)}
                    //         icon={<UploadOutlined/>}> Upload Directory </Button> </Space> </>}
                    titleRender={node => <>
                        <Dropdown overlay={() => menu(node)} trigger={['contextMenu']}>
                            <Space>
                                {node.title}
                            </Space>
                        </Dropdown>

                    </>}
                />
                    // </Dropdown>

                }

                {/* FILE UPLOAD MODAL */}

                <Modal visible={this.state.showUploadFilesModal} title={'Upload file to remote directory'} closable
                       maskClosable={!this.state.uploading}
                       onCancel={this.closeFileUploadModal}
                       onOk={(event) => this.handleSubmit(event, 'file')}
                       okText='Upload'
                       okButtonProps={{
                           disabled: this.state.fileList.length === 0 || this.state.uploadProgress === 100,
                           loading: this.state.uploading
                       }}
                       width={720}
                >

                    <Alert message={<>
                        <Typography.Text>
                            Upload files to path: <Typography.Text strong> {this.state.selectedPath}</Typography.Text>
                        </Typography.Text>
                    </>}/>
                    <br/>
                    <Row>
                        <Upload
                            fileList={this.state.fileList}
                            onChange={this.handleUpload}
                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                            multiple
                            accept='.csv,application/vnd.ms-excel'
                        >
                            <Button icon={<UploadOutlined/>}>Choose files</Button>

                        </Upload>
                        {this.state.uploadProgress !== undefined && this.state.uploadProgress !== 0 &&
                        <Progress percent={this.state.uploadProgress}/>}
                    </Row>

                </Modal>

                {/* DIRECTORY UPLOAD MODAL */}
                {/*TODO*/}
                <Modal visible={this.state.showUploadFolderModal} title={'Upload folder to remote directory'} closable
                       maskClosable={!this.state.uploading}
                       onCancel={this.closeFolderUploadModal}
                       onOk={(event) => this.handleSubmit(event, 'folder')}
                       okText='Upload'
                       okButtonProps={{
                           disabled: this.state.fileList.length === 0 || this.state.uploadProgress === 100,
                           loading: this.state.uploading
                       }}
                       width={720}
                >

                    <Alert message={<>
                        <Typography.Text>
                            Upload/overwrite folder to path: <Typography.Text
                            strong> {this.state.selectedPath}</Typography.Text>
                        </Typography.Text>
                    </>}/>
                    <br/>
                    <Row>
                        <Upload
                            fileList={this.state.fileList}
                            onChange={this.handleUpload}
                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                            multiple
                            directory
                        >
                            <Button icon={<UploadOutlined/>}>Choose folder</Button>

                        </Upload>
                        {this.state.uploadProgress !== undefined && this.state.uploadProgress !== 0 &&
                        <Progress percent={this.state.uploadProgress}/>}
                    </Row>

                </Modal>

                {/* DELETE MODAL */}

                <Modal visible={this.state.showDeleteModal} title={'Delete file/folder from remote directory'} closable
                       maskClosable
                       onCancel={this.closeDeleteModal}
                       onOk={this.handleDelete}
                       okText='Yes, delete file/folder'
                       okButtonProps={{danger: true, loading: this.state.deleting}}
                >

                    <Alert message={<>
                        <Typography.Text>
                            Are you sure you want to delete <Typography.Text
                            strong> {this.state.selectedPath}</Typography.Text> ?
                        </Typography.Text>
                    </>} type='warning'/>

                </Modal>

            </div>
        );
    }
}

export default withRouter(PaymentCycleRawDataFileVault);