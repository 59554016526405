import {
    Alert,
    Button,
    ConfigProvider,
    Empty,
    FormInstance,
    Modal,
    notification,
    Result,
    Row,
    Spin,
    Typography
} from 'antd';
import React, {Component} from 'react';
import {authorize, initClient} from '../../../common/gapiHandler';
import {camelCaseSplit, capitalize} from '../../../common/utils';
import './index.css';
import moment from "moment";
import {postRequest} from "../../../api/postRequest";
import {
    HalfYear,
    Month,
    NonVerbose,
    Quarter,
    ReportTypeChannel,
    ReportTypeStaticChannel,
    Verbose,
    Year
} from "../../../REPORTING/pages/SmartReports";
import enUSIntl from "antd/lib/locale/en_US";
import ProForm, {
    ProFormDatePicker,
    ProFormDependency,
    ProFormGroup,
    ProFormList,
    ProFormRadio,
    ProFormSelect
} from "@ant-design/pro-form";
import {FileExcelOutlined, FilePptOutlined} from "@ant-design/icons";
import {PageContainer} from "@ant-design/pro-components";

export interface IBrandAccountReportingProps {
    //Here we pass the Props Interface
    ppkValue?: string;
    cmsExtraId?: string
}

export interface IBrandAccountReportingState {
    //here we pass the State Interface
    isGapiLibraryLoading: boolean,
    isAuthorized: boolean,
    isAuthorizing: boolean,
    isRequestLoading: boolean,
    // emptyResponse: boolean
    brandAccountInfo?: any

//test
    processing: boolean
    success?: any
    error?: any
    resultModalVisible: boolean
    formRef: React.RefObject<FormInstance>
    loadingChannelsFromTags?: boolean
    processingInfoMultipleChannels?: { [k: string]: { processing: boolean, successData: any, error: any } }
    resultBulkModalVisible: boolean
}

//class ComponentName Component<PropsInterface, StateInterface>
class BrandAccountReporting extends Component<IBrandAccountReportingProps, IBrandAccountReportingState> {

    constructor(props: IBrandAccountReportingProps) {
        super(props)

        this.state = {
            isAuthorized: false,
            isAuthorizing: false,
            isRequestLoading: false,
            isGapiLibraryLoading: false,
            // emptyResponse: false,

            //	test
            processing: false,
            resultModalVisible: false,
            formRef: React.createRef<FormInstance>(),
            resultBulkModalVisible: false
        }
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        // console.log('cms prop', this.props.cmsExtraId);

        // this.initGAPIAndSignIn()
    }

    initGAPIAndSignIn() {
        this.setState({isGapiLibraryLoading: true})
        return new Promise((resolve, reject) => {

            console.log('1 init gapi');
            (window as any).gapi.load('client:auth2', () => initClient(async (success: boolean) => {
                console.log('4 callback')
                if (!success) {
                    reject('Could not load gapi client')
                }

                console.log(success ? 'Loaded gapi client' : 'Could not load gapi client');
                this.setState({isGapiLibraryLoading: false})


                console.log('Authorizing...');
                this.setState({isAuthorizing: true})
                try {
                    const res = await authorize()
                    console.log('auth res', res)
                    this.setState({isAuthorized: true, isAuthorizing: false, brandAccountInfo: res}, () => resolve(res))
                } catch (err) {
                    console.log('auth err', err)
                    this.setState({isAuthorized: false, isAuthorizing: false}, () => reject(err))
                }

            }))
        })
    }

    // authorize() {
    //     console.log('Authorizing...');
    //     console.log('5 authorize')
    //     this.setState({isAuthorizing: true},
    //         () => authorize()
    //             .then((res) => {
    //                 console.log('auth res', res)
    //                 this.setState({isAuthorized: true, isAuthorizing: false, brandAccountInfo: res})
    //             })
    //             .catch((err) => {
    //                 console.log('auth err', err)
    //                 this.setState({isAuthorized: false, isAuthorizing: false})
    //             })
    //     )
    // }


    onSubmit = async (values: any) => {
        // console.log({values})
        // console.log(values.params[0].start_date)
        // console.log(values.params[0].end_date)
        // console.log(values)
        // console.log(values.channel_params[0])
        // console.log(values.start_date)
        // console.log(values.period_over_period)
        // console.log(values.year_over_year)
        try {
            const res = await this.initGAPIAndSignIn()
            console.log({res})
        } catch (e) {
            console.log({e})
            return
        }

        if (values.report_type == ReportTypeChannel || values.report_type == ReportTypeStaticChannel) {
            Object.defineProperty(values, 'params',
                Object.getOwnPropertyDescriptor(values, 'channel_params')!);
            delete values['channel_params'];
            // console.log(values)

            const v = values.params[0].period
            if (values.params[0].range == Month) {
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY-MM").format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY-MM").add(1, 'M').subtract(1, 'd').format("YYYY-MM-DD"),
                    period_over_period: {
                        start_date: moment(v, "YYYY-MM").subtract(1, 'M').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-MM").subtract(1, 'd').format("YYYY-MM-DD"),
                    },
                    year_over_year: {
                        start_date: moment(v, "YYYY-MM").subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-MM").subtract(1, 'y').add(1, 'M').subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }


            if (values.params[0].range == Quarter) {
                console.log(values)
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY-Q").format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY-Q").add(1, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    period_over_period: {
                        start_date: moment(v, "YYYY-Q").subtract(1, 'Q').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-Q").subtract(1, 'd').format("YYYY-MM-DD"),
                    },
                    year_over_year: {
                        start_date: moment(v, "YYYY-Q").subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-Q").subtract(1, 'y').add(1, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }

            if (values.params[0].range == HalfYear) {
                const halfno = values.params[0].halfno
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY").add(halfno == 1 ? 2 : 4, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    //new
                    period_over_period: {
                        start_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(2, 'Q').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    },
                    year_over_year: {
                        start_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'y').add(2, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }

            if (values.params[0].range == Year) {
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY").format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY").add(1, 'y').subtract(1, 'd').format("YYYY-MM-DD"),
                    //new
                    year_over_year: {
                        start_date: moment(v, "YYYY").subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY").subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }

            // delete values.params[0]['range']
            delete values.params[0]['period']

            if (!values.report_template_requests) {
                values.report_template_requests = []
            }

            values.params[0]['is_brand_account'] = true
            // values.params[0]['auth_token'] = this.state.brandAccountInfo["Cc"]["access_token"]
            // values.params[0]['auth_token'] = this.state.brandAccountInfo["Cc"]["login_hint"]

            //find the object key which holds the token object
            let objKey = ''
            for (const key in this.state.brandAccountInfo) {
                if (this.state.brandAccountInfo[key].hasOwnProperty('access_token')) {
                    objKey = key
                    break
                }
            }
            values.params[0]['auth_token'] = this.state.brandAccountInfo[objKey]
            // console.log(values)

        }


        console.log({values})
        // console.log(values.params[0])
        // console.log(values.report_template_requests[0])
        // console.log(values.report_template_requests[1])

        // return

        this.setState({processing: true, success: null, error: null})

        // TEST
        postRequest(`/api/youtubereports/make/${values.report_type}`, values, false).then((res) => {
            this.setState({processing: false, success: res.data})
            console.log({res})
            //    show result Modal
            this.setState({resultModalVisible: true})
        }).catch((err) => {
            let message = capitalize(err.response.data.message)
            this.setState({processing: false, error: message})
            notification.error({message, duration: 0})
        })
    }

    render() {

        return (
            <div className="BrandAccountReporting" style={this.style}>
                <PageContainer>
                    <Row style={{width: '100%'}}>
                        {/*{!this.state.isAuthorized && !this.state.isAuthorizing && !this.state.isGapiLibraryLoading &&*/}
                        {/*<Row justify='center'><Text type='danger'>You are not authorized to access the performance data*/}
                        {/*    of this asset.</Text></Row>}*/}
                        {this.state.isGapiLibraryLoading && !this.state.isRequestLoading && <Row justify='center'>
                            <Spin spinning={true}
                                // tip='Library loading...'
                            />
                        </Row>}
                        {!this.state.isGapiLibraryLoading && this.state.isAuthorizing &&
                        <Row justify='center'><Spin spinning={true} tip='Authorizing...'></Spin></Row>}

                        <div style={{width: '100%'}}>
                            <Spin spinning={this.state.isRequestLoading} tip='Loading...' style={{width: '100%'}}>
                                {/*{!this.state.isGapiLibraryLoading && this.state.isAuthorized && !this.state.isRequestLoading &&*/}

                                <>
                                    <Spin spinning={this.state.processing} tip={"Processing... This won't take long."}>

                                        <Alert
                                            message={<>
                                                <Typography.Text type={"secondary"}>
                                                    {"For detailed documentation, see "}
                                                    <Typography.Link
                                                        href="https://developers.google.com/youtube/analytics/content_owner_reports#video-reports"
                                                        target={"_blank"}>the official docs</Typography.Link>.
                                                </Typography.Text>
                                            </>}
                                            type={"info"}
                                            showIcon={true}
                                        />

                                        <ConfigProvider locale={enUSIntl}>
                                            <ProForm size={"large"} onFinish={(values) => this.onSubmit(values)}
                                                     formRef={this.state.formRef as any} style={{width: '100%'}}
                                                // submitter={{submitButtonProps:{title:'test'}}}
                                                     submitter={{searchConfig: {submitText: 'Select Channel & Submit'}}}
                                            >
                                                {/*Report Type*/}
                                                <ProFormSelect name="report_type" label={"Report Type"} disabled
                                                               initialValue={ReportTypeStaticChannel}
                                                               rules={[{required: true}]}
                                                               fieldProps={{defaultActiveFirstOption: true}} // works ? maybe not
                                                               request={async () => {
                                                                   let res = await postRequest('/api/youtubereports/supported_report_types')
                                                                   let data = res.data
                                                                   // let options: { [k: string]: string }[] = [] Doesnt work
                                                                   // data.map((i: string) => options.push({i}))
                                                                   let options: { label?: string, value?: string }[] = []
                                                                   data.map((i: string) => options.push({
                                                                       label: camelCaseSplit(i),
                                                                       value: i
                                                                   }))
                                                                   // console.log({options})
                                                                   return options
                                                               }}/>
                                                {/*Params*/}
                                                <ProFormDependency name={['report_type']}>
                                                    {({report_type}) => {
                                                        // console.log({report_type})
                                                        if (!report_type) {
                                                            return <Empty description={"Select a report type"}/>;
                                                        }

                                                        if (report_type == ReportTypeStaticChannel) {
                                                            return (
                                                                <>
                                                                    <ProFormList
                                                                        name={['channel_params']}
                                                                        label="Report Parameters"
                                                                        creatorButtonProps={false}
                                                                        deleteIconProps={false}
                                                                        copyIconProps={false}
                                                                        initialValue={[
                                                                            {}
                                                                        ]}
                                                                        key={'channel-list'}
                                                                    >

                                                                        <ProFormGroup>

                                                                            <ProFormRadio.Group
                                                                                name={"range"}
                                                                                label="Range"
                                                                                radioType="button"
                                                                                options={[
                                                                                    {label: 'Month', value: Month,},
                                                                                    {label: 'Quarter', value: Quarter,},
                                                                                    {
                                                                                        label: 'Half-Year',
                                                                                        value: HalfYear,
                                                                                    },
                                                                                    {label: 'Year', value: Year,},
                                                                                ]}
                                                                                initialValue={Month}
                                                                                rules={[{required: true}]}
                                                                            />


                                                                            <ProFormDependency name={['range']}>
                                                                                {({range}) => {
                                                                                    // let range=range.range
                                                                                    // console.log({range})
                                                                                    if (!range) {
                                                                                        return <Empty
                                                                                            description={"Select range"}/>;
                                                                                    }
                                                                                    if (range == Month) {
                                                                                        return (
                                                                                            <>
                                                                                                <ProFormDatePicker.Month
                                                                                                    name="period"
                                                                                                    label="Period"
                                                                                                    fieldProps={{
                                                                                                        format: 'YYYY-MM',
                                                                                                        // onChange:(v,d)=>{
                                                                                                        //     console.log(v,d)
                                                                                                        // }
                                                                                                    }}

                                                                                                    rules={[{
                                                                                                        // type: 'object',
                                                                                                        required: true,
                                                                                                    }]}
                                                                                                    initialValue={moment(moment().startOf('month').subtract(1, 'month'), "YYYY-MM-DD")}
                                                                                                />

                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    if (range == Quarter) {
                                                                                        return <ProFormDatePicker.Quarter
                                                                                            name="period"
                                                                                            label="Period"
                                                                                            fieldProps={{
                                                                                                format: "YYYY-Q"
                                                                                            }}

                                                                                            rules={[{
                                                                                                required: true,
                                                                                            }]}
                                                                                        />
                                                                                    }
                                                                                    if (range == HalfYear) {
                                                                                        return <ProFormDatePicker.Year
                                                                                            name="period"
                                                                                            label="Period"
                                                                                            addonAfter={
                                                                                                <ProFormRadio.Group
                                                                                                    name="halfno"
                                                                                                    noStyle
                                                                                                    radioType="button"
                                                                                                    options={[
                                                                                                        {
                                                                                                            label: '1st',
                                                                                                            value: 1,
                                                                                                        },
                                                                                                        {
                                                                                                            label: '2nd',
                                                                                                            value: 2,
                                                                                                        },
                                                                                                    ]}
                                                                                                    initialValue={1}
                                                                                                    rules={[{required: true}]}
                                                                                                    // transform={(_) => ({})}
                                                                                                />
                                                                                            }
                                                                                            fieldProps={{
                                                                                                format: 'YYYY'
                                                                                            }}

                                                                                            rules={[{
                                                                                                required: true,
                                                                                            }]}
                                                                                        />
                                                                                    }
                                                                                    if (range == Year) {
                                                                                        return <ProFormDatePicker.Year
                                                                                            name="period"
                                                                                            label="Period"
                                                                                            fieldProps={{
                                                                                                format: 'YYYY'
                                                                                            }}

                                                                                            rules={[{
                                                                                                required: true,
                                                                                            }]}
                                                                                        />
                                                                                    }
                                                                                    return null
                                                                                }
                                                                                }
                                                                            </ProFormDependency>

                                                                            <ProFormDependency name={['range']}>
                                                                                {({range}) => {
                                                                                    // if (range == HalfYear || range == Year) return null
                                                                                    return (<ProFormRadio.Group
                                                                                        name={"verbose"}
                                                                                        label="Verbose"
                                                                                        radioType="button"
                                                                                        options={[
                                                                                            {
                                                                                                label: 'Yes',
                                                                                                value: Verbose,
                                                                                            },
                                                                                            {
                                                                                                label: 'No',
                                                                                                value: NonVerbose,
                                                                                            }
                                                                                        ]}
                                                                                        initialValue={Verbose}
                                                                                        rules={[{required: true}]}
                                                                                        // disabled={range == HalfYear || range == Year}
                                                                                        // help={range == HalfYear || range == Year ? 'Only non-comparative reports can be exported.' : 'Applies to every template request where Verbose=Auto.'}
                                                                                        help={range == Year ? 'Only non-verbose reports can be exported.' : 'Applies to every template request where Verbose=Auto.'}
                                                                                    />)
                                                                                }}
                                                                            </ProFormDependency>

                                                                        </ProFormGroup>

                                                                    </ProFormList>
                                                                </>

                                                            )
                                                        }


                                                        return null;
                                                    }}


                                                </ProFormDependency>

                                                {/*<Row>*/}
                                                {/*    <Col span={24}>*/}
                                                <ProFormDependency name={['report_type']} ignoreFormListField
                                                                   style={{width: '100%'}}>
                                                    {({report_type}) => {
                                                        // console.log({report_type})
                                                        if (!report_type) {
                                                            return null;
                                                            // return <Empty description={"Select a report type"}/>;
                                                        }

                                                        if (report_type.includes("Static")) {
                                                            return <>
                                                                <Alert
                                                                    message={"The templates included in the static report are the following:"}
                                                                    showIcon
                                                                />
                                                                <Alert
                                                                    message={"" +
                                                                    // "The templates included in the static report are the following:\n" +
                                                                    "- Key Channel Metrics\n" +
                                                                    // "\t* Key Channel Metrics\n" +
                                                                    "- Performance Overview\n" +
                                                                    "\t* Views\n" +
                                                                    "\t* Watchtime\n" +
                                                                    "\t* Top Videos\n" +
                                                                    "\t* Subscribers vs Non Subscribers Watchtime\n" +
                                                                    "\t* Subscribers Growth\n" +
                                                                    "\t* Subscribers Source\n" +
                                                                    "\t* Subscribers YouTube Watch Page\n" +
                                                                    "- Channel Reach\n" +
                                                                    "\t* Traffic Sources (Views)\n" +
                                                                    "\t* Traffic Sources: YouTube Search (Keywords)\n" +
                                                                    "\t* Devices\n" +
                                                                    "\t* Playback Locations\n" +
                                                                    "- Audience Demographics\n" +
                                                                    "\t* Age Groups\n" +
                                                                    "\t* Gender\n" +
                                                                    "\t* Geography\n" +
                                                                    "- Engagement\n" +
                                                                    "\t* Likes\n" +
                                                                    "\t* Shares\n" +
                                                                    "\t* Endscreens/Cards\n" +
                                                                    "\t* Playlists\n"
                                                                        // "Please not that these templates cannot be modified by the user in the 'static' report type."
                                                                    }
                                                                    type={"info"}
                                                                    // showIcon
                                                                    style={{whiteSpace: "pre"}}
                                                                />
                                                                <Alert
                                                                    message={"Please not that these templates cannot be modified by the user in the 'static' report type."}
                                                                    showIcon
                                                                />
                                                                <br/>
                                                            </>
                                                        }


                                                    }}
                                                </ProFormDependency>
                                                {/*</Col>*/}
                                                {/*</Row>*/}
                                            </ProForm>
                                        </ConfigProvider>
                                    </Spin>


                                    {/*Result Modal For single channel*/}
                                    {this.state.success &&
                                    <Modal
                                        visible={this.state.resultModalVisible}
                                        onOk={() => {
                                            this.setState({resultModalVisible: false})
                                        }}
                                        closable={false}
                                        maskClosable={true}
                                        cancelButtonProps={{hidden: true}}
                                    >
                                        <Result title={"Report Created Successfully!"} status="success" subTitle={
                                            <>
                                                <Typography.Text type={"secondary"}>Shared with:
                                                    digitalminds.com</Typography.Text>
                                            </>
                                        }
                                                extra={[
                                                    <a href={this.state.success.presentationUrl} target={"_blank"}>
                                                        <Button size={"large"} type="primary" key="presentation"
                                                                icon={<FilePptOutlined/>}>Go To
                                                            Presentation</Button>
                                                    </a>,
                                                    <a href={this.state.success.spreadsheetUrl} target={"_blank"}>
                                                        <Button size={"large"} key="spreadsheet"
                                                                icon={<FileExcelOutlined/>}>Go To
                                                            Spreadsheet</Button>
                                                    </a>
                                                ]}
                                        />

                                    </Modal>
                                    }
                                </>
                                {/*}*/}

                            </Spin>
                        </div>
                    </Row>

                </PageContainer>
            </div>
        );
    }
}

export default BrandAccountReporting;