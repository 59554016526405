import React from 'react';
import {Column} from "@ant-design/plots";
import ProCard from "@ant-design/pro-card";

const ColumnLoading = () => {
    return (
        <ProCard ghost>
            <Column xField={"x"} yField={"y"} data={[{x: 1, y: 1}]} loading></Column>
        </ProCard>
    );
};

export default ColumnLoading;