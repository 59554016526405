import { graphDataObject, roundByUnit } from './graphConfigBuilder';
import { roundNumAdvanced } from '../../../common/utils';

type totalsObject = {
  key: number;
  value: number;
  valueUnit: string;
  dateRange: string;
  dateRangeChronologicalOrder: number;
  dateRangeTopologicalOrder: number;
};

/**
 * Build the totals object needed to display the total sum of values per date range for the given date ranges
 *
 * @param data The graph config object's data parameter
 * @returns A totals object
 */
export const buildTotals = (data: Array<graphDataObject>[]) => {
  let totals: Array<totalsObject> = [];
  let key = 0;
  for (const dataArray of data) {
    key += 1;
    const firstObject = dataArray[0];
    const dateRange = firstObject.dateRange;
    const dateRangeChronologicalOrder = firstObject.dateRangeChronologicalOrder;
    const dateRangeTopologicalOrder = firstObject.dateRangeTopologicalOrder;
    const valueUnit = firstObject.valueUnit;
    let value = 0;
    for (const obj of dataArray) {
      value += parseInt(obj.valueAlpha);
    }
    totals = totals.concat({
      key: key,
      value: valueUnit ? roundNumAdvanced(value, undefined, 2, valueUnit).value : value,
      valueUnit: valueUnit,
      dateRange: dateRange,
      dateRangeChronologicalOrder: dateRangeChronologicalOrder,
      dateRangeTopologicalOrder: dateRangeTopologicalOrder,
    });
  }
  return totals;
};
