import {EditOutlined, YoutubeFilled} from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    Descriptions,
    Image,
    List,
    Row,
    Space,
    Spin,
    Statistic,
    Switch,
    Typography
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import Avatar from 'antd/lib/avatar/avatar';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {youtubeGetChannelImageTitleTopics} from '../../../api/youtubeGetChannelImageTitleTopics';
import {ExtendedAssetInfo} from '../../../common/models';
import AssetPerformance from '../../components/AssetPerformance';
import EntityTags from '../../components/EntityTags';
import './index.css';
import {postRequest} from "../../../api/postRequest";
import {PageContainer} from "@ant-design/pro-components";
import withRouter from "../../../components/withRouter";

interface IMatchParams {
    id: string;
}

export interface IExtendedAssetProps {
    //Here we pass the Props Interface
    params: any
}

export interface IExtendedAssetState {
    //here we pass the State Interface
    data?: ExtendedAssetInfo;
    isLoading: boolean;
    imgUrl?: string;
    channelTitle?: string;
    youtubePerformanceEnabled: boolean
}

const IMG_RESOLUTION: 'default' | 'medium' | 'high' = 'high';
const ENABLE_IMG_RENDER: boolean = true;


//class ComponentName Component<PropsInterface, StateInterface>
class ExtendedAsset extends Component<IExtendedAssetProps, IExtendedAssetState> {
    constructor(props: IExtendedAssetProps) {
        super(props);

        this.state = {
            isLoading: true,
            youtubePerformanceEnabled: false

        };

        this.getFunc = this.getFunc.bind(this);
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getFunc();

    }

    getFunc = async () => {
        postRequest('/api/assets/get/' + this.props.params.id)
            .then(async (response) => {
                console.log('Authenticated');
                // console.log('resas', response);
                // const result = response.data.arr[0] as ExtendedAssetInfo;
                const result = response.data as ExtendedAssetInfo;
                // console.log('resas', result);

                if (ENABLE_IMG_RENDER) {
                    if (result.ppk_id == 1) {
                        const res = await youtubeGetChannelImageTitleTopics(result.ppk_value)
                        this.setState({imgUrl: res?.imgUrl, channelTitle: res?.channelTitle});
                    }
                }
                // if (result.platform === 'YOUTUBE' && result.ppk_value.startsWith('UC')) {}

                this.setState({
                    data: result,
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.log('Error on GET :' + error);
            });
    };

    render() {

        // const FinancialsWithPermission =(props:any)=> withPermissions('read', 'finances::data')(<AssetFinancialChart {...props}/>, null)

        return (
            <div className="ExtendedAsset" style={this.style}>
                <PageContainer>
                    <Spin spinning={this.state.isLoading}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => window.history.back()}
                            //  title={'Asset ' + this.state.data?.id}
                            //  subTitle={this.state.channelTitle}
                            title={this.state.channelTitle || 'Asset ' + this.state.data?.id}
                            subTitle={'Asset ID: ' + this.state.data?.id}
                            //  subTitle={'(' + this.state.data?.id + ')'}
                            extra={
                                [
                                    <Link to={'/crm/assets/edit/' + this.props.params.id}>
                                        <Button key="edit" icon={<EditOutlined/>}>Edit</Button>
                                    </Link>
                                ]
                            }
                            //  avatar={{ src: this.state.imgUrl }}
                        >
                            <Row justify="space-between">
                                <Col span={20}>
                                    <Space size={50}>
                                        {this.state.data?.platform &&
                                        <Statistic title="Platform" value={this.state.data?.platform}/>}
                                        {this.state.data?.asset_domain &&
                                        <Statistic title="Domain" value={this.state.data?.asset_domain}/>}
                                        {/* <Statistic title="Tier" value={this.state.data?.tier} /> */}
                                        {this.state.data?.commission &&
                                        <Statistic title="Commission" value={this.state.data?.commission}/>}
                                        {this.state.data?.cms && <Statistic title="CMS" value={this.state.data?.cms}/>}
                                        {this.state.data?.platform === 'YOUTUBE' && this.state.data?.ppk_value.startsWith('UC') &&
                                        <Statistic title="Channel Link"
                                                   valueRender={(_) => <Row justify='center'><a
                                                       href={'https://youtube.com/channel/' + this.state.data?.ppk_value}
                                                       target='_blank'><YoutubeFilled
                                                       style={{fontSize: 40, color: '#FF0000'}}/></a></Row>}
                                        />
                                        }
                                        <Statistic title="Key"
                                                   valueRender={(_) => <Text>{this.state.data?.ppk_value}</Text>}
                                        />
                                    </Space>
                                    {/* <br />
                           <br /> */}
                                    <Descriptions size="small" column={2}>
                                        {/* <Descriptions.Item label="Key">{this.state.data?.ppk_value}</Descriptions.Item> */}
                                        {/* {this.state.data?.platform === 'YOUTUBE' && this.state.data?.ppk_value.startsWith('UC') && (
                                 <Descriptions.Item label="Channel Link">
                                    <a
                                       target="_blank"
                                       href={'https://youtube.com/channel/' + this.state.data?.ppk_value}
                                    >
                                       Visit
                                    </a>
                                 </Descriptions.Item>
                              )} */}
                                        {/* <Descriptions.Item label="Platform">{this.state.data?.platform}</Descriptions.Item> */}
                                        {/* <Descriptions.Item label="Commission">{this.state.data?.commission}</Descriptions.Item> */}
                                        {/* <Descriptions.Item label="Customer">{this.state.data?.customer}</Descriptions.Item> */}
                                        {/* {this.state.data?.customer && <Descriptions.Item label="Customer">
                                 <Link to={'/crm/view/' + this.state.data?.customer_id}>
                                    {this.state.data?.customer + ' (' + this.state.data?.customer_id + ')'}
                                 </Link>
                              </Descriptions.Item>} */}

                                        {/* <Descriptions.Item label="Tier">{this.state.data?.tier}</Descriptions.Item> */}
                                        {this.state.data?.affiliate != 0 && String(this.state.data?.affiliate) !== '' && (
                                            <>
                                                <Descriptions.Item label="Affiliate">
                                                    {this.state.data?.affiliate}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Affiliate Commission">
                                                    {this.state.data?.affiliate_commission}
                                                </Descriptions.Item>
                                            </>
                                        )}
                                    </Descriptions>
                                </Col>

                                {/* <Col span={8}> */}
                                <Avatar src={<Image placeholder src={this.state.imgUrl}/>} size={130}/>
                                {/* <Image placeholder src={this.state.imgUrl} width={100} /> */}
                                {/* </Col> */}
                            </Row>
                            {/* <br /> */}
                            {/* <br /> */}

                            <Title level={4}>Linked Customer</Title>
                            <Card>
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar>{this.state.data?.customer_id}</Avatar>}
                                        // title={<a href="https://ant.design">{item.ppk_value}</a>}
                                        // title={<a href={"/crm/view/" + this.state.data?.customer_id}>{this.state.data?.customer} (ID: {this.state.data?.customer_id})</a>}
                                        title={<Link
                                            to={"/crm/customers/view/" + this.state.data?.customer_id}>{this.state.data?.customer} (ID: {this.state.data?.customer_id})</Link>}
                                        description={this.state.data?.cms}

                                    />
                                </List.Item>
                            </Card>
                        </PageHeader>
                        <br/>
                        <br/>
                        <div style={{margin: '0 24px 0 24px'}}>
                            {this.state.data?.ppk_id == 1 && this.state.data.ppk_value && this.state.data.cms_extra_id &&
                            <>
                                <Row align='middle'>
                                    <Typography.Title level={4}>Youtube Performance{'   '}
                                        <Switch
                                            checked={this.state.youtubePerformanceEnabled}
                                            onChange={(checked, _) => this.setState({youtubePerformanceEnabled: checked})}
                                            // title={this.state.youtubePerformanceEnabled ? 'Click to disable' : 'Click to enable'}
                                            checkedChildren='Disable'
                                            unCheckedChildren='Enable'
                                        />
                                    </Typography.Title>
                                    {/* <Text style={{ marginLeft: 10 }} type='secondary'>Click to enable/disable</Text> */}
                                </Row>
                                <br/>
                                {/*TODO FIX QUERIES (different for views and revenue --see chart engine AND IMPLEMENTATION --with golang in backend*/}
                                <Row style={{width: '100%'}}>
                                    {this.state.youtubePerformanceEnabled &&
                                    <AssetPerformance ppkValue={this.state.data.ppk_value}
                                                      cmsExtraId={this.state.data.cms_extra_id}/>}
                                </Row>
                                <br/>
                                <br/>
                            </>}


                            {/*<Row>*/}
                            {/*    <Typography.Title level={4}>Financials</Typography.Title>*/}
                            {/*</Row>*/}
                            {/*<br/>*/}
                            {/*<Row>*/}
                            {/*    {this.state.data === undefined ? <Row justify='center'><Spin/></Row> :*/}
                            {/*        <AssetFinancialChart asset_id={parseInt(this.props.match.params.id)}*/}
                            {/*                             ppk_value={this.state.data?.ppk_value!}*/}
                            {/*                             ppk_id={this.state.data?.ppk_id!}/>*/}
                            {/*    }*/}
                            {/*</Row>*/}
                            {/*<br/>*/}


                            <Row>
                                <Title level={4}>Tags</Title>
                            </Row>
                            <br/>
                            <Row>
                                <EntityTags entityId={this.props.params.id} entityDomain="_asset"/>
                            </Row>
                        </div>
                    </Spin>
                </PageContainer>
            </div>
        );
    }
}

export default withRouter(ExtendedAsset);
