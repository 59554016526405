import React, {Component} from 'react';
import './index.css';
import {ConfigProvider, FormInstance, notification, Popconfirm, Switch, Typography} from "antd";
import enUSIntl from "antd/lib/locale/en_US";
import {ActionType, EditableProTable, ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import ProForm, {ProFormDependency, ProFormSelect} from "@ant-design/pro-form";
import {getRndInteger} from "../../../common/utils";
import axiosApiInstance from "../../../api/axiosClient";

export interface IAdminTagsProps {
    //Here we pass the Props Interface
}

export interface IAdminTagsState {
    //here we pass the State Interface

    //Tag Categories
    editableKeysTagCategory: any,
    dataSourceTagCategory: DataSourceTypeTagCategory[]
    changedRecordKeysTagCategory: React.Key[]
    refTagCategory: React.RefObject<ActionType>

    //Tag Category Values
    editableKeysTagCategoryValues: any,
    dataSourceTagCategoryValues: DataSourceTypeTagCategoryValues[]
    changedRecordKeysTagCategoryValues: React.Key[]
    refTagCategoryValues: React.RefObject<ActionType>
    refTagCategorySelect: React.RefObject<FormInstance>

    //Taggation
    dataSourceTaggation: DataSourceTypeTaggation[]
    refTaggation: React.RefObject<ActionType>
}

type DataSourceTypeTagCategory = {
    id: number
    category_name: string
    color: string
    controlled_by_customer: boolean;
    allow_multiple: boolean;
};

type DataSourceTypeTagCategoryValues = {
    id: number
    value: string
};

type DataSourceTypeTaggation = {
    id: number
    taggable_entity_id: number
    entity_id: number
    tag_category_id: number
    tag_category_value_id: number
};

type DataSourceTypeTaggableEntity = {
    id: number
    entity_name: string
    get_endpoint: string
};

//class ComponentName Component<PropsInterface, StateInterface>
class AdminTags extends Component<IAdminTagsProps, IAdminTagsState> {

    //Component State
    state = {

        //Tag Categories
        editableKeysTagCategory: [],
        dataSourceTagCategory: [],
        changedRecordKeysTagCategory: [],
        refTagCategory: React.createRef<ActionType>(),

        //Tag Category Values
        editableKeysTagCategoryValues: [],
        dataSourceTagCategoryValues: [],
        changedRecordKeysTagCategoryValues: [],
        refTagCategoryValues: React.createRef<ActionType>(),
        refTagCategorySelect: React.createRef<FormInstance>(),

        //Taggation
        dataSourceTaggation: [],
        refTaggation: React.createRef<ActionType>(),

    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }

    onSaveTagCategory = async (key: any, row: DataSourceTypeTagCategory & { index?: number | undefined },
                               newLineConfig: DataSourceTypeTagCategory & { index?: number | undefined }) => {
        // console.log({key, row, newLineConfig}, this.state.dataSourceTagCategory);
        // return
        if (key < 0) { //    Add
            try {
                let {id, ...rest} = row;
                const res = await axiosApiInstance.post('/api/tags/addcategory', {
                    // category_name: row.category_name,
                    // color: row.color,
                    // controlled_by_customer: row.controlled_by_customer,
                    // allow_multiple: row.allow_multiple,
                    ...rest
                })
                this.refreshDataSources()
                notification.success({message: 'Success'})
            } catch (e) {
                // notification.error({message: e.response.data.message})
            }
        } else { //    Update
            try {
                const res = await axiosApiInstance.put('/api/tags/updatecategory', {
                    // id: row.id,
                    // category_name: row.category_name,
                    // color: row.color,
                    // controlled_by_customer: row.controlled_by_customer,
                    // allow_multiple: row.allow_multiple,
                    ...row
                })
                notification.success({message: 'Success'})
            } catch (e) {
                // notification.error({message: e.response.data.message})
            }
        }
    }

    onDeleteTagCategory = async (id: number) => {
        try {
            const res = await postRequest('/api/tags/deletecategory', {id})
            // console.log({res})
            this.refreshDataSources()
            notification.success({message: 'Success'})
        } catch (e) {
            // notification.error({message: e.response.data.message})
        }
    }


    onSaveTagCategoryValues = async (key: any, row: DataSourceTypeTagCategoryValues & { index?: number | undefined },
                                     originRow: DataSourceTypeTagCategoryValues & { index?: number | undefined },
                                     newLineConfig: any, category_id: number) => {
        // console.log({key, row, newLineConfig}, this.state.dataSourceTagCategory);
        if (key < 0) { //    Add
            try {
                const res = await postRequest('/api/tags/addcategoryvalue', {
                    category_id,
                    value: row.value
                })
                this.state.refTagCategoryValues.current?.reload()
                notification.success({message: 'Success'})
            } catch (e) {
                notification.error({message: e.response.data.message})
            }
        } else { //    Update
            try {
                const res = await postRequest('/api/tags/updatecategoryvalue', {
                    id: row.id,
                    value: row.value
                })
                notification.success({message: 'Success'})
            } catch (e) {
                notification.error({message: e.response.data.message})
            }
        }
    }

    onDeleteTagCategoryValue = async (id: number) => {
        try {
            const res = await postRequest('/api/tags/deletecategoryvalue', {id})
            console.log({res})
            this.state.refTagCategoryValues.current?.reload()
            notification.success({message: 'Success'})
        } catch (e) {
            notification.error({message: e.response.data.message})
        }
    }


    onCreateTaggation = async (values: DataSourceTypeTaggation) => {
        try {
            const res = await postRequest('/api/tags/addtaggation', {
                taggable_entity_id: values.taggable_entity_id,
                entity_id: values.entity_id,
                tag_category_id: values.tag_category_id,
                tag_category_value_id: values.tag_category_value_id
            })
            this.state.refTaggation.current?.reload()
            notification.success({message: 'Success'})
        } catch (e) {
            notification.error({message: e.response.data.message})
        }
    }

    onDeleteTaggation = async (id: number) => {
        try {
            const res = await postRequest('/api/tags/deletetaggation', {id})
            console.log({res})
            this.state.refTaggation.current?.reload()
            notification.success({message: 'Success'})
        } catch (e) {
            notification.error({message: e.response.data.message})
        }
    }

    columnsTagCategory: ProColumns<DataSourceTypeTagCategory>[] = [
        {
            title: 'ID', dataIndex: 'id', editable: false,
            render: (text, record) => {
                if (record.id <= 0)
                    return 'auto'
                return text;
            }
        },
        {
            title: 'Category name', dataIndex: 'category_name', editable: (text, record, index) => {
                return true;
            }
        },
        {
            title: 'Category color', dataIndex: 'color', editable: (text, record, index) => {
                return true;
            }, valueType: 'color'
        },
        {
            title: 'Allow multiple', dataIndex: 'allow_multiple',
            editable: (text, record, index) => {
                return true;
            },
            valueType: 'switch',
            // valueEnum: {
            //     0: {
            //         text: 'yes',
            //         status: 'Error',
            //     },
            //     1: {
            //         text: '已解决',
            //         status: 'Success',
            //     },
            // },
            render: (text, record, _, action) => {
                if (action.isEditable({index: record.id}).isEditable) {
                    return <Switch checked={record.allow_multiple}
                        // onChange={checked => action.saveEditable(record.id)}
                    />
                }
                return record.allow_multiple ? 'Yes' : 'No'
            }
        },
        {
            title: 'Controlled by customer', dataIndex: 'controlled_by_customer', editable: (text, record, index) => {
                return true;
            }, valueType: 'switch',
            render: (text, record, _, action) => {
                if (action.isEditable({index: record.id}).isEditable) {
                    return <Switch checked={record.controlled_by_customer}
                        // onChange={checked => action.saveEditable(record.id)}
                    />
                }
                return record.controlled_by_customer ? 'Yes' : 'No'
            }
        },
        {
            title: 'Action',
            valueType: 'option',
            width: 200,
            render: (text, record, _, action) => [
                <a
                    key="editable"
                    onClick={() => {
                        action?.startEditable?.(record.id);
                    }}
                >
                    Edit
                </a>,
                <Popconfirm title={'Delete this line?'} onConfirm={() => this.onDeleteTagCategory(record.id)}>
                    <a key="delete">Delete</a>
                </Popconfirm>
            ],
        },
    ];

    columnsTagCategoryValues: ProColumns<DataSourceTypeTagCategoryValues>[] = [
        {
            title: 'ID', dataIndex: 'id', editable: false,
            render: (text, record) => {
                if (record.id <= 0)
                    return 'auto'
                return text;
            }
        },
        {
            title: 'Category value', dataIndex: 'value', editable: (text, record, index) => {
                return true;
            }
        },
        {
            title: 'Action',
            valueType: 'option',
            width: 200,
            render: (text, record, _, action) => [
                <a
                    key="editable"
                    onClick={() => {
                        action?.startEditable?.(record.id);
                    }}
                >
                    Edit
                </a>,
                <Popconfirm title={'Delete this line?'} onConfirm={() => this.onDeleteTagCategoryValue(record.id)}>
                    <a key="delete">Delete</a>
                </Popconfirm>
            ],
        },
    ];

    columnsTaggation: ProColumns<DataSourceTypeTaggation>[] = [
        {
            title: 'ID', dataIndex: 'id', editable: false,
            render: (text, record) => {
                if (record.id <= 0)
                    return 'auto'
                return text;
            }
        },
        {title: 'Taggable Entity (Domain)', dataIndex: 'taggable_entity_id', editable: false},
        {title: 'Taggable Entity ID (Actual)', dataIndex: 'entity_id', valueType: 'digit', editable: false},
        {title: 'Category ID', dataIndex: 'tag_category_id', editable: false},
        {title: 'Category Value ID', dataIndex: 'tag_category_value_id', editable: false},
        {
            title: 'Action',
            valueType: 'option',
            width: 200,
            render: (text, record, _, action) => [
                <Popconfirm title={'Delete this line?'} onConfirm={() => this.onDeleteTaggation(record.id)}>
                    <a key="delete">Delete</a>
                </Popconfirm>
            ],
        },
    ];


    refreshDataSources = (refreshSelect = true) => {
        this.state.refTagCategory.current?.reload()
        this.state.refTagCategoryValues.current?.reload()
        if (refreshSelect)
            this.state.refTagCategorySelect.current?.resetFields()
    }

    render() {
        return (
            <>
                {/*<MainLayout breadcrumpLinks={['/admin', '/admin/tags']}*/}
                {/*            breadcrumpTitles={['Admin Dashboard', 'Admin Tags']}>*/}
                {/*Tag Categories*/}
                <Typography.Title level={4}>Tag Categories</Typography.Title>
                <br/>

                <ConfigProvider locale={enUSIntl}>
                    <EditableProTable<DataSourceTypeTagCategory>
                        request={async (params, sort, filter) => {
                            const res = await postRequest('/api/tags/getcategories')
                            console.log(res)
                            return {data: res.data, success: true, total: res.data.length}
                        }}

                        actionRef={this.state.refTagCategory as any}
                        columns={this.columnsTagCategory}
                        rowKey="id"
                        controlled={true}
                        value={this.state.dataSourceTagCategory}
                        onChange={(dataSource) => this.setState({dataSourceTagCategory: dataSource as any})}
                        recordCreatorProps={{
                            newRecordType: 'dataSource',
                            record: (index, dataSource) => ({
                                id: getRndInteger(-100000, -1),
                                // id: index,
                                // id: 0,
                                category_name: '',
                                color: '',
                                allow_multiple: false,
                                controlled_by_customer: false,
                            }),
                            creatorButtonText: 'Add Category',

                        }}
                        // recordCreatorProps={false}
                        pagination={{pageSize: 6, hideOnSinglePage: false, showSizeChanger: true}}
                        editable={{
                            type: 'single',
                            editableKeys: this.state.editableKeysTagCategory,
                            actionRender: (row, config, defaultDoms) => {
                                return [defaultDoms.save, defaultDoms.delete || defaultDoms.cancel];
                            },
                            onChange: (editableKeys) => this.setState({editableKeysTagCategory: editableKeys}),
                            onSave: this.onSaveTagCategory,
                            deletePopconfirmMessage: 'Delete this line?',
                            onlyOneLineEditorAlertMessage: 'Only one line can be edited at the same time',
                            onlyAddOneLineAlertMessage: 'Only add one line'
                        }}
                        bordered


                    />

                </ConfigProvider>
                <br/>

                {/*Tag Category Values*/}
                <Typography.Title level={4}>Allowed Values for each Tag Category</Typography.Title>
                <br/>

                <ConfigProvider locale={enUSIntl}>

                    <ProForm
                        submitter={false}
                        onValuesChange={this.state.refTagCategoryValues.current?.reload}
                        formRef={this.state.refTagCategorySelect as any}
                    >
                        <ProFormSelect
                            name="category_id"
                            label={'Category'}
                            fieldProps={{defaultActiveFirstOption: true}}
                            request={async () => {
                                try {
                                    const res = await postRequest('/api/tags/getcategories')
                                    // console.log('getcategories', res)
                                    return res.data.map((item: DataSourceTypeTagCategory) => ({
                                        label: item.category_name,
                                        value: item.id
                                    }))
                                } catch (e) {
                                    notification.error({message: e.response.data.message})
                                }
                            }}

                        />

                        <ProFormDependency name={['category_id']}>
                            {({category_id}) => {
                                // console.log('category_id', category_id)
                                if (!category_id) return null
                                return (
                                    <EditableProTable<DataSourceTypeTagCategoryValues>
                                        request={async (params, sort, filter) => {
                                            const res = await postRequest('/api/tags/getvaluesforcategory', {id: category_id})
                                            console.log('getvaluesforcategory', res)
                                            return {data: res.data, success: true, total: res.data.length}
                                        }}

                                        actionRef={this.state.refTagCategoryValues as any}
                                        columns={this.columnsTagCategoryValues}
                                        rowKey="id"
                                        controlled={true}
                                        value={this.state.dataSourceTagCategoryValues}
                                        onChange={(dataSource) => this.setState({dataSourceTagCategoryValues: dataSource as any})}
                                        recordCreatorProps={{
                                            newRecordType: 'dataSource',
                                            record: (index) => ({
                                                id: getRndInteger(-100000, -1),
                                                // id: 0,
                                                value: '',
                                            }),
                                            creatorButtonText: 'Add Category Value',
                                        }}
                                        pagination={{pageSize: 6, hideOnSinglePage: false, showSizeChanger: true}}
                                        editable={{
                                            type: 'single',
                                            editableKeys: this.state.editableKeysTagCategoryValues,
                                            actionRender: (row, config, defaultDoms) => {
                                                return [defaultDoms.save, defaultDoms.delete || defaultDoms.cancel];
                                            },
                                            onChange: (editableKeys) => this.setState({editableKeysTagCategoryValues: editableKeys}),
                                            onSave: async (key, row, originRow, newLine) => this.onSaveTagCategoryValues(key, row, originRow, newLine, category_id),
                                            deletePopconfirmMessage: 'Delete this line?',
                                            onlyOneLineEditorAlertMessage: 'Only one line can be edited at the same time',
                                            onlyAddOneLineAlertMessage: 'Only add one line'
                                        }}
                                        bordered
                                    />


                                );
                            }}
                        </ProFormDependency>
                    </ProForm>

                </ConfigProvider>
                <br/>

                <Typography.Title level={4}>New Taggation</Typography.Title>
                <ConfigProvider locale={enUSIntl}>

                    <ProForm
                        // submitter={false}
                        onValuesChange={this.state.refTagCategoryValues.current?.reload}
                        formRef={this.state.refTagCategorySelect as any}
                        layout={'horizontal'}
                        name={'tag_assign'}
                        onFinish={this.onCreateTaggation}
                    >
                        <ProFormSelect
                            name="taggable_entity_id"
                            label={'Taggable Entity (Domain)'}
                            fieldProps={{defaultActiveFirstOption: true}}
                            rules={[{required: true}]}
                            request={async () => {
                                try {
                                    const res = await postRequest('/api/tags/gettaggableentities')
                                    console.log('gettaggableentities', res)
                                    return res.data.map((item: DataSourceTypeTaggableEntity) => ({
                                        label: item.entity_name,
                                        value: item.id
                                    }))
                                } catch (e) {
                                    notification.error({message: e.response.data.message})
                                }
                            }}
                        />

                        <ProFormDependency name={['taggable_entity_id']}>
                            {({taggable_entity_id}) => {
                                console.log('taggable_entity_id', taggable_entity_id)
                                if (!taggable_entity_id) return null
                                return (
                                    <ProFormSelect
                                        dependencies={['taggable_entity_id']}
                                        name="entity_id"
                                        label={'Taggable Entity (Actual)'}
                                        fieldProps={{defaultActiveFirstOption: true}}
                                        rules={[{required: true}]}
                                        request={async () => {
                                            try {
                                                const res = await postRequest('/api/tags/gettaggableentityendpoint', {id: taggable_entity_id})
                                                console.log('gettaggableentityendpoint', res)
                                                const res2 = await postRequest(res.data)
                                                console.log('gettaggableentities', res2)
                                                return res2.data.map((item: any) => ({
                                                    label: (item.ppk_value || item.full_name || '') + ' (' + item.id + ')',
                                                    value: item.id,
                                                }))
                                            } catch (e) {
                                                notification.error({message: e.response.data.message})
                                            }
                                        }}
                                    />
                                );
                            }}
                        </ProFormDependency>

                        <ProFormSelect
                            name="tag_category_id"
                            label={'Category'}
                            rules={[{required: true}]}
                            fieldProps={{defaultActiveFirstOption: true}}
                            request={async () => {
                                try {
                                    const res = await postRequest('/api/tags/getcategories')
                                    // console.log('getcategories', res)
                                    return res.data.map((item: DataSourceTypeTagCategory) => ({
                                        label: item.category_name,
                                        value: item.id
                                    }))
                                } catch (e) {
                                    notification.error({message: e.response.data.message})
                                }
                            }}

                        />

                        <ProFormDependency name={['tag_category_id']}>
                            {({tag_category_id}) => {
                                // console.log('category_id', category_id)
                                if (!tag_category_id) return null
                                return (
                                    <ProFormSelect
                                        dependencies={['tag_category_id']}
                                        name="tag_category_value_id"
                                        label={'Taggable Entity (Actual)'}
                                        fieldProps={{defaultActiveFirstOption: true}}
                                        rules={[{required: true}]}
                                        request={async () => {
                                            try {
                                                const res = await postRequest('/api/tags/getvaluesforcategory', {id: tag_category_id})
                                                console.log('gettaggableentities', res)
                                                return res.data.map((item: DataSourceTypeTagCategoryValues) => ({
                                                    label: item.value + ' (' + item.id + ')',
                                                    value: item.id,
                                                }))
                                            } catch (e) {
                                                notification.error({message: e.response.data.message})
                                            }
                                        }}
                                    />
                                );
                            }}
                        </ProFormDependency>


                    </ProForm>


                </ConfigProvider>

                <br/>
                <br/>

                <Typography.Title level={4}>Taggations</Typography.Title>
                <br/>

                <ConfigProvider locale={enUSIntl}>
                    <EditableProTable<DataSourceTypeTaggation>
                        request={async (params, sort, filter) => {
                            const res = await postRequest('/api/tags/gettaggations')
                            console.log('gettaggations', res)
                            return {data: res.data, success: true, total: res.data.length}
                        }}

                        actionRef={this.state.refTaggation as any}
                        columns={this.columnsTaggation}
                        rowKey="id"
                        recordCreatorProps={false}
                        pagination={{pageSize: 6, hideOnSinglePage: true}}
                        editable={{
                            // editableKeys:[],
                            actionRender: (row, config, defaultDoms) => {
                                return [defaultDoms.save, defaultDoms.delete || defaultDoms.cancel];
                            },
                            deletePopconfirmMessage: 'Delete this line?',
                            onlyOneLineEditorAlertMessage: 'Only one line can be edited at the same time',
                            onlyAddOneLineAlertMessage: 'Only add one line'
                        }}
                        bordered
                    />

                </ConfigProvider>

                {/*</MainLayout>*/}
            </>
        );
    }


}

export default AdminTags;