import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {PageContainer, ProList} from "@ant-design/pro-components";
import {
    Avatar,
    Badge,
    Button,
    Col,
    Divider,
    notification,
    Radio,
    Row,
    Slider,
    Space,
    Tag,
    Tooltip,
    Typography
} from "antd";
import axiosApiInstance from "../../../api/axiosClient";
import Icon, {
    EyeInvisibleOutlined,
    EyeOutlined,
    InstagramOutlined,
    MonitorOutlined,
    PlusCircleOutlined,
    TeamOutlined,
    YoutubeFilled
} from "@ant-design/icons";
import ProCard, {CheckCard} from "@ant-design/pro-card";
import CheckableTag from "antd/es/tag/CheckableTag";
import {ReactComponent as TikTokSvg} from '../../../assets/tiktok.svg';
import {ReactComponent as TikTokSquare} from '../../../assets/tiktok_square.svg';
import './CreatorsLeadsSearch.css';
import Search from "antd/es/input/Search";
import {ActionType} from "@ant-design/pro-table";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import followersCountFormatter from "../../../api/followersFormatter";
import {avatarColorizer} from "../../../api/avatarColorizer";
import {ReactComponent as LogoCompactTrans} from '../../../assets/dm_logo_compact_trans.svg';
import {
    AVATAR_SIZE,
    BADGE_FONT_SIZE,
    DM_COLOR,
    IG_COLOR,
    MAX_SHOWN_TAGS,
    YT_COLOR,
    YT_TOPIC_COLOR
} from "../../../common/constants";

type Props = {};



type SearchCriteria = {
    platform: string[],
    keyword: string,
    min_followers: number,
    // max_followers_count?: number,
    category: string[],
    youtube_topic: string[],
    youtube_video_category: string[],
    instagram_category: string[],
    linked_with_dm: string,
    include_hidden: string,
};

type SearchOptions = {
    youtube_topics: API.CreatorsLeadsSearch.PotentialLeadYoutubeTopic[],
    youtube_video_categories: API.CreatorsLeadsSearch.YoutubeVideoCategory[],
    instagram_categories: string[],
    linked_with_dm: string[],
};
type ResponseLeadsBySearch = {
    youtube_accounts: API.CreatorsLeadsSearch.PotentialLeadYoutubeAccount[];
    instagram_accounts: API.CreatorsLeadsSearch.PotentialLeadInstagramAccount[];
    tiktok_accounts: API.CreatorsLeadsSearch.PotentialLeadTiktokAccount[];
};

const YES = 'yes'
const NOW = 'now'
const PAST = 'past'
const NEVER = 'never'
const NO = 'no'
const NOT_SET = 'not_set'
const HIDDEN_ONLY = 'hidden_only'
const CreatorsLeadsSearch = (props: Props) => {

        // a state variable object for the search criteria (selected)
        const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
            platform: [],
            keyword: '',
            min_followers: 0,
            // max_followers_count: 0,
            category: [],
            //    new
            youtube_topic: [],
            youtube_video_category: [],
            instagram_category: [],
            linked_with_dm: NOT_SET,
            include_hidden: NO,
        });


        // a useeffect hook to get the categories from the backend
        const [availableCategories, setAvailableCategories] = React.useState([]);
        const [visibleCategories, setVisibleCategories] = React.useState([]);
        const [expanded, setExpanded] = useState(false);
        // use an action ref from pro list to trigger the search
        const actionRef = useRef<ActionType>();
        const [searchParams, setSearchParams] = useSearchParams();
        const [searchOptions, setSearchOptions] = useState<SearchOptions | undefined>({
            youtube_topics: [],
            youtube_video_categories: [],
            instagram_categories: [],
            linked_with_dm: [NOW, PAST, NEVER, NOT_SET],
        });


        useEffect(() => {
                axiosApiInstance.get('/api/leads/potential/search/categories')
                    .then((res) => {
                        // console.log(res.data);
                        // let allCategories = ['all', ...res.data];
                        let allCategories = res.data;
                        setAvailableCategories(allCategories);
                        setVisibleCategories(allCategories.slice(0, MAX_SHOWN_TAGS));
                    })
                    .catch((err) => {
                        console.log(err);
                    })

                axiosApiInstance.get('/api/leads/potential/search/options')
                    .then((res) => {
                        console.log(res.data);
                        setSearchOptions(res.data as SearchOptions || undefined);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
            , []);


        // handleCategoriesChange
        // const handleCategoriesChange = (tag, checked) => {
        //     // console.log(tag, checked);
        //     const {category} = searchCriteria;
        //     let nextSelectedTags = checked
        //         ? [...category, tag]
        //         : category.filter(t => t !== tag);
        //     // console.log(nextSelectedTags);
        //     // select all the tags when the 'all' tag is selected
        //     if (nextSelectedTags.includes('all')) {
        //         nextSelectedTags = ['all', ...availableCategories];
        //     }
        //     // deselect all the tags when the 'all' tag is deselected
        //     if (tag === 'all' && checked === false) {
        //         nextSelectedTags = [];
        //     }
        //     setSearchCriteria({...searchCriteria, category: nextSelectedTags});
        // }

        const handleYoutubeTopicChange = (tag, checked) => {
            // simple change handler for youtube topic
            const {youtube_topic} = searchCriteria;
            let nextSelectedTags = checked
                ? [...youtube_topic, tag]
                : youtube_topic.filter(t => t !== tag);
            setSearchCriteria({...searchCriteria, youtube_topic: nextSelectedTags});
        }

        const handleYoutubeVideoCategoryChange = (tag, checked) => {
            // simple change handler for youtube video category
            const {youtube_video_category} = searchCriteria;
            let nextSelectedTags = checked
                ? [...youtube_video_category, tag]
                : youtube_video_category.filter(t => t !== tag);
            setSearchCriteria({...searchCriteria, youtube_video_category: nextSelectedTags});
        }

        const handleInstagramCategoryChange = (tag, checked) => {
            // simple change handler for instagram category
            const {instagram_category} = searchCriteria;
            let nextSelectedTags = checked
                ? [...instagram_category, tag]
                : instagram_category.filter(t => t !== tag);
            setSearchCriteria({...searchCriteria, instagram_category: nextSelectedTags});
        }

        // const toggleExpand = () => {
        //     if (!expanded) {
        //         setVisibleCategories(availableCategories); // show all tags if expanded
        //     } else {
        //         setVisibleCategories(availableCategories.slice(0, MAX_SHOWN_TAGS)); // show only first 4 tags if not expanded
        //     }
        //     setExpanded(!expanded);
        // };

        const toggleHidden = (data: {
            platform: string
            influencer_handle: string
            is_hidden: boolean
        }) => {
            axiosApiInstance.post('/api/leads/potential/search/toggle_hidden', data)
                .then((res) => {
                    notification.success({message: 'Success'});
                    actionRef.current?.reload();
                })
                .catch((err) => {
                    console.log(err);
                })
        }

        // use effect hook to get the search results from the backend whenever the search criteria changes
        useEffect(() => {
            // console.log(searchCriteria);
            actionRef.current?.reload();
        }, [searchCriteria]);


        useEffect(() => {
            // when the search criteria changes, update the search params
            //     serialize the search criteria object into a query string
            console.log(searchCriteria)
            let urlSearchParams = new URLSearchParams();
            for (let key in searchCriteria) {
                // for each key, check the type of the value and convert it to string accordingly
                // if (Array.isArray(searchCriteria[key])) {
                //
                // }
                urlSearchParams.append(key, searchCriteria[key].toString());
            }
            console.log(urlSearchParams.toString());
            setSearchParams(urlSearchParams);
            // console.log(['adsah  fds','sdfsd  f'].toString())
            // console.log(Number(345).toString())

        }, [searchCriteria]);


        useEffect(() => {
            console.log(searchParams.toString());
            if (searchParams.toString() === '') {
                return;
            }

            // deserialize the search params into an object of the same structure as the search criteria
            let newSearchCriteria: SearchCriteria = {
                platform: [],
                keyword: '',
                min_followers: 0,
                // max_followers_count: 0,
                category: [],
                youtube_topic: [],
                youtube_video_category: [],
                instagram_category: [],
                linked_with_dm: NOT_SET,
                include_hidden: NO,
            };
            // iterate over the searchParams.entries() and convert them to the search criteria object
            searchParams.forEach((value, key) => {
                    // console.log(key, value)
                    if (value == '') {
                        return;
                    }
                    // if the key is platform or category, convert the value to an array
                    if (key === 'platform' || key === 'category' || key === 'instagram_category' || key === 'youtube_topic' || key === 'youtube_video_category') {
                        newSearchCriteria[key] = value.split(',');
                    } else if (key === 'min_followers') {
                        newSearchCriteria[key] = Number(value);
                    } else {
                        newSearchCriteria[key] = value;
                    }
                }
            );
            console.log(searchCriteria);
            setSearchCriteria(newSearchCriteria);
        }, []);


        const navigate = useNavigate()
        const location = useLocation();

        return (
            // <ModalForm
            //     // title="Search for Leads"
            //     // trigger={
            //     //     <Button type="primary" icon={<SearchOutlined/>}
            //     //             onClick={() => navigate('search', {state: {background: location}})}
            //     //     >
            //     //         Search for Leads
            //     //     </Button>
            //     // }
            //     open
            //     submitter={false}
            //     modalProps={{
            //         width: '80vw',
            //         bodyStyle: {
            //             height: '88vh',
            //             overflowY: 'auto',
            //             overflowX: 'hidden',
            //         },
            //         style: {
            //             top: 32,
            //         },
            //         onCancel: () => navigate('/crm/leads')
            //     }}
            //     className={"CreatorsLeadsSearch"}
            // >
            <PageContainer
                className={"CreatorsLeadsSearch"}
            >
                <ProCard
                    bordered
                    // bodyStyle
                    //     style={{width: '100%'}}
                >

                    {/*// a space for the search criteria fields*/}
                    {/*// category, keyword, min followers_count, max followers_count, platform*/}
                    {/*// category is checkable tags*/}
                    <Row
                        // style={{padding:0}}
                        // style={{width: '100%'}}
                    >
                        <Space direction={"vertical"}
                               size={0}
                               style={{width: '100%'}}
                        >

                            <Row justify={"space-between"}
                                 style={{width: '100%'}}
                            >
                                <Space style={{marginRight: 8,}}>

                                    <Typography.Text
                                        style={{fontSize: 12}}
                                    >Platform: </Typography.Text>

                                    <Space style={{display: 'flex'}}>
                                        <CheckCard.Group size={"small"} multiple
                                                         value={searchCriteria.platform}
                                            // on check, set the platform in the search criteria
                                                         onChange={(value) => {
                                                             // console.log(value)
                                                             setSearchCriteria({
                                                                 ...searchCriteria,
                                                                 platform: value as string[]
                                                             });
                                                         }}
                                        >
                                            <CheckCard value={"youtube"}
                                                       title={'YouTube'}
                                                       avatar={<YoutubeFilled style={{color: YT_COLOR}}/>}
                                            />
                                            <CheckCard value={"instagram"}
                                                       title={'Instagram'}
                                                       avatar={<InstagramOutlined
                                                           style={{color: IG_COLOR}}
                                                       />}
                                            />
                                            <CheckCard value={"tiktok"}
                                                       title={'TikTok'}
                                                // avatar={<Icon component={<TikTokSvg fill={'ff0050'} />} />}
                                                       avatar={<Icon component={TikTokSvg}
                                                           // style={{fill:'ff0050'}}
                                                       />}
                                                // style={{color:'#ff0050'}}
                                            />
                                        </CheckCard.Group>
                                    </Space>
                                </Space>

                                <Space style={{marginRight: 8,}}>

                                    <Typography.Text
                                        style={{fontSize: 12}}
                                    >Linked with DM: </Typography.Text>

                                    <Radio.Group
                                        // size={"small"}
                                        value={searchCriteria.linked_with_dm}
                                        options={[
                                            {label: 'Yes, now', value: NOW},
                                            {label: 'Yes, in the past', value: PAST},
                                            {label: 'Never', value: NEVER},
                                            {label: 'Unset', value: NOT_SET},
                                        ]}
                                        optionType={"button"}
                                        onChange={(e) => {
                                            setSearchCriteria({
                                                ...searchCriteria,
                                                linked_with_dm: e.target.value
                                            });
                                        }}
                                    />

                                </Space>

                                <Space style={{marginRight: 8,}}>

                                    <Typography.Text
                                        style={{fontSize: 12}}
                                    >Include hidden: </Typography.Text>

                                    <Radio.Group
                                        // size={"small"}
                                        value={searchCriteria.include_hidden}
                                        options={[
                                            {label: 'Yes', value: YES},
                                            {label: 'No', value: NO},
                                            {label: 'Hidden Only', value: HIDDEN_ONLY},
                                        ]}
                                        optionType={"button"}
                                        onChange={(e) => {
                                            setSearchCriteria({
                                                ...searchCriteria,
                                                include_hidden: e.target.value
                                            });
                                        }}
                                    />

                                </Space>


                                <Space>
                                    <Search
                                        placeholder="Search for a creator"
                                        allowClear
                                        // enterButton="Search"
                                        value={searchCriteria.keyword}
                                        enterButton
                                        size="large"
                                        // onSearch={onSearch}
                                        // on search, set the keyword in the search criteria
                                        // onSearch={(value) => {
                                        //     setSearchCriteria({...searchCriteria, keyword: value});
                                        // }}
                                        onChange={(e) => {
                                            setSearchCriteria({...searchCriteria, keyword: e.target.value});
                                        }}
                                    />
                                </Space>
                            </Row>


                            <Divider/>

                            {/*<Space size={[0, 8]} wrap style={{marginBottom: 12}}>*/}
                            {/*    <Space style={{marginRight: 8,}}>*/}
                            {/*        <Typography.Text*/}
                            {/*            style={{fontSize: 12}}*/}
                            {/*        >Category: </Typography.Text>*/}
                            {/*    </Space>*/}
                            {/*    <CheckableTag*/}
                            {/*        key={'all'}*/}
                            {/*        // checked={selectedTags.includes(tag)}*/}
                            {/*        checked={searchCriteria.category.includes('all')}*/}
                            {/*        onChange={(checked) => handleCategoriesChange('all', checked)}*/}
                            {/*    >All</CheckableTag>*/}
                            {/*    {visibleCategories.map((tag) => (*/}
                            {/*        <CheckableTag*/}
                            {/*            key={tag}*/}
                            {/*            // checked={selectedTags.includes(tag)}*/}
                            {/*            checked={searchCriteria.category.includes(tag)}*/}
                            {/*            onChange={(checked) => handleCategoriesChange(tag, checked)}*/}
                            {/*        >*/}
                            {/*            {tag}*/}
                            {/*        </CheckableTag>*/}
                            {/*    ))}*/}

                            {/*    {availableCategories.length > MAX_SHOWN_TAGS && (*/}
                            {/*        <Button*/}
                            {/*            // style={{border: 'none', paddingLeft: 0, paddingRight: 0}}*/}
                            {/*            type={'link'}*/}
                            {/*            onClick={toggleExpand}*/}
                            {/*        >*/}
                            {/*            {expanded ? <>Hide <UpOutlined/></> : <>Show {availableCategories.length - MAX_SHOWN_TAGS} more <DownOutlined/></>}*/}
                            {/*        </Button>*/}
                            {/*    )}*/}

                            {/*</Space>*/}


                            {/*<Divider/>*/}

                            {/*<Typography.Paragraph ellipsis={{rows: 2, expandable: true, symbol: 'more'}}>*/}

                            <Space size={[0, 8]} wrap style={{marginBottom: 12}}>
                                <Space style={{marginRight: 8,}}>
                                    <Badge color={YT_TOPIC_COLOR} text={
                                        <Typography.Text
                                            style={{fontSize: 12}}
                                        >
                                            Youtube Topic: </Typography.Text>
                                    }/>
                                </Space>
                                {searchOptions?.youtube_topics?.map((tag) => (
                                    <CheckableTag
                                        key={tag.wikipedia_url}
                                        checked={searchCriteria.youtube_topic.includes(tag.wikipedia_url)}
                                        onChange={(checked) => handleYoutubeTopicChange(tag.wikipedia_url, checked)}
                                        className={'yt-topic-tag'}
                                    >
                                        {tag.name}
                                    </CheckableTag>
                                ))}

                            </Space>
                            {/*</Typography.Paragraph>*/}

                            <Divider/>

                            <Space size={[0, 8]} wrap style={{marginBottom: 12}}>
                                <Space style={{marginRight: 8,}}>
                                    <Badge color={YT_COLOR} text={<Typography.Text
                                        style={{fontSize: 12}}
                                    >
                                        Primary Youtube Video Category: </Typography.Text>}/>
                                </Space>
                                {searchOptions?.youtube_video_categories?.map((tag) => (
                                    <CheckableTag
                                        key={tag.id}
                                        checked={searchCriteria.youtube_video_category.includes(tag.id)}
                                        onChange={(checked) => handleYoutubeVideoCategoryChange(tag.id, checked)}
                                        className={'yt-video-category-tag'}
                                    >
                                        {tag.name}
                                    </CheckableTag>
                                ))}
                            </Space>

                            <Divider/>

                            <Space size={[0, 8]} wrap style={{marginBottom: 12}}>
                                <Space style={{marginRight: 8,}}>
                                    <Badge color={IG_COLOR} text={<Typography.Text
                                        style={{fontSize: 12}}
                                    >
                                        Instagram Category: </Typography.Text>}/>
                                </Space>
                                {searchOptions?.instagram_categories?.map((tag) => (
                                    <CheckableTag
                                        key={tag}
                                        checked={searchCriteria.instagram_category.includes(tag)}
                                        onChange={(checked) => handleInstagramCategoryChange(tag, checked)}
                                        className={'ig-category-tag'}
                                    >
                                        {tag}
                                    </CheckableTag>
                                ))}
                            </Space>

                            <Divider/>

                            <Row align={"middle"}>
                                {/*<Space size={[0, 8]} wrap style={{marginBottom: 12, width: '100%'}}>*/}
                                {/*<Col span={2} lg={{span: 2}} md={{span: 14}} >*/}
                                <Space style={{marginRight: 16,}}>
                                    <Typography.Text
                                        style={{fontSize: 12,}}
                                    >Min Followers: </Typography.Text>
                                </Space>
                                {/*</Col>*/}
                                {/*slider for min/max followers count*/}
                                {/*<Space size={[0, 8]} wrap style={{marginBottom: 12, width: '100%'}}>*/}
                                <Col span={12} xl={{span: 12}} md={{span: 20}}>
                                    <Slider
                                        // style={{width: '100%'}}
                                        // style={{width: 600}}
                                        // range
                                        // defaultValue={[20, 50]}
                                        min={0}
                                        max={1000000}
                                        // on change, set the min and max followers count in the search criteria
                                        onChange={(value) => {
                                            setSearchCriteria({
                                                ...searchCriteria,
                                                min_followers: value,
                                                // min_followers_count: value[0],
                                                // max_followers_count: value[1]
                                            });
                                        }}
                                        // value={[searchCriteria.min_followers_count, searchCriteria.max_followers_count]}
                                        value={searchCriteria.min_followers}
                                        marks={{
                                            0: '0',
                                            // 1000: '1K',
                                            // 10000: '10K',
                                            100000: '100K',
                                            250000: '250K',
                                            500000: '500K',
                                            750000: '750K',
                                            1000000: '1M',
                                            // 10000000: '10M+',
                                        }}

                                    />
                                </Col>
                                {/*<Col span={4}>*/}
                                {/*    <InputNumber*/}
                                {/*        // min={1}*/}
                                {/*        // max={20}*/}
                                {/*        value={searchCriteria.min_followers || 0}*/}
                                {/*        // onChange={(value) => {*/}
                                {/*        //     setSearchCriteria({*/}
                                {/*        //         ...searchCriteria,*/}
                                {/*        //         min_followers_count: value,*/}
                                {/*        //     });*/}
                                {/*        // }}*/}
                                {/*        style={{margin: '0 16px'}}*/}
                                {/*        // value={inputValue}*/}
                                {/*        // onChange set the min followers count in the search criteria*/}
                                {/*        onPressEnter={(value) => {*/}
                                {/*            setSearchCriteria({*/}
                                {/*                ...searchCriteria,*/}
                                {/*                min_followers: +value.currentTarget.value,*/}
                                {/*            });*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</Col>*/}
                                {/*</Space>*/}
                            </Row>
                        </Space>
                    </Row>

                </ProCard>

                <br/>

                {/*<ProCard*/}
                {/*    bordered*/}
                {/*    bodyStyle={{*/}
                {/*        // padding: '8px 0px 0px 0px',*/}
                {/*        padding: 0,*/}
                {/*        paddingInline: 0,*/}
                {/*    }}*/}
                {/*>*/}
                <ProList<
                        { platform: string; } &
                    API.CreatorsLeadsSearch.PotentialLeadYoutubeAccount &
                    API.CreatorsLeadsSearch.PotentialLeadInstagramAccount &
                    API.CreatorsLeadsSearch.PotentialLeadTiktokAccount
                    >
                    actionRef={actionRef}
                    className={'search-results'}
                    grid={{
                        gutter: 16, column: 3,
                        // sm: 1, lg: 3
                    }}
                    itemCardProps={{
                        bodyStyle: {
                            padding: 8,
                        },
                    }}
                    // style={{width: '100%'}}
                    // on card click, navigate to the influencer's profile
                    // onItem={(record: any) => {
                    //     return {
                    //         onClick: () => {
                    //             // console.log(record);
                    //             if (record.platform === 'instagram') {
                    //                 navigate(`instagram/${record.influencer_handle}/`);
                    //             } else if (record.platform === 'youtube') {
                    //                 // navigate(`crm/leads/search/youtube/${item.influencer_handle}/`);
                    //             } else if (record.platform === 'tiktok') {
                    //                 // navigate(`crm/leads/search/tiktok/${item.influencer_handle}/`);
                    //             }
                    //         },
                    //     };
                    // }}
                    columns={[
                        {
                            title: 'Platform',
                            dataIndex: 'platform',
                            valueType: 'text',
                        },
                        {
                            title: 'Influencer Handle',
                            dataIndex: 'influencer_handle',
                            valueType: 'text',
                        },
                        {
                            title: 'Influencer Name',
                            dataIndex: 'influencer_name',
                            valueType: 'text',
                        },
                        {
                            title: 'Image URL',
                            dataIndex: 'image_url',
                            valueType: 'text',
                        },
                        {
                            title: 'Followers Count',
                            dataIndex: 'followers_count',
                            valueType: 'text',
                            // sorter: (a, b) => a.followers_count - b.followers_count,
                        }
                    ]}
                    request={async (params = {}, sort, filter) => {
                        console.log('FETCH')
                        const res = await axiosApiInstance.get('/api/leads/potential/search/', {params: searchCriteria});
                        // console.log(res.data);
                        let data = (res.data || []) as ResponseLeadsBySearch;
                        console.log(data)

                        // merge sort data.youtubeAccounts, data.instagramAccounts, data.tiktokAccounts
                        // into a single array of PotentialLeadAccount
                        // type PotentialLeadAccount = {
                        //     platform: string;
                        //     influencer_handle: string;
                        //     influencer_name: string;
                        //     image_url: string;
                        //     followers_count: number;
                        // }
                        // let potentialLeadAccounts : PotentialLeadAccount[] = [];
                        let potentialLeadAccounts: any[] = [];
                        // potentialLeadAccounts = potentialLeadAccounts.concat(data.youtubeAccounts);
                        potentialLeadAccounts = potentialLeadAccounts.concat(data.youtube_accounts.map((item: any) => ({platform: 'youtube', ...item})));
                        potentialLeadAccounts = potentialLeadAccounts.concat(data.instagram_accounts.map((item: any) => ({platform: 'instagram', ...item})));
                        potentialLeadAccounts = potentialLeadAccounts.concat(data.tiktok_accounts.map((item: any) => ({platform: 'tiktok', ...item})));

                        // sort the potentialLeadAccounts array
                        // sort by followers_count
                        potentialLeadAccounts.sort((a, b) => {
                            return b.followers_count - a.followers_count;
                        });
                        // console.log(potentialLeadAccounts);

                        return {
                            data: potentialLeadAccounts,
                            success: true,
                            total: potentialLeadAccounts.length || 0,
                        }
                    }}
                    rowKey={record => record.platform + record.influencer_handle}
                    // search={{
                    //     filterType: 'query',
                    //     // filterType: 'light',
                    //     labelWidth: 'auto',
                    //     layout: 'inline',
                    // }}
                    search={false}
                    bordered
                    // split={false}
                    metas={{
                        title: {
                            title: 'Influencer Handle',
                            dataIndex: 'influencer_handle',
                            search: false,
                            render: (text, record) => {
                                //     depending on the platform, return a link to the influencer's profile
                                return (
                                    <>
                                        <Row justify={"space-between"} align={"top"} style={{marginBottom: 16}}>

                                            <Space size={"large"}>

                                                <Badge
                                                    count={
                                                        record.platform === 'instagram' ? <InstagramOutlined
                                                            style={{color: IG_COLOR, fontSize: BADGE_FONT_SIZE}}
                                                        /> : record.platform === 'youtube' ? <YoutubeFilled
                                                            style={{color: YT_COLOR, fontSize: BADGE_FONT_SIZE}}
                                                            ///> : record.platform === 'tiktok' ? <Icon component={TikTokSvg}
                                                        /> : record.platform === 'tiktok' ?
                                                            <Icon component={TikTokSquare}
                                                                  style={{fontSize: BADGE_FONT_SIZE}}
                                                            /> : null
                                                    }
                                                >
                                                    <Avatar
                                                        // shape="square"
                                                        size="large"
                                                        src={record.image_url}
                                                        style={{
                                                            backgroundColor: 'transparent',
                                                            lineHeight: '100%',
                                                            width: AVATAR_SIZE,
                                                            height: AVATAR_SIZE,
                                                        }}
                                                        // if the image_url is not valid, return the influencer_handle initial as the avatar, with a grey background
                                                        // acts as fallback
                                                        icon={
                                                            <Avatar
                                                                // shape="square"
                                                                size="large"
                                                                style={{
                                                                    // width: AVATAR_SIZE,
                                                                    // height: AVATAR_SIZE,
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    fontSize: 20,
                                                                    // give the avatar another background color, according to the first letter of the influencer_handle
                                                                    // backgroundColor: 'red',
                                                                    backgroundColor: avatarColorizer(record.influencer_handle)
                                                                }}
                                                            >
                                                                {record.influencer_handle[0]?.toUpperCase()}
                                                            </Avatar>
                                                        }
                                                    />
                                                </Badge>

                                                <Space size={"small"} direction={"vertical"}>

                                                    <Typography.Link>
                                                        {record.platform === 'instagram' ? <a
                                                            href={`https://www.instagram.com/${record.influencer_handle}`}
                                                            target={'_blank'}
                                                        >{record.influencer_handle}</a> : record.platform === 'youtube' ?
                                                            <a
                                                                href={`https://www.youtube.com/${record.influencer_handle}`}
                                                                target={'_blank'}
                                                            >{record.influencer_name}</a> : record.platform === 'tiktok' ?
                                                                <a
                                                                    href={`https://www.tiktok.com/@${record.influencer_handle}`}
                                                                    target={'_blank'}
                                                                >{record.influencer_handle}</a> : null}
                                                    </Typography.Link>

                                                    <Typography.Text type={"secondary"} ellipsis={true}
                                                                     style={{width: 200}}>
                                                        {record.platform === 'youtube' ? record.influencer_handle :
                                                            record.influencer_name}
                                                    </Typography.Text>
                                                </Space>
                                            </Space>

                                            <Space size={"large"}>

                                                {record.is_hidden &&
                                                    <Tooltip title={"Hidden by default"}>
                                                        <EyeInvisibleOutlined
                                                            // style={{color: '#FF0000'}}
                                                        />
                                                    </Tooltip>
                                                }

                                                {record.is_linked_with_dm &&
                                                    <Tooltip title={"Currently linked with Digital Minds"}>
                                                        <LogoCompactTrans
                                                            width={34} height={26}
                                                            // fill={'#043742'}
                                                            fill={'#fff'}
                                                            style={{
                                                                border: `1px solid ${DM_COLOR}`,
                                                                borderRadius: 16,
                                                                padding: 2,
                                                                backgroundColor: DM_COLOR,

                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                                {record.was_linked_with_dm &&
                                                    <Tooltip title={"Previously linked with Digital Minds"}>
                                                        <LogoCompactTrans
                                                            width={34} height={26}
                                                            // fill={'#043742'}
                                                            fill={'#fff'}
                                                            style={{
                                                                border: '1px solid #b7001c', borderRadius: 16, padding: 2,
                                                                backgroundColor: '#b7001c'
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                            </Space>

                                        </Row>

                                        <Row justify={"space-between"} align={"bottom"}>
                                            <Space align={"center"}>
                                                <></>
                                                {record.platform === 'instagram' && record.api_category ?
                                                    <Tag color={IG_COLOR}>{record.api_category}</Tag> :
                                                    record.platform === 'youtube' ?
                                                        <>
                                                            {record.primary_video_category_id &&
                                                                <Tag
                                                                    color={YT_COLOR}>{record.primary_video_category.name}</Tag>}
                                                            {record.topics &&
                                                                // <Space>
                                                                //     {record.topics.map((topic) => (
                                                                //         <Tag color={"#FF0000"}>{topic.name}</Tag>
                                                                //     ))}
                                                                // </Space>
                                                                <Avatar.Group maxCount={3} size={"small"}>
                                                                    {record.topics.map((topic) => (
                                                                        <Tooltip title={topic.name} placement="top">
                                                                            <Avatar
                                                                                style={{
                                                                                    backgroundColor: YT_TOPIC_COLOR,
                                                                                }}
                                                                            >{topic.name[0]}</Avatar>
                                                                        </Tooltip>
                                                                    ))}
                                                                </Avatar.Group>
                                                            }
                                                        </> :
                                                        //     record.platform === 'tiktok' ?
                                                        //         <Tag color={"#000000"}>{record.api_category}</Tag> :
                                                        null}
                                            </Space>

                                            <Space align={"baseline"}>
                                                <TeamOutlined/>
                                                <Typography.Text
                                                    // strong={false}
                                                    // type={"success"}
                                                    // style={{fontWeight: 500}}
                                                >{followersCountFormatter(record.followers_count)}</Typography.Text>
                                            </Space>
                                        </Row>
                                    </>
                                )
                            }
                        },
                        actions: {
                            cardActionProps: 'actions',
                            search: false,
                            render: (text, record) => {
                                //     depending on the platform, return a link to the influencer's profile
                                return [
                                    // <a
                                    //     onClick={() => {
                                    //         navigate(`${record.platform}/${record.influencer_handle}/`);
                                    //     }}
                                    //     //{/*>View Report <LineChartOutlined/></a>,*/}
                                    // >View Report <MonitorOutlined/></a>,
                                    <Button type={"text"} icon={<MonitorOutlined/>}
                                            onClick={() => {
                                                navigate(`${record.platform}/${record.influencer_handle}/`);
                                            }}
                                        //{/*>View Report <LineChartOutlined/></a>,*/}
                                    >View Report</Button>,
                                    <Button type={"text"}
                                            icon={!record.is_hidden ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                                            onClick={() => toggleHidden({
                                                influencer_handle: record.influencer_handle,
                                                platform: record.platform,
                                                is_hidden: !record.is_hidden
                                            })}>{!record.is_hidden ? 'Hide' : 'Unhide'}</Button>,
                                    <Button type={"text"} icon={<PlusCircleOutlined/>}
                                            onClick={() => {
                                                let data: API.CreatorsLeads.CreatorLead = {
                                                    creator: record.influencer_handle,
                                                    main_platform_id: record.platform === 'instagram' ? 2 : record.platform === 'youtube' ? 1 : record.platform === 'tiktok' ? 3 : 0,
                                                    accounts: [{
                                                        key: record.platform === 'youtube' ? 'https://www.youtube.com/channel/' + record.channel_id : record.platform === 'instagram' ? 'https://www.instagram.com/' + record.influencer_handle : record.platform === 'tiktok' ? 'https://www.tiktok.com/@' + record.influencer_handle : '',
                                                        platform_id: record.platform === 'instagram' ? 2 : record.platform === 'youtube' ? 1 : record.platform === 'tiktok' ? 3 : 0,
                                                    }],
                                                    date_of_communication: new Date().toISOString(),
                                                    communication_via_id: 1,
                                                    status_id: 2,
                                                }
                                                navigate(`/crm/leads/list/new`, {
                                                    state: {data}
                                                });
                                            }}
                                    >New Lead</Button>,
                                ]

                            }
                        },
                        // avatar: {
                        //     dataIndex: 'image_url',
                        //     search: false,
                        // },
                        // avatar: {
                        //     dataIndex: 'image_url',
                        //     search: false,
                        //     render: (text, record) => {
                        //         // return the image_url as the avatar, plus a badge with the icon of the platform
                        //         return (
                        //             // <Space>
                        //             <Badge
                        //                 count={
                        //                     record.platform === 'instagram' ? <InstagramFilled
                        //                         style={{color: '#E1306C'}}
                        //                     /> : record.platform === 'youtube' ? <YoutubeFilled
                        //                         style={{color: '#FF0000'}}
                        //                         ///> : record.platform === 'tiktok' ? <Icon component={TikTokSvg}
                        //                     /> : record.platform === 'tiktok' ? <Icon component={TikTokSquare}
                        //                         // style={{fill:'ff0050'}}
                        //                     /> : null
                        //                 }
                        //                 style={{
                        //                     // width: 200,
                        //                     // backgroundColor: '#fff',
                        //                     // backgroundColor: 'white',
                        //                     // color: '#999',
                        //                     // color: '#999',
                        //                     // boxShadow: '0 0 0 1px #d9d9d9 inset'
                        //                 }}
                        //             >
                        //                 <Avatar
                        //                     // shape="square"
                        //                     size="large"
                        //                     src={record.image_url}
                        //                     style={{
                        //                         backgroundColor: 'transparent',
                        //                         lineHeight: '100%',
                        //                         // width: 220,
                        //                         // height: 220,
                        //                     }}
                        //                     // if the image_url is not valid, return the influencer_handle initial as the avatar, with a grey background
                        //                     // acts as fallback
                        //                     icon={
                        //                         <Avatar
                        //                             // shape="square"
                        //                             size="large"
                        //                         >
                        //                             {record.influencer_handle[0]?.toUpperCase()}
                        //                         </Avatar>
                        //                     }
                        //                     // onError={() => {
                        //                     //     console.log('error loading image')
                        //                     //     return true
                        //                     // }}
                        //                 />
                        //             </Badge>
                        //             // </Space>
                        //         )
                        //     },
                        // },
                        description: {
                            title: 'Influencer Name',
                            dataIndex: 'influencer_name',
                            search: false,
                        },
                        // content: {
                        //     render: (_, record) => (    // render the followers count, and the platform
                        //         // <div>
                        //         //     <p>Followers Count: {record.followers_count}</p>
                        //         //     {/*<p>Platform: {record.platform}</p>*/}
                        //         // </div>
                        //         <Space>
                        //             <TeamOutlined/>
                        //             <Typography.Text>{followersCountFormatter(record.followers_count)}</Typography.Text>
                        //         </Space>
                        //     ),
                        //     search: false,
                        // },
                        platform: {
                            title: 'Platform',
                            valueType: 'select',
                            valueEnum: {
                                'instagram': 'Instagram',
                                'youtube': 'YouTube',
                                'tiktok': 'TikTok',
                            },
                            fieldProps: {
                                placeholder: 'Select a platform',
                                mode: 'multiple',
                            }
                        },
                        // followers: {
                        //     title: 'Followers Count',
                        //     valueType: 'slider',
                        //     fieldProps: {
                        //         min: 0,
                        //         max: 10000000,
                        //         range: true,
                        //         defaultValue: [0, 10000000],
                        //         marks: {
                        //             // 0: '0',
                        //             1000: '1K',
                        //             10000: '10K',
                        //             100000: '100K',
                        //             1000000: '1M',
                        //             10000000: '10M',
                        //         }
                        //     },
                        // },
                        min_followers: {
                            title: 'Min. Followers Count',
                            valueType: 'digit',
                            // valueType: 'slider',
                            // fieldProps: {
                            //     min: 0,
                            //     max: 100000000,
                            //     // marks: {
                            //     //     1000: '1K',
                            //     //     10000: '10K',
                            //     //     100000: '100K',
                            //     //     1000000: '1M',
                            //     //     10000000: '10M',
                            //     // },
                            //     tipFormatter: value => value === 0 ? '0' : value >= 1000000 ? `${value / 1000000}M` : `${value / 1000}K`,
                            // },
                            // search: {
                            //     width: 'lg',
                            // }
                        }

                    }}
                    options={{
                        // search: {
                        //     allowClear: true,
                        // },
                        // fullScreen: true,
                        fullScreen: false,
                        reload: false,
                        density: false,
                        setting: false,
                    }}
                    pagination={{
                        pageSize: 9,
                    }}

                />
                {/*</ProCard>*/}

                {/*</ModalForm>*/}
            </PageContainer>
        )
    }
;

export default CreatorsLeadsSearch;