import React from 'react';
import { Row, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const ChannelPlaceholder = () => {
  return (
    <div className='channel_select_placeholder'>
      <Row align='middle'>
        <div
          className='profile_image_placeholder'
          style={{ width: 48, height: 48, borderRadius: 24, backgroundColor: '#CCC', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <UserOutlined style={{ fontSize: 28, color: '#FFF', alignSelf: 'center' }} />
        </div>
        <Typography.Text italic type='secondary' style={{ marginLeft: 12, color: '#CCC' }}>
          Selected Channel
        </Typography.Text>
      </Row>
    </div>
  );
};

export default ChannelPlaceholder;
