import {Alert} from 'antd';
import React, {Component} from 'react';
import FileManagerComponent from '../../../ERP/components/FileManagerComponent';
import './index.css';
import {PageContainer} from "@ant-design/pro-components";

export interface IFileManagerProps {
    //Here we pass the Props Interface
}

export interface IFileManagerState {
    //here we pass the State Interface
}

//class ComponentName Component<PropsInterface, StateInterface>
class FileManager extends Component<IFileManagerProps, IFileManagerState> {

    constructor(props: IFileManagerProps) {
        super(props)

        this.state = {}
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }

    render() {
        return (
            <div className="FileManager" style={this.style}>
                <>
                    <PageContainer>

                        <Alert message='View the file structure inside the ERP file vault.' showIcon/>
                        {/* <br /> */}
                        <Alert message='Right click onto a folder in order to upload files in it.' type='success'
                               closable/>
                        {/* <br /> */}
                        <Alert message='Right click on a file in order to delete it.' type='error' closable/>
                        <br/>
                        <div style={{border: '0.1px solid lightgrey', padding: 8}}>

                            <FileManagerComponent/>
                        </div>


                    </PageContainer>
                </>
            </div>
        );
    }
}

export default FileManager;