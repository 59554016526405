import {InstagramOutlined, TikTokOutlined, YoutubeFilled} from "@ant-design/icons";
import {IG_COLOR, TT_COLOR, YT_COLOR} from "../../../common/constants";
import * as React from "react";
import {Space, Typography} from "antd";
import {generate} from "@ant-design/colors";

export const toEUR = (c: number) => `€${c.toLocaleString()}`;
export const YT_ICON = <YoutubeFilled style={{fontSize: 22, color: YT_COLOR}}/>;
export const IG_ICON = <InstagramOutlined style={{fontSize: 22, color: IG_COLOR}}/>;
export const TT_ICON = <TikTokOutlined style={{fontSize: 22, color: TT_COLOR}}/>;
const PricingTitleComponent = (icon: JSX.Element) => (title: string) =>
    (
        <Space size={5} align='baseline'>
            {icon}
            <Typography.Text>{title}</Typography.Text>
        </Space>
    );
export const TitleYT = PricingTitleComponent(YT_ICON);
export const TitleTT = PricingTitleComponent(TT_ICON);
export const TitleIG = PricingTitleComponent(IG_ICON);

export function formatFollowers(num) {
    if (num < 1000) {
        return num.toString();
    }

    const units = ["K", "M", "B", "T"];
    const order = Math.floor(Math.log10(num) / 3);
    const unitname = units[order - 1];

    const scaledNum = num / Math.pow(1000, order);
    const roundedNum = Math.round(scaledNum * 10) / 10;

    return roundedNum % 1 === 0 ? `${roundedNum.toFixed(0)}${unitname}` : `${roundedNum.toFixed(1)}${unitname}`;
}

export function formatSocialMediaAccountName(url) {
    const urlObj = new URL(url);
    const pathParts = urlObj.pathname.split('/').filter(part => part !== '');

    switch (urlObj.hostname) {
        case 'www.youtube.com':
            if (pathParts[0] === 'channel') {
                // Case "https://www.youtube.com/channel/UCsTsMV466UGUkkT50XUxF6g"
                return pathParts[1];
            } else if (pathParts[0] === 'c') {
                // Case "https://www.youtube.com/c/kaitoyli_saltedcaramelasmr"
                // Case "https://www.youtube.com/c/KDexplorer10"
                // Case "https://www.youtube.com/c/Sotospapp"
                return `@${pathParts[1]}`;
            } else {
                if (pathParts[0].includes("@")) {
                    // Case "https://www.youtube.com/@Nikolasplytas"
                    return pathParts[0];
                } else {
                    // Case
                    return `@${pathParts[0]}`;
                }
            }
        case 'www.instagram.com':
            if (pathParts[0].includes("@")) {
                // Case ""
                return pathParts[0];
            } else {
                // Case "https://www.instagram.com/katerina___vlachou/"
                // No 157
                return `@${pathParts[0]}`;
            }
        case 'www.tiktok.com':
            if (pathParts[0].includes("@")) {
                // Case "https://www.tiktok.com/@annitaspithaki7"
                return pathParts[0];
            } else {
                // Case ""
                return `@${pathParts[0]}`;
            }
        default:
            throw new Error("Unsupported social media URL");
    }
}


// Function to convert hex to RGB
function hexToRGB(hex: string) {
    // Remove the leading # if present
    hex = hex.replace(/^#/, '');

    // Parse the hex color to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    return {r, g, b}
}

function perceivedBrightness(r, g, b) {
    // Using the luminance formula from WCAG 2.0
    return 0.299 * r + 0.587 * g + 0.114 * b;
}

// Function to determine brightness and return appropriate color
export function getPaletteColor(primary_color: string, palette: string[]) {
    const {r, g, b} = hexToRGB(primary_color);
    let brightness = perceivedBrightness(r, g, b);

    if (brightness > 128) {
        // Dark text
        return palette[9]
    } else if (brightness > 70) {
        // Medium brightness text
        const midPalette = generate(palette[0]);
        const appropriateMidColor = getPaletteColor(palette[9], midPalette)
        return appropriateMidColor
    } else {
        // Bright text
        return palette[-1]
    }
}