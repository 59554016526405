import * as React from 'react';
import {ProTable} from "@ant-design/pro-components";
import dayjs from "dayjs";
import {Typography} from "antd";
import {numFormatterInt, numFormatterPercent} from "./YouTubeBenchmarks";
import {useAtom} from "jotai";
import {yearlyViewsAtom} from "./atoms";

type Props = {};

export const YearView = (props: Props) => {
    const [yearlyViews, refreshYearlyViews] = useAtom(yearlyViewsAtom)

    return (
        <div>
            <ProTable<API.Benchmarks.YouTubeYearlyView>
                // actionRef={actionRefYearly}
                rowKey={'month'}
                dataSource={yearlyViews.data}
                search={false}
                toolBarRender={false}
                // options={{
                //     reload: true,
                //     fullScreen: false,
                //     setting: false,
                //     density: false,
                //     search: false,
                // }}
                columns={[
                    // {
                    //     key: 'index',
                    //     valueType: 'indexBorder',
                    //     align: 'center',
                    // },
                    {
                        title: 'Month',
                        dataIndex: 'month',
                        key: 'month',
                        render: (value: string) => dayjs(value, 'YYYY-MM').format('MMMM'),
                        sorter: (a, b) => dayjs(a.month, 'YYYY-MM').diff(dayjs(b.month, 'YYYY-MM')),
                    },
                    {
                        title: 'Total Subscribers Lifetime',
                        dataIndex: 'totalSubscribersLifetime',
                        key: 'totalSubscribersLifetime',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.totalSubscribersLifetime - b.totalSubscribersLifetime,
                    },
                    {
                        title: 'Subscribers',
                        dataIndex: 'subscribers',
                        key: 'subscribers',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.subscribers - b.subscribers,
                    },
                    {
                        title: 'Total Views Lifetime',
                        dataIndex: 'totalViewsLifetime',
                        key: 'totalViewsLifetime',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.totalViewsLifetime - b.totalViewsLifetime,
                    },
                    {
                        title: 'Views',
                        dataIndex: 'views',
                        key: 'views',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.views - b.views,
                    },
                    {
                        title: 'Organic Views',
                        dataIndex: 'organicViews',
                        key: 'organicViews',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.organicViews - +b.organicViews,
                    },
                    {
                        title: 'Organic Views Percentage',
                        dataIndex: 'organicViewsPercentage',
                        key: 'organicViewsPercentage',
                        render: (value) => numFormatterPercent.format(+value),
                        sorter: (a, b) => a.organicViewsPercentage - b.organicViewsPercentage,
                    },
                    {
                        title: 'Total Watch Time Hours Lifetime',
                        dataIndex: 'totalWatchTimeHoursLifetime',
                        key: 'totalWatchTimeHoursLifetime',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.totalWatchTimeHoursLifetime - b.totalWatchTimeHoursLifetime,
                    },
                    {
                        title: 'Organic Watch Time Hours',
                        dataIndex: 'organicWatchTimeHours',
                        key: 'organicWatchTimeHours',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.organicWatchTimeHours - b.organicWatchTimeHours,
                    },
                    {
                        title: 'Organic Watch Time Percentage',
                        dataIndex: 'organicWatchTimePercentage',
                        key: 'organicWatchTimePercentage',
                        render: (value) => numFormatterPercent.format(+value),
                        sorter: (a, b) => a.organicWatchTimePercentage - b.organicWatchTimePercentage,
                    },
                    {
                        title: 'Audience Retention',
                        dataIndex: 'audienceRetention',
                        key: 'audienceRetention',
                        sorter: (a, b) => dayjs(a.audienceRetention, 'HH:mm:ss').diff(dayjs(b.audienceRetention, 'HH:mm:ss')),
                    },
                    {
                        title: 'Likes',
                        dataIndex: 'likes',
                        key: 'likes',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.likes - b.likes,
                    },
                    {
                        title: 'Dislikes',
                        dataIndex: 'dislikes',
                        key: 'dislikes',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.dislikes - b.dislikes,
                    },
                    {
                        title: 'Shares',
                        dataIndex: 'shares',
                        key: 'shares',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.shares - b.shares,
                    },
                    {
                        title: 'Comments',
                        dataIndex: 'comments',
                        key: 'comments',
                        render: (value) => numFormatterInt.format(+value),
                        sorter: (a, b) => a.comments - b.comments,
                    },
                    {
                        title: 'Likes Dislikes Ratio',
                        dataIndex: 'likesDislikesRatio',
                        key: 'likesDislikesRatio',
                        render: (value) => numFormatterPercent.format(+value),
                        sorter: (a, b) => a.likesDislikesRatio - b.likesDislikesRatio,
                    },
                ]}
                // request={async () => {
                //     const response = await axiosApiInstance.get(`/api/benchmarks/yearly`, {
                //         params: {
                //             year
                //         }
                //     });
                //     setYearlyViews(response.data);
                //     return {
                //         data: response.data.data || [],
                //         success: true,
                //         total: response.data.data?.length,
                //     };
                // }}
                pagination={false}
                cardBordered
                summary={() => {
                    return (
                        <ProTable.Summary fixed="top">
                            <ProTable.Summary.Row style={{backgroundColor: '#e6f4ff'}}>
                                <ProTable.Summary.Cell index={0}>
                                    <Typography.Text strong>Average</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={1}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.totalSubscribersLifetime)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={2}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.subscribers)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={3}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.totalViewsLifetime)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={4}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.views)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={5}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.organicViews)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={6}>
                                    <Typography.Text strong>{numFormatterPercent.format(yearlyViews.avg.organicViewsPercentage)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={7}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.totalWatchTimeHoursLifetime)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={8}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.organicWatchTimeHours)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={9}>
                                    <Typography.Text strong>{numFormatterPercent.format(yearlyViews.avg.organicWatchTimePercentage)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={10}>
                                    <Typography.Text strong>{yearlyViews.avg.audienceRetention}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={11}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.likes)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={12}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.dislikes)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={13}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.shares)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={14}>
                                    <Typography.Text strong>{numFormatterInt.format(yearlyViews.avg.comments)}</Typography.Text>
                                </ProTable.Summary.Cell>
                                <ProTable.Summary.Cell index={15}>
                                    <Typography.Text strong>{numFormatterPercent.format(yearlyViews.avg.likesDislikesRatio)}</Typography.Text>
                                </ProTable.Summary.Cell>
                            </ProTable.Summary.Row>
                        </ProTable.Summary>
                    )
                }}
                sticky={{offsetHeader: 54}}
            />
        </div>
    );
};

export default YearView;