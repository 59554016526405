import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import type {FormListFieldData, InputRef} from 'antd';
import {Button, Divider, Form, notification, Space, Tooltip} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormGroup,
    ProFormInstance,
    ProFormList,
    ProFormSelect,
    ProFormText,
    ProFormTextArea
} from "@ant-design/pro-components";
import {PageLoading} from "@ant-design/pro-layout";
import {DownloadOutlined, PlusOutlined, WarningOutlined} from "@ant-design/icons";
import axiosApiInstance from "../../../api/axiosClient";
import {GlobalStateContext} from "../../../context/GlobalContext";
import {ProFormSwitch} from "@ant-design/pro-form";
import type {DraggableData, DraggableEvent} from 'react-draggable';
import Draggable from 'react-draggable';

type Props = {
    editable: boolean
};

const CreatorLeadViewEdit = (props: Props) => {
    const [form] = Form.useForm<API.CreatorsLeads.CreatorLead>();
    const navigate = useNavigate();
    const routerParams = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const formRef = useRef<ProFormInstance>();
    const inputRef = useRef<InputRef>(null);
    // const categoriesRef = useRef<BaseSelect>(null);
    // const [categoriesData, setCategoriesData] = useState<API.CreatorsLeads.CreatorLeadContentCategory[] | undefined>([]);
    const {
        creatorsLeadsTableRef,
        creatorsLeadsCategories,
        setCreatorsLeadsCategories,
        creatorsLeadsItems,
        employees,
    } = useContext(GlobalStateContext);
    // watch for changes of managed_by_employee_id field
    const managed_by_employee_id = Form.useWatch('managed_by_employee_id', form);

    useEffect(() => {
        // console.log('creatorsLeadsItems effect', creatorsLeadsItems)
        setFormData()
    }, [creatorsLeadsItems]);

    const fetchCategoriesData = async () => {
        try {
            // setLoading(true)
            const response = await axiosApiInstance.get<API.CreatorsLeads.CreatorLeadContentCategory[]>('/api/leads/categories/')
            console.log(response.data)
            // setCategoriesData(response.data);
            setCreatorsLeadsCategories(response.data);
            // setLoading(false)
        } catch (e) {
            // console.log('catch error')
            // setCategoriesData(undefined)
            setCreatorsLeadsCategories(undefined);
            // setLoading(false)
        }
    };


    const addItem = async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        const category = form.getFieldValue(['new_category'])
        form.resetFields(['new_category'])
        await axiosApiInstance.post<API.CreatorsLeads.CreatorLeadContentCategory[]>(`/api/leads/categories/`, {name: category})
        fetchCategoriesData()
    };

    // console.log('props', props.lead)


    const updateAccountFollowerCount = async (field: FormListFieldData) => {
        // console.log(field)
        let acc = form.getFieldValue(['accounts', field.name]);
        // console.log(acc)
        setLoading(true)
        await axiosApiInstance.post(`/api/leads/accounts/${acc.id}/update_count`)
        await creatorsLeadsTableRef.current.reload()
        setLoading(false)
        // form.setFieldsValue(props.lead)
        // setFormData()

    }

    const setFormData = () => {
        // console.log('set', creatorsLeadsItems, props.lead)
        const creatorLead = creatorsLeadsItems?.find(value => value.id == +routerParams.id);
        form.setFieldsValue(creatorLead)
    }

    const [bounds, setBounds] = useState({left: 0, top: 0, bottom: 0, right: 0});
    const draggleRef = useRef<HTMLDivElement>(null);
    const [disabled, setDisabled] = useState(true);

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const {clientWidth, clientHeight} = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    return (
        <ModalForm<API.CreatorsLeads.CreatorLead>
            open
            form={form}
            formRef={formRef}
            autoFocusFirstInput
            // width={"100%"}
            width={props.editable ? 1000 : undefined}
            submitter={props.editable ? {} : false}
            // onVisibleChange={()=>console.log(props.lead)}
            onVisibleChange={(visible) => {
                // console.log(props.lead)
                if (visible)
                    setFormData()
            }}
            modalProps={{
                destroyOnClose: true,
                okText: 'Save',
                okButtonProps: {hidden: !props.editable, loading: loading},
                // confirmLoading:loading,
                onCancel: () => navigate(`/crm/leads/list`),
                // bodyStyle: {height: props.editable ? "calc(100vh - 300px)" : "calc(100vh - 250px)", overflowY: 'auto'},
                // style: {top: 70},
                modalRender: (modal) => (
                    <Draggable
                        // disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )
            }}
            onFinish={async formData => {
                // console.log(formData)
                // return
                return axiosApiInstance.put(`/api/leads/items/${routerParams.id}`, formData)
                    .then(() => {
                        // fetchDeliverableData();
                        // executionDeliverablesListRef?.current?.reload()
                        notification.success({message: 'Success'})
                        creatorsLeadsTableRef?.current?.reload()
                        return true
                    }).catch(() => {
                        notification.error({message: 'Error'})
                        return false
                    })
                // .then(()=>form.resetFields())
            }}
            validateMessages={{required: "${label} is required!"}}
            // request={() => {
            //     return axiosApiInstance.get<API.Deliverable>(`/api/deliverables/${routerParams.deliverableId}`)
            //         .then(value => {
            //             // console.log(value.data)
            //             return value.data
            //         })
            //     // return Promise.resolve({})
            // }}
            // initialValues={deliverableData}
            readonly={!props.editable}
            requiredMark={props.editable}
            title={
                <div
                    style={{
                        width: '100%',
                        height: 12,
                        cursor: 'move',
                        // backgroundColor: '#d5d5d5',
                        backgroundImage: 'repeating-linear-gradient(45deg, #d5d5d5 0, #d5d5d5 1px, #fff 0, #fff 50%)',
                        backgroundSize: '10px 10px'
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                    onFocus={() => {
                    }}
                    onBlur={() => {
                    }}
                    // end
                >
                </div>
            }
            // onValuesChange={(changedValues, values) => console.log(changedValues, values)}

        >

            {loading ? <PageLoading/> :
                <>
                    <ProFormDigit
                        name="id"
                        hidden={true}
                    />

                    <ProFormGroup>
                        <ProFormText
                            width="lg"
                            name="creator"
                            label="Creator"
                            rules={[{required: true}]}
                        />

                        <ProFormSelect
                            name={'managed_by_employee_id'}
                            label={'Managed By'}
                            width="sm"
                            showSearch
                            // mode={"multiple"}
                            rules={[{required: true}]}
                            options={employees?.map(i => ({label: i.full_name, value: i.id}))}
                            help={
                                form.getFieldValue('managed_by_employee_id') && employees?.filter(i => i.id == managed_by_employee_id)[0]?.email == '' ?
                                    <><WarningOutlined/> No
                                        email found</> : employees?.filter(i => i.id == managed_by_employee_id)[0]?.email
                            }
                        />
                    </ProFormGroup>

                    <ProFormGroup>
                        <ProFormSelect
                            width="xs"
                            // wrapperCol={{style: {width: 90}}}
                            name="main_platform_id"
                            label="Main Platform"
                            // valueEnum={
                            //     {
                            //         1: {
                            //             text: 'YouTube',
                            //         }
                            //     }}
                            options={[{label: 'YouTube', value: 1}, {label: 'Instagram', value: 2}, {
                                label: 'TikTok',
                                value: 3
                            },]}
                            rules={[{required: true}]}
                        />

                        <ProFormSelect
                            name={'content_categories'}
                            label={'Content categories'}
                            width="xl"
                            convertValue={value => {
                                // console.log(value)
                                return value?.map(i => ({label: i.name, value: i.id}))
                            }}
                            normalize={value => {
                                // console.log(value)
                                return value?.map(i => ({id: i}))
                            }}
                            mode={"multiple"}
                            // options={[{label: 'Cooking', value: 1}, {label: 'Lifestyle', value: 2},]}
                            // request={() =>
                            //     axiosApiInstance.get<API.CreatorsLeads.CreatorLeadContentCategory[]>('/api/leads/categories/')
                            //         .then(res => {
                            //             return res.data.map(i => ({label: i.name, value: i.id}))
                            //         })
                            // }
                            options={creatorsLeadsCategories?.map(i => ({label: i.name, value: i.id}))}
                            fieldProps={{
                                dropdownRender: (menu) => (
                                    <>
                                        {menu}
                                        <Divider style={{margin: '8px 0'}}/>
                                        {/*<Space style={{padding: '0 8px 4px'}}>*/}
                                        <Space>
                                            <ProFormText
                                                placeholder="Please enter item"
                                                name={'new_category'}
                                                // fieldProps={{ref: inputRef}}
                                                allowClear
                                                noStyle
                                            />
                                            <Button type="text" icon={<PlusOutlined/>} onClick={addItem}>
                                                Add item
                                            </Button>
                                        </Space>
                                    </>
                                )
                            }}
                        />
                    </ProFormGroup>

                    <ProFormGroup>

                        <ProFormDatePicker label={'Date of communication'} name={"date_of_communication"}
                                           normalize={value => {
                                               // console.log(value)
                                               return new Date(value).toISOString()
                                           }}
                        />

                        <ProFormSelect
                            width="sm"
                            name="communication_via_id"
                            label="Communication via"
                            // valueEnum={
                            //     {
                            //         1: {
                            //             text: 'Email',
                            //         },
                            //     }}
                            options={[{label: 'Email', value: 1}, {label: 'Phone', value: 2}, {
                                label: 'Instagram',
                                value: 3
                            },]}
                            rules={[{required: true}]}

                        />

                        <ProFormSelect
                            width="sm"
                            name="status_id"
                            label="Status"
                            // valueEnum={
                            //     {
                            //         1: {
                            //             text: 'Signed',
                            //             status: 'Success',
                            //         }
                            //     }
                            // }
                            options={[{label: 'Signed', value: 1}, {label: 'Pending', value: 2}, {
                                label: 'Declined',
                                value: 3
                            },]}
                            rules={[{required: true}]}
                        />

                    </ProFormGroup>

                    <ProFormGroup>
                        <ProFormTextArea
                            width="md"
                            name="negotiation"
                            label="Negotiation"
                            // rules={[{required: true}]}
                        />


                        <ProFormTextArea
                            width="md"
                            name="reason"
                            label="Comments"
                            // rules={[{required: true}]}
                        />

                    </ProFormGroup>

                    <ProFormList name="accounts"
                                 label={'Accounts'}
                                 copyIconProps={props.editable ? {} : false}
                                 deleteIconProps={props.editable ? {} : false}
                                 creatorButtonProps={props.editable ? {creatorButtonText: 'Add account'} : false}
                                 actionRender={(field, action, defaultActionDom) => [
                                     ...defaultActionDom,
                                     !props.editable && <Tooltip title={'Fetch follower count'}>
                                         <Button icon={<DownloadOutlined/>} type={"link"}
                                                 onClick={() => updateAccountFollowerCount(field)}/>
                                     </Tooltip>
                                 ]}
                    >
                        {(f, index, action) => {
                            return (
                                <ProFormGroup>
                                    <ProFormDigit
                                        name="id"
                                        hidden={true}
                                    />

                                    <ProFormDigit
                                        name="creator_lead_id"
                                        hidden={true}
                                    />

                                    <ProFormSelect
                                        // width="xs"
                                        wrapperCol={{style: {width: 70}}}
                                        name="platform_id"
                                        label="Platform"
                                        // valueEnum={
                                        //     {
                                        //         1: {
                                        //             text: 'YouTube',
                                        //         }
                                        //     }}
                                        options={[{label: 'YouTube', value: 1}, {
                                            label: 'Instagram',
                                            value: 2
                                        }, {label: 'TikTok', value: 3},]}
                                        rules={[{required: true}]}
                                    />


                                    <ProFormText
                                        // width={"md"}
                                        wrapperCol={{style: {width: 430}}}
                                        label="Key"
                                        name="key"
                                    />

                                    <ProFormDigit
                                        name="following"
                                        label={'Following'}
                                        fieldProps={{precision: 0}}
                                        // width={"xs"}
                                        wrapperCol={{style: {width: 80}}}
                                    />

                                    <ProFormSwitch
                                        name="auto_fetch_followers"
                                        label={'Auto Fetch Followers'}
                                        // width={"xs"}
                                        hidden={!props.editable}
                                        wrapperCol={{style: {width: 150}}}
                                    />


                                </ProFormGroup>
                            );
                        }}
                    </ProFormList>


                </>
            }

        </ModalForm>
    );
};

export default CreatorLeadViewEdit;