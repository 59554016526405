import { YoutubeAnalyticsResponse } from '../../../api/aggregateReportBuilder';
import { cmsIdEnum, cmsIdToKey } from '../../../common/cmsInfo';
import { roundNumAdvanced } from '../../../common/utils';
import moment from 'moment';

export type graphBuilderParams = {
  date_range: string[];
  metric: string;
  group_by: string;
  round_unit: string;
  topologicalOrder: number;
  chronologicalOrder: number;
};

export type graphData = {
  // data: Array<graphDataObject>;
  data: Array<any>;
  groupField: string;
  isGroup: boolean;
  isStack: boolean;
  seriesField: string;
  xField: string;
  yField: string;
};

export type graphDataObject = {
  date: string;
  dateAlpha: string;
  dateRange: string;
  dateRangeChronologicalOrder: number;
  dateRangeTopologicalOrder: number;
  key: string;
  metric: string;
  timeUnit: string;
  timeUnitIndexAlpha: string;
  value: number;
  valueAlpha: string;
  valueUnit: string;
};

type compiledData = {
  [element: string]: { [date: string]: number };
};

type groupedData = {
  [element: string]: { [date: string]: Array<number> };
};

/**
 * Build the graph config object for the one or two graph data arrays given
 *
 * @param data1 Array of graph data objects
 * @param data2 Optional second array of graph data objects
 * @returns A graph config object
 */
export const buildGraphData = (data1: Array<graphDataObject>, data2?: Array<graphDataObject>): graphData => {
  return {
    data: data1.concat(data2 ?? []),
    xField: 'timeUnitIndexAlpha',
    yField: 'value',
    isGroup: true,
    isStack: true,
    seriesField: 'key',
    groupField: 'dateRange',
  };
};
/**
 * Create the graph config data array elements for a given date range
 *
 * @param params - Parameters including corresponding date range, grouping, rounding value etc.
 * @param data1 - Raw Youtube Analytics Data for a given date range
 * @param data2 - Optional second Analytics Data for the same date range
 * @returns - An array of graph config data objects
 */
export const makeGraphConfigDataArray = (params: any, data1: YoutubeAnalyticsResponse, data2?: YoutubeAnalyticsResponse): Array<graphDataObject> => {
  const compiledData = compileResponseData(params.date_range, data1, data2);
  const groupedData = aggregateResponseDataByGroup(compiledData, params.group_by, params.tagValues);
  return assembleGroupedDataObjectArray(groupedData, params);
};

export const parseTimeUnitIndexAlpha = (group: string, index: number): string => {
  const parsedGroupName = group.charAt(0).toUpperCase() + group.slice(1);
  return `${parsedGroupName} ${index}`;
};

export const roundByUnit = (val: number, unit: string): number => {
  return Number((unit === 'B' ? val / 1_000_000_000 : unit === 'M' ? val / 1_000_000 : unit === 'K' ? val / 1000 : val).toFixed(2));
};

/**
 * Compile a Youtube Analytics response queried-by-CMS into a hash map of values grouped by-day
 *
 * @param dateRange Date Range of YTA query
 * @param firstResponse Youtube Analytics Response object
 * @param secondResponse Optional second Youtube Analytics Response object
 * @returns Hash map of [moment(), number] pairs indexed by CMS
 */
function compileResponseData(dateRange: string[], firstResponse: YoutubeAnalyticsResponse, secondResponse?: YoutubeAnalyticsResponse): compiledData {
  let result: compiledData = {};

  for (const element in firstResponse.body) {
    const firstResponseRows = firstResponse.body[element].rows ?? []; // [date, value] pairs of self uploaded assets
    const secondResponseRows = secondResponse?.body[element].rows ?? []; // [date, value] pairs of third party assets
    const [indexDay, endDay] = [moment(dateRange[0]), moment(dateRange[1])]; // destructured date range

    // initialize date-value pairs
    result[element] = {};
    while (indexDay <= endDay) {
      result[element][indexDay.format()] = 0;
      indexDay.add(1, 'days'); // increment condition variable
    }

    // accumulate value from self uploaded assets
    for (const row of firstResponseRows) {
      const date: string = moment(row[0]).format();
      result[element][date] += row[1];
    }

    // accumulate value from third party assets
    for (const row of secondResponseRows) {
      const date: string = moment(row[0]).format();
      result[element][date] += row[1];
    }
  }
  return result;
}

/**
 * Takes in the hash map of combined values of self-uploaded & third party assets that are grouped by-day and re-groups them by a given metric (month, quarter etc...)
 *
 * @param compiledData Hash map indexed by CMS of values grouped by-day
 * @param groupBy Metric by which the data will be aggregated
 * @returns Hash map of newly grouped data
 */
function aggregateResponseDataByGroup(compiledData: compiledData, groupBy: string, tagValues?: Array<any>): groupedData {
  let result: groupedData = {};
  const jump: (string | number)[] | undefined =
    groupBy === 'day'
      ? [1, 'days']
      : groupBy === 'week'
      ? [1, 'weeks']
      : groupBy === 'month'
      ? [1, 'months']
      : groupBy === 'quarter'
      ? [1, 'quarters']
      : groupBy === 'half'
      ? [2, 'quarters']
      : groupBy === 'year'
      ? [1, 'years']
      : undefined;

  if (!jump) {
    console.error('Error parsing groupBy param.');
    return {};
  }

  // for each cms, get it's corresponding key and the first date in the input object
  for (const element in compiledData) {
    const key = tagValues ? tagValues.filter((tag) => tag.id == element)[0].value : cmsIdToKey[element as cmsIdEnum];
    const dateSeed = Object.keys(compiledData[element])[0]; // get the first date

    let index = moment(dateSeed).format();
    const groupEndDate = moment(dateSeed).add(...jump!); // this is the date where the group ends

    // here we build the groups
    result[key] = {};
    for (const date in compiledData[element]) {
      if (!result[key][index]) result[key][index] = [];
      // if the current date we are testing is still before the end date of the group, add it in that group. else shift into the next group
      if (moment(date).diff(groupEndDate) < 0) {
        result[key][index].push(compiledData[element][date]);
      } else {
        index = groupEndDate.format(); // new group index is the last group end date
        groupEndDate.add(...jump!); // skip forward to the next threshold
        result[key][index] = [compiledData[element][date]]; // first value of the new group is the current index date
      }
    }
  }
  return result;
}

/**
 * Receive a hash map of values grouped by dates grouped by CMS and use them to build an array of graph objects for a given date range according to the parameters given
 *
 * @param groupedData hash map of values
 * @param params given parameters
 * @returns graph object array for given date range
 */
function assembleGroupedDataObjectArray(groupedData: groupedData, params: graphBuilderParams): graphDataObject[] {
  let result: graphDataObject[] = [];

  const parseDateRange = (dateRange: string[]): string => {
    const startDate = moment(dateRange[0]).format('DD/MM/YYYY');
    const endDate = moment(dateRange[1]).format('DD/MM/YYYY');
    return `${startDate} to ${endDate}`;
  };

  for (const key in groupedData) {
    let metricIndex = 1;
    for (const date in groupedData[key]) {
      const pureValue = groupedData[key][date].reduce((sum, acc) => sum + acc, 0); // sum of all values in a given range
      const newEntry: graphDataObject = {
        key: key,
        metric: params.metric,
        timeUnit: params.group_by,
        timeUnitIndexAlpha: parseTimeUnitIndexAlpha(params.group_by, metricIndex),
        value: params.round_unit ? roundNumAdvanced(pureValue, undefined, 2, params.round_unit).value : pureValue,
        valueAlpha: String(pureValue),
        valueUnit: params.round_unit,
        date: date,
        dateAlpha: moment(date).format('DD/MM/YYYY'),
        dateRange: parseDateRange(params.date_range),
        dateRangeChronologicalOrder: params.chronologicalOrder,
        dateRangeTopologicalOrder: params.topologicalOrder,
      };

      metricIndex += 1;
      result = result.concat(newEntry);
    }
  }
  return result;
}
