import React, {Component} from 'react';
import './index.css';
import {TAG_SHAPE, TAG_SIZE} from '../../../common/constants';
import {
    AutoComplete,
    Avatar,
    Badge,
    Button,
    Col,
    Image,
    Input,
    notification,
    Row,
    Space,
    Spin,
    Table,
    Tooltip,
    Typography,
} from 'antd';
// import {Container} from 'react-smooth-dnd';
import {PlusOutlined} from '@ant-design/icons';
import {Navigate} from 'react-router-dom';
import TagsDrawer from '../../components/TagsDrawer';
import {AssetListRow, AssignTagFormV2, TagCategoryValueDrawer, TagCategoryValueEntity} from '../../../common/models';
import {postRequest} from "../../../api/postRequest";
import {assignTagToEntityV2} from "../../../api/assignTagToEntityV2";
import {PageContainer} from "@ant-design/pro-components";

const {Text} = Typography;

export interface IAssetsProps {
    //Here we pass the Props Interface
    // api_call: string;
}

export interface IAssetsState {
    //here we pass the State Interface
    data: any;
    rows: number;
    isLoading: boolean;
    asset: number;
    searched: string;
    filteredData: any;
    redirect: boolean;
    currentPage: number;
    channelImageUrls: { [assetId: string]: string };

    //  tags
    assetTags: { [assetId: number]: { id: number; data: React.ReactNode; obj: TagCategoryValueDrawer }[] };
    isTagsVisible: boolean;
    isDragging: boolean;
}

const PAGE_SIZE = 10;

const IMG_RESOLUTION: 'default' | 'medium' | 'high' = 'default';
const ENABLE_IMG_RENDER: boolean = true;
const IMG_WIDTH = 55;
const IMG_HEIGHT = 55;

const applyDrag = (arr: any, dragResult: any) => {
    const {removedIndex, addedIndex, payload} = dragResult;
    // console.log('drag res', dragResult);
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
    }

    return result;
};

//class ComponentName Component<PropsInterface, StateInterface>
class Assets extends Component<IAssetsProps, IAssetsState> {
    constructor(props: IAssetsProps) {
        super(props);

        this.state = {
            data: [], // Fetched data
            filteredData: [], // data after filter (initially same as fetched data)
            rows: 0, // number of rows fetched
            isLoading: true, // while loading
            asset: 0, //Current asset
            searched: '',
            redirect: false,
            currentPage: 1,
            channelImageUrls: {},

            // tags
            assetTags: {},
            isTagsVisible: false,
            isDragging: false,
        };

        this.getFunc = this.getFunc.bind(this);
        this.setAsset = this.setAsset.bind(this);
        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.getUniqueValues = this.getUniqueValues.bind(this);
        //   this.onAssetClose = this.onAssetClose.bind(this);
        this.showTagsDrawer = this.showTagsDrawer.bind(this);
        this.closeTagsDrawer = this.closeTagsDrawer.bind(this);
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getFunc();
    }

    getFunc = async () => {
        try {
            const response = await postRequest('/api/assets/getall')
            // console.log('respdata', response);

            // console.log('Authenticated');
            if (!response.data) response.data = []
            const result = response.data;
            console.log('result', result);
            // console.log('respdata', response.data.arr);

            // assign tags
            const assetTags = await this.initializeAssetTags(result.map((a: AssetListRow) => a.id));
            // const assetTags: any = {}
            // console.log('assetTags', assetTags, result.map((a: AssetListRow) => a.id));

            this.setState(
                {
                    data: result,
                    filteredData: result,
                    rows: result.length,
                    assetTags: assetTags,
                },
                async () => {
                    this.setState({isLoading: false})
                    const uniqueTags = await this.getUniqueTags()
                    // console.log(...uniqueTags)
                    this.columns[1].filters.push(...this.getUniqueValues('platform'));
                    this.columns[3].filters.push(...this.getUniqueValues('asset_domain'));
                    this.columns[4].filters.push(...this.getUniqueValues('customer'));
                    this.columns[5].filters.push(...this.getUniqueValues('customer_id'));
                    // console.log(this.columns[7].filters)
                    this.columns[7].filters.push(...uniqueTags)
                    // console.log(this.columns[7].filters)
                }
            );
        } catch (error) {
            this.setState({
                data: [],
                filteredData: [],
                rows: 0,
                assetTags: {},
                isLoading: false
            });
            console.log('Error on GET :' + error);
        }

    };

    async initializeAssetTags(assetIds: string[]) {
        let assetTags: any = {};
        assetIds.forEach((id) => {
            assetTags[id] = [];
        });
        // console.log('atinit', assetTags);


        // const res = await this.getTagsOfEntitiesInDomain('_asset');
        try {
            const res = await postRequest('/api/tags/getcategoryvaluesentities', {domain: '_asset'});
            if (!res.data) return assetTags
            // console.log('gettagsres', res);

            // if (res.data.arr === null) res.data.arr = [];
            // res.data.arr.forEach((tag: any) => {
            res.data.forEach((tagValue: TagCategoryValueEntity) => {
                // console.log('tag.entityId', tag.entityId);
                if (!assetTags[+tagValue.entity_id]) return
                assetTags[+tagValue.entity_id].push({
                    id: tagValue.id,
                    data: (
                        <Tooltip title={tagValue.value + ' (' + tagValue.category_name + ')'} placement="top">
                            <Avatar
                                style={{backgroundColor: tagValue.color}}
                                // icon={<UserOutlined />}
                                shape={TAG_SHAPE}
                            >
                                {tagValue.value}
                            </Avatar>
                        </Tooltip>
                    ),
                    obj: tagValue,
                });
            });
            // console.log('astags', assetTags);

            return assetTags;
        } catch (e: any) {
            notification.error({message: e.response.data.message})
            return assetTags
        }
    }

    showTagsDrawer() {
        this.setState({isTagsVisible: true});
    }

    closeTagsDrawer() {
        this.setState({isTagsVisible: false});
    }

    handleSearchInput(text: string) {
        this.setState({searched: text.toString()}, () => {
            //   console.log('searched',this.state.searched);
            this.setState({
                // filteredData: {
                //    arr: this.state.data.arr.filter(
                //       (asset: AssetListRow) =>
                //          asset.id.toString().toLowerCase().includes(this.state.searched.toLowerCase()) ||
                //          asset.ppkValue.toLowerCase().includes(this.state.searched.toLowerCase()) ||
                //          asset.customer.toLowerCase().includes(this.state.searched.toLowerCase())
                //    ),
                // },
                filteredData: this.state.data.filter(
                    (asset: AssetListRow) =>
                        asset.id.toString().toLowerCase().includes(this.state.searched.toLowerCase()) ||
                        asset.ppk_value.toLowerCase().includes(this.state.searched.toLowerCase()) ||
                        asset.customer.toLowerCase().includes(this.state.searched.toLowerCase())
                ),

            });
        });
    }

    setAsset(id: number) {
        if (id !== this.state.asset) {
            this.setState({asset: id}, () => {
                this.setState({redirect: true});
            });
            //  console.log('set asset', id);
        }
    }


    columns: any = [
        {
            title: 'Asset ID',
            dataIndex: 'id',
            key: 'id',
            width: 80,
            ellipsis: true,
            sorter: (a: AssetListRow, b: AssetListRow) => a.id - b.id,
            // sortDirections: ['ascend', 'descend'],
            filters: [
                {text: 'Channels', value: 'channel',},
                // {text: 'Asset Labels', value: 'label',}
            ],
            // defaultFilteredValue: ['channel'],
            onFilter: (value: any, record: AssetListRow) => {
                return record.ppk_value.startsWith('UC') && record.platform == 'YOUTUBE'
                // if (value == 'channel') {
                //     return record.ppk_value.startsWith('UC') && record.platform == 'YOUTUBE'
                // }
                // } else if (value=='label') {
                //
                // }
                // return  record.ppk_value.startsWith('UC') && record.platform=='YOUTUBE'
            },
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            width: 100,
            ellipsis: true,
            sorter: (a: AssetListRow, b: AssetListRow) => a.platform.localeCompare(b.customer),
            sortDirections: ['ascend', 'descend'],
            filters: [],
            onFilter: (value: any, record: AssetListRow) => record.platform.localeCompare(value) === 0,
        },
        {
            title: 'Asset Key',
            dataIndex: 'ppk_value',
            width: 140,
            ellipsis: true,
            // render: ({ text }: any) => <Text ellipsis>{text}</Text>,
            // sorter: (a: AssetListRow, b: AssetListRow) => a.ppk_value.localeCompare(b.ppk_value),
            // sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Asset Domain',
            dataIndex: 'asset_domain',
            width: 100,
            ellipsis: true,
            sorter: (a: AssetListRow, b: AssetListRow) => a.asset_domain.localeCompare(b.customer),
            sortDirections: ['ascend', 'descend'],
            filters: [],
            onFilter: (value: any, record: AssetListRow) => record.asset_domain.localeCompare(value) === 0,
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            width: 120,
            ellipsis: true,
            sorter: (a: AssetListRow, b: AssetListRow) => a.customer.localeCompare(b.customer),
            sortDirections: ['ascend', 'descend'],
            filters: [],
            onFilter: (value: any, record: AssetListRow) => record.customer.localeCompare(value) === 0,
        },
        {
            title: 'Customer ID',
            dataIndex: 'customer_id',
            width: 120,
            ellipsis: true,
            sorter: (a: AssetListRow, b: AssetListRow) => a.customer_id - b.customer_id,
            sortDirections: ['ascend', 'descend'],
            filters: [],
            onFilter: (value: any, record: AssetListRow) => record.customer_id - value === 0,
        },
        {
            title: 'Image',
            dataIndex: 'image_url',
            width: 120,
            key: 'img_url',
            render: (text: string, record: AssetListRow) => {
                // console.log(text, record);
                if (text === '\r' || text === '') return <></>
                return (
                    <Avatar
                        src={
                            <Image
                                placeholder
                                src={text === '\r' ? undefined : text}
                                width={IMG_WIDTH}
                                height={IMG_HEIGHT}
                                preview={false}
                            />
                        }
                        style={{width: IMG_WIDTH, height: IMG_HEIGHT}}
                    />
                );
                // return <Avatar src={text} />;
            },
        },
        {
            title: (
                <Space>
                    Tags
                    <Button type="dashed" onClick={() => this.showTagsDrawer()} icon={<PlusOutlined/>}>
                        Assign
                    </Button>
                </Space>
            ),
            filterMode: 'tree',
            filterMultiple: true,
            defaultSortOrder: 'ascend',
            showSorterTooltip: {
                title: 'Sort by tier'
            },
            sorter: (a: AssetListRow, b: AssetListRow) => {
                // if has tier tag (1-5) sort by tier
                let tierA = 1000
                this.state.assetTags[a.id].map((item: any) => {
                    if (item.obj.category_name == 'Tier') {
                        tierA = parseInt(item.obj.value)
                    }
                })

                let tierB = 1000
                this.state.assetTags[b.id].map((item: any) => {
                    if (item.obj.category_name == 'Tier') {
                        tierB = parseInt(item.obj.value)
                    }
                })

                return tierA - tierB
                // console.log(tierA, this.state.assetTags[a.id],this.state.assetTags[b.id])
                // console.log(tierA)

            },


            key: 'tags',
            width: 120,
            filters: [],
            onFilter: (value: any, record: AssetListRow) => {
                if (value.startsWith('category')) return true
                let valueId = value.split('#')[1];
                return this.state.assetTags[record.id].some(element => element.id.toString() == valueId)
                // for (const element of this.state.assetTags[record.id]) {
                //     // console.log(element);
                //     // if (parseInt(element.id.toString()) === value) return true;
                //     if (element.id.toString() == valueId) return true;
                // }
                // return false;

            },
            render: (text: string, record: AssetListRow, rowIndex: number) => {
                return (
                    <div
                        style={{
                            // flex: 1,
                            border: this.state.isDragging ? '2px dashed #bfbfbf' : 'none',
                            background: this.state.isDragging ? '#f5f5f5' : 'transparent',
                        }}
                    >
                        {/*<Container*/}
                        {/*    groupName="1"*/}
                        {/*    orientation="horizontal"*/}
                        {/*    behaviour="drop-zone"*/}
                        {/*    getChildPayload={(i) => {*/}
                        {/*        // console.log(i, this.state.assetTags[+record.id][i]);*/}
                        {/*        return this.state.assetTags[+record.id][i];*/}
                        {/*    }}*/}
                        {/*    // onDropReady={(e) => console.log('drop ready', e)}*/}
                        {/*    onDrop={async (dropResult) => {*/}
                        {/*        if (dropResult.addedIndex == null) return;*/}
                        {/*        // console.log('drop body', dropResult, 'this', record.id);*/}
                        {/*        const assignTagForm: AssignTagFormV2 = {*/}
                        {/*            tag_category_value_id: parseInt(dropResult.payload.id),*/}
                        {/*            entity_id: record.id,*/}
                        {/*            taggable_entity_domain: '_asset'*/}
                        {/*        };*/}

                        {/*        await assignTagToEntityV2(assignTagForm);*/}

                        {/*        this.columns[7].filters = [...await this.getUniqueTags()]*/}

                        {/*        // console.log('record', record.id, 'got', dropResult.payload.id);*/}
                        {/*        let newState = {...this.state.assetTags};*/}
                        {/*        newState[+record.id] = applyDrag(this.state.assetTags[+record.id], dropResult);*/}
                        {/*        this.setState({assetTags: newState});*/}
                        {/*    }}*/}
                        {/*    shouldAnimateDrop={() => false}*/}
                        {/*    shouldAcceptDrop={(sourceContainerOptions, payload) => {*/}
                        {/*        if (this.state.assetTags[+record.id].filter((e: any) => e.id == payload.id).length > 0)*/}
                        {/*            return false;*/}
                        {/*        return true;*/}
                        {/*    }}*/}
                        {/*>*/}
                            <Avatar.Group
                                maxCount={2}
                                maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}}
                                style={{width: '150px', padding: '5px', height: '40px '}}
                                size={TAG_SIZE}
                                // size={TAG_GROUP_SIZE}
                            >
                                {this.state.assetTags[+record.id].map((p: any, i: number) => {
                                    // console.log('this.state.tagState[record.id]',this.state.tagState[record.id]);
                                    return p.data;
                                })}
                            </Avatar.Group>
                        {/*</Container>*/}
                    </div>
                );
            },
        },
    ];

    getUniqueValues(field: string): { text: string; value: string }[] {
        let distinct = Array.from(
            new Set<string>(this.state.data.map((record: any) => record[field])).values()
        ).sort();
        let filters: { text: string; value: string }[] = [];
        distinct.forEach((item) => {
            filters.push({text: item, value: item});
        });

        // console.log(filters);

        return filters;
    }

    async getUniqueTags(): Promise<any> {
        try {
            const res = await postRequest('/api/tags/getdistinctcategoryvaluesdomain', {domain: '_asset'})
            if (!res.data) return []
            const distinct = res.data as TagCategoryValueDrawer[]
            let filters: { text: React.ReactNode; value: any; children: { text: React.ReactNode; value: any; key?: string }[] }[] = [];
            distinct.forEach((item) => {
                const categoryFilterIndex = filters.findIndex((value, index, obj) => value.value == 'category#' + item.category_id);
                if (categoryFilterIndex == -1) {
                    filters.push({
                        text: <Badge color={item.color} text={item.category_name} key={item.category_id}/>,
                        // text: item.category_name,
                        value: 'category#' + item.category_id,
                        // value: item.category_name,
                        // key: item.category_id.toString(),
                        // disabled: true,
                        children: []
                    })
                    filters[filters.length - 1].children.push({
                        text: <Badge color={item.color} text={item.value} key={item.id}/>,
                        // text: item.value,
                        value: 'value#' + item.id,
                        // value: item.value,
                        // key: item.id.toString()
                    });
                } else {
                    filters[categoryFilterIndex].children.push({
                        text: <Badge color={item.color} text={item.value} key={item.id}/>,
                        // text: item.value,
                        value: 'value#' + item.id,
                        // value: item.value,
                        // key: item.id.toString()
                    });
                }
                // console.log(filters)
            });
            // console.log(filters)
            return filters
        } catch (e: any) {
            notification.error({message: e.response.data.message})
            return []
        }

    }


    render() {
        if (this.state.redirect) {
            return <Navigate to={'/crm/assets/view/' + this.state.asset}/>;
        }

        return (
            <div className="Assets" style={this.style}>
                <PageContainer>
                    <Row gutter={0} id="asset-search">
                        {/* <Col span={this.state.asset === '0' ? 23 : 12}> */}
                        <Col span={23}>
                            <Spin spinning={this.state.isLoading} style={{height: '100vh'}}>
                                {!this.state.isLoading && (
                                    <Space direction="vertical">
                                        <AutoComplete
                                            style={{width: '100%'}}
                                            onSelect={(id: any) => {
                                                this.setAsset(parseInt(id));
                                            }}
                                            onChange={(text: string) => {
                                                this.handleSearchInput(text);
                                            }}
                                            options={this.state.filteredData.map((asset: AssetListRow) => {
                                                return {
                                                    label: asset.id + ' / ' + asset.ppk_value + ' / ' + asset.customer,
                                                    value: asset.id,
                                                };
                                            })}
                                        >
                                            <Input.Search size="large" placeholder="Search..." allowClear/>
                                        </AutoComplete>

                                        <TagsDrawer
                                            onClose={this.closeTagsDrawer}
                                            isVisible={this.state.isTagsVisible}
                                            onDragStart={() => this.setState({isDragging: true})}
                                            onDragEnd={() => this.setState({isDragging: false})}
                                        />

                                        <Table
                                            columns={this.columns}
                                            dataSource={this.state.filteredData}
                                            onRow={(record: AssetListRow, rowIndex: any) => {
                                                return {
                                                    onClick: (event: any) => {
                                                        this.setAsset(record.id);
                                                    }, // click row
                                                    onDoubleClick: (event: any) => {
                                                    }, // double click row
                                                    onContextMenu: (event: any) => {
                                                    }, // right button click row
                                                    onMouseEnter: (event: any) => {
                                                    }, // mouse enter row
                                                    onMouseLeave: (event: any) => {
                                                    }, // mouse leave row
                                                };
                                            }}
                                            // pagination={{ simple: true }}
                                            pagination={{
                                                showSizeChanger: false,
                                                pageSize: PAGE_SIZE,
                                                // current: this.state.currentPage,
                                            }}
                                            rowClassName={'clickable-row'}
                                            // onChange={(pagination, filters, sorter, extra) => {
                                            // console.log('action', extra.action);
                                            // console.log('ds', extra.currentDataSource);
                                            // console.log('pag', pagination.current);
                                            // if (extra.action === 'paginate') {
                                            // this.setState({ currentPage: pagination.current! }, () =>
                                            // this.fetchImageUrlsForPage()
                                            // );
                                            // }
                                            // else {
                                            //    this.fetchImageUrlsForPage();
                                            // }

                                            // }
                                            // }}
                                        />
                                    </Space>
                                )}
                            </Spin>
                        </Col>
                    </Row>
                </PageContainer>
            </div>
        );
    }
}

export default Assets;
