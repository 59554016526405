import {Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, message, Row, Select} from 'antd';
import Title from 'antd/lib/typography/Title';
// import moment from 'moment';
import dayjs from "dayjs";
import React, {Component} from 'react';
import DynamicTable from '../../../components/DynamicTable';
import './index.css';
import {executeQuery} from "../../../api/executeQuery";
import {postRequest} from "../../../api/postRequest";
import {PageContainer} from "@ant-design/pro-components";

export interface IRatesProps {
    //Here we pass the Props Interface
}

export interface IRatesState {
    //here we pass the State Interface
    origin_currencies: any[],
    destination_currencies: any[],
    formRef: React.RefObject<FormInstance>,
}

//class ComponentName Component<PropsInterface, StateInterface>
class Rates extends Component<IRatesProps, IRatesState> {

    constructor(props: IRatesProps) {
        super(props)

        this.state = {
            origin_currencies: [],
            destination_currencies: [],
            formRef: React.createRef<FormInstance>(),
        }
    }

    getDropdowns = async () => {

        executeQuery("GET_CURRENCIES")
            .then(async (response) => {
                // alert(response.data)
                var result: { label: string; value: any; }[] = []
                response.data.forEach((element: any) => {
                    result.push({
                        "label": element["value"] + " / " + element["descr"] + (element["extra"].length > 0 ? " / " + element["extra"] : ""),
                        "value": element["id"]
                    })
                });
                console.log(result)
                this.setState({destination_currencies: result, origin_currencies: result})


            })
            .catch((error) => {
                console.log('Error on GET :' + error);
                message.error('Error on GET :' + error);
            });
    };

    //Add style here
    style = {};
    postNewRate = async (data: any) => {
        //  console.log(data)
        var new_data = data
        new_data["date"] = dayjs(new_data["date"]).format("YYYYMMDD")
        console.log(new_data)
        // return

        postRequest("/api/erp/addrate", data)
            .then(async (response) => {
                // alert(response.data)
                // console.log(response)
                window.location.reload();

            })
            .catch((error) => {
                console.log('Error on GET :' + error);
                message.error('Error on GET :' + error);
            });
    }

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getDropdowns();
    }

    addNewRate() {

        this.state.formRef?.current
            ?.validateFields()
            .then((result) => {
                message.success({content: 'Valid!', key: 'validate'});
                console.log(
                    this.state.formRef.current?.getFieldsValue()
                );

                this.postNewRate(this.state.formRef.current?.getFieldsValue())

            })
            .catch((err) => {
                message.error({content: 'Not valid!', key: 'validate'});
            });


    }

    render() {

        const layout = {
            labelCol: {span: 18},
            wrapperCol: {span: 18},
        };

        return (
            <div className="Rates" style={this.style}>
                <PageContainer>
                    <Row justify="center">
                        <Title level={1}>Rates</Title>
                    </Row>
                    <Row justify="center">
                        <DynamicTable call_api_post="/api/queries/execute" call_api_post_params={{"key": "GET_RATES"}}/>
                    </Row>
                    <Row justify={"center"}>

                        <Title level={5}>Add a new rate</Title>
                    </Row>
                    <Form.Provider>
                        <Form
                            {...layout}
                            name="customerForm"
                            //   onFinish={onFinish}
                            //   onFinishFailed={onFinishFailed}
                            validateMessages={{required: 'This field is required!'}}
                            ref={this.state.formRef}
                            layout="vertical"
                            size="large"
                            style={{marginLeft: '48px'}}
                            // initialValues={{ linked_assets: [], linked_contracts: [] }}
                        >


                            <Row justify={"space-around"}>
                                <Col>

                                    <Form.Item label="Origin Currency" name="origin_currency"
                                               rules={[{required: true}]}>


                                        {this.state.origin_currencies ?
                                            <Select
                                                showSearch
                                                allowClear
                                                style={{width: "250px"}}
                                                placeholder="Select Origin Currency"
                                                optionFilterProp="label"
                                                //  onChange={onChange}
                                                //  onSearch={onSearch}
                                                options={this.state.origin_currencies} // replace with acutal data !!!
                                                filterOption={(inputValue, option) =>
                                                    option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                                                }
                                            ></Select> : "Loading ..."}
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Destination Currency" name="destination_currency"
                                               initialValue={'43'}>
                                        {this.state.destination_currencies ?
                                            <Select
                                                showSearch
                                                allowClear
                                                style={{width: "250px"}}
                                                placeholder="Select Destination Currency"
                                                optionFilterProp="label"
                                                //  onChange={onChange}
                                                //  onSearch={onSearch}
                                                defaultValue={'43'}
                                                disabled
                                                options={this.state.destination_currencies} // replace with acutal data !!!
                                                filterOption={(inputValue, option) =>
                                                    option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
                                                }
                                            ></Select> : "Loading ..."}
                                    </Form.Item>
                                </Col>
                                <Col>

                                    <Form.Item label="Rate" name="rate"
                                               rules={[{required: true, type: "number", min: 0}]}>
                                        <InputNumber/>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Date" name="date" rules={[{required: false, type: "date"}]}>
                                        <DatePicker format="YYYYMMDD" defaultValue={dayjs()}/>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Notes" name="notes" rules={[{required: false}]}>

                                        <Input></Input>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify={"center"} style={{paddingTop: "25px"}}>
                                <Button type={"primary"} onClick={() => this.addNewRate()}>Create</Button>
                            </Row>


                        </Form>
                    </Form.Provider>


                </PageContainer>

            </div>
        );
    }


}

export default Rates;