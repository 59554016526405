import * as React from 'react';
import {RESET} from "jotai/utils";
import {useAtom} from "jotai";
import {filterAccountTypeAtom} from "./atoms";
import {Segmented, Tooltip, Typography} from "antd";
import {blue} from "@ant-design/colors";
import {StarFilled, UserOutlined} from '@ant-design/icons';

type Props = {};

export const FilterAccountType = (props: Props) => {
    const [filterAccountType, setFilterAccountType] = useAtom(filterAccountTypeAtom)

    return (
        <Tooltip title={"Click to choose account type"}>
            <Segmented
                name="filter_account_type"
                value={filterAccountType}
                options={[
                    {
                        value: 'BRAND',
                        label: <Typography.Text strong
                                                style={{
                                                    color: filterAccountType === 'BRAND' ? blue.primary : undefined,
                                                    fontSize: 15
                                                }}
                                                type={filterAccountType !== 'BRAND' ? 'secondary' : undefined}>
                            <StarFilled/>{' Brands'}</Typography.Text>
                    },
                    {
                        value: 'CREATOR',
                        label: <Typography.Text strong
                                                style={{
                                                    color: filterAccountType === 'CREATOR' ? blue.primary : undefined,
                                                    fontSize: 15
                                                }}
                                                type={filterAccountType !== 'CREATOR' ? 'secondary' : undefined}>
                            <UserOutlined/>{' Creators'}</Typography.Text>
                    },
                ]}
                placeholder="Filter by account type"
                onResize={undefined} onResizeCapture={undefined}
                size={"large"}
                onChange={(value) => {
                    // console.log(value)
                    setFilterAccountType(value as string || RESET)
                }}
                // light drop shadow/glow on hover
                style={{boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)'}}
            />
        </Tooltip>
    );
};

export default FilterAccountType