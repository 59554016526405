import * as React from 'react';
import {useState} from 'react';
import {ProTable} from "@ant-design/pro-components";
import {Avatar, Button, Space, Tag, Tooltip, Typography} from "antd";
import {ProColumns} from "@ant-design/pro-table";
import DeleteCreator from "./DeleteCreator";
import {EditOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
// import NotifyCreatorManager from "./NotifyCreatorManager";
import ExportCreatorsExcel from "./ExportCreatorsExcel";
import {UserAuth} from "../../../context/AuthContext";
import './Creators.css';
import DeleteBulkCreators from "./DeleteBulkCreators";
import {creatorsAtom, creatorsAccountsByPlatformAtom, filterSearchAtom, pageAtom, pageSizeAtom} from "./atoms";
import {useAtom, useAtomValue} from "jotai";
import {queryClientAtom} from "jotai-tanstack-query";
import {
    formatFollowers,
    formatSocialMediaAccountName,
    IG_ICON,
    TitleIG,
    TitleTT,
    TitleYT,
    toEUR, TT_ICON,
    YT_ICON
} from "./utils";
// import { createFromIconfontCN } from '@ant-design/icons';
// const IconFont = createFromIconfontCN({
//     scriptUrl: [
//         '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overridden)
//         '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
//     ],
// });
type Props = {};


const CreatorsAccountsByPlatform = (props: Props) => {
    // const actionRef = useRef<ActionType>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    // const {
    //     employees,
    //     setEmployees,
    // } = useContext(GlobalStateContext);
    const [categoriesFilters, setCategoriesFilters] = useState(undefined);
    const [employeesFilters, setEmployeesFilters] = useState(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate()
    const location = useLocation()
    const {can} = UserAuth();

    const [{data: creators, isPending, isError}] = useAtom(creatorsAccountsByPlatformAtom);
    const [page, setPage] = useAtom(pageAtom);
    const [pageSize, setPageSize] = useAtom(pageSizeAtom);

    const queryClient = useAtomValue(queryClientAtom);
    const [filterSearch, setFilterSearch] = useAtom(filterSearchAtom)
    // const creatorsAfterSortSearch = useAtomValue(creatorsAfterSortSearchAtom)

    //
    // Functions
    //


    const columns: ProColumns<API.Creators.CreatorWithAccount>[] = [
        {
            dataIndex: 'social_media_account_id',
            title: 'ID',
            // valueType: 'indexBorder',
            align: 'center',
            width: '2%',
        },
        {
            align: 'center',
            render: (_, record) => {
                return <Avatar
                    src={`data:image/jpeg;base64,${record.image}`}
                    style={{width: 34, height: 34}}
                    shape={"circle"}
                />
            },
            width: 70,
        },
        {
            title:'Account',
            dataIndex: 'platform_account_id',
            align: 'center',
            render: (_, record) =>
                <Space direction="vertical" style={{display: 'flex', alignItems: 'center'}}>
                    <Tooltip title={formatSocialMediaAccountName(record.key)}>
                        <a href={record.key} target="_blank" rel="noreferrer"
                           style={{color: "darkslategrey"}}>{record.title}</a>
                    </Tooltip>
                </Space>

        },
        {
            title: 'Creator',
            dataIndex: 'creator',
            align: 'center',
            width: '8%',
        },
        {
            title: 'Identity',
            dataIndex: 'main_platform_id',
            valueType: 'select',
            align: 'center',
            width: '8%',
            valueEnum: {
                1: {text: 'YouTube'},
                2: {text: 'Instagram'},
                3: {text: 'TikTok'},
            },
            // filters: true,
            render: (_, record) => record.main_platform.characterization
        },
        {
            title: 'Followers',
            dataIndex: 'followers',
            // width: "50%",
            // sorter: true,
            hideInSearch: true,
            align: 'center',
            // width: '22%',
            render: (_, record) => formatFollowers(record.following),
            sorter: (a, b) => a.following - b.following
        },
        {
            title: 'Other Accounts',
            // dataIndex: 'followers',
            // width: "50%",
            // sorter: true,
            hideInSearch: true,
            align: 'center',
            width: '22%',
            children: [
                {
                    title: YT_ICON,
                    dataIndex: 'youtube_pricing',
                    align: 'center',

                    // renderText: (_text, record) => toEUR(record.pricing.youtube_video),
                    render: (dom, record) => record.accounts.filter(account => account.platform.name === "YouTube" && account.id !== record.social_media_account_id).map((account) => {
                        return <Space direction="vertical" style={{display: 'flex', alignItems: 'center'}}>
                            <Tooltip title={formatSocialMediaAccountName(account.key)}>
                                <a href={account.key} target="_blank" rel="noreferrer"
                                   style={{color: "darkslategrey"}}>{formatFollowers(account.following)}</a>
                            </Tooltip>
                        </Space>
                    }),
                    sorter: (a, b) => {
                        //
                        // Sorting column by creator's biggest channel's followers
                        //

                        // Store the two creators' accounts' followers into two tables
                        let followers_a = a.accounts.filter(account => account.platform.name === "YouTube").map((account) => account.following)
                        let followers_b = b.accounts.filter(account => account.platform.name === "YouTube").map((account) => account.following)

                        // Keep only the max number of followers of each creator's biggest channel
                        let max_a = 0
                        if (followers_a.length > 0) {
                            max_a = Math.max(...followers_a);
                        }

                        let max_b = 0
                        if (followers_b.length > 0) {
                            max_b = Math.max(...followers_b);
                        }

                        // Compare the two maxes to sort the table, based on each creator's biggest channel's followers
                        if (max_a > max_b) {
                            return 1;
                        } else if (max_a < max_b) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                },
                {
                    title: IG_ICON,
                    dataIndex: 'instagram_pricing',
                    align: 'center',

                    render: (dom, record) => record.accounts.filter(account => account.platform.name === "Instagram" && account.id !== record.social_media_account_id).map((account) => (
                        <Space direction="vertical" style={{display: 'flex', alignItems: 'center'}}>
                            <Tooltip title={formatSocialMediaAccountName(account.key)}>
                                <a href={account.key} target="_blank" rel="noreferrer"
                                   style={{color: "darkslategrey"}}>{formatFollowers(account.following)}</a>
                            </Tooltip>
                        </Space>
                    )),
                    sorter: (a, b) => {
                        //
                        // Sorting column by creator's biggest channel's followers
                        //

                        // Store the two creators' accounts' followers into two tables
                        let followers_a = a.accounts.filter(account => account.platform.name === "Instagram").map((account) => account.following)
                        let followers_b = b.accounts.filter(account => account.platform.name === "Instagram").map((account) => account.following)

                        // Keep only the max number of followers of each creator's biggest channel
                        let max_a = 0
                        if (followers_a.length > 0) {
                            max_a = Math.max(...followers_a);
                        }

                        let max_b = 0
                        if (followers_b.length > 0) {
                            max_b = Math.max(...followers_b);
                        }

                        // Compare the two maxes to sort the table, based on each creator's biggest channel's followers
                        if (max_a > max_b) {
                            return 1;
                        } else if (max_a < max_b) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                },
                {
                    title: TT_ICON,
                    dataIndex: 'tiktok_pricing',
                    align: 'center',

                    render: (dom, record) => record.accounts.filter(account => account.platform.name === "TikTok" && account.id !== record.social_media_account_id).map((account) => (
                        <Space direction="vertical" style={{display: 'flex', alignItems: 'center'}}>
                            <Tooltip title={formatSocialMediaAccountName(account.key)}>
                                <a href={account.key} target="_blank" rel="noreferrer"
                                   style={{color: "darkslategrey"}}>{formatFollowers(account.following)}</a>
                            </Tooltip>
                        </Space>
                    )),
                    sorter: (a, b) => {
                        //
                        // Sorting column by creator's biggest channel's followers
                        //

                        // Store the two creators' accounts' followers into two tables
                        let followers_a = a.accounts.filter(account => account.platform.name === "TikTok").map((account) => account.following)
                        let followers_b = b.accounts.filter(account => account.platform.name === "TikTok").map((account) => account.following)

                        // Keep only the max number of followers of each creator's biggest channel
                        let max_a = 0
                        if (followers_a.length > 0) {
                            max_a = Math.max(...followers_a);
                        }

                        let max_b = 0
                        if (followers_b.length > 0) {
                            max_b = Math.max(...followers_b);
                        }

                        // Compare the two maxes to sort the table, based on each creator's biggest channel's followers
                        if (max_a > max_b) {
                            return 1;
                        } else if (max_a < max_b) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                },
            ],
        },
        {
            title: 'Categories',
            dataIndex: 'content_categories',
            align: 'center',
            width: '8%',
            search: false,
            filters: categoriesFilters,
            render: (_, record) => (
                <Space direction="vertical" style={{display: 'flex', alignItems: 'center'}}>
                    {record.content_categories?.map(({name}) => (
                        <Tag key={name}>
                            {name}
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: 'Pricings',
            dataIndex: 'pricings',
            align: 'center',
            width: '52%',
            children: [
                {
                    title: TitleYT('YouTube'),
                    // title: YT_ICON,
                    dataIndex: 'youtube_pricing',
                    align: 'center',
                    children: [
                        {
                            title: 'Video',
                            dataIndex: 'youtube_video_pricing',
                            align: 'center',
                            renderText: (_text, record) => toEUR(record.pricing.youtube_video),
                            sorter: (a, b) => a.pricing.youtube_video - b.pricing.youtube_video
                        }
                    ]
                },
                {
                    title: ''
                    // render: () => <Divider type='vertical' />
                },
                {
                    title: TitleIG('Instagram'),
                    // title: IG_ICON,
                    dataIndex: 'instagram_pricing',
                    align: 'center',
                    children: [
                        {
                            title: 'Reel',
                            dataIndex: 'instagram_reel_pricing',
                            align: 'center',
                            renderText: (_text, record) => toEUR(record.pricing.instagram_reel),
                            sorter: (a, b) => a.pricing.instagram_reel - b.pricing.instagram_reel
                        },
                        {
                            title: 'Post',
                            dataIndex: 'instagram_post_pricing',
                            align: 'center',
                            renderText: (_text, record) => toEUR(record.pricing.instagram_post),
                            sorter: (a, b) => a.pricing.instagram_post - b.pricing.instagram_post
                        },
                        {
                            title: 'Story',
                            dataIndex: 'instagram_story_pricing',
                            align: 'center',
                            renderText: (_text, record) => toEUR(record.pricing.instagram_story),
                            sorter: (a, b) => a.pricing.instagram_story - b.pricing.instagram_story
                        }
                    ]
                },
                {
                    title: ''
                    // render: () => <Divider type='vertical' />
                },
                {
                    title: TitleTT('TikTok'),
                    // title: TT_ICON,
                    dataIndex: 'tiktok_pricing',
                    align: 'center',
                    children: [
                        {
                            title: 'Video',
                            dataIndex: 'tiktok_video_pricing',
                            align: 'center',
                            renderText: (_text, record) => toEUR(record.pricing.tiktok_video),
                            sorter: (a, b) => a.pricing.tiktok_video - b.pricing.tiktok_video
                        }
                    ]
                }
            ]
        },
        {
            title: '',
            width: '8%',
            render: (_, record) => <Space>
                <Link to={`${record.id}`}>
                    <Tooltip title={'View'}><Button icon={<EyeOutlined/>} type={"text"}/></Tooltip>
                </Link>
                <Link to={`${record.id}/edit`}>
                    <Tooltip title={'Edit'}><Button icon={<EditOutlined/>} type={"text"}/></Tooltip>
                </Link>
                {/*<CreatorViewEdit editable={false} creator={record} key={record.id + '_view'}/>*/}
                {/*<CreatorViewEdit editable={true} creator={record} key={record.id + '_edit'}/>*/}
                {/*<NotifyCreatorManager id={record.id} key={record.id}/>*/}
                <DeleteCreator id={record.id} key={record.id}/>
            </Space>
        }
    ];

    return (
        <>

            <ProTable<API.Creators.CreatorWithAccount>
                columns={columns}
                // actionRef={creatorsTableRef}
                // dataSource={creators}
                loading={isPending}
                dataSource={creators?.data}
                ghost
                rowKey={record => record.social_media_account_id}
                // search={{
                //     labelWidth: 'auto',
                // }}
                search={false}
                options={{
                    // setting: {
                    //     listsHeight: 400,
                    // },
                    reload: () => {
                        // queryClient.invalidateQueries({queryKey: ['creators', 'items']})
                        queryClient.invalidateQueries({queryKey: ['creators']})
                    },
                    search: {
                        placeholder: 'Please enter keyword', allowClear: true,
                        onChange: (e) => {
                            setFilterSearch(e.target.value)
                            setPage(1);
                        },
                        value: filterSearch,
                    },
                }}
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: creators?.total,
                    onChange: (page, pageSize) => {
                        setPage(page)
                        setPageSize(pageSize)
                    },
                    hideOnSinglePage: true,
                    showSizeChanger: true,
                }}
                // request={async (params = {}, sort, filter) =>
                //     // axiosApiInstance.get<CreatorRow[]>('/api/creators/', {params})
                // {
                //     // console.log('on request')
                //     // console.log(params)
                //     console.log(filter)
                //     // console.log(sort)
                //     const res = await axiosApiInstance.get<API.Creators.Creator[]>('/api/creators/items', {
                //         params:
                //             {
                //                 ...filter, ...params,
                //                 sort_communication_date: sort.date_of_communication,
                //                 sort_creation_date: sort.created_at,
                //                 sort_accounts: sort.accounts_stringified
                //             }
                //     })
                //     // console.log(res.data)
                //     // await fetchCategoriesData()
                //     // await fetchEmployeesData()
                //     return Promise.resolve({data: res.data, success: true, total: res.data?.length})
                // }
                // }
                // onDataSourceChange={dataSource => {
                //     // console.log('on ds change', dataSource)
                //     setCreators(dataSource)
                //     // setFilters()
                //     setCategoriesFilters(creatorsCategories?.map(value => ({
                //         text: value.name,
                //         value: value.id
                //     })))
                //     setEmployeesFilters(employees?.map(value => ({
                //         text: value.full_name,
                //         value: value.id
                //     })))
                // }}
                // onLoad={dataSource => {
                //     // console.log('on load')
                //     // setCreatorsItems(dataSource)
                // }}
                // pagination={{
                //     pageSize: 7,
                //     // pageSize: 150,
                //     hideOnSinglePage: true,
                //     showSizeChanger: false,
                //     // onChange: (page) => console.log(page),
                // }}
                dateFormatter="string"
                toolBarRender={() => [
                    <Button key="button" icon={<PlusOutlined/>} type="primary"
                            onClick={() => navigate('/crm/creators/list/new', {state: {background: location}})}
                    >
                        New
                    </Button>,
                    can('export', 'leads::data') && <ExportCreatorsExcel/>, // correct permission, do not change
                    // <CreatorCreate/>
                ]}
                tableAlertRender={({selectedRowKeys}) => {
                    return (
                        <Space size={24}>
                          <span>
                                Selected {selectedRowKeys.length} {selectedRowKeys.length > 1 ? 'creators' : 'creator'}
                          </span>
                        </Space>
                    )
                }}
                tableAlertOptionRender={({selectedRowKeys, onCleanSelected}) => {
                    return (
                        <Space size={16}>
                            <DeleteBulkCreators creatorsIds={selectedRowKeys as number[]}
                                                onClearSelection={onCleanSelected}/>
                            <a style={{marginInlineStart: 8}} onClick={onCleanSelected}>
                                Clear selection
                            </a>
                            {/*<a>Delete batch</a>*/}
                        </Space>
                    );
                }}
                // rowSelection={{
                //     selectedRowKeys,
                //     onChange: (keys: React.Key[]) => setSelectedRowKeys(keys as number[]),
                //     // renderCell: (value, record, index, originNode) => {
                //     //     return <Row justify={"center"} align="middle" style={{height: 46}}>
                //     //         <Col>
                //     //             {originNode}
                //     //         </Col>
                //     //     </Row>
                //     // },
                // }}
                columnsState={{
                    persistenceKey: 'creator-creators-list',
                    persistenceType: 'localStorage',
                    // onChange(value) {
                    //     console.log('value: ', value);
                    // },
                }}
            />
            {/*</Col>*/}
            {/*</Row>*/}

            <Outlet/>

        </>
    );
};

export default CreatorsAccountsByPlatform;