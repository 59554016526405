import React, {Component} from 'react';
import DynamicTable from '../../../components/DynamicTable';
import './index.css';
import {PageContainer} from "@ant-design/pro-components";

export interface IPlatformProductKeysProps {
    //Here we pass the Props Interface
}

export interface IPlatformProductKeysState {
    //here we pass the State Interface
}

//class ComponentName Component<PropsInterface, StateInterface>
class PlatformProductKeys extends Component<IPlatformProductKeysProps, IPlatformProductKeysState> {

    constructor(props: IPlatformProductKeysProps) {
        super(props)

        this.state = {}
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        console.log('/platformproductkeys');
    }

    render() {
        return (
            <PageContainer>

                <DynamicTable call_api_post="/api/queries/execute" call_api_post_params={{"key": "GET_PPKS"}}/>

            </PageContainer>
        );
    }
}

export default PlatformProductKeys;