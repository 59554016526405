import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ModalForm} from "@ant-design/pro-components";
import {Avatar, Button, Card, Divider, Image, Row, Skeleton, Space, Statistic, Tooltip, Typography} from "antd";
import axiosApiInstance from "../../../api/axiosClient";
import Meta from "antd/es/card/Meta";
import {
    CommentOutlined,
    EyeOutlined,
    HeartFilled,
    LeftOutlined,
    PlayCircleFilled,
    PlayCircleOutlined,
    RightOutlined,
    TeamOutlined,
    ThunderboltOutlined, YoutubeFilled,
    YoutubeOutlined
} from "@ant-design/icons";
import followersFormatter from "../../../api/followersFormatter";

type Props = {};

declare interface YoutubeAccountReportVideoItem {
    id: string;
    like_count: number;
    comment_count: number;
    engagement_rate: number;
    title: string;
    thumbnail_url: string;
    thumbnail: string;
    published_at: string;
    browser_url: string;
}

declare interface YoutubeAccountReport {
    subscriber_count: number;
    view_count: number;
    videos_count: number;
    average_engagement_rate: number;
    profile_pic_url: string;
    channel_id: string;
    channel_title: string;
    channel_description: string;
    videos: YoutubeAccountReportVideoItem[];
}

const SLIDING_WINDOW_SIZE = 2;

export const CreatorsLeadsSearchReportYoutube = (props: Props) => {

    // state for the report data
    const [reportData, setReportData] = useState<YoutubeAccountReport | undefined>(undefined)
    // feed page state
    const [currentFeedPage, setCurrentFeedPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState(0);
    // feed window state
    const [feedWindow, setFeedWindow] = useState<YoutubeAccountReportVideoItem[]>([])
    const routerParams = useParams()
    // console.log(routerParams.handle)
    const navigate = useNavigate()

    // fetch report data from the backend, with the handle as a parameter, using the routerParams.handle and useEffect
    const getData = async () => {
        try {
            const response = await axiosApiInstance.get(`/api/leads/potential/reports/youtube/${routerParams.channel_id}`)
            console.log(response.data)
            setReportData(response.data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        // fetch data from the backend
        getData()
    }, [])

    useEffect(() => {
        // set the total pages for a data length of reportData.feed.length, and a window size of SLIDING_WINDOW_SIZE
        setTotalPages(reportData?.videos.length - SLIDING_WINDOW_SIZE + 1);
        const minIdx = currentFeedPage + 1 - SLIDING_WINDOW_SIZE;
        const maxIdx = minIdx + SLIDING_WINDOW_SIZE;
        // console.log(currentFeedPage, SLIDING_WINDOW_SIZE, minIdx, maxIdx)
        // console.log(reportData?.feed.slice(minIdx, maxIdx))
        setFeedWindow(reportData?.videos.slice(minIdx, maxIdx))
    }, [reportData, currentFeedPage]);

    return (
        <ModalForm
            open
            submitter={false}
            modalProps={{
                width: 650,
                bodyStyle: {
                    // height: '70vh',
                    // height: 610,
                    minHeight: 610,
                    height: 'fit-content',
                    // overflowY: 'auto',
                },
                style: {
                    top: 100,
                },
                onCancel: () => navigate('/crm/leads')
            }}
            className={"CreatorsLeadsSearchReportYoutube"}
        >
            <Skeleton active loading={reportData === undefined}>
                <Card
                    // style={{width: 'fit-content', margin: 'auto'}}
                    style={{width: 580, margin: 'auto'}}
                    actions={[
                        <Tooltip
                            title={`${new Intl.NumberFormat('el-GR').format(reportData?.subscriber_count)} Subscribers`}>
                            <Statistic
                                value={reportData?.subscriber_count}
                                formatter={followersFormatter}
                                prefix={<TeamOutlined
                                    // style={{color: '#3797F0'}}
                                />}
                                // suffix="Followers"
                            />
                        </Tooltip>,
                        // <Tooltip title={`${new Intl.NumberFormat('el-GR').format(reportData?.videos_count)} Videos`}>
                        //     <Statistic
                        //         value={new Intl.NumberFormat('el-GR').format(reportData?.videos_count)}
                        //         // value={reportData?.media_count}
                        //         prefix={<PictureOutlined
                        //             // style={{color: '#3797F0'}}
                        //         />}
                        //         // groupSeparator={'.'}
                        //         // decimalSeparator={','}
                        //         // suffix="Posts"
                        //     />
                        // </Tooltip>,
                        <Tooltip title={`${new Intl.NumberFormat('el-GR').format(reportData?.view_count)} Views`}>
                            <Statistic
                                value={followersFormatter(reportData?.view_count)}
                                // value={reportData?.media_count}
                                prefix={<EyeOutlined
                                    // style={{color: '#3797F0'}}
                                />}
                                // groupSeparator={'.'}
                                // decimalSeparator={','}
                                // suffix="Posts"
                            />
                        </Tooltip>,
                        <Tooltip
                            title={`Average Engagement Rate (Last 12 videos): ${new Intl.NumberFormat('el-GR').format(reportData?.average_engagement_rate * 100)}%`}>
                            <Statistic
                                value={reportData?.average_engagement_rate * 100}
                                // value={new Intl.NumberFormat('el-GR').format(reportData?.engagement_rate * 100)}
                                precision={2}
                                decimalSeparator={','}
                                prefix={<ThunderboltOutlined/>}
                                suffix="%"
                            />
                        </Tooltip>,

                    ]}
                >
                    <Meta
                        // avatar={<Avatar icon={<InstagramOutlined style={{color: '#E1306C', fontSize: 20}}/>} style={{backgroundColor: 'transparent'}}/>}
                        avatar={<Avatar
                            // src={reportData.profile_pic_url}
                            // src={`data:image/jpeg;base64,${reportData?.profile_pic}`}
                            src={reportData?.profile_pic_url}
                            style={{width: 70, height: 70}}
                        />}
                        // title={reportData?.username}
                        title={
                            <Space
                                // align={"middle"}
                                align={"center"}
                                size={"middle"}
                                // gutter={[0, 80]}
                                // style={{alignItems: 'center'}}
                            >
                                <Typography.Title level={4}
                                                  style={{marginBottom: 4}}
                                >{reportData?.channel_title}</Typography.Title>

                                <YoutubeFilled style={{color: '#FF0000', fontSize: 20, marginTop: 4}}/>

                                <Tooltip
                                    title={`${new Intl.NumberFormat('el-GR').format(reportData?.videos_count)} Uploaded Videos`}>
                                    <PlayCircleOutlined style={{color: 'red'}}/>
                                </Tooltip>
                            </Space>
                        }
                        description={reportData?.channel_id}
                    />
                    {reportData?.channel_description &&
                        <Typography.Paragraph
                            style={{marginTop: 32, marginBottom: 0}}
                            ellipsis={{rows: 3, tooltip: true}}
                        >{reportData?.channel_description}</Typography.Paragraph>
                    }
                </Card>

                <br/>

                <Divider>
                    Recent Videos
                </Divider>

                {/*center the items under the space*/}
                <Row justify="center">

                    <Space wrap size={12}>
                        <Button
                            icon={<LeftOutlined/>}
                            disabled={currentFeedPage === 1}
                            onClick={() => {
                                if (currentFeedPage > 1) {
                                    setCurrentFeedPage(currentFeedPage - 1);
                                }
                            }}
                        />

                        {feedWindow?.map((feedItem, index) => (

                            <Card
                                hoverable
                                bodyStyle={{display: 'none'}}
                                style={{width: 240}}
                                cover={
                                    // feedItem.type === 'IMAGE' || feedItem.type === 'CAROUSEL' ?
                                    <Image crossOrigin="anonymous" alt="example"
                                        // src={feedItem.preview_image_url}
                                           height={180} style={{objectFit: 'cover', objectPosition: 'center'}}
                                        src={`data:image/jpeg;base64,${feedItem.thumbnail}`}
                                           // src={feedItem.thumbnail_url}
                                           onClick={() => {
                                               // open the post in a new tab, using feedItem.browser_url
                                               window.open(feedItem.browser_url, '_blank');
                                           }
                                           }

                                           preview={{
                                               // mask icon according to the type of the post
                                               mask: <Space direction={"vertical"} align={"center"}>
                                                   <PlayCircleFilled style={{fontSize: 28}}/>
                                                   <Typography.Paragraph type={"secondary"}
                                                                         ellipsis={{rows: 2, tooltip: true}}
                                                                         style={{
                                                                             marginBottom: 0,
                                                                             color: '#fff',
                                                                             width: 180
                                                                         }}>
                                                       {feedItem.title}
                                                   </Typography.Paragraph>
                                               </Space>
                                               ,
                                               visible: false,
                                           }}

                                    />
                                    // :<Image crossOrigin="anonymous" alt="example" src={feedItem.preview_image_url}/>
                                }
                                actions={[
                                    // icon for likes and comments
                                    <Tooltip
                                        title={`${new Intl.NumberFormat('el-GR').format(feedItem.like_count)} Likes`}>
                                        <Space>
                                            <HeartFilled/>
                                            <span>{followersFormatter(feedItem.like_count)}</span>
                                        </Space>
                                    </Tooltip>
                                    ,
                                    <Tooltip
                                        title={`${new Intl.NumberFormat('el-GR').format(feedItem.comment_count)} Comments`}>
                                        <Space>
                                            <CommentOutlined/>
                                            <span>{followersFormatter(feedItem.comment_count)}</span>
                                        </Space>
                                    </Tooltip>,
                                    <Tooltip
                                        title={`Engagement Rate: ${new Intl.NumberFormat('el-GR', {maximumFractionDigits: 2}).format(feedItem.engagement_rate * 100)}%`}>
                                        <Space>
                                            <ThunderboltOutlined/>
                                            <span>{new Intl.NumberFormat('el-GR', {maximumFractionDigits: 2}).format(feedItem.engagement_rate * 100)}%</span>
                                        </Space>
                                    </Tooltip>,
                                ]}
                            >
                            </Card>
                        ))}

                        <Button
                            icon={<RightOutlined/>}
                            // disabled={currentFeedPage === Math.ceil(reportData?.feed.length / POSTS_PAGE_SIZE)}
                            disabled={currentFeedPage === totalPages}
                            onClick={() => {
                                // if (currentFeedPage < Math.ceil(reportData?.feed.length / POSTS_PAGE_SIZE)) {
                                if (currentFeedPage < totalPages) {
                                    setCurrentFeedPage(currentFeedPage + 1);
                                }
                            }}
                        />
                    </Space>
                </Row>

            </Skeleton>

        </ModalForm>
    );
};

export default CreatorsLeadsSearchReportYoutube;