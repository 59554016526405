import * as React from 'react';
import {FilterOutlined} from "@ant-design/icons";
import {RESET} from "jotai/utils";
import {LightFilter, ProFormSelect} from "@ant-design/pro-components";
import {useAtom, useAtomValue} from "jotai";
import {channelCategoriesAtom, filterChannelCategoriesAtom} from "./atoms";

type Props = {};

export const FilterCategories = (props: Props) => {
    const channelCategories = useAtomValue(channelCategoriesAtom)
    const [filterChannelCategories, setFilterChannelCategories] = useAtom(filterChannelCategoriesAtom)

    return (
        <LightFilter
            bordered
            collapseLabel={<FilterOutlined/>}
            onFinish={async (values) => setFilterChannelCategories(values.filter_categories || RESET)}
            size={"large"}
        >
            <ProFormSelect
                name="filter_categories"
                showSearch
                fieldProps={{
                    value: filterChannelCategories,
                    mode: 'multiple',
                    options: channelCategories.map((category) => ({
                        label: category.category,
                        value: category.id
                    })),
                }}
                placeholder="Filter by category"
            />
        </LightFilter>
    );
};

export default FilterCategories;