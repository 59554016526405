import { ActionType, FormListActionType, PageContainer, ProFormInstance } from '@ant-design/pro-components';
import ReleaseEntryStepForm from './ReleaseEntryStepForm';
import PendingReleasesTable from './PendingReleasesTable';
import { Button, FormListOperation, Modal } from 'antd';
import { createContext, useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { atom, useAtom } from 'jotai';
import NewArtistForm from './NewArtistForm';

// const releasesActionRef = useAtomValue(releasesActionRefAtom);
type PageContext = {
    stepFormRef?: React.MutableRefObject<ProFormInstance>;
    firstFormPageRef?: React.MutableRefObject<ProFormInstance>;
    secondFormPageRef?: React.MutableRefObject<ProFormInstance>;
    listRef?: React.MutableRefObject<FormListActionType>;
    releasesActionRef?: React.MutableRefObject<ActionType>;
    artists?: SelectedArtistsByCategory;
    setArtists?: React.Dispatch<React.SetStateAction<SelectedArtistsByCategory>>;
    trackAction?: ProFormListRowAction;
    setTrackAction?: React.Dispatch<React.SetStateAction<ProFormListRowAction>>;
};

type ProFormListRowAction = FormListOperation & {
    getCurrentRowData: () => any;
    setCurrentRowData: (data: any) => void;
};

// type SelectedArtistsByCategory = {
//     main_artists?: number[];
//     featuring_artists?: number[];
//     composers?: number[];
//     authors?: number[];
//     remixers?: number[];
//     producers?: number[];
//     arrangers?: number[];
//     publishers?: number[];
// };
type SelectedArtistsByCategory = {
    main_artists?: string[];
    featuring_artists?: string[];
    composers?: string[];
    authors?: string[];
    remixers?: string[];
    producers?: string[];
    arrangers?: string[];
    publishers?: string[];
};

export const DDEXPageContext = createContext<PageContext>({});

export const PageContextProvider = ({ children }: any) => {
    const stepFormRef = useRef<ProFormInstance>();
    const firstFormPageRef = useRef<ProFormInstance>();
    const secondFormPageRef = useRef<ProFormInstance>();
    const listRef = useRef<FormListActionType>();
    const [artists, setArtists] = useState<SelectedArtistsByCategory>({});
    const [trackAction, setTrackAction] = useState<ProFormListRowAction>();
    const releasesActionRef = useRef<ActionType>();

    return (
        <DDEXPageContext.Provider
            value={{
                stepFormRef,
                firstFormPageRef,
                secondFormPageRef,
                listRef,
                releasesActionRef,
                artists,
                setArtists,
                trackAction,
                setTrackAction
            }}
        >
            {children}
        </DDEXPageContext.Provider>
    );
};

export const isModalOpenAtom = atom(false);

const DDEXMainPage = () => {
    useEffect(() => console.log('ddex main page rendered'));
    return (
        <PageContextProvider>
            <PageContainer>
                <ReleaseEntryStepForm />
                <br />
                <PendingReleasesTable />
            </PageContainer>
        </PageContextProvider>
    );
};

export default DDEXMainPage;
