import React, {Component} from 'react';
import './index.css';
import {postRequest} from "../../../api/postRequest";
import enUSIntl from "antd/lib/locale/en_US";
import {EditableProTable, ProColumns} from "@ant-design/pro-table";
// import ProCard from '@ant-design/pro-card';
import {
    Alert,
    Button,
    Card,
    ConfigProvider,
    notification,
    Popconfirm,
    Row,
    Space,
    Tag,
    Tooltip,
    Typography
} from "antd";
import {roundNum} from "../../../common/utils";

// import { ProFormField } from '@ant-design/pro-form';

export interface IUnmatchedKeysUpdateFormProps {
    //Here we pass the Props Interface
}

export interface IUnmatchedKeysUpdateFormState {
    //here we pass the State Interface
    editableKeys: any,
    dataSource: DataSourceType[]
    loading: boolean
    changedRecordKeys: React.Key[]
    saving: boolean
    applying: boolean
}

type UnmatchedType = 'NOT_FOUND' | 'NO_CUSTOMER'

type DataSourceType = {
    ppk_value: string;
    ppk_id: number;
    ppk_desc: string;
    total_gross_platform: number;
    origin_currency_code: string;
    origin_currency_symbol: string;
    total_gross_platform_euro: number;
    destination_currency_code: string;
    destination_currency_symbol: string;
    type: UnmatchedType;
    commision?: number;
    customer_id?: number;
    to_delete: boolean;
}


//class ComponentName Component<PropsInterface, StateInterface>
class UnmatchedKeysUpdateForm extends Component<IUnmatchedKeysUpdateFormProps, IUnmatchedKeysUpdateFormState> {

    constructor(props: IUnmatchedKeysUpdateFormProps) {
        super(props)

        this.state = {
            editableKeys: [],
            dataSource: [],
            loading: false,
            changedRecordKeys: [],
            saving: false,
            applying: false
        }
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        this.setState({loading: true})

        const res = await postRequest('/api/erp/newcycle/getunmatched')
        // let data = res.data.result as DataSourceType[] || []
        let data = res.data.result as any[] || []

        data = data.map(item => ({
            ...item,
            total_gross_platform: parseFloat(item.total_gross_platform),
            total_gross_platform_euro: parseFloat(item.total_gross_platform_euro),
            to_delete: item.to_delete == '1',
            customer_id: item.customer_id === "" ? null : item.customer_id,
            commision: item.commision === "" ? null : item.commision,
        })) as DataSourceType[]

        // console.log('DATA', data)
        // console.log({data}, data[0] as DataSourceType, typeof (data[0] as DataSourceType))

        this.setState({
            dataSource: data,
            editableKeys: data.map((item: DataSourceType) => item.ppk_value),
            loading: false
        }, () => {
            // console.log('this.state.editableKeys', this.state.editableKeys)
        })
    }


    saveChangesToDB = async () => {
        if (this.state.changedRecordKeys.length === 0) {
            notification.info({message: 'No changes were made.'})
            return
        }

        this.setState({saving: true})

        for (const record of this.state.dataSource) {
            if (!this.state.changedRecordKeys.includes(record.ppk_value + '#' + record.ppk_id)) continue
            let body = {
                ppk_value: record.ppk_value,
                ppk_id: record.ppk_id,
                commision: record.commision !== undefined && record.commision !== null ? record.commision : 'NULL',
                customer_id: record.customer_id || 'NULL',
                to_delete: record.to_delete ? 1 : 0,
            }

            try {
                await postRequest('/api/erp/newcycle/updateunmatchedtablerow', body)
            } catch (err) {
                notification.error({message: err.message})
                this.setState({saving: false})
            }
        }

        notification.success({message: 'Successfully saved ' + this.state.changedRecordKeys.length + ' changed records to the database.'})

        this.setState({saving: false, changedRecordKeys: []})
    }

    applyFixesAndUpdate = async () => {

        this.setState({saving: true})

        try {
            await postRequest('/api/erp/newcycle/applyunmatchedcorrections')
            notification.success({message: 'Successfully applied changes. Reloading...'})

            setTimeout(() => {
                this.setState({saving: false})
                window.location.reload()
            }, 500)

        } catch (err) {
            notification.error({message: err.message})
            this.setState({saving: false})
        }

    }

    numberColumnConfig = (config: 'origin' | 'destination' | 'none', precision = 3) => {
        return {
            align: 'right' as const,
            render: (text: number, record: DataSourceType, index: number) => {
                if (config == 'origin') return record.origin_currency_symbol + ' ' + roundNum(text, precision)
                if (config == 'destination') return record.destination_currency_symbol + ' ' + roundNum(text, precision)
                if (config == 'none') return roundNum(text, precision)
            }
        }
    }

    columns: ProColumns<DataSourceType>[] = [
        // columns: any[] = [
        {
            title: "Key", dataIndex: "ppk_value", valueType: 'text', editable: false,
            sorter: (a: DataSourceType, b: DataSourceType) => a.ppk_value.localeCompare(b.ppk_value)
        },
        {
            title: "Product", dataIndex: "ppk_desc", valueType: 'text', editable: false, ellipsis: true
            // sorter: (a: DataSourceType, b: DataSourceType) => a.ppk_value.localeCompare(b.ppk_value)
        },
        {
            title: "Gross Deposited (USD)",
            dataIndex: "total_gross_platform",
            ...this.numberColumnConfig('origin', 4) as any,
            sorter: (a: any, b: any) => a.total_gross_platform - b.total_gross_platform,
            editable: false
        },
        {
            title: "Gross Deposited (EUR)",
            dataIndex: "total_gross_platform_euro",
            ...this.numberColumnConfig('destination', 4) as any,
            sorter: (a: any, b: any) => a.total_gross_platform - b.total_gross_platform,
            editable: false
        },
        {
            title: "Commision", dataIndex: "commision", align: 'right' as const, valueType: 'digit',
            fieldProps: (from:any, {rowKey, rowIndex}:any) => {
                if (this.state.dataSource[rowIndex].to_delete) {
                    return {disabled: true, allowClear: false};
                }
                if (this.state.dataSource[rowIndex].type !== 'NOT_FOUND') {
                    return {disabled: true, allowClear: false};
                }
                return {disabled: false, allowClear: false};
            },
        },
        {
            title: "Customer Id", dataIndex: "customer_id", align: 'right' as const, valueType: 'digit',
            fieldProps: (from:any, {rowKey, rowIndex}:any) => {
                if (this.state.dataSource[rowIndex].to_delete) {
                    return {disabled: true, allowClear: false};
                }
                return {disabled: false, allowClear: false};
            }
        },
        {
            title: "Type",
            dataIndex: "type",
            // filters: true,
            // onFilter: true,
            // valueEnum: {
            //     NOT_FOUND: {text: 'NF', status: 'Default'},
            //     NO_CUSTOMER: {text: 'NC', status: 'Processing'},
            // },
            sorter: (a: DataSourceType, b: DataSourceType) => a.type.localeCompare(b.type),
            editable: false,
            render: (_: any, entity: DataSourceType) => (
                entity.type == 'NOT_FOUND' ?
                    <Tooltip placement="top" title={'Keys that don\'t exist in the database'}>
                        <Tag color='red'>{entity.type}</Tag>
                    </Tooltip>
                    :
                    <Tooltip placement="top" title={'Keys that are not associated to any customer'}>
                        <Tag color='red'>{entity.type}</Tag>
                    </Tooltip>
            )
            // filters...
        },
        {title: "Delete", dataIndex: "to_delete", valueType: "switch",}
    ];

    render() {
        return (
            <div className="UnmatchedKeysUpdateForm" style={this.style}>

                <Row justify='space-between' style={{padding: 4}}>
                    <Typography.Title level={5}>Unmatched Keys</Typography.Title>
                    <Space>
                        <Popconfirm
                            // title={'Are you sure? This will apply corrections to the results and permanently delete the remaining unmatched keys.'}
                            title={'Are you sure? This will apply corrections to the results and permanently delete the selected unmatched keys.'}
                            onConfirm={() => this.applyFixesAndUpdate()}>
                            <Button
                                type="default"
                                key="apply"
                                loading={this.state.applying}
                            >
                                Apply fixes and recompute
                            </Button>
                        </Popconfirm>
                        <Tooltip title={'Temporarily save changes. This will not apply the changes.'}>
                            <Button
                                type="primary"
                                key="save"
                                loading={this.state.saving}
                                onClick={() => {
                                    this.saveChangesToDB()
                                }}
                            >
                                Save changes
                            </Button>
                        </Tooltip>
                    </Space>
                </Row>

                <br/>

                <Alert
                    message={'For an an update to take place, a key must have both a commision and a customer id filled in.'}
                    showIcon
                />
                <br/>

                <Row>
                    <Card bodyStyle={{padding: 0}} style={{width: '100%'}}>
                        <ConfigProvider locale={enUSIntl}>
                            <EditableProTable<DataSourceType>
                                // style={{padding:0}}
                                size={'middle'}
                                loading={this.state.loading || this.state.saving}
                                columns={this.columns}
                                rowKey="ppk_value"
                                value={this.state.dataSource}
                                onChange={(dataSource) => this.setState({dataSource: dataSource as any})}
                                recordCreatorProps={false}
                                // pagination={{}}
                                // pagination={{hideOnSinglePage: true}}
                                pagination={{pageSize: 6, size: 'default', hideOnSinglePage: true}}
                                editable={{
                                    type: 'multiple',
                                    editableKeys: this.state.editableKeys,
                                    onValuesChange: (record, recordList) => {
                                        if (!this.state.changedRecordKeys.includes(record.ppk_value + '#' + record.ppk_id))
                                            this.setState({changedRecordKeys: [...this.state.changedRecordKeys, record.ppk_value + '#' + record.ppk_id]})
                                        this.setState({dataSource: recordList})
                                    },
                                    onChange: (editableKeys) => this.setState({editableKeys}),
                                }}
                                // bordered
                                // toolbar={{
                                //     actions: [
                                //         <Button key="primary" type="primary">
                                //             Custom Action
                                //         </Button>,
                                //     ],
                                // }}
                            />
                        </ConfigProvider>
                    </Card>
                </Row>

            </div>
        );
    }

}

export default UnmatchedKeysUpdateForm;