import * as React from 'react';
import {useState} from 'react';
import {LightFilter, ModalForm, ProFormSelect, ProList} from "@ant-design/pro-components";
import {Avatar, Button, Dropdown, Input, InputRef, Popconfirm, Space, Tag, Tooltip} from "antd";
import CategorySelectModal from "./CategorySelectModal";
import {useAtom} from "jotai";
import {
    channelCategoriesAtom,
    channelsAtom,
    filterChannelCategoriesAtom,
    filterSearchAtom,
    searchFilteredChannelsAtom
} from "./atoms";
import {DeleteOutlined, EllipsisOutlined, FilterOutlined} from '@ant-design/icons';
import {RESET} from "jotai/utils";
import axiosApiInstance from "../../../../api/axiosClient";
import './BecnhmarksChannels.css'

type Props = {};


export const BenchmarksChannels = (props: Props) => {
    const [channels, refreshChannels] = useAtom(channelsAtom)
    const [channelCategories, refreshChannelCategories] = useAtom(channelCategoriesAtom)
    const [filterChannelCategories, setFilterChannelCategories] = useAtom(filterChannelCategoriesAtom)
    const [filterSearch, setFilterSearch] = useAtom(filterSearchAtom)
    const [searchFilteredChannels, setSearchFilteredChannels] = useAtom(searchFilteredChannelsAtom)
    const [isManageCategoriesModalOpen, setIsManageCategoriesModalOpen] = useState(false)
    // input ref
    const inputRef = React.useRef<InputRef>(null);
    return (
        <div>
            <ProList<API.Benchmarks.YouTubeBenchmarksHarvestChannel>
                rowKey="id"
                // dataSource={channels}
                dataSource={searchFilteredChannels}
                showActions="hover"
                headerTitle={`${searchFilteredChannels.length} channels`}
                options={{
                    reload: false,
                    setting: false,
                    density: false,
                    search: {
                        placeholder: "Search...",
                        onChange: (e) => {
                            setFilterSearch(e.target.value)
                        },
                        value: filterSearch,
                        allowClear: true,
                    }
                }}
                pagination={{pageSize: 8}}
                toolBarRender={() => (
                    [
                        <Dropdown trigger={['click']} menu={{
                            items: [
                                {
                                    label: 'Manage Categories',
                                    key: '1',
                                    // icon: <PlusOutlined/>,
                                    onClick: () => setIsManageCategoriesModalOpen(true)
                                },
                            ]
                        }}>

                            {/*<Button onClick={() => setIsNewCategoryModalOpen(true)} icon={<EllipsisOutlined/>} type={"ghost"}/>*/}
                            <Button icon={<EllipsisOutlined/>} type={"ghost"}/>
                        </Dropdown>
                    ]
                )}
                ghost
                metas={{
                    title: {
                        dataIndex: 'name',
                        render: (dom, entity) => (
                            <a href={`https://www.youtube.com/channel/${entity.id}`}
                               target={'_blank'}>{entity?.name}</a>
                        )
                    },
                    avatar: {
                        dataIndex: 'image',
                        editable: false,
                        render: (dom, entity) => (
                            <Avatar
                                src={`data:image/jpeg;base64,${entity?.image}`}
                                style={{width: 40, height: 40}}
                                shape={"circle"}
                            />
                        )
                    },
                    description: {
                        dataIndex: 'desc',
                        // render: () => 'Description'
                    },
                    subTitle: {
                        render: (dom, entity, index, action, schema) => {
                            return (
                                <Space size={0}>
                                    {entity.categories?.map((category: API.Benchmarks.YouTubeBenchmarksChannelCategory) => (
                                        <Tooltip title={`Add ${category.category} to filter`}>
                                            <Tag color="blue"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => {
                                                     if (!filterChannelCategories.includes(category.id)) {
                                                         setFilterChannelCategories([...filterChannelCategories, category.id])
                                                     }
                                                 }}
                                            >{category.category}</Tag>
                                        </Tooltip>
                                    ))}
                                </Space>
                            )
                        },
                    },
                    actions: {
                        render: (text, row, index, action) => [
                            <CategorySelectModal channel={row}/>
                        ],
                    },
                }}
            />

            <ModalForm open={isManageCategoriesModalOpen}
                       onOpenChange={(open) => setIsManageCategoriesModalOpen(open)}
                       width={600}
                       style={{maxHeight: '60vh'}}
                       className={'manage-categories-modal'}
                // title={'Select Categories'}
                       title={`${channelCategories.length} Categories`}
                       onFinish={async (values) => {
                           let category = inputRef.current.input.value.trim()
                           console.log(category)
                           if (!category) return false
                           return axiosApiInstance.post(`/api/benchmarks/youtube/categories`, {category})
                               .then((res) => {
                                   refreshChannelCategories()
                                   return false
                               })
                               .catch((err) => {
                                   // console.log(err)
                                   return false
                               })
                       }}
                       modalProps={{
                           destroyOnClose: true,
                           // add a subtle border to the modal
                           bodyStyle: {border: '1px solid #ebedf5', marginTop: '24px', marginBottom: '24px'},
                       }}
                       submitter={{
                           render: (props, doms) => {
                               return [
                                   <Input
                                       name="category"
                                       // label="Category"
                                       placeholder="Enter name of new category..."
                                       // rules={[{required: true}]}
                                       style={{width: '100%', flex: 1}}
                                       ref={inputRef}
                                   />,
                                   // ...doms,
                                   doms[1],
                                   doms[0],
                               ];
                           },
                           searchConfig: {
                               submitText: 'Add Category',
                               resetText: 'Close',
                           },
                       }}
            >


                <ProList<API.Benchmarks.YouTubeBenchmarksChannelCategory>
                    toolBarRender={false}
                    options={false}
                    tableAlertRender={false}
                    metas={{
                        title: {
                            dataIndex: 'category',
                        },
                        description: {},
                        subTitle: {},
                        actions: {
                            render: (text, row, index, action) => [
                                <Popconfirm title={'Delete Category?'} onConfirm={async () => {
                                    try {
                                        await axiosApiInstance.delete(`/api/benchmarks/youtube/categories/${row.id}`)
                                        refreshChannelCategories()
                                    } catch (e) {
                                        console.log(e)
                                    }
                                }} okText={'Yes'} cancelText={'No'}>
                                    <Button type="link" danger icon={<DeleteOutlined/>}/>
                                </Popconfirm>
                            ],
                        },
                        avatar: {render: () => null},
                    }}
                    rowKey="id"
                    headerTitle="Channel Categories"
                    dataSource={channelCategories}
                    style={{maxHeight: 'inherit', overflow: 'auto'}}
                    showActions={'always'}
                />

            </ModalForm>

        </div>
    );
};

export default BenchmarksChannels;