import { YTAnalyticsReportQueryParams } from '../common/models';

export const fetchAnalytics = async (requestData: YTAnalyticsReportQueryParams) => {
  const ownerId_LTDM = 'xcDgV1hLEnOLtNvdGppirg';

  let request = (window as any).gapi.client.youtubeAnalytics.reports.query({
    // The start-date and end-date parameters must be YYYY-MM-DD strings.
    ...requestData,
    ids: 'contentOwner==' + (requestData.contentOwner || ownerId_LTDM),
  });

  return await new Promise((resolve, reject) => {
    request.execute((response: any) => {
      resolve(response);
    });
  });
};
