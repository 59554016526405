/*eslint eqeqeq: "off"*/
import {FileTextOutlined, FolderOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {ConfigProvider, Row, Space, Tooltip} from 'antd';
import {Component} from 'react';
import './index.css';
import {postRequest} from "../../../api/postRequest";
import ProTable, {ProColumns} from "@ant-design/pro-table";
import enUSIntl from "antd/lib/locale/en_US";
import moment from "moment";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";

export interface ICustomerVaultProps {
    //Here we pass the Props Interface
    id: string;
}

export interface ICustomerVaultState {
    //here we pass the State Interface
    data?: any;
    folderUrl?: any;
}

type CustomerPortalFile = {
    id: string;
    name: string;
    type: string;
    creation_date: string;
    url: string;
    mime_type: string;
}

//class ComponentName Component<PropsInterface, StateInterface>
class CustomerVault extends Component<ICustomerVaultProps, ICustomerVaultState> {
    constructor(props: ICustomerVaultProps) {
        super(props);

        this.state = {};
    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    columns: ProColumns<CustomerPortalFile>[] = [
        {valueType: "indexBorder"},
        {
            title: 'Name', dataIndex: 'name', valueType: 'text',
            render: (dom, entity, index, action, schema) => {
                return <a href={entity.url} target={"_blank"}>{entity.name}</a>
            },
            sorter: (a, b) => {
                return a.name.localeCompare(b.name)
            }
        },
        {
            title: 'Creation Date',
            dataIndex: 'creation_date',
            editable: false,
            align: "center",
            width: '18%',
            sorter: (a, b) => {
                //
                //Sorting column
                //
                //Compare two dates to sort antd's pro table
                const first_date = moment(a.creation_date, "DD/MM/YYYY").toDate();
                const second_date = moment(b.creation_date, "DD/MM/YYYY").toDate();

                if (first_date > second_date) {
                    return 1
                } else if (first_date < second_date) {
                    return -1
                } else {
                    return 0
                }
            }
        },
    ];


    render() {

        return (
            <div className="CustomerVault" style={this.style}>
                {/* <h2>CRM File Vault</h2> */}

                <ConfigProvider locale={enUSIntl}>

                    {/*<Card bodyStyle={{padding: 0}}>*/}

                    <ProTable<CustomerPortalFile>
                        columns={this.columns}
                        bordered
                        request={async (params = {}, sort, filter) => {
                            const res = await postRequest(`/api/customers/getCustomerPortalFolderFiles/` + this.props.id)
                            const data = res.data?.files || []
                            // console.log(res.data)
                            this.setState({folderUrl: res.data?.folderUrl})
                            return {data, success: true, total: data.length}
                        }}
                        rowKey="id"
                        search={false}
                        locale={{emptyText: <Space direction={'vertical'}> <FileTextOutlined/>No files</Space>}}
                        pagination={{pageSize: 5, hideOnSinglePage: true}}
                        dateFormatter="string"
                        // toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                        toolBarRender={false}
                        // debounceTime={5000}
                        // toolBarRender={() => []}
                        // style={{width:"100%"}}
                        tableExtraRender={(props, dataSource) =>
                            (<Row justify={"space-between"}>
                                <Space align={"baseline"}>
                                    <Title level={4}>Customer Vault</Title>
                                    <Tooltip
                                        title={"Synced with the Customer Portal"}><QuestionCircleOutlined/></Tooltip>
                                </Space>

                                <Space size={"large"}>
                                    {this.state.folderUrl ?
                                        <>
                                            <a href={this.state.folderUrl} target={"_blank"}>View folder in Google
                                                Drive <FolderOutlined/> </a>
                                            <Text type={"secondary"}>{dataSource.length} files</Text>
                                        </>
                                        : null}
                                </Space>
                            </Row>)
                        }
                    />
                    {/*</Card>*/}


                </ConfigProvider>
            </div>
        );
    }
}

export default CustomerVault;
