import {Alert, Button, Card, Col, ConfigProvider, Divider, Row, Spin, Statistic, Typography} from 'antd';
import ProForm, {
    ProFormDateRangePicker,
    ProFormDependency,
    ProFormRadio,
    ProFormSelect,
    ProFormSwitch
} from '@ant-design/pro-form';
import {enUSIntl} from '@ant-design/pro-provider';
import Text from 'antd/lib/typography/Text';
import React, {Component} from 'react';
import {
    AggrReportGraphData,
    AggrReportResult,
    buildAnalyticsReportForMetricForCMSsV3,
    constructGraphDataFromAggrReportResult,
    convertAggrReportResultToCumulative,
} from '../../../api/aggregateReportBuilder';
import {cmsIdEnum, cmsIdToName, defaultRevenueCurrency} from '../../../common/cmsInfo';
import {authorize} from '../../../common/gapiHandler';
import DashboardReportingGraphV2 from '../../components/DashboardReportingGraphV2';
// import moment from 'moment';
import './index.css';
import {SearchOutlined} from '@ant-design/icons';
import {postRequest} from '../../../api/postRequest';
import {PageContainer} from "@ant-design/pro-components";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat)

export interface IDashboardReportingV2Props {
    //Here we pass the Props Interface
}

export interface IDashboardReportingV2State {
    //here we pass the State Interface
    isAuthorized: boolean;
    isAuthorizing: boolean;
    isRequestLoading: boolean;
    isGapiLibraryLoading: boolean;
    report?: any;
    formValues: GraphFormValues;
    responseData?: AggrReportResult;
    graphData?: AggrReportGraphData;
    granularity?: 'day' | 'month';
}

interface GraphFormValues {
    startDate?: string;
    endDate?: string;
    contentOwner?: string[];
    lifetime?: boolean;
    allCMS?: boolean;
    cumulative?: boolean;
    granularity?: 'day' | 'month';
}

class DashboardReportingV2 extends Component<IDashboardReportingV2Props, IDashboardReportingV2State> {
    constructor(props: IDashboardReportingV2Props) {
        super(props);

        const currDate = new Date();

        this.state = {
            isAuthorized: false,
            isAuthorizing: false,
            isRequestLoading: false,
            isGapiLibraryLoading: true,
            formValues: {
                startDate: dayjs().subtract(6, 'M').startOf('month').format('YYYY-MM-DD'),
                endDate: dayjs().startOf('month').format('YYYY-MM-DD'),
                contentOwner: [cmsIdEnum['xcDgV1hLEnOLtNvdGppirg']],
                lifetime: false,
                allCMS: false,
                cumulative: true,
                granularity: 'month',
            },
            granularity: 'month',
        };

        // this.reportHandler = this.reportHandler.bind(this)
        // this.formHandler = this.formHandler.bind(this)
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        // Load the gapi (google apis) JS client library and the auth2 client

        // (window as any).gapi.load('client:auth2', () =>
        //   initClient((success: boolean) => {
        //     console.log(success ? 'Loaded gapi client' : 'Could not load gapi client');
        //     this.setState({ isGapiLibraryLoading: false });

        //     if (isUserAuthorized()) this.setState({ isAuthorized: true });
        //     else this.setState({ isAuthorized: false });

        //     if (!isUserAuthorized()) this.authorize();

        //     if (success)
        //       //test
        //       this.formHandler();
        //     // 	fetchAnalytics({ startDate: '2021-05-01', endDate: '2021-05-20', filters: 'uploaderType==self', metrics: 'views' }, (res: any) => console.log(res))
        //   })
        // );
        this.setState({isAuthorized: true, isAuthorizing: false, isGapiLibraryLoading: false}) // TODO fix this duct tape solution
        this.formHandler()
    }

    // !! deprecated
    authorize() {
        console.log('Authorizing...');

        this.setState({isAuthorizing: true}, () =>
            authorize()
                .then(() => this.setState({isAuthorized: true, isAuthorizing: false}))
                .catch(() => this.setState({isAuthorized: false, isAuthorizing: false}))
        );
    }

    // async reportHandler() {
    // 	const report = await buildLifetimeAnalyticsReport()
    // 	this.setState({ report })
    // }

    formHandler = async () => {
        // this.state.formRef.current?.submit()

        this.setState({isRequestLoading: true});

        // console.log('before', values);

        let formValues = {...this.state.formValues};

        console.log(formValues);

        if (this.state.formValues.lifetime) {
            const startDate = '2010-01-01';
            // const currDate = new Date()
            // const endDate = currDate.getFullYear() + '-' + (currDate.getMonth() + 1 < 10 ? '0' : '') + (currDate.getMonth() + 1) + '-01'
            let endDate;
            if (this.state.formValues.granularity == 'month') {
                endDate = dayjs().startOf('month').format('YYYY-MM-DD');
            } else if (this.state.formValues.granularity == 'day') {
                endDate = dayjs().format('YYYY-MM-DD');
            }
            formValues = {...formValues, startDate, endDate};
            // this.setState({ formValues: { ...this.state.formValues, startDate, endDate } })
        }

        if (this.state.formValues.allCMS) {
            const contentOwner = Object.values(cmsIdEnum);
            formValues = {...formValues, contentOwner: contentOwner};
            // this.setState({ formValues: { ...this.state.formValues, contentOwner: this.state.formValues.contentOwner } })
        }

        const requestTemplate = {
            date_range: [formValues.startDate, formValues.endDate],
            elements: formValues.contentOwner,
            metric: 'views,redViews,estimatedMinutesWatched,estimatedRedMinutesWatched,subscribersGained,subscribersLost,estimatedRevenue,estimatedRedPartnerRevenue',
            dimension: formValues.granularity,
            query_by: 'cms',
            currency: 'EUR',
        };

        try {
            const a = postRequest(
                '/api/graphsReporting/fetch',
                {
                    ...requestTemplate,
                    filters: 'uploaderType==self',
                },
                false
            );
            const b = postRequest(
                '/api/graphsReporting/fetch',
                {
                    ...requestTemplate,
                    filters: 'uploaderType==thirdParty;claimedStatus==claimed',
                },
                false
            );
            const [res1, res2] = await Promise.all([a, b]);

            const responses = [res1.data, res2.data];
            let aggrReportResult = buildAnalyticsReportForMetricForCMSsV3(responses);

            this.setState({responseData: JSON.parse(JSON.stringify(aggrReportResult))}, () => {
                if (this.state.formValues.cumulative === true) {
                    aggrReportResult = convertAggrReportResultToCumulative(aggrReportResult);
                }

                const graphData = constructGraphDataFromAggrReportResult(aggrReportResult);

                this.setState({
                    isRequestLoading: false,
                    graphData: graphData,
                    granularity: formValues.granularity,
                });
            });
        } catch (e) {
            console.error(e);
        }
    };

    toggleCumulativeOffline() {
        // console.log('toggleCumulativeOffline', this.state.formValues.cumulative, this.state.responseData);

        this.setState({isRequestLoading: true});
        let aggrReportResult = JSON.parse(JSON.stringify(this.state.responseData));

        if (this.state.formValues.cumulative) {
            aggrReportResult = convertAggrReportResultToCumulative(aggrReportResult);
        }

        const graphData = constructGraphDataFromAggrReportResult(aggrReportResult);

        this.setState({isRequestLoading: false, graphData: graphData});
    }

    render() {
        const inputForm = (
            <ConfigProvider locale={enUSIntl}>
                {/* <LightFilter */}
                {/* <QueryFilter */}
                <ProForm
                    onFinish={async (values: GraphFormValues) => {
                        // this.sendAuthorizedApiRequest(values)
                        // message.success("Success");
                        // if (values.startDate && values.endDate && values.dimension && values.contentOwner)
                        // console.log(values);
                    }}
                    submitter={{
                        searchConfig: {
                            resetText: 'Reset',
                            submitText: 'Submit',
                        },
                        render: false,
                    }}
                    validateMessages={{required: 'This field is required'}}
                    layout='inline'
                    onValuesChange={(changedValues: GraphFormValues, values: GraphFormValues) => {
                        // UPDATE TRIGGER LOGIC
                        // console.log('form', values, changedValues);

                        // if (values.contentOwner?.length === 0) {
                        // 	this.setState({ formValues: { ...this.state.formValues, ...changedValues } })
                        // 	return
                        // }

                        // if (Object.keys(changedValues).length === 1 && changedValues.lifetime === false) {
                        // 	this.setState({ formValues: { ...this.state.formValues, ...changedValues } })
                        // 	return
                        // }

                        // if (Object.keys(changedValues).length === 1 && changedValues.allCMS === false) {
                        // 	this.setState({ formValues: { ...this.state.formValues, ...changedValues } })
                        // 	return
                        // }

                        // if (Object.keys(changedValues).length === 1 && changedValues.allCMS === true && this.state.formValues.contentOwner?.length === 8) {
                        // 	this.setState({ formValues: { ...this.state.formValues, ...changedValues } })
                        // 	return
                        // }

                        if (Object.keys(changedValues).length === 1 && changedValues.cumulative !== undefined) {
                            this.setState({formValues: {...this.state.formValues, ...changedValues}}, () => this.toggleCumulativeOffline());
                            return;
                        }

                        // this.setState({ formValues: { ...this.state.formValues, ...changedValues } }, () => this.formHandler())
                        this.setState({
                            formValues: {...this.state.formValues, ...changedValues},
                        });
                    }}
                    size='middle'>
                    <ProFormSwitch name='allCMS' label='All CMS' fieldProps={{checked: this.state.formValues.allCMS}}/>
                    <ProFormDependency name={['allCMS']}>
                        {({allCMS}) => {
                            return (
                                <ProFormSelect
                                    name='contentOwner'
                                    options={Object.entries(cmsIdToName).map((e) => {
                                        return {label: e[1].slice(14), value: e[0]};
                                    })}
                                    placeholder='CMS'
                                    rules={[{required: true}]}
                                    mode={'multiple' as const}
                                    fieldProps={{
                                        showArrow: true,
                                        value: this.state.formValues.contentOwner,
                                    }}
                                    disabled={allCMS}
                                    // style={{overflowX: '-moz-hidden-unscrollable'}}
                                    style={{maxWidth: 200}}
                                />
                            );
                        }}
                    </ProFormDependency>
                    <ProFormSwitch name='cumulative' label='Cumulative'
                                   fieldProps={{checked: this.state.formValues.cumulative}} style={{flex: 1}}/>
                    <ProFormSwitch name='lifetime' label='Lifetime'
                                   fieldProps={{checked: this.state.formValues.lifetime}}/>
                    <ProFormRadio.Group
                        name={'granularity'}
                        label='Granularity'
                        radioType='button'
                        options={[
                            {label: 'Day', value: 'day'},
                            {label: 'Month', value: 'month'},
                        ]}
                        initialValue={'month'}
                        rules={[{required: true}]}
                    />
                    <ProFormDependency name={[['lifetime'], ['granularity']]}>
                        {({lifetime, granularity}) => {
                            // console.log(lifetime, granularity)
                            if (granularity == 'day') {
                                return (
                                    <ProFormDateRangePicker
                                        label={'Period'}
                                        width='md'
                                        name='range'
                                        disabled={lifetime}
                                        rules={[{required: true}]}
                                        fieldProps={{
                                            ranges: {
                                                'Last 7 days': [dayjs().subtract(2, 'd').subtract(7, 'd'), dayjs()],
                                                'Last 28 days': [dayjs().subtract(2, 'd').subtract(28, 'd'), dayjs()],
                                                'Last 90 days': [dayjs().subtract(2, 'd').subtract(90, 'd'), dayjs()],
                                                'Last 365 days': [dayjs().subtract(2, 'd').subtract(365, 'd'), dayjs()],
                                                [dayjs().format('MMMM YYYY')]: [dayjs().startOf('month'), dayjs().endOf('month')], // current month
                                                [dayjs().subtract(1, 'M').format('MMMM YYYY')]: [dayjs().subtract(1, 'M').startOf('month'), dayjs().subtract(1, 'M').endOf('month')], // previous month
                                                [dayjs().subtract(2, 'M').format('MMMM YYYY')]: [dayjs().subtract(2, 'M').startOf('month'), dayjs().subtract(2, 'M').endOf('month')], // previous-previous month
                                                [dayjs().format('YYYY')]: [dayjs().startOf('year'), dayjs().endOf('year')], // current year
                                                [dayjs().subtract(1, 'y').format('YYYY')]: [dayjs().subtract(1, 'y').startOf('year'), dayjs().subtract(1, 'y').endOf('year')], // previous year
                                            },
                                            picker: 'date',
                                            placeholder: ['Start date', 'End date'],
                                            value: [dayjs(this.state.formValues.startDate, 'YYYY-MM-DD'), dayjs(this.state.formValues.endDate, 'YYYY-MM-DD')],
                                        }}
                                        transform={(values: string[]) => {
                                            console.log(values);
                                            return {
                                                startDate: values[0] || undefined,
                                                endDate: values[1] || undefined,
                                            };
                                        }}
                                        style={{flex: 1}}
                                    />
                                );
                            }
                            if (granularity == 'month') {
                                return (
                                    <ProFormDateRangePicker
                                        label={'Period'}
                                        width='md'
                                        name='range'
                                        disabled={lifetime}
                                        rules={[{required: true}]}
                                        fieldProps={{
                                            picker: 'month',
                                            placeholder: ['Start month', 'End month'],
                                            value: [dayjs(this.state.formValues.startDate, 'YYYY-MM-DD'), dayjs(this.state.formValues.endDate, 'YYYY-MM-DD')],
                                        }}
                                        transform={(values: string[]) => {
                                            return {
                                                startDate: dayjs(values[0]).startOf('month').format('YYYY-MM-DD') || undefined,
                                                endDate: dayjs(values[1]).startOf('month').format('YYYY-MM-DD') || undefined,
                                            };
                                        }}
                                        style={{flex: 1}}
                                    />
                                );
                            }
                        }}
                    </ProFormDependency>
                    <Button type='primary' size='middle' onClick={() => this.formHandler()}>
                        Run <SearchOutlined/>
                    </Button>
                </ProForm>
                {/* </QueryFilter> */}
                {/* </LightFilter> */}
            </ConfigProvider>
        );

        return (
            <div className='DashboardReportingV2' style={this.style}>
                <PageContainer>
                    <Typography.Title level={2}>Dashboard Reporting</Typography.Title>

                    <Alert message={<Text>Welcome to the Analytics Dashboard.</Text>} type='info' closable/>

                    <br/>
                    <br/>

                    {this.state.isGapiLibraryLoading && (
                        <Row justify='center'>
                            <Spin spinning={true} tip='Library loading...'></Spin>
                        </Row>
                    )}
                    {!this.state.isGapiLibraryLoading && this.state.isAuthorizing && (
                        <Row justify='center'>
                            <Spin spinning={true} tip='Authorizing...'></Spin>
                        </Row>
                    )}

                    {!this.state.isGapiLibraryLoading && this.state.isAuthorized && (
                        <>
                            <Spin spinning={this.state.isRequestLoading} tip='Loading...'>
                                {inputForm}
                                <Divider/>
                                {/* <br /> */}

                                <Row
                                    justify='space-between'
                                    style={{
                                        backgroundColor: '#F0F2F5',
                                        padding: '16px 32px 15px 32px',
                                    }}>
                                    <Card>
                                        <Statistic title='Views' value={this.state.responseData?.views.sum.value}
                                                   suffix={this.state.responseData?.views.sum.unit}/>
                                    </Card>
                                    <Card>
                                        <Statistic title='Watch Time'
                                                   value={this.state.responseData?.watchTime.sum.value}
                                                   suffix={this.state.responseData?.watchTime.sum.unit}/>
                                    </Card>
                                    <Card>
                                        <Statistic
                                            title='Subscribers'
                                            value={this.state.responseData?.subscribers.sum.value}
                                            suffix={this.state.responseData?.subscribers.sum.unit}
                                        />
                                    </Card>
                                    <Card>
                                        <Statistic
                                            title='Est. Revenue'
                                            value={this.state.responseData?.revenue.sum.value}
                                            prefix={defaultRevenueCurrency}
                                            suffix={this.state.responseData?.revenue.sum.unit}
                                            precision={2}
                                        />
                                    </Card>
                                </Row>

                                <br/>
                                <br/>
                                <Row wrap gutter={32} style={{marginBottom: 64}}>
                                    <Col span={12}>
                                        <DashboardReportingGraphV2
                                            metric='views'
                                            label='Views'
                                            unit={this.state.graphData?.views.unit!}
                                            dimension={this.state.granularity!}
                                            data={this.state.graphData?.views.data}
                                            cumulative={this.state.formValues.cumulative!}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DashboardReportingGraphV2
                                            metric='watchTime'
                                            label='Watch Time'
                                            unit={this.state.graphData?.watchTime.unit!}
                                            dimension={this.state.granularity!}
                                            data={this.state.graphData?.watchTime.data}
                                            cumulative={this.state.formValues.cumulative!}
                                        />
                                    </Col>
                                </Row>
                                <Row wrap gutter={32} style={{marginBottom: 64}}>
                                    <Col span={12}>
                                        <DashboardReportingGraphV2
                                            metric='subscribers'
                                            label='Subscribers'
                                            unit={this.state.graphData?.subscribers.unit!}
                                            dimension={this.state.granularity!}
                                            data={this.state.graphData?.subscribers.data}
                                            cumulative={this.state.formValues.cumulative!}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DashboardReportingGraphV2
                                            metric='revenue'
                                            label='Revenue'
                                            unit={this.state.graphData?.revenue.unit!}
                                            dimension={this.state.granularity!}
                                            data={this.state.graphData?.revenue.data}
                                            cumulative={this.state.formValues.cumulative!}
                                        />
                                    </Col>
                                </Row>
                            </Spin>
                        </>
                    )}

                    {/* <Button onClick={this.reportHandler}>
						Generate analytics report
					</Button>
					<Card>
						{this.state.report && JSON.stringify(this.state.report)}
					</Card> */}
                </PageContainer>
            </div>
        );
    }
}

export default DashboardReportingV2;
