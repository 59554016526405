import * as React from 'react';
import { useState } from 'react';
import { ProLayout } from '@ant-design/pro-layout';
import Icon, {
    BarChartOutlined,
    BellFilled,
    BookOutlined,
    ContactsOutlined,
    ControlOutlined,
    CustomerServiceOutlined,
    DollarCircleOutlined,
    FileTextOutlined,
    FormOutlined,
    GroupOutlined,
    HomeOutlined,
    InfoCircleFilled,
    LineChartOutlined,
    PhoneOutlined,
    PieChartOutlined,
    PlayCircleOutlined,
    PlaySquareOutlined,
    RiseOutlined,
    SearchOutlined,
    SettingOutlined,
    TeamOutlined,
    UnorderedListOutlined,
    UserOutlined,
    YoutubeFilled,
    YoutubeOutlined
} from '@ant-design/icons';
import { ProSettings } from '@ant-design/pro-components';
import enUS from 'antd/es/locale/en_US';
// import enUS from "antd/locale/en_US";
import { Avatar, ConfigProvider, Dropdown, Input } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
// import {ReactComponent as LogoCompactTrans} from '../assets/dm_logo_compact_trans.svg';
import { ReactComponent as Logo } from '../assets/dm_logo_long.svg';
import { UserAuth } from '../context/AuthContext';
import { AvatarDropdownMenu } from './AvatarDropdownMenu';
import { Route } from '@ant-design/pro-layout/es/typing';
import { ReactComponent as TikTokSvg } from '../assets/tiktok.svg';

type Props = {};

export const MyLayout = (props: Props) => {
    const [settings, setSetting] = useState<Partial<ProSettings> | undefined>({
        fixSiderbar: true,
        layout: 'mix',
        splitMenus: true
    });

    const [num, setNum] = useState(40);
    const location = useLocation();

    const { can, user } = UserAuth();

    const managerReportingRoutes = [
        {
            path: '/reporting/engine',
            name: 'Charts Engine',
            icon: <PieChartOutlined />
        }
    ];

    let accessibleRoutes: Route = {
        path: '/',
        routes: [
            {
                path: '/home',
                name: 'Home',
                icon: <HomeOutlined />
                // component: './Welcome',
            }
        ]
    };

    let crm = {
        path: '/crm',
        name: 'Customer Management',
        icon: <TeamOutlined />,
        routes: []
    };

    if (can('read', 'creators::data')) {
        crm.routes.push({
            path: '/crm/creators',
            name: 'Creators',
            icon: <TeamOutlined />,
            routes: [
                {
                    path: '/crm/creators/list',
                    name: 'Search',
                    icon: <SearchOutlined />
                }
            ]
        });
    }
    if (can('read', 'customers::data')) {
        crm.routes.push(
            {
                path: '/crm/customers',
                name: 'Customers',
                icon: <TeamOutlined />,
                routes: [
                    {
                        // path: '/crm/customers',
                        path: '/crm/customers/list',
                        name: 'List',
                        icon: <UnorderedListOutlined />
                        // exact: true,
                    },
                    {
                        path: '/crm/customers/new',
                        name: 'Create',
                        icon: <FormOutlined />
                        // exact: true,
                        // computedMatch: {path: ''}
                    },
                    {
                        path: '/crm/customers/view/:id',
                        name: 'Customer',
                        hideInMenu: true
                    },
                    {
                        path: '/crm/customers/edit/:id',
                        name: 'Edit Customer',
                        hideInMenu: true
                    }
                ]
            },
            {
                path: '/crm/assets',
                name: 'Assets',
                icon: <RiseOutlined />,
                routes: [
                    {
                        // path: '/crm/assets',
                        path: '/crm/assets/list',
                        name: 'List',
                        icon: <UnorderedListOutlined />
                    },
                    {
                        path: '/crm/assets/new',
                        name: 'Create',
                        icon: <FormOutlined />
                    },
                    {
                        path: '/crm/assets/view/:id',
                        name: 'Asset',
                        hideInMenu: true
                    },
                    {
                        path: '/crm/assets/edit/:id',
                        name: 'Edit Asset',
                        hideInMenu: true
                    }
                ]
            },
            {
                path: '/crm/contracts',
                name: 'Contracts',
                icon: <FileTextOutlined />,
                routes: [
                    {
                        path: '/crm/contracts/list',
                        name: 'List',
                        icon: <UnorderedListOutlined />
                    },
                    {
                        path: '/crm/contracts/new',
                        name: 'Create',
                        icon: <FormOutlined />
                    },
                    {
                        path: '/crm/contracts/view/:id',
                        name: 'Contract',
                        hideInMenu: true
                    },
                    {
                        path: '/crm/contracts/edit/:id',
                        name: 'Edit Contract',
                        hideInMenu: true
                    }
                ]
            },
            {
                path: '/crm/leads',
                name: 'Creators Leads',
                icon: <FormOutlined />,
                routes: [
                    {
                        path: '/crm/leads/list',
                        name: 'Manage',
                        icon: <UnorderedListOutlined />
                    },
                    {
                        path: '/crm/leads/search',
                        name: 'Discover',
                        icon: <SearchOutlined />
                    }
                ]
            },
            can('read', 'admin::data') && {
                path: 'revenuebrackets',
                name: 'Revenue Brackets',
                icon: <GroupOutlined />
            },

            can('read', 'connections::data') && {
                path: '/social/',
                name: 'Social',
                icon: <ContactsOutlined />,
                routes: [
                    {
                        path: '/social/connections',
                        name: 'Connections',
                        icon: <PhoneOutlined />
                    }
                ]
            }
        );
    }
    accessibleRoutes.routes.push(crm);

    if (can('read', 'finances::data')) {
        accessibleRoutes.routes.push({
            path: '/erp/paymentcycles',
            name: 'Financials',
            icon: <BookOutlined />,
            routes: [
                {
                    path: '/erp/paymentcycles/:id',
                    name: 'Payment Cycle',
                    hideInMenu: true
                }
            ]
        });
    }

    if (can('exec', 'reporting::data')) {
        accessibleRoutes.routes.push({
            path: '/reporting',
            name: 'Reporting',
            icon: <LineChartOutlined />,
            routes: [
                {
                    path: '/reporting/smartreports',
                    name: 'Smart Reports',
                    icon: <LineChartOutlined />,
                    routes: [
                        {
                            path: '/reporting/smartreports',
                            name: 'CMS Channels',
                            icon: <DollarCircleOutlined />
                        },
                        {
                            path: '/reporting/brandaccounts',
                            name: 'Managed Channels',
                            icon: <SearchOutlined />
                        }
                    ]
                },
                ...(can('read', 'management::reporting::data') ? managerReportingRoutes : []),
                {
                    path: '/reporting/videocomparison',
                    name: 'Video Comparison',
                    icon: <PlaySquareOutlined />
                },
                {
                    path: '/reporting/videostats',
                    name: 'Video Statistics',
                    icon: <PlayCircleOutlined />
                },
                {
                    path: '/reporting/benchmarks',
                    name: 'Benchmarks',
                    icon: <LineChartOutlined />,
                    routes: [
                        {
                            path: '/reporting/benchmarks/youtube',
                            name: 'YouTube',
                            icon: <YoutubeFilled />
                        },
                        {
                            path: '/reporting/benchmarks/tiktok',
                            name: 'TikTok',
                            icon: <Icon component={TikTokSvg} style={{ color: '#6e6e6e', fontSize: 14 }} />
                        }
                    ]
                },
                {
                    path: '/reporting/tiermetrics',
                    name: 'Tier Metrics',
                    icon: <BarChartOutlined />
                },
                {
                    path: '/admin',
                    name: 'Admin Dashboard',
                    hideInMenu: true
                }
            ]
        });
    }

    // Music Distribution
    if (can('read', 'testing::features')) {
        accessibleRoutes.routes.push({
            path: '/music',
            name: 'Music Distribution',
            icon: <CustomerServiceOutlined />,
            routes: [
                {
                    path: '/music/main',
                    name: 'Releases',
                    icon: <UnorderedListOutlined />
                },
                {
                    path: '/music/artists',
                    name: 'Artists',
                    icon: <UserOutlined />
                },
                {
                    path: '/music/labels',
                    name: 'Labels',
                    icon: <ControlOutlined />
                },
                {
                    path: '/music/asset_labels',
                    name: 'Asset Labels',
                    icon: <YoutubeOutlined />
                }
            ]
        });
    }

    if (can('read', 'admin::data')) {
        accessibleRoutes.routes.push({
            path: '/admin',
            name: 'Admin',
            icon: <SettingOutlined />
        });
    }

    return (
        <div
            id='test-pro-layout'
            style={
                {
                    // height: '100vh',
                    // backgroundColor: "#F5F5F5"
                    // color: "#F5F5F5"
                }
            }
        >
            <ConfigProvider locale={enUS}>
                <ProLayout
                    location={location}
                    menuItemRender={(item: any, defaultDom: any) => {
                        return <Link to={item.path}> {defaultDom} </Link>;
                    }}
                    subMenuItemRender={(item: any, defaultDom: any) => (
                        <Link to={item.path}> {defaultDom} </Link>
                    )}
                    // location={{
                    //     pathname,
                    // }}
                    route={accessibleRoutes}
                    appList={[
                        // {
                        //     // icon: 'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg',
                        //     icon: <></>,
                        //     // icon: <LinkOutlined />,
                        //     title: 'Main Platform',
                        //     desc: 'CRM, ERP and reporting modules',
                        //     url: 'http://app.digitalminds-dashboard.com',
                        //     target: '_blank',
                        // },
                        {
                            // icon: <LinkOutlined />,
                            icon: <></>,
                            title: 'Brands',
                            desc: 'Sales & Project Management Solution',
                            url: 'http://brands.digitalminds-dashboard.com',
                            target: '_blank'
                        },
                        {
                            // icon: <LinkOutlined />,
                            icon: <></>,
                            title: 'Portal',
                            desc: 'Customer and Internal Portal',
                            url: 'http://portal.digitalminds-dashboard.com',
                            target: '_blank'
                        },
                        {
                            // icon: <LinkOutlined />,
                            icon: <></>,
                            title: 'RBAC',
                            desc: 'Role based access management module',
                            url: 'http://rbac.digitalminds-dashboard.com',
                            target: '_blank'
                        },
                        {
                            // icon: <LinkOutlined />,
                            icon: <></>,
                            title: 'Bulk Payments',
                            desc: 'Paypal bulk payments management module',
                            url: 'http://bulk.digitalminds-dashboard.com',
                            target: '_blank'
                        }
                    ]}
                    siderMenuType='group'
                    menu={{
                        collapsedShowGroupTitle: true
                    }}
                    actionsRender={(props) => {
                        if (props.isMobile) return [];
                        return [
                            props.layout !== 'side' && document.body.clientWidth > 1400 ? (
                                <div
                                    key='SearchOutlined'
                                    aria-hidden
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginInlineEnd: 24
                                    }}
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <Input
                                        style={{
                                            borderRadius: 4,
                                            marginInlineEnd: 0,
                                            backgroundColor: 'rgba(0,0,0,0.03)'
                                        }}
                                        prefix={
                                            <SearchOutlined
                                                style={{
                                                    color: 'rgba(0, 0, 0, 0.15)'
                                                }}
                                            />
                                        }
                                        placeholder='search'
                                        bordered={false}
                                    />
                                </div>
                            ) : undefined,
                            <InfoCircleFilled
                                key='InfoCircleFilled'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginInlineEnd: 16
                                }}
                            />,
                            <BellFilled
                                key='BellFilled'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginInlineEnd: 24
                                }}
                            />,
                            // <Space>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginInlineEnd: 32
                                }}
                            >
                                <Dropdown overlay={<AvatarDropdownMenu />}>
                                    <Avatar
                                        src={user?.photoURL}
                                        // size={'small'}
                                    />
                                </Dropdown>
                            </div>

                            // </Space>
                            // <QuestionCircleFilled key="QuestionCircleFilled"/>,
                            // <GithubFilled key="GithubFilled"/>,
                        ];
                    }}
                    menuFooterRender={(props) => {
                        if (props?.collapsed) return undefined;
                        return (
                            <div
                                style={{
                                    textAlign: 'center',
                                    paddingBlockStart: 12
                                }}
                            >
                                <div>© 2022 Digital Minds</div>
                                {/*<div></div>*/}
                            </div>
                        );
                    }}
                    logo={
                        <Link to='/home'>
                            <Logo
                                fill='#0E3748'
                                width={170}
                                // style={{marginBottom: -20, marginLeft: -8, marginRight: -32}}
                                style={{ marginBottom: -20, marginLeft: -20, marginRight: -4 }}
                            />
                        </Link>
                    }
                    // title={false}
                    title={'DM Platform'}
                    {...settings}
                >
                    <Outlet />
                </ProLayout>
            </ConfigProvider>
        </div>
    );
};
