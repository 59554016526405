import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ModalForm} from "@ant-design/pro-components";
import {Avatar, Button, Card, Divider, Image, Row, Skeleton, Space, Statistic, Tag, Tooltip, Typography} from "antd";
import axiosApiInstance from "../../../api/axiosClient";
import Meta from "antd/es/card/Meta";
import Icon, {
    BlockOutlined,
    CheckCircleFilled,
    CommentOutlined, EyeOutlined,
    HeartFilled, HeartOutlined,
    InstagramOutlined,
    LeftOutlined,
    PictureOutlined, PlayCircleFilled,
    PlayCircleOutlined,
    RightOutlined,
    TagsOutlined,
    TeamOutlined,
    ThunderboltOutlined
} from "@ant-design/icons";
import followersFormatter from "../../../api/followersFormatter";
import {ReactComponent as TikTokSvg} from '../../../assets/tiktok.svg';

type Props = {};

declare interface TiktokAccountReportVideoItem {
    browser_url: string;
    image_url: string;
    image: string;
    video_views: number;
    title: string;
}

declare interface TiktokAccountReport {
    handle: string;
    full_name?: string;
    follower_count: number;
    following_count: number;
    likes_count: number;
    profile_pic_url?: string;
    profile_pic?: string;
    is_verified: boolean;
    biography?: string;
    videos: TiktokAccountReportVideoItem[];
}

const SLIDING_WINDOW_SIZE = 2;

export const CreatorsLeadsSearchReportTiktok = (props: Props) => {

    // state for the report data
    const [reportData, setReportData] = useState<TiktokAccountReport | undefined>(undefined)
    // feed page state
    const [currentFeedPage, setCurrentFeedPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState(0);
    // feed window state
    const [feedWindow, setFeedWindow] = useState<TiktokAccountReportVideoItem[]>([])
    const routerParams = useParams()
    // console.log(routerParams.handle)
    const navigate = useNavigate()

    // fetch report data from the backend, with the handle as a parameter, using the routerParams.handle and useEffect
    const getData = async () => {
        try {
            const response = await axiosApiInstance.get(`/api/leads/potential/reports/tiktok/${routerParams.handle}`)
            console.log(response.data)
            setReportData(response.data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        // fetch data from the backend
        getData()
    }, [])

    useEffect(() => {
        // set the total pages for a data length of reportData.feed.length, and a window size of SLIDING_WINDOW_SIZE
        setTotalPages(reportData?.videos.length - SLIDING_WINDOW_SIZE + 1);
        const minIdx = currentFeedPage + 1 - SLIDING_WINDOW_SIZE;
        const maxIdx = minIdx + SLIDING_WINDOW_SIZE;
        // console.log(currentFeedPage, SLIDING_WINDOW_SIZE, minIdx, maxIdx)
        // console.log(reportData?.feed.slice(minIdx, maxIdx))
        setFeedWindow(reportData?.videos.slice(minIdx, maxIdx))
    }, [reportData, currentFeedPage]);

    return (
        <ModalForm
            open
            submitter={false}
            modalProps={{
                width: 650,
                bodyStyle: {
                    // height: '70vh',
                    // height: 610,
                    minHeight: 580,
                    height: 'fit-content',
                    // overflowY: 'auto',
                },
                style: {
                    top: 100,
                },
                onCancel: () => navigate('/crm/leads')
            }}
            className={"CreatorsLeadsSearchReportTiktok"}
        >
            {/*Instagram report for {routerParams.handle}*/}
            {/*<Skeleton active/>*/}

            {/*Instagram report for the handle*/}
            {/*<Result*/}
            {/*    status="info"*/}
            {/*    title={`Instagram report for ${routerParams.handle}`}*/}
            {/*    subTitle="This is a placeholder for the Instagram report"*/}
            {/*/>*/}

            {/*  fill the modal with the report data*/}
            {/*    use pro descriptions to display the data*/}
            <Skeleton active loading={reportData === undefined}>

                {/*<Typography.Title level={5}>Instagram Report for {routerParams.handle}</Typography.Title>*/}

                <Card
                    // style={{width: 'fit-content', margin: 'auto'}}
                    style={{width: 580, margin: 'auto'}}
                    actions={[
                        <Tooltip
                            title={`${new Intl.NumberFormat('el-GR').format(reportData?.follower_count)} Followers`}>
                            <Statistic
                                value={reportData?.follower_count}
                                formatter={followersFormatter}
                                prefix={<TeamOutlined
                                    // style={{color: '#3797F0'}}
                                />}
                                // suffix="Followers"
                            />
                        </Tooltip>,

                        <Tooltip title={`${new Intl.NumberFormat('el-GR').format(reportData?.likes_count)} Likes`}>
                            <Statistic
                                value={followersFormatter(reportData?.likes_count)}
                                // value={reportData?.media_count}
                                prefix={<HeartOutlined />}
                            />
                        </Tooltip>,

                        // engagement rate
                        // <Tooltip
                        //     title={`Average Engagement Rate (Last 12 posts): ${new Intl.NumberFormat('el-GR').format((reportData?.likes_count/reportData?.follower_count) * 100)}%`}>
                        //     <Statistic
                        //         value={(reportData?.likes_count/reportData?.follower_count) * 100}
                        //         // value={new Intl.NumberFormat('el-GR').format(reportData?.engagement_rate * 100)}
                        //         precision={2}
                        //         decimalSeparator={','}
                        //         prefix={<ThunderboltOutlined/>}
                        //         suffix="%"
                        //     />
                        // </Tooltip>,

                    ]}
                >
                    <Meta
                        // avatar={<Avatar icon={<InstagramOutlined style={{color: '#E1306C', fontSize: 20}}/>} style={{backgroundColor: 'transparent'}}/>}
                        avatar={<Avatar
                            // src={reportData.profile_pic_url}
                            src={`data:image/jpeg;base64,${reportData?.profile_pic}`}
                            style={{width: 70, height: 70}}
                        />}
                        // title={reportData?.username}
                        title={
                            <Space
                                // align={"middle"}
                                align={"center"}
                                size={"middle"}
                                // gutter={[0, 80]}
                                // style={{alignItems: 'center'}}
                            >
                                <Typography.Title level={4}
                                                  style={{marginBottom: 4}}
                                >{reportData?.handle}</Typography.Title>
                                {reportData?.is_verified &&
                                    <Tooltip title="Verified">
                                        <CheckCircleFilled style={{color: '#3797F0', fontSize: 20, marginTop: 4}}/>
                                    </Tooltip>
                                }
                                {/*<Avatar icon={<InstagramOutlined style={{color: '#E1306C', fontSize: 20}}/>}*/}
                                {/*        style={{backgroundColor: 'transparent'}}/>*/}
                                {/*<InstagramOutlined style={{color: '#E1306C', fontSize: 20, marginTop: 4}}/>*/}
                                <Icon component={TikTokSvg}
                                      style={{color: '#000', fontSize: 20, marginTop: 4}}
                                />

                                {/*<Tooltip*/}
                                {/*    title={`${new Intl.NumberFormat('el-GR').format(reportData?.total_igtv_videos)} IGTV Videos`}>*/}
                                {/*    <PlayCircleOutlined style={{color: 'red'}}/>*/}
                                {/*</Tooltip>*/}
                            </Space>
                        }
                        description={reportData?.full_name}
                    />
                    {reportData?.biography &&
                        <Typography.Paragraph
                            style={{marginTop: 32, marginBottom: 0}}
                            ellipsis={{rows: 3, tooltip: true}}
                        >{reportData?.biography}</Typography.Paragraph>
                    }

                </Card>

                <br/>

                <Divider>
                    Recent Posts
                </Divider>

                {/*center the items under the space*/}
                <Row justify="center">

                    <Space wrap size={12}>
                        <Button
                            icon={<LeftOutlined/>}
                            disabled={currentFeedPage === 1}
                            onClick={() => {
                                if (currentFeedPage > 1) {
                                    setCurrentFeedPage(currentFeedPage - 1);
                                }
                            }}
                        />

                        {feedWindow?.map((feedItem, index) => (

                            <Card
                                hoverable
                                bodyStyle={{display: 'none'}}
                                style={{width: 240}}
                                cover={
                                    // feedItem.type === 'IMAGE' || feedItem.type === 'CAROUSEL' ?
                                    <Image crossOrigin="anonymous" alt="example"
                                        // src={feedItem.preview_image_url}
                                           height={200} style={{objectFit: 'cover', objectPosition: 'top'}}
                                           src={`data:image/jpeg;base64,${feedItem.image}`}
                                           onClick={() => {
                                               // open the post in a new tab, using feedItem.browser_url
                                               window.open(feedItem.browser_url, '_blank');
                                           }
                                           }

                                           preview={{
                                               // mask icon according to the type of the post
                                               mask: <Space direction={"vertical"} align={"center"}>
                                                           <PlayCircleFilled style={{fontSize: 28}}/>
                                                   <Typography.Paragraph type={"secondary"}
                                                                         ellipsis={{rows: 2, tooltip: true}}
                                                                         style={{
                                                                             marginBottom: 0,
                                                                             color: '#fff',
                                                                             width: 180
                                                                         }}>
                                                       {feedItem.title}
                                                   </Typography.Paragraph>
                                               </Space>
                                               ,
                                               visible: false,
                                           }}

                                    />
                                    // :<Image crossOrigin="anonymous" alt="example" src={feedItem.preview_image_url}/>
                                }
                                actions={[
                                    // icon for likes and comments
                                    <Tooltip
                                        title={`${new Intl.NumberFormat('el-GR').format(feedItem.video_views)} Views`}>
                                        <Space>
                                            <EyeOutlined/>
                                            <span>{followersFormatter(feedItem.video_views)}</span>
                                        </Space>
                                    </Tooltip>
                                    // ,
                                    // <Tooltip
                                    //     title={`${new Intl.NumberFormat('el-GR').format(feedItem.comments_count)} Comments`}>
                                    //     <Space>
                                    //         <CommentOutlined/>
                                    //         <span>{followersFormatter(feedItem.comments_count)}</span>
                                    //     </Space>
                                    // </Tooltip>,
                                    // <Tooltip
                                    //     title={`Engagement Rate: ${new Intl.NumberFormat('el-GR', {maximumFractionDigits: 2}).format(feedItem.engagement_rate * 100)}%`}>
                                    //     <Space>
                                    //         <ThunderboltOutlined/>
                                    //         <span>{new Intl.NumberFormat('el-GR', {maximumFractionDigits: 2}).format(feedItem.engagement_rate * 100)}%</span>
                                    //     </Space>
                                    // </Tooltip>,
                                ]}
                            >
                                {/*<Meta description={*/}
                                {/*    <Typography.Paragraph type={"secondary"} ellipsis={{rows: 1, tooltip: true}}*/}
                                {/*                          style={{marginBottom: 0}}>*/}
                                {/*        {feedItem.caption}*/}
                                {/*    </Typography.Paragraph>*/}
                                {/*}/>*/}
                                {/*<Meta description={'dfes'}/>*/}

                            </Card>
                        ))}

                        <Button
                            icon={<RightOutlined/>}
                            // disabled={currentFeedPage === Math.ceil(reportData?.feed.length / POSTS_PAGE_SIZE)}
                            disabled={currentFeedPage === totalPages}
                            onClick={() => {
                                // if (currentFeedPage < Math.ceil(reportData?.feed.length / POSTS_PAGE_SIZE)) {
                                if (currentFeedPage < totalPages) {
                                    setCurrentFeedPage(currentFeedPage + 1);
                                }
                            }}
                        />
                    </Space>
                </Row>

            </Skeleton>

        </ModalForm>
    );
};

export default CreatorsLeadsSearchReportTiktok;