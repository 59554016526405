import React, {Component} from 'react';
import './index.css';
import {Alert, ConfigProvider, Divider, message, Row, Spin, Typography} from 'antd';
import {ProFormDatePicker, ProFormSelect, ProFormText, QueryFilter} from "@ant-design/pro-form";
import enUSIntl from 'antd/lib/locale/en_US';
import ProTable, {ProColumns} from '@ant-design/pro-table';
import {SearchOutlined} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import {authorize, initClient, isUserAuthorized} from '../../../common/gapiHandler';
import {fetchAnalytics} from '../../../api/ytAnalyticsReport';
import {cmsIdToName} from '../../../common/cmsInfo';
import {PageContainer} from "@ant-design/pro-components";

// var gapi: any;
// var GoogleAuth: any;


export interface IAnalyticsQueryEngineProps {
    //Here we pass the Props Interface
}

export interface IAnalyticsQueryEngineState {
    //here we pass the State Interface
    isAuthorized: boolean,
    isAuthorizing: boolean,
    isRequestLoading: boolean,
    responseData?: any,
    isGapiLibraryLoading: boolean,
    report?: any
}


//class ComponentName Component<PropsInterface, StateInterface>
class AnalyticsQueryEngine extends Component<IAnalyticsQueryEngineProps, IAnalyticsQueryEngineState> {

    constructor(props: IAnalyticsQueryEngineProps) {
        super(props)

        this.state = {
            isAuthorized: false,
            isAuthorizing: false,
            isRequestLoading: false,
            isGapiLibraryLoading: true,
        }

        // this.initClient = this.initClient.bind(this)
        // this.updateSigninStatus = this.updateSigninStatus.bind(this)
        // this.loadYTAPIClientInterfaces = this.loadYTAPIClientInterfaces.bind(this)
        // this.sendAuthorizedApiRequest = this.sendAuthorizedApiRequest.bind(this)
        // this.fetchAnalytics = this.fetchAnalytics.bind(this)
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        // Load the gapi (google apis) JS client library and the auth2 client
        let gapi = (window as any).gapi;
        // console.log('gapi', gapi);

        (window as any).gapi.load('client:auth2', () => initClient((success: boolean) => {
            console.log(success ? 'Loaded gapi client' : 'Could not load gapi client');
            this.setState({isGapiLibraryLoading: false})

            if (isUserAuthorized()) this.setState({isAuthorized: true})
            else this.setState({isAuthorized: false})

            if (!isUserAuthorized()) this.authorize()

            // if (success)  //test
            // 	fetchAnalytics({ startDate: '2021-05-01', endDate: '2021-05-20', filters: 'uploaderType==self', metrics: 'views' }, (res: any) => console.log(res))
        }))
    }


    authorize() {
        console.log('authorizing...');

        this.setState({isAuthorizing: true},
            () => authorize().then(() => this.setState({isAuthorized: true, isAuthorizing: false}))
        )
    }

    async queryHandler(requestData: any) {
        this.setState({isRequestLoading: true})
        // const res: any = await fetchAnalytics({...requestData, contentOwner: 'xcDgV1hLEnOLtNvdGppirg'})
        const res: any = await fetchAnalytics({...requestData})
        console.log('r', res);

        this.setState({isRequestLoading: false, responseData: res})
    }


    render() {

        let columns: ProColumns<any>[] = [];
        let tableDataSource: any[] = [];

        if (this.state.responseData && this.state.responseData.result) {

            columns = this.state.responseData.columnHeaders.map((col: { name: string, columnType: string, dataType: string }) => {
                return {
                    title: col.name,
                    dataIndex: col.name,
                    key: col.name,
                    // valueType: col.name === 'day' ? 'date' : 'text'
                    // valueType: col.dataType === 'INTEGER' ? 'number' : 'text',
                    align: 'right'
                    // width: 200,
                }
            })

            console.log('columns', columns);

            this.state.responseData.result.rows.forEach((row: any[], index: any) => {
                let obj: { [key: string]: any } = {}
                row.forEach((element: any, index: number) => {
                    // console.log(element);
                    obj[columns[index].dataIndex as string] = element
                });
                tableDataSource.push(obj)
            })

            console.log('tablesource', tableDataSource);

        }


        return (
            <div className="AnalyticsQueryEngine" style={this.style}>
                <PageContainer>
                    {/* <Button id='sign-in-or-out-button' onClick={this.handleAuthClick}>
						{this.state.isAuthorized ? 'Sign Out' : 'Sign in/Authorize'}
					</Button>

					<Button id='revoke-access-button' onClick={this.revokeAccess} style={{ display: this.state.isAuthorized ? 'inline-block' : 'none' }}>
						Revoke access
					</Button> */}


                    {/* <Button onClick={this.sendAuthorizedApiRequest}
					// style={{ display: this.state.isAuthorized ? 'inline-block' : 'none' }}
					>
						Execute query
					</Button> */}


                    <Typography.Title level={2}>Analytics Query Engine</Typography.Title>

                    <Alert
                        message={<><Text>Welcome to the Analytics Query Engine. Execute queries on various metrics and
                            dimensions to get cumulative reports.</Text> <br/>
                            <Text>Check the <a
                                href={'https://developers.google.com/youtube/analytics/content_owner_reports'}
                                target='_blank'>documentation</a> for instructions on composing a query. </Text></>}
                        type='info' closable/>

                    <br/>


                    {this.state.isGapiLibraryLoading &&
                    <Row justify='center'><Spin spinning={true} tip='Library loading...'></Spin></Row>}
                    {!this.state.isGapiLibraryLoading && this.state.isAuthorizing &&
                    <Row justify='center'><Spin spinning={true} tip='Authorizing...'></Spin></Row>}

                    {!this.state.isGapiLibraryLoading && this.state.isAuthorized &&
                    <>


                        <ConfigProvider locale={enUSIntl}>

                            {/* <ProForm */}
                            <QueryFilter
                                onFinish={async (values: any) => {
                                    this.queryHandler(values)
                                    console.log(values);
                                    message.success("Success");
                                }}
                                submitter={{
                                    searchConfig: {
                                        resetText: "Reset",
                                        submitText: <>Run Query <SearchOutlined/> </>
                                    }
                                }}
                                validateMessages={{required: "This field is required!"}}
                                initialValues={{
                                    startDate: '2021-02-22',
                                    endDate: '2021-05-22',
                                    metrics: 'views',
                                    dimensions: 'day',
                                    filters: 'uploaderType==self'
                                }}
                                collapsed={false}
                                collapseRender={false}
                            >
                                <ProFormDatePicker
                                    width="md"
                                    name="startDate"
                                    label="Start Date"
                                    placeholder="Select"
                                    rules={[{required: true}]}
                                />
                                <ProFormDatePicker
                                    width="md"
                                    name="endDate"
                                    label="End Date"
                                    placeholder="Select"
                                    rules={[{required: true}]}
                                />
                                {/* <ProFormText
								width="md"
								name="currency"
								label="currency"
								tooltip="3-letter ISO 4217 code"
								placeholder="Select"
							/> */}
                                <ProFormText
                                    width="md"
                                    name="metrics"
                                    label="Metrics"
                                    tooltip="A comma-separated list of YouTube Analytics metrics, such as views or likes,dislikes."
                                    placeholder="Select"
                                />
                                <ProFormText
                                    width="md"
                                    name="dimensions"
                                    label="Dimensions"
                                    tooltip="A comma-separated list of YouTube Analytics dimensions, such as video or ageGroup,gender."
                                    placeholder="Select"
                                    labelCol={{span: 8}}
                                />
                                <ProFormText
                                    width="md"
                                    name="filters"
                                    label="Filters"
                                    tooltip="A list of filters that should be applied when retrieving YouTube Analytics data."
                                    placeholder="Select"
                                />
                                <ProFormText
                                    width="md"
                                    name="sort"
                                    label="Sort"
                                    tooltip="A comma-separated list of dimensions or metrics that determine the sort order for YouTube Analytics data. By default the sort order is ascending. The - prefix causes descending sort order."
                                    placeholder="Select"
                                />
                                <ProFormSelect
                                    options={Object.entries(cmsIdToName).map((e) => {
                                        return {label: e[1], value: e[0]}
                                    })}
                                    name="contentOwner"
                                    label="CMS"
                                    placeholder="Select"
                                />
                            </QueryFilter>
                            {/* </ProForm> */}


                            <Divider/>

                            {/* <Card> */}

                            {this.state.isRequestLoading &&
                            <Row justify='center'>
                                <Spin spinning={true}></Spin>
                            </Row>
                            }

                            {!this.state.responseData && !this.state.isRequestLoading &&
                            <Alert message={'The results of the query will be displayed in this area.'} type='info'/>}

                            {this.state.responseData && this.state.responseData.error && !this.state.isRequestLoading &&
                            <Alert message={JSON.stringify(this.state.responseData.message, null, 2) || ''}
                                   type='error'/>}

                            {this.state.responseData && this.state.responseData.result && !this.state.isRequestLoading &&

                            <ProTable<any>
                                columns={columns}
                                rowKey="key"
                                pagination={{
                                    showQuickJumper: true,
                                }}
                                // search={false}
                                options={{
                                    // search: true,
                                    reload: false
                                }}
                                // beforeSearchSubmit={(params) => console.log(params)}
                                dateFormatter="string"
                                // dataSource={tableDataSource}
                                request={async (params, sort, filter) => {
                                    // console.log(params, sort, filter);

                                    let filteredSource = tableDataSource.slice()
                                    for (const param in params) {
                                        if (param === 'pageSize' || param === 'current') continue
                                        filteredSource = filteredSource.filter((row) => row[param] === params[param])
                                    }
                                    // console.log(filteredSource);

                                    return {
                                        // data: tableDataSource,
                                        data: filteredSource,
                                        success: true,
                                        // total: tableDataSource.length,
                                        total: filteredSource.length,
                                    }
                                }}
                            />
                            }

                            {/* </Card> */}
                        </ConfigProvider>
                    </>}


                </PageContainer>
            </div>
        );
    }
}

export default AnalyticsQueryEngine;