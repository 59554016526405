import {ModalForm} from '@ant-design/pro-components';
import * as React from 'react';
import {Typography} from "antd";
import axiosApiInstance from "../../../api/axiosClient";
import {useAtom, useAtomValue} from "jotai";
import {queryClientAtom} from "jotai-tanstack-query";
import {creatorsAtom} from "./atoms";

type Props = {
    creatorsIds: number[]
    onClearSelection?: any
};

export const DeleteBulkCreators = (props: Props) => {
    const queryClient = useAtomValue(queryClientAtom);
    const [{data: creators}] = useAtom(creatorsAtom);

    return (
        <ModalForm
            width={400}
            title={props.creatorsIds.length > 1 ? `Delete ${props.creatorsIds.length} Items` : "Delete 1 Item"}
            trigger={
                <Typography.Link
                    // type={props.batch ? "danger" : undefined}
                    type={"danger"}
                >{'Batch delete'}</Typography.Link>
            }
            submitter={{submitButtonProps: {danger: true}}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                // onCancel: () => console.log('run'),
                okText: 'Delete',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                return axiosApiInstance.delete('/api/creators/items', {data: {ids: props.creatorsIds}})
                    .then(res => {
                        // queryClient.invalidateQueries({queryKey: ['creators', 'items']})
                        queryClient.invalidateQueries({queryKey: ['creators']})
                        props.onClearSelection()
                        return true
                    }).catch(reason => false)
            }}
        >
            Are you sure?
        </ModalForm>
    );
};

export default DeleteBulkCreators