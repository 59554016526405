import React, {Component} from 'react';
import './index.css';
import {Button, Row, Spin} from 'antd';
import {Line, LineConfig} from '@ant-design/plots';
// import { LineConfig } from '@ant-design/charts/es/line';
import {ExportOutlined} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import {postRequest} from '../../../api/postRequest';
import moment from "moment";
import {roundNum} from "../../../common/utils";

export interface IAssetFinancialChartProps {
    //Here we pass the Props Interface
    asset_id: number;
    ppk_value: string;
    ppk_id: number;
}

export interface IAssetFinancialChartState {
    //here we pass the State Interface
    chartData?: any[];
    chartConfig?: LineConfig;
    chartRef?: any;
    isLoading: boolean;
}

interface AssetFinancialHistoryRow {
    // affiliateId: string;
    // affiliatePercentage: number;
    // affiliateShare: number;
    // assetId: string;
    // balance: number;
    client_euro: string;
    // commission: number;
    // customerId: number;
    date: string;
    dm_euro: string;
    // fullName: string;
    // id: number;
    // paid: string;
    // ppkValue: string;
    // rate: number;
    // tier: string;
    total_euro: string;
    // year: number;
}

//class ComponentName Component<PropsInterface, StateInterface>
class AssetFinancialChart extends Component<IAssetFinancialChartProps, IAssetFinancialChartState> {
    constructor(props: IAssetFinancialChartProps) {
        super(props);

        this.state = {
            isLoading: true,
            chartData: [],
            chartRef: React.createRef(),
        };

        // this.getFinancialHistoryOfAsset = this.getFinancialHistoryOfAsset.bind(this);
        this.fetchFinancials = this.fetchFinancials.bind(this);
    }

    //Add style here
    style = {
        // width: '100%'
        // flex: 1
    };

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.fetchFinancials();
    }

    fetchFinancials() {
        // chart
        //   const max = 13;
        //   const min = 3;
        //   const chartData = [
        //      { year: 'Mar 2018', value: Math.random() * max + min },
        //      { year: 'Jun 2018', value: Math.random() * max + min },
        //      { year: 'Sep 2018', value: Math.random() * max + min },
        //      { year: 'Dec 2018', value: Math.random() * max + min },
        //      { year: 'Mar 2019', value: Math.random() * max + min },
        //      { year: 'Jun 2019', value: Math.random() * max + min },
        //      { year: 'Sep 2019', value: Math.random() * max + min },
        //      { year: 'Dec 2019', value: Math.random() * max + min },
        //      { year: 'Mar 2020', value: Math.random() * max + min },
        //      { year: 'Jun 2020', value: Math.random() * max + min },
        //      { year: 'Sep 2020', value: Math.random() * max + min },
        //      { year: 'Dec 2020', value: Math.random() * max + min },
        //   ];

        postRequest('/api/erp/assethistory', {ppk_value: this.props.ppk_value, ppk_id: this.props.ppk_id})
            .then((res) => {
                let finHist: AssetFinancialHistoryRow[] = res.data || [];

                if (finHist.length === 0) {
                    // console.log('finHist empty', finHist);
                    this.setState({chartData: undefined, isLoading: false});
                    return;
                }
                console.log('finhist', finHist);
                console.log('finhist', parseFloat(finHist[0].client_euro));


                // let chartData: { datetime: string; dmEuro: number }[] = [];
                // let chartData: { datetime: Date; dmEuro: number }[] = [];
                let chartData: { datetime: string; euro: number; category: string }[] = [];
                finHist.forEach((element: AssetFinancialHistoryRow) => {
                    chartData.push({
                        datetime: new Date(element.date).toLocaleDateString('el'),
                        //   datetime: new Date(element.datetime),
                        euro: parseFloat(element.dm_euro),
                        category: 'DM',
                    });
                    chartData.push({
                        datetime: new Date(element.date).toLocaleDateString('el'),
                        //   datetime: new Date(element.datetime),
                        euro: parseFloat(element.client_euro),
                        category: 'Client',
                    });
                    chartData.push({
                        datetime: new Date(element.date).toLocaleDateString('el'),
                        //   datetime: new Date(element.datetime),
                        euro: parseFloat(element.total_euro),
                        category: 'Total',
                    });
                });
                // console.log('chartData', chartData);

                this.setState({chartData: chartData}, () => {
                    //    console.log('state.chartData', this.state.chartData);
                    const chartConfig: LineConfig = {
                        data: this.state.chartData!,
                        height: 400,
                        xField: 'datetime',
                        yField: 'euro',
                        seriesField: 'category',
                        point: {
                            // size: 2,
                            // size: 5,
                            // shape: 'dot',
                            // shape: 'diamond',
                        },
                        xAxis: {tickCount: 5},

                        slider: {
                            start: 0,
                            end: 1,
                        },

                        tooltip: {
                            customItems: originalItems => {
                                return originalItems.map((value, index, array) => {
                                    let newValue: any = value
                                    newValue.value = (roundNum(parseFloat(value.value.toString()), 3)?.toLocaleString('el-GR') || 0) + ' €'
                                    return newValue
                                })
                            }
                        },
                        appendPadding: [50, 0, 0, 0],
                        meta: {
                            month: {
                                formatter: value => moment(value, 'YYYY-MM-DD').format('MMM YY')
                            }
                        }
                    };

                    this.setState({chartConfig: chartConfig}, () => {
                        //   console.log('state.chartConfig', this.state.chartConfig);
                        this.setState({isLoading: false});
                    });
                });
            })
            .catch(() => {
                console.log('Error on POST.');
                this.setState({isLoading: false});
            });
    }

    // getFinancialHistoryOfAsset = async () => {
    //    var session_url = API + '/api/erp/assethistory';
    //    var username = 'dev';
    //    var password = '1234';

    //    let data = qs.stringify({
    //       asset: this.props.asset_id,
    //    });
    //    return axios.post(session_url, data, {
    //       headers: {
    //          Accept: '*/*',
    //          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    //       },
    //       auth: {
    //          username: username,
    //          password: password,
    //       },
    //    });
    // };

    render() {
        // console.log('state', this.state.isLoading, this.state.chartData);
        return (
            <div className="AssetFinancialChart" style={{width: '100%'}}>
                <Row style={{display: 'flex'}}>
                    {!this.state.isLoading && this.state.chartData !== undefined && (
                        <div style={{height: 500, width: '100%'}}>
                            {/* <Row > */}
                            <Row justify="end">
                                <Button
                                    style={{marginBottom: '16px'}}
                                    onClick={() => this.state.chartRef.current.downloadImage()}
                                    icon={<ExportOutlined/>}
                                >
                                    Export Chart
                                </Button>
                            </Row>
                            <Line {...this.state.chartConfig!} chartRef={this.state.chartRef}/>
                            {/* </Row> */}
                        </div>
                    )}
                    {this.state.isLoading && this.state.chartData !== undefined && <Row style={{height: 200}}>
                        {/*<Line loading={true} data={[]}/>*/}
                        <Row justify={"center"}> <Spin/> </Row>
                    </Row>
                    }
                    {!this.state.isLoading && this.state.chartData === undefined && (
                        // <Empty description={'No financial data found for asset.'} />
                        <Row style={{height: 100}}>
                            <Text type='secondary'>No financial data found for this asset.</Text>
                        </Row>
                    )}
                </Row>
            </div>
        );
    }
}

export default AssetFinancialChart;
