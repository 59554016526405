/*eslint eqeqeq: "off"*/
import {Component, SyntheticEvent} from 'react';
// import PDFReport from '../../../components/PDFReport';
import CustomerAssetsTable from '../../components/PPK_Datatable';
// import Performance from '../../components/Performance'
import './index.css';
import CustomerVault from '../../components/CustomerVault';
import {
    Button,
    Col,
    Collapse,
    Descriptions,
    message,
    notification,
    Result,
    Row,
    Space,
    Spin,
    Statistic,
    Tooltip,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    CheckCircleOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PushpinOutlined,
    QuestionCircleOutlined,
    SendOutlined,
} from '@ant-design/icons';
import EntityTags from '../../components/EntityTags';
import {validateVAT} from '../../../api/validateVAT';
import {ExtendedCustomerInfo} from '../../../common/models';
import {Link} from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import {postRequest} from "../../../api/postRequest";
import {PageContainer} from "@ant-design/pro-components";
import withRouter from "../../../components/withRouter";

interface IMatchParams {
    id: string;
}

// interface RouteComponentProps<P, R> {}

export interface ICustomerProps {
    params: any
}

export interface ICustomerState {
    //here we pass the State Interface
    data?: ExtendedCustomerInfo;
    isLoading: boolean;
    error: boolean;
    selectedTemplateID: string;
    parametersStr: string;
    //    generatePDF: boolean;
    pdfReportComp: JSX.Element | null;
    id: string;
    vat: any;
}

//class ComponentName Component<PropsInterface, StateInterface>
class Customer extends Component<ICustomerProps, ICustomerState> {
    constructor(props: ICustomerProps) {
        super(props);

        this.getFunc = this.getFunc.bind(this);
        this.refreshFolder = this.refreshFolder.bind(this);
        this.changeTemplate = this.changeTemplate.bind(this);

        this.state = {
            // data: {},
            isLoading: false,
            error: false,
            selectedTemplateID: '',
            parametersStr: '',
            //  generatePDF: false,
            pdfReportComp: null,
            id: this.props.params.id,
            vat: [],
        };
    }

    //Component State

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
        this.getFunc();
    }

    refreshFolder = () => {
        this.getFunc();
    };

    getFunc = async () => {
        this.setState({isLoading: true})
        postRequest('/api/customers/get/' + this.props.params.id)
            .then((response) => {
                // console.log('Authenticated');
                // const result = response.data as ExtendedCustomerInfo;
                const result = response.data || null
                // console.log(result);
                this.setState({data: result});
                this.setState({isLoading: false})
            })
            .catch((error) => {
                // this.setState({isLoading: false, error: true});
                this.setState({isLoading: false})
                console.log('Error on GET :' + error);
                notification.error({message: error.response.data.message})
            });
    };

    changeTemplate(e: SyntheticEvent<HTMLSelectElement, Event>) {
        this.setState({selectedTemplateID: e.currentTarget.value});
    }

    render() {
        if (this.state.error === true) {
            return (
                <PageContainer>
                    <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, this customer id doesn't exist in the database."
                        extra={
                            <Button type="primary" href="/crm">
                                Back to Customers
                            </Button>
                        }
                    />
                </PageContainer>
            );
        }

        if (this.state.isLoading) {
            return <Row justify={"center"}><Spin/></Row>
        }

        const PersonalDetails = [<></>];
        const ContactDetails = [<></>];
        const PaymentDetails = [<></>];
        const NotesDetails = [<></>];
        const CustomerHeader = [<></>];

        //IF FETCH
        if (this.state.data) {
            PersonalDetails.push(
                <>
                    <Descriptions title="Personal Details" bordered={false} column={4} layout="horizontal">
                        <Descriptions.Item label="First Name">
                            {this.state.data.first_name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last Name">
                            {this.state.data.last_name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Full Name">
                            {this.state.data.full_name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tier (Podio)">{this.state.data.tier || '-'}</Descriptions.Item>
                    </Descriptions>
                </>
            );

            ContactDetails.push(
                <>
                    <Descriptions title="Contact Info" bordered={false} column={5} layout="horizontal">
                        <Descriptions.Item label="Phone">{this.state.data.phone || '-'}</Descriptions.Item>
                        <Descriptions.Item label="Email">
                            <Space direction='horizontal'>
                                {this.state.data?.email || '-'}
                                <Tooltip title="Send email">
                                    <a
                                        href={'mailto:' + this.state.data.email.replace(' ', '')}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button shape="circle" icon={<SendOutlined/>} type='link' size='small'/>
                                    </a>
                                </Tooltip>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Address">
                            <Space>
                                {this.state.data.address || '-'}
                                <Tooltip title="Go to Maps">
                                    <a
                                        href={
                                            'https://www.google.com/maps/search/' +
                                            this.state.data.address.replace(' ', '+')
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button shape="circle" icon={<PushpinOutlined/>} type='link' size='small'/>
                                    </a>
                                </Tooltip>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Resid. Country">
                            {this.state.data.country || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Zipcode">{this.state.data.zipcode || '-'}</Descriptions.Item>
                    </Descriptions>
                </>
            );

            const contractIsValid = new Date(this.state.data.contract_end_date) >= new Date();

            PaymentDetails.push(
                <>
                    <Descriptions title="Payment Details" bordered={false} column={5} layout="horizontal">
                        <Descriptions.Item label="Fiscal Country">
                            {this.state.data.country || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="VAT">
                            <Space>
                                {this.state.data.vat || '-'}
                                <Tooltip title="Validate">
                                    <Button
                                        shape="circle"
                                        icon={<QuestionCircleOutlined/>}
                                        onClick={async () => {
                                            const response = await validateVAT(this.state.data!.vat.replace(' ', ''))
                                            // console.log('responseVAT', response);
                                            const result = response.data.validity;
                                            result !== 'true' ? message.error('VAT format is wrong.') : message.success('VAT format is correct.');
                                        }}
                                        type='link' size='small'
                                    />
                                </Tooltip>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Fiscal Currency">
                            {this.state.data.currency || '-'}
                        </Descriptions.Item>

                        <Descriptions.Item label="Payment Option">
                            {this.state.data.payment_option || '-'}
                        </Descriptions.Item>

                        {/*<Descriptions.Item label="Payment Destination">*/}
                        {/*    {this.state.data.payment_destination || '-'}*/}
                        {/*</Descriptions.Item>*/}

                        <Descriptions.Item label="Swift/Bic Code">
                            {this.state.data.swift_bic_code || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="IΒΑΝ Number">
                            {this.state.data.iban_number || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Bank Account Owner">
                            {this.state.data.bank_account_owner || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Paypal Email">
                            {this.state.data.paypal_email || '-'}
                        </Descriptions.Item>

                        <Descriptions.Item label="Contract End Date">
                            <Space>
                                {this.state.data.contract_end_date || '-'}
                                {this.state.data.contract_end_date &&
                                <Tooltip title="View Contract">
                                    <Link to={'/crm/contracts/view/' + this.state.data.contract_id}>
                                        {contractIsValid ?
                                            <Button shape="circle"
                                                    icon={<CheckCircleOutlined style={{color: '#3f8600'}}/>} type='link'
                                                    size='small'/>
                                            :
                                            <Button shape="circle"
                                                    icon={<ExclamationCircleOutlined style={{color: '#cf1322'}}/>}
                                                    type='link' size='small'/>
                                        }
                                    </Link>
                                </Tooltip>}
                            </Space>

                            {/* <Badge.Ribbon
                        text={contractIsValid ? <CheckOutlined /> : <ExclamationOutlined />}
                        color={contractIsValid ? 'green' : 'gold'}
                     >
                        From: {this.state.data.contract_start_date || '-'}
                        <br />
                        Till: {this.state.data.contract_end_date || '-'}
                        <br />
                        <br />
                        <Typography.Text type="secondary">
                           {contractIsValid ? 'This contract is valid.' : 'This contract has expired.'}
                        </Typography.Text>
                     </Badge.Ribbon> */}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            );

            NotesDetails.push(
                <>
                    <Descriptions title="Notes" bordered={true} column={1} layout="horizontal">
                        <Descriptions.Item>
                            {/* <Typography.Text></Typography.Text> */}
                            {this.state.data.notes || '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            );


            CustomerHeader.push(<>
                <Space size={50}>
                    {this.state.data?.full_name && <Statistic title="Full Name" value={this.state.data?.full_name}/>}
                    {this.state.data?.tier && <Statistic title="Tier (Podio)" value={this.state.data?.tier}/>}
                    {this.state.data?.email && <Statistic title="Email" value={this.state.data?.email}/>}
                    {this.state.data?.account_manager &&
                    <Statistic title="Account Manager (Podio)" value={this.state.data?.account_manager}/>}
                    {/*<Statistic title="Tags" valueRender={(_) => <Space wrap size="middle"><EntityTags entityId={this.state.id} entityDomain='_customer' editable={false} size='small' padding={0} /></Space>} />*/}
                    {/* {this.state.data?.platform === 'YOUTUBE' && this.state.data?.ppk_value.startsWith('UC') &&
                  <Statistic title="Channel Link"
                     valueRender={(_) => <Row justify='center'><a href={'https://youtube.com/channel/' + this.state.data?.ppk_value} target='_blank'><YoutubeFilled style={{ fontSize: 40, color: '#FF0000' }} /></a></Row>}
                  />
               }
               <Statistic title="Key"
                  valueRender={(_) => <Text>{this.state.data?.ppk_value}</Text>}
               /> */}
                </Space>
            </>)
        }

        return (
            <>
                <PageContainer>
                    <Spin spinning={this.state.isLoading} style={{minHeight: '50vh'}}>
                        {this.state.data && (
                            <>

                                <PageHeader
                                    className="site-page-header"
                                    onBack={() => window.history.back()}
                                    title={this.state.data.full_name || 'Customer ' + this.state.data?.id}
                                    subTitle={'Customer ID: ' + this.state.data?.id}
                                    extra={
                                        [
                                            <Link to={'/crm/customers/edit/' + this.props.params.id}>
                                                <Button key="edit" icon={<EditOutlined/>}>Edit</Button>
                                            </Link>
                                        ]
                                    }
                                >
                                    {/*
                        =============================
                        Contact Info And Payment Info
                        =============================
                        */}

                                    <Row>
                                        {CustomerHeader}
                                    </Row>
                                    <br/>
                                    <br/>
                                    <br/>

                                    <Row>
                                        <Title level={4}>Tags</Title>
                                    </Row>
                                    <br/>
                                    <EntityTags entityId={this.state.id} entityDomain='_customer' editable={false}
                                                size='small' padding={0}/>


                                    {/* <Row gutter={16}> */}
                                    {/* <Col span={8}>
                              {PersonalDetails}
                           </Col> */}
                                    {/* <Col span={8}>
                              {ContactDetails}
                           </Col> */}
                                    {/* <Col span={8}>
                              {PaymentDetails}
                           </Col> */}
                                    {/* </Row> */}
                                    <br/>

                                    {/*
                        ========
                        Products
                        ======== 
                        */}
                                    <Row>
                                        <Title level={4}>Products</Title>
                                    </Row>
                                    <CustomerAssetsTable
                                        customer_id={this.props.params.id}
                                        style={{width: '100%'}}
                                    />
                                    <br/>
                                    <br/>
                                    <br/>

                                    <Row>
                                        <Title level={4}>Customer Details</Title>
                                    </Row>
                                    <Collapse bordered={false} defaultActiveKey={[]}>
                                        <Collapse.Panel
                                            header="Personal Info, Contact Info, Payment Info - Click to expand"
                                            key="1">
                                            <br/>
                                            <Row>
                                                {PersonalDetails}
                                            </Row>
                                            <br/>

                                            <Row>
                                                {ContactDetails}
                                            </Row>
                                            <br/>

                                            <Row>
                                                {PaymentDetails}
                                            </Row>
                                            <br/>

                                            <Row>
                                                {NotesDetails}
                                            </Row>
                                        </Collapse.Panel>
                                    </Collapse>
                                    <br/>
                                    <br/>
                                    {/*
                        ========
                        PDF Generator
                        ======== 
                        */}
                                    {/* <Row gutter={32}>
                              <Col span={12}>
                                 <Descriptions title="PDF Reporter" bordered={false}>
                                    <Descriptions.Item>
                                       <PDFReportTester
                                          jsonInput={JSON.parse(
                                             JSON.stringify({
                                                client_name: `${this.state.data.full_name}`,
                                                company_name: 'Digital Minds LTD',
                                                copies: '3 (three)',
                                                date: `${new Date().getDay() +
                                                   '/' +
                                                   new Date().getMonth() +
                                                   ', ' +
                                                   new Date().getFullYear()
                                                   }`,
                                                location: 'Athens, Greece',
                                                logo:
                                                   'https://www.startup.gr/wp-content/uploads/2019/01/Digital_Minds_Logo_1051686708.png',
                                             })
                                          )}
                                       />
                                    </Descriptions.Item>
                                 </Descriptions>
                              </Col>
                              <Col span={12}>
                                 <Descriptions title="Tags" bordered={false}>
                                    <Descriptions.Item>
                                       <Space wrap size="middle">
                                          <EntityTags entityId={this.state.id} entityDomain='_customer' />
                                       </Space>
                                    </Descriptions.Item>
                                 </Descriptions>
                              </Col>
                           </Row> */}
                                    <br/>
                                    <br/>

                                    {/*
                        ========
                        Customer Vault
                        ======== 
                        */}
                                    <Row>
                                        <Col span={24}>
                                            {/* <Descriptions title="CRM File Vault" bordered={false} style={{ minWidth: '100%' }}> */}
                                            {/* <Descriptions.Item style={{ minWidth: '100%' }}> */}
                                            {/*<Space align={"baseline"}>*/}
                                            {/*    <Title level={4}>Customer Vault</Title>*/}
                                            {/*    <Tooltip title={"Synced with the customer portal"}><QuestionCircleOutlined/></Tooltip>*/}
                                            {/*</Space>*/}
                                            <CustomerVault id={this.state.id}/>
                                            {/*</Col>*/}
                                            {/*<Col span={12}>*/}
                                            {/*    /!* <Descriptions title="Uploader" bordered={false} style={{ minWidth: '100%' }}> *!/*/}
                                            {/*    /!* <Descriptions.Item style={{ minWidth: '100%' }}> *!/*/}
                                            {/*    <Title level={4}>Upload file to Vault</Title>*/}
                                            {/*    <UploadFileCrm id={this.state.id} handler={this.refreshFolder}/>*/}
                                        </Col>
                                    </Row>
                                </PageHeader>
                            </>
                        )}
                    </Spin>
                </PageContainer>
            </>
        );
    }
}

export default withRouter(Customer);
