import React, {Component} from 'react';
import './index.css';
import {Table} from 'antd';
import {getRequest} from "../../api/getRequest";

export interface IShowDataTableProps {
    //Here we pass the Props Interface
    call_url: string;
    style: Object;
}

export interface IShowDataTableState {
    //here we pass the State Interface
    rows: number;
    data: any;
    newvalue: string;
    newdescr: string;
    snackbar: any;
    style: Object;
    loading: boolean;
}

//class ComponentName Component<PropsInterface, StateInterface>
class ShowDataTable extends Component<IShowDataTableProps, IShowDataTableState> {
    constructor(props: IShowDataTableProps) {
        super(props);

        this.getFunc = this.getFunc.bind(this);

        this.state = {
            rows: 0,
            data: [],
            newvalue: '',
            newdescr: '',
            snackbar: '',
            style: this.props.style == null ? {} : this.props.style,
            loading: true,
        };
    }

    getFunc = async () => {
        getRequest(this.props.call_url)
            .then((response) => {
                console.log('Authenticated');
                console.log(response);
                this.setState({data: JSON.parse(response.data['rows'])});
                this.setState({rows: this.state.data['arr']['length'], loading: false});
            })
            .catch((error) => {
                this.setState({loading: false});
                console.log('Error on GET :' + error);
            });
    };

    // Before the component mounts, we initialise our state
    componentWillMount() {
        this.getFunc();
    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }

    columns: any = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 80,
            ellipsis: true,
            sorter: (a: any, b: any) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Value',
            dataIndex: 'value',
            width: 120,
            ellipsis: true,
            sorter: (a: any, b: any) => a.value.localeCompare(b.value),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Descr',
            dataIndex: 'descr',
            width: 90,
            ellipsis: true,
        },
    ];

    render() {
        return (
            <Table
                columns={this.columns}
                dataSource={this.state.data.arr}
                pagination={{showSizeChanger: false, pageSize: 5}}
                loading={this.state.loading}
            />
        );
    }
}

export default ShowDataTable;
