import {Component} from 'react';

import './index.css';
import {Avatar, Image, Row, Spin, Table} from 'antd';
import AssetFinancialChart from '../AssetFinancialChart';
import {Link} from 'react-router-dom';
import {ExtendedAssetInfo} from '../../../common/models';
import Text from 'antd/lib/typography/Text';
import {getRequest} from "../../../api/getRequest";

export interface IPPK_DatatableProps {
    //Here we pass the Props Interface
    customer_id: string;
    style: Object;
}

export interface IPPK_DatatableState {
    //here we pass the State Interface
    rows: number;
    data: ExtendedAssetInfo[];
    snackbar: any;
    style: Object;
    isLoading: boolean;
}


// const IMG_RESOLUTION: 'default' | 'medium' | 'high' = 'medium';
const ENABLE_IMG_RENDER: boolean = true;

class CustomerAssetsTable extends Component<IPPK_DatatableProps, IPPK_DatatableState> {
    constructor(props: IPPK_DatatableProps) {
        super(props);

        this.state = {
            rows: 0,
            data: [],
            snackbar: '',
            style: this.props.style === null ? {} : this.props.style,
            isLoading: true,
        };

        this.getFunc = this.getFunc.bind(this);
    }

    async getFunc() {
        try {
            // const res = await getCustomerAssets(+this.props.customer_id) // DEPR
            const res = await getRequest(`/api/assets/ppk/get/${+this.props.customer_id}`)
            if (!res.data) res.data = []
            const result = res.data as ExtendedAssetInfo[]
            console.log('ppk', this.props.customer_id, result);

            this.setState({rows: result.length, data: result, isLoading: false});
        } catch (error) {
            console.log('Error on GET :' + error);
            this.setState({rows: 0, data: [], isLoading: false});
        }

    }

    // Before the component mounts, we initialise our state
    componentWillMount() {
        // this.getFunc();
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getFunc();
    }

    render() {
        const columns: any = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                ellipsis: true,
                sorter: (a: any, b: any) => parseInt(a.id) - parseInt(b.id),
                sortDirections: ['ascend', 'descend'],
                render: (text: string, record: any) => {
                    return <Link to={'/crm/assets/view/' + text}>{text}</Link>
                }
            },
            // {
            //     title: 'Platform',
            //     dataIndex: 'platform',
            //     key: 'platform',
            //     // width: 120,
            //     ellipsis: true,
            //     render: (text: string, record: any) => {
            //         if (record.platform === 'YOUTUBE') {
            //             if (record.ppk_value.startsWith('UC')) {
            //                 return text + ' [CHANNEL]';
            //             } else {
            //                 return text + ' [MUSIC]';
            //             }
            //         } else {
            //             return text;
            //         }
            //     },
            // },
            {
                title: 'Asset Key',
                dataIndex: 'ppk_value',
                key: 'ppk_value',
                // width: 90,
                ellipsis: true,
                // filters: [],
                // sorter: (a: CustomerListRow, b: CustomerListRow) => a.tier.localeCompare(b.tier),
                // sortDirections: ['ascend', 'descend'],
                render: (text: string, record: any) => {
                    if (record.ppk_id == 1) { // is channel
                        return (
                            <a href={'https://www.youtube.com/channel/' + text} target="_blank" rel="noreferrer">
                                {text}
                            </a>
                        );
                    } else {
                        return text;
                    }
                },
            },
            {
                title: 'Asset Domain',
                dataIndex: 'asset_domain',
                // key: 'ppk_value',
                // width: 90,
                ellipsis: true,
            },
            {
                // title: '',
                dataIndex: 'image_url',
                key: 'image_url',
                render: (text: string, record: any) => {
                    // console.log(text, record);
                    if (!text) return null;
                    return <Avatar src={<Image placeholder src={text}/>}/>;
                    // return <Avatar src={text} />;
                },
            },
            {
                title: 'Commission',
                dataIndex: 'commission',
                key: 'commission',
                // width: 90,
                ellipsis: true,
                // filters: [],
                // sorter: (a: CustomerListRow, b: CustomerListRow) => a.tier.localeCompare(b.tier),
                // sortDirections: ['ascend', 'descend'],
            },
        ];


        this.state.data.forEach((element: any) => {
            element.key = element.id;
        });

        return (
            <>
                {/* {!this.state.isLoading && ( */}
                {this.state.data.length !== 0 ?
                    <Table
                        columns={columns}
                        dataSource={this.state.data}
                        loading={this.state.isLoading}
                        pagination={{pageSize: 5, hideOnSinglePage: true}}
                        expandable={{
                            expandedRowRender: (record) => <AssetFinancialChart asset_id={record.id}
                                                                                ppk_value={record.ppk_value!}
                                                                                ppk_id={record.ppk_id!}
                            />,
                        }}
                        expandRowByClick
                        rowClassName={'clickable-row'}
                        bordered={false}
                    />
                    : this.state.isLoading ?

                        <Row justify='center'> <Spin/></Row> :

                        <Row>
                            <Text type='secondary'>No associated products were found.</Text>
                        </Row>
                }
            </>
        );
    }
}

export default CustomerAssetsTable;
