import {Card, ConfigProvider, Space} from 'antd';
import React, {Component} from 'react';
import './index.css';
import enUSIntl from "antd/lib/locale/en_US";
import ProTable, {ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import moment from "moment";
import {FileTextOutlined} from "@ant-design/icons";


export interface IPaymentCycleLogsProps {
    //Here we pass the Props Interface
    cycle_id?: string
    pageSize?: number
    polling?: number
}

export interface IPaymentCycleLogsState {


}


type PaymentCycleLog = {
    id: number;
    cycle_id: string;
    message: string;
    creation_time: string;
    type: string;
};


//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleLogs extends Component<IPaymentCycleLogsProps, IPaymentCycleLogsState> {

    constructor(props: IPaymentCycleLogsProps) {
        super(props);


    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {

    }

    // After the component did mount, we set the state.
    componentDidMount() {
        // console.log('cycle logs', this.props.cycle_id);
    }

    columns: ProColumns<PaymentCycleLog>[] = [
        {
            title: 'Type', dataIndex: 'type', valueType: 'text', width: 120, align: "center",
            // valueEnum: row => ({
            //     // all: { text: 'processing', status: 'processing' },
            //     info: {
            //         // text: 'info',
            //         status: 'default',
            //     },
            //     success: {
            //         // text: 'done',
            //         status: 'success',
            //     },
            //     error: {
            //         // text: 'error',
            //         status: 'error',
            //     },
            // })
            valueEnum: row => ({
                // all: { text: 'processing', status: 'processing' },
                default: {
                    text: 'default',
                    status: 'default',
                },
                processing: {
                    text: 'processing',
                    status: 'processing',
                },
                success: {
                    text: 'success',
                    status: 'success',
                },
                error: {
                    text: 'error',
                    status: 'error',
                },
            })
        },
        {
            title: 'Creation time', dataIndex: 'creation_time', valueType: 'text', width: 160,
            render: (dom, entity, index) =>
                (moment(entity.creation_time, "YYYY-MM-DD kk:mm:ss").format("DD/MM/YYYY kk:mm:ss"))
        },
        {
            title: 'Message', dataIndex: 'message', valueType: 'text',
            // copyable: true
        },
    ];


    render() {


        return (
            <>

                {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}


                <ConfigProvider locale={enUSIntl}>

                    <Card bodyStyle={{padding: 0}}>

                        <ProTable<PaymentCycleLog>
                            columns={this.columns}
                            // actionRef={this.state.actionRef as any}
                            // cardBordered
                            // bordered
                            // size={"small"}
                            // style={{fontSize:6}}
                            showHeader={false}
                            // headerTitle={"Logs"}
                            polling={this.props.polling || 2000}
                            request={async (params = {}, sort, filter) => {
                                const res = await postRequest('/api/paymentCycles/getLogs/' + this.props.cycle_id)
                                const data = res.data || []
                                // console.log(res.data)
                                return {data, success: true, total: data.length}
                            }}
                            // rowKey={record => record.creation_time}
                            rowKey={'id'}
                            search={false}
                            locale={{emptyText: <Space direction={'vertical'}> <FileTextOutlined/> No logs</Space>}}
                            pagination={{pageSize: this.props.pageSize || 10, hideOnSinglePage: true}}
                            dateFormatter="string"
                            // toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                            toolBarRender={false}
                            // toolBarRender={() => []}
                        />
                    </Card>


                </ConfigProvider>

            </>
        );
    }
}

export default PaymentCycleLogs;