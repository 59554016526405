import * as React from 'react';
import {useState} from 'react';
import axiosApiInstance from "../../../api/axiosClient";
import {Button, notification, Tooltip} from "antd";
import {ModalForm} from '@ant-design/pro-components';
import {FileExcelFilled, FileExcelOutlined} from "@ant-design/icons";
import {UserAuth} from "../../../context/AuthContext";

type Props = {
    id?: number
};

const ExportCreatorLeadsExcel = (props: Props) => {

    const [exporting, setExporting] = useState(false)

    const exportExcel = () => {
        setExporting(true)
        notification.info({message: 'Operation started'})
        return axiosApiInstance.post(`/api/leads/items/export`, null, {
            // headers: {
            //     "Accept": "*/*",
            //     'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            //     Authorization: `Bearer ${access_token}`
            // },
            responseType: 'blob',
        })
            .then(res => {
                //download
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'creator_leads.xlsx');
                document.body.appendChild(link);
                link.click();

                // sleep for 1 second
                // setTimeout(() => {
                // }, 1000);

                notification.success({message: 'Exported successfully'})
                setExporting(false)
                return true

            }).catch(reason => {
                setExporting(false)
                return false
            })
    }

    return (
        <ModalForm
            width={400}
            title={"Export Creator Leads to excel?"}
            trigger={
                <Tooltip title={'Export to excel'}>
                    <Button type={"text"} icon={<FileExcelOutlined/>}
                        // icon={<DeleteOutlined/>}
                    />
                </Tooltip>
            }
            submitter={{submitButtonProps: {loading: exporting}}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                okText: 'Yes',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                return exportExcel()
            }}
        >
            {/*Export Creator Leads to excel?*/}
        </ModalForm>
    );
};

export default ExportCreatorLeadsExcel;