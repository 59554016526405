import {Button, ConfigProvider, notification, Row, Space} from 'antd';
import React, {Component} from 'react';
import './index.css';
import enUSIntl from "antd/lib/locale/en_US";
import ProTable, {ActionType, ProColumns} from "@ant-design/pro-table";
import {postRequest} from "../../../api/postRequest";
import {ClearOutlined, DownloadOutlined, FileTextOutlined, UploadOutlined} from "@ant-design/icons";
import CSVReader from "react-csv-reader";
import {auth} from "../../../config/firebase";
import axios from "axios";
import {API} from "../../../common/constants";
import withRouter from "../../../components/withRouter";


export interface IPaymentCycleRatesProps {
    //Here we pass the Props Interface
    params: any
    pageSize?: number
    polling?: false | number
}

export interface IPaymentCycleRatesState {
    data?: PaymentCycleRate[]
    actionRef: React.RefObject<ActionType>
}


type PaymentCycleRate = {
    cycle_id: string
    origin_currency_id: number
    rate_to_euro: number
    notes: string
};


//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycleRates extends Component<IPaymentCycleRatesProps, IPaymentCycleRatesState> {

    constructor(props: IPaymentCycleRatesProps) {
        super(props);

        this.state = {
            actionRef: React.createRef<ActionType>()
        }

    }

    //Add style here
    style = {};


    // Before the component mounts, we initialise our state
    componentWillMount() {

    }

    // After the component did mount, we set the state.
    componentDidMount() {

    }

    columns: ProColumns<PaymentCycleRate>[] = [
        {title: 'Origin Currency', dataIndex: 'origin_currency_id', valueType: 'digit'},
        {title: 'Rate to Euro', dataIndex: 'rate_to_euro', valueType: 'digit',},
        {title: 'Notes', dataIndex: 'notes', valueType: 'text',},
    ];

    onSubmit = async () => {
        if (!this.state.data) {
            notification.warning({message: 'No data loaded'})
            return
        }
        try {
            const res = await postRequest('/api/paymentCycles/uploadRatesInfo/' + this.props.params.id, this.state.data, false)
            console.log(res)
            notification.success({message: 'OK'})
            this.state.actionRef.current?.reload()

            this.onClear()
        } catch (e) {
            console.log(e)
            notification.error({message: 'Something went wrong'})
        }
    }

    onClear = () => {
        // @ts-ignore
        document.querySelector('.csv-input').value = ''
        this.setState({data: undefined})
    }

    downloadSample = async () => {
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(API + '/api/paymentCycles/sampleFiles/rates', null,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
            .then(res => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'sample_rates.csv');
                document.body.appendChild(link);
                link.click();

                notification.success({message: 'Success'})
            }).catch(reason => {
            console.log(reason)
            notification.error({message: 'Error'})
        })
    }


    render() {


        return (
            <>

                {/*<Typography.Title level={4}>Payment Cycle {this.props.cycle_id} Logs</Typography.Title>*/}
                <Row justify={"space-between"}>
                    <Space>
                        <CSVReader
                            parserOptions={{
                                header: true,
                                dynamicTyping: true,
                                transform(value: string, field: string | number): any {
                                    if (field == 'rate_to_euro') {
                                        // replace comma with dot
                                        return value.replace(',', '.')
                                    }
                                    return value
                                }
                            }}
                            // inputRef={instance => {
                            //     console.log(instance)
                            // }}
                            // cssInputClass={"ant-btn ant-btn-default"}
                            label={'Upload / Replace Rates: '}
                            onFileLoaded={(data, fileInfo, originalFile) => {
                                console.log(data, fileInfo, originalFile)
                                this.setState({data})
                            }}
                            onError={error => {
                                notification.error({message: error.message})
                            }}
                        />

                        {this.state.data &&
                        <>
                            <Button type={"default"} onClick={this.onSubmit}
                                    size={"middle"}>Load<UploadOutlined/></Button>
                            <Button type={"default"} danger onClick={this.onClear}
                                    size={"middle"}>Clear<ClearOutlined/></Button>
                        </>
                        }
                    </Space>
                    <Button icon={<DownloadOutlined/>} onClick={this.downloadSample}>Download Sample</Button>
                </Row>
                <br/>

                <ConfigProvider locale={enUSIntl}>

                    {/*<Card bodyStyle={{padding: 0}}>*/}

                    <ProTable<PaymentCycleRate>
                        columns={this.columns}
                        actionRef={this.state.actionRef as any}
                        // cardBordered
                        bordered
                        // size={"small"}
                        // style={{fontSize:6}}
                        // showHeader={false}
                        // headerTitle={"Logs"}
                        polling={this.props.polling || 0}
                        request={async (params = {}, sort, filter) => {
                            try {
                                const res = await postRequest('/api/paymentCycles/getRatesInfo/' + this.props.params.id)
                                const data = res.data || []
                                console.log(res.data)
                                return {data, success: true, total: data.length}
                            } catch (e) {
                                return {data: [], success: false, total: 0}
                            }
                        }}
                        rowKey="filename"
                        search={false}
                        locale={{
                            emptyText: <Space direction={'vertical'}> <FileTextOutlined/>No exported unmatched
                                files</Space>
                        }}
                        pagination={{pageSize: this.props.pageSize || 10, hideOnSinglePage: true}}
                        dateFormatter="string"
                        toolbar={{style: {marginBottom: 20, textAlign: "end"}}}
                        // toolBarRender={false}
                        // toolBarRender={() => []}
                    />
                    {/*</Card>*/}


                </ConfigProvider>

            </>
        );
    }
}

export default withRouter(PaymentCycleRates);