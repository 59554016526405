import {CheckCircleOutlined, EditOutlined, ExclamationCircleOutlined, LinkOutlined} from '@ant-design/icons';
import {Button, Card, Col, List, Row, Space, Spin, Statistic} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import Avatar from 'antd/lib/avatar/avatar';
import Title from 'antd/lib/typography/Title';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {getAssetsOfContract} from '../../../api/getAssetsOfContract';
import {AssetListRow, ExtendedContractInfo} from '../../../common/models';
import './index.css';
import {getRequest} from "../../../api/getRequest";
import {PageContainer} from "@ant-design/pro-components";
import withRouter from "../../../components/withRouter";

interface IMatchParams {
    id: string;
}

export interface IExtendedContractProps {
    //Here we pass the Props Interface
    params: any
}

export interface IExtendedContractState {
    //here we pass the State Interface
    data?: ExtendedContractInfo;
    isLoading: boolean;
    imgUrl?: string;
    channelTitle?: string;
    assets?: any
}

const IMG_RESOLUTION: 'default' | 'medium' | 'high' = 'high';
const ENABLE_IMG_RENDER: boolean = true;


//class ComponentName Component<PropsInterface, StateInterface>
class ExtendedContract extends Component<IExtendedContractProps, IExtendedContractState> {
    constructor(props: IExtendedContractProps) {
        super(props);

        this.state = {
            isLoading: true,
        };

        this.getFunc = this.getFunc.bind(this);
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getFunc();
    }

    getFunc = async () => {

        getRequest('/api/contracts/get/' + this.props.params.id)
            .then(async (response) => {
                console.log('Authenticated');
                // console.log('resas', response);
                // const result = response.data.arr[0] as ExtendedContract;
                const result = response.data as ExtendedContractInfo;
                // console.log('resExtendedContractInfo', result);

                const assets = (await getAssetsOfContract(+this.props.params.id)).data
                // console.log('resassets', assets);

                this.setState({
                    data: result,
                    isLoading: false,
                    assets
                });
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.log('Error on GET :' + error);
            });
    };

    render() {
        return (
            <div className="ExtendedContract" style={this.style}>
                <PageContainer>
                    <Spin spinning={this.state.isLoading}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => window.history.back()}
                            //  title={'Asset ' + this.state.data?.id}
                            //  subTitle={this.state.channelTitle}
                            title={this.state.channelTitle || 'Contract ' + this.state.data?.id}
                            subTitle={'Contract ID: ' + this.state.data?.id}
                            //  subTitle={'(' + this.state.data?.id + ')'}
                            extra={
                                [
                                    <Link to={'/crm/contracts/edit/' + this.props.params.id}>
                                        <Button key="edit" icon={<EditOutlined/>}>Edit</Button>
                                    </Link>
                                ]
                            }
                            //  avatar={{ src: this.state.imgUrl }}
                        >
                            <Row justify="space-between">
                                <Col span={20}>
                                    <Space size={50}>
                                        <Statistic title="Start Date" value={this.state.data?.start_date}/>
                                        <Statistic title="End Date" value={this.state.data?.end_date}/>
                                        <Statistic title="Customer ID" value={this.state.data?.customer_id}
                                                   formatter={(value) => value + ''}/>
                                        <Statistic title="# Of Linked Assets" value={this.state.data?.num_assets}/>
                                        {this.state.data?.end_date &&
                                        <Statistic title="Is Active"
                                                   valueStyle={new Date(this.state.data?.end_date) >= new Date() ? {color: '#3f8600'} : {color: '#cf1322'}}
                                                   prefix={new Date(this.state.data?.end_date) >= new Date() ?
                                                       <CheckCircleOutlined/> : <ExclamationCircleOutlined/>}
                                                   valueRender={(_) => <></>}
                                        />
                                        }
                                        {this.state.data?.contract_url &&
                                        <Statistic title="Preview Link"
                                                   prefix={<a href={this.state.data?.contract_url}
                                                              target='_blank'><LinkOutlined/></a>}
                                                   valueRender={(_) => <></>}
                                        />
                                        }
                                    </Space>
                                    <br/>
                                    <br/>

                                </Col>

                            </Row>
                            <br/>
                            <br/>
                            {/* <Title level={4}>Preview Link</Title>
                     <br />
                     <br /> */}
                            <Title level={4}>Linked Customer</Title>
                            <Card>
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar>{this.state.data?.customer_id}</Avatar>}
                                        // title={<a href="https://ant.design">{item.ppk_value}</a>}
                                        title={<a
                                            href={"/crm/customers/view/" + this.state.data?.customer_id}>{this.state.data?.full_name} (ID: {this.state.data?.customer_id})</a>}
                                        description={this.state.data?.tier}

                                    />
                                </List.Item>
                            </Card>
                            <br/>
                            <br/>
                            <Title level={4}>Linked Assets</Title>
                            <Card>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.assets}
                                    renderItem={(item: AssetListRow) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={item.image_url ? <Avatar src={item.image_url}/> :
                                                    <Avatar>{item.id}</Avatar>}
                                                // title={<a href="https://ant.design">{item.ppk_value}</a>}
                                                title={<a
                                                    href={"/crm/assets/view/" + item.id}>{item.ppk_value} (ID: {item.id})</a>}
                                                description={item.platform}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Card>
                            {/* </Row> */}
                        </PageHeader>
                        {/* <Row> */}
                    </Spin>
                </PageContainer>
            </div>
        );
    }
}

export default withRouter(ExtendedContract);
