import { PhoneOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const CallModal = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    console.log('searchParams: ', searchParams);
    return (
        <Modal
            title={'Call Number'}
            onOk={() => {
                const link = document.createElement('a');
                link.id = 'call_link';
                link.href = `sip:${searchParams.get('number')}`;
                link.click();
                navigate(`/social/connections`);
            }}
            onCancel={() => navigate(`/social/connections`)}
            okText='Call'
            okButtonProps={{ icon: <PhoneOutlined /> }}
            open
        >
            <Typography.Text>Will you call the following number?</Typography.Text>
            <br />
            <Typography.Text italic>{`${searchParams.get('number')} - ${searchParams.get(
                'name'
            )}`}</Typography.Text>
        </Modal>
    );
};

export default CallModal;
