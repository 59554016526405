import * as React from 'react';
import {useContext} from 'react';
import axiosApiInstance from "../../../api/axiosClient";
import {GlobalStateContext} from "../../../context/GlobalContext";
import {Button} from "antd";
import {ModalForm} from '@ant-design/pro-components';
import {DeleteOutlined} from "@ant-design/icons";
import {queryClientAtom} from "jotai-tanstack-query";
import {useAtomValue} from "jotai";

type Props = {
    id?: number
};

const DeleteCreator = (props: Props) => {
    const queryClient = useAtomValue(queryClientAtom);

    return (
        <ModalForm
            width={400}
            title={"Delete Creator"}
            trigger={
                <Button type={"text"} icon={<DeleteOutlined/>} danger
                    // icon={<DeleteOutlined/>}
                />
            }
            submitter={{submitButtonProps: {danger: true}}}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                okText: 'Delete',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                return axiosApiInstance.delete(`/api/creators/items/${props.id}`)
                    .then(res => {
                        // console.log(res)
                        // queryClient.invalidateQueries({queryKey: ['creators','items']})
                        queryClient.invalidateQueries({queryKey: ['creators']})
                        return true
                    }).catch(reason => false)
            }}
        >
            Are you sure?
        </ModalForm>
    );
};

export default DeleteCreator;