import * as React from 'react';
import dayjs from "dayjs";
import XLSX from "sheetjs-style-v2";
import {useAtom} from "jotai";
import {
    channelsAtom,
    monthAtom,
    monthlyViewsAtom,
    rankingViewsAtom,
    showCompareCategoriesModalAtom,
    showMonthModalAtom,
    viewTypeAtom,
    yearAtom,
    yearlyViewsAtom
} from "./atoms";
import {Button, Tooltip} from "antd";
import {ExportOutlined} from "@ant-design/icons";

type Props = {};

export const ExportToExcel = (props: Props) => {
    const [viewType, setViewType] = useAtom(viewTypeAtom);
    const [year, setYear] = useAtom(yearAtom);
    const [month, setMonth] = useAtom(monthAtom);
    const [showMonthModal, setShowMonthModal] = useAtom(showMonthModalAtom);
    const [showCompareCategoriesModal, setShowCompareCategoriesModal] = useAtom(showCompareCategoriesModalAtom)
    // const channelCategories = useAtomValue(channelCategoriesAtom)
    // const [filterChannelCategories, setFilterChannelCategories] = useAtom(filterChannelCategoriesAtom)
    const [channels, refreshChannels] = useAtom(channelsAtom)
    const [monthlyViews, refreshMonthlyViews] = useAtom(monthlyViewsAtom)
    const [yearlyViews, refreshYearlyViews] = useAtom(yearlyViewsAtom)
    const [rankingViews, refreshRankingViews] = useAtom(rankingViewsAtom)

    const buildAndDownloadXLSX = () => {

        let data: any
        let title: string = 'YouTube_Benchmarks_'

        if (viewType === 'month') {
            data = [...monthlyViews.data,
                {
                    ...monthlyViews.avg,
                    channelName: 'Average',
                    channelID: '',
                }]

            title += `Month_${dayjs(month.toString(), 'M').format('MMM')}_${year}.xlsx`
        } else if (viewType === 'year') {
            data = [...yearlyViews.data,
                {
                    ...yearlyViews.avg,
                    month: 'Average',
                }]

            title += `Year_${year}.xlsx`
        } else if (viewType === 'ranking') {
            data = rankingViews.map((item, index) => {
                return {
                    ...item,
                    rank: index + 1
                }
            })
            title += `Ranking_${dayjs().format('DD_MM_YYYY')}.xlsx`
        } else if (viewType === 'channels') {
            data = channels.map((item, index) => {
                return {
                    id: item.id,
                    name: item.name,
                    categories: item.categories.map((category) => category.category).join(';'),
                }
            })
            title += `Channels.xlsx`
        }

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {dateNF: 'dd/mm/yyyy', cellDates: true});
        // const wb: XLSX.WorkBook = {Sheets: {[viewType]: ws}, SheetNames: [capitalize(viewType)]};
        const wb: XLSX.WorkBook = {Sheets: {Report: ws}, SheetNames: ['Report']};
        XLSX.writeFile(wb, title, {
            bookType: 'xlsx',
            type: 'array'
        });

    };

    return (
        <Tooltip title={'Export to Excel'}>
            <Button
                size={'large'}
                type={'text'}
                icon={<ExportOutlined/>}
                onClick={() => {
                    buildAndDownloadXLSX();
                }}
            />
        </Tooltip>
    );
};

export default ExportToExcel;