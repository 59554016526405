import {AppstoreAddOutlined, CheckOutlined, LoadingOutlined} from '@ant-design/icons';
import {Alert, Button, message, notification, Row, Space, Spin, Table} from 'antd';

import {TableRowSelection} from 'antd/lib/table/interface';
import Text from 'antd/lib/typography/Text';
import React, {Component} from 'react';
import {executeQuery} from '../../../api/executeQuery';
import {GetCycleFolderResponse} from '../../../common/models';

import './index.css';
import {postRequest} from "../../../api/postRequest";

export interface ILoadStepProps {
    //Here we pass the Props Interface
    handler?: Function,
    year: number,
    id: number,
    type_cycle: number,
}

export interface ILoadStepState {
    //here we pass the State Interface
    year: number,
    id: number,
    type_cycle: number,

    selectedRowKeys: any[],
    data: FileListRow[],
    loadedFiles: any[],
    rows: number,
    proceed: boolean,
    loading: boolean
    unloading: boolean

    selectedRate?: string,

    rates?: any[],
    // rate: any,
    unloadVisible: boolean
    time?: string

}


interface FileListRow {
    key: number,
    filepath: string,
    loaded: boolean,
    num_rows: number,
    platform: 'Youtube' | "MD" | "Spotify" | "Unknown"
}

const LOADED_TEXT = 'Loaded'
const NOT_LOADED_TEXT = 'Not Loaded'

//class ComponentName Component<PropsInterface, StateInterface>
class LoadStep extends Component<ILoadStepProps, ILoadStepState> {

    constructor(props: ILoadStepProps) {
        super(props)

        this.getFunc = this.getFunc.bind(this)
        this.proceedStep = this.proceedStep.bind(this)
        this.selectChange = this.selectChange.bind(this)
        this.state = {
            id: this.props.id,
            year: this.props.year,
            type_cycle: this.props.type_cycle,
            data: [],
            loadedFiles: [],
            // rate: React.createRef(),
            selectedRowKeys: [],
            rows: 0,
            loading: false,
            unloading: false,
            unloadVisible: false,
            proceed: true,
        }
    }


    //Add style here
    style = {};

    proceedStep() {
        // message.info(<>Files loaded : {this.state.loadedFiles.length}</>)
        // notification.info({ message: 'There are ' + (this.state.loadedFiles.length) + " available files to load.", key: 'available' });
        notification.info({message: 'Files loaded: ' + this.state.loadedFiles.length, key: 'load'});


        this.setState({proceed: true});

        postRequest(`/api/erp/newcycle/proceedcycle`, {
            id: this.state.id,
            year: this.state.year,
            step: "2"
        })
            .then((response) => {
                console.log('Authenticated');
                console.log(response)
                this.props.handler ? this.props.handler() : window.location.reload()
            }).catch(function (error) {
            console.log('Error on GET :' + error);
        });
    }

    getDropdowns = async () => {
        interface RateListRow {
            rate: string;
            currency_origin: string;
            currency_origin_extra: string;
            currency_destination: string;
            currency_destination_extra: string;
            date: string;
        }

        try {
            const response = await executeQuery("GET_RATES")
            const rates = response.data as RateListRow[] || []

            let result: { label: string; value: any; }[] = []
            rates.forEach((element) => {
                result.push({
                    label: element.rate + " [" + element.currency_origin + "(" + element.currency_origin_extra + ")" + " → " + element.currency_destination + "(" + element["currency_destination_extra"] + ")" + "] " + "[" + element.date + "]",
                    value: element.rate
                })
            });
            // console.log('rate',result)
            this.setState({rates: result})

            this.setState({selectedRate: result[0].value})
            // this.state.rate.current?.setFieldsValue({ value: result[0] })


        } catch (error) {
            console.log('Error on GET RATES :' + error);
            message.error('Error on GET RATES :' + error);
        }

    };

    getFunc = async () => {

        postRequest(`/api/erp/newcycle/cyclefolder`, {
            id: this.state.id,
            year: this.state.year
        })
            .then((response) => {
                console.log('Authenticated');
                console.log(response)

                let result = response.data.result as GetCycleFolderResponse[] || []
                let loaded: any[] = []
                let proceed = true


                let data: FileListRow[] = []
                for (let i = 0; i < result.length; i++) {
                    data.push({
                        key: i, filepath: result[i].filepath, loaded: result[i].is_loaded, num_rows: result[i].num_rows,
                        platform: result[i].filepath.includes("Youtube") ? "Youtube" : result[i].filepath.includes("MD") ? "MD" : result[i].filepath.includes("Spotify") ? "Spotify" : "Unknown"
                    })
                    if (data[i].loaded === true && data[i].platform !== "Unknown") {
                        proceed = false

                        loaded.push(data[i])
                    }


                }
                // alert(proceed)

                console.log('data', data)

                this.setState({rows: data.length, data, loadedFiles: loaded, proceed: proceed})


                if (data.length > 0) {
                    if (data.length - loaded.length > 0) {
                        notification.info({
                            message: 'There are ' + (data.length - loaded.length) + " available files to load.",
                            key: 'available'
                        });
                    } else {
                        notification.info({message: 'All files are loaded.', key: 'available'});

                    }
                } else {
                    message.warning("Files for loading not found!");
                }
                // if (loaded.length > 0) { message.info({ content: (loaded.length) + " files are already loaded.", key: 'loading' }) }

            }).catch(function (error) {
            console.log('Error on GET :' + error);
        });
    }

    selectChange = (selectedRowsKeys: any) => {
        // console.log('selectedRowsKeys', selectedRowsKeys);
        this.setState({selectedRowKeys: selectedRowsKeys})
    }

    componentWillMount() {
        this.getFunc()
        this.getDropdowns()
    }


    loadFiles = async () => {
        let error = 0

        this.setState({loading: true})

        if (this.state.selectedRowKeys.length < 1) {
            message.warning(<>Please select files to load</>)
            return
        }

        // var rate = this.state.rate.current?.getFieldsValue()["rate"]
        var rate = this.state.selectedRate

        let files_to_load: string[] = []
        this.state.selectedRowKeys.forEach(element => {

            if (!this.state.data[element].loaded) {
                files_to_load.push(this.state.data[element].filepath)
            }

            if (rate == undefined && this.state.data[element].filepath.includes("Youtube")) {
                error += 1;
                return
            }
        });

        let files_to_load_joined = files_to_load.join()

        if (error > 0) {
            message.warning("Rate cannot be empty with Youtube files!")
            this.setState({loading: false})
            return
        }

        // message.loading({ content: "Please wait till loading is finished.", key: 'loading' });

        postRequest(`/api/erp/newcycle/loadfile`, {
            list: files_to_load_joined,
            id: this.state.id,
            year: this.state.year,
            // rate: rate,
        })
            .then((response) => {
                console.log('Authenticated');
                console.log('loadfile', response)

                let time = response.data.time
                this.setState({time})

                // message.success("res:" + res)
                notification.success({
                    message: 'Successfully loaded ' + (this.state.selectedRowKeys.length) + " files. (" + time + ")",
                    key: 'load'
                });

                this.setState({loading: false, selectedRowKeys: []})

                this.props.handler ? this.props.handler() && this.getFunc() : message.error("Error function call")


            }).catch(function (error) {
            console.log('Error on GET :' + error);
            message.error(error)
        });

    }

    // rateSelect(e: any) {
    // 	// alert(e)
    // 	this.setState({ rate: e })
    // }

    unloadFile = async (filepath: string) => {
        // alert(this.state.selectedRowKeys)
        // var filepath = filename.replace("LOADED", "")

        // message.warn('Unloading...');

        this.setState({unloading: true})

        postRequest(`/api/erp/newcycle/unloadfile`, {
            filename: filepath,
            id: this.state.id,
            year: this.state.year,
        })
            .then((response) => {
                // console.log('Authenticated');
                // console.log(response.data)

                this.setState({unloading: false})
                const num_files = filepath.split(',').length
                const files_literal = num_files > 1 ? 'files' : 'file'
                notification.success({
                    message: 'Successfully unloaded ' + num_files + " " + files_literal,
                    key: 'unload'
                });

                // window.location.reload();
                this.getFunc()

            }).catch((error) => {
            this.setState({unloading: false})
            console.log('Error on GET :' + error);
            message.error(error)
        });

    }


    unloadAllLoadedFiles = async () => {
        // alert(this.state.selectedRowKeys)
        // var filepath = filename.replace("LOADED", "")

        const allfiles = this.state.loadedFiles.map((f) => f.filepath).join()
        // console.log('allfiles', allfiles);
        if (allfiles === '') {
            message.warning('No loaded files.');
            return
        }

        this.unloadFile(allfiles)

    }

    onRateChange(value: string) {
        this.setState({selectedRate: value})
    }


    render() {
        const columns = [
            {
                title: "Filepath",
                dataIndex: "filepath",
                render: (text: string) => {
                    text = text.substring(text.indexOf('ERP_VAULT') + 9, text.lastIndexOf('/') + 1) + '\r\n' + text.substring(text.lastIndexOf('/') + 1)
                    // text = String(text).substring(String(text).indexOf('ERP_VAULT') + 9)
                    return text
                },
                sorter: (a: { filepath: string | any[]; }, b: { filepath: string | any[]; }) => a.filepath.length - b.filepath.length,
                // ellipsis: true,
                // colSpan: 4,
                width: 700
            },
            {
                title: "Platform",
                dataIndex: "platform",
                filters: [
                    {
                        text: "Youtube",
                        value: "Youtube",
                        multiple: 1,

                    },
                    {
                        text: "MD",
                        value: "MD",
                        multiple: 2,
                    },
                    {
                        text: "Spotify",
                        value: "Spotify",
                        multiple: 3,
                    },
                    {
                        text: "Unknown",
                        value: "Unknown",
                        multiple: 4,
                    }
                ],
                render: (text: any) => <p>{text}</p>,
                onFilter: (value: any, record: any) => record.platform === value,
            },
            {
                title: "Load Status",
                dataIndex: "loaded",
                filters: [
                    {
                        text: LOADED_TEXT,
                        value: true,
                        multiple: 1,

                    },
                    {
                        text: NOT_LOADED_TEXT,
                        value: false,
                        multiple: 2,
                    }
                ],
                render: (loaded: boolean, record: FileListRow) => {
                    if (this.state.selectedRowKeys.includes(record.key) && this.state.loading)
                        return <> <Spin indicator={antIcon}/></>
                    else if (loaded === true)
                        return <>
                            {/* <Space direction='vertical' size={[0,0]} > */}
                            <p style={{color: "green"}}><CheckOutlined/> {LOADED_TEXT}</p>
                            <Text type='secondary' style={{fontSize: 12}}>{record.num_rows} records</Text>
                            {/* </Space> */}
                        </>
                    else return <><p style={{color: "orange"}}><AppstoreAddOutlined/> {NOT_LOADED_TEXT}</p> </>
                },
                onFilter: (value: any, record: FileListRow) => record.loaded === value,
            },
            {
                title: "Action",
                render: (_: any, record: FileListRow) => record.loaded ?
                    <Button type={"dashed"} onClick={() => this.unloadFile(record.filepath)}>Unload</Button> : "",
            },
        ]


        const rowSelection: TableRowSelection<any> = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.selectChange,
            getCheckboxProps: (data: FileListRow) => ({
                disabled: data.loaded,
            }),
        };

        const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

        // const rateSelect = <Form ref={this.state.rate}>
        // 	<Form.Item label="Rate:" name="rate" >
        // 		<Select
        // 			showSearch
        // 			allowClear
        // 			style={{ width: "300px" }}
        // 			placeholder="Select rate..."
        // 			optionFilterProp="label"
        // 			options={this.state.rates}
        // 			filterOption={(inputValue, option) =>
        // 				option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
        // 			}
        // 		></Select>
        // 	</Form.Item>
        // </Form>

        // const rateSelect = <Select
        // 	showSearch
        // 	allowClear
        // 	style={{ width: "300px" }}
        // 	placeholder="Select rate..."
        // 	optionFilterProp="label"
        // 	options={this.state.rates}
        // 	filterOption={(inputValue, option) =>
        // 		option?.label?.toString().toLowerCase().indexOf(inputValue.toLowerCase())! >= 0
        // 	}
        // 	onChange={this.onRateChange}
        // 	value={this.state.selectedRate}
        // />


        return (
            <>
                <br/>
                <Alert message='Please select the files you want to load for the current billing cycle.' type='info'
                       showIcon closable/>
                <br/>

                <Row justify="space-between" align='middle'>
                    <Space>
                        <Button type='primary' disabled={this.state.selectedRowKeys.length === 0} onClick={() => {
                            this.loadFiles()
                        }} loading={this.state.loading}>Load Selected Files</Button>
                        {this.state.selectedRowKeys.length == 0 && <Text type='secondary'>No files are selected.</Text>}
                    </Space>

                    <Space>
                        <Button type='default' danger disabled={this.state.loadedFiles.length === 0} onClick={() => {
                            this.unloadAllLoadedFiles()
                        }} loading={this.state.unloading}>Unload All</Button>
                        {this.state.loadedFiles.length == 0 && <Text type='secondary'>No loaded files.</Text>}
                    </Space>


                    {/* {rateSelect} */}
                </Row>
                <br/>
                {this.state.time && <><Alert message={'Elapsed time: ' + this.state.time} type='info' showIcon
                                             closable/><br/></>}

                <Row>
                    <Table style={{width: "100%"}} dataSource={this.state.data} columns={columns}
                           rowSelection={rowSelection}
                           loading={this.state.unloading}
                    />
                </Row>

                <Row justify="end" align="middle">
                    <Button type={"primary"} onClick={() => this.proceedStep()} disabled={this.state.proceed}>Next
                        Step</Button>
                </Row>
            </>
        );
    }


}

export default LoadStep;