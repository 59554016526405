import ProTable, { ProColumns } from '@ant-design/pro-table';
import React from 'react';
import axiosApiInstance from '../../../../api/axiosClient';
import {
    AppleOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SpotifyOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Button, Form, Row, Space, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    ModalForm,
    ProFormCheckbox,
    ProFormGroup,
    ProFormList,
    ProFormSelect,
    ProFormText
} from '@ant-design/pro-form';
import { atom, useAtom } from 'jotai';

const isAddNewArtistModalOpen = atom(false);

const NewArtist = () => {
    const [form] = Form.useForm<API.MusicDistribution.Artist>();
    const [isOpen, setIsOpen] = useAtom(isAddNewArtistModalOpen);
    return (
        <ModalForm<API.MusicDistribution.Artist>
            title='Add new artist'
            width={1000}
            form={form}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            trigger={
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    style={{ width: '100%' }}
                    onClick={() => setIsOpen(true)}
                >
                    Add
                </Button>
            }
            modalProps={{
                destroyOnClose: true,
                onCancel: () => setIsOpen(false)
            }}
            onFinish={async (values) => {
                await axiosApiInstance.post('/api/products/artists/', values);
                // stepFormRef.current?.resetFields(['main_artist_id']);
                return true;
            }}
            open={isOpen}
        >
            <ProFormText
                label='Key Name'
                name='key_name'
                required
                rules={[
                    {
                        required: true
                    }
                ]}
            />
            <ProFormText name='spotify_id' label='Spotify ID' initialValue={undefined} />
            <ProFormText name='apple_id' label='Apple ID' initialValue={undefined} />
            <ProFormList<API.MusicDistribution.Name>
                name='names'
                initialValue={[
                    {
                        full_name: ''
                    }
                ]}
                min={1}
                // actionRef={actionRef}
                colProps={{ span: 24 }}
                creatorButtonProps={{
                    position: 'bottom',
                    creatorButtonText: 'New Name'
                }}
            >
                {(f, index, action) => (
                    <ProFormGroup align='center' direction='horizontal' rowProps={{ justify: 'start' }}>
                        <ProFormText
                            name='full_name'
                            label='Full Name'
                            placeholder={'Full Name'}
                            width='md'
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                            required
                        />
                        <ProFormSelect
                            name='full_name_language'
                            label='Language'
                            mode='single'
                            width='xs'
                            // options={countryOptions}
                            options={[
                                {
                                    label: '🇬🇷',
                                    value: 'GR'
                                },
                                {
                                    label: '🇬🇧',
                                    value: 'EN'
                                }
                            ]}
                        />
                        <ProFormCheckbox
                            key={'is_default'}
                            name={'is_default'}
                            label='Default?'
                            initialValue={index === 0}
                        />
                        <ProFormCheckbox key={'is_stage_name'} name={'is_stage_name'} label='Stage Name?' />
                    </ProFormGroup>
                )}
            </ProFormList>
        </ModalForm>
    );
};

const ArtistsTable = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const columns: ProColumns<API.MusicDistribution.Artist>[] = [
        {
            title: '',
            key: 'icon',
            search: false,
            hideInSearch: true,
            hideInForm: true,
            render: () => <UserOutlined />
        },
        {
            title: 'Artist',
            key: 'Artist',
            dataIndex: 'key_name'
            // search: true,
        },
        {
            title: (
                <Row align='middle'>
                    <SpotifyOutlined style={{ marginRight: '4px' }} />
                    <Typography.Text>Spotify ID</Typography.Text>
                </Row>
            ),
            key: 'Spotify',
            dataIndex: 'spotify_id',
            search: false
        },
        {
            title: (
                <Row align='middle'>
                    <AppleOutlined style={{ marginRight: '4px' }} />
                    <Typography.Text>Apple ID</Typography.Text>
                </Row>
            ),

            key: 'Apple',
            dataIndex: 'apple_music_id',
            search: false
        },
        {
            title: 'Completed',
            key: 'Completed',
            dataIndex: 'is_completed',
            filterMode: 'menu',
            render(_dom, entity) {
                if (entity.is_completed) {
                    return (
                        <CheckCircleOutlined
                            style={{
                                color: 'green'
                            }}
                        />
                    );
                }
                return (
                    <CloseCircleOutlined
                        style={{
                            color: 'red'
                        }}
                    />
                );
            }
        },
        {
            title: '',
            key: 'actions',
            search: false,
            render(_dom, entity) {
                return (
                    <>
                        <Button
                            type='link'
                            icon={<EditOutlined />}
                            onClick={() =>
                                navigate(`/music/artists/${entity.key_name}/edit`, {
                                    state: { background: location }
                                })
                            }
                        />
                        <Button
                            type='link'
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => {
                                navigate(`/music/artists/${entity.key_name}/delete`, {
                                    state: { background: location }
                                });
                            }}
                        />
                    </>
                );
            }
        }
    ];
    return (
        <ProTable
            columns={columns}
            search={false}
            request={(params) => axiosApiInstance.get('/api/products/artists/')}
            rowKey='key_name'
            toolBarRender={() => [<NewArtist />]}
        />
    );
};

export default ArtistsTable;
