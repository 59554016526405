import React, {Component} from 'react';
import './index.css';
// import { Grid, GridRow, GridCell, List, TextField, Button } from 'rmwc';
import {Button, FormInstance, message, Modal, Row, Typography,} from 'antd';
import NewCustomerForm from '../../components/NewCustomerForm';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {postRequest} from "../../../api/postRequest";
import {PageContainer} from "@ant-design/pro-components";

const {confirm} = Modal;
const {Title} = Typography;


export interface INewCustomerProps {
    //Here we pass the Props Interface
    edit?: boolean
    id?: number
}

export interface INewCustomerState {
    //here we pass the State Interface
    formRef: React.RefObject<FormInstance>;
    changeOccurred: boolean
}

//class ComponentName Component<PropsInterface, StateInterface>
class NewCustomer extends Component<INewCustomerProps, INewCustomerState> {
    constructor(props: INewCustomerProps) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onValidate = this.onValidate.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onFormValuesChange = this.onFormValuesChange.bind(this);

        //Component State
        this.state = {
            formRef: React.createRef<FormInstance>(),
            changeOccurred: false
        };
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        if (this.props.edit) {

        }
    }

    onFormValuesChange() {
        this.setState({changeOccurred: true})
    }


    onSubmit(e: any) {
        e.preventDefault();
        // console.log('from submit data', this.state.formRef.current?.getFieldsValue());
        // return

        // warn if wrong data, but user can proceeed if they want
        this.state.formRef.current
            ?.validateFields()
            .then((result) => {
                if (this.props.edit) {
                    this.editCustomer()
                } else {
                    this.postNewCustomer()
                }
            })
            .catch((err) => {
                // message.error({content: 'Not valid!', key: 'validate'});
                confirm({
                    title: 'Do you want to proceed anyway?',
                    icon: <ExclamationCircleOutlined/>,
                    content: 'Some field values are not of the appropriate type.',
                    onOk: () => {
                        if (this.props.edit) {
                            this.editCustomer()
                        } else {
                            this.postNewCustomer()
                        }
                    },
                    onCancel: () => {
                        // console.log('Cancel');
                    },
                });
            });


    }

    editCustomer = async () => {
        message.loading({content: 'Saving...', key: 'submit'});

        // console.log(this.state.formRef.current?.getFieldsValue())

        postRequest("/api/customers/edit/" + this.props.id, this.state.formRef.current?.getFieldsValue(), false)
            .then(async (response) => {
                // alert(response.data)
                // console.log(response)
                const customer_id = response.data.id
                // console.log(customer_id)
                message.success({content: "Saved! (Customer ID: " + customer_id + ")", key: 'submit'})
            })
            .catch((error) => {
                message.error({content: error.response.data.message, key: 'submit'})
                // console.log('Error:' + error);
                // message.error('Error:' + error);
            });
    };

    postNewCustomer = async () => {
        message.loading({content: 'Creating...', key: 'submit'});

        postRequest("/api/customers/create", this.state.formRef.current?.getFieldsValue(), false)
            .then(async (response) => {
                // alert(response.data)
                const customer_id = response.data.id
                message.success({content: "Customer created! (ID: " + customer_id + ")", key: 'submit'})

            })
            .catch((error) => {
                message.error({content: error.response.data.message, key: 'submit'})
            });
    };


    onValidate(e: any) {
        // console.log('from submit data', this.state.formRef.current?.getFieldsValue());

        message.loading({content: 'Please wait...', key: 'validate'});
        this.state.formRef?.current
            ?.validateFields()
            .then((result) => {
                message.success({content: 'All fields are valid', key: 'validate'});
            })
            .catch((err) => {
                message.error({content: 'Some fields are invalid', key: 'validate'});
            });
    }

    onReset() {
        // console.log(this.state.formRef.current?.getFieldsValue());
        this.state.formRef.current?.resetFields();
    }

    render() {
        const bread = this.props.edit ? '/crm/customers/edit/' + this.props.id : '/crm/customers/new'
        const breadTitle = this.props.edit ? 'Edit Customer ' + this.props.id : 'New Customer'
        const pageTitle = this.props.edit ? 'Edit Customer ' + this.props.id : 'Create New Customer'
        const submitBtnText = this.props.edit ? 'Save' : 'Create'

        return (
            <>
                <PageContainer>
                    <div className="NewCustomer" style={this.style}>
                        {/* <Row justify="center">
                     <Title level={2}>Create New Customer</Title>
                  </Row>
                  <br /> */}

                        {/*<Card bordered={false}>*/}
                        {/*    <><Row justify='center'><Space direction='horizontal' align='baseline'> <Title*/}
                        {/*        level={3}>{pageTitle}</Title> <FormOutlined style={{fontSize: 20}}/></Space></Row></>*/}
                        {/*</Card>*/}

                        {/* <Card> */}
                        <NewCustomerForm
                            formRef={this.state.formRef}
                            edit={this.props.edit}
                            id={this.props.id}
                            onFormValuesChange={this.onFormValuesChange}
                        />
                        {/* </Card> */}

                        <br/>
                        <br/>
                        {/* Actions */}

                        <Row justify="space-between" style={{padding: '0 64px 0 64px'}}>

                            {!this.props.edit &&
                                <Button onClick={this.onReset} size="large" danger>
                                    Reset
                                </Button>
                            }

                            <Button onClick={this.onValidate} size="large">
                                Validate
                            </Button>


                            <Button type="primary" htmlType="submit" onClick={this.onSubmit} size="large"
                                    disabled={this.props.edit ? !this.state.changeOccurred : false}>
                                {submitBtnText}
                            </Button>

                        </Row>

                    </div>
                </PageContainer>
            </>
        );
    }
}

export default NewCustomer;
