import {Avatar, Drawer, message, notification, Space, Spin, Tooltip} from 'antd';
import React, {Component, CSSProperties} from 'react';
// import {Container, Draggable} from 'react-smooth-dnd';
import {TAG_SHAPE, TAG_SIZE} from '../../../common/constants';
import './index.css';
import {DraggableTag, TagCategoryValueDrawer} from '../../../common/models';
import {postRequest} from "../../../api/postRequest";

export interface ITagsDrawerProps {
    //Here we pass the Props Interface
    onDragStart: Function;
    onDragEnd: Function;
    onClose: Function;
    isVisible: boolean;
}

export interface ITagsDrawerState {
    //here we pass the State Interface
    data: TagCategoryValueDrawer[];
    draggableTags: DraggableTag[];
    isNewTagModalVisible: boolean;
}


const groupStyle: CSSProperties = {
    // margin: '0 0px 0 3px',
    flex: 1,
    overflowX: 'auto',
    // overflow: 'auto',
    // overflowX: 'scroll'
    // overflow: 'scroll'
    // flexDirection: 'row' as 'row',
    // display: 'flex',
    // flexWrap: 'wrap',
};

//class ComponentName Component<PropsInterface, StateInterface>
class TagsDrawer extends Component<ITagsDrawerProps, ITagsDrawerState> {
    constructor(props: ITagsDrawerProps) {
        super(props);

        //Component State
        this.state = {
            //   data: fillArr(),
            data: [],
            draggableTags: [],
            isNewTagModalVisible: false,
        };

        this.getTagCategoryValuesDrawer = this.getTagCategoryValuesDrawer.bind(this);
        this.showNewTagModal = this.showNewTagModal.bind(this);
        this.closeNewTagModal = this.closeNewTagModal.bind(this);
        this.createNewTag = this.createNewTag.bind(this);
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
    }

    // After the component did mount, we set the state.
    componentDidMount() {
        this.getTagCategoryValuesDrawer();
    }

    showNewTagModal() {
        this.setState({isNewTagModalVisible: true});
    }

    closeNewTagModal() {
        this.setState({isNewTagModalVisible: false});
    }

    getTagCategoryValuesDrawer() {

        const session_url = '/api/tags/getcategoryvaluesdrawer';

        postRequest(session_url)
            .then((response) => {
                // console.log('Authenticated');
                // console.log("TAG DRAWER")
                // console.log({response});
                // console.log('json',JSON.parse(response));
                this.setState({data: response.data ? response.data : [], draggableTags: []}, () => {
                    // console.log('tags', response.data.arr);
                    let tags: DraggableTag[] = [];
                    this.state.data.forEach((item: TagCategoryValueDrawer, index: number) => {
                        tags.push({
                            id: item.id + '',
                            data: (
                                <Tooltip title={item.value + ' (' + item.category_name + ')'} placement="top">
                                    <Avatar style={{backgroundColor: item.color, cursor: 'grab'}} size={TAG_SIZE}
                                            shape={TAG_SHAPE}>
                                        {item.value}
                                    </Avatar>
                                </Tooltip>
                            ),
                        });
                    });

                    this.setState(
                        {draggableTags: [...this.state.draggableTags, ...tags]}
                        // ,() =>console.log('draggableTags', this.state.draggableTags)
                    );
                });
            })
            .catch((e) => {
                notification.error({message: e.response.data.message})
            });
    }

    async createNewTag(tagObj: { domainId: number; class: string; valueId: number }) {

        postRequest(`/api/tags/createTag`, tagObj)
            .then((response) => {
                console.log('Authenticated');
                console.log('res', response.data.arr);
                // console.log('json',JSON.parse(response));
                message.success('Success');
                this.setState({data: [], draggableTags: []}, () => this.getTagCategoryValuesDrawer());
            })
            .catch(function (error) {
                message.success('Error');
                console.log('Error on GET :' + error);
            });
    }

    render() {
        return (
            <div className="TagsDrawer" style={this.style}>
                <Drawer
                    // title="All tags"
                    placement={'top'}
                    closable={true}
                    onClose={() => this.props.onClose()}
                    visible={this.props.isVisible}
                    mask={false}
                    keyboard={true}
                    height={120}
                    // bodyStyle={{}}
                >
                    <Space>

                        {/*TODO*/}
                        {/*<Tooltip title={'New tag'} placement="top">*/}
                        {/*    <Button*/}
                        {/*        type="dashed"*/}
                        {/*        // shape="circle"*/}
                        {/*        shape="round"*/}
                        {/*        icon={<PlusOutlined style={{color: '#40a9ff'}}/>}*/}
                        {/*        onClick={this.showNewTagModal}*/}
                        {/*        style={{margin: 'auto'}}*/}
                        {/*    >Create tag</Button>*/}
                        {/*</Tooltip>*/}
                        <div style={groupStyle}>
                            {/*<Spin spinning={this.state.draggableTags === []}>*/}
                            {/*    <Container*/}
                            {/*        groupName="1"*/}
                            {/*        behaviour="copy"*/}
                            {/*        getChildPayload={(i) => {*/}
                            {/*            // console.log(i, this.state.tagState[0][i]);*/}
                            {/*            return this.state.draggableTags[i];*/}
                            {/*        }}*/}
                            {/*        orientation={'horizontal'}*/}
                            {/*        onDragStart={() => {*/}
                            {/*            this.props.onDragStart();*/}
                            {/*        }}*/}
                            {/*        onDragEnd={() => {*/}
                            {/*            this.props.onDragEnd();*/}
                            {/*        }}*/}
                            {/*    >*/}

                            {/*        {this.state.draggableTags.map((p: any, i: number) => {*/}
                            {/*            // if (i === 0)*/}
                            {/*            //    return (*/}
                            {/*            //       <Tooltip title={'New tag'} placement="top">*/}
                            {/*            //          <Button*/}
                            {/*            //             type="dashed"*/}
                            {/*            //             shape="circle"*/}
                            {/*            //             icon={<PlusOutlined style={{ color: '#40a9ff' }} />}*/}
                            {/*            //             onClick={this.showNewTagModal}*/}
                            {/*            //             style={{ marginTop: '13.5px', marginRight: '12.5px' }}*/}
                            {/*            //          />*/}
                            {/*            //       </Tooltip>*/}
                            {/*            //    );*/}
                            {/*            return (*/}
                            {/*                <Draggable key={i}>*/}
                            {/*                    <div className="draggable-item">{p.data}</div>*/}
                            {/*                </Draggable>*/}
                            {/*                // p.data*/}
                            {/*            );*/}
                            {/*        })}*/}
                            {/*    </Container>*/}
                            {/*</Spin>*/}
                        </div>
                    </Space>
                </Drawer>

                {/*TODO*/}
                {/*<ModalForm*/}
                {/*   title="Create New Tag"*/}
                {/*   visible={this.state.isNewTagModalVisible}*/}
                {/*   modalProps={{*/}
                {/*      onCancel: () => this.closeNewTagModal(),*/}
                {/*      width: 300,*/}
                {/*      centered: true,*/}
                {/*   }}*/}
                {/*   submitter={{*/}
                {/*      searchConfig: {*/}
                {/*         submitText: 'Create',*/}
                {/*         resetText: 'Cancel',*/}
                {/*      },*/}
                {/*   }}*/}
                {/*   onFinish={async (values: any) => {*/}
                {/*      console.log('values', values);*/}
                {/*      this.createNewTag(values);*/}
                {/*      this.closeNewTagModal();*/}
                {/*      return true;*/}
                {/*   }}*/}
                {/*>*/}
                {/*   <ProForm.Group>*/}
                {/*      <ProFormText name="class" label="Tag Class" placeholder="Enter a tag class" width="md" />*/}
                {/*   </ProForm.Group>*/}
                {/*   <ProForm.Group>*/}
                {/*      <ProFormSelect*/}
                {/*         width="xs"*/}
                {/*         options={[*/}
                {/*            {*/}
                {/*               value: 0,*/}
                {/*               label: '0',*/}
                {/*            },*/}
                {/*         ]}*/}
                {/*         name="domainId"*/}
                {/*         label="Domain ID"*/}
                {/*         placeholder="Select"*/}
                {/*         initialValue="0"*/}
                {/*         disabled*/}
                {/*      />*/}
                {/*      <ProFormSelect*/}
                {/*         width="xs"*/}
                {/*         options={[*/}
                {/*            {*/}
                {/*               value: 0,*/}
                {/*               label: '0',*/}
                {/*            },*/}
                {/*         ]}*/}
                {/*         name="valueId"*/}
                {/*         label="Value ID"*/}
                {/*         placeholder="Select"*/}
                {/*         initialValue="0"*/}
                {/*         disabled*/}
                {/*      />*/}
                {/*   </ProForm.Group>*/}
                {/*</ModalForm>*/}
            </div>
        );
    }
}

export default TagsDrawer;
