import { Button, ConfigProvider, message, notification, Tooltip } from 'antd';
import React, { Component } from 'react';
import './index.css';
import enUSIntl from 'antd/lib/locale/en_US';
import { postRequest } from '../../../api/postRequest';
import { Link } from 'react-router-dom';
import {
    DeleteOutlined,
    DollarCircleOutlined,
    FileTextOutlined,
    HistoryOutlined,
    PlusOutlined,
    RightOutlined,
    UserOutlined,
    WarningOutlined
} from '@ant-design/icons';
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table';
import {
    ModalForm,
    ProFormDateRangePicker,
    ProFormSelect,
    ProFormSwitch,
    ProFormText,
    ProFormTextArea
} from '@ant-design/pro-form';
import PaymentCycleLogs from '../../components/PaymentCycleLogs';
import moment from 'moment';
import ProFormDatePickerMonth from '@ant-design/pro-form/es/components/DatePicker/MonthPicker';
import { PageContainer } from '@ant-design/pro-components';
import ProFormDatePickerYear from '@ant-design/pro-form/es/components/DatePicker/YearPicker';
import { auth } from '../../../config/firebase';
import axios from 'axios';
import { API } from '../../../common/constants';

export interface IPaymentCyclesProps {
    //Here we pass the Props Interface
}

export interface IPaymentCyclesState {
    actionRef: React.RefObject<ActionType>;
}

export type PaymentCycleMeta = {
    cycle_id: string;
    creation_time: string;
    description: string;
    step: string;
    phase: string;
    available_files_cnt: number;
    loaded_files_cnt: number;
    loaded_records_cnt: number;
    reference_month: string;
    is_compound: boolean;
};

//class ComponentName Component<PropsInterface, StateInterface>
class PaymentCycles extends Component<IPaymentCyclesProps, IPaymentCyclesState> {
    constructor(props: IPaymentCyclesProps) {
        super(props);

        this.state = {
            actionRef: React.createRef<ActionType>()
        };
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {}

    // After the component did mount, we set the state.
    componentDidMount() {
        // console.log('/newbillablecycle');
    }

    columns: ProColumns<PaymentCycleMeta>[] = [
        { title: 'Cycle Id', dataIndex: 'cycle_id', valueType: 'text' },
        // {title: 'Creation time', dataIndex: 'creation_time', valueType: 'dateTime',},
        {
            title: 'Creation time',
            dataIndex: 'creation_time',
            valueType: 'text',
            render: (dom, entity, index) =>
                moment(entity.creation_time, 'YYYY-MM-DD kk:mm:ss').format('DD/MM/YYYY kk:mm:ss')
        },
        { title: 'Description', dataIndex: 'description', valueType: 'text' },
        { title: 'Step', dataIndex: 'step', valueType: 'text' },
        { title: 'Phase', dataIndex: 'phase', valueType: 'text' },
        // {title: 'Available files count', dataIndex: 'available_files_cnt', valueType: 'digit', align: "right"},
        // {title: 'Loaded files count', dataIndex: 'loaded_files_cnt', valueType: 'digit', align: "right"},
        // {
        //     title: 'Loaded records count',
        //     dataIndex: 'loaded_records_cnt',
        //     render: num => (num?.toLocaleString('el-GR')),
        //     align: "right"
        // },
        {
            title: 'Reference month',
            dataIndex: 'reference_month',
            valueType: 'text',
            render: (dom, entity, index) => moment(entity.reference_month, 'YYYY-MM-DD').format('MM/YYYY')
        },
        {
            title: 'Actions',
            valueType: 'option',
            key: 'option',
            align: 'center',
            render: (text, record, _, action) => [
                // <a
                //     key="editable"
                //     onClick={() => {
                //         action?.startEditable?.(record.id);
                //     }}
                // >
                //     编辑
                // </a>,
                // <a href={record.url} target="_blank" rel="noopener noreferrer" key="view">
                //     查看
                // </a>,
                // <TableDropdown
                //     key="actionGroup"
                //     onSelect={() => action?.reload()}
                //     menus={[
                //         { key: 'copy', name: '复制' },
                //         { key: 'delete', name: '删除' },
                //     ]}
                // />,
                <Link to={'/erp/paymentcycles/' + record.cycle_id} key='view'>
                    {/*View*/}
                    <Tooltip title={'View'}>
                        <RightOutlined />
                    </Tooltip>
                </Link>,

                <ConfigProvider locale={enUSIntl}>
                    <ModalForm
                        // title="Payment Cycle Logs"
                        width={1000}
                        trigger={
                            <Tooltip title={'Logs'}>
                                <Button type='link'>
                                    <FileTextOutlined />
                                </Button>
                            </Tooltip>
                        }
                        autoFocusFirstInput
                        modalProps={{
                            cancelButtonProps: { hidden: true },
                            cancelText: 'Close',
                            closable: false
                        }}
                        submitter={{
                            submitButtonProps: {
                                style: {
                                    display: 'none'
                                }
                            }
                        }}>
                        <PaymentCycleLogs cycle_id={record.cycle_id} />
                    </ModalForm>
                </ConfigProvider>,

                <ConfigProvider locale={enUSIntl}>
                    <ModalForm
                        title={
                            <>
                                {' '}
                                <WarningOutlined /> {'Delete Payment Cycle ' + record.cycle_id}
                            </>
                        }
                        trigger={
                            <Tooltip title={'Delete'}>
                                <Button type='link' danger>
                                    <DeleteOutlined />
                                </Button>
                            </Tooltip>
                        }
                        width={400}
                        modalProps={{
                            cancelText: 'No',
                            okText: 'Yes, delete'
                        }}
                        submitter={{
                            submitButtonProps: {
                                danger: true
                            }
                        }}
                        onFinish={async (values: any) => {
                            try {
                                await postRequest('/api/paymentCycles/delete/' + record.cycle_id);
                                message.success('success');
                                this.state.actionRef.current?.reload();
                                return true;
                            } catch (e) {
                                notification.error({ message: e.response.data.message });
                                return false;
                            }
                        }}>
                        Are you sure?
                    </ModalForm>
                </ConfigProvider>
            ]
        }
    ];

    render() {
        return (
            <>
                <PageContainer>
                    {/* Scrape */}

                    {/*<Typography.Title level={4}>Payment Cycles</Typography.Title>*/}
                    {/*<br/>*/}

                    <ConfigProvider locale={enUSIntl}>
                        <ProTable<PaymentCycleMeta>
                            columns={this.columns}
                            actionRef={this.state.actionRef as any}
                            // cardBordered
                            bordered
                            request={async (params = {}, sort, filter) => {
                                // console.log(params)
                                const res = await postRequest('/api/paymentCycles/getCyclesMeta');
                                const data = res.data || [];
                                // console.log(res.data)
                                return { data, success: true, total: data.length };
                            }}
                            // editable={{
                            //     type: 'multiple',
                            // }}
                            // columnsState={{
                            //     persistenceKey: 'pro-table-singe-demos',
                            //     persistenceType: 'localStorage',
                            //     onChange(value) {
                            //         console.log('value: ', value);
                            //     },
                            // }}
                            rowKey='cycle_id'
                            // search={{
                            //     labelWidth: 'auto',
                            // }}
                            search={false}
                            // form={{
                            //     syncToUrl: (values, type) => {
                            //         if (type === 'get') {
                            //             return {
                            //                 ...values,
                            //                 created_at: [values.startTime, values.endTime],
                            //             };
                            //         }
                            //         return values;
                            //     },
                            // }}
                            pagination={{ pageSize: 10, hideOnSinglePage: true }}
                            // dateFormatter="string"
                            // headerTitle="headerTitle"
                            toolbar={{ style: { marginBottom: 16, textAlign: 'end' } }}
                            toolBarRender={() => [
                                // <Dropdown key="menu" overlay={menu}>
                                //     <Button>
                                //         <EllipsisOutlined />
                                //     </Button>
                                // </Dropdown>,
                                <ConfigProvider locale={enUSIntl}>
                                    <ModalForm<{
                                        cycle_id: string;
                                        description: string;
                                        reference_month: string;
                                        is_compound: boolean;
                                    }>
                                        title='Create a New Payment Cycle'
                                        trigger={
                                            <Tooltip title={'Create a New Payment Cycle'}>
                                                <Button type='primary'>
                                                    <PlusOutlined />
                                                    New Payment Cycle
                                                </Button>
                                            </Tooltip>
                                        }
                                        autoFocusFirstInput
                                        width={500}
                                        onFinish={async (values: any) => {
                                            if (!values.cycle_id || !values.reference_month) {
                                                notification.error({
                                                    message: 'Please fill in the required fields'
                                                });
                                                return Promise.reject();
                                            }
                                            values.reference_month = values.reference_month + '-01';
                                            // console.log(values);
                                            // return
                                            try {
                                                await postRequest('/api/paymentCycles/create', values, false);
                                                message.success('Created');
                                                this.state.actionRef.current?.reload();
                                                return true;
                                            } catch (e) {
                                                notification.error({ message: e.response.data.message });
                                                return false;
                                            }
                                        }}>
                                        <ProFormText
                                            width='md'
                                            name='cycle_id'
                                            label='Payment Cycle Id'
                                            tooltip='A unique id for the payment cycle'
                                            required
                                        />

                                        <ProFormDatePickerMonth
                                            width='md'
                                            name='reference_month'
                                            label='Reference Month'
                                            required
                                            tooltip='The month to which the payment cycles refers'
                                        />

                                        <ProFormTextArea
                                            width='md'
                                            name='description'
                                            label='Description'
                                            tooltip='A short description for the payment cycle'
                                        />

                                        <ProFormSwitch
                                            width='md'
                                            name='is_compound'
                                            label='Is Compound'
                                            tooltip='Whether the cycle consists of sub cycles'
                                        />
                                    </ModalForm>
                                </ConfigProvider>,

                                <Tooltip title={'View historical payments data'}>
                                    <Link to={'/erp'}>
                                        <Button icon={<HistoryOutlined />} type='text' />
                                    </Link>
                                </Tooltip>,

                                <ConfigProvider locale={enUSIntl}>
                                    <ModalForm<{
                                        year: string;
                                    }>
                                        title='Export Yearly History Report'
                                        trigger={
                                            <Tooltip title={'Export Yearly History Report'}>
                                                <Button icon={<DollarCircleOutlined />} type='text' />
                                            </Tooltip>
                                        }
                                        autoFocusFirstInput
                                        width={500}
                                        onFinish={async (values: any) => {
                                            if (!values.year) {
                                                notification.error({
                                                    message: 'Please fill in the required fields'
                                                });
                                                return Promise.reject();
                                            }
                                            // console.log(values);
                                            // return
                                            try {
                                                notification.info({ message: 'Operation started' });

                                                const token = await auth.currentUser?.getIdToken();

                                                axios
                                                    .post(
                                                        API +
                                                            '/api/paymentCycles/downloadYearlyHistoryExport/' +
                                                            values.year,
                                                        null,
                                                        {
                                                            headers: {
                                                                Accept: '*/*',
                                                                'Content-Type':
                                                                    'application/x-www-form-urlencoded;charset=utf-8',
                                                                Authorization: `Bearer ${token}`
                                                            },
                                                            responseType: 'blob'
                                                        }
                                                    )
                                                    .then((res) => {
                                                        // console.log(res)
                                                        const url = window.URL.createObjectURL(
                                                            new Blob([res.data])
                                                        );
                                                        const link = document.createElement('a');
                                                        link.href = url;
                                                        link.setAttribute(
                                                            'download',
                                                            'yearly_history_export_' + values.year + '.xlsx'
                                                        );
                                                        document.body.appendChild(link);
                                                        link.click();

                                                        notification.success({ message: 'Success' });
                                                    })
                                                    .catch((reason) => {
                                                        console.log(reason);
                                                        notification.error({ message: 'Error' });
                                                    });

                                                return true;
                                            } catch (e) {
                                                notification.error({ message: e.response.data.message });
                                                return false;
                                            }
                                        }}>
                                        <ProFormDatePickerYear
                                            width='md'
                                            name='year'
                                            label='Pick a year'
                                            required
                                            tooltip='The year for which the report will be exported'
                                        />
                                    </ModalForm>
                                </ConfigProvider>,

                                <ConfigProvider locale={enUSIntl}>
                                    <ModalForm<{
                                        customer_id: number;
                                        date_range: Array<string>;
                                    }>
                                        title='Export customer history for a certain time period'
                                        trigger={
                                            <Tooltip title={'Export Customer History'}>
                                                <Button icon={<UserOutlined />} type='text' />
                                            </Tooltip>
                                        }
                                        autoFocusFirstInput
                                        width={500}
                                        onFinish={async (values: any) => {
                                            notification.info({ message: 'Operation started' });
                                            const token = await auth.currentUser?.getIdToken();

                                            axios
                                                .post(
                                                    API +
                                                        '/api/paymentCycles/downloadDetailedHistoryExportForCustomer',
                                                    values,
                                                    {
                                                        headers: {
                                                            Accept: '*/*',
                                                            'Content-Type':
                                                                'application/x-www-form-urlencoded;charset=utf-8',
                                                            Authorization: `Bearer ${token}`
                                                        },
                                                        responseType: 'blob'
                                                    }
                                                )
                                                .then(({ data }) => {
                                                    const url = window.URL.createObjectURL(new Blob([data]));
                                                    const link = document.createElement('a');
                                                    link.href = url;
                                                    link.setAttribute(
                                                        'download',
                                                        `customer_export_${values.customer_select}_${values.month_range_picker[0]}_${values.month_range_picker[1]}.xlsx`
                                                    );
                                                    document.body.appendChild(link);
                                                    link.click();

                                                    notification.success({ message: 'Success' });
                                                })
                                                .catch((reason) => {
                                                    console.log(reason);
                                                    notification.error({ message: 'Error' });
                                                    return false;
                                                });
                                            return true;
                                        }}>
                                        <ProFormSelect
                                            name='customer_select'
                                            label='Customer ID'
                                            request={async () => {
                                                var { data: customers } = await postRequest(
                                                    '/api/customers/getall'
                                                );
                                                return customers.map((cus: any) => ({
                                                    label: `[${cus.id}] ${cus.full_name}`,
                                                    value: cus.id
                                                }));
                                            }}
                                            showSearch
                                            formItemProps={{
                                                required: true
                                            }}
                                        />
                                        <ProFormDateRangePicker
                                            name='month_range_picker'
                                            label='Dates'
                                            fieldProps={{ picker: 'month', format: 'YYYY-MM-01' }}
                                            formItemProps={{
                                                required: true
                                            }}
                                        />
                                    </ModalForm>
                                </ConfigProvider>
                            ]}
                        />
                    </ConfigProvider>
                </PageContainer>
            </>
        );
    }
}

export default PaymentCycles;
