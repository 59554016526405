import { PlusOutlined } from '@ant-design/icons';
import ProForm, {
    ModalForm,
    ProFormCheckbox,
    ProFormGroup,
    ProFormInstance,
    ProFormItem,
    ProFormList,
    ProFormSelect,
    ProFormText
} from '@ant-design/pro-form';
import { Badge, Button, Form } from 'antd';
import React from 'react';
import axiosApiInstance from '../../../api/axiosClient';
import { countryOptions } from './ddex_atoms';

const NewArtistForm = ({ stepFormRef }: Props) => {
    const [form] = Form.useForm<API.MusicDistribution.Artist>();
    return (
        <ModalForm<API.MusicDistribution.Artist>
            title='Add new artist'
            width={1000}
            form={form}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            trigger={
                <Button type='dashed' icon={<PlusOutlined />} style={{ width: '100%' }}>
                    Add
                </Button>
            }
            modalProps={{
                destroyOnClose: true,
                onCancel: () => true
            }}
            onFinish={async (values) => {
                await axiosApiInstance.post('/api/products/artists/', values);
                stepFormRef.current?.resetFields(['main_artist_id']);
                return true;
            }}
        >
            <ProFormText
                label='Key Name'
                name='key_name'
                required
                rules={[
                    {
                        required: true
                    }
                ]}
            />
            <ProFormText name='spotify_id' label='Spotify ID' initialValue={undefined} />
            <ProFormText name='apple_id' label='Apple ID' initialValue={undefined} />
            <ProFormList<API.MusicDistribution.Name>
                name='names'
                initialValue={[
                    {
                        full_name: ''
                    }
                ]}
                min={1}
                // actionRef={actionRef}
                colProps={{ span: 24 }}
                creatorButtonProps={{
                    position: 'bottom',
                    creatorButtonText: 'New Name'
                }}
            >
                {(f, index, action) => (
                    <ProFormGroup align='center' direction='horizontal' rowProps={{ justify: 'start' }}>
                        <ProFormText
                            name='full_name'
                            label='Full Name'
                            placeholder={'Full Name'}
                            width='md'
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                            required
                        />
                        <ProFormSelect
                            name='full_name_language'
                            label='Language'
                            mode='single'
                            width='xs'
                            // options={countryOptions}
                            options={[
                                {
                                    label: '🇬🇷',
                                    value: 'GR'
                                },
                                {
                                    label: '🇬🇧',
                                    value: 'EN'
                                }
                            ]}
                        />
                        <ProFormCheckbox
                            key={'is_default'}
                            name={'is_default'}
                            label='Default?'
                            initialValue={index === 0}
                        />
                        <ProFormCheckbox key={'is_stage_name'} name={'is_stage_name'} label='Stage Name?' />
                    </ProFormGroup>
                )}
            </ProFormList>
        </ModalForm>
    );
};

type Props = {
    stepFormRef: React.MutableRefObject<ProFormInstance>;
};

export default NewArtistForm;
