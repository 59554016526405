import React, {Component} from 'react';

// import Navbar from '../../../components/navbar';
import './index.css';
import axios from 'axios';
import {API} from '../../../common/constants';
import {Button, Input, message, Popconfirm, Row, Table} from 'antd';
import {auth} from "../../../config/firebase";
import {getRequest} from "../../../api/getRequest";

export interface IAdminPlatformsProps {
    //Here we pass the Props Interface
}

export interface IAdminPlatformsState {
    //here we pass the State Interface
    rows: number;
    data: any;
    newvalue: string;
    newdescr: string;
    isLoading: boolean;
}

//class ComponentName Component<PropsInterface, StateInterface>
class AdminPlatforms extends Component<IAdminPlatformsProps, IAdminPlatformsState> {
    constructor(props: IAdminPlatformsProps) {
        super(props);

        this.addPlatform = this.addPlatform.bind(this);
        this.getPlatforms = this.getPlatforms.bind(this);
        this.editPlatform = this.editPlatform.bind(this);
        this.deletePlatform = this.deletePlatform.bind(this);

        this.state = {rows: 0, data: [], newvalue: '', newdescr: '', isLoading: false};
    }

    newValueChange = (e: { target: { value: any } }) => {
        this.setState({
            newvalue: e.target.value,
        });
    };

    newDescrChange = (e: { target: { value: any } }) => {
        this.setState({
            newdescr: e.target.value,
        });
    };

    editPlatform(id: string) {
    }

    deletePlatform = async (id: string) => {
        var session_url = API + '/api/admin/deleteplatform';
        const token = await auth.currentUser?.getIdToken()
        axios
            .post(
                session_url,
                {
                    withCredentials: true,
                },
                {
                    params: {
                        id: id,
                    },
                    headers: {
                        Accept: '*/*',
                        Authorization: `Bearer ${token}`
                    },
                }
            )
            .then((response) => {
                console.log('Authenticated');
                this.getPlatforms();
                message.success('Deleted successfully.');
            })
            .catch((error) => {
                console.log('Error on Authentication Delete');
                message.success('An error occured.');
            });
    }

    getPlatforms() {
        this.setState({isLoading: true});

        getRequest('/api/admin/platform')
            .then((response) => {
                console.log('Authenticated');
                console.log(response);
                this.setState({data: JSON.parse(response.data['rows'])});
                // console.log(this.state.data["arr"]["length"])
                this.setState({rows: this.state.data['arr']['length']});
                this.setState({isLoading: false});
            })
            .catch((error) => {
                console.log('Error on GET :' + error);
                this.setState({isLoading: false});
            });
    }

    addPlatform = async () => {
        //Check fields
        var session_url = API + '/api/admin/addplatform';
        const token = await auth.currentUser?.getIdToken()

        axios
            .post(
                session_url,
                {withCredentials: true},
                {
                    params: {
                        value: this.state.newvalue,
                        descr: this.state.newdescr,
                    },
                    headers: {
                        Accept: '*/*',
                        Authorization: `Bearer ${token}`
                    },
                }
            )
            .then((response) => {
                console.log(response);
                //   Show Snackbar
                this.getPlatforms();
                message.success('Added successfully.');
            })
            .catch((error) => {
                console.log('Error on Authentication ADD');
                message.success('An error occured.');
            });
    }

    // Before the component mounts, we initialise our state
    componentWillMount() {
        this.getPlatforms();
    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }

    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '20%',
            },
            {
                title: 'Value',
                dataIndex: 'value',
                width: '25%',
                // editable: true,
                ellipsis: true,
            },
            {
                title: 'Description',
                dataIndex: 'descr',
                width: '25%',
                ellipsis: true,
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                width: '30%',
                render: (value: any, record: any) => (
                    <>
                        <Popconfirm title="Sure to delete?" onConfirm={() => this.deletePlatform(record.id)}>
                            <a>Delete</a>
                        </Popconfirm>
                        <Button disabled type="link">
                            Edit
                        </Button>
                    </>
                ),
            },
        ];

        return (
            <>
                <Row>
                    {this.state.data.arr && (
                        <Table
                            columns={columns}
                            dataSource={[...this.state.data.arr]}
                            // pagination={{ showSizeChanger: false, pageSize: 5 }}
                            pagination={false}
                            loading={this.state.isLoading}
                            scroll={{y: 330}}
                        />
                    )}
                </Row>

                <Row style={{marginTop: 8}}>
                    <Input.Group compact>
                        <Input
                            addonBefore="auto id"
                            style={{
                                width:
                                    parseInt(columns[0].width.split('%')[0]) + parseInt(columns[1].width.split('%')[0]) + '%',
                            }}
                            value={this.state.newvalue}
                            onChange={this.newValueChange}
                            placeholder="value"
                        />
                        <Input
                            style={{width: columns[2].width}}
                            value={this.state.newdescr}
                            onChange={this.newDescrChange}
                            placeholder="description"
                        />
                        <Button type="primary" onClick={() => this.addPlatform()} style={{width: columns[3].width}}>
                            Add
                        </Button>
                    </Input.Group>
                </Row>
            </>
        );
    }
}

export default AdminPlatforms;
