import {PlusSquareOutlined, SearchOutlined, UploadOutlined} from '@ant-design/icons';
import {
    Alert,
    Button,
    Card,
    Input,
    message,
    Modal,
    notification,
    Progress,
    Row,
    Space,
    Table,
    Typography,
    Upload
} from 'antd';
import Title from 'antd/lib/typography/Title';
import axios from 'axios';
import React, {Component} from 'react';
import {CSVLink} from 'react-csv';
import {API} from '../../../common/constants';
import {GetCycleFolderResponse, PaymentsResultsByCustomer, PaymentsResultsStatisticsV2} from '../../../common/models';
import {roundNum} from '../../../common/utils';
import ResultsCustomer from '../ResultsCustomer';
import './index.css';
import Highlighter from 'react-highlight-words';
import UnmatchedKeysUpdateForm from "../UnmatchedKeysUpdateForm";
import {postRequest} from "../../../api/postRequest";


export interface IResultsStepProps {
    //Here we pass the Props Interface
    handler?: Function,
    year: number,
    id: number,
    type_cycle: number,


}

export interface IResultsStepState {
    //here we pass the State Interface
    year: number,
    id: number,
    type_cycle: number,
    proceed: boolean,
    previous: boolean,
    rows: number,
    results: any,
    resultsJSON: any,
    total: any,
    totalJSON: any,
    totalSUM: number,
    totalSumMap: Map<string, number>,
    finalSUM: Map<string, number>,
    customers: Map<string, any>,
    data: any,
    show: boolean,
    loading: boolean,

    results_by_customer?: PaymentsResultsByCustomer[]
    // results_statistics?: PaymentsResultsStatistics,
    results_statistics?: PaymentsResultsStatisticsV2[],
    results_unmatched_ppk?: any[],
    results_unmatched_assets?: any[],
    analytical_results?: any[]


    searchText: string,
    searchedColumn: string,

    fileList: any[]
    uploading: boolean
    uploadProgress?: number
    compareModalVisible: boolean
}

const STATISTIC_PRECISION = 3


//class ComponentName Component<PropsInterface, StateInterface>
class ResultsStep extends Component<IResultsStepProps, IResultsStepState> {

    constructor(props: IResultsStepProps) {
        super(props)
        this.state = {
            year: this.props.year,
            id: this.props.id,
            type_cycle: this.props.type_cycle,
            proceed: true,
            previous: false,
            rows: 0,
            data: [],
            results: [],
            resultsJSON: [],
            total: [],
            totalJSON: [],
            totalSUM: 0,
            totalSumMap: new Map(),
            finalSUM: new Map(),
            customers: new Map(),
            show: false,
            loading: false,

            searchText: '',
            searchedColumn: '',

            fileList: [],
            uploading: false,
            compareModalVisible: false
        }
    }

    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {
        // this.getFunc()
        this.getResults()

    }

    // After the component did mount, we set the state.
    componentDidMount() {
    }


    getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    // placeholder={`Search ${dataIndex}`}
                    placeholder={`Search...`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                    {/* <Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							this.setState({
								searchText: selectedKeys[0],
								searchedColumn: dataIndex,
							});
						}}
					>
						Filter
					</Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => this.searchInput!.select(), 100);
            }
        },
        render: (text: any) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    numberColumnConfig = (config: 'origin' | 'destination' | 'none', precision = 3) => {
        return {
            align: 'right' as const,
            render: (text: string, record: PaymentsResultsByCustomer, index: number) => {
                if (config == 'origin') return record.origin_currency_symbol + ' ' + roundNum(parseFloat(text), precision)
                if (config == 'destination') return record.destination_currency_symbol + ' ' + roundNum(parseFloat(text), precision)
                if (config == 'none') return roundNum(parseFloat(text), precision)
            }
        }
    }

    columns_results = [
        {
            title: "Customer ID",
            dataIndex: "customer_id",
            ...this.numberColumnConfig('none'),
            sorter: (a: any, b: any) => parseInt(a.customer_id) - parseInt(b.customer_id),
            ...this.getColumnSearchProps('customer_id')
        },
        {
            title: "Full Name",
            dataIndex: "full_name",
            ...this.getColumnSearchProps('full_name')
        },
        {
            title: "Gross Revenue (Platform)",
            dataIndex: "total_gross_platform",
            ...this.numberColumnConfig('origin'),
            sorter: (a: any, b: any) => parseFloat(a.total_gross_platform) - parseFloat(b.total_gross_platform),
        },
        {
            title: "Gross Revenue",
            dataIndex: "total_gross_platform_euro",
            ...this.numberColumnConfig('destination'),
            sorter: (a: any, b: any) => parseFloat(a.total_gross_platform_euro) - parseFloat(b.total_gross_platform_euro),
        },
        // {
        // 	title: "Origin currency",
        // 	dataIndex: "origin_currency",
        // },
        {
            title: "DM Fee",
            dataIndex: "total_dm_net",
            ...this.numberColumnConfig('destination'),
            sorter: (a: any, b: any) => parseFloat(a.total_dm_net) - parseFloat(b.total_dm_net),
        },
        {
            title: "Client Revenue",
            dataIndex: "total_client_net",
            ...this.numberColumnConfig('destination'),
            sorter: (a: any, b: any) => parseFloat(a.total_client_net) - parseFloat(b.total_client_net),
        },
        // {
        // 	title: "Admin fees",
        // 	dataIndex: "admin_fees",
        // 	...this.numberColumnConfig('destination')
        // },
        {
            title: "Affiliate Share",
            dataIndex: "affiliation_fees",
            ...this.numberColumnConfig('destination')
        },
        // {
        // 	title: "Destination currency",
        // 	dataIndex: "destination_currency",
        // },
    ]

    columns_statistics = [
        {
            title: "Platform",
            dataIndex: "platform",
            render: (text: string, record: PaymentsResultsStatisticsV2, index: number) => {
                let label
                switch (text) {
                    case "MD":
                        label = "Music Distribution (Believe)"
                        break
                    case "SP":
                        label = "Spotify"
                        break
                    case "YT":
                        label = "YouTube"
                        break
                    default:
                        label = text
                        break
                }
                return <Typography.Text strong>{label}</Typography.Text>
            },
        },
        {
            title: "Client Net",
            dataIndex: "client_net_euro",
            align: 'right' as const,
            render: (text: string, record: PaymentsResultsStatisticsV2, index: number) => {
                let val = record.euro_currency_symbol + ' ' + roundNum(parseFloat(text), 3)
                let subVal
                if (record.origin_currency_code != record.euro_currency_code && record.origin_currency_code != '')
                    subVal = record.origin_currency_symbol + ' ' + roundNum(parseFloat(record.client_net.toString()), 3)
                return <> <Typography.Text style={{fontSize: "medium"}}>{val}</Typography.Text> {subVal ? <>
                    <br/><Typography.Text
                    type={"secondary"}> {subVal}</Typography.Text></> : null} </>
            }
        },
        {
            title: "DM Fee",
            dataIndex: "dm_fee_euro",
            align: 'right' as const,
            render: (text: string, record: PaymentsResultsStatisticsV2, index: number) => {
                let val = record.euro_currency_symbol + ' ' + roundNum(parseFloat(text), 3)
                let subVal
                if (record.origin_currency_code != record.euro_currency_code && record.origin_currency_code != '')
                    subVal = record.origin_currency_symbol + ' ' + roundNum(parseFloat(record.dm_fee.toString()), 3)
                return <> <Typography.Text style={{fontSize: "medium"}}>{val}</Typography.Text> {subVal ? <>
                    <br/><Typography.Text
                    type={"secondary"}> {subVal}</Typography.Text></> : null} </>
            }
        },
        {
            title: "Unmatched Revenue",
            dataIndex: "unmatched_revenue_euro",
            align: 'right' as const,
            render: (text: string, record: PaymentsResultsStatisticsV2, index: number) => {
                let val = record.euro_currency_symbol + ' ' + roundNum(parseFloat(text), 3)
                let subVal
                if (record.origin_currency_code != record.euro_currency_code && record.origin_currency_code != '')
                    subVal = record.origin_currency_symbol + ' ' + roundNum(parseFloat(record.unmatched_revenue.toString()), 3)
                return <> <Typography.Text style={{fontSize: "medium"}}>{val}</Typography.Text> {subVal ? <>
                    <br/><Typography.Text
                    type={"secondary"}> {subVal}</Typography.Text></> : null} </>
            }
        },
        {
            title: "Total Deposited",
            dataIndex: "total_deposit_euro",
            align: 'right' as const,
            render: (text: string, record: PaymentsResultsStatisticsV2, index: number) => {
                let val = record.euro_currency_symbol + ' ' + roundNum(parseFloat(text), 3)
                let subVal
                if (record.origin_currency_code != record.euro_currency_code && record.origin_currency_code != '')
                    subVal = record.origin_currency_symbol + ' ' + roundNum(parseFloat(record.total_deposit.toString()), 3)
                return <> <Typography.Text style={{fontSize: "medium"}}>{val}</Typography.Text> {subVal ? <>
                    <br/><Typography.Text
                    type={"secondary"}> {subVal}</Typography.Text></> : null} </>
            }
        }
    ]

    getFunc = async () => {

        postRequest('/api/erp/newcycle/cyclefolder', {
            id: this.state.id,
            year: this.state.year,
            output: "show",
        })
            .then((response) => {
                // console.log('Authenticated');
                // console.log(response)

                let result = response.data.result as GetCycleFolderResponse[] || []

                let error = true
                if (result.length > 0) {
                    error = false
                }
                var i = 0;
                let data_list: any[] = []
                result.forEach((element) => {
                    if (element)
                        if (element.filepath.includes('undefined')) {
                            data_list[i] = {key: "0"}
                            error = true
                        } else {
                            data_list[i] = {key: element.filepath}
                            error = false
                        }
                    i++;
                });


                var proceed = true
                if (error == false) {
                    notification.info({message: `Found ${data_list.length} Results .csv files.`})
                    this.setState({rows: data_list.length, data: data_list, proceed: proceed})
                } else {
                    notification.error({message: `There are 0 .csv files.`})
                }


            }).catch(function (error) {
            console.log('Error on GET :' + error);
        });
    }


    searchInput: any = null


    handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({searchText: ''});
    };

    getAnalyticalResults() {

        postRequest(`/api/erp/newcycle/getanalyticalresults`, {
            id: this.state.id,
            year: this.state.year,
            output: "show",
        })
            .then((response) => {
                console.log('Authenticated');
                console.log(response)

                let result = response.data.result || []

                this.setState({analytical_results: result})


            }).catch(function (error) {
            console.log('Error on GET :' + error);
        });
    }

    handleUpload = ({fileList}: any) => {

        console.log('fileList', fileList);

        // you store them in state, so that you can make a http req with them later
        this.setState({fileList});
    };

    handleSubmit = (event: { preventDefault: () => void; }) => {

        this.setState({uploading: true})
        event.preventDefault();

        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key

        // formData.append("files", this.state.fileList.map((f) => f.originFileObj));
        for (const file of this.state.fileList)
            formData.append("files", file.originFileObj);
        formData.append("year", this.props.year + '');
        formData.append("id", this.props.id + '');

        axios
            .post(API + `/api/erp/newcycle/compareresults`, formData,
                {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
                    },
                    auth: {
                        username: 'dev',
                        password: '1234',
                    },
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent
                        const percent = roundNum((loaded / total) * 100, 2)
                        console.log('progressEvent', progressEvent, percent);
                        this.setState({uploadProgress: roundNum(percent / 2, 2)})
                    }
                })
            .then(res => {
                this.setState({uploadProgress: 100})

                console.log("res", res);

                setTimeout(() => {
                    this.setState({fileList: [], uploading: false, compareModalVisible: false})

                    // download the result file
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'result_diff.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                }, 1000)

                notification.success({message: 'Success. Downloading...'})


            })
            .catch(err => {
                this.setState({uploading: false})
                notification.error({message: 'Error uploading file.'})
                console.log("err", err);
            });
    };


    render() {

        const statisticCardHeight = 230

        return (
            <>

                <Row justify='space-between' style={{padding: 4}}>
                    <Title level={5}>Results Report</Title>
                    <Space>
                        <Button type='dashed'
                                onClick={() => this.setState({compareModalVisible: true})}
                        >
                            Compare Results with CSV
                        </Button>
                        <Button type='default'>
                            <CSVLink
                                filename={"analytical_results.csv"}
                                data={this.state.analytical_results || []}
                            >
                                Export Analytical Results Report
                            </CSVLink>
                        </Button>
                        <Button type='primary'>
                            <CSVLink
                                filename={"results.csv"}
                                data={this.state.results_by_customer || []}
                            >
                                Export CSV file
                            </CSVLink>
                        </Button>
                    </Space>
                </Row>
                <br/>
                <Alert message={<> Click the <PlusSquareOutlined/> icon for revenue breakdown.</>} type='info'
                       showIcon/>
                <br/>
                <Row>
                    <Card bodyStyle={{padding: 0}} style={{width: '100%'}}>
                        <Table
                            dataSource={this.state.results_by_customer}
                            columns={this.columns_results}
                            pagination={{hideOnSinglePage: true}}
                            loading={this.state.loading}
                            expandable={{
                                expandedRowRender: (record) => <ResultsCustomer customer_id={record.customer_id}/>,
                                // expandRowByClick: true
                            }}
                            // rowClassName={'clickable-row'}
                        />
                    </Card>
                </Row>

                <br/>

                <Modal visible={this.state.compareModalVisible} title={'Upload comparison CSV file'}
                       onCancel={() => this.setState({compareModalVisible: false})}
                >

                    <Alert message={<>
                        <p>Upload the CSV file which includes the results to compare.</p>
                        <p>The file should contain two columns : "customer_id" and "amount" (in euros) in this
                            order.</p>
                        <Row>
                            <Upload
                                fileList={this.state.fileList}
                                onChange={this.handleUpload}
                                beforeUpload={() => false} // return false so that antd doesn't upload the file right away
                                accept={'.csv'}
                            >
                                <Button icon={<UploadOutlined/>}>Upload File</Button>

                            </Upload>
                            {this.state.uploadProgress !== undefined && this.state.uploading &&
                            <Progress percent={this.state.uploadProgress} status='active'/>}
                        </Row>
                        {this.state.fileList.length > 0 &&
                        <>
                            <br/>
                            <Row>
                                <Button onClick={this.handleSubmit} loading={this.state.uploading}>
                                    Submit
                                </Button>
                            </Row>
                        </>
                        }

                    </>}/>
                </Modal>

                {/*This is the table with all types of unmatched keys. The user can edit it and then recompute the results. */}
                <UnmatchedKeysUpdateForm/>

                <br/>

                {/* <Row> */}
                <Row style={{padding: 4}} justify='space-between'>
                    <Title level={5}>Report Statistics</Title>
                </Row>
                {/* <Card bodyStyle={{padding:'0'}}> */}
                <Row>
                    {/*<Card bodyStyle={{padding: 0}}>*/}
                    <Table
                        dataSource={this.state.results_statistics}
                        columns={this.columns_statistics}
                        pagination={false}
                        loading={this.state.loading}
                        bordered
                    />
                    {/*</Card>*/}
                </Row>
                {/* </Card> */}


                <br/>
                <Row justify="space-between" align="middle">

                    {/* <Space>
						<Button type={"primary"} onClick={() => this.calculateResults()}>Calculate Results</Button>

					</Space> */}
                    <Button type={"ghost"} onClick={() => this.previousStep()} disabled={this.state.previous}>Previous
                        Step</Button>
                    <Button type={"primary"} onClick={() => this.proceedStep()} disabled={this.state.proceed}>Next
                        Step</Button>
                    {/* <Space>
					</Space> */}
                </Row>


            </>
        );
    }

    getResults(): void {
        this.setState({loading: true})

        postRequest(`/api/erp/newcycle/results`)
            .then((response) => {
                console.log('Authenticated');
                console.log(response)
                // console.log('all_data', data2, data3)

                let results_by_customer = response.data.result || []
                let results_unmatched_ppk = response.data.unmatched_ppk || []
                let results_unmatched_assets = response.data.unmatched_assets || []
                let data1: any[] = [];
                for (let i = 0; i < results_by_customer.length; i++) {
                    data1.push({...results_by_customer[i], key: i});
                }
                let data2: any[] = [];
                for (let i = 0; i < results_unmatched_ppk.length; i++) {
                    data2.push({...results_unmatched_ppk[i], key: i});
                }
                let data3: any[] = [];
                for (let i = 0; i < results_unmatched_assets.length; i++) {
                    data3.push({...results_unmatched_assets[i], key: i});
                }
                // console.log('all_data', data2, data3)

                this.setState({
                    results_by_customer: data1 || [],
                    results_statistics: response.data.statistics || [],
                    results_unmatched_ppk: data2 || [],
                    results_unmatched_assets: data3 || []
                })

                this.setState({loading: false})

                this.getAnalyticalResults()


            }).catch((error) => {
            console.log('Error on GET :' + error);
            message.error(error)
            this.setState({loading: false})
        });
        this.setState({proceed: false})
    }


    previousStep(): void {
        this.setState({previous: true});

        postRequest(`/api/erp/newcycle/proceedcycle`, {
            id: this.state.id,
            year: this.state.year,
            step: "2"
        })
            .then((response) => {
                console.log('Authenticated');
                console.log(response)

                this.props.handler ? this.props.handler() : window.location.reload()
            }).catch(function (error) {
            console.log('Error on GET :' + error);
        });

    }

    proceedStep(): void {
        this.setState({proceed: true});

        postRequest(`/api/erp/newcycle/proceedcycle`, {
            id: this.state.id,
            year: this.state.year,
            step: "4"
        })
            .then((response) => {
                console.log('Authenticated');
                console.log(response)

                this.props.handler ? this.props.handler() : window.location.reload()
            }).catch(function (error) {
            console.log('Error on GET :' + error);
        });

    }
}


export default ResultsStep;

