import ProTable, { ProColumns } from '@ant-design/pro-table';
import React from 'react';
import axiosApiInstance from '../../../../api/axiosClient';
import { Button, Form } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalForm, ProFormSelect, ProFormText } from '@ant-design/pro-form';

const NewLabel = () => {
    const [form] = Form.useForm<API.DDEX.Label>();
    return (
        <ModalForm<API.DDEX.Label>
            title='Add new artist'
            width={1000}
            form={form}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            trigger={
                <Button type='primary' icon={<PlusOutlined />} style={{ width: '100%' }}>
                    Add
                </Button>
            }
            modalProps={{
                destroyOnClose: true,
                onCancel: () => true
            }}
            onFinish={async (values) => {
                await axiosApiInstance.post('/api/records/labels/', values);
                // stepFormRef.current?.resetFields(['label_id']);
                return true;
            }}
        >
            <ProFormText key={'full_name'} name={'full_name'} label='Name' />
            <ProFormText key={'p_line'} name={'p_line'} label='(P) Line' />
            <ProFormText key={'c_line'} name={'c_line'} label='(C) Line' />
            <ProFormSelect key={'asset_label'} name={'asset_label'} label='Asset Label' />
        </ModalForm>
    );
};

const LabelsTable = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const columns: ProColumns<API.MusicDistribution.Label>[] = [
        {
            title: 'id',
            key: 'id',
            dataIndex: 'ID',
            hideInSearch: true,
            hideInForm: true
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            hideInSearch: true,
            hideInForm: true
        },
        {
            title: '(P) Line',
            key: 'p_line',
            dataIndex: 'p_line',
            hideInSearch: true,
            hideInForm: true
        },
        {
            title: '(C) Line',
            key: 'c_line',
            dataIndex: 'c_line',
            hideInSearch: true,
            hideInForm: true
        },
        {
            title: '',
            key: 'actions',
            search: false,
            render(_dom, entity) {
                return (
                    <>
                        <Button
                            type='link'
                            icon={<EditOutlined />}
                            onClick={() =>
                                navigate(`/music/labels/${entity.ID}/edit`, {
                                    state: { background: location }
                                })
                            }
                        />
                        <Button
                            type='link'
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => {
                                navigate(`/music/labels/${entity.ID}/delete`, {
                                    state: { background: location }
                                });
                            }}
                        />
                    </>
                );
            }
        }
    ];
    return (
        <ProTable
            columns={columns}
            search={false}
            request={(params) => axiosApiInstance.get(`/api/products/labels/`)}
            rowKey='ID'
            toolBarRender={() => [<NewLabel />]}
        />
    );
};

export default LabelsTable;
