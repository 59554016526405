import { useContext, useEffect, useState } from 'react';
import { DDEXPageContext } from './DDEXMainPage';
import {
    ProFormDigit,
    ProFormGroup,
    ProFormItem,
    ProFormList,
    ProFormRadio,
    ProFormSelect,
    ProFormSwitch,
    ProFormText,
    ProFormTimePicker,
    ProFormUploadButton
} from '@ant-design/pro-form';
import ProCard from '@ant-design/pro-card';
import { Badge, Button, message, Tooltip } from 'antd';
import { checksEnabled, fetchArtistOptions } from './ReleaseEntryStepForm';
import { DeleteOutlined, DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { countryOptions } from './ddex_atoms';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import axiosApiInstance from '../../../api/axiosClient';
import axios from 'axios';

dayjs.extend(duration);

const formatDuration = (duration: number) => {
    // Extract whole minutes
    const minutes = Math.floor(duration / 60);

    // Extract remaining whole seconds
    const seconds = Math.floor(duration % 60);

    // Extract milliseconds
    const milliseconds = Math.round((duration % 1) * 1000);

    // Format the result as mm:ss:SSS
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    const formattedMilliseconds = String(milliseconds).padStart(3, '0');

    return `${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
};

const parseDuration = (durationString: string) => {
    // console.log({durationString})
    if (!isNaN(+durationString)) {
        return dayjs.duration(+durationString);
    }
    if (!durationString) {
        return dayjs.duration(0);
    }
    // Split the duration string into minutes, seconds, and milliseconds
    const [minutes, secondsWithMillis] = durationString.split(':');
    const [seconds, milliseconds] = secondsWithMillis.split('.');

    // Create a Day.js duration object
    const dur = dayjs.duration({
        minutes: parseInt(minutes, 10),
        seconds: parseInt(seconds, 10),
        milliseconds: parseInt(milliseconds, 10)
    });

    return dur;
};

const StepsFormSecondPage = () => {
    const { firstFormPageRef, secondFormPageRef, listRef, artists } = useContext(DDEXPageContext);
    // const fileInputRef = useRef(null);
    const [removingFiles, setRemovingFiles] = useState([]);
    const [errorRemovingFiles, setErrorRemovingFiles] = useState([]);

    useEffect(() => console.log('steps form second page rendered'));
    return (
        <ProFormList<API.MusicDistribution.ArtTrack>
            name='art_tracks'
            initialValue={[
                {
                    isrc: '',
                    main_artists: artists?.main_artists,
                    featuring_artists: artists?.featuring_artists
                }
            ]}
            min={1}
            actionRef={listRef}
            colProps={{ span: 24 }}
            creatorRecord={() => ({
                isrc: '',
                main_artists: artists?.main_artists,
                featuring_artists: artists?.featuring_artists
            })}
            creatorButtonProps={{
                position: 'bottom',
                creatorButtonText: 'New Track',
                style: {
                    marginTop: 15
                }
                // onClick: () => setTrackNo((prev) => prev + 1)
            }}
            actionRender={() => [<></>]}
        >
            {(f, index, action) => {
                return (
                    <div key='isrc'>
                        <ProCard
                            style={{
                                marginBottom: -35
                            }}
                        >
                            <ProFormGroup
                                align='center'
                                direction='horizontal'
                                colProps={{ span: 24 }}
                                rowProps={{ justify: 'end' }}
                            >
                                <ProFormItem label='#' wrapperCol={{ span: 10 }}>
                                    <Badge color='#002288' size='small' count={index + 1} />
                                </ProFormItem>
                                <ProFormDigit
                                    key='volume_number'
                                    name='volume_number'
                                    label='Vol. No.'
                                    min={1}
                                    initialValue={
                                        index === 0 ? 1 : listRef?.current?.get(index - 1).volume_number
                                    }
                                    fieldProps={{
                                        onChange(value) {
                                            // setVolNo(value as number);
                                            action?.setCurrentRowData({ track_number: 1 });
                                        }
                                    }}
                                    // width={'xs'}
                                    width={60}
                                />
                                <ProFormDigit
                                    key='track_number'
                                    name='track_number'
                                    label='Track No.'
                                    initialValue={
                                        index === 0 ? 1 : listRef?.current?.get(index - 1).track_number + 1
                                    }
                                    min={1}
                                    // width={'xs'}
                                    width={60}
                                />
                                <ProFormText
                                    key='isrc'
                                    name='isrc'
                                    label='ISRC'
                                    placeholder={'ISRC'}
                                    width={140}
                                    transform={(value) => value?.replace(/-/g, '')}
                                    rules={[
                                        {
                                            pattern: /^[A-Z]{2}\-?[A-Z0-9]{3}\-?[0-9]{2}\-?[0-9]{5}/,
                                            message: 'Invalid ISRC',
                                            validateTrigger: 'onFinish',
                                            required: checksEnabled
                                        },
                                        {
                                            transform: (value: string) => {
                                                return value?.replace(/-/g, '');
                                            },
                                            len: 12,
                                            validateTrigger: 'onFinish',
                                            message: 'ISRC must be 12 characters long (hyphens excluded)',
                                            required: checksEnabled
                                        }
                                    ]}
                                    required
                                />
                                <ProFormText
                                    // key='track_name'
                                    name='title'
                                    label='Track Name'
                                    width='md'
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                    placeholder={'Track Name'}
                                />
                                <ProFormSelect
                                    label='Type'
                                    initialValue={'ORIGINAL'}
                                    allowClear={false}
                                    name='track_type'
                                    options={['ORIGINAL', 'KARAOKE', 'MEDLEY', 'COVER', 'COVER BY BAND']}
                                />

                                <ProFormTimePicker
                                    label='Duration'
                                    fieldProps={{
                                        format: 'mm:ss.SSS'
                                    }}
                                    name='duration'
                                    width='sm'
                                    convertValue={(value) => {
                                        // console.log('1', {value});
                                        // convert nanoseconds to dayjs HH:mm.sss
                                        if (dayjs.isDayjs(value)) {
                                            // console.log('2')
                                            return value.format('mm:ss.SSS');
                                        } else if (!isNaN(+value)) {
                                            // console.log('3')
                                            return formatDuration(value / 1000000000);
                                        } else {
                                            // console.log('4')
                                            return value;
                                        }
                                    }}
                                    transform={(value) => {
                                        // console.log({value});
                                        // return {'duration': value};
                                        if (!isNaN(+value)) {
                                            return { duration: value };
                                        }
                                        // convert dayjs HH:mm.sss to  nanoseconds
                                        const duration = parseDuration(value);
                                        return { duration: duration.asMilliseconds() * 1000000 };
                                    }}
                                    rules={[
                                        {
                                            required: checksEnabled
                                        }
                                    ]}
                                    hidden
                                />

                                <ProFormText
                                    key='filename'
                                    name='filename'
                                    label='Filename'
                                    width='sm'
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                    placeholder={'Filename'}
                                    hidden
                                    rules={[
                                        {
                                            required: checksEnabled
                                        }
                                    ]}
                                />

                                <ProFormUploadButton
                                    // hidden
                                    // fieldRef={fileInputRef}
                                    label='Audio File'
                                    name='audio_file'
                                    title={'Upload Audio File'}
                                    // width='xs'
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                    max={1}
                                    // icon={null}
                                    // accept='.mp3'
                                    fieldProps={{
                                        // ref: fileInputRef,
                                        beforeUpload: (file) => {
                                            // console.log(file, index);
                                            const audio = new Audio(URL.createObjectURL(file));
                                            audio.addEventListener('loadedmetadata', () => {
                                                // console.log(audio.duration);
                                                const durationFormatted = formatDuration(audio.duration);
                                                const dateObj = dayjs(durationFormatted, 'mm:ss.SSS');
                                                secondFormPageRef.current?.setFieldValue(
                                                    ['art_tracks', index, 'duration'],
                                                    dateObj
                                                );
                                            });
                                            secondFormPageRef?.current?.setFieldValue(
                                                ['art_tracks', index, 'filename'],
                                                file.name
                                            );
                                            return true;
                                        },
                                        customRequest: async ({ file, onSuccess, onError, onProgress }) => {
                                            // console.log(file);
                                            // return Promise.resolve()
                                            try {
                                                const response = await axiosApiInstance.post(
                                                    '/api/products/releases/generate-presigned-url-upload',
                                                    {
                                                        fileName: (file as any).name,
                                                        fileType: (file as any).type,
                                                        folderName:
                                                            firstFormPageRef?.current?.getFieldValue(
                                                                'upc_ean'
                                                            )
                                                    }
                                                );
                                                // console.log({response});

                                                const { presignedUrl } = response.data;

                                                return axios
                                                    .put(presignedUrl, file, {
                                                        headers: {
                                                            'Content-Type': (file as any).type
                                                        },
                                                        onUploadProgress: (progressEvent) => {
                                                            const percentCompleted = Math.round(
                                                                (progressEvent.loaded * 100) /
                                                                    progressEvent.total
                                                            );
                                                            onProgress({ percent: percentCompleted });
                                                        }
                                                    })
                                                    .then(() => {
                                                        // console.log({responseUpl});
                                                        secondFormPageRef?.current?.setFieldValue(
                                                            ['art_tracks', index, 'is_uploaded'],
                                                            true
                                                        );
                                                        message.success('File uploaded successfully');
                                                        // onSuccess(responseUpl.data)
                                                        onSuccess('ok');
                                                    })
                                                    .catch((err) => {
                                                        console.error('Error uploading file:', err);
                                                        message.error('Error uploading file');
                                                        onError(err);
                                                    });
                                            } catch (err) {
                                                console.error('Error uploading file:', err);
                                                message.error('Error uploading file');
                                                onError(err);
                                            }
                                        },
                                        onRemove: async (file) => {
                                            // console.log(file);
                                            // try {
                                            //     const response = await axiosApiInstance.post('/api/products/releases/generate-presigned-url-delete', {
                                            //         fileName: (file as any).name,
                                            //         folderName: firstFormPageRef?.current?.getFieldValue('upc_ean'),
                                            //     });
                                            //     console.log({response});
                                            //
                                            //     const {presignedUrl} = response.data;
                                            //
                                            //     return axios.delete(presignedUrl);
                                            //     // console.log({responseDel});
                                            //
                                            //     // message.success('File removed successfully');
                                            // } catch (err) {
                                            //     console.error('Error removing file:', err);
                                            //     message.error('Error removing file');
                                            // }
                                            if (!removingFiles.includes(index))
                                                setRemovingFiles([...removingFiles, index]);
                                            return axiosApiInstance
                                                .post('/api/products/releases/delete-object', {
                                                    fileName: (file as any).name,
                                                    folderName:
                                                        firstFormPageRef?.current?.getFieldValue('upc_ean')
                                                })
                                                .then(() => {
                                                    setRemovingFiles(
                                                        removingFiles.filter((i) => i !== index)
                                                    );
                                                    secondFormPageRef?.current?.setFieldValue(
                                                        ['art_tracks', index, 'is_uploaded'],
                                                        false
                                                    );
                                                });
                                            //     .catch((err) => {
                                            //     console.error('Error removing file:', err);
                                            //     message.error('Error removing file');
                                            // } );
                                        },
                                        disabled: removingFiles.includes(index),
                                        locale: {
                                            downloadFile: 'Download',
                                            previewFile: 'Preview',
                                            removeFile: 'Remove',
                                            uploadError: 'Upload Error',
                                            uploading: 'Uploading...'
                                        },
                                        showUploadList: {
                                            showDownloadIcon: true
                                        },
                                        onDownload: async (file) => {
                                            try {
                                                const response = await axiosApiInstance.post(
                                                    '/api/products/releases/generate-presigned-url-get',
                                                    {
                                                        fileName: (file as any).name,
                                                        folderName:
                                                            firstFormPageRef?.current?.getFieldValue(
                                                                'upc_ean'
                                                            )
                                                    }
                                                );

                                                const { presignedUrl } = response.data;

                                                window.open(presignedUrl, '_blank');
                                            } catch (err) {
                                                console.error('Error downloading file:', err);
                                                message.error('Error downloading file');
                                            }
                                        },
                                        listType: 'text'
                                        // onChange: (info) => {
                                        //     // console.log('onChange', info);
                                        //     if (info.)
                                        // }
                                    }}
                                    convertValue={(value) => {
                                        // console.log('1', {value}, secondFormPageRef?.current?.getFieldValue(['art_tracks', index, 'is_uploaded']));
                                        if (
                                            secondFormPageRef?.current?.getFieldValue([
                                                'art_tracks',
                                                index,
                                                'is_uploaded'
                                            ]) &&
                                            !value
                                        ) {
                                            return [
                                                {
                                                    uid: index,
                                                    name: secondFormPageRef?.current?.getFieldValue([
                                                        'art_tracks',
                                                        index,
                                                        'filename'
                                                    ]),
                                                    status: 'done'
                                                }
                                            ];
                                        }
                                        return value;
                                    }}
                                />

                                <ProFormSwitch hidden name={'is_uploaded'} />

                                <ProFormGroup>
                                    <ProFormItem label=' '>
                                        <Button
                                            type='link'
                                            size='small'
                                            style={{
                                                marginRight: -4,
                                                marginLeft: -10
                                            }}
                                            icon={
                                                !!action.getCurrentRowData().artists_group_collapsed ? (
                                                    <Tooltip title='Configure Artists'>
                                                        <DownCircleOutlined />
                                                    </Tooltip>
                                                ) : (
                                                    <UpCircleOutlined />
                                                )
                                            }
                                            onClick={() => {
                                                const collaped =
                                                    !!action.getCurrentRowData().artists_group_collapsed;
                                                action.setCurrentRowData({
                                                    artists_group_collapsed: !collaped
                                                });
                                                // setTrackAction(action);
                                                // navigate('artists', { state: { background: location } });
                                            }}
                                        />
                                    </ProFormItem>
                                    <ProFormItem label=' '>
                                        <Tooltip title='Delete Row'>
                                            <Button
                                                type='link'
                                                size='small'
                                                style={{
                                                    marginRight: -4,
                                                    marginLeft: -26
                                                }}
                                                icon={<DeleteOutlined />}
                                                disabled={listRef?.current?.getList().length < 2}
                                                danger
                                                onClick={() => {
                                                    action.remove(index);
                                                }}
                                            />
                                        </Tooltip>
                                    </ProFormItem>
                                    <ProFormSwitch
                                        initialValue={true}
                                        name={'artists_group_collapsed'}
                                        hidden
                                    />
                                </ProFormGroup>
                            </ProFormGroup>
                            <ProFormGroup
                                colProps={{ span: 24 }}
                                direction='horizontal'
                                collapsible
                                collapsed={!!action.getCurrentRowData().artists_group_collapsed}
                                // style={{ paddingLeft: '20px', paddingRight: '20px' }}
                            >
                                <ProCard colSpan={24} direction='column' bordered title='Collaborators'>
                                    <ProCard>
                                        <ProCard colSpan={12}>
                                            <ProFormGroup direction='vertical'>
                                                <ProFormSelect
                                                    label={'Main Artists'}
                                                    name={'main_artists'}
                                                    mode='multiple'
                                                    width={'lg'}
                                                    request={fetchArtistOptions}
                                                />
                                                <ProFormSelect
                                                    label='Featuring Artists'
                                                    name={'featuring_artists'}
                                                    mode='multiple'
                                                    width={'lg'}
                                                    request={fetchArtistOptions}
                                                />
                                                <ProFormSelect
                                                    label='Arrangers'
                                                    name={'arrangers'}
                                                    mode='multiple'
                                                    width={'lg'}
                                                    request={fetchArtistOptions}
                                                />
                                                <ProFormSelect
                                                    label='Authors'
                                                    name={'authors'}
                                                    mode='multiple'
                                                    width={'lg'}
                                                    request={fetchArtistOptions}
                                                />
                                            </ProFormGroup>
                                        </ProCard>
                                        <ProCard>
                                            <ProFormGroup direction='vertical'>
                                                <ProFormSelect
                                                    label='Producers'
                                                    name={'producers'}
                                                    mode='multiple'
                                                    width={'lg'}
                                                    request={fetchArtistOptions}
                                                />
                                                <ProFormSelect
                                                    label='Publishers'
                                                    name={'publishers'}
                                                    mode='multiple'
                                                    width={'lg'}
                                                    request={fetchArtistOptions}
                                                />
                                                <ProFormSelect
                                                    label='Remixers'
                                                    name={'remixers'}
                                                    mode='multiple'
                                                    width={'lg'}
                                                    request={fetchArtistOptions}
                                                />
                                                <ProFormSelect
                                                    label='Composers'
                                                    name={'composers'}
                                                    mode='multiple'
                                                    width={'lg'}
                                                    request={fetchArtistOptions}
                                                />
                                            </ProFormGroup>
                                        </ProCard>
                                    </ProCard>
                                    <ProCard direction='row' title='Miscellaneous'>
                                        <ProFormGroup direction='horizontal'>
                                            <ProFormSelect
                                                label='Title Language'
                                                name='title_language'
                                                allowClear={false}
                                                width={'sm'}
                                                initialValue={'GR'}
                                                options={countryOptions}
                                                showSearch
                                            />
                                            <ProFormSelect
                                                label='Lyrics Language'
                                                name='lyrics_language'
                                                allowClear={false}
                                                width={'sm'}
                                                initialValue={'GR'}
                                                showSearch
                                                options={countryOptions}
                                            />
                                            <ProFormDigit
                                                label='Preview Start'
                                                name='preview_start'
                                                width='xs'
                                                normalize={(d) => d.toString()}
                                                initialValue={45}
                                            />
                                            <ProFormSelect
                                                label='Price'
                                                name='price'
                                                width={'sm'}
                                                initialValue={'MID'}
                                                options={[
                                                    {
                                                        label: 'Back: 0.69€ / 0.69$ / 0.59£',
                                                        value: 'BACK'
                                                    },
                                                    {
                                                        label: 'Mid: 0.99€ / 0.99$ / 0.79£',
                                                        value: 'MID'
                                                    },
                                                    {
                                                        label: 'Front: 1.29€ / 1.29$ / 0.99£',
                                                        value: 'FRONT'
                                                    }
                                                ]}
                                            />
                                            <ProFormRadio.Group
                                                label='Parental Advisory'
                                                name='parental_advisory'
                                                allowClear
                                                options={['Yes', 'No', 'Cleaned']}
                                            />
                                            <ProFormRadio.Group
                                                label='Instrumental'
                                                name='instrumental'
                                                initialValue={'No'}
                                                options={['Yes', 'No']}
                                            />
                                        </ProFormGroup>
                                    </ProCard>
                                </ProCard>
                            </ProFormGroup>
                        </ProCard>
                    </div>
                );
            }}
        </ProFormList>
    );
};

export default StepsFormSecondPage;
