import {Column, Line} from '@ant-design/plots';
import {Empty, Row, Tag} from 'antd';
import Text from 'antd/lib/typography/Text';
import React, {Component} from 'react';
import {aggregatedMetric} from '../../../api/aggregateReportBuilder';
import {roundNum} from '../../../common/utils';
import './index.css';

// const wildCardDimension = 'day'
const decimals = 2

export interface IDashboardReportingGraphV2ComparativeProps {
    //Here we pass the Props Interface
    metric: aggregatedMetric
    cumulative: boolean
    label: string | false
    data1?: any
    data2?: any
    dimension: string
    unit: string
    slider?: boolean
}

export interface IDashboardReportingGraphV2ComparativeState {
    //here we pass the State Interface
    // isLoading: boolean;
    // data?: any
    // formRef: React.RefObject<FormInstance<any>>;
    // dimension?: string
    // dimension: 'day' | 'month'
    graphData?: any
    data1?: any
    data2?: any
}


//class ComponentName Component<PropsInterface, StateInterface>
class DashboardReportingGraphV2Comparative extends Component<IDashboardReportingGraphV2ComparativeProps, IDashboardReportingGraphV2ComparativeState> {

    constructor(props: IDashboardReportingGraphV2ComparativeProps) {
        super(props)

        this.state = {
            // isLoading: false,
            // formRef: React.createRef<FormInstance<any>>()
            data1: this.props.data1,
            data2: this.props.data2,
        }
    }


    //Add style here
    style = {};

    // Before the component mounts, we initialise our state
    componentWillMount() {

        // let data1 = [["2021-10", 5], ["2021-11", 8]]
        // let data2 = [["2021-11", 10], ["2021-12", 15]]
        // let data1Trans = data1.map((i: any[]) => ({date: i[0], value: i[1]}))
        // let data2Trans = data2.map((i: any[]) => ({date: i[0], value: i[1]}))

        // let data1Trans = this.props.data1.map((i: any[]) => ({[this.props.dimension]: i[0], [this.props.metric]: i[1]}))
        // let data2Trans = this.props.data2.map((i: any[]) => ({[this.props.dimension]: i[0], [this.props.metric]: i[1]}))

        // let res = this.fullOuterJoin('data1', 'data2', data1Trans, data2Trans, 'date')
        // let res = this.fullOuterJoin('data1', 'data2', data1Trans, data2Trans, this.props.dimension)

        // res.sort((a: any, b: any) => {
        //     let da = new Date(a[this.props.dimension]),
        //         db = new Date(b[this.props.dimension]);
        //     return da < db;
        // });

        // let graphData = res.map((i: any) => ({
        //     data1: i.data1 ? i.data1[this.props.metric] : 0,
        //     data2: i.data2 ? i.data2[this.props.metric] : 0,
        //     [this.props.dimension]: i[this.props.dimension]
        // }))

        let graphData = [
            ...this.props.data1.map((i: any[]) => ({
                [this.props.dimension]: i[0],
                [this.props.metric]: i[1],
                category: 'Left'
            })),
            ...this.props.data2.map((i: any[]) => ({
                [this.props.dimension]: i[0],
                [this.props.metric]: i[1],
                category: 'Right'
            })),
        ]

        graphData.sort((a: any, b: any) => {
            let dsa = a[this.props.dimension].split('-'),
                dsb = b[this.props.dimension].split('-')
            if (new Date(dsa[0], dsa[1]).valueOf() > new Date(dsb[0], dsb[1]).valueOf()) {
                return 1
            } else if (new Date(dsa[0], dsa[1]).valueOf() < new Date(dsb[0], dsb[1]).valueOf()) {
                return -1
            } else return 0
        });

        this.setState({graphData})
    }

    // After the component did mount, we set the state.
    componentDidMount() {


    }


    // static getDerivedStateFromProps(props: IDashboardReportingGraphV2ComparativeProps, current_state: IDashboardReportingGraphV2ComparativeState) {
    //     console.log('GETDERIVEDSTATEFROMPROPS')
    //     if (current_state.data1 !== props.data1 || current_state.data2 !== props.data2) {
    //
    //         let graphData = [
    //             ...props.data1.map((i: any[]) => ({
    //                 [props.dimension]: i[0],
    //                 [props.metric]: i[1],
    //                 category: 'Left'
    //             })),
    //             ...props.data2.map((i: any[]) => ({
    //                 [props.dimension]: i[0],
    //                 [props.metric]: i[1],
    //                 category: 'Right'
    //             })),
    //         ]
    //
    //         graphData.sort((a: any, b: any) => {
    //             let dsa = a[props.dimension].split('-'),
    //                 dsb = b[props.dimension].split('-')
    //             if (new Date(dsa[0], dsa[1]).valueOf() > new Date(dsb[0], dsb[1]).valueOf()) {
    //                 return 1
    //             } else if (new Date(dsa[0], dsa[1]).valueOf() < new Date(dsb[0], dsb[1]).valueOf()) {
    //                 return -1
    //             } else return 0
    //         });
    //
    //
    //         return {
    //             data1: props.data1,
    //             data2: props.data2,
    //             graphData: graphData
    //         }
    //     }
    //     return null
    // }

    fullOuterJoin = (dataset_a_name: any, dataset_b_name: any, dataset_a: any, dataset_b: any, key: any) => {
        const getNullProperties = (keys: any) => Object.fromEntries(keys.map((k: any) => [k, null]));
        var data = {[dataset_a_name]: dataset_a, [dataset_b_name]: dataset_b},
            result = Object
                .entries(data)
                .reduce((r: any, [table, rows]) => {
                    //forEach dynamic destructuring
                    rows.forEach(({[key]: id, ...row}) => {
                        if (!r[id]) r.items.push(r[id] = {[key]: id, ...getNullProperties(r.tables)});
                        r[id][table] = row;
                    });
                    r.tables.push(table);
                    r.items.forEach((item: any) => r.tables.forEach((t: any) => item[t] = item[t] || null));
                    return r;
                }, {tables: [], items: []})
                .items;

        return result;
    }


    render() {
        const graphHeight = 420
        // const graphHeight = '100%'

        // const data =

        return (
            <div className="DashboardReportingGraphV2Comparative" style={{width: '100%'}}>


                {/* Graph */}
                <Row>
                    <div style={{height: graphHeight, width: '100%'}}>
                        {this.props.label && <Row justify='end'>
                            <Tag>{this.props.label + (this.props.unit ? ' (' + this.props.unit + ')' : '')}</Tag>
                        </Row>}

                        {!this.state.data1 || !this.state.data2 &&
                        <Row justify='center' style={{height: graphHeight, flex: 1, alignContent: 'center'}}> <Empty
                            description={<Text type='secondary'>Please select some parameters.</Text>}/></Row>}

                        {this.state.data1 && this.state.data2 && !this.props.cumulative &&
                        <div style={{height: graphHeight, flex: 1}}>
                            <Column data={this.state.graphData}
                                    xField={this.props.dimension}
                                    yField={this.props.metric}
                                    seriesField={'category'}
                                    isGroup={true}
                                    tooltip={{
                                        formatter: (datum) => {
                                            // console.log('datum', datum, datum[this.props.metric] % 1 === 0 );
                                            return {
                                                name: datum[this.props.dimension],
                                                value: roundNum(datum[this.props.metric], decimals) + this.props.unit
                                            };
                                        },
                                    }}
                                    xAxis={{
                                        label: {
                                            formatter: (v) => {
                                                if (this.props.dimension === 'month')
                                                    return new Date(v).toLocaleString('default', {
                                                        year: 'numeric',
                                                        month: 'short'
                                                    })
                                                return v
                                            },
                                        },
                                    }}
                                    yAxis={{
                                        label: {
                                            formatter: (v) => {
                                                return v + ' ' + this.props.unit
                                            },
                                        },
                                    }}
                                    slider={this.props.slider ? {
                                        start: 0,
                                        end: 1,
                                    } : false}
                            />
                        </div>
                        }

                        {this.state.data1 && this.state.data2 && this.props.cumulative &&
                        // <div style={{ height: graphHeight, flex: 1 }}>
                        <Line
                            data={this.state.graphData}
                            xField={this.props.dimension}
                            yField={this.props.metric}
                            seriesField={'category'}
                            autoFit
                            tooltip={{
                                formatter: (datum) => {
                                    return {
                                        name: datum[this.props.dimension],
                                        value: roundNum(datum[this.props.metric], decimals) + this.props.unit
                                    };
                                },
                            }}
                            xAxis={{
                                label: {
                                    formatter: (v) => {
                                        if (this.props.dimension === 'month')
                                            return new Date(v).toLocaleString('default', {
                                                year: 'numeric',
                                                month: 'short'
                                            })
                                        return v
                                    },
                                },
                            }}
                            yAxis={{
                                label: {
                                    formatter: (v) => {
                                        return v + ' ' + this.props.unit
                                    },
                                },
                            }}
                        />
                            // </div>
                        }

                    </div>

                </Row>


            </div>
        );
    }
}

export default DashboardReportingGraphV2Comparative;