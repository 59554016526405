export const YOUTUBE_API_KEY = 'AIzaSyCe4DpyKpKXx--e8-OHlKlkV1_BhVu5i3U';


export const OAUTH2_SCOPES = [
	'https://www.googleapis.com/auth/youtube',
	'https://www.googleapis.com/auth/youtube.readonly',
	'https://www.googleapis.com/auth/youtubepartner',
	'https://www.googleapis.com/auth/yt-analytics-monetary.readonly',
	'https://www.googleapis.com/auth/yt-analytics.readonly',
];
export const ONE_MONTH_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 30;
export const OAUTH2_CLIENT_ID = '145704441841-ggh3phmeurle5ecqe3bilfkcp7me724s.apps.googleusercontent.com'; // this comes from iasonasc@dm account credentials -> OAuth 2.0 Client IDs -> DMUP Reports
export const API_KEY = 'AIzaSyBCmnzLhSIMHL5kdyqvksrQW07z-47cujw'

export const DISCOVERY_DOC_YT = 'https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'
export const DISCOVERY_DOC_YT_AL = 'https://www.googleapis.com/discovery/v1/apis/youtubeAnalytics/v2/rest'

