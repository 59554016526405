import * as React from 'react';
import {Access} from "../../../../components/Access";
import {ProTable} from "@ant-design/pro-components";
import {Avatar, Space, Typography} from "antd";
import {numFormatterInt} from "./TikTokBenchmarks";
import {UserAuth} from "../../../../context/AuthContext";
import {useAtom, useAtomValue} from "jotai";
import {channelsAtom, monthlyViewsAtom} from "./atoms";

type Props = {};

export const MonthView = (props: Props) => {
        const [{data: monthlyViews}] = useAtom(monthlyViewsAtom)
        const {data: accounts} = useAtomValue(channelsAtom)
        const {can} = UserAuth();
        return (
            <div>
                <Access accessible={can('view', 'benchmarks::breakdown')} fallback={null}>
                    <ProTable<API.Benchmarks.TiktokBenchmarksAccountDataPoint>
                        // actionRef={actionRefMonthly}
                        cardBordered
                        rowKey={'userID'}
                        search={false}
                        toolBarRender={false}
                        dataSource={monthlyViews.data}
                        columns={[
                            // {
                            //     key: 'index',
                            //     valueType: 'indexBorder',
                            //     align: 'center',
                            // },
                            {
                                key: 'image',
                                // valueType: 'indexBorder',
                                align: 'center',
                                width: 100,
                                render: (dom, entity) => {
                                    const img = accounts?.find((a) => a.userID === entity.userID)?.image
                                    return <Avatar
                                        src={`data:image/jpeg;base64,${img}`}
                                        style={{width: 34, height: 34}}
                                        shape={"circle"}
                                    />
                                }
                            },

                            {
                                title: 'Account',
                                dataIndex: 'userID',
                                key: 'userID',
                                sorter: (a, b) => a.userID.localeCompare(b.userID),
                                // render the name with an icon to open the channel in a new tab
                                render: (value, record) => {
                                    const name = accounts.find((a) => a.userID === record.userID)?.name
                                    return (
                                        <Space size={0} direction={'vertical'}>
                                            <Typography.Link strong
                                                             href={`https://www.tiktok.com/@${record.userID}`}
                                                             target={'_blank'}
                                            >{value}</Typography.Link>
                                            <Typography.Text type={'secondary'}>{name}</Typography.Text>
                                        </Space>
                                    )
                                }
                            },
                            {
                                title: 'Followers',
                                dataIndex: 'followers',
                                key: 'followers',
                                render: (value) => numFormatterInt.format(+value),
                                sorter: (a, b) => a.followers - b.followers,
                                // defaultSortOrder: 'descend',
                            },
                            {
                                title: 'Following',
                                dataIndex: 'following',
                                key: 'following',
                                render: (value) => numFormatterInt.format(+value),
                                sorter: (a, b) => a.following - b.following,
                            },
                            {
                                title: 'Likes',
                                dataIndex: 'likes',
                                key: 'likes',
                                render: (value) => numFormatterInt.format(+value),
                                sorter: (a, b) => a.likes - b.likes,
                            },
                            {
                                title: 'Uploads',
                                dataIndex: 'uploads',
                                key: 'uploads',
                                render: (value) => numFormatterInt.format(+value),
                                sorter: (a, b) => a.uploads - b.uploads,
                            },
                        ]}

                        summary={() => {
                            return (
                                <ProTable.Summary fixed="top">
                                    <ProTable.Summary.Row style={{backgroundColor: '#e6f4ff'}}>
                                        <ProTable.Summary.Cell index={0}></ProTable.Summary.Cell>
                                        <ProTable.Summary.Cell index={1}>
                                            <Typography.Text strong>Average</Typography.Text>
                                        </ProTable.Summary.Cell>
                                        <ProTable.Summary.Cell index={2}>
                                            <Typography.Text
                                                strong>{numFormatterInt.format(monthlyViews.avg.followers)}</Typography.Text>
                                        </ProTable.Summary.Cell>
                                        <ProTable.Summary.Cell index={3}>
                                            <Typography.Text
                                                strong>{numFormatterInt.format(monthlyViews.avg.following)}</Typography.Text>
                                        </ProTable.Summary.Cell>
                                        <ProTable.Summary.Cell index={4}>
                                            <Typography.Text
                                                strong>{numFormatterInt.format(monthlyViews.avg.likes)}</Typography.Text>
                                        </ProTable.Summary.Cell>
                                        <ProTable.Summary.Cell index={5}>
                                            <Typography.Text
                                                strong>{numFormatterInt.format(monthlyViews.avg.uploads)}</Typography.Text>
                                        </ProTable.Summary.Cell>
                                    </ProTable.Summary.Row>
                                </ProTable.Summary>
                            )
                        }}
                        sticky={{offsetHeader: 54}}

                        pagination={false}
                    />
                </Access>
            </div>
        );
    }
;

export default MonthView;