import * as React from 'react';
import {Suspense} from 'react';
import {RESET} from "jotai/utils";
import {Avatar, Col, Modal, Row, Select} from "antd";
import {useAtom} from "jotai";
import {
    channelMetricsAtom,
    loadableMonthlyChartDataAtom,
    monthModalFilterSearchAtom,
    monthModalSearchFilteredChannelsAtom,
    monthModalSelectedChannelsAtom,
    rankedChannelsAtom,
    selectedChannelMetricAtom,
    selectedChannelMetricObjAtom,
    showMonthModalAtom,
    yFieldAtom
} from "./atoms";
import {ProCard, ProTable} from "@ant-design/pro-components";
import './MonthlyChartModal.css'
import {Line} from "@ant-design/plots";
import {numFormatterInt} from "./YouTubeBenchmarks";
import dayjs from "dayjs";
import {PageLoading} from "@ant-design/pro-layout";
import {useAtomValue, useSetAtom} from "jotai/index";

type Props = {};

export const MonthlyChartModal = (props: Props) => {
    const setShowMonthModal = useSetAtom(showMonthModalAtom);
    const setSelectedChannels = useSetAtom(monthModalSelectedChannelsAtom);
    const setSelectedChannelMetric = useSetAtom(selectedChannelMetricAtom);
    const setFilterSearch = useSetAtom(monthModalFilterSearchAtom)

    return (
        <div>
            <Modal
                className={'monthly-chart-modal-container'}
                open={true}
                onCancel={() => {
                    setShowMonthModal(RESET)
                    setSelectedChannels(RESET)
                    setSelectedChannelMetric(RESET)
                    setFilterSearch(RESET)
                }}
                width={'100%'}
                bodyStyle={{maxHeight: '70vh'}}
                footer={null}
            >
                <Suspense fallback={
                    <PageLoading style={{height: '70vh'}}/>
                }>
                    <ModalContent/>
                </Suspense>
            </Modal>
        </div>
    );
};


export const ModalContent = (props: Props) => {
    const [selectedChannels, setSelectedChannels] = useAtom(monthModalSelectedChannelsAtom);
    const rankedChannels = useAtomValue(rankedChannelsAtom);
    const channelMetrics = useAtomValue(channelMetricsAtom);
    const [selectedChannelMetric, setSelectedChannelMetric] = useAtom(selectedChannelMetricAtom);
    const [monthlyChartData] = useAtom(loadableMonthlyChartDataAtom);
    const selectedChannelMetricObj = useAtomValue(selectedChannelMetricObjAtom)
    const searchFilteredChannels = useAtomValue(monthModalSearchFilteredChannelsAtom)
    const yField = useAtomValue(yFieldAtom)
    const [filterSearch, setFilterSearch] = useAtom(monthModalFilterSearchAtom)

    return (
        <Row
            style={{maxHeight: 'inherit'}}
        >
            <Col span={8} style={{maxHeight: 'inherit'}}>
                <ProTable
                    columns={[
                        {
                            // channel
                            // title: 'Channel',
                            // dataIndex: 'image',
                            render: (dom, entity) => (
                                <Avatar
                                    src={`data:image/jpeg;base64,${entity?.image}`}
                                    style={{width: 34, height: 34}}
                                    shape={"circle"}
                                />
                            )
                        },
                        {
                            // channel
                            title: 'Channel',
                            dataIndex: 'channelName',
                        },
                        {
                            //     subscribers
                            title: 'Subscribers Lifetime',
                            dataIndex: 'totalSubscribersLifetime',
                            sorter: (a, b) => a.totalSubscribersLifetime - b.totalSubscribersLifetime,
                            render: (value) => numFormatterInt.format(+value),
                        },
                        // {
                        //     title: channelMetrics.find((metric) => metric.data_index === selectedChannelMetric)?.name,
                        //     dataIndex: channelMetrics.find((metric) => metric.data_index === selectedChannelMetric)?.json_index,
                        //     sorter: (a, b) => a[channelMetrics.find((metric) => metric.data_index === selectedChannelMetric)?.json_index] - b[channelMetrics.find((metric) => metric.data_index === selectedChannelMetric)?.json_index],
                        // },
                    ]}
                    // dataSource={rankedChannels}
                    dataSource={searchFilteredChannels}
                    search={false}
                    // options={false}
                    options={{
                        density: false,
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        search: {
                            placeholder: "Search...",
                            onChange: (e) => {
                                setFilterSearch(e.target.value)
                            },
                            value: filterSearch,
                            allowClear: true,
                            style: {width: '100%', flexGrow: 1}
                        },
                    }}
                    // cardBordered
                    pagination={false}
                    tableStyle={{
                        padding: 0, margin: 0, paddingBlock: 0, paddingInline: 0,
                        // overflowY: 'auto',
                        // maxHeight: 'inherit'
                    }}
                    style={{
                        overflowY: 'auto',
                        maxHeight: 'inherit'
                    }}
                    rowKey="channelID"
                    rowSelection={{
                        selectedRowKeys: selectedChannels,
                        type: 'checkbox',
                        onChange: (selectedRowKeys: string[], selectedRows) => {
                            // console.log(selectedRowKeys, selectedRows);
                            setSelectedChannels(selectedRowKeys)
                        },
                    }}
                    tableAlertRender={false}
                    headerTitle={`${selectedChannels.length} Channels Selected`}

                />
            </Col>

            <Col span={16} style={{maxHeight: 'inherit'}}>
                <ProCard
                    colSpan={16}
                    // layout="center"
                    // bordered
                    split={'horizontal'}
                    // style={{maxHeight: 'inherit'}}
                    style={{height: '100%'}}
                    bodyStyle={{height: '100%'}}
                >
                    <ProCard>

                        <Select
                            value={selectedChannelMetric}
                            options={channelMetrics.map((metric) => ({
                                label: metric.name,
                                value: metric.data_index
                            }))}
                            dropdownMatchSelectWidth={false}
                            onChange={(value) => {
                                setSelectedChannelMetric(value)
                            }}
                        />
                    </ProCard>
                    <ProCard
                        style={{height: '100%'}}
                        bodyStyle={{height: '100%'}}
                        className={'monthly-chart-container-line-card'}
                    >
                        <Line
                            style={{height: '100%'}}
                            // point style
                            point={{
                                size: 2,
                                shape: 'dot',
                            }}
                            smooth={true}
                            loading={monthlyChartData?.state === 'loading'}
                            data={monthlyChartData.state === 'hasData' ? monthlyChartData.data : []}

                            xField={'date'}
                            // yField={selectedChannelMetricObj?.json_index}
                            yField={yField}
                            seriesField={'channelID'}
                            slider={{
                                start: 0,
                                end: 1,
                            }}
                            meta={{
                                date: {
                                    formatter: (v) => {
                                        return dayjs(v).format('MMM YYYY')
                                    }
                                },
                                [selectedChannelMetricObj?.json_index]: {
                                    formatter: (v) => {
                                        return numFormatterInt.format(v || 0)
                                    }
                                },
                                channelID: {
                                    formatter: (v) => {
                                        return rankedChannels.find((channel) => channel.channelID === v)?.channelName
                                    }
                                }
                            }}

                        />
                    </ProCard>

                </ProCard>

            </Col>
        </Row>
    );
};

export default MonthlyChartModal;