export const cmsIdToName: { [k in keyof typeof cmsIdEnum]: string } = {
    'xcDgV1hLEnOLtNvdGppirg': 'Digital Minds Ltd (Managed)',
    'BFEqhq9k4jWPkvgaLq_Qug': 'Digital Minds Live',
    'y8Sek62JbLMqQ2rx2TXgpg': 'Digital Minds Ltd-srav',
    'idnX3ho04dWOzhWsooMvHw': 'Digital Minds S',
    'Obm3Siph0b-6wTgyPbtJZw': 'Digital Minds Ltd',
    'nQT84PQuawtnFBF5XIWixQ': 'Digital Minds Music',
    'ZRcggOuStL2Jbwqu0TqgYA': 'Digital Minds Entertainment (M)',
    '_amtO2sTzzw2cuVqaxQ2Kg': 'Digital Minds Entertainment'
}

// export enum cmsIdEnum {
//     'xcDgV1hLEnOLtNvdGppirg',
//     'BFEqhq9k4jWPkvgaLq_Qug',
//     'y8Sek62JbLMqQ2rx2TXgpg',
//     'idnX3ho04dWOzhWsooMvHw',
//     'Obm3Siph0b-6wTgyPbtJZw',
//     'nQT84PQuawtnFBF5XIWixQ',
//     'ZRcggOuStL2Jbwqu0TqgYA',
//     '_amtO2sTzzw2cuVqaxQ2Kg',
// }

export const cmsIdToKey: { [k in keyof typeof cmsIdEnum]: string } = {
    'xcDgV1hLEnOLtNvdGppirg': 'LTD M',
    'BFEqhq9k4jWPkvgaLq_Qug': 'Live',
    'y8Sek62JbLMqQ2rx2TXgpg': 'Srav',
    'idnX3ho04dWOzhWsooMvHw': 'S',
    'Obm3Siph0b-6wTgyPbtJZw': 'LTD',
    'nQT84PQuawtnFBF5XIWixQ': 'Music',
    'ZRcggOuStL2Jbwqu0TqgYA': 'Entertainment M',
    '_amtO2sTzzw2cuVqaxQ2Kg': 'Entertainment',
}

export enum cmsIdEnum {
    'xcDgV1hLEnOLtNvdGppirg' = 'xcDgV1hLEnOLtNvdGppirg',
    'BFEqhq9k4jWPkvgaLq_Qug' = 'BFEqhq9k4jWPkvgaLq_Qug',
    'y8Sek62JbLMqQ2rx2TXgpg' = 'y8Sek62JbLMqQ2rx2TXgpg',
    'idnX3ho04dWOzhWsooMvHw' = 'idnX3ho04dWOzhWsooMvHw',
    'Obm3Siph0b-6wTgyPbtJZw' = 'Obm3Siph0b-6wTgyPbtJZw',
    'nQT84PQuawtnFBF5XIWixQ' = 'nQT84PQuawtnFBF5XIWixQ',
    'ZRcggOuStL2Jbwqu0TqgYA' = 'ZRcggOuStL2Jbwqu0TqgYA',
    '_amtO2sTzzw2cuVqaxQ2Kg' = '_amtO2sTzzw2cuVqaxQ2Kg',
}

// export const aggregatedMetricUnit = {
//     views: 'M',
//     subscribers: '',
//     watchTime: 'KHr',
//     revenue: '€'
// }

export const defaultRevenueCurrency = '€'