import React, {useEffect, useState} from 'react';
import {PageContainer, ProForm, ProFormRadio} from "@ant-design/pro-components";
import {ConfigProvider, Empty, Row, Space, Statistic} from 'antd';
import enUSIntl from "antd/lib/locale/en_US";
import axiosApiInstance from "../../../api/axiosClient";
import {ProFormDateRangePicker, ProFormSelect} from "@ant-design/pro-form";
import dayjs from "dayjs";
import {useQuery} from '@tanstack/react-query';
import {Column} from "@ant-design/plots";
import ColumnLoading from './ColumnLoading';
import {each, groupBy} from "@antv/util";

type Props = {};

// Product
export type DataType = {
    streams: number
    revenue: number
};

// DataSource
export type DataSourceType = {
    currency: number
    data: DataType[]
    total: number
};

//
export type TierType = {
    label: string
    value: number
}

// Filters
export type FiltersType = {
    startDate: string
    endDate: string
    tiers: number[]
    customers: number[]
    metric: string
}

//
// Constants
//
const metrics = [
    {
        label: 'DM Revenue',
        value: 'dm_revenue'
    },
    {
        label: 'Customer Revenue',
        value: 'customer_revenue'
    },
    {
        label: 'Views/Streams',
        value: 'streams'
    },
]

const TierMetrics = (props: Props) => {
    //
    // REACT QUERY - Functions
    //
    // Query functions, always return a promise
    // If you put them outside your component, then they don't have to rerender every time the component mounts
    const {data: tiers, status: tierStatus} = useQuery({
        queryKey: ['tiers'],
        queryFn: () => axiosApiInstance.get('/api/tiermetrics/tiers'),
        select: (res) => res.data.map((tier) => (
            {
                label: "Tier " + tier.value,
                value: tier.id
            }
        ))
    })

    // Dependent useQuery - managed customers are dependent to tiers
    const {data: managedCustomers, status: customerStatus} = useQuery({
        queryKey: ['customers'],
        queryFn: () => axiosApiInstance.get('/api/tiermetrics/managedcustomers'),
        // select: Form response's data
        // What should we keep from response?
        select: (res) => res.data,
        // useQuery: Dependent Query
        // Dependent (or serial) queries depend on previous ones to finish before they can execute. To achieve this, it's as easy as using the enabled option to tell a query when it is ready to run
        enabled: !!tiers
    })


    //
    // Initialize State
    //
    const [annotations, setAnnotations] = useState<any>();
    const currencyMap: any = {43: '€', 46: '£', 142: '$'};

    const currDate = new Date();

    // Used mainly for querying. They are updated every time a filter is changed
    const [filters, setFilters] = useState<FiltersType>({
        startDate: currDate.getFullYear() - 1 + '-' + (currDate.getMonth() + 1 < 10 ? '0' : '') + (currDate.getMonth() + 1) + '-01',
        endDate: currDate.getFullYear() + '-' + (currDate.getMonth() + 1 < 10 ? '0' : '') + (currDate.getMonth() + 1) + '-01',
        tiers: tierStatus === 'success' ? tiers[0].value : [],
        customers: customerStatus === 'success' ? managedCustomers : [],
        metric: "dm_revenue"
    });

    // Used for displaying data on graphs. They are updated every time the "submit" button is pressed
    const [filtersToTrigger, setFiltersToTrigger] = useState<FiltersType>({
        startDate: currDate.getFullYear() - 1 + '-' + (currDate.getMonth() + 1 < 10 ? '0' : '') + (currDate.getMonth() + 1) + '-01',
        endDate: currDate.getFullYear() + '-' + (currDate.getMonth() + 1 < 10 ? '0' : '') + (currDate.getMonth() + 1) + '-01',
        tiers: tierStatus === 'success' ? tiers[0].value : [],
        customers: customerStatus === 'success' ? managedCustomers : [],
        metric: "dm_revenue"
    });


    //
    // Functions
    //
    const getChartData = async () => {
        if (tierStatus != 'loading' && customerStatus != 'loading') {
            // console.log("GET CHART DATA")
            return axiosApiInstance.post('/api/tiermetrics/chartdata', {
                ...filters
            });
        }
    }

    const formAnnotations = () => {
        //
        // Annotations
        //
        if (chartData) {
            // console.log("FORM ANNOTATIONS")
            let annotationsArr: any = [];

            each(groupBy(chartData.data, 'month'), (values, k) => {
                let value = values.reduce((a: any, b: { metric: any }) => a + b.metric, 0);

                let roundedValue = Math.round((value as number) * 100) / 100;

                let finalValue = (roundedValue.toLocaleString('el-GR') || 0)

                if (filtersToTrigger.metric == "dm_revenue" || filtersToTrigger.metric == "customer_revenue") {
                    finalValue = finalValue + ' ' + currencyMap[chartData.currency]
                    // finalValue = roundedValue.toFixed(2) + ' ' + currencyMap[chartData.currency]
                    // finalValue = parseFloat(String(roundedValue)).toFixed(2) + ' ' + currencyMap[chartData.currency]
                }

                annotationsArr.push({
                    type: 'text',
                    position: [k, value],
                    content: `${ finalValue }`,
                    style: {
                        textAlign: 'center',
                        fontSize: 14,
                        fill: 'rgba(0,0,0,0.85)'
                    },
                    offsetY: -15
                });
            });

            setAnnotations(annotationsArr);
        }
    };

    // REACT QUERY - Function
    // status: First time the query runs. Refers to data
    // fetchStatus: Every other time the query runs. Refers to fetching my data
    const {data: chartData, status: dataStatus, fetchStatus: dataFetchStatus, refetch: refetchData} = useQuery({
        queryKey: ['chartData'],
        queryFn: () => getChartData(),
        select: (res) => res.data
    })

    // UseEffect for printing data
    useEffect(() => {
        // console.log("tierStatus: " + tierStatus)
        // console.log("customerStatus: " + customerStatus)

        // console.log(metrics)
        // console.log(tiers)
        // console.log(managedCustomers)
        // console.log(filters)
        // console.log(filtersToTrigger)
        // console.log(chartData)
    })

    useEffect(() => {
        if (tierStatus != 'loading') {
            // SETTING FILTER TIERS
            setFilters((prevFilters) => ({
                ...prevFilters,
                tiers: [tiers[0].value],
            }))
        }
    }, [tiers])

    useEffect(() => {
        if (customerStatus != 'loading') {
            // SETTING FILTER CUSTOMERS
            setFilters((prevFilters) => ({
                ...prevFilters,
                customers: managedCustomers
            }))
        }
    }, [managedCustomers])

    useEffect(() => {
        // if (tierStatus != 'loading' && customerStatus != 'loading') {
        // CALLING SET CHART DATA
        refetchData()
        // }
    }, [filters.customers])

    useEffect(() => {
        // Set Annotations
        formAnnotations();
    }, [chartData]);

    return (
        <PageContainer>

            <>
                <Row>
                    <ConfigProvider locale={enUSIntl}>

                        <ProForm<FiltersType> layout={"inline"}

                                              onValuesChange={(changedValues, values) => {
                                                  // If tier field have changed
                                                  if (changedValues.tiers) {
                                                      setFilters((prevFilters) => ({
                                                          ...prevFilters,
                                                          tiers: changedValues.tiers
                                                      }));
                                                  } else {
                                                      setFilters((prevFilters) => ({
                                                          ...prevFilters,
                                                          ...changedValues
                                                      }));
                                                  }
                                              }
                                              }

                                              submitter={{
                                                  resetButtonProps: false,
                                                  submitButtonProps: {
                                                      style: {marginLeft: 8},
                                                      disabled: tierStatus === 'loading' || customerStatus === 'loading' || dataStatus === 'loading'
                                                  },
                                              }}

                                              onFinish={async () => {
                                                  setFiltersToTrigger(filters)
                                                  // setSubmitHasBeenPressed(true)
                                                  await refetchData()
                                              }}

                                              disabled={tierStatus === 'loading' || customerStatus === 'loading' || dataStatus === 'loading'}
                        >
                            <Space align={"baseline"}>

                                {/*<Segmented options={metrics}*/}
                                {/*           onResize={undefined}*/}
                                {/*           onResizeCapture={undefined}*/}
                                {/*    // onChange={onMetricsChange}*/}
                                {/*/>*/}

                                <ProFormRadio.Group
                                    name="metric"
                                    label="Metric"
                                    radioType={"button"}
                                    initialValue={filters.metric}
                                    options={metrics}
                                />

                                <ProFormSelect style={{width: "300px"}}
                                               name="tiers"
                                               label="Select Tiers"
                                               options={tiers}

                                               fieldProps={{
                                                   mode: 'multiple',
                                                   maxTagCount: 'responsive',
                                                   value: filters.tiers
                                               }}
                                />

                                <ProFormDateRangePicker
                                    width='md'
                                    name='monthRange'
                                    initialValue={[filters.startDate, filters.endDate]}
                                    fieldProps={{
                                        format: 'YYYY-MM',
                                        picker: 'month',
                                        placeholder: ['Start month', 'End month'],
                                        value: [dayjs(filters.startDate, 'YYYY-MM'), dayjs(filters.endDate, 'YYYY-MM')],
                                    }}
                                    transform={(values: string[]) => {
                                        return {
                                            startDate: values[0].substring(0, 7) + '-01' || undefined,
                                            endDate: values[1].substring(0, 7) + '-01' || undefined,
                                        };
                                    }}
                                    style={{flex: 1}}
                                />

                            </Space>


                        </ProForm>

                    </ConfigProvider>
                </Row>
                <br/>
                {/*There are no data!*/}
                {dataStatus === 'success' && chartData.data === null ? (
                    // {chartData.data == null ? (
                    <>
                        <br/>
                        <br/>
                        <Empty/>
                    </>
                ) : (
                    dataFetchStatus !== 'fetching' && dataStatus === 'success' ? (
                        <div style={{padding: '1%', marginLeft: '2%', marginRight: '2%'}}>
                            <Row justify={'end'}>
                                <Statistic
                                    title={chartData && (filtersToTrigger.metric == "dm_revenue" || filtersToTrigger.metric == "customer_revenue") ? 'Total Revenue' : 'Total Streams'}
                                    value={chartData ? chartData.total : 0}
                                    precision={filtersToTrigger.metric == "dm_revenue" || filtersToTrigger.metric == "customer_revenue" ? 2 : 0}
                                    groupSeparator={'.'}
                                    decimalSeparator={','}
                                    suffix={chartData && (filtersToTrigger.metric == "dm_revenue" || filtersToTrigger.metric == "customer_revenue") ? currencyMap[chartData.currency] : ''}
                                />
                            </Row>
                            <br/>
                            <Column
                                isStack={true}
                                xField={'month'}
                                yField={'metric'}
                                seriesField={'tier'}
                                appendPadding={[20, 0, 0, 0]}
                                data={chartData ? chartData.data : []}
                                color={['#85a5ff',
                                    '#ff85c0',
                                    '#b37feb',
                                    '#5cdbd3',
                                    '#ffa39e',]}
                                // color={['#581845',
                                //     '#900C3F',
                                //     '#FF5733',
                                //     '#FFC300',
                                //     '#DAF7A6',]}
                                yAxis={{
                                    minLimit: 0,
                                    label: {
                                        formatter: (value) => {
                                            let formNumber = new Intl.NumberFormat('en-US', {
                                                notation: 'compact'
                                            });

                                            let formattedNumber = ''
                                            // If chartData not undefined
                                            if (chartData) {
                                                formattedNumber = formNumber.format(+value)
                                                // If chosen metric is revenue, add currency symbol
                                                if (filtersToTrigger.metric == "dm_revenue" || filtersToTrigger.metric == "customer_revenue") {
                                                    formattedNumber = currencyMap[chartData.currency] + ' ' + formattedNumber
                                                }
                                            }

                                            return (formattedNumber);
                                        }
                                    }
                                }}

                                tooltip={{
                                    domStyles: {
                                        'g2-tooltip': {'overflow-y': 'auto', 'max-height': '320px'}
                                    },
                                    formatter: (datum) => {
                                        console.log(datum)
                                        let finalValue = Math.round((datum['metric'] as number) * 100) / 100;

                                        let formattedValue = (finalValue?.toLocaleString('el-GR') || 0)

                                        if (filtersToTrigger.metric == "dm_revenue" || filtersToTrigger.metric == "customer_revenue") {
                                            formattedValue = formattedValue + ' ' + currencyMap[chartData.currency]
                                        }

                                        let tooltipDatumName
                                        for (let i = tiers.length - 1; i >= 0; i--) {
                                            if (tiers[i].value === datum['tier']) {
                                                tooltipDatumName = tiers[i].label
                                            }
                                        }

                                        return {
                                            name: tooltipDatumName,
                                            value: formattedValue
                                        };
                                    }
                                }}
                                maxColumnWidth={100}
                                // Format month in column graph and annotations
                                meta={{
                                    month: {
                                        formatter: (value) => dayjs(value, 'YYYY-MM').format('MMM YY')
                                    }
                                }}
                                //todo: Fix annotations
                                annotations={annotations ? annotations : []}
                                interactions={[{type: 'legend-filter', enable: false}]}
                            />

                        </div>

                    ) : (
                        <>
                            <br/>
                            <br/>
                            <ColumnLoading/>
                        </>
                    )

                )
                }
            </>


        </PageContainer>
    );
}

export default TierMetrics;